import { faEdit } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'

type LeadSourcesListItemProps = {
  leadSourceGuid: string
  leadSourceType: string
  name: string
  count: number
  listOrder: number
  onEditLeadSourceClicked: (leadSourceGuid: string) => void
}

export const LeadSourcesListItem = ({
  leadSourceGuid,
  leadSourceType,
  name,
  count,
  listOrder,
  onEditLeadSourceClicked,
}: LeadSourcesListItemProps) => {
  return (
    <div className="flex flex-row items-center rounded-lg border border-solid border-bz-gray-500 px-6 py-4">
      <div className="flex flex-row items-center gap-2">
        <span>{listOrder}.</span>

        <span>{name}</span>
      </div>

      <div className="ml-auto flex flex-row items-center gap-6">
        <div className="flex flex-col">
          <span>{leadSourceType}</span>
          <span>Lead Source Type</span>
        </div>

        <div className="flex flex-col">
          <span>{count}</span>
          <span>Count</span>
        </div>

        <div className="flex flex-row items-center gap-2">
          <Button
            type="primary"
            icon={<FontAwesomeIcon icon={faEdit} />}
            onClick={() => onEditLeadSourceClicked(leadSourceGuid)}
          >
            Edit
          </Button>
          {/* <Button icon={<FontAwesomeIcon icon={faArchive} />}>Archive</Button> */}
        </div>
      </div>
    </div>
  )
}

export type LeadSourcesListProps = {
  leadSources: {
    leadSourceGuid: string
    leadSourceType: string
    name: string
    count: number
    listOrder: number
  }[]
  onEditLeadSourceClicked: (leadSourceGuid: string) => void
}

export const LeadSourcesList = ({
  leadSources,
  onEditLeadSourceClicked,
}: LeadSourcesListProps) => {
  return (
    <div className="flex flex-col gap-3">
      {leadSources.map(leadSource => (
        <LeadSourcesListItem
          key={leadSource.leadSourceGuid}
          leadSourceGuid={leadSource.leadSourceGuid}
          name={leadSource.name}
          leadSourceType={leadSource.leadSourceType}
          listOrder={leadSource.listOrder}
          count={leadSource.count}
          onEditLeadSourceClicked={onEditLeadSourceClicked}
        />
      ))}
    </div>
  )
}
