import { Guid } from '@breezy/shared'
import { Form } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { TechMultiSelectForm, TechOptionsTech } from './TechMultiSelectForm'

type TechPickerModalProps = {
  onClose: () => void
  onSave: (userGuids: Guid[]) => void
  technicians: TechOptionsTech[]
  prePopulatedChoices?: Guid[]
}

export const TechPickerModal = React.memo<TechPickerModalProps>(
  ({ onClose, onSave, technicians, prePopulatedChoices }) => {
    const [selectedTechGuids, setSelectedTechGuids] = useState<Guid[]>(
      prePopulatedChoices ?? [],
    )
    const selectOptions = useMemo(
      () =>
        technicians.filter(
          tech =>
            !tech.deactivatedAt ||
            // If there is a deactivated user assigned to an appointment, they'll be in `prePopulatedChoices`. If we
            // filter them out here, they'll show up in the select as a GUID. So if they're already selected, we need
            // them to be in the "options". If they get un-selected, since they are deactivated they'll get filtered
            // from the dropdown.
            selectedTechGuids.includes(tech.userGuid),
        ),
      [selectedTechGuids, technicians],
    )

    const onConfirm = useCallback(() => {
      onSave(selectedTechGuids)
    }, [onSave, selectedTechGuids])

    return (
      <OnsiteConfirmModal
        header="Assign Technician"
        onCancel={onClose}
        onConfirm={onConfirm}
        open
        confirmDisabled={!selectedTechGuids.length}
      >
        <Form layout="vertical">
          <TechMultiSelectForm
            className="mb-0"
            technicians={selectOptions}
            selectedTechGuids={selectedTechGuids}
            onChange={setSelectedTechGuids}
          />
        </Form>
      </OnsiteConfirmModal>
    )
  },
)
