import {
  CompanyCreatorV2Input,
  clamp,
  companyCreatorV2InputSchema,
  isNullish,
  prettyJson,
} from '@breezy/shared'
import {
  faBillboard,
  faBuildingMemo,
  faScrewdriverWrench,
  faUsers,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Steps } from 'antd'
import { useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import BzDrawer from '../../../../elements/BzDrawer/BzDrawer'
import { useReactHookFormSubmit } from '../../../../elements/Forms/useReactHookFormSubmit'
import { trpc } from '../../../../hooks/trpc'
import { useMessage } from '../../../../utils/antd-utils'
import { CreateCompanyDrawerCompanyDetailsSection } from './CreateCompanyDrawerCompanyDetailsSection/CreateCompanyDrawerCompanyDetailsSection'
import { CreateCompanyDrawerEquipmentTypesSection } from './CreateCompanyDrawerEquipmentTypesSection/CreateCompanyDrawerEquipmentTypesSection'
import { CreateCompanyDrawerLeadSourcesSection } from './CreateCompanyDrawerLeadSourcesSection/CreateCompanyDrawerLeadSourcesSection'
import { CreateCompanyDrawerTeamMembersSection } from './CreateCompanyDrawerTeamMembersSection/CreateCompanyDrawerTeamMembersSection'

export type CreateCompanyDrawerProps = {
  open: boolean
  onCancel: () => void
  onCompanyCreatedFinished?: () => void
}

export const CreateCompanyDrawer = ({
  open,
  onCancel,
  onCompanyCreatedFinished,
}: CreateCompanyDrawerProps) => {
  const message = useMessage()

  const [submitElement, triggerSubmit] = useReactHookFormSubmit()
  const [currStep, setCurrStep] = useState(0)

  const companyCreatorV2Mutation =
    trpc.companies['companies:create'].useMutation()

  const form = useForm<CompanyCreatorV2Input>({
    resolver: zodResolver(companyCreatorV2InputSchema),
  })

  const onClose = () => {
    form.reset()
    setCurrStep(0)
    onCancel()
  }

  const onSubmit = form.handleSubmit(
    async data => {
      const hasRootUser = !isNullish(
        data.teamMembers.find(teamMember => teamMember.isSuperAdmin),
      )

      if (!hasRootUser) {
        message.error(
          'No root user selected! Please select one user to be a root user (super admin).',
        )
        return
      }

      try {
        await companyCreatorV2Mutation.mutateAsync(data)
        message.success(`Company ${data.company.name} was created`)
        onCompanyCreatedFinished?.()
        onClose()
      } catch (err) {
        console.error(err)
      }
    },
    error => {
      if (error.company || error.billingProfile) {
        setCurrStep(0)
      } else if (error.teamMembers) {
        setCurrStep(1)
      } else if (error.leadSources) {
        setCurrStep(2)
      } else {
        setCurrStep(3)
      }
    },
  )

  const stepItems = useMemo(
    () => [
      {
        title: 'Company Details',
        content: <CreateCompanyDrawerCompanyDetailsSection />,
        icon: <FontAwesomeIcon icon={faBuildingMemo} />,
        onClick: () => setCurrStep(0),
      },
      {
        title: 'Team Members',
        content: <CreateCompanyDrawerTeamMembersSection />,
        icon: <FontAwesomeIcon icon={faUsers} />,
        onClick: () => setCurrStep(1),
      },
      {
        title: 'Lead Sources',
        content: <CreateCompanyDrawerLeadSourcesSection />,
        icon: <FontAwesomeIcon icon={faBillboard} />,
        onClick: () => setCurrStep(2),
      },
      {
        title: 'Equipment Types',
        content: <CreateCompanyDrawerEquipmentTypesSection />,
        icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
        onClick: () => setCurrStep(3),
      },
    ],
    [],
  )

  return (
    <FormProvider {...form}>
      <BzDrawer
        title="Create New Company"
        item={open ? { onCancel: onClose } : undefined}
        preferredWidth={960}
        destroyOnClose
        footer={
          <div className="flex flex-row gap-3">
            <Button
              htmlType="button"
              onClick={onClose}
              disabled={companyCreatorV2Mutation.isLoading}
            >
              {companyCreatorV2Mutation.isLoading ? 'Loading' : 'Cancel'}
            </Button>

            <Button
              className="ml-auto"
              htmlType="button"
              onClick={() => setCurrStep(currStep => clamp(currStep - 1, 0, 3))}
              disabled={currStep === 0 || companyCreatorV2Mutation.isLoading}
            >
              {companyCreatorV2Mutation.isLoading ? 'Loading' : 'Back'}
            </Button>

            <Button
              type="primary"
              htmlType="button"
              onClick={() => {
                if (currStep === 3) {
                  triggerSubmit()
                } else {
                  setCurrStep(currStep => clamp(currStep + 1, 0, 3))
                }
              }}
              disabled={companyCreatorV2Mutation.isLoading}
            >
              {companyCreatorV2Mutation.isLoading
                ? 'Loading'
                : currStep === 3
                ? 'Create Company'
                : 'Next'}
            </Button>
          </div>
        }
      >
        <form className="flex h-full w-full flex-row gap-6" onSubmit={onSubmit}>
          <Steps
            current={currStep}
            items={stepItems}
            direction="vertical"
            className="w-max"
            size="small"
          />

          {stepItems[currStep].content}

          {submitElement}
        </form>
        {Object.keys(form.formState.errors).length > 0 && (
          <>
            <span className="text-bz-error">Errors:</span>
            {Object.entries(form.formState.errors).map(
              ([errorKey, errorValue]) => {
                return (
                  <div className="text-bz-error" key={errorKey}>
                    <div>
                      Field: {errorKey}, Error: {prettyJson(errorValue)}
                    </div>
                  </div>
                )
              },
            )}
          </>
        )}
      </BzDrawer>
    </FormProvider>
  )
}
