import {
  BzDateFns,
  CLOSED_STATUSES,
  Dfns,
  IsoDateString,
  TimeZoneId,
} from '@breezy/shared'
import { useMemo } from 'react'
import { UseQueryResponse, useQuery } from 'urql'
import { JobsRecentJobsQueryQuery } from '../../../generated/user/graphql'
import { RECENT_JOBS_QUERY } from '../../../gql/queries/Jobs.gql'
import { JobLifecycleTimePeriod } from '../../../hooks/useJobLifecyclePersistedDisplay'

export type UseRecentJobsInput = {
  companyGuid: string
  tzId: TimeZoneId
  closedJobTimePeriod: JobLifecycleTimePeriod
}

export type UseRecentJobsOutput = UseQueryResponse<
  JobsRecentJobsQueryQuery,
  {
    companyGuid: string
    closedStatuses: string[]
    jobLifecycleStatusUpdatedAt: IsoDateString
  }
>

export const useRecentJobs = ({
  companyGuid,
  tzId,
  closedJobTimePeriod,
}: UseRecentJobsInput): UseRecentJobsOutput => {
  // TODO: Add timezone support?
  // This is an artifact from moving this query into its own hook. This TODO might not apply anymore

  const jobQueryArgs: {
    companyGuid: string
    closedStatuses: string[]
    jobLifecycleStatusUpdatedAt: IsoDateString
  } = useMemo(() => {
    let updatedAt: Date = Dfns.startOfDay(new Date())

    switch (closedJobTimePeriod) {
      case 'Past day':
        updatedAt = Dfns.subDays(1, updatedAt)
        break
      case 'Past week':
        updatedAt = Dfns.subWeeks(1, updatedAt)
        break
      case 'Past month':
        updatedAt = Dfns.subMonths(1, updatedAt)
        break
      case 'Past 60 days':
        updatedAt = Dfns.subDays(60, updatedAt)
        break
      case 'Past 90 days':
        updatedAt = Dfns.subDays(90, updatedAt)
        break
      case 'All time':
        // Nice suggestion from Copilot lmao
        updatedAt = Dfns.subYears(100, updatedAt)
        break
    }

    return {
      companyGuid: companyGuid,
      closedStatuses: CLOSED_STATUSES as unknown as string[],
      jobLifecycleStatusUpdatedAt: BzDateFns.formatISO(updatedAt, tzId),
    }
  }, [closedJobTimePeriod, companyGuid, tzId])

  return useQuery({
    query: RECENT_JOBS_QUERY,
    variables: jobQueryArgs,
  })
}
