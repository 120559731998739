import {
  BzDateTime,
  IsoDateString,
  R,
  ReportingDateRange,
  Technician,
  TimePastNReportRequest,
  UTC_TIME_ZONE,
  formatUsc,
  getDateGroupingTypeForRange,
  getPastNReportingTimeWindowsFor,
  isNullish,
} from '@breezy/shared'
import { memo, useMemo } from 'react'
import { LineChart, LineData } from '../../../elements/Charts/LineChart'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { getStandardXAxisValue, useStandardXAxisFormatter } from '../utils'
import { TeamPerformanceJob } from './team-performance-queries.gql'

type IndividualTechnicianPerformanceEarnedRevenueChartProps = {
  technicianUserGuidToTechnicianMap: Record<string, Technician>
  technicianUserGuidToEarnedRevenueJobsMap: Record<string, TeamPerformanceJob[]>
  dateRange: ReportingDateRange
  timeUnit: TimePastNReportRequest['timeUnit']
  timePastN: TimePastNReportRequest['timePastN']
}

type EarnedRevenueChartData = {
  readonly periodStart: IsoDateString
  readonly periodEnd: IsoDateString
  readonly technicianGuidToEarnedRevenueUscMap: Record<string, number>
}

const Y_AXIS_FORMATTER = (earnedRevenueUsc: number) =>
  formatUsc(earnedRevenueUsc)

export const IndividualTechnicianPerformanceEarnedRevenueChart =
  memo<IndividualTechnicianPerformanceEarnedRevenueChartProps>(
    ({
      technicianUserGuidToTechnicianMap,
      technicianUserGuidToEarnedRevenueJobsMap,
      dateRange,
      timeUnit,
      timePastN,
    }) => {
      const tzId = useExpectedCompanyTimeZoneId()

      const reportingTimeWindows = useMemo(
        () =>
          getPastNReportingTimeWindowsFor(
            {
              timeUnit: timeUnit,
              timePastN: timePastN,
            },
            UTC_TIME_ZONE,
          ),
        [timePastN, timeUnit],
      )

      const reportingData = useMemo(() => {
        const data: Record<IsoDateString, EarnedRevenueChartData> = {}

        for (let i = 0; i < reportingTimeWindows.length; ++i) {
          const { start, end } = reportingTimeWindows[i]

          const startDateTime = BzDateTime.fromIsoString(start, tzId)
          const endDateTime = BzDateTime.fromIsoString(end, tzId)

          const technicianGuidToTotalEarnedRevenueUscMap: Record<
            string,
            number
          > = {}
          Object.entries(technicianUserGuidToEarnedRevenueJobsMap).forEach(
            ([technicianUserGuid, allEarnedRevenueJobs]) => {
              const earnedRevenueJobsForThisTimeWindow =
                allEarnedRevenueJobs.filter(job => {
                  if (isNullish(job.workCompletedAt)) {
                    return false
                  }

                  const workCompletedAtDateTime = BzDateTime.fromIsoString(
                    job.workCompletedAt,
                    tzId,
                  )
                  if (
                    workCompletedAtDateTime.isAfter(startDateTime) &&
                    workCompletedAtDateTime.isBefore(endDateTime)
                  ) {
                    return true
                  }

                  return false
                })

              technicianGuidToTotalEarnedRevenueUscMap[technicianUserGuid] = 0

              earnedRevenueJobsForThisTimeWindow.forEach(job => {
                job.jobTechnicianSoldRevenueSummaries.forEach(summary => {
                  if (
                    !isNullish(summary.technicianUserGuid) &&
                    technicianUserGuid === summary.technicianUserGuid
                  ) {
                    technicianGuidToTotalEarnedRevenueUscMap[
                      technicianUserGuid
                    ] =
                      (technicianGuidToTotalEarnedRevenueUscMap[
                        technicianUserGuid
                      ] ?? 0) + (summary.totalSoldRevenueUsc ?? 0)
                  }
                })
              })
            },
          )

          data[start] = {
            periodStart: start,
            periodEnd: end,
            technicianGuidToEarnedRevenueUscMap:
              technicianGuidToTotalEarnedRevenueUscMap,
          }
        }

        return data
      }, [reportingTimeWindows, technicianUserGuidToEarnedRevenueJobsMap, tzId])

      const xAxisValues = useMemo(() => {
        const orderedBucketKeys = R.keys(reportingData).sort()

        return orderedBucketKeys.map(bucketTimestamp =>
          getStandardXAxisValue(
            tzId,
            bucketTimestamp,
            getDateGroupingTypeForRange(dateRange, tzId),
          ),
        )
      }, [dateRange, reportingData, tzId])

      const xAxisFormatter = useStandardXAxisFormatter(dateRange, tzId)

      const lineChartData = useMemo<LineData[]>(() => {
        const orderedBucketKeys = R.keys(reportingData).sort()

        const chartData: LineData[] = []

        Object.entries(technicianUserGuidToTechnicianMap).forEach(
          ([technicianUserGuid, technician]) => {
            const technicianConversionRateData = orderedBucketKeys.map(
              bucketTimestamp => {
                return reportingData[bucketTimestamp]
                  .technicianGuidToEarnedRevenueUscMap[technicianUserGuid]
              },
            )

            chartData.push({
              label: `${
                technician.user.firstName
              } ${technician.user.lastName.substring(0, 1)}.`,
              data: technicianConversionRateData,
              renderTooltipValue: (earnedRevenueUsc: number) =>
                formatUsc(earnedRevenueUsc),
            })
          },
        )

        return chartData
      }, [reportingData, technicianUserGuidToTechnicianMap])

      return (
        <LineChart
          data={lineChartData}
          yAxisFormatter={Y_AXIS_FORMATTER}
          xAxisValues={xAxisValues}
          xAxisFormatter={xAxisFormatter}
          showLegend={true}
        />
      )
    },
  )
