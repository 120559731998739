import { CompanyCreatorV2Input, isNullish, toCamelCase } from '@breezy/shared'
import { faUpload } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Collapse, Upload, UploadFile } from 'antd'
import Papa from 'papaparse'
import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useMessage } from 'src/utils/antd-utils'
import { fromError } from 'zod-validation-error'
import { CompanyDetailsFormSchema, companyDetailsFormSchema } from '../schema'
import { CompanyDetailsSectionBillingProfileCollapsible } from './CompanyDetailsSectionBillingProfileCollapsible'
import { CompanyDetailsSectionCompanyCollapsible } from './CompanyDetailsSectionCompanyCollapsible'
import { CompanyDetailsSectionInvoicesEstimatesCollapsible } from './CompanyDetailsSectionInvoicesEstimatesCollapsible'

export const CreateCompanyDrawerCompanyDetailsSection = () => {
  const message = useMessage()
  const { setValue, reset, watch, trigger } =
    useFormContext<CompanyCreatorV2Input>()

  const formValues = watch()

  const onFileUploaded = useCallback(
    (file: UploadFile, fileList: UploadFile[]) => {
      if (isNullish(file.originFileObj)) {
        message.error('Could not open file')
        return
      }

      if (fileList.length === 0) {
        return
      }

      Papa.parse<CompanyDetailsFormSchema>(file.originFileObj as File, {
        header: true,
        transformHeader: header => toCamelCase(header),
        complete: results => {
          const parsed = companyDetailsFormSchema.safeParse(results.data[0])
          if (!parsed.success) {
            message.error(
              `Failed to validate Company CSV with errors ${fromError(
                parsed.error,
              )}`,
            )

            reset({
              ...formValues,
              company: undefined,
              billingProfile: undefined,
            })
          } else {
            const data = parsed.data
            setValue('company', {
              name: data.companyName,
              timezone: data.timezone,
            })
            setValue('billingProfile', {
              logoUrl: data.companyLogoURL,
              websiteUrl: data.websiteURL,
              websiteDisplayName: data.websiteDisplayName,
              phoneNumber: '',
              emailAddress: data.businessEmailAddress,
              fullLegalName: data.businessFullLegalName,
              contractorLicenseNumber: data.contractorLicenseNumber,
              businessAddress: {
                line1: data.businessAddressLine1,
                line2: data.businessAddressLine2,
                city: data.businessAddressCity,
                zipCode: data.businessAddressZipCode,
                stateAbbreviation: data.businessAddressStateAbbreviation,
              },
              invoiceAndEstimates: {
                invoiceMemo: data.invoiceMemo,
                invoiceTerm: data.defaultInvoiceTerm,
                estimateDisclaimer: data.estimateDisclaimer,
                invoiceDisclaimer: data.invoiceDisclaimer,
              },
              defaultPricebookTaxRate: {
                name: 'Default Tax Rate',
                rate: Number(data.defaultTaxRate),
                version: 1,
              },
            })

            trigger(['company', 'billingProfile'])
          }
        },
      })
    },
    [formValues, message, reset, setValue, trigger],
  )

  const onFileRemoved = useCallback(
    () =>
      reset({ ...formValues, company: undefined, billingProfile: undefined }),
    [formValues, reset],
  )

  return (
    <div className="flex h-full w-full flex-col gap-3 overflow-y-scroll">
      <label className="flex flex-col gap-2">
        <span>Select Company Details CSV</span>
        <Upload
          accept="text/csv"
          maxCount={1}
          customRequest={({ onSuccess }) => {
            onSuccess?.('ok')
          }}
          onChange={({ file, fileList }) => onFileUploaded(file, fileList)}
          onRemove={onFileRemoved}
        >
          <Button icon={<FontAwesomeIcon icon={faUpload} />}>
            Upload File
          </Button>
        </Upload>
      </label>

      {!isNullish(formValues.company) &&
        !isNullish(formValues.billingProfile) && (
          <Collapse
            defaultActiveKey={[
              'Company',
              'Business Profile',
              'Invoices and Estimates',
            ]}
            items={[
              {
                key: 'Company',
                label: 'Company',
                children: <CompanyDetailsSectionCompanyCollapsible />,
              },
              {
                key: 'Business Profile',
                label: 'Business Profile',
                children: <CompanyDetailsSectionBillingProfileCollapsible />,
              },
              {
                key: 'Invoices and Estimates',
                label: 'Invoices and Estimates',
                children: <CompanyDetailsSectionInvoicesEstimatesCollapsible />,
              },
            ]}
          />
        )}
    </div>
  )
}
