import {
  QBO_ERROR_CODES,
  QboIncomeAccount,
  bzExpect,
  parseQboErrorInfo,
} from '@breezy/shared'
import { useCallback, useEffect, useState } from 'react'
import { useQuickbooksAuthorizeButtonProps } from '../../components/Quickbooks/QuickbooksAuthorizeButton'
import {
  triggerQBOUnauthModal,
  useIsQboEnabled,
} from '../../components/Quickbooks/QuickbooksSyncButton'
import { useModal } from '../../utils/antd-utils'
import { trpc } from '../trpc'

export type FinanceAccountTreeData = {
  value: string
  title: React.ReactNode
  accountName: string
  accountType: string
  children?: FinanceAccountTreeData[]
}

const COULD_NOT_FETCH_ERROR_MESSAGE_TITLE =
  'Could not fetch Quickbooks accounts.'
const FALLBACK_ERROR_MESSAGE_CONTENT =
  'Please try again later or contact support.'

const AccountTitle = ({
  name,
  accountType,
}: QboIncomeAccount): React.ReactNode => {
  return (
    <div className="row flex-between">
      <div className="text-ellipsis whitespace-nowrap">{name}</div>{' '}
      <div className="grey7 ml-1 truncate pr-2 text-right">
        <i>{accountType}</i>
      </div>
    </div>
  )
}

export const qboAccountsToTreeData = (data: QboIncomeAccount[]) => {
  const itemMap: Record<string, FinanceAccountTreeData> = {}
  const parentIdToChildrenIds: Record<string, string[]> = {}
  const parentItems: FinanceAccountTreeData[] = []

  for (const { id, name, parentId, accountType } of data) {
    const treeItem = {
      value: id,
      accountName: name,
      accountType,
      title: AccountTitle({ id, name, parentId, accountType }),
    }
    itemMap[id] = treeItem

    if (parentId) {
      if (!parentIdToChildrenIds[parentId]) {
        parentIdToChildrenIds[parentId] = []
      }
      parentIdToChildrenIds[parentId].push(id)
    } else {
      parentItems.push(treeItem)
    }
  }

  const fillChildren = (item: FinanceAccountTreeData) => {
    const childrenIds = parentIdToChildrenIds[item.value]
    if (childrenIds) {
      const children = childrenIds.map(id => {
        const childItem = itemMap[id]
        fillChildren(childItem)
        return childItem
      })
      item.children = children
    }
  }

  parentItems.forEach(fillChildren)

  return parentItems
}

export const useQboAccountTreeData = ():
  | FinanceAccountTreeData[]
  | undefined => {
  const Modal = useModal()
  const title = COULD_NOT_FETCH_ERROR_MESSAGE_TITLE
  const isQboEnabled = useIsQboEnabled()
  const qboAuthorizeButtonProps = useQuickbooksAuthorizeButtonProps()

  const [treeData, setTreeData] = useState<FinanceAccountTreeData[]>()

  const incomeAccountQuery = trpc.qbo[
    'finance-app:get-income-accounts'
  ].useQuery(undefined, {
    enabled: false,
    retry: false,
    onError: () => {},
  })

  const refetch = useCallback(() => {
    return incomeAccountQuery.refetch()
    // eslint doesn't realize that incomeAccountQuery.refetch is all we use. It wants me
    // to include the whole `incomeAccountQuery` object, which changes constantly and
    // causes a shallow-unequal chain reaction.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeAccountQuery.refetch])

  useEffect(() => {
    if (
      isQboEnabled &&
      incomeAccountQuery.data &&
      !incomeAccountQuery.isError
    ) {
      refetch().then(res => {
        if (res.isError) {
          const qboErrorInfo = parseQboErrorInfo(res.error.message)
          let content = FALLBACK_ERROR_MESSAGE_CONTENT

          if (qboErrorInfo) {
            const [errorCode, message] = qboErrorInfo
            if (errorCode === QBO_ERROR_CODES.AUTH) {
              triggerQBOUnauthModal(
                Modal,
                qboAuthorizeButtonProps,
                async () => {
                  const res = await refetch()
                  if (res.isError) {
                    Modal.error({
                      title,
                      content: res.error?.message || content,
                    })
                  }
                },
              )
              setTreeData([])
              return
            }
            content = message
          }
          console.error(JSON.stringify(res.error, null, 2))
          Modal.error({ title, content })
        } else {
          setTreeData(
            qboAccountsToTreeData(
              bzExpect(res.data, 'incomeAccountQuery.data'),
            ),
          )
        }
      })
    }
  }, [
    isQboEnabled,
    incomeAccountQuery.data,
    incomeAccountQuery.isError,
    refetch,
    qboAuthorizeButtonProps,
    title,
    Modal,
  ])

  return treeData
}
