import { PageHeader } from '@ant-design/pro-components'
import {
  BzDateTime,
  EnrichedGeneralEvent,
  IsoDateString,
  PermissionV2,
  TimeZoneId,
} from '@breezy/shared'
import { faTimelineArrow } from '@fortawesome/pro-light-svg-icons'
import { Divider, Table } from 'antd'
import Search from 'antd/lib/input/Search'
import { ColumnsType } from 'antd/lib/table'
import { useState } from 'react'
import ReactJson from 'react-json-view'
import { useParams } from 'react-router-dom'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Page } from '../../components/Page/Page'
import { Authorized } from '../../components/Permissions/Authorized/Authorized'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { trpc } from '../../hooks/trpc'
import {
  useExpectedCompany,
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'

const EntityHistoryPage = m(() => {
  const companyGuid = useExpectedCompanyGuid()
  const routeEntityGuid = useParams().entityGuid

  const [entityGuid, setEntityGuid] = useState<string | undefined>(
    routeEntityGuid ? routeEntityGuid : undefined,
  )

  return (
    <Authorized
      to={PermissionV2.OFFICE_FINANCIAL_INFORMATION_MANAGE}
      allowsNonCompanyUser
    >
      <Page requiresCompanyUser={true}>
        <PageHeader
          title={
            <PageTitle title="Entity History Viewer" icon={faTimelineArrow} />
          }
        />
        <div className="column w-full p-[16px]">
          <Search
            value={entityGuid}
            placeholder="Enter Entity Guid"
            onSearch={setEntityGuid}
            style={{ width: 600 }}
            allowClear
            enterButton="Search"
            size="large"
          />
          <Divider />
          {entityGuid && (
            <EntityHistoryTableLoader
              companyGuid={companyGuid}
              entityGuid={entityGuid}
            />
          )}
        </div>
      </Page>
    </Authorized>
  )
})

type TableItemType = EnrichedGeneralEvent

const withSpacesBetweenWords = (str: string | undefined) => {
  return (str ?? '').replace(/([A-Z])/g, ' $1').trim()
}

const cols = (tzId: TimeZoneId): ColumnsType<TableItemType> => [
  {
    title: 'Version',
    dataIndex: 'entityVersion',
    key: 'entityVersion',
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: 'Acting User',
    dataIndex: 'actingUser',
    key: 'actingUser',
    render: (_: unknown, record: TableItemType) => (
      <div>{record.actingUser.displayName}</div>
    ),
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: 'Event Type',
    dataIndex: 'eventType',
    key: 'eventType',
    render: (v: string) => withSpacesBetweenWords(v),
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: 'Occurred At',
    dataIndex: 'occurredAt',
    key: 'occurredAt',
    render: (v: IsoDateString) =>
      BzDateTime.fromIsoString(v, tzId).toHighPrecisionDateTimeString(),
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: 'Data',
    dataIndex: 'eventData',
    key: 'eventData',
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    render: (v: any) => (
      <ReactJson src={v} indentWidth={2} collapsed={1} name={null} />
    ),
    width: 800,
    ellipsis: {
      showTitle: true,
    },
  },
]

type EntityHistoryTableLoaderProps = {
  companyGuid: string
  entityGuid: string
}

const EntityHistoryTableLoader = m<EntityHistoryTableLoaderProps>(
  ({ companyGuid, entityGuid }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const companyName = useExpectedCompany().name
    const query = trpc.devTools['devtools:read-entity-history'].useQuery({
      companyGuid,
      entityGuid,
    })
    const entityType = query.data?.[0]?.entityType ?? ''

    return (
      <TrpcQueryLoader
        query={query}
        render={data => (
          <div className="col w-full">
            <div className="flex">
              <h2>{withSpacesBetweenWords(entityType)}</h2>
              <h2 className="ml-4"> - Company: {companyName}</h2>
              <h2 className="ml-4">
                <i>(TimeZone: {tzId})</i>
              </h2>
            </div>
            <Table
              rowKey="entityVersion"
              dataSource={data}
              scroll={{ x: true }}
              columns={cols(tzId)}
              pagination={{ pageSize: 100 }}
              size="small"
            />
          </div>
        )}
        loadingComponent={
          <div className="center-children-v min-h-[400px]">
            <LoadingSpinner />
          </div>
        }
      />
    )
  },
)

export default EntityHistoryPage
