import {
  BzDateFns,
  bzOptional,
  CreateJobLeadRequestSchema,
  localDateSchema,
} from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Form } from 'antd'
import React, { useCallback, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { DateField } from '../../elements/Forms/DateField'
import FormBody from '../../elements/Forms/FormBody'
import FormDivider from '../../elements/Forms/FormDivider'
import FormHeader from '../../elements/Forms/FormHeader'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../elements/Forms/SelectField'
import { StateField } from '../../elements/Forms/StateField'
import { TextAreaField } from '../../elements/Forms/TextAreaField'
import { TextField } from '../../elements/Forms/TextField'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'

const TIME_WINDOWS = [
  {
    label: '8 AM - 11 AM',
    value: '8 AM - 11 AM',
  },
  {
    label: '11 AM - 2 PM',
    value: '11 AM - 2 PM',
  },
  {
    label: '2 PM - 5 PM',
    value: '2 PM - 5 PM',
  },
]

const CreateJobLeadRequestFormSchema = CreateJobLeadRequestSchema.omit({
  appointmentAvailabilities: true,
}).extend({
  preferredAvailabilityDate: localDateSchema,
  preferredAvailabilityTimeWindows: bzOptional(z.string().array()),
  backupAvailabilityDate: localDateSchema,
  backupAvailabilityTimeWindows: bzOptional(z.string().array()),
})

export type CreateJobLeadRequestData = z.infer<
  typeof CreateJobLeadRequestFormSchema
>

type CreateNewJobLeadFormProps = {
  onCancel?: () => void
  onSubmit: (data: CreateJobLeadRequestData) => void
  isLoading: boolean
}

export const CreateNewJobLeadForm = React.memo<CreateNewJobLeadFormProps>(
  ({ onCancel, onSubmit, isLoading }) => {
    const companyTimezoneId = useExpectedCompanyTimeZoneId()
    const jobTypeQuery = trpc.jobTypes['job-types:get'].useQuery(undefined, {})

    const todayLocalDate = useRef(
      BzDateFns.getTodayLocalDate(companyTimezoneId),
    ).current

    const tomorrowLocalDate = useRef(
      BzDateFns.formatLocalDate(BzDateFns.getTomorrow(companyTimezoneId)),
    ).current

    const {
      control,
      formState: { errors },
      handleSubmit,
      setValue,
    } = useForm<CreateJobLeadRequestData>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: zodResolver(CreateJobLeadRequestFormSchema),
      defaultValues: {
        preferredAvailabilityDate: todayLocalDate,
        preferredAvailabilityTimeWindows: TIME_WINDOWS.map(tw => tw.value),
        backupAvailabilityDate: tomorrowLocalDate,
        backupAvailabilityTimeWindows: TIME_WINDOWS.map(tw => tw.value),
      },
    })

    const onStateChange = useCallback(
      (state: string) => {
        setValue('serviceAddressStateAbbreviation', state)
      },
      [setValue],
    )

    const onSubmitInner = useCallback(
      (data: CreateJobLeadRequestData) => {
        onSubmit(data)
      },
      [onSubmit],
    )
    return (
      <Form
        className="flex min-h-0 flex-1 flex-col"
        layout="vertical"
        onSubmitCapture={handleSubmit(onSubmitInner)}
      >
        <FormBody>
          <FormHeader>Contact Details</FormHeader>

          <div className="flex w-full flex-row gap-3">
            <ReactHookFormItem
              className="min-w-[300px] flex-1"
              control={control}
              name="contactFirstName"
              label="First Name"
              errors={errors}
              render={({ field }) => <TextField {...field} />}
            />
            <ReactHookFormItem
              className="min-w-[300px] flex-1"
              control={control}
              name="contactLastName"
              label="Last Name"
              errors={errors}
              render={({ field }) => <TextField {...field} />}
            />
          </div>
          <div className="flex w-full flex-row gap-3">
            <ReactHookFormItem
              control={control}
              className="min-w-[300px] flex-1"
              name="contactPhoneNumber"
              label="Phone #"
              errors={errors}
              render={({ field }) => <TextField {...field} />}
            />
            <ReactHookFormItem
              control={control}
              className="min-w-[300px] flex-1"
              name="contactEmailAddress"
              label="Email Address"
              errors={errors}
              render={({ field }) => <TextField {...field} />}
            />
          </div>
          <FormDivider />
          <FormHeader>Service Location Details</FormHeader>
          <div className="flex w-full flex-1 flex-row gap-3">
            <ReactHookFormItem
              control={control}
              className="min-w-[300px] flex-1"
              name="serviceAddressLine1"
              label="Address Line 1"
              errors={errors}
              render={({ field }) => <TextField {...field} />}
            />
            <ReactHookFormItem
              control={control}
              className="min-w-[300px] flex-1"
              name="serviceAddressLine2"
              label="Address Line 2"
              errors={errors}
              render={({ field }) => <TextField {...field} />}
            />
          </div>
          <div className="flex w-full flex-1 flex-row gap-3">
            <ReactHookFormItem
              control={control}
              className="flex-1"
              name="serviceAddressCity"
              label="Address City"
              errors={errors}
              render={({ field }) => <TextField {...field} />}
            />
            <ReactHookFormItem
              control={control}
              className="flex-1"
              name="serviceAddressStateAbbreviation"
              label="Address State"
              errors={errors}
              render={({ field }) => (
                <StateField {...field} onChange={onStateChange} />
              )}
            />
            <ReactHookFormItem
              control={control}
              className="flex-1"
              name="serviceAddressZipCode"
              label="Address Zip Code"
              errors={errors}
              render={({ field }) => <TextField {...field} />}
            />
          </div>
          <FormDivider />
          <FormHeader>Job Details</FormHeader>
          <ReactHookFormItem
            control={control}
            className="w-[300px] "
            name="jobType"
            label="Type of Service"
            errors={errors}
            render={({ field }) => {
              return (
                <SelectField
                  options={
                    jobTypeQuery.data?.map(jt => {
                      return {
                        value: jt.name,
                        label: jt.name,
                      }
                    }) ?? []
                  }
                  {...field}
                  size="large"
                  title="Type of Service"
                />
              )
            }}
          />
          <ReactHookFormItem
            control={control}
            className="flex-1"
            name="jobSummary"
            label="Additional Details"
            errors={errors}
            render={({ field }) => <TextAreaField {...field} />}
          />
          <FormDivider />
          <FormHeader>Visit Availability</FormHeader>
          <div className="flex w-full flex-1 flex-row gap-3">
            <ReactHookFormItem
              control={control}
              className="w-[200px] "
              name="preferredAvailabilityDate"
              label="Preferred Availability"
              errors={errors}
              render={({ field }) => <DateField {...field} />}
            />
            <ReactHookFormItem
              control={control}
              className="w-[450px] "
              name="preferredAvailabilityTimeWindows"
              label="Time Windows"
              errors={errors}
              render={({ field }) => {
                return (
                  <SelectField
                    values={[]}
                    mode="multiple"
                    options={TIME_WINDOWS}
                    {...field}
                    size="large"
                    title="Type of Service"
                  />
                )
              }}
            />
          </div>
          <div className="flex w-full flex-1 flex-row gap-3">
            <ReactHookFormItem
              control={control}
              className="w-[200px] "
              name="backupAvailabilityDate"
              label="Backup Availability"
              errors={errors}
              render={({ field }) => <DateField {...field} />}
            />
            <ReactHookFormItem
              control={control}
              className="w-[450px] "
              name="backupAvailabilityTimeWindows"
              label="Time Windows"
              errors={errors}
              render={({ field }) => {
                return (
                  <SelectField
                    values={[]}
                    mode="multiple"
                    options={TIME_WINDOWS}
                    {...field}
                    size="large"
                    title="Type of Service"
                  />
                )
              }}
            />
          </div>
        </FormBody>

        <>
          <div className="mx-4 mx-[-24px] flex flex-row items-center justify-between gap-3 gap-3 border-0 border-t-4 border-solid border-t-bz-gray-400 bg-white p-0 px-4 pt-4 md:-mx-6 md:px-6">
            {onCancel && (
              <Button
                block
                size="large"
                onClick={onCancel}
                disabled={isLoading}
              >
                Cancel
              </Button>
            )}
            <Button
              block
              type="primary"
              size="large"
              data-testid="pay-button"
              data-dd-action-name={'create-job-lead-submit'}
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              Create Job Lead
            </Button>
          </div>
          {isLoading && <div className="absolute inset-0 z-10 cursor-wait" />}
        </>
      </Form>
    )
  },
)
