import classNames from 'classnames'
import React from 'react'
import { Styled } from '../../utils/Stylable'
import './ChartTooltip.less'

const CARET_SIZE = 8

type ChartTooltipContentProps = Styled<{
  title?: string
  data: {
    label: string
    formattedLabel?: React.ReactNode
    color: string
    value: React.ReactNode
  }[]
}>

export const ChartTooltipContent = React.memo<ChartTooltipContentProps>(
  ({ data, style, className, title }) => (
    <div
      className={classNames('bz-tooltip-body rounded bg-white p-2', className)}
      style={style}
    >
      {title ? (
        <div className="mb-2 text-sm font-semibold text-bz-gray-1000">
          {title}
        </div>
      ) : null}
      {data.map(({ label, color, value, formattedLabel }, i) => (
        <div
          key={label}
          className={classNames(
            'flex flex-row items-start space-x-2 border-0 border-solid',
            {
              'mt-2 border-t border-bz-gray-500 pt-2': i !== 0,
            },
          )}
        >
          <div
            className="h-[14px] w-[14px] rounded-sm"
            style={{
              backgroundColor: color,
            }}
          />
          <div>
            <div className="mb-1 text-sm leading-none text-bz-gray-1000">
              {formattedLabel ?? label}
            </div>
            <div className="text-sm font-semibold text-bz-gray-1000">
              {value}
            </div>
          </div>
        </div>
      ))}
    </div>
  ),
)

type ChartTooltipProps = ChartTooltipContentProps & {
  left: number
  top: number
  caretOnRight?: boolean
  visible?: boolean
}

export const ChartTooltip = React.memo<ChartTooltipProps>(
  ({ left, top, caretOnRight, data, visible }) => {
    return (
      <div
        className={classNames(
          'pointer-events-none fixed z-10 duration-500 ease-in-out',
          visible ? 'transition-all' : 'invisible',
        )}
        style={{
          left: `${left + window.scrollX}px`,
          top: `${top + window.scrollY}px`,
          // Gotta translate -50% in Y to center it with the point we get, and if the caret is on the right, we need to
          // shift to be to the left of it.
          transform: caretOnRight
            ? 'translate(-100%, -50%)'
            : 'translateY(-50%)',
        }}
      >
        <ChartTooltipContent
          data={data}
          style={{
            marginRight: caretOnRight ? `${CARET_SIZE}px` : undefined,
            marginLeft: caretOnRight ? undefined : `${CARET_SIZE}px`,
          }}
        />
        <div
          className={classNames(
            'absolute top-1/2 h-0 w-0 translate-y-[-50%]',
            caretOnRight ? 'right-0' : 'left-0',
          )}
          style={{
            borderTop: `${CARET_SIZE}px solid transparent`,
            borderBottom: `${CARET_SIZE}px solid transparent`,
            [caretOnRight ? 'borderLeft' : 'borderRight']: `${
              CARET_SIZE + 1
            }px solid white`,
          }}
        />
      </div>
    )
  },
)
