import {
  AccountType,
  AccountTypeDisplayNames,
  BzAddress,
  BzDateFns,
  CalculatePaths,
  EquipmentType,
  MaintenancePlanStatus,
  R,
  formatEquipmentType,
  streetAddressLine1And2Condensed,
} from '@breezy/shared'
import {
  faEllipsis,
  faLocationDot,
  faSliders,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Dropdown, Form, Popover } from 'antd'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import classNames from 'classnames'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'urql'
import { z } from 'zod'
import { ContactCell } from '../../adam-components/ListPage/ContactCell'
import { DetailChip } from '../../adam-components/ListPage/DetailChip'
import { DetailsCell } from '../../adam-components/ListPage/DetailsCell'
import { ListPageContainer } from '../../adam-components/ListPage/ListPageContainer'
import { ListPageFilter } from '../../adam-components/ListPage/ListPageFilter'
import { ListPageFilterBar } from '../../adam-components/ListPage/ListPageFilterBar'
import { ListPageFilterButton } from '../../adam-components/ListPage/ListPageFilterButton'
import {
  ListPageTable,
  ListPageTableColsConfig,
  usePagination,
} from '../../adam-components/ListPage/ListPageTable'
import { SummaryStatBox } from '../../adam-components/ListPage/SummaryStats'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { AccountTagRow } from '../../components/AccountTagRow/AccountTagRow'
import { CSModeContext } from '../../components/Admin/CSMode/CSModeWrapper'
import { WhenCSMode } from '../../components/Admin/CSMode/WhenCSMode'
import { EquipmentInfoCard } from '../../components/EquipmentInfoCard/EquipmentInfoCard'
import { Page } from '../../components/Page/Page'
import ProgressiveJobCreationModal from '../../components/ProgressiveJobCreationModal/ProgressiveJobCreationModal'
import {
  QuickbooksSyncAccountButton,
  useIsQboEnabled,
} from '../../components/Quickbooks/QuickbooksSyncButton'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../elements/Forms/SelectField'
import { SwitchField } from '../../elements/Forms/SwitchField'
import { TextField } from '../../elements/Forms/TextField'
import { useReactHookFormSubmit } from '../../elements/Forms/useReactHookFormSubmit'
import { Link } from '../../elements/Link/Link'
import {
  AccountsBoolExp,
  AccountsListPageDataQuery,
  AccountsOrderBy,
  IntComparisonExp,
  TimestamptzComparisonExp,
} from '../../generated/user/graphql'
import { TAGS_MINIMAL_FOR_COMPANY_QUERY } from '../../gql/queries/Tags.gql'
import { useFetchAccountByAccountGuidQuery } from '../../hooks/fetch/useFetchAccountByGuid'
import { trpc } from '../../hooks/trpc'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
  useExpectedPrincipal,
} from '../../providers/PrincipalUser'
import {
  useBooleanState,
  useQueryParamFlag,
  useQueryParamState,
  useQueryParamStateArray,
  useQueryParamStateEnumArray,
  useQueryParamStateWithOptions,
} from '../../utils/react-utils'
import {
  ArchiveAccountModal,
  useArchiveAccountModal,
} from '../AccountDetailsPage/ArchiveAccountModal'
import {
  MaintenancePlanWizard,
  useMaintenancePlanWizardFlags,
} from '../CreateOrEditMaintenancePlanPage/MaintenancePlanWizard'
import {
  ACCOUNTS_LIST_PAGE_COUNT_ACCOUNTS_QUERY,
  ACCOUNTS_LIST_PAGE_DATA_BY_RECENTLY_VIEWED_QUERY,
  ACCOUNTS_LIST_PAGE_DATA_QUERY,
} from './AccountsListPage.gql'

type AccountActionsDropdownProps =
  AccountsListPageDataQuery['accounts'][number] & {
    onAddMaintenancePlan: (accountGuid: string) => void
  }

const AccountActionsDropdown = React.memo<AccountActionsDropdownProps>(
  ({ accountGuid, archived, onAddMaintenancePlan }) => {
    const navigate = useNavigate()

    const { archiveAccount, closeConfirmProps } = useArchiveAccountModal(
      accountGuid,
      archived,
    )

    const [isCreatingNewJob, setIsCreatingNewJob] = useState(false)

    const { data: accountForNewJob, isFetching: isFetchingAccountsForNewJob } =
      useFetchAccountByAccountGuidQuery({
        accountGuid,
        opts: {
          enabled: isCreatingNewJob,
        },
      })

    const isCreatingNewJobOpen = isCreatingNewJob && accountForNewJob

    const dropdownItems = useMemo(() => {
      const items: ItemType[] = [
        {
          key: 'Edit',
          label: (
            <div onClick={() => navigate(`/accounts/${accountGuid}?edit=1`)}>
              Edit
            </div>
          ),
        },
        {
          key: 'Create job',
          label: (
            <div onClick={() => setIsCreatingNewJob(true)}>Create job</div>
          ),
        },
        {
          key: 'Add Maintenance Plan',
          label: (
            <div onClick={() => onAddMaintenancePlan(accountGuid)}>
              Add Maintenance Plan
            </div>
          ),
        },
        {
          type: 'divider',
        },
        {
          key: 'Archive Account',
          label: archived ? 'Unarchive Account' : 'Archive Account',
          danger: !archived,
          onClick: archiveAccount,
        },
      ]

      return items
    }, [accountGuid, archiveAccount, archived, navigate, onAddMaintenancePlan])

    return (
      <>
        <Dropdown
          disabled={isFetchingAccountsForNewJob}
          className="mb-4"
          menu={{
            items: dropdownItems,
          }}
        >
          <Button shape="circle" icon={<FontAwesomeIcon icon={faEllipsis} />} />
        </Dropdown>
        {isCreatingNewJobOpen && accountForNewJob && (
          <ProgressiveJobCreationModal
            isOpen
            setIsOpen={setIsCreatingNewJob}
            selectedAccount={accountForNewJob}
          />
        )}
        <ArchiveAccountModal {...closeConfirmProps} archived={archived} />
      </>
    )
  },
)

const SORT_TYPES = [
  'RECENTLY_VIEWED',
  'NAME',
  'NUM_JOBS',
  'OLDEST_JOB',
  'NEWEST_JOB',
] as const

type SortType = (typeof SORT_TYPES)[number]

const PRETTY_SORT_TYPE_MAP: Record<SortType, string> = {
  RECENTLY_VIEWED: 'Recently Viewed (Most Recent)',
  NAME: 'Account Name (A-Z)',
  NUM_JOBS: 'Number of Jobs (Highest)',
  OLDEST_JOB: 'Last Job (Oldest)',
  NEWEST_JOB: 'Last Job (Newest)',
}

const SORT_TYPE_OPTIONS = SORT_TYPES.map(type => ({
  key: type,
  label: PRETTY_SORT_TYPE_MAP[type],
}))

const LAST_JOB_DATE_TYPES = [
  'UNDER_30_DAYS',
  '1_TO_3_MONTHS',
  '3_TO_6_MONTHS',
  '6_TO_12_MONTHS',
  '12_OR_MORE_MONTHS',
  'ANY',
] as const

type LastJobDateType = (typeof LAST_JOB_DATE_TYPES)[number]

const LAST_JOB_DATE_TO_WHERE_CONDITION: Record<
  LastJobDateType,
  TimestamptzComparisonExp
> = {
  UNDER_30_DAYS: {
    _gte: BzDateFns.nowUtcTransform(date => BzDateFns.subDays(date, 30)),
  },
  '1_TO_3_MONTHS': {
    _gte: BzDateFns.nowUtcTransform(date => BzDateFns.subMonths(date, 3)),
    _lte: BzDateFns.nowUtcTransform(date => BzDateFns.subMonths(date, 1)),
  },
  '3_TO_6_MONTHS': {
    _gte: BzDateFns.nowUtcTransform(date => BzDateFns.subMonths(date, 6)),
    _lte: BzDateFns.nowUtcTransform(date => BzDateFns.subMonths(date, 3)),
  },
  '6_TO_12_MONTHS': {
    _gte: BzDateFns.nowUtcTransform(date => BzDateFns.subMonths(date, 12)),
    _lte: BzDateFns.nowUtcTransform(date => BzDateFns.subMonths(date, 6)),
  },
  '12_OR_MORE_MONTHS': {
    _lte: BzDateFns.nowUtcTransform(date => BzDateFns.subMonths(date, 12)),
  },
  ANY: {},
}

const PRETTY_LAST_JOB_DATE_MAP: Record<LastJobDateType, string> = {
  UNDER_30_DAYS: 'Less than 30 days ago',
  '1_TO_3_MONTHS': '1-3 months ago',
  '3_TO_6_MONTHS': '3-6 months ago',
  '6_TO_12_MONTHS': '6-12 months ago',
  '12_OR_MORE_MONTHS': '12+ months ago',
  ANY: 'Any',
}

const LAST_JOB_DATE_OPTIONS = LAST_JOB_DATE_TYPES.map(type => ({
  value: type,
  label: PRETTY_LAST_JOB_DATE_MAP[type],
}))

const NUM_JOB_TYPES = ['1', '2-4', '5+'] as const

type NumJobsType = (typeof NUM_JOB_TYPES)[number]

const NUM_JOBS_OPTIONS = NUM_JOB_TYPES.map(type => ({
  value: type,
  label: type,
}))

const NUM_LOCATIONS_TYPES = ['1', '2-4', '5+'] as const

type NumLocationsType = (typeof NUM_LOCATIONS_TYPES)[number]

const NUM_LOCATIONS_OPTIONS = NUM_LOCATIONS_TYPES.map(type => ({
  value: type,
  label: type,
}))

const ACCOUNT_TYPES = [AccountType.RESIDENTIAL, AccountType.COMMERCIAL] as const

const ACCOUNT_TYPE_OPTIONS = ACCOUNT_TYPES.map(type => ({
  value: type,
  label: AccountTypeDisplayNames[type],
}))

const EQUIPMENT_TYPE_OPTIONS = Object.values(EquipmentType).map(type => ({
  value: type,
  label: formatEquipmentType(type),
}))

const FILTER_LABELS = {
  showNonMPOnly: 'Membership',
  showArchived: 'Archived',
  accountTypes: 'Account Type',
  tagNames: 'Tags',
  numJobs: 'Number of Jobs',
  numLocations: 'Number of Locations',
  lastJobDate: 'Last Job Date',
  equipmentTypes: 'Equipment Type',
  equipmentModelNumber: 'Equipment Model Number',
  equipmentSerialNumber: 'Equipment Serial Number',
}

const moreFiltersSchema = z.object({
  showNonMPOnly: z.boolean(),
  showArchived: z.boolean(),
  accountTypes: z.array(z.nativeEnum(AccountType)),
  tagNames: z.array(z.string()),
  numJobs: z.array(z.enum(NUM_JOB_TYPES)),
  numLocations: z.array(z.enum(NUM_LOCATIONS_TYPES)),
  lastJobDate: z.enum(LAST_JOB_DATE_TYPES),
  equipmentTypes: z.array(z.nativeEnum(EquipmentType)),
  equipmentModelNumber: z.string(),
  equipmentSerialNumber: z.string(),
})

type MoreFiltersSchema = z.infer<typeof moreFiltersSchema>

const computeHasFilters = ({
  showNonMPOnly,
  showArchived,
  accountTypes,
  tagNames,
  numJobs,
  numLocations,
  lastJobDate,
  equipmentTypes,
  equipmentModelNumber,
  equipmentSerialNumber,
}: MoreFiltersSchema) =>
  showNonMPOnly ||
  showArchived ||
  accountTypes.length +
    tagNames.length +
    numJobs.length +
    numLocations.length +
    equipmentTypes.length +
    (equipmentModelNumber ? 1 : 0) +
    (equipmentSerialNumber ? 1 : 0) +
    (lastJobDate !== 'ANY' ? 1 : 0) >
    0

type Tag = { tagGuid: string; name: string }

type MoreFiltersProps = {
  showNonMPOnly: boolean
  setShowNonMPOnly: (showNonMPOnly: boolean) => void
  showArchived: boolean
  setShowArchived: (showArchived: boolean) => void
  accountTypes: AccountType[]
  setAccountTypes: (accountTypes: AccountType[]) => void
  tagOptions: Tag[]
  tagNames: string[]
  setTagNames: (tagNames: string[]) => void
  numJobs: NumJobsType[]
  setNumJobs: (numJobs: NumJobsType[]) => void
  numLocations: NumLocationsType[]
  setNumLocations: (numLocations: NumLocationsType[]) => void
  lastJobDate: LastJobDateType
  setLastJobDate: (lastJobDate: LastJobDateType) => void
  equipmentTypes: EquipmentType[]
  setEquipmentTypes: (equipmentTypes: EquipmentType[]) => void
  equipmentModelNumber: string
  setEquipmentModelNumber: (equipmentModelNumber: string) => void
  equipmentSerialNumber: string
  setEquipmentSerialNumber: (equipmentSerialNumber: string) => void
}

// TODO: we have a very similar component in InvoiceListPageV2 that isn't using react hooks form. We should prob
// refactor it to do it this way.
const MoreFilters = React.memo<MoreFiltersProps>(
  ({
    showNonMPOnly = false,
    setShowNonMPOnly,
    showArchived = false,
    setShowArchived,
    accountTypes = [],
    setAccountTypes,
    tagOptions,
    tagNames = [],
    setTagNames,
    numJobs = [],
    setNumJobs,
    numLocations = [],
    setNumLocations,
    lastJobDate = 'ANY',
    setLastJobDate,
    equipmentTypes = [],
    setEquipmentTypes,
    equipmentModelNumber = '',
    setEquipmentModelNumber,
    equipmentSerialNumber = '',
    setEquipmentSerialNumber,
  }) => {
    const [moreFiltersOpen, openMoreFilters, closeMoreFilters] =
      useBooleanState()

    const defaultValues = useMemo<MoreFiltersSchema>(
      () => ({
        showNonMPOnly,
        showArchived,
        accountTypes,
        tagNames,
        numJobs,
        numLocations,
        lastJobDate,
        equipmentTypes,
        equipmentModelNumber,
        equipmentSerialNumber,
      }),
      [
        showNonMPOnly,
        showArchived,
        accountTypes,
        tagNames,
        numJobs,
        numLocations,
        lastJobDate,
        equipmentTypes,
        equipmentModelNumber,
        equipmentSerialNumber,
      ],
    )

    const {
      formState: { errors, dirtyFields },
      control,
      handleSubmit,
      reset,
    } = useForm<MoreFiltersSchema>({
      resolver: zodResolver(moreFiltersSchema),
      defaultValues,
    })

    const onOpen = useCallback(() => {
      reset(defaultValues)
      openMoreFilters()
    }, [defaultValues, openMoreFilters, reset])

    const onSubmit = useCallback(
      (data: MoreFiltersSchema) => {
        setShowNonMPOnly(data.showNonMPOnly)
        setShowArchived(data.showArchived)
        setAccountTypes(data.accountTypes)
        setTagNames(data.tagNames)
        setNumJobs(data.numJobs)
        setNumLocations(data.numLocations)
        setLastJobDate(data.lastJobDate)
        setEquipmentTypes(data.equipmentTypes)
        setEquipmentModelNumber(data.equipmentModelNumber)
        setEquipmentSerialNumber(data.equipmentSerialNumber)
        closeMoreFilters()
      },
      [
        closeMoreFilters,
        setAccountTypes,
        setEquipmentModelNumber,
        setEquipmentSerialNumber,
        setEquipmentTypes,
        setLastJobDate,
        setNumJobs,
        setNumLocations,
        setShowArchived,
        setShowNonMPOnly,
        setTagNames,
      ],
    )

    const hasFilters = useMemo(
      () =>
        computeHasFilters({
          showNonMPOnly,
          showArchived,
          accountTypes,
          tagNames,
          numJobs,
          numLocations,
          lastJobDate,
          equipmentTypes,
          equipmentModelNumber,
          equipmentSerialNumber,
        }),
      [
        accountTypes,
        equipmentModelNumber,
        equipmentSerialNumber,
        equipmentTypes,
        lastJobDate,
        numJobs,
        numLocations,
        showArchived,
        showNonMPOnly,
        tagNames,
      ],
    )

    const [areYouSureOpen, openAreYouSure, closeAreYouSure] = useBooleanState()

    const onClose = useCallback(() => {
      if (R.keys(dirtyFields).some(key => dirtyFields[key])) {
        openAreYouSure()
      }
      closeMoreFilters()
    }, [closeMoreFilters, dirtyFields, openAreYouSure])

    const onAreYouSureCancel = useCallback(() => {
      closeAreYouSure()
      openMoreFilters()
    }, [closeAreYouSure, openMoreFilters])

    const onAreYouSureConfirm = useCallback(() => {
      closeAreYouSure()
    }, [closeAreYouSure])

    const [submitElement, triggerSubmit] = useReactHookFormSubmit()

    return (
      <>
        <ListPageFilterButton
          size="large"
          icon={<FontAwesomeIcon icon={faSliders} />}
          onClick={onOpen}
          hasFilters={hasFilters}
        >
          Filters
        </ListPageFilterButton>
        <OnsiteConfirmModal
          open={areYouSureOpen}
          danger
          header="Discard changes?"
          onCancel={onAreYouSureCancel}
          onConfirm={onAreYouSureConfirm}
          confirmText="Discard Changes"
        >
          The filters that you've set will not be applied unless you select
          "Show Results". Are you sure you want to close without applying the
          selected filters?
        </OnsiteConfirmModal>
        <OnsiteConfirmModal
          open={moreFiltersOpen}
          size="large-width"
          header="Filters"
          onCancel={onClose}
          onConfirm={triggerSubmit}
          confirmText="Show Results"
          modalClassName="h-[90%]"
        >
          <Form
            className="space-y-6"
            layout="vertical"
            onSubmitCapture={handleSubmit(onSubmit)}
          >
            <div className="flex flex-row items-center rounded-md border border-solid border-bz-gray-500 px-3 py-[3px]">
              <div className="mr-4 flex min-w-0 flex-1">
                Show Non-Maintenance Plan Members Only
              </div>
              <ReactHookFormItem
                noBottomMargin
                control={control}
                name="showNonMPOnly"
                errors={errors}
                render={({ field }) => <SwitchField {...field} />}
              />
            </div>
            <div className="flex flex-row items-center rounded-md border border-solid border-bz-gray-500 px-3 py-[3px]">
              <div className="mr-4 flex min-w-0 flex-1">
                Show archived accounts
              </div>
              <ReactHookFormItem
                noBottomMargin
                control={control}
                name="showArchived"
                errors={errors}
                render={({ field }) => <SwitchField {...field} />}
              />
            </div>
            <ReactHookFormItem
              control={control}
              name="accountTypes"
              label="Account Types"
              errors={errors}
              render={({ field }) => (
                <SelectField
                  mode="multiple"
                  title="Account Types"
                  options={ACCOUNT_TYPE_OPTIONS}
                  sheetSize="half"
                  placeholder="Choose account types"
                  {...field}
                  values={field.value}
                />
              )}
            />
            <ReactHookFormItem
              control={control}
              name="tagNames"
              label="Tags"
              errors={errors}
              render={({ field }) => (
                <SelectField
                  mode="multiple"
                  title="Tags"
                  options={tagOptions.map(tag => ({
                    label: tag.name,
                    value: tag.name,
                  }))}
                  sheetSize="half"
                  placeholder="Choose account types"
                  {...field}
                  values={field.value}
                />
              )}
            />
            <ReactHookFormItem
              control={control}
              name="numJobs"
              label="Number of Jobs"
              errors={errors}
              render={({ field }) => (
                <SelectField
                  mode="multiple"
                  title="Number of Jobs"
                  options={NUM_JOBS_OPTIONS}
                  sheetSize="half"
                  placeholder="Choose the number of jobs"
                  {...field}
                  values={field.value}
                />
              )}
            />
            <ReactHookFormItem
              control={control}
              name="numLocations"
              label="Number of Locations"
              errors={errors}
              render={({ field }) => (
                <SelectField
                  mode="multiple"
                  title="Number of Locations"
                  options={NUM_LOCATIONS_OPTIONS}
                  placeholder="Choose the number of locations"
                  sheetSize="half"
                  {...field}
                  values={field.value}
                />
              )}
            />
            <ReactHookFormItem
              control={control}
              name="lastJobDate"
              label="Last Job Date"
              errors={errors}
              render={({ field }) => (
                <SelectField
                  options={LAST_JOB_DATE_OPTIONS}
                  title="Last Job Date"
                  sheetSize="half"
                  {...field}
                />
              )}
            />
            <ReactHookFormItem
              control={control}
              name="equipmentTypes"
              label="Equipment Type"
              errors={errors}
              render={({ field }) => (
                <SelectField
                  mode="multiple"
                  sheetSize="full"
                  options={EQUIPMENT_TYPE_OPTIONS}
                  title="Equipment Type"
                  placeholder="Choose equipment types"
                  {...field}
                  values={field.value}
                />
              )}
            />

            <ReactHookFormItem
              control={control}
              name="equipmentModelNumber"
              label="Equipment Model Number"
              errors={errors}
              render={({ field }) => (
                <TextField placeholder="Enter a model number" {...field} />
              )}
            />
            <ReactHookFormItem
              control={control}
              name="equipmentSerialNumber"
              label="Equipment Serial Number"
              errors={errors}
              render={({ field }) => (
                <TextField placeholder="Enter a serial number" {...field} />
              )}
            />

            {submitElement}
          </Form>
        </OnsiteConfirmModal>
      </>
    )
  },
)

type Equipment =
  AccountsListPageDataQuery['accounts'][number]['accountLocations'][number]['location']['installedEquipment'][number]

type EquipmentCardProps = {
  equipment: Equipment
}

const EquipmentCard = React.memo<EquipmentCardProps>(({ equipment }) => {
  const {
    equipmentType,
    manufacturer,
    installationDate,
    modelNumber,
    serialNumber,
    estimatedEndOfLifeDate,
  } = equipment
  return (
    <EquipmentInfoCard
      equipmentType={equipmentType as EquipmentType}
      manufacturer={manufacturer}
      installDate={installationDate}
      modelNumber={modelNumber}
      serialNumber={serialNumber}
      estimatedEndOfLifeDate={estimatedEndOfLifeDate}
    />
  )
})

type EquipmentCellProps = {
  equipment: Equipment[]
}

const EquipmentCell = React.memo<EquipmentCellProps>(({ equipment }) => {
  if (!equipment.length) {
    return null
  }

  return (
    <div className="flex flex-row space-x-2">
      <DetailChip
        className="max-w-[205px] *:truncate"
        popoverContent={<EquipmentCard equipment={equipment[0]} />}
      >
        {formatEquipmentType(equipment[0].equipmentType as EquipmentType)}
      </DetailChip>
      {equipment.length > 1 && (
        <DetailChip
          square
          popoverPlacement="left"
          popoverContent={
            <div className="flex flex-col space-y-2">
              {equipment.slice(1).map(equipment => (
                <EquipmentCard
                  equipment={equipment}
                  key={equipment.installedEquipmentGuid}
                />
              ))}
            </div>
          }
        >
          +{equipment.length - 1}
        </DetailChip>
      )}
    </div>
  )
})
const STALE_WHERE_CLAUSE: AccountsBoolExp = {
  jobsAggregate: {
    count: {
      filter: {
        createdAt: {
          _lt: BzDateFns.nowUtcTransform(date => BzDateFns.subYears(date, 1)),
        },
      },
      predicate: {
        _gt: 0,
      },
    },
  },
}

export const AccountsListPage = React.memo(() => {
  const companyGuid = useExpectedCompanyGuid()
  const tzId = useExpectedCompanyTimeZoneId()
  const { userGuid } = useExpectedPrincipal()

  const { page, pageSize, setPage, setPageSize, resetPage, limit, offset } =
    usePagination('50')

  const [searchTerm, setSearchTerm] = useQueryParamState('search', '')
  const [addMaintenancePlanAccountGuid, setAddMaintenancePlanAccountGuid] =
    useState<string | undefined>(undefined)
  const [showNonMPOnly, , setShowNonMPOnlyOff, setShowNonMPOnly] =
    useQueryParamFlag('nonMPOnly')

  const [showArchived, , setShowArchivedOff, setShowArchived] =
    useQueryParamFlag('archived')

  const [accountTypes, setAccountTypes] = useQueryParamStateEnumArray(
    'types',
    [],
    ACCOUNT_TYPES,
  )

  const [tagNames, setTagNames] = useQueryParamStateArray('tags', [])

  const [numJobs, setNumJobs] = useQueryParamStateEnumArray(
    'numJobs',
    [],
    NUM_JOB_TYPES,
  )

  const [numLocations, setNumLocations] = useQueryParamStateEnumArray(
    'numLocations',
    [],
    NUM_LOCATIONS_TYPES,
  )

  const [lastJobDate, setLastJobDate] = useQueryParamStateWithOptions(
    'lastJob',
    'ANY' as const,
    LAST_JOB_DATE_TYPES,
  )

  const [equipmentTypes, setEquipmentTypes] = useQueryParamStateEnumArray(
    'equipment',
    [],
    Object.values(EquipmentType) as [EquipmentType],
  )

  const [modelNumber, setModelNumber] = useQueryParamState('model', '')
  const [serialNumber, setSerialNumber] = useQueryParamState('serial', '')

  const [sortType, setSortTypeRaw] = useQueryParamStateWithOptions(
    'sort',
    SORT_TYPES[0],
    SORT_TYPES,
  )

  const setSortType = useCallback(
    (type: SortType) => {
      setSortTypeRaw(type)
      resetPage()
    },
    [resetPage, setSortTypeRaw],
  )

  const [{ data: tagOptionsData, fetching: fetchingTagOptions }] = useQuery({
    query: TAGS_MINIMAL_FOR_COMPANY_QUERY,
    variables: { companyGuid },
  })

  const tagsByNameMap = useMemo(
    () => R.indexBy(R.prop('name'), tagOptionsData?.tags ?? []),
    [tagOptionsData?.tags],
  )

  const orderByClause = useMemo<AccountsOrderBy>(() => {
    switch (sortType) {
      case 'RECENTLY_VIEWED':
        return {}
      case 'NAME':
        return { accountDisplayName: 'ASC' }
      case 'NUM_JOBS':
        return { jobsAggregate: { count: 'DESC' } }
      case 'OLDEST_JOB':
        return { lastJobDate: 'ASC' }
      case 'NEWEST_JOB':
        return { lastJobDate: 'DESC' }
    }
  }, [sortType])

  const whereClause = useMemo<AccountsBoolExp>(() => {
    if (searchTerm) {
      const _ilike = `%${searchTerm}%`

      return {
        _or: [
          {
            accountDisplayName: { _ilike },
          },
          {
            accountType: { _ilike },
          },
          {
            accountContacts: {
              contact: {
                fullName: { _ilike },
              },
            },
          },
          {
            accountLocations: {
              location: {
                address: {
                  canonicalFullAddress: { _ilike },
                },
              },
            },
          },
          {
            tags: {
              tag: {
                name: { _ilike },
              },
            },
          },
          {
            maintenancePlans: {
              status: {
                _eq: MaintenancePlanStatus.ACTIVE,
              },
              maintenancePlanDefinition: {
                marketingInfo: {
                  name: { _ilike },
                },
              },
            },
          },
          {
            accountLocations: {
              location: {
                installedEquipment: {
                  equipmentType: { _ilike },
                },
              },
            },
          },
          {
            accountLocations: {
              location: {
                installedEquipment: {
                  modelNumber: { _ilike },
                },
              },
            },
          },
          {
            accountLocations: {
              location: {
                installedEquipment: {
                  serialNumber: { _ilike },
                },
              },
            },
          },
        ],
      }
    }
    const whereClauses: AccountsBoolExp[] = []
    if (showNonMPOnly) {
      whereClauses.push({
        maintenancePlansAggregate: {
          count: {
            predicate: {
              _eq: 0,
            },
          },
        },
      })
    }
    if (!showArchived) {
      whereClauses.push({
        archived: {
          _eq: false,
        },
      })
    }
    if (accountTypes.length) {
      whereClauses.push({ accountType: { _in: accountTypes } })
    }
    if (tagNames.length) {
      whereClauses.push({
        tags: {
          tag: {
            tagGuid: {
              _in: tagNames
                .map(name => tagsByNameMap[name]?.tagGuid)
                .filter(Boolean),
            },
          },
        },
      })
    }
    if (numJobs.length) {
      const predicates: IntComparisonExp[] = []

      if (numJobs.includes('1')) {
        predicates.push({
          _eq: 1,
        })
      }
      if (numJobs.includes('2-4')) {
        predicates.push({
          _gte: 2,
          _lte: 5,
        })
      }
      if (numJobs.includes('5+')) {
        predicates.push({
          _gte: 5,
        })
      }

      whereClauses.push({
        _or: predicates.map(predicate => ({
          jobsAggregate: {
            count: {
              predicate,
            },
          },
        })),
      })
    }
    if (numLocations.length) {
      const predicates: IntComparisonExp[] = []

      if (numLocations.includes('1')) {
        predicates.push({
          _eq: 1,
        })
      }
      if (numLocations.includes('2-4')) {
        predicates.push({
          _gte: 2,
          _lte: 5,
        })
      }
      if (numLocations.includes('5+')) {
        predicates.push({
          _gte: 5,
        })
      }

      whereClauses.push({
        _or: predicates.map(predicate => ({
          accountLocationsAggregate: {
            count: {
              predicate,
            },
          },
        })),
      })
    }
    if (lastJobDate !== 'ANY') {
      whereClauses.push({
        lastJobDate: LAST_JOB_DATE_TO_WHERE_CONDITION[lastJobDate],
      })
    }

    if (equipmentTypes.length) {
      whereClauses.push({
        accountLocations: {
          location: {
            installedEquipment: { equipmentType: { _in: equipmentTypes } },
          },
        },
      })
    }
    if (modelNumber) {
      whereClauses.push({
        accountLocations: {
          location: {
            installedEquipment: { modelNumber: { _ilike: `%${modelNumber}%` } },
          },
        },
      })
    }
    if (serialNumber) {
      whereClauses.push({
        accountLocations: {
          location: {
            installedEquipment: {
              serialNumber: { _ilike: `%${serialNumber}%` },
            },
          },
        },
      })
    }

    return {
      _and: whereClauses,
    }
  }, [
    accountTypes,
    equipmentTypes,
    lastJobDate,
    modelNumber,
    numJobs,
    numLocations,
    searchTerm,
    serialNumber,
    showArchived,
    showNonMPOnly,
    tagNames,
    tagsByNameMap,
  ])

  const [{ data: normalData, fetching: fetchingNormal }, refetchNormal] =
    useQuery({
      query: ACCOUNTS_LIST_PAGE_DATA_QUERY,
      pause: sortType === 'RECENTLY_VIEWED',
      variables: {
        limit,
        offset,
        orderBy: orderByClause,
        where: whereClause,
      },
    })

  const [
    { data: byRecentlyViewedData, fetching: fetchingByRecentlyViewed },
    refetchByRecentlyViewed,
  ] = useQuery({
    query: ACCOUNTS_LIST_PAGE_DATA_BY_RECENTLY_VIEWED_QUERY,
    pause: sortType !== 'RECENTLY_VIEWED',
    variables: {
      limit,
      offset,
      where: whereClause,
      companyGuid,
      userGuid,
    },
  })

  const data =
    sortType === 'RECENTLY_VIEWED' ? byRecentlyViewedData : normalData
  const fetching =
    sortType === 'RECENTLY_VIEWED' ? fetchingByRecentlyViewed : fetchingNormal

  const filters = useMemo(() => {
    const filters: { key: string; optionsSelected: string[] }[] = []
    if (showNonMPOnly) {
      filters.push({
        key: 'showNonMPOnly',
        optionsSelected: ['No'],
      })
    }
    if (showArchived) {
      filters.push({
        key: 'showArchived',
        optionsSelected: ['Shown'],
      })
    }
    if (accountTypes.length) {
      filters.push({
        key: 'accountTypes',
        optionsSelected: accountTypes.map(
          accountType => AccountTypeDisplayNames[accountType],
        ),
      })
    }
    if (tagNames.length) {
      filters.push({
        key: 'tagNames',
        optionsSelected: tagNames,
      })
    }
    if (numJobs.length) {
      filters.push({
        key: 'numJobs',
        optionsSelected: numJobs,
      })
    }
    if (numLocations.length) {
      filters.push({
        key: 'numLocations',
        optionsSelected: numLocations,
      })
    }
    if (lastJobDate !== 'ANY') {
      filters.push({
        key: 'lastJobDate',
        optionsSelected: [PRETTY_LAST_JOB_DATE_MAP[lastJobDate]],
      })
    }
    if (equipmentTypes.length) {
      filters.push({
        key: 'equipmentTypes',
        optionsSelected: equipmentTypes.map(formatEquipmentType),
      })
    }
    if (modelNumber) {
      filters.push({
        key: 'equipmentModelNumber',
        optionsSelected: [modelNumber],
      })
    }
    if (serialNumber) {
      filters.push({
        key: 'equipmentSerialNumber',
        optionsSelected: [serialNumber],
      })
    }

    return filters
  }, [
    accountTypes,
    equipmentTypes,
    lastJobDate,
    modelNumber,
    numJobs,
    numLocations,
    serialNumber,
    showArchived,
    showNonMPOnly,
    tagNames,
  ])

  const hasFilters = useMemo(
    () =>
      computeHasFilters({
        showNonMPOnly,
        showArchived,
        accountTypes,
        tagNames,
        numJobs,
        numLocations,
        lastJobDate,
        equipmentTypes,
        equipmentModelNumber: modelNumber,
        equipmentSerialNumber: serialNumber,
      }),
    [
      accountTypes,
      equipmentTypes,
      lastJobDate,
      modelNumber,
      numJobs,
      numLocations,
      serialNumber,
      showArchived,
      showNonMPOnly,
      tagNames,
    ],
  )

  const clearAllFilters = useCallback(() => {
    setShowNonMPOnlyOff()
    setShowArchivedOff()
    setAccountTypes([])
    setTagNames([])
    setNumJobs([])
    setNumLocations([])
    setLastJobDate('ANY')
    setEquipmentTypes([])
    setModelNumber('')
    setSerialNumber('')
  }, [
    setAccountTypes,
    setEquipmentTypes,
    setLastJobDate,
    setModelNumber,
    setNumJobs,
    setNumLocations,
    setSerialNumber,
    setShowArchivedOff,
    setShowNonMPOnlyOff,
    setTagNames,
  ])

  const clearFilter = useCallback(
    (filterKey: string, option: string) => {
      switch (filterKey) {
        case 'showNonMPOnly':
          setShowNonMPOnlyOff()
          break
        case 'showArchived':
          setShowArchivedOff()
          break
        case 'accountTypes':
          setAccountTypes(
            accountTypes.filter(
              accountType => AccountTypeDisplayNames[accountType] !== option,
            ),
          )
          break
        case 'tagNames':
          setTagNames(tagNames.filter(name => name !== option))
          break
        case 'numJobs':
          setNumJobs(numJobs.filter(numJobValue => numJobValue !== option))
          break
        case 'numLocations':
          setNumLocations(
            numLocations.filter(
              numLocationsValue => numLocationsValue !== option,
            ),
          )
          break
        case 'lastJobDate':
          setLastJobDate('ANY')
          break
        case 'equipmentTypes':
          setEquipmentTypes(
            equipmentTypes.filter(
              equipmentType => formatEquipmentType(equipmentType) !== option,
            ),
          )
          break
        case 'equipmentModelNumber':
          setModelNumber('')
          break
        case 'equipmentSerialNumber':
          setSerialNumber('')
          break
      }
    },
    [
      accountTypes,
      equipmentTypes,
      numJobs,
      numLocations,
      setAccountTypes,
      setEquipmentTypes,
      setLastJobDate,
      setModelNumber,
      setNumJobs,
      setNumLocations,
      setSerialNumber,
      setShowArchivedOff,
      setShowNonMPOnlyOff,
      setTagNames,
      tagNames,
    ],
  )

  const isQboEnabled = useIsQboEnabled()
  const { isCSMode } = useContext(CSModeContext)

  const qboStaleAccountsQuery = trpc.qbo[
    'finance-app:get-stale-accounts'
  ].useQuery(
    {},
    {
      enabled: isQboEnabled,
    },
  )

  const totalItems = data?.accountsAggregate.aggregate?.count ?? 0

  const [{ data: mpCountData, fetching: fetchingMpCountData }, refetchMpCount] =
    useQuery({
      query: ACCOUNTS_LIST_PAGE_COUNT_ACCOUNTS_QUERY,
      variables: {
        where: {
          _and: [
            whereClause,
            {
              maintenancePlansAggregate: {
                count: {
                  predicate: {
                    _gt: 0,
                  },
                },
              },
            },
          ],
        },
      },
    })

  const [
    { data: staleCountData, fetching: fetchingStaleCountData },
    refetchStaleCount,
  ] = useQuery({
    query: ACCOUNTS_LIST_PAGE_COUNT_ACCOUNTS_QUERY,
    variables: {
      where: {
        _and: [whereClause, STALE_WHERE_CLAUSE],
      },
    },
  })

  const [
    { data: repeatCountData, fetching: fetchingRepeatCountData },
    refetchRepeatCount,
  ] = useQuery({
    query: ACCOUNTS_LIST_PAGE_COUNT_ACCOUNTS_QUERY,
    variables: {
      where: {
        _and: [
          whereClause,
          {
            jobsAggregate: {
              count: {
                predicate: {
                  _gte: 2,
                },
              },
            },
          },
        ],
      },
    },
  })

  const refetch = useCallback(() => {
    refetchNormal()
    refetchByRecentlyViewed()
    refetchMpCount()
    refetchStaleCount()
    refetchRepeatCount()
  }, [
    refetchNormal,
    refetchByRecentlyViewed,
    refetchMpCount,
    refetchStaleCount,
    refetchRepeatCount,
  ])

  const [
    maintenancePlanWizardOpen,
    openMaintenancePlanWizard,
    closeMaintenancePlanWizard,
  ] = useMaintenancePlanWizardFlags('mpw', 'accounts-list-page')

  const onAddMaintenancePlan = useCallback(
    (accountGuid: string) => {
      setAddMaintenancePlanAccountGuid(accountGuid)
      openMaintenancePlanWizard()
    },
    [openMaintenancePlanWizard, setAddMaintenancePlanAccountGuid],
  )

  const onCloseMpWizard = useCallback(() => {
    closeMaintenancePlanWizard()
    setAddMaintenancePlanAccountGuid(undefined)
    refetch()
  }, [closeMaintenancePlanWizard, refetch])

  const cols = useMemo(() => {
    const cols: ListPageTableColsConfig<
      AccountsListPageDataQuery['accounts'][number]
    > = [
      {
        header: 'Details',
        render: account => {
          const {
            accountGuid,
            accountDisplayName,
            accountType,
            accountLocations,
            maintenancePlans,
            tags,
            archived,
          } = account

          // Note: we are ordering by created date, so we're considering the first one the "primary location"
          const primaryLocation = accountLocations[0]

          const maintenancePlansLite = maintenancePlans.map(plan => ({
            name:
              plan.maintenancePlanDefinition?.marketingInfo?.name ??
              'Missing Name',
            color: plan.maintenancePlanDefinition?.flare?.primaryColorHex,
          }))

          const accountDetailsLink = CalculatePaths.accountDetails({
            accountGuid,
          })

          const flatTags = tags.flatMap(tag => tag.tag.name)

          const detailItems: React.ReactNode[] = [
            <Link blue={false} bold={false} to={accountDetailsLink}>
              {AccountTypeDisplayNames[accountType]}
            </Link>,
          ]

          if (accountLocations.length > 1) {
            detailItems.push(
              <Popover
                overlayClassName="detail-chip-popover"
                content={
                  <div className="max-h-56 space-y-2 overflow-auto p-3">
                    {accountLocations.map(({ locationGuid, location }) => (
                      <DetailChip
                        key={locationGuid}
                        icon={<FontAwesomeIcon icon={faLocationDot} />}
                      >
                        {BzAddress.formatAddressSingleLine(location.address)}
                      </DetailChip>
                    ))}
                  </div>
                }
              >
                <span>
                  Multiple Locations{' '}
                  <span className="text-bz-gray-700">
                    ({accountLocations.length})
                  </span>
                </span>
              </Popover>,
            )
          } else if (primaryLocation) {
            // Note: I don't think it's possible for an account not to have at least one location, so this could
            // probably safely have been an "else" not an "else if" but I guess better safe than sorry.
            const address = streetAddressLine1And2Condensed(
              primaryLocation.location.address.line1,
              primaryLocation.location.address.line2,
            )
            detailItems.push(
              <Link
                blue={false}
                bold={false}
                to={CalculatePaths.locationDetails({
                  locationGuid: primaryLocation.locationGuid,
                })}
              >
                {address}
              </Link>,
            )
          }

          return (
            <DetailsCell
              dropdown={
                <AccountActionsDropdown
                  {...account}
                  onAddMaintenancePlan={onAddMaintenancePlan}
                />
              }
              disabledTagContent={archived ? 'Archived' : undefined}
              link={{
                to: accountDetailsLink,
                label: accountDisplayName,
              }}
              detailItems={detailItems}
              footer={
                <AccountTagRow
                  maintenancePlans={maintenancePlansLite}
                  tags={flatTags}
                />
              }
            />
          )
        },
      },

      {
        header: 'Contact',
        render: account => (
          <span
            className={classNames({
              'opacity-60': account.archived,
            })}
          >
            <ContactCell account={account} />
          </span>
        ),
      },
      {
        header: '# of Jobs',
        render: ({ archived, jobsAggregate }) => (
          <span
            className={classNames({
              'opacity-60': archived,
            })}
          >
            {jobsAggregate.aggregate?.count ?? 0}
          </span>
        ),
      },
      // TODO: LTV
      {
        header: 'Last Job',
        render: ({ archived, lastJobDate }) => {
          return (
            <span
              className={classNames({
                'opacity-60': archived,
              })}
            >
              {lastJobDate ? (
                BzDateFns.format(
                  BzDateFns.parseISO(lastJobDate, tzId),
                  'MMM d, yyyy',
                )
              ) : (
                <span className="text-bz-gray-700">-</span>
              )}
            </span>
          )
        },
      },
      {
        header: 'Equipment',
        render: ({ archived, accountLocations }) => {
          const equipment = R.flatten(
            accountLocations.map(({ location }) => location.installedEquipment),
          )
          return (
            <span
              className={classNames({
                'opacity-60': archived,
              })}
            >
              <EquipmentCell equipment={equipment} />
            </span>
          )
        },
      },
    ]

    if (isQboEnabled && isCSMode) {
      cols.push({
        header: 'QBO',
        render: ({ accountGuid }) => (
          <WhenCSMode>
            <QuickbooksSyncAccountButton
              placement="left"
              size="sm"
              params={{ accountGuid }}
              loading={qboStaleAccountsQuery.isLoading}
              staleInfo={qboStaleAccountsQuery.data?.[accountGuid]}
              onSuccess={qboStaleAccountsQuery.refetch}
            />
          </WhenCSMode>
        ),
      })
    }
    return cols
  }, [
    isCSMode,
    isQboEnabled,
    qboStaleAccountsQuery.data,
    qboStaleAccountsQuery.isLoading,
    qboStaleAccountsQuery.refetch,
    onAddMaintenancePlan,
    tzId,
  ])

  const summaryStatsLoading =
    fetching ||
    fetchingMpCountData ||
    fetchingStaleCountData ||
    fetchingRepeatCountData ||
    fetchingTagOptions

  return (
    <Page requiresCompanyUser className="flex min-w-[1024px] overflow-auto">
      <ListPageContainer
        summaryStats={
          <>
            <SummaryStatBox
              label="Total Accounts"
              loading={summaryStatsLoading}
              tooltipContent="The total number of accounts."
            >
              {totalItems}
            </SummaryStatBox>
            <SummaryStatBox
              label="Membership Plan Rate"
              loading={summaryStatsLoading}
              tooltipContent="The percentage of accounts containing a service location with a maintenance plan."
            >
              {Math.round(
                (100 * (mpCountData?.accountsAggregate.aggregate?.count ?? 0)) /
                  (totalItems > 0 ? totalItems : 1),
              )}
              %
            </SummaryStatBox>
            <SummaryStatBox
              label="Stale Accounts"
              loading={summaryStatsLoading}
              tooltipContent="Customers who haven't had a job in the last 12 months."
            >
              {staleCountData?.accountsAggregate.aggregate?.count ?? 0}
            </SummaryStatBox>
            <SummaryStatBox
              label="Repeat Service Rate"
              loading={summaryStatsLoading}
              tooltipContent="The percentage of accounts with more than one job on record."
            >
              {Math.round(
                (100 *
                  (repeatCountData?.accountsAggregate.aggregate?.count ?? 0)) /
                  (totalItems > 0 ? totalItems : 1),
              )}
              %
            </SummaryStatBox>
          </>
        }
        filterBar={
          <ListPageFilterBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            totalItems={totalItems}
            sortByControl={
              <ListPageFilter
                label="Sort by"
                options={SORT_TYPE_OPTIONS}
                value={sortType}
                onChange={setSortType}
              />
            }
            hasFilters={hasFilters}
            filters={filters}
            filterLabels={FILTER_LABELS}
            clearAllFilters={clearAllFilters}
            clearFilter={clearFilter}
          >
            <MoreFilters
              showNonMPOnly={showNonMPOnly}
              setShowNonMPOnly={setShowNonMPOnly}
              showArchived={showArchived}
              setShowArchived={setShowArchived}
              accountTypes={accountTypes}
              setAccountTypes={setAccountTypes}
              tagOptions={tagOptionsData?.tags ?? []}
              tagNames={tagNames}
              setTagNames={setTagNames}
              numJobs={numJobs}
              setNumJobs={setNumJobs}
              numLocations={numLocations}
              setNumLocations={setNumLocations}
              lastJobDate={lastJobDate}
              setLastJobDate={setLastJobDate}
              equipmentTypes={equipmentTypes}
              setEquipmentTypes={setEquipmentTypes}
              equipmentModelNumber={modelNumber}
              setEquipmentModelNumber={setModelNumber}
              equipmentSerialNumber={serialNumber}
              setEquipmentSerialNumber={setSerialNumber}
            />
          </ListPageFilterBar>
        }
        table={
          <ListPageTable
            autoSize
            data={data?.accounts}
            fetching={fetching}
            cols={cols}
            rowKey="accountGuid"
            page={page}
            pageSize={pageSize}
            totalItems={totalItems}
            setPage={setPage}
            setPageSize={setPageSize}
            hasFilters={hasFilters || !!searchTerm}
            itemsDescriptor="accounts"
          />
        }
      />
      {maintenancePlanWizardOpen && addMaintenancePlanAccountGuid && (
        <MaintenancePlanWizard
          onRamp="accounts-list-page"
          accountGuid={addMaintenancePlanAccountGuid}
          onClose={onCloseMpWizard}
        />
      )}
    </Page>
  )
})
