import Papa from 'papaparse'
import { fromCamelCaseToPascalCase, withSpacesBetweenWords } from './string-utils'

export const CSV_DELIMITER = ','

export const createCsvFile = <T extends Record<string, unknown>>(firstRow: T, csvRows?: string) => {
  const csvHeader = Object.keys(firstRow)
    .map(key => withSpacesBetweenWords(fromCamelCaseToPascalCase(key)))
    .join(CSV_DELIMITER)

  return `${csvHeader}\r\n${csvRows}`
}

export const convertDataToCsvRows = <T extends Record<string, unknown>>(data: T[]) => {
  if (data.length < 1) {
    console.error('No data to export to CSV')
    return
  }
  const csvRows = Papa.unparse(data, {
    delimiter: CSV_DELIMITER,
    header: false,
  })

  return csvRows
}

export const convertCsvRowsToDataWithHeader = <T extends Record<string, unknown>>(
  csvRows: string,
  headers: (keyof T)[],
) => {
  const parsedData = Papa.parse<T>(csvRows, {
    delimiter: CSV_DELIMITER,
    header: true,
    dynamicTyping: true,
    transformHeader: (_, i) => headers[i] as string,
    skipEmptyLines: true,
  })

  if (parsedData.errors.length) {
    console.error('Errors parsing CSV data:', parsedData.errors)
    return []
  }

  return parsedData.data
}
