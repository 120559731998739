import { Dfns, R, bzExpect } from '@breezy/shared'
import { faUser } from '@fortawesome/pro-light-svg-icons'
import { Col, Image, Row } from 'antd'
import { useParams } from 'react-router-dom'
import ContactRowCircleButtons from '../../components/Contacts/ContactRowCircleButtons'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Page } from '../../components/Page/Page'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import CardIconTitleHeader from '../../elements/CardIconTitleHeader/CardIconTitleHeader'
import LabeledItem from '../../elements/LabeledItem/LabeledItem'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'
import { trpc } from '../../hooks/trpc'

const UserDetailsPage = () => {
  const profilePicUrl = '/img/user-photo-placeholder.jpg'
  const userGuid = bzExpect(
    useParams().userGuid,
    'userGuid',
    'User Guid is required',
  )
  const query = trpc.user['users:get-by-guid'].useQuery({ userGuid })

  return (
    <TrpcQueryLoader
      query={query}
      errorMessage={`Failed to load user by ID: ${userGuid}`}
      loadingComponent={
        <Page requiresCompanyUser={true}>
          <div style={{ width: 366 }}>
            <ScrollCard>
              <LoadingSpinner />
            </ScrollCard>
          </div>
        </Page>
      }
      render={({ user }) => (
        <Page requiresCompanyUser>
          <Row>
            <div style={{ width: 366 }}>
              <ScrollCard>
                <Col>
                  <CardIconTitleHeader
                    icon={faUser}
                    title="User Details"
                    className="mb-4"
                  />

                  {user.deactivatedAt && (
                    <h1 className="text-red-500">
                      User was deactivated on{' '}
                      {R.pipe(
                        Dfns.parseISO,
                        Dfns.format('MMMM d, yyyy'),
                      )(user.deactivatedAt)}
                    </h1>
                  )}

                  <Row className="center-children-vh my-2">
                    <Col>
                      <Image
                        width={200}
                        height={200}
                        src={profilePicUrl}
                        preview={false}
                      />
                    </Col>
                  </Row>
                  <Row className="flex-between my-2">
                    <LabeledItem title="Name">
                      <div className="semibold_14_22">
                        {user.firstName} {user.lastName}
                      </div>
                    </LabeledItem>
                    <div className="center-children-vh">
                      <ContactRowCircleButtons
                        contact={{
                          email: user.emailAddress,
                          phone: {
                            number: user.phoneNumbers[0].phoneNumber,
                            type: user.phoneNumbers[0].type,
                          },
                        }}
                      />
                    </div>
                  </Row>
                  <Row className="my-2">
                    <LabeledItem title="Roles">
                      <div>
                        {user.roles.length === 0 && (
                          <div className="semibold_14_22">No Roles</div>
                        )}
                        {user.roles.length > 0 &&
                          user.roles.map((role, index) => (
                            <div className="semibold_14_22" key={index}>
                              {role.name}
                            </div>
                          ))}
                      </div>
                    </LabeledItem>
                  </Row>
                </Col>
              </ScrollCard>
            </div>
          </Row>
        </Page>
      )}
    />
  )
}

export default UserDetailsPage
