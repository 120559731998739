import { LeadSourcesList } from './LeadSourcesList'

export type ArchivedLeadSourcesProps = {
  leadSources: {
    leadSourceGuid: string
    leadSourceType: string
    name: string
    count: number
    ordinal: string
  }[]
  onEditLeadSourceClicked: (leadSourceGuid: string) => void
}

export const ArchivedLeadSources = ({
  leadSources,
  onEditLeadSourceClicked,
}: ArchivedLeadSourcesProps) => {
  return (
    <div className="flex flex-col gap-2">
      <LeadSourcesList
        leadSources={leadSources
          .sort((a, b) => a.ordinal.localeCompare(b.ordinal))
          .map((leadSource, idx) => ({
            leadSourceGuid: leadSource.leadSourceGuid,
            count: leadSource.count,
            leadSourceType: leadSource.leadSourceType,
            name: leadSource.name,
            listOrder: idx + 1,
          }))}
        onEditLeadSourceClicked={onEditLeadSourceClicked}
      />
    </div>
  )
}
