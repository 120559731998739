import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AdvancedMarker,
  AdvancedMarkerProps,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import RenderIf from '../../../elements/RenderIf/RenderIf'
import tailwindConfig from '../../../tailwind.config'
import AdvancedMarkerPopup from '../AdvancedMarkerPopup/AdvancedMarkerPopup'
import MapPin from '../MapPin/MapPin'

type BzMarkerState = 'active' | 'background' | 'default' | 'hidden'
/* A config for defining colors based on the marker state */
type MarkerStateColorConfig = {
  [K in BzMarkerState]?: string
}

type BzMapPinConfig = {
  /* If you want to render a custom react node as the pin content use this prop */
  pinContent?: React.ReactNode
  /* The config for the color of the pin content */
  pinColorConfig: MarkerStateColorConfig
  /* Renders a font awesome icon as the pin content */
  pinIcon?: IconProp
  /* The config for the color of the pin icon if one is passed in*/
  pinIconColorConfig?: MarkerStateColorConfig
}

/*
  An opinionated wrapper around the react-google-maps <AdvancedMarker /> component
  that provides a consistent look and feel for the pins and popups on the map.
*/
type BzAdvancedMarkerProps = AdvancedMarkerProps & {
  /* The marker state that the parent component uses to control the styles of the pin */
  markerState: BzMarkerState
  /* Conditional to render the popup */
  renderPopupIf?: boolean
  /* Popup content */
  popupContent?: React.ReactNode
  /* The dd action name for the pin */
  ddPinActionName?: string
} & BzMapPinConfig

const useMarkerColor = (
  state: BzMarkerState,
  config?: MarkerStateColorConfig,
) => {
  return useMemo(() => {
    if (!config) {
      return undefined
    }

    return config[state] || config['default']
  }, [config, state])
}

const BzAdvancedMarker = React.memo<BzAdvancedMarkerProps>(
  ({
    renderPopupIf = false,
    popupContent,
    markerState,
    pinContent,
    pinIconColorConfig,
    pinColorConfig,
    pinIcon,
    children,
    ddPinActionName,
    ...rest
  }) => {
    const [isHovered, setIsHovered] = useState(false)

    const [markerRef, marker] = useAdvancedMarkerRef()
    const onMouseOver = useCallback(() => {
      setIsHovered(true)
    }, [])

    const onMouseOut = useCallback(() => {
      setIsHovered(false)
    }, [])

    // Override the marker state on hover
    const currentMarkerState = useMemo(() => {
      if (isHovered) {
        return 'active'
      }

      return markerState
    }, [isHovered, markerState])

    const pinColor = useMarkerColor(currentMarkerState, pinColorConfig)
    const iconColor = useMarkerColor(currentMarkerState, pinIconColorConfig)

    // If the pin content is a react node, use it
    const mapPinContent = useMemo(() => {
      if (pinContent) {
        return pinContent
      }
      if (pinIcon) {
        return (
          <FontAwesomeIcon
            className={classNames('h-5 w-5', iconColor)}
            icon={pinIcon}
          />
        )
      }
      return null
    }, [pinContent, pinIcon, iconColor])

    return (
      <AdvancedMarker ref={markerRef} {...rest}>
        <MapPin
          className={classNames({
            'opacity-40': currentMarkerState === 'background',
          })}
          content={mapPinContent}
          colorHex={
            pinColor ?? tailwindConfig.theme.extend.colors['bz-gray'][400]
          }
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          ddActionName={ddPinActionName}
        />
        <RenderIf if={renderPopupIf}>
          <AdvancedMarkerPopup marker={marker}>
            {popupContent}
          </AdvancedMarkerPopup>
        </RenderIf>
        {children}
      </AdvancedMarker>
    )
  },
)

export default BzAdvancedMarker
