import { Tag as BreezyTag } from '@breezy/shared'
import { createContext, useContext } from 'react'
import { Job } from 'src/gql/queries/Jobs.gql'
import {
  BasicTech,
  BoardIdToStatusIdMap,
  KanbanJob,
} from '../../../../components/Kanban/kanbanUtils'
import { JobLifecycle } from '../../JobsPage.gql'
import {
  JobMenuDropdownOnChangeJobTypeHandler,
  JobMenuDropdownOnCreateLinkedJobHandler,
  JobMenuDropdownOnEditJobHandler,
  JobMenuDropdownOnUpdatePipelineStatusHandler,
} from '../JobMenuDropdown'

export type TableJob = {
  key: string
  displayId: number
  jobGuid: string
  jobType: string
  accountDisplayName: string
  location: string
  jobClassFormatted: string
  contact?: Job['account']['accountContacts'][number]['contact']
  contactFormatted: string
  nextAppointment: string
  technicians: BasicTech[]
  tags?: BreezyTag[]
  locationGuid: string
  accountGuid: string
  jobLifecycleGuid: string
  isCreatedLinkedJob: boolean
}

export type JobTableContextType = {
  disabled?: boolean
  isLoading?: boolean
  boardIdToStatusIdMap: BoardIdToStatusIdMap
  jobMap: Record<string, KanbanJob>
  lifecycles: JobLifecycle[]
  onChange: (datum: KanbanJob, statusId: string) => void
  onEditJob: JobMenuDropdownOnEditJobHandler
  onCreateLinkedJob: JobMenuDropdownOnCreateLinkedJobHandler
  onUpdateJobPipelineStatus: JobMenuDropdownOnUpdatePipelineStatusHandler
  onChangeJobType: JobMenuDropdownOnChangeJobTypeHandler
}

export const JobTableContext = createContext<JobTableContextType>({
  disabled: false,
  isLoading: false,
  boardIdToStatusIdMap: {},
  jobMap: {},
  lifecycles: [],
  onChange: () => {},
  onEditJob: () => {},
  onCreateLinkedJob: () => {},
  onUpdateJobPipelineStatus: () => {},
  onChangeJobType: () => {},
})

export const useJobTableContext = () => useContext(JobTableContext)
