import { CSSProperties, Fragment } from 'react'
import './ColoredCircle.less'

const ColoredCircle = ({ color }: { color: CSSProperties['color'] }) => {
  const styles = { backgroundColor: color }

  return color ? (
    <Fragment>
      <div className="colored-circle h-[10px] w-[10px]" style={styles} />
    </Fragment>
  ) : null
}

export default ColoredCircle
