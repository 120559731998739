import { DocumentType, gql } from 'src/generated'

export const LEAD_SOURCES_QUERY = gql(/* GraphQL */ `
  query LeadSourcesSettingsPageLeadSourcesQuery($companyGuid: uuid!) {
    activeCompanyLeadSources: companyLeadSources(
      where: {
        companyGuid: { _eq: $companyGuid }
        archivedAt: { _isNull: true }
      }
    ) {
      canonicalLeadSourceName
      canonicalLeadSourceNameOverride
      ordinal
      companyLeadSourceGuid
      companyLeadSourceLinksAggregate {
        aggregate {
          count
        }
      }
    }

    archivedCompanyLeadSources: companyLeadSources(
      where: {
        companyGuid: { _eq: $companyGuid }
        archivedAt: { _isNull: false }
      }
    ) {
      canonicalLeadSourceName
      canonicalLeadSourceNameOverride
      ordinal
      companyLeadSourceGuid
      companyLeadSourceLinksAggregate {
        aggregate {
          count
        }
      }
    }
  }
`)

export const CANONICAL_LEAD_SOURCES_QUERY = gql(/* GraphQL */ `
  query LeadSourcesSettingsPageCanonicalLeadSourcesQuery {
    canonicalLeadSources {
      canonicalLeadSourceName
      attributionLinkingStrategy
      attributionPrompt
    }
  }
`)

export type CanonicalLeadSource = DocumentType<
  typeof CANONICAL_LEAD_SOURCES_QUERY
>['canonicalLeadSources'][number]

export const UPSERT_LEAD_SOURCE_MUTATION = gql(/* GraphQL */ `
  mutation LeadSourcesSettingsPageUpsertLeadSourceMutation(
    $companyGuid: uuid!
    $companyLeadSourceGuid: uuid!
    $canonicalLeadSourceName: String!
    $canonicalLeadSourceNameOverride: String
    $attributionPromptOverride: String
    $attributionLinkingStrategyOverride: String
    $archivedAt: timestamptz
  ) {
    insertCompanyLeadSources(
      objects: {
        companyGuid: $companyGuid
        companyLeadSourceGuid: $companyLeadSourceGuid
        canonicalLeadSourceName: $canonicalLeadSourceName
        canonicalLeadSourceNameOverride: $canonicalLeadSourceNameOverride
        attributionPromptOverride: $attributionPromptOverride
        attributionLinkingStrategyOverride: $attributionLinkingStrategyOverride
        archivedAt: $archivedAt
      }
      onConflict: {
        constraint: company_lead_sources_pkey
        updateColumns: [
          canonicalLeadSourceName
          canonicalLeadSourceNameOverride
          attributionLinkingStrategyOverride
          attributionPromptOverride
          archivedAt
        ]
      }
    ) {
      affectedRows
    }
  }
`)

export const LEAD_SOURCES_SINGLE_QUERY = gql(/* GraphQL */ `
  query LeadSourcesSettingsPageLeadSourcesSingleQuery(
    $companyGuid: uuid!
    $companyLeadSourceGuid: uuid!
  ) {
    companyLeadSources(
      where: {
        companyGuid: { _eq: $companyGuid }
        companyLeadSourceGuid: { _eq: $companyLeadSourceGuid }
      }
    ) {
      canonicalLeadSource {
        canonicalLeadSourceName
        attributionLinkingStrategy
        attributionPrompt
      }
      canonicalLeadSourceNameOverride
      attributionPromptOverride
      attributionLinkingStrategyOverride
      archivedAt
    }
  }
`)
