import { BzDateTime, TimeWindowDto } from '@breezy/shared'
import { Form, Select } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { EventChangeInfo } from './PendingChanges/pendingChanges'

export type OutsideArrivalWindowResolution = 'CANCEL' | 'IGNORE' | 'CHANGE'

const RESOLUTION_OPTIONS = [
  {
    label: 'Cancel the visit move',
    value: 'CANCEL',
  },
  {
    label: 'Move the visit anyway',
    value: 'IGNORE',
  },
  {
    label: 'Update arrival window to start at the same time',
    value: 'CHANGE',
  },
] satisfies { label: string; value: OutsideArrivalWindowResolution }[]

type OutsideArrivalWindowModalProps = {
  onClose: (resolution?: OutsideArrivalWindowResolution) => void
  pendingChange: EventChangeInfo
  currentWindow: TimeWindowDto
}

export const OutsideArrivalWindowModal =
  React.memo<OutsideArrivalWindowModalProps>(
    ({ onClose, pendingChange, currentWindow }) => {
      const tzId = useExpectedCompanyTimeZoneId()
      const [resolution, setResolution] =
        useState<OutsideArrivalWindowResolution>()

      const onOk = useCallback(() => {
        onClose(resolution)
      }, [onClose, resolution])

      const description = useMemo(() => {
        const newStart = BzDateTime.fromIsoString(pendingChange.start, tzId)

        const arrivalStart = BzDateTime.fromIsoString(currentWindow.start, tzId)

        const arrivalEnd = BzDateTime.fromIsoString(currentWindow.end, tzId)

        return (
          <p>
            You're moving a visit to{' '}
            <strong>{newStart.toDateFormat('MMMM d, yyyy')}</strong> at{' '}
            <strong>{newStart.toDateFormat('h:mma').toLowerCase()}</strong>.
            However, the arrival window is on{' '}
            <strong>{arrivalStart.toDateFormat('MMMM d, yyyy')}</strong> from{' '}
            <strong>{arrivalStart.toDateFormat('h:mma').toLowerCase()}</strong>{' '}
            to <strong>{arrivalEnd.toDateFormat('h:mma').toLowerCase()}</strong>
            .
          </p>
        )
      }, [currentWindow.end, currentWindow.start, pendingChange.start, tzId])

      return (
        <OnsiteConfirmModal
          open
          header="Move Visit Outside Arrival Window?"
          onCancel={() => onClose()}
          onConfirm={onOk}
          confirmDisabled={!resolution}
          confirmText="Confirm"
        >
          {description}
          <Form layout="vertical">
            <Form.Item label="What do you want to do?" className="mb-0">
              <Select
                placeholder="Select an option"
                value={resolution}
                onChange={setResolution}
                options={RESOLUTION_OPTIONS}
              />
            </Form.Item>
          </Form>
        </OnsiteConfirmModal>
      )
    },
  )
