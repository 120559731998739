import React, { useCallback, useContext, useEffect } from 'react'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { useUnsavedChangesWarning } from '../../hooks/useUnsavedChangesWarning'
import { BlockerFunction, useBlocker } from '../../providers/BlockerWrapper'
import { StateSetter, useStrictContext } from '../../utils/react-utils'
import { useSchedulePendingChanges } from './PendingChanges/SchedulePendingChangesContext'
import { DEFAULT_PENDING_CHANGES } from './PendingChanges/pendingChanges'
import { SchedulePageContext } from './scheduleUtils'

type SchedulePlaygroundModeContextType = {
  playgroundMode: boolean
  setPlaygroundMode: StateSetter<boolean>
}

export const SchedulePlaygroundModeContext =
  React.createContext<SchedulePlaygroundModeContextType>({
    playgroundMode: false,
    setPlaygroundMode: () => {},
  })

export const SchedulePlaygroundModeWrapper =
  React.memo<React.PropsWithChildren>(({ children }) => {
    const { scheduleView } = useStrictContext(SchedulePageContext)
    const { setPendingChangesRaw } = useSchedulePendingChanges()
    const [playgroundMode, setPlaygroundMode] = React.useState(false)

    // When they switch tabs, it discards their changes. However, it doesn't turn off playground mode. This is
    // confusing. We also have the "are you sure you want to navigate away?" prompt when they navigate, so we can just
    // turn off playground mode when they switch tabs.
    useEffect(() => {
      setPlaygroundMode(false)
    }, [scheduleView])

    const shouldBlock = useCallback<BlockerFunction>(
      () => playgroundMode,
      [playgroundMode],
    )

    const blocker = useBlocker('SchedulePlaygroundMode', shouldBlock)

    useUnsavedChangesWarning(playgroundMode)

    const proceed = useCallback(() => {
      if (!blocker.proceed) {
        return
      }
      setPlaygroundMode(false)
      setPendingChangesRaw(DEFAULT_PENDING_CHANGES)
      blocker.proceed()
    }, [blocker, setPendingChangesRaw])

    return (
      <SchedulePlaygroundModeContext.Provider
        value={{ playgroundMode, setPlaygroundMode }}
      >
        {children}
        {blocker.state === 'blocked' && (
          <OnsiteConfirmModal
            danger
            header="Are you sure you want to exit?"
            onCancel={blocker.reset}
            onConfirm={proceed}
            confirmText="Yes, Exit"
          >
            You are currently editing the schedule. Navigating away will discard
            your changes.
            <br />
            Are you sure you want to exit?
          </OnsiteConfirmModal>
        )}
      </SchedulePlaygroundModeContext.Provider>
    )
  })

export const useSchedulePlaygroundMode = () =>
  useContext(SchedulePlaygroundModeContext)
