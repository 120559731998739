import { InfoWindow } from '@vis.gl/react-google-maps'
import React from 'react'

type AdvancedMarkerPopupProps = {
  marker: google.maps.marker.AdvancedMarkerElement | null
}

const AdvancedMarkerPopup = React.memo<
  React.PropsWithChildren<AdvancedMarkerPopupProps>
>(({ marker, children }) => {
  return <InfoWindow anchor={marker}>{children}</InfoWindow>
})

export default AdvancedMarkerPopup
