import { Divider, Form, Input, Radio } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'
import { ChangeJobTypeFormSchema } from '../change-job-type-form-schema'

export const ReasonForChangeStep = () => {
  const { control } = useFormContext<ChangeJobTypeFormSchema>()

  return (
    <>
      <Controller
        control={control}
        name="reasonForChange.description"
        render={({ field }) => (
          <Form.Item label="Why is the job type being changed?">
            <Input.TextArea
              {...field}
              allowClear
              rows={5}
              placeholder="Enter the reason for changing this job's job type"
            />
          </Form.Item>
        )}
      />

      <Controller
        control={control}
        name="reasonForChange.reason"
        render={({ field }) => (
          <div className="flex flex-col">
            <span className="mb-3 font-semibold text-bz-gray-800">
              Additional response options
            </span>

            <Divider className="m-0" />

            <label className="flex w-full flex-row py-5 font-semibold hover:cursor-pointer">
              <span>Clerical error</span>

              <Radio
                {...field}
                name="reasonForChange.reason"
                className="ml-auto"
                value={'CLERICAL_ERROR'}
                checked={field.value === 'CLERICAL_ERROR'}
              />
            </label>

            <Divider className="m-0" />

            <label className="flex w-full flex-row py-5 font-semibold hover:cursor-pointer">
              <span>The job flipped/was turned over</span>

              <Radio
                {...field}
                name="reasonForChange.reason"
                className="ml-auto"
                value={'JOB_FLIPPED_OR_TURNED_OVER'}
                checked={field.value === 'JOB_FLIPPED_OR_TURNED_OVER'}
              />
            </label>

            <Divider className="m-0" />

            <label className="flex w-full flex-row py-5 font-semibold hover:cursor-pointer">
              <span>The customer provided incorrect information</span>

              <Radio
                {...field}
                name="reasonForChange.reason"
                className="ml-auto"
                value={'CUSTOMER_PROVIDED_INCORRECT_INFORMATION'}
                checked={
                  field.value === 'CUSTOMER_PROVIDED_INCORRECT_INFORMATION'
                }
              />
            </label>
          </div>
        )}
      />
    </>
  )
}
