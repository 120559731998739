import { PermissionV2 } from '@breezy/shared'
import { useMemo } from 'react'
import { useIsAuthorized } from './useIsAuthorized'

export const useCanManageOfficeJobs = () => {
  const isAuthorized = useIsAuthorized()
  return useMemo(() => {
    return isAuthorized(PermissionV2.OFFICE_ACCOUNTS_JOBS_MANAGE)
  }, [isAuthorized])
}
