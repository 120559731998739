import { PageHeader } from '@ant-design/pro-components'
import { BzDateFns, JobLeadAvailability } from '@breezy/shared'
import { Button, ConfigProvider, Divider, Empty, List, message } from 'antd'
import React, { ComponentProps, useCallback, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Page } from '../../components/Page/Page'
import ProgressiveJobCreationModal from '../../components/ProgressiveJobCreationModal/ProgressiveJobCreationModal'
import BzDrawer from '../../elements/BzDrawer/BzDrawer'
import { trpc } from '../../hooks/trpc'
import useIsMobile from '../../hooks/useIsMobile'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { CreateNewJobLeadForm } from './CreateNewJobLeadForm'
import { JobLeadView } from './JobLeadView'
import { FETCH_JOB_LEADS } from './JobLeads.gql'
import { JobLead } from './types'

type JobLeadStatus = 'open' | 'converted' | 'archived'

const OPEN = 'open' satisfies JobLeadStatus
const CONVERTED = 'converted' satisfies JobLeadStatus
const ARCHIVED = 'archived' satisfies JobLeadStatus
type NotificationContentProps = {
  data: JobLead[]
  refetch: () => void
}

const JobLeadsContent = React.memo<NotificationContentProps>(
  ({ data, refetch }) => {
    const [open, converted, archived] = useMemo(() => {
      const open: JobLead[] = []
      const converted: JobLead[] = []
      const archived: JobLead[] = []
      for (const jobLead of data) {
        if (jobLead.jobLeadStatus === OPEN) {
          open.push(jobLead)
        } else if (jobLead.jobLeadStatus === CONVERTED) {
          converted.push(jobLead)
        } else if (jobLead.jobLeadStatus === ARCHIVED) {
          archived.push(jobLead)
        } else {
          open.push(jobLead)
        }
      }
      return [open, converted, archived]
    }, [data])

    const [jobLeadToConvert, setJobLeadToConvert] = useState<
      JobLead | undefined
    >(undefined)

    const onConvertJob = useCallback((jobLead: JobLead) => {
      setJobLeadToConvert(jobLead)
    }, [])

    return (
      <ConfigProvider renderEmpty={() => <Empty description="No Job Leads" />}>
        <div>
          {(
            [
              ['Open', open],
              ['Converted', converted],
              ['Archived', archived],
            ] as const
          ).map(([header, list]) => (
            <React.Fragment key={header}>
              <Divider orientation="left" orientationMargin={0}>
                {header}
              </Divider>
              <List
                dataSource={list}
                renderItem={jobLead => (
                  <List.Item>
                    <JobLeadView
                      key={jobLead.jobLeadGuid}
                      jobLead={jobLead}
                      refetch={refetch}
                      onConvertJob={onConvertJob}
                    />
                  </List.Item>
                )}
              />
            </React.Fragment>
          ))}
        </div>
        {!!jobLeadToConvert && (
          <ProgressiveJobCreationModal
            isOpen
            jobLead={jobLeadToConvert}
            setIsOpen={open => {
              if (!open) setJobLeadToConvert(undefined)
            }}
          />
        )}
      </ConfigProvider>
    )
  },
)

export const JobLeadsPage = React.memo(() => {
  const isMobile = useIsMobile()
  const companyGuid = useExpectedCompanyGuid()

  const [creatingNewJobLead, setCreatingNewJobLead] = useState(false)
  const onCreateNewJobLeadClick = useCallback(() => {
    setCreatingNewJobLead(true)
  }, [setCreatingNewJobLead])

  const cancelCreateNewJobLead = useCallback(() => {
    setCreatingNewJobLead(false)
  }, [setCreatingNewJobLead])

  const [endOfDayToday] = useState(BzDateFns.endOfToday())
  const [ninetyDaysAgo] = useState(BzDateFns.subDays(endOfDayToday, 90))

  const fetchJobLeadsQuery = useQuery({
    query: FETCH_JOB_LEADS,
    variables: {
      companyGuid,
      timeStart: ninetyDaysAgo.toISOString(),
      timeEnd: endOfDayToday.toISOString(),
    },
  })

  const refetchJobLeads = useCallback(() => {
    fetchJobLeadsQuery[1]({ requestPolicy: 'network-only' })
  }, [fetchJobLeadsQuery])

  const createJobLeadsMutation = trpc.jobLeads['job-leads:create'].useMutation()

  const onCreateNewJobLeadSubmit: ComponentProps<
    typeof CreateNewJobLeadForm
  >['onSubmit'] = useCallback(
    async data => {
      try {
        const times: JobLeadAvailability['times'] = []
        if (data.preferredAvailabilityDate) {
          times.push({
            type: 'freeform',
            value: `${BzDateFns.localDateToFriendlyDateString(
              data.preferredAvailabilityDate,
            )}, Windows: ${
              data.preferredAvailabilityTimeWindows?.join(', ') ?? '—'
            }`,
          })
        }

        if (data.backupAvailabilityDate) {
          times.push({
            type: 'freeform',
            value: `${BzDateFns.localDateToFriendlyDateString(
              data.backupAvailabilityDate,
            )}, Windows: ${
              data.backupAvailabilityTimeWindows?.join(', ') ?? '—'
            }`,
          })
        }

        await createJobLeadsMutation.mutateAsync({
          ...data,
          appointmentAvailabilities:
            times.length > 0
              ? {
                  times: times,
                }
              : undefined,
        })

        refetchJobLeads()
        setCreatingNewJobLead(false)
        message.success('Successfully create new job lead')
      } catch (e) {
        console.error(
          `Failed to create new job lead. Please try again in a few minutes`,
          e,
        )
        message.error(
          `Failed to create new job lead. Please try again in a few minutes`,
        )
      }
    },
    [createJobLeadsMutation, refetchJobLeads],
  )

  return (
    <Page requiresCompanyUser>
      <div
        className="card container m-0 m-auto overflow-y-scroll"
        style={
          isMobile
            ? {
                padding: '4px',
                height: '70%',
                maxWidth: '100vw',
                boxSizing: 'border-box',
              }
            : undefined
        }
      >
        <PageHeader
          title="Job Leads"
          extra={
            <>
              <Button type="primary" onClick={onCreateNewJobLeadClick}>
                Create New Job Lead
              </Button>
            </>
          }
        >
          <>
            {fetchJobLeadsQuery[0].data ? (
              <JobLeadsContent
                data={fetchJobLeadsQuery[0].data.jobLeads}
                refetch={refetchJobLeads}
              />
            ) : fetchJobLeadsQuery[0].fetching ? (
              <LoadingSpinner />
            ) : (
              <></>
            )}
          </>
        </PageHeader>
      </div>
      <BzDrawer
        destroyOnClose
        title="Create New Job Lead"
        preferredWidth={720}
        showClose
        item={
          creatingNewJobLead ? { onCancel: cancelCreateNewJobLead } : undefined
        }
        children={
          <CreateNewJobLeadForm
            onCancel={cancelCreateNewJobLead}
            onSubmit={onCreateNewJobLeadSubmit}
            isLoading={createJobLeadsMutation.isLoading}
          />
        }
      ></BzDrawer>
    </Page>
  )
})
