import { PageHeader } from '@ant-design/pro-components'
import { WorkingHours } from '@breezy/shared'
import { faClock } from '@fortawesome/pro-light-svg-icons'
import { Button, Col, Form, InputNumber } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useState } from 'react'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ThinDivider from '../../elements/ThinDivider'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { Page } from '../Page/Page'
import TrpcQueryLoader from '../TrpcQueryLoader'

type WorkingHoursEditViewProps = WorkingHours & {
  onUpdate: () => void
}

const WorkingHoursEditView = ({
  startHour,
  endHour,
  onUpdate,
}: WorkingHoursEditViewProps) => {
  const message = useMessage()
  // WTF: Why doesn't this have the Company Name?
  const company = useExpectedCompany()
  const [form] = useForm<WorkingHours>()
  const [canSubmit, setCanSubmit] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const setWorkingHoursMut =
    trpc.scheduling['scheduling:set-company-working-hours'].useMutation()

  const setWorkingHours = (workingHours: WorkingHours) => {
    setCanSubmit(false)
    setWorkingHoursMut.mutate(workingHours, {
      onSuccess: () => {
        onUpdate()
        message.success('Working hours updated')
      },
      onSettled: () => setIsSubmitting(false),
    })
  }

  return (
    <Col className="p-4">
      <p>TimeZone: {company?.timezone}</p>
      <ThinDivider />
      <Form form={form} disabled={isSubmitting} onFinish={setWorkingHours}>
        <Form.Item
          name="startHour"
          label="Business Start Hour (24-hour)"
          initialValue={startHour}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            id="startHour"
            name="startHour"
            min={0}
            max={23}
            onChange={() => setCanSubmit(true)}
          />
        </Form.Item>
        <Form.Item
          name="endHour"
          label="Business Close Hour (24-hour)"
          initialValue={endHour}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            id="endHour"
            name="endHour"
            min={0}
            max={23}
            onChange={() => setCanSubmit(true)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={!canSubmit}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Col>
  )
}

const WorkingHoursEditViewPage = () => {
  const query =
    trpc.scheduling['scheduling:get-company-working-hours'].useQuery()

  return (
    <Page requiresCompanyUser={true}>
      <PageHeader
        title={<PageTitle title="Company Working Hours" icon={faClock} />}
      />
      <TrpcQueryLoader
        query={query}
        render={(workingHours: WorkingHours) => (
          <WorkingHoursEditView
            {...workingHours}
            onUpdate={() => query.refetch()}
          />
        )}
      />
    </Page>
  )
}

export default WorkingHoursEditViewPage
