import { faScrewdriverWrench, faTag } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { DetailChip } from '../../adam-components/ListPage/DetailChip'
import { TagRow } from '../../elements/TagRow/TagRow'

type AccountTagRowMaintenancePlan = {
  color?: string
  name: string
}

type AccountTagRowProps = {
  maintenancePlans?: AccountTagRowMaintenancePlan[]
  tags: string[]
  chipClassName?: string
}

export const AccountTagRow = React.memo(
  ({ maintenancePlans, tags, chipClassName }: AccountTagRowProps) => (
    <TagRow chipClassName={chipClassName}>
      {maintenancePlans?.map((maintenancePlan, i) => (
        <React.Fragment key={`${i}_${maintenancePlan?.name}`}>
          {maintenancePlan && (
            <DetailChip
              className={chipClassName}
              accentColor={maintenancePlan.color}
              icon={
                <FontAwesomeIcon
                  icon={faScrewdriverWrench}
                  style={{ color: maintenancePlan.color }}
                />
              }
            >
              {maintenancePlan.name}
            </DetailChip>
          )}
        </React.Fragment>
      ))}
      {tags.map(tag => (
        <DetailChip
          className={chipClassName}
          key={tag}
          icon={<FontAwesomeIcon icon={faTag} className="text-bz-gray-900" />}
        >
          {tag}
        </DetailChip>
      ))}
    </TagRow>
  ),
)
