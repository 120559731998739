import { PageHeader } from '@ant-design/pro-components'
import { Dfns, JobLifecycle, R, isNullish } from '@breezy/shared'
import { faEdit, faShuffle, faTrash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Input } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { Page } from '../../components/Page/Page'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'
import { trpc } from '../../hooks/trpc'
import { Styled } from '../../utils/Stylable'
import { useMessage } from '../../utils/antd-utils'
import { useQueryParamState } from '../../utils/react-utils'
import { JobLifecycleSettingsPage } from './JobLifecycleSettingsPage'
import { CreateNewPipelineModal } from './components/CreateNewPipelineModal'
import { JobLifecycleDeleteModal } from './components/JobLifecycleDeleteModal'

type JobLifecycleItemSectionProps = React.PropsWithChildren<
  Styled<{
    label: string
  }>
>

const JobLifecycleItemSection = React.memo<JobLifecycleItemSectionProps>(
  ({ className, label, children }) => (
    <div className={classNames('flex-1', className)}>
      <div className="mb-2 text-base font-semibold">{children}</div>
      <div className="text-bz-gray-700">{label}</div>
    </div>
  ),
)

type JobLifecycleItemProps = JobLifecycle & {
  setSelectedLifecycleGuid: (guid: string) => void
  onDeleteLifecycleClicked: (jobLifecycleGuid: string) => void
}

const JobLifecycleItem = React.memo<JobLifecycleItemProps>(
  ({
    jobLifecycleGuid,
    name,
    description,
    updatedAt,
    statuses,
    setSelectedLifecycleGuid,
    onDeleteLifecycleClicked,
  }) => {
    const formattedUpdatedAt = useMemo(
      () => R.pipe(Dfns.parseISO, Dfns.format('MM/dd/yy'))(updatedAt),
      [updatedAt],
    )

    const numJobsForLifecycle = useMemo(
      () => statuses.reduce((acc, curr) => acc + (curr?.jobCount ?? 0), 0),
      [statuses],
    )

    return (
      <div className="flex flex-row items-center space-x-6 rounded-lg border border-solid border-bz-gray-500 px-6 py-4">
        <div className="mr-4 rounded-full bg-daybreak-blue-100 p-3">
          <FontAwesomeIcon
            icon={faShuffle}
            className="text-base text-daybreak-blue-900"
          />
        </div>

        <JobLifecycleItemSection label={description} className="flex-[5]">
          {name}
        </JobLifecycleItemSection>

        <JobLifecycleItemSection label="Jobs in lifecycle">
          {numJobsForLifecycle}
        </JobLifecycleItemSection>

        <JobLifecycleItemSection label="Last updated">
          {formattedUpdatedAt}
        </JobLifecycleItemSection>

        <div className="ml-4 flex flex-row gap-2">
          <Button
            icon={<FontAwesomeIcon icon={faEdit} />}
            onClick={() => setSelectedLifecycleGuid(jobLifecycleGuid)}
          >
            Edit
          </Button>

          <Button
            type="default"
            danger
            icon={<FontAwesomeIcon icon={faTrash} />}
            onClick={() => onDeleteLifecycleClicked(jobLifecycleGuid)}
          />
        </div>
      </div>
    )
  },
)

type JobLifecycleListProps = {
  setSelectedLifecycleGuid: (guid: string) => void
  refetch: () => void
  data: JobLifecycle[]
}

const JobLifecycleList = React.memo<JobLifecycleListProps>(
  ({ setSelectedLifecycleGuid, refetch, data }) => {
    const message = useMessage()

    const [searchTerm, setSearchTerm] = useState('')

    const filteredLifecycles = useMemo(() => {
      const lcSearchTerm = searchTerm.toLowerCase()
      return data.filter(l => !l.isArchived && l.name.includes(lcSearchTerm))
    }, [data, searchTerm])

    const [lifecycleToDelete, setLifecycleToDelete] =
      useState<JobLifecycle | null>(null)

    const lifecycleDeleteMutation = trpc.jobLifecycles[
      'job-lifecycles:delete'
    ].useMutation({
      onSuccess: () => {
        setLifecycleToDelete(null)
        refetch()
      },
    })

    const [createNewPipelineModalOpen, setCreateNewPipelineModalOpen] =
      useState(false)

    return (
      <>
        <div className="flex flex-row items-center gap-4">
          <div className="mb-4 flex flex-1 flex-col">
            {/* TODO: learn more button */}
            <div className="text-sm text-bz-gray-700">
              Job pipelines allow you to customize your job management processes
              across your account.
            </div>
          </div>

          <div className="flex flex-row items-center gap-2">
            <Input
              placeholder="Search lifecycles..."
              prefix={<AiOutlineSearch />}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              allowClear
              className="w-96"
            />

            <Button
              type="primary"
              onClick={() => setCreateNewPipelineModalOpen(true)}
            >
              Add New Pipeline
            </Button>
          </div>
        </div>

        <div className="mt-4 min-h-0 space-y-4 overflow-auto">
          {filteredLifecycles.map(lifecycle => (
            <JobLifecycleItem
              key={lifecycle.jobLifecycleGuid}
              setSelectedLifecycleGuid={setSelectedLifecycleGuid}
              onDeleteLifecycleClicked={jobLifecycleGuid => {
                const lifecycle = data.find(
                  lifecycle => lifecycle.jobLifecycleGuid === jobLifecycleGuid,
                )
                if (isNullish(lifecycle)) {
                  message.error(
                    'Invalid Job Lifecycle to delete. Job Lifecycle does not exist',
                  )
                  return
                }

                setLifecycleToDelete(lifecycle)
              }}
              {...lifecycle}
            />
          ))}
        </div>

        <JobLifecycleDeleteModal
          open={!isNullish(lifecycleToDelete)}
          isLoading={lifecycleDeleteMutation.isLoading}
          lifecycle={lifecycleToDelete}
          allLifecycles={data}
          onDeleteClicked={lifecycleDeleteMutation.mutate}
          onCancel={() => setLifecycleToDelete(null)}
        />

        <CreateNewPipelineModal
          open={createNewPipelineModalOpen}
          onCreateJobPipeline={() =>
            setSelectedLifecycleGuid('new-jobs-pipeline')
          }
          onCreateSalesPipeline={() =>
            setSelectedLifecycleGuid('new-sales-pipeline')
          }
          onClose={() => setCreateNewPipelineModalOpen(false)}
        />
      </>
    )
  },
)

export const JobLifecycleSettingsListPage = React.memo(() => {
  const [selectedLifecycleGuid, setSelectedLifecycleGuid] = useQueryParamState(
    'id',
    '',
  )
  const lifecycleQuery = trpc.jobLifecycles['job-lifecycles:get'].useQuery()

  const resetSelectedLifecycle = useCallback(() => {
    lifecycleQuery.refetch()
    setSelectedLifecycleGuid('')
  }, [lifecycleQuery, setSelectedLifecycleGuid])

  const selectedLifecycle = useMemo(
    () =>
      lifecycleQuery.data?.find(
        l => l.jobLifecycleGuid === selectedLifecycleGuid,
      ),
    [lifecycleQuery, selectedLifecycleGuid],
  )

  const renderContent = useCallback(
    (data: JobLifecycle[]) => {
      if (selectedLifecycleGuid) {
        // TODO: look up the lifecycle data. If it's not there, treat it as "new" maybe?
        return (
          <JobLifecycleSettingsPage
            selectedLifecycle={selectedLifecycle}
            resetSelectedLifecycle={resetSelectedLifecycle}
          />
        )
      }
      return (
        <JobLifecycleList
          setSelectedLifecycleGuid={setSelectedLifecycleGuid}
          refetch={lifecycleQuery.refetch}
          data={data}
        />
      )
    },
    [
      lifecycleQuery.refetch,
      resetSelectedLifecycle,
      selectedLifecycle,
      selectedLifecycleGuid,
      setSelectedLifecycleGuid,
    ],
  )

  return (
    <Page requiresCompanyUser className="overflow-hidden p-0">
      <PageHeader
        title={<PageTitle title="Job Pipelines" icon={faShuffle} />}
      />
      <ScrollCard className="relative flex flex-col" hasPageHeading>
        <TrpcQueryLoader query={lifecycleQuery} render={renderContent} />
      </ScrollCard>
    </Page>
  )
})
