import { useQuery } from 'urql'
import { PRICEBOOK_TAX_RATES_ADMIN_QUERY } from '../PricebookAdmin.gql'

export const useGetPricebookTaxRatesAdmin = () => {
  const getPricebookTaxRatesAdminQuery = useQuery({
    query: PRICEBOOK_TAX_RATES_ADMIN_QUERY,
  })

  const { data, fetching, error } = getPricebookTaxRatesAdminQuery[0]
  return {
    getPricebookTaxRatesAdminQuery,
    data,
    fetching,
    error,
  }
}
