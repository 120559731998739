import {
  RenewalTypeOption,
  getRenewalTypeOptionFromBillingProfiles,
  isNullish,
} from '@breezy/shared'
import { Select } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { useUpsertCompanyBillingProfile } from '../../hooks/useUpsertCompanyBillingProfile'
import { useMessage } from '../../utils/antd-utils'
import { MaintenancePlanBillingProfileViewProps } from './maintenancePlanConfigUtils'

const ObjectType = 'Renewal Types' as const

const MaintenancePlanRenewalTypesManageView = ({
  companyGuid,
  billingProfiles,
  onMutate,
}: MaintenancePlanBillingProfileViewProps) => {
  const message = useMessage()
  const [isUploading, setIsUploading] = useState(false)
  const [justPicked, setJustPicked] = useState<RenewalTypeOption | undefined>(
    undefined,
  )
  const currentOption = useMemo(() => {
    if (!isNullish(justPicked)) return justPicked
    return getRenewalTypeOptionFromBillingProfiles(billingProfiles)
  }, [justPicked, billingProfiles])

  const { upsert } = useUpsertCompanyBillingProfile()

  const upsertRenewalOptions = useCallback(
    async (option: RenewalTypeOption) => {
      setIsUploading(true)
      const {
        __typename,
        emailAddress,
        phoneNumber,
        businessAddress,
        defaultTaxRate,
        maintenancePlanTaxRate,
        maintenancePlanServiceItem,
        ...originalRow
      } = billingProfiles[0]
      try {
        await upsert({
          object: {
            ...originalRow,
            companyGuid,
            maintenancePlanAutoRenewalAllowed:
              option === 'AUTO' || option === 'BOTH',
            maintenancePlanManualRenewalAllowed:
              option === 'MANUAL' || option === 'BOTH',
            updatedAt: new Date().toISOString(),
          },
        })
        onMutate()
      } catch (e) {
        console.error(`Failed to update ${ObjectType}`, e)
        message.error(`There was an unknown issue updating ${ObjectType}`)
        setJustPicked(undefined)
      }
      setIsUploading(false)
    },
    [upsert, companyGuid, billingProfiles, onMutate, message],
  )

  const onChange = useCallback(
    async (t: RenewalTypeOption) => {
      setJustPicked(t)
      await upsertRenewalOptions(t)
    },
    [upsertRenewalOptions],
  )

  return (
    <>
      <div className="col w-full px-2 pt-2">
        <div className="row flex-between ">
          <div className="col">
            <h3 className="grey9">{ObjectType}</h3>
            <p className="regular_14_22 grey8 max-w-[700px] font-light">
              Pick which renewal types you will offer for your Maintenance Plans
            </p>
          </div>
        </div>
        <div className="mx-auto max-w-[600px]">
          {!isUploading && (
            <Select
              allowClear={false}
              value={currentOption}
              onChange={(value: string) => onChange(value as RenewalTypeOption)}
              className="grey10 mb-6 w-full min-w-[300px] text-center text-[20px] font-semibold"
            >
              <Select.Option key={'AUTO'} value={'AUTO'}>
                <div className="grey9 w-full text-center text-[20px] font-semibold">
                  Auto-Renew Only
                </div>
              </Select.Option>
              <Select.Option key={'MANUAL'} value={'MANUAL'}>
                <div className="grey9 w-full text-center text-[20px] font-semibold">
                  Manual-Renew Only
                </div>
              </Select.Option>
              <Select.Option key={'BOTH'} value={'BOTH'}>
                <div className="grey9 w-full text-center text-[20px] font-semibold">
                  Auto & Manual Allowed
                </div>
              </Select.Option>
            </Select>
          )}
        </div>
        {isUploading && <LoadingSpinner />}
      </div>
    </>
  )
}

export default MaintenancePlanRenewalTypesManageView
