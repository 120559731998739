import { PageHeader } from '@ant-design/pro-components'
import {
  EquipmentType,
  INSTALL_JOB_PROJECT_TYPE,
  InstallProjectType,
  bzOptional,
  displayForInstallProjectType,
  formatEquipmentType,
  guidSchema,
  isNullish,
} from '@breezy/shared'
import { faGear } from '@fortawesome/pro-light-svg-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Checkbox, Divider, Input, Select } from 'antd'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'urql'
import { z } from 'zod'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import { Page } from '../../components/Page/Page'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import {
  COMPANY_CONFIG_SETTINGS_QUERY,
  COMPANY_CONFIG_UPSERT_ONE,
  CompanyConfig,
} from './CompanyConfigSettingsPage.gql'

const companyConfigSettingsFormSchema = z.object({
  hiddenEquipmentTypesJsonArray: bzOptional(z.string().array()),
  visibleInstallProjectTypesJsonArray: bzOptional(z.string().array()),
  defaultAccountManagerGuid: bzOptional(guidSchema),
  locationMunicipalityFieldEnabled: bzOptional(z.boolean()),
  qboEnabled: bzOptional(z.boolean()),
  confirmationNotificationSuppressedByDefault: z.boolean().default(false),
  blurb: bzOptional(z.string()),
  accountManagerEnabled: z.boolean().default(false),
})

type CompanyConfigSettingsFormSchema = z.infer<
  typeof companyConfigSettingsFormSchema
>

const EQUIPMENT_TYPE_OPTIONS = Object.values(EquipmentType).map(value => ({
  value,
  label: formatEquipmentType(value),
}))

const INSTALL_PROJECT_TYPE_OPTIONS = INSTALL_JOB_PROJECT_TYPE.map(value => ({
  value,
  label: displayForInstallProjectType(value),
}))

type CompanyConfigSettingsPageContentProps = {
  data: CompanyConfig
  onSubmit: (data: CompanyConfigSettingsFormSchema) => void
}

const CompanyConfigSettingsPageContent = ({
  data,
  onSubmit,
}: CompanyConfigSettingsPageContentProps) => {
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<CompanyConfigSettingsFormSchema>({
    resolver: zodResolver(companyConfigSettingsFormSchema),
    defaultValues: {
      hiddenEquipmentTypesJsonArray: data.companyConfigByPk
        ?.hiddenEquipmentTypesJsonArray as EquipmentType[] | undefined,
      visibleInstallProjectTypesJsonArray: data.companyConfigByPk
        ?.visibleInstallProjectTypesJsonArray as
        | InstallProjectType[]
        | undefined,
      defaultAccountManagerGuid:
        data.companyConfigByPk?.defaultAccountManagerUserGuid,
      locationMunicipalityFieldEnabled:
        data.companyConfigByPk?.locationMunicipalityFieldEnabled,
      qboEnabled: data.companyConfigByPk?.qboEnabled,
      confirmationNotificationSuppressedByDefault:
        data.companyConfigByPk?.confirmationNotificationSuppressedByDefault,
      blurb: data.companyConfigByPk?.blurb,
      accountManagerEnabled: data.companyConfigByPk?.accountManagerEnabled,
    },
  })

  const isAccountManagerEnabled: boolean | undefined = watch(
    'accountManagerEnabled',
  )

  const onSubmitInner = handleSubmit(data => onSubmit(data))

  useEffect(() => {
    if (!isAccountManagerEnabled) {
      setValue('defaultAccountManagerGuid', undefined)
    }
  }, [isAccountManagerEnabled, setValue])

  return (
    <form className="flex h-full w-full flex-col" onSubmit={onSubmitInner}>
      <div className="flex h-full w-full flex-col gap-6 overflow-y-scroll p-3">
        <Controller
          control={control}
          name="hiddenEquipmentTypesJsonArray"
          render={({ field }) => (
            <label className="flex flex-col gap-2">
              <span className="font-semibold">Hidden Equipment Types</span>

              <Select
                {...field}
                mode="multiple"
                placeholder="Select equipment types..."
                value={field.value ?? []}
                options={EQUIPMENT_TYPE_OPTIONS}
                status={
                  errors.hiddenEquipmentTypesJsonArray ? 'error' : undefined
                }
              />
            </label>
          )}
        />

        <Controller
          control={control}
          name="visibleInstallProjectTypesJsonArray"
          render={({ field }) => (
            <label className="flex flex-col gap-2">
              <span className="font-semibold">
                Visible Install Project Types (blank for all)
              </span>

              <Select
                {...field}
                mode="multiple"
                placeholder="Select visible install project types..."
                value={field.value ?? []}
                options={INSTALL_PROJECT_TYPE_OPTIONS}
                status={
                  errors.visibleInstallProjectTypesJsonArray
                    ? 'error'
                    : undefined
                }
              />
            </label>
          )}
        />

        <Controller
          control={control}
          name="accountManagerEnabled"
          render={({ field }) => (
            <label className="flex flex-row gap-2">
              <span>Account Manager Field Enabled:</span>

              <Checkbox {...field} checked={field.value} />
            </label>
          )}
        />

        {isAccountManagerEnabled && (
          <Controller
            control={control}
            name="defaultAccountManagerGuid"
            render={({ field }) => (
              <label className="flex flex-col gap-2">
                <span className="font-semibold">Default Account Manager</span>

                <Select
                  {...field}
                  showSearch
                  placeholder="Select user to be default account manager..."
                  options={data.companyUsers.map(user => ({
                    label: user.userByUserGuid.fullName ?? 'Company User',
                    value: user.userByUserGuid.userGuid,
                  }))}
                  filterOption={(input, option) =>
                    (option?.label.toLowerCase() ?? '').includes(
                      input.toLowerCase(),
                    )
                  }
                />
              </label>
            )}
          />
        )}

        <Controller
          control={control}
          name="locationMunicipalityFieldEnabled"
          render={({ field }) => (
            <label className="flex flex-row gap-2">
              <span>Location Municipality Field Enabled:</span>

              <Checkbox {...field} checked={field.value} />
            </label>
          )}
        />

        <Controller
          control={control}
          name="qboEnabled"
          render={({ field }) => (
            <label className="flex flex-row gap-2">
              <span>QBO Enabled:</span>

              <Checkbox {...field} checked={field.value} />
            </label>
          )}
        />

        <Controller
          control={control}
          name="confirmationNotificationSuppressedByDefault"
          render={({ field }) => (
            <label className="flex flex-row gap-2">
              <span>Confirmation Notification Suppressed By Default</span>

              <Checkbox {...field} checked={field.value} />
            </label>
          )}
        />

        <Controller
          control={control}
          name="blurb"
          render={({ field }) => (
            <label className="flex flex-col gap-2">
              <span>Blurb</span>

              <Input.TextArea {...field} rows={5} />
            </label>
          )}
        />
      </div>

      <div className="mt-auto flex flex-col">
        <Divider />

        <div className="flex w-full flex-row items-center justify-end gap-3">
          <Button htmlType="button" disabled={!isDirty} onClick={() => reset()}>
            Cancel
          </Button>

          <Button type="primary" htmlType="submit" disabled={!isDirty}>
            Save
          </Button>
        </div>
      </div>
    </form>
  )
}

export const CompanyConfigSettingsPage = () => {
  const { companyGuid } = useExpectedCompany()

  const message = useMessage()

  const companyConfigQuery = useQuery({
    query: COMPANY_CONFIG_SETTINGS_QUERY,
    variables: { companyGuid },
  })

  const [, companyConfigUpsertOneMutation] = useMutation(
    COMPANY_CONFIG_UPSERT_ONE,
  )

  const onSubmit = async (data: CompanyConfigSettingsFormSchema) => {
    try {
      await companyConfigUpsertOneMutation({
        object: {
          companyGuid,
          accountManagerEnabled: data.accountManagerEnabled,
          blurb: data.blurb,
          qboEnabled: data.qboEnabled,
          defaultAccountManagerUserGuid: data.defaultAccountManagerGuid,
          locationMunicipalityFieldEnabled:
            data.locationMunicipalityFieldEnabled,
          confirmationNotificationSuppressedByDefault:
            data.confirmationNotificationSuppressedByDefault,
          hiddenEquipmentTypesJsonArray:
            isNullish(data.hiddenEquipmentTypesJsonArray) ||
            data.hiddenEquipmentTypesJsonArray.length === 0
              ? null
              : data.hiddenEquipmentTypesJsonArray,
          visibleInstallProjectTypesJsonArray:
            isNullish(data.visibleInstallProjectTypesJsonArray) ||
            data.visibleInstallProjectTypesJsonArray.length === 0
              ? null
              : data.visibleInstallProjectTypesJsonArray,
        },
      })

      message.success('Updated company config!')
      companyConfigQuery[1]()
    } catch (err) {
      message.error('Failed to updated company config')
    }
  }

  return (
    <Page requiresCompanyUser className="flex flex-col p-0">
      <PageHeader title={<PageTitle title="Company Config" icon={faGear} />} />

      <ScrollCard hasPageHeading className="flex h-full w-full flex-col">
        <GqlQueryLoader
          query={companyConfigQuery}
          render={data => (
            <CompanyConfigSettingsPageContent data={data} onSubmit={onSubmit} />
          )}
        />
      </ScrollCard>
    </Page>
  )
}
