import tailwindConfig from '../../../tailwind.config'

const BREEZY_LOGO_URL =
  'https://s3.us-west-1.amazonaws.com/www.getbreezyapp.com/images/Breezy-01.png'
export const BreezyFlatfileTheme = {
  root: {
    primaryColor: tailwindConfig.theme.extend.colors['daybreak-blue'][500],
  },
  sidebar: {
    logo: BREEZY_LOGO_URL,
    primaryColor: tailwindConfig.theme.extend.colors['daybreak-blue'][500],
    activeTextColor: tailwindConfig.theme.extend.colors['daybreak-blue'][500],
  },
}
