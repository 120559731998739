import {
  nextGuid,
  PricebookCategory,
  PricebookCategoryGuid,
} from '@breezy/shared'
import { Popconfirm } from 'antd'
import { useCallback, useState } from 'react'
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai'
import { useHover } from 'react-use'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { useModalState } from '../../utils/react-utils'
import {
  UpsertPricebookCategoryFormValues,
  UpsertPricebookCategoryModal,
} from './UpsertPricebookCategoryModal'
import { treeSearchedTitle } from './Utils/utils'

type PricebookCategoryNodeProps = {
  category: PricebookCategory
  searchValue: string
  refetch: () => void
  onNewChildCategoryAdded: (parentCategoryGuid: PricebookCategoryGuid) => void
}

export const PricebookCategoryNode = ({
  category,
  searchValue,
  refetch,
  onNewChildCategoryAdded,
}: PricebookCategoryNodeProps) => {
  const savePricebookCategory =
    trpc.pricebook['pricebook:save-category'].useMutation()
  const deletePricebookCategory =
    trpc.pricebook['pricebook:delete-category'].useMutation()

  const { companyGuid } = useExpectedCompany()

  const [isCreateOpen, openCreate, closeCreate] = useModalState(false)
  const [isEditOpen, openEdit, closeEdit] = useModalState(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)

  const createCategory = useCallback(
    (formValues: UpsertPricebookCategoryFormValues) => {
      savePricebookCategory.mutate(
        {
          name: formValues.name,
          companyGuid,
          pricebookCategoryGuid: nextGuid(),
          parentCategoryGuid: category.pricebookCategoryGuid,
        },
        {
          onSuccess: () => {
            closeCreate()
            refetch()
            onNewChildCategoryAdded(category.pricebookCategoryGuid)
          },
        },
      )
    },
    [
      savePricebookCategory,
      companyGuid,
      category.pricebookCategoryGuid,
      closeCreate,
      refetch,
      onNewChildCategoryAdded,
    ],
  )

  const editCategory = useCallback(
    (formValues: UpsertPricebookCategoryFormValues) => {
      savePricebookCategory.mutate(
        {
          name: formValues.name,
          companyGuid,
          pricebookCategoryGuid: category.pricebookCategoryGuid,
          parentCategoryGuid: category.parentCategoryGuid,
          sourcePhotoGuid: formValues.sourcePhotoGuid,
          photoGuid: formValues.photoGuid,
        },
        {
          onSuccess: () => {
            closeEdit()
            refetch()
          },
        },
      )
    },
    [
      savePricebookCategory,
      companyGuid,
      category.pricebookCategoryGuid,
      category.parentCategoryGuid,
      closeEdit,
      refetch,
    ],
  )

  const deleteCategory = useCallback(() => {
    deletePricebookCategory.mutate(
      {
        companyGuid,
        pricebookCategoryGuid: category.pricebookCategoryGuid,
      },
      {
        onSuccess: () => {
          refetch()
        },
      },
    )
  }, [deletePricebookCategory, category, refetch, companyGuid])

  const element = (hovered: boolean) => (
    <div>
      {treeSearchedTitle(category.name, searchValue)}{' '}
      {hovered || isCreateOpen || isEditOpen || isDeleteOpen ? (
        <span className="inline-flex items-center gap-3">
          <AiOutlinePlus onClick={openCreate} />
          <AiOutlineEdit onClick={openEdit} />
          <Popconfirm
            title="Are you sure you want to delete this category?"
            placement="bottom"
            okType="danger"
            onOpenChange={setIsDeleteOpen}
            onConfirm={deleteCategory}
          >
            <AiOutlineDelete />
          </Popconfirm>
        </span>
      ) : null}
      {isCreateOpen && (
        <UpsertPricebookCategoryModal
          onClose={closeCreate}
          onSubmit={createCategory}
          mode="create"
          loading={savePricebookCategory.isLoading}
        />
      )}
      {isEditOpen && (
        <UpsertPricebookCategoryModal
          onClose={closeEdit}
          onSubmit={editCategory}
          mode="edit"
          initialValues={{
            name: category.name,
            sourcePhotoGuid: category.sourcePhotoGuid,
            sourcePhotoUrl: category.sourcePhotoUrl,
            photoGuid: category.photoGuid,
            photoUrl: category.photoUrl,
          }}
          loading={savePricebookCategory.isLoading}
        />
      )}
    </div>
  )

  const [hoverable] = useHover(element)

  return hoverable
}
