import { AccountType, nextGuid, PricebookTaxRateGuid } from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, ConfigProvider, Divider, Form, Select, Switch } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { CheckboxButtonField } from '../../../../elements/Forms/CheckboxButtonField'
import { NumberField } from '../../../../elements/Forms/NumberField'
import { RadioButtonField } from '../../../../elements/Forms/RadioButtonField'
import { ReactHookFormItem } from '../../../../elements/Forms/ReactHookFormItem'
import { TextField } from '../../../../elements/Forms/TextField'
import { useReactHookFormSubmit } from '../../../../elements/Forms/useReactHookFormSubmit'
import { trpc } from '../../../../hooks/trpc'
import { useExpectedCompanyGuid } from '../../../../providers/PrincipalUser'
import { useMessage } from '../../../../utils/antd-utils'
import { usePricebookAdmin } from '../../hooks/usePricebookAdmin'

const SET_AS_DEFAULT_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

const ACCOUNT_TYPES_OPTIONS = [
  { label: 'Residential', value: AccountType.RESIDENTIAL },
  { label: 'Commercial', value: AccountType.COMMERCIAL },
]

const TaxRateEditDataSchema = z.object({
  name: z.string().min(1),
  percentage: z.number(),
  isDefault: z.boolean(),
  deactivate: z.boolean(),
  accountTypes: z.array(z.nativeEnum(AccountType)).default([]),
  zipCodes: z
    .array(
      z
        .string()
        .min(5, 'Zip code must be 5 characters long')
        .max(5, 'Zip code must be 5 characters long'),
    )
    .default([]),
})

type TaxRateEditData = z.infer<typeof TaxRateEditDataSchema>
type UpsertPricebookTaxRateFormProps = {
  initialValues?: Partial<TaxRateEditData> & {
    pricebookTaxRateGuid?: PricebookTaxRateGuid
  }
}
export const UpsertPricebookTaxRateForm =
  React.memo<UpsertPricebookTaxRateFormProps>(({ initialValues }) => {
    const companyGuid = useExpectedCompanyGuid()
    const { closeDrawer, refetchAll } = usePricebookAdmin()

    const defaultValues = useMemo(
      () => ({
        name: initialValues?.name ?? '',
        percentage: initialValues?.percentage ?? 0,
        isDefault: initialValues?.isDefault ?? false,
        deactivate: initialValues?.deactivate ?? false,
        accountTypes: initialValues?.accountTypes ?? [],
        zipCodes: initialValues?.zipCodes ?? [],
      }),
      [
        initialValues?.accountTypes,
        initialValues?.deactivate,
        initialValues?.isDefault,
        initialValues?.name,
        initialValues?.percentage,
        initialValues?.zipCodes,
      ],
    )

    const {
      control,
      formState: { errors },
      handleSubmit,
    } = useForm<TaxRateEditData>({
      resolver: zodResolver(TaxRateEditDataSchema),
      defaultValues,
    })

    const savePricebookItemMutation =
      trpc.pricebook['pricebook:save-tax-rate'].useMutation()

    const message = useMessage()

    const onSubmit = useCallback(
      async (data: TaxRateEditData) => {
        const { percentage, deactivate, ...rest } = data

        try {
          await savePricebookItemMutation.mutateAsync({
            companyGuid,
            pricebookTaxRateGuid:
              initialValues?.pricebookTaxRateGuid ?? nextGuid(),
            isActive: !deactivate,
            rate: percentage / 100,
            ...rest,
          })
          message.success('Successfully updated Tax Rate')
          refetchAll()
          closeDrawer()
        } catch (e) {
          console.error(e)
          message.error(
            'There was an error updating the Pricebook Tax Rate. Please try again.',
          )
        }
      },
      [
        closeDrawer,
        companyGuid,
        initialValues?.pricebookTaxRateGuid,
        message,
        refetchAll,
        savePricebookItemMutation,
      ],
    )

    const [submitElement, triggerSubmit] = useReactHookFormSubmit()

    return (
      <Form layout="vertical" onSubmitCapture={handleSubmit(onSubmit)}>
        <div className="flex flex-row space-x-3 *:flex-1 *:basis-0">
          <ReactHookFormItem
            control={control}
            name="name"
            label="Name"
            required
            errors={errors}
            render={({ field }) => <TextField {...field} />}
          />
          <ReactHookFormItem
            control={control}
            name="percentage"
            label="Percentage"
            required
            errors={errors}
            render={({ field }) => (
              <NumberField {...field} suffix="%" max={100} />
            )}
          />
        </div>
        <ReactHookFormItem
          required
          control={control}
          name="isDefault"
          label="Set as Default?"
          errors={errors}
          render={({ field }) => (
            <RadioButtonField
              optionType="button"
              size="large"
              {...field}
              options={SET_AS_DEFAULT_OPTIONS}
            />
          )}
        />
        <Divider />
        <div className="mb-4">
          Automatically apply this tax rate to Estimates and Invoices that meet
          any of the following criteria:
        </div>
        <ReactHookFormItem
          control={control}
          name="accountTypes"
          label="Account Types"
          errors={errors}
          render={({ field }) => (
            <CheckboxButtonField {...field} options={ACCOUNT_TYPES_OPTIONS} />
          )}
        />
        <ReactHookFormItem
          control={control}
          name="zipCodes"
          label="Zip Codes"
          errors={errors}
          render={({ field }) => (
            <ConfigProvider
              renderEmpty={() => <div className="p-2">Type a zip code</div>}
            >
              <Select
                mode="tags"
                placeholder="Add a zip code"
                {...field}
                popupMatchSelectWidth={false}
              />
            </ConfigProvider>
          )}
        />
        <Divider />
        <div className="flex flex-row items-center justify-between">
          <Controller
            control={control}
            name="deactivate"
            rules={{ required: false }}
            render={args => {
              return (
                <div className="flex flex-row items-center">
                  <Switch
                    checked={args.field.value}
                    onChange={e => {
                      args.field.onChange(e)
                    }}
                  />
                  <div
                    className="ml-2 cursor-pointer font-semibold"
                    onClick={() => args.field.onChange(!args.field.value)}
                  >
                    Deactivate Item
                  </div>
                </div>
              )
            }}
          />
          <div className="flex flex-row space-x-3">
            <Button size="large" onClick={closeDrawer}>
              Cancel
            </Button>
            <Button size="large" type="primary" onClick={triggerSubmit}>
              {initialValues?.pricebookTaxRateGuid ? 'Update' : 'Add'}
            </Button>
          </div>
        </div>
        {submitElement}
      </Form>
    )
  })
