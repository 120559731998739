import { formatPercentage } from '@breezy/shared'
import { Col, Row } from 'antd'
import { memo, useMemo } from 'react'
import { Tile } from '../Tile'
import { TeamPerformanceDivider } from './TeamPerformanceDivider'
import { TeamPerformanceJob } from './team-performance-queries.gql'

type JobConversionRateTileProps = {
  jobs: TeamPerformanceJob[]
  loading: boolean
}

export const JobConversionRateTile = memo<JobConversionRateTileProps>(
  ({ jobs, loading }) => {
    const allJobsConversionRate = useMemo(() => {
      const numConverted = jobs.filter(job => job.isConverted).length
      return jobs.length > 0 ? numConverted / jobs.length : 0
    }, [jobs])

    const opportunityConversionRate = useMemo(() => {
      const opportunities = jobs.filter(job => job.isOpportunity)
      const numConverted = opportunities.filter(job => job.isConverted).length
      return opportunities.length > 0 ? numConverted / opportunities.length : 0
    }, [jobs])

    const hotLeadConversionRate = useMemo(() => {
      const hotLeads = jobs.filter(job => job.isHotLead)
      const numConverted = hotLeads.filter(job => job.isConverted).length
      return hotLeads.length > 0 ? numConverted / hotLeads.length : 0
    }, [jobs])

    return (
      <Tile title="Job Conversion Rate" loading={loading}>
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">All Jobs</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {formatPercentage(allJobsConversionRate)}
          </Col>
        </Row>
        <TeamPerformanceDivider />
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">Opportunities</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {formatPercentage(opportunityConversionRate)}
          </Col>
        </Row>
        <TeamPerformanceDivider />
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">Hot Leads</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {formatPercentage(hotLeadConversionRate)}
          </Col>
        </Row>
      </Tile>
    )
  },
)
