import { PageHeader } from '@ant-design/pro-components'
import { PermissionV2 } from '@breezy/shared'
import { faDownload, faTags, faUpload } from '@fortawesome/pro-light-svg-icons'
import { faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, MenuProps, Space, Tabs } from 'antd'
import { useMemo } from 'react'
import { AiOutlineBars, AiOutlineDown } from 'react-icons/ai'
import { useMutation } from 'urql'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'
import { gql } from '../../generated'
import { useIntercom } from '../../hooks/useIntercom'
import { useIsPricebookPhotosEnabled } from '../../hooks/useIsPricebookPhotosEnabled'
import {
  useExpectedCompanyGuid,
  useIsImpersonating,
} from '../../providers/PrincipalUser'
import { BehindFeatureFlag } from '../BehindFeatureFlag'
import { FeatureEnableView } from '../FeatureEnableView/FeatureEnableView'
import { Page } from '../Page/Page'
import { Authorized } from '../Permissions/Authorized/Authorized'
import './PricebookAdmin.less'
import { useBulkExportModal } from './hooks/useBulkExportModal'
import { useBulkImportWizard } from './hooks/useBulkImportWizard'
import {
  PricebookAdminDrawerMode,
  PricebookAdminProvider,
  PricebookManagedType,
  pricebookManagedTypeDetails,
  usePricebookAdmin,
} from './hooks/usePricebookAdmin'
import { usePricebookAdminDrawer } from './hooks/usePricebookAdminDrawer'

export const UPSERT_COMPANY_CONFIG_PRICEBOOK_PHOTOS_ENABLED_MUTATION =
  gql(/* GraphQL */ `
    mutation UpsertCompanyConfigPricebookPhotosEnabled(
      $config: CompanyConfigInsertInput!
    ) {
      insertCompanyConfigOne(
        object: $config
        onConflict: {
          constraint: company_config_pkey
          updateColumns: [pricebookPhotosEnabled]
        }
      ) {
        __typename
      }
    }
  `)

export function PricebookAdmin() {
  const {
    drawerMode,
    pricebookItems,
    categories,
    startAddNewServiceItemFlow,
    startAddNewMaterialItemFlow,
    startAddNewEquipmentItemFlow,
    startAddNewLaborItemFlow,
    startAddNewTaxRateItemFlow,
    startAddNewDiscountItemFlow,
    pricebookVersionHistoryClicked,
    startManageCategoriesFlow,
  } = usePricebookAdmin()
  const isImpersonating = useIsImpersonating()
  const companyGuid = useExpectedCompanyGuid()

  const {
    pricebookPhotosEnabled,
    fetching: fetchingPricebookConfig,
    refetch,
  } = useIsPricebookPhotosEnabled()

  const [{ fetching: upsertingPricebookConfig }, upsert] = useMutation(
    UPSERT_COMPANY_CONFIG_PRICEBOOK_PHOTOS_ENABLED_MUTATION,
  )

  const fetching = fetchingPricebookConfig || upsertingPricebookConfig

  const newItemMenu = useMemo<MenuProps>(
    () => ({
      onClick: ({ key }) => {
        switch (key as PricebookManagedType) {
          case PricebookManagedType.SERVICE:
            startAddNewServiceItemFlow()
            break
          case PricebookManagedType.MATERIAL:
            startAddNewMaterialItemFlow()
            break
          case PricebookManagedType.EQUIPMENT:
            startAddNewEquipmentItemFlow()
            break
          case PricebookManagedType.LABOR:
            startAddNewLaborItemFlow()
            break
          case PricebookManagedType.TAX_RATE:
            startAddNewTaxRateItemFlow()
            break
          case PricebookManagedType.DISCOUNT:
            startAddNewDiscountItemFlow()
            break
          default:
            throw new Error(`MissingCaseException: ${key}`)
        }

        return
      },
      items: pricebookManagedTypeDetails
        .filter(p => !p.excludedFromAddNewItemMenu)
        .map(pricebookManagedType => {
          return {
            label: `New ${pricebookManagedType.singular}${
              pricebookManagedType.isItem ? ' item' : ''
            }`,
            key: pricebookManagedType.type,
          }
        }),
    }),
    [
      startAddNewDiscountItemFlow,
      startAddNewEquipmentItemFlow,
      startAddNewLaborItemFlow,
      startAddNewMaterialItemFlow,
      startAddNewServiceItemFlow,
      startAddNewTaxRateItemFlow,
    ],
  )

  const { drawer } = usePricebookAdminDrawer()
  const { toggleWizard: toggleImport, wizard } = useBulkImportWizard()
  const { toggle: toggleExport, ExportModal } = useBulkExportModal()

  useIntercom({
    isLauncherVisible: drawerMode === PricebookAdminDrawerMode.CLOSED,
  })

  if (!pricebookItems || !categories) return null

  return (
    <Page requiresCompanyUser className="flex flex-col p-0">
      <PageHeader
        title={<PageTitle title="Pricebook" icon={faTags} />}
        extra={
          <div className="flex gap-2">
            <Authorized
              to={PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_PRICEBOOK}
              overrideAuthorized={isImpersonating}
            >
              <>
                <Button
                  onClick={toggleImport}
                  icon={<FontAwesomeIcon icon={faUpload} />}
                >
                  Import
                </Button>

                <Button
                  onClick={toggleExport}
                  icon={<FontAwesomeIcon icon={faDownload} />}
                >
                  Export
                </Button>
              </>
            </Authorized>

            <Dropdown menu={newItemMenu}>
              <Button type="primary" block>
                <Space>
                  Add New Item
                  <AiOutlineDown />
                </Space>
              </Button>
            </Dropdown>
          </div>
        }
      />
      <ScrollCard
        className="flex h-full w-full flex-col space-y-4"
        hasPageHeading
      >
        <BehindFeatureFlag
          enabledFeatureFlag="pricebook-photos"
          render={
            <FeatureEnableView
              featureTitle="Pricebook Photos"
              featureEnabled={pricebookPhotosEnabled}
              isLoading={fetching}
              setValue={async pricebookPhotosEnabled => {
                await upsert({
                  config: { companyGuid, pricebookPhotosEnabled },
                })
              }}
              refetch={refetch}
              toggleContainerClassName=""
            />
          }
          fallback={<></>}
        />
        <Tabs
          items={pricebookManagedTypeDetails
            .filter(detail => detail.table && detail.icon)
            .map(pricebookManagedType => {
              return {
                label: (
                  <span>
                    {pricebookManagedType.icon && (
                      <span style={{ marginRight: 5 }}>
                        {pricebookManagedType.icon}
                      </span>
                    )}
                    {pricebookManagedType.singular}
                  </span>
                ),
                key: pricebookManagedType.type,
                children: pricebookManagedType.table,
              }
            })}
          tabBarExtraContent={
            <div className="flex gap-2">
              <Button type={'text'} onClick={startManageCategoriesFlow}>
                <AiOutlineBars className="icon_bars" /> Categories
              </Button>

              <Button
                type={'text'}
                icon={<FontAwesomeIcon icon={faClockRotateLeft} />}
                onClick={pricebookVersionHistoryClicked}
              >
                Version History
              </Button>
            </div>
          }
        />
      </ScrollCard>
      {wizard}
      {drawer}
      {ExportModal}
    </Page>
  )
}

export const PricebookAdminPage = () => (
  <PricebookAdminProvider>
    <PricebookAdmin />
  </PricebookAdminProvider>
)
