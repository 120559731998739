import {
  BzDateFns,
  IsoDateString,
  TechnicianPerformanceReportRequest,
  TechnicianPerformanceReportRequestSchema,
  generateTechnicianPerformanceCsv,
} from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Col, DatePicker, Form } from 'antd'
import cn from 'classnames'
import dayjs from 'dayjs'
import { memo, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import { trpc } from '../../../hooks/trpc'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { useMessage } from '../../../utils/antd-utils'
import { downloadCsv } from '../../../utils/export-to-csv'

type GenerateReportModalProps = {
  open: boolean
  onClose: () => void
  initialDateRangeStart?: IsoDateString
  initialDateRangeEnd?: IsoDateString
}

/**
 * TODO: Some parts of this component are duplicated in `TechnicianPerformanceGenerateReportForm`.
 * Consider refactoring to avoid duplication.
 */
export const GenerateReportModal = memo<GenerateReportModalProps>(
  ({ open, onClose, initialDateRangeStart, initialDateRangeEnd }) => {
    const btnFormSubmit = useRef<HTMLInputElement>(null)
    const tzId = useExpectedCompanyTimeZoneId()
    const message = useMessage()
    const todaysDate = BzDateFns.format(BzDateFns.now(tzId), 'yyyy-MM-dd')

    const generateReportMut =
      trpc.technicianPerformance[
        'technician-performance:generate-report'
      ].useMutation()

    const form = useForm<TechnicianPerformanceReportRequest>({
      resolver: zodResolver(TechnicianPerformanceReportRequestSchema),
      defaultValues: {
        dateRange: {
          startAt: initialDateRangeStart,
          endAt: initialDateRangeEnd,
        },
      },
    })

    const onSubmit = form.handleSubmit(async values => {
      try {
        const report = await generateReportMut.mutateAsync({
          dateRange: values.dateRange,
        })

        downloadCsv(
          generateTechnicianPerformanceCsv(report, tzId),
          `technician-performance-report-${todaysDate}.csv`,
        )

        // Reset the form to non-dirty state without resetting values
        form.reset(form.getValues())

        message.success('Successfully generated Technician Performance report')

        onClose()
      } catch (e) {
        message.error(
          'Failed to generate Technician Performance report. Please reload the application and try again, or contact support if the problem persists.',
        )
        console.error(e)
      }
    })

    return (
      <OnsiteBasicModal
        open={open}
        size="large"
        onClose={onClose}
        header={'Generate Report'}
        footer={
          <div
            className={cn(
              'absolute inset-x-0 bottom-0 flex flex-row justify-between gap-2 bg-white px-6 pb-6 pt-4',
              'z-10 border-0 border-t-4 border-solid border-t-bz-gray-400',
            )}
          >
            <Button
              key="cancel"
              size="large"
              className="w-full font-semibold"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              key="completeJob"
              htmlType="button"
              type="primary"
              size="large"
              className="w-full font-semibold"
              onClick={() => {
                btnFormSubmit.current?.click()
              }}
            >
              Generate Report
            </Button>
          </div>
        }
      >
        <form
          className="bz-technician-performance-generate-report-form"
          onSubmit={onSubmit}
        >
          <Col span={24}>
            <Controller
              name="dateRange"
              control={form.control}
              render={({ field }) => (
                <Form.Item
                  className="bz-date-picker-form-item m-0"
                  label="Date Range"
                  colon={false}
                  required
                  validateStatus={form.formState.errors.dateRange && 'error'}
                  help={form.formState.errors.dateRange?.message}
                >
                  <DatePicker.RangePicker
                    className="w-full"
                    picker="date"
                    format="MM/DD/YYYY"
                    onChange={dates => {
                      const [start, end] = dates || []
                      field.onChange({
                        startAt: start
                          ? BzDateFns.formatISO(start.toDate(), tzId)
                          : undefined,
                        endAt: end
                          ? BzDateFns.formatISO(end.toDate(), tzId)
                          : undefined,
                      })
                    }}
                    value={[
                      field.value?.startAt
                        ? dayjs(BzDateFns.parseISO(field.value.startAt, tzId))
                        : null,
                      field.value?.endAt
                        ? dayjs(BzDateFns.parseISO(field.value.endAt, tzId))
                        : null,
                    ]}
                  />
                </Form.Item>
              )}
            />
            <div className="mt-1 leading-6 text-bz-gray-700">
              The report will be in .CSV format.
            </div>
            {/* Submitting our react-hook-form directly through antd component doesn't seem
                to work, so we create this hidden button with an attached ref so we can use it
                to trigger a form submission */}
            <input ref={btnFormSubmit} type="submit" className="hidden" />
          </Col>
        </form>
      </OnsiteBasicModal>
    )
  },
)
