import {
  BzDateTime,
  IsoDateString,
  R,
  ReportingDateRange,
  Technician,
  TimePastNReportRequest,
  UTC_TIME_ZONE,
  formatPercentage,
  getDateGroupingTypeForRange,
  getPastNReportingTimeWindowsFor,
  isNullish,
} from '@breezy/shared'
import { memo, useMemo } from 'react'
import { LineChart, LineData } from '../../../elements/Charts/LineChart'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { getStandardXAxisValue, useStandardXAxisFormatter } from '../utils'
import { TeamPerformanceJob } from './team-performance-queries.gql'

type IndividualTechnicianPerformanceConversionRateChartProps = {
  technicianUserGuidToTechnicianMap: Record<string, Technician>
  technicianUserGuidToAssignedJobsMap: Record<string, TeamPerformanceJob[]>
  dateRange: ReportingDateRange
  timeUnit: TimePastNReportRequest['timeUnit']
  timePastN: TimePastNReportRequest['timePastN']
}

type ConversionRateChartData = {
  readonly periodStart: IsoDateString
  readonly periodEnd: IsoDateString
  readonly technicianGuidToConversionRateMap: Record<string, number>
}

const Y_AXIS_FORMATTER = (conversionRate: number) =>
  formatPercentage(conversionRate)

export const IndividualTechnicianPerformanceConversionRateChart =
  memo<IndividualTechnicianPerformanceConversionRateChartProps>(
    ({
      technicianUserGuidToTechnicianMap,
      technicianUserGuidToAssignedJobsMap,
      dateRange,
      timeUnit,
      timePastN,
    }) => {
      const tzId = useExpectedCompanyTimeZoneId()

      const reportingTimeWindows = useMemo(
        () =>
          getPastNReportingTimeWindowsFor(
            {
              timeUnit: timeUnit,
              timePastN: timePastN,
            },
            UTC_TIME_ZONE,
          ),
        [timePastN, timeUnit],
      )

      const reportingData = useMemo(() => {
        const data: Record<IsoDateString, ConversionRateChartData> = {}

        for (let i = 0; i < reportingTimeWindows.length; ++i) {
          const { start, end } = reportingTimeWindows[i]

          const startDateTime = BzDateTime.fromIsoString(start, tzId)
          const endDateTime = BzDateTime.fromIsoString(end, tzId)

          const technicianGuidToConversionRateMap: Record<string, number> = {}
          Object.entries(technicianUserGuidToAssignedJobsMap).forEach(
            ([technicianUserGuid, allAssignedJobs]) => {
              const assignedJobsForThisTimeWindow = allAssignedJobs.filter(
                job => {
                  if (isNullish(job.workCompletedAt)) {
                    return false
                  }

                  const workCompletedAtDateTime = BzDateTime.fromIsoString(
                    job.workCompletedAt,
                    tzId,
                  )
                  if (
                    workCompletedAtDateTime.isAfter(startDateTime) &&
                    workCompletedAtDateTime.isBefore(endDateTime)
                  ) {
                    return true
                  }

                  return false
                },
              )

              const convertedJobsInThisTimeWindow =
                assignedJobsForThisTimeWindow.filter(job => job.isConverted)

              technicianGuidToConversionRateMap[technicianUserGuid] =
                assignedJobsForThisTimeWindow.length === 0
                  ? 0
                  : convertedJobsInThisTimeWindow.length /
                    assignedJobsForThisTimeWindow.length
            },
          )

          data[start] = {
            periodStart: start,
            periodEnd: end,
            technicianGuidToConversionRateMap,
          }
        }

        return data
      }, [reportingTimeWindows, technicianUserGuidToAssignedJobsMap, tzId])

      const xAxisValues = useMemo(() => {
        const orderedBucketKeys = R.keys(reportingData).sort()

        return orderedBucketKeys.map(bucketTimestamp =>
          getStandardXAxisValue(
            tzId,
            bucketTimestamp,
            getDateGroupingTypeForRange(dateRange, tzId),
          ),
        )
      }, [dateRange, reportingData, tzId])

      const xAxisFormatter = useStandardXAxisFormatter(dateRange, tzId)

      const lineChartData = useMemo<LineData[]>(() => {
        const orderedBucketKeys = R.keys(reportingData).sort()

        const chartData: LineData[] = []

        Object.entries(technicianUserGuidToTechnicianMap).forEach(
          ([technicianUserGuid, technician]) => {
            const technicianConversionRateData = orderedBucketKeys.map(
              bucketTimestamp => {
                return reportingData[bucketTimestamp]
                  .technicianGuidToConversionRateMap[technicianUserGuid]
              },
            )

            chartData.push({
              label: `${
                technician.user.firstName
              } ${technician.user.lastName.substring(0, 1)}.`,
              data: technicianConversionRateData,
              renderTooltipValue: (conversionRate: number) =>
                formatPercentage(conversionRate),
            })
          },
        )

        return chartData
      }, [reportingData, technicianUserGuidToTechnicianMap])

      return (
        <LineChart
          data={lineChartData}
          yAxisFormatter={Y_AXIS_FORMATTER}
          yAxisDomain={[0, 'dataMax']}
          yAxisTicks={[0, 0.25, 0.5, 0.75, 1]}
          xAxisValues={xAxisValues}
          xAxisFormatter={xAxisFormatter}
          showLegend={true}
          allowDecimals={true}
        />
      )
    },
  )
