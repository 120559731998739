import { PageHeader } from '@ant-design/pro-components'
import { PermissionV2, RecommendationAnalyticsRow } from '@breezy/shared'
import { faChartMixed } from '@fortawesome/pro-light-svg-icons'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Page } from '../../components/Page/Page'
import Authenticated from '../../components/Permissions/Authenticated/Authenticated'
import { Authorized } from '../../components/Permissions/Authorized/Authorized'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import {
  localDateColumn,
  numberColumn,
} from '../../components/datatables/CommonColumnDefinitions'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { trpc } from '../../hooks/trpc'
import { m } from '../../utils/react-utils'

type RecommendationAnalyticsTableProps = {
  companyName: string
  data: RecommendationAnalyticsRow[]
}

type TableItemType = RecommendationAnalyticsRow

const tableColumns: ColumnsType<TableItemType> = [
  localDateColumn('Date', 'localDate'),
  numberColumn('Types', 'numRecommendationTypes'),
  numberColumn('Recommendations', 'numRecommendationsGenerated'),
  numberColumn('Completed', 'numRecommendationsCompleted'),
  numberColumn('Interacted', 'numRecommendationsInteracted'),
  numberColumn('Dismissed', 'numRecommendationsDismissed'),
  numberColumn('Users Interacted', 'numUniqueUsersInteracted'),
  numberColumn('Interactions', 'numInteractions'),
  numberColumn('Dismissals', 'numDismissals'),
]

const RecommendationAnalyticsTable = m<RecommendationAnalyticsTableProps>(
  ({ data, companyName }) => {
    return (
      <div className="column">
        <h2>{companyName}</h2>
        <Table
          rowKey="localDate"
          dataSource={data}
          scroll={{ x: true }}
          columns={tableColumns}
          pagination={{ pageSize: 100 }}
          size="small"
        />
      </div>
    )
  },
)

const RecommendationAnalytics = () => {
  const query =
    trpc.devTools['devtools:recommendation-analytics'].useQuery(undefined)

  return (
    <TrpcQueryLoader
      query={query}
      render={data => {
        const byCompany = data.reduce((acc, row) => {
          if (!acc[row.companyGuid]) acc[row.companyGuid] = []
          acc[row.companyGuid].push(row)
          return acc
        }, {} as Record<string, RecommendationAnalyticsRow[]>)
        return (
          <>
            {Object.values(byCompany).map(v => {
              return (
                <RecommendationAnalyticsTable
                  companyName={v[0].companyName}
                  data={v}
                />
              )
            })}
          </>
        )
      }}
    />
  )
}

export const RecommendationsAnalyticsPage = () => {
  return (
    <Page requiresCompanyUser={false}>
      <Authenticated>
        <Authorized to={PermissionV2.EXECUTE_DEV_TOOLS} allowsNonCompanyUser>
          <div className="card-no-fixed-height pt-2">
            <PageHeader
              className="pl-0"
              title={
                <PageTitle
                  title="Analytics - Recommendations"
                  icon={faChartMixed}
                />
              }
            />
            <RecommendationAnalytics />
          </div>
        </Authorized>
      </Authenticated>
    </Page>
  )
}
