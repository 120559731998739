import { PricebookDiscountDescriptionSchema } from '@breezy/shared'
import { Form, Input } from 'antd'

export const PricebookFormDiscountDescription = () => {
  return (
    <Form.Item
      name="description"
      label="Description"
      required
      rules={[
        {
          required: true,
          validator: async (_, value) => {
            if (!value) {
              return Promise.reject(new Error('Description is required'))
            }
            const result = PricebookDiscountDescriptionSchema.safeParse(value)
            if (!result.success) {
              throw new Error(
                result.error.issues[0]?.message || result.error.message,
              )
            }
          },
        },
      ]}
    >
      <Input.TextArea rows={6} />
    </Form.Item>
  )
}
