import { useCallback, useEffect } from 'react'
import { useBlocker } from '../providers/BlockerWrapper'

// https://github.com/remix-run/react-router/discussions/10489#discussioncomment-6407125
export function usePrompt({
  when,
  message,
  key,
}: {
  when: boolean
  message: string
  key: string
}) {
  const shouldBlock = useCallback(() => when, [when])
  const blocker = useBlocker(key, shouldBlock)

  useEffect(() => {
    if (blocker.state === 'blocked') {
      const proceed = window.confirm(message)
      if (proceed) {
        blocker.proceed()
      } else {
        blocker.reset()
      }
    }
  }, [blocker, message])

  useEffect(() => {
    if (blocker.state === 'blocked' && !when) {
      blocker.reset()
    }
  }, [blocker, when])
}
