import { formatThousandsAsK, getDateGroupingTypeForRange } from '@breezy/shared'
import { faArrowTrendUp } from '@fortawesome/pro-light-svg-icons'
import React, { useMemo } from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Card } from '../../elements/Card/Card'
import { LineChart } from '../../elements/Charts/LineChart'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { NoDataOverlay } from './NoDataOverlay'
import {
  StandardReportingDateRangePicker,
  useStandardReportingDateRangePickerState,
} from './ReportingDateRangePicker/StandardReportingDateRangePicker'
import { DUMMY_BACKGROUND_BUCKETED_EARNED_REVENUE_DATA } from './dummyBackgroundData'
import { getStandardXAxisValue, useStandardXAxisFormatter } from './utils'

const Y_AXIS_FORMATTER = (value: number) => `$${formatThousandsAsK(value)}`

type EarnedRevenueWidgetProps = {
  // Not optional. I'm expecting at least a fixed height class
  className: string
}
export const EarnedRevenueWidget = React.memo<EarnedRevenueWidgetProps>(
  ({ className }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const [dateRange, setDateRange] =
      useStandardReportingDateRangePickerState('Last 3 months')

    const bucketedEarnedRevenueQuery = trpc.reporting[
      'bucketed-earned-revenue:get'
    ].useQuery({ dateRange })

    const hasRevenue = useMemo(() => {
      for (const bucket of bucketedEarnedRevenueQuery.data ?? []) {
        if (bucket.value > 0) {
          return true
        }
      }
      return false
    }, [bucketedEarnedRevenueQuery.data])

    const groupingType = useMemo(
      () => getDateGroupingTypeForRange(dateRange, tzId),
      [dateRange, tzId],
    )

    const [xAxisValues, data, totalRevenue] = useMemo(() => {
      const xAxisValues: string[] = []
      const values: number[] = []

      let totalRevenue = 0

      let data = DUMMY_BACKGROUND_BUCKETED_EARNED_REVENUE_DATA
      if (hasRevenue && bucketedEarnedRevenueQuery.data) {
        data = bucketedEarnedRevenueQuery.data
      }

      for (const datum of data) {
        xAxisValues.push(getStandardXAxisValue(tzId, datum.date, groupingType))
        values.push(datum.value)
        totalRevenue += datum.value
      }

      return [xAxisValues, values, totalRevenue]
    }, [bucketedEarnedRevenueQuery.data, groupingType, hasRevenue, tzId])

    const lineChartData = useMemo(
      () => [
        {
          label: 'Revenue',
          data,
          renderTooltipValue: (value: number) =>
            value.toLocaleString('en-us', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: Number.isInteger(value) ? 0 : 2,
            }),
        },
      ],
      [data],
    )

    const xAxisFormatter = useStandardXAxisFormatter(dateRange, tzId)

    const hasNoDataOverlay = !hasRevenue

    return (
      <Card
        title="Historical Revenue"
        infoNextToTitle
        // The ".slice(0, -1)" is to get rid of the "s" at the end of days/weeks/months
        info={`Does not include the current ${groupingType.slice(0, -1)}`}
        className={className}
        titleAction={
          !hasNoDataOverlay && (
            <StandardReportingDateRangePicker
              range={dateRange}
              setRange={setDateRange}
            />
          )
        }
      >
        {bucketedEarnedRevenueQuery.isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="flex h-full flex-col">
              <div className="pb-4 text-base font-semibold text-bz-gray-900">
                Total: $
                {totalRevenue.toLocaleString('en-us', {
                  maximumFractionDigits: 0,
                })}
              </div>
              <div className="flex-1">
                <LineChart
                  data={lineChartData}
                  yAxisFormatter={Y_AXIS_FORMATTER}
                  xAxisValues={xAxisValues}
                  xAxisFormatter={xAxisFormatter}
                />
              </div>
            </div>
            {hasNoDataOverlay && (
              <NoDataOverlay
                className="inset-[-16px]"
                icon={faArrowTrendUp}
                title="No revenue to report on"
                link={{ to: '/jobs', text: 'View jobs' }}
              >
                <div className="text-center">
                  Create a job or collect payment from an invoice to display
                  revenue here.
                </div>
              </NoDataOverlay>
            )}
          </>
        )}
      </Card>
    )
  },
)
