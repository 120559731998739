import { Button, ButtonProps } from 'antd'
import classNames from 'classnames'
import React from 'react'

type CommsPageTextActionButtonProps = ButtonProps

export const CommsPageTextActionButton =
  React.memo<CommsPageTextActionButtonProps>(({ className, ...rest }) => (
    <Button
      {...rest}
      className={classNames(
        'rounded-md border',
        'text-bz-gray-900 hover:text-bz-primary-hover',
        className,
      )}
    />
  ))
