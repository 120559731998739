import {
  CompanyCreatorV2Input,
  RoleId,
  isNullish,
  toCamelCase,
} from '@breezy/shared'
import { faUpload } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Collapse, Upload, UploadFile } from 'antd'
import Papa from 'papaparse'
import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useMessage } from 'src/utils/antd-utils'
import { fromError } from 'zod-validation-error'
import { teamMembersFormSchema } from '../schema'
import { TeamMembersUserCollapsibleItem } from './TeamMembersUserCollapsibleItem'

export const CreateCompanyDrawerTeamMembersSection = () => {
  const message = useMessage()

  const { setValue, watch, reset } = useFormContext<CompanyCreatorV2Input>()

  const formValues = watch()

  const onFileUploaded = useCallback(
    (file: UploadFile, fileList: UploadFile[]) => {
      if (isNullish(file.originFileObj)) {
        message.error('Could not open file')
        return
      }

      if (fileList.length === 0) {
        return
      }

      Papa.parse(file.originFileObj as File, {
        header: true,
        transformHeader: header => toCamelCase(header),
        complete: results => {
          const parsed = teamMembersFormSchema.safeParse(results.data)
          if (!parsed.success) {
            message.error(
              `Failed to validate Team Members CSV with errors ${fromError(
                parsed.error,
              )}`,
            )
            reset({
              ...formValues,
              teamMembers: [],
            })
          } else {
            setValue(
              'teamMembers',
              parsed.data.map(teamMember => {
                const roles: RoleId[] = []

                if (teamMember['salesTech?'] === 'Yes') {
                  roles.push(RoleId.SALES_TECHNICIAN)
                }

                if (teamMember['maintenanceTech?'] === 'Yes') {
                  roles.push(RoleId.MAINTENANCE_TECHNICIAN)
                }

                if (teamMember['serviceTech?'] === 'Yes') {
                  roles.push(RoleId.SERVICE_TECHNICIAN)
                }

                if (teamMember['installTech?'] === 'Yes') {
                  roles.push(RoleId.INSTALL_TECHNICIAN)
                }

                if (teamMember['admin?'] === 'Yes') {
                  roles.push(RoleId.ADMIN)
                }

                if (teamMember['accountant?'] === 'Yes') {
                  roles.push(RoleId.ACCOUNTANT)
                }

                if (teamMember['dispatcher?'] === 'Yes') {
                  roles.push(RoleId.DISPATCHER)
                }

                if (teamMember['customerServiceRep?'] === 'Yes') {
                  roles.push(RoleId.CUSTOMER_SERVICE_REPRESENTATIVE)
                }

                return {
                  firstName: teamMember.firstName,
                  lastName: teamMember.lastName,
                  emailAddress: teamMember.emailAddress,
                  phoneNumber: teamMember['phone#'],
                  isSuperAdmin: teamMember['superAdmin?'] === 'Yes',
                  hasMobileAccess: teamMember['mobileAccess?'] === 'Yes',
                  hasDesktopAccess: teamMember['desktopAccess?'] === 'Yes',
                  roles,
                }
              }),
            )
          }
        },
      })
    },
    [formValues, message, reset, setValue],
  )

  const onFileRemoved = () => {
    reset({
      ...formValues,
      teamMembers: [],
    })
  }

  return (
    <div className="flex w-full flex-col gap-3 overflow-y-scroll">
      <label className="flex flex-col gap-2">
        <span>Select Team Members CSV</span>

        <Upload
          accept="text/csv"
          maxCount={1}
          customRequest={({ onSuccess }) => {
            onSuccess?.('ok')
          }}
          onChange={({ file, fileList }) => onFileUploaded(file, fileList)}
          onRemove={onFileRemoved}
        >
          <Button icon={<FontAwesomeIcon icon={faUpload} />}>
            Upload File
          </Button>
        </Upload>
      </label>

      {!isNullish(formValues.teamMembers) && (
        <Collapse
          items={formValues.teamMembers.map((teamMember, idx) => ({
            key: teamMember.emailAddress,
            label: `${teamMember.firstName} ${teamMember.lastName} (${teamMember.emailAddress})`,
            extra: <>{teamMember.isSuperAdmin && <span>Root User</span>}</>,
            children: <TeamMembersUserCollapsibleItem index={idx} />,
          }))}
        />
      )}
    </div>
  )
}
