import { CompanyCreatorV2Input } from '@breezy/shared'
import { Input } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'

export const CompanyDetailsSectionBillingProfileCollapsible = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext<CompanyCreatorV2Input>()

  return (
    <div className="flex w-full flex-col gap-3">
      <Controller
        control={control}
        name="billingProfile.fullLegalName"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Business Full Legal Name</span>
            <Input
              {...field}
              placeholder="Enter business' full legal name here..."
              status={
                errors.billingProfile?.fullLegalName ? 'error' : undefined
              }
            />
          </label>
        )}
      />

      <div className="flex flex-row gap-3">
        <Controller
          control={control}
          name="billingProfile.businessAddress.city"
          render={({ field }) => (
            <label className="flex flex-1 flex-col gap-1">
              <span>City</span>
              <Input
                {...field}
                placeholder="Enter business' city here..."
                status={
                  errors.billingProfile?.businessAddress?.city
                    ? 'error'
                    : undefined
                }
              />
            </label>
          )}
        />

        <Controller
          control={control}
          name="billingProfile.businessAddress.stateAbbreviation"
          render={({ field }) => (
            <label className="flex flex-1 flex-col gap-1">
              <span>State (abbreviation)</span>
              <Input
                {...field}
                placeholder="Enter business' state abbreviation here..."
                status={
                  errors.billingProfile?.businessAddress?.stateAbbreviation
                    ? 'error'
                    : undefined
                }
              />
            </label>
          )}
        />
      </div>

      <Controller
        control={control}
        name="billingProfile.emailAddress"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Email Address</span>
            <Input
              {...field}
              placeholder="Enter business email address here..."
              status={errors.billingProfile?.emailAddress ? 'error' : undefined}
            />
          </label>
        )}
      />

      <Controller
        control={control}
        name="billingProfile.businessAddress.line1"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Line 1</span>
            <Input
              {...field}
              placeholder="Enter business' first address here..."
              status={
                errors.billingProfile?.businessAddress?.line1
                  ? 'error'
                  : undefined
              }
            />
          </label>
        )}
      />

      <Controller
        control={control}
        name="billingProfile.businessAddress.line2"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Line 2</span>
            <Input
              {...field}
              placeholder="Enter business' second address here..."
              status={
                errors.billingProfile?.businessAddress?.line2
                  ? 'error'
                  : undefined
              }
            />
          </label>
        )}
      />

      <Controller
        control={control}
        name="billingProfile.businessAddress.zipCode"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Zip Code</span>
            <Input
              {...field}
              placeholder="Enter business' zip code here..."
              status={
                errors.billingProfile?.businessAddress?.zipCode
                  ? 'error'
                  : undefined
              }
            />
          </label>
        )}
      />
    </div>
  )
}
