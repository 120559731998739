import classNames from 'classnames'
import React from 'react'
import { Tag } from '../../components/Tags'
import { Link } from '../../elements/Link/Link'

const TEXT_SEPARATOR = <span className="mx-1 text-bz-gray-600">•</span>

type DetailsCellProps = {
  dropdown?: React.ReactNode
  link: {
    to: string
    label: string
  }
  detailItems: React.ReactNode[]
  footer?: React.ReactNode
  disabledTagContent?: string
}

export const DetailsCell = React.memo<DetailsCellProps>(
  ({ dropdown, link, detailItems, footer, disabledTagContent }) => {
    const disabled = !!disabledTagContent
    return (
      <div className="flex flex-row items-center pb-4 pt-3">
        {dropdown}
        <div className="ml-4">
          <div className="flex flex-row items-center">
            {/* The "main-detail-link" class is for cypress */}
            <Link
              bold
              blue={!disabled}
              className={classNames('main-detail-link text-base', {
                'opacity-60': disabled,
              })}
              to={link.to}
            >
              {link.label}
            </Link>
            {disabled ? (
              <Tag
                noPopover
                hideIcon
                compact
                tag={{ name: disabledTagContent }}
                className="ml-2"
                overrideStyle={{
                  textColor: 'text-bz-text-secondary',
                  border: 'border border-solid border-bz-text-tertiary',
                  bgColor: 'bg-bz-border-secondary',
                }}
              />
            ) : null}
          </div>
          <div
            className={classNames('text-bz-gray-900', {
              'opacity-60': disabled,
            })}
          >
            {detailItems.map((item, index) => (
              <React.Fragment key={index}>
                {index !== 0 ? TEXT_SEPARATOR : null}
                {item}
              </React.Fragment>
            ))}
          </div>
          {footer ? (
            <div
              className={classNames('mt-2', {
                'opacity-60': disabled,
              })}
            >
              {footer}
            </div>
          ) : null}
        </div>
      </div>
    )
  },
)
