import {
  BzDateFns,
  FREQUENCY_OPTIONS,
  IsoDateString,
  R,
  WEEKDAY_BUTTON_OPTIONS,
  getNthDOWOfMonth,
  getSuffixForNumber,
  isLastDOWOfMonth,
  splitOutUntil,
  toPlural,
} from '@breezy/shared'
import { Form, InputNumber, Radio, Select } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { Frequency, Options, RRule } from 'rrule'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { Styled } from '../../utils/Stylable'

const BY_MONTH_OPTIONS = ['BY_DAY', 'BY_WEEKDAY', 'BY_LAST_WEEKDAY'] as const
export type ByMonthOption = (typeof BY_MONTH_OPTIONS)[number]

type RecurrenceFormV2Props = Styled<{
  rrule: string
  startingDate: IsoDateString
  onChange: (rule: string) => void
  exclusiveDayOfWeek?: boolean
}>

export const RecurrenceFormV2 = React.memo<RecurrenceFormV2Props>(
  ({ rrule, onChange, className, startingDate, exclusiveDayOfWeek }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const date = useMemo(
      () => BzDateFns.parseISO(startingDate, tzId),
      [startingDate, tzId],
    )

    const startingDOW = useMemo(() => BzDateFns.getISODay(date) - 1, [date])

    const startingDayOfMonth = useMemo(() => BzDateFns.getDate(date), [date])

    const [ruleOptions, originalUntil] = useMemo(() => {
      // The RRule lib really struggles with "UNTIL" because it's very particular about the date format. That
      // information is irrelevant for the purposes of this form, however, so we can just chop it off and add it back
      // when we save.
      const [sanitizedRRule, originalUntil] = splitOutUntil(rrule)

      const rule = RRule.fromString(sanitizedRRule)

      const options = R.pick(
        ['freq', 'interval', 'byweekday', 'bysetpos', 'bymonthday'],
        rule.options,
      )

      if (options.freq !== RRule.WEEKLY) {
        options.byweekday = []
      } else if (!options.byweekday) {
        options.byweekday = [startingDOW]
      }

      return [options, originalUntil]
    }, [rrule, startingDOW])

    const { freq, interval, byweekday, bysetpos, bymonthday } = ruleOptions

    const weekdayMap = useMemo(
      () =>
        (byweekday ?? []).reduce(
          (acc, dow) => R.update(dow, true, acc),
          new Array(7).fill(false),
        ),
      [byweekday],
    )

    const byMonthOption = useMemo<ByMonthOption>(() => {
      if (bysetpos?.length) {
        if (bysetpos[0] === -1) {
          return 'BY_LAST_WEEKDAY'
        }
        return 'BY_WEEKDAY'
      }
      return 'BY_DAY'
    }, [bysetpos])

    const setRRule = useCallback(
      (options: Partial<Options>) => {
        let resolvedByMonthDay: number | number[] | null | undefined =
          'bymonthday' in options ? options.bymonthday : bymonthday
        if (!options.bymonthday && options.freq === Frequency.MONTHLY) {
          resolvedByMonthDay = startingDayOfMonth
        }
        const rule = RRule.optionsToString({
          ...ruleOptions,
          ...options,
          bymonthday: resolvedByMonthDay,
        })
        // The rule starts with "RRULE:" so the slice gets rid of that
        const str = rule.slice(6)
        onChange(originalUntil ? `${str};${originalUntil}` : str)
      },
      [bymonthday, onChange, originalUntil, ruleOptions, startingDayOfMonth],
    )

    const setWeekdayMap = useCallback(
      (dow: number) => {
        const newMap = [...weekdayMap]
        newMap[dow] = !newMap[dow]
        const byweekday: number[] = []
        for (const [i, selected] of newMap.entries()) {
          if (selected) {
            byweekday.push(i)
          }
        }
        setRRule({ byweekday })
      },
      [weekdayMap, setRRule],
    )

    const setByMonthOption = useCallback(
      (byMonthOption: ByMonthOption) => {
        if (byMonthOption === 'BY_DAY') {
          setRRule({
            bysetpos: null,
            byweekday: null,
            bymonthday: startingDayOfMonth,
          })
        } else if (byMonthOption === 'BY_WEEKDAY') {
          setRRule({
            bysetpos: getNthDOWOfMonth(date),
            byweekday: [startingDOW],
            bymonthday: null,
          })
        } else if (byMonthOption === 'BY_LAST_WEEKDAY') {
          setRRule({ bysetpos: -1, byweekday: [startingDOW], bymonthday: null })
        } else {
          console.error('Invalid byMonthOption:', byMonthOption)
        }
      },

      [date, setRRule, startingDOW, startingDayOfMonth],
    )

    const freqOptions = useMemo(
      () =>
        FREQUENCY_OPTIONS.map(({ value, label }) => ({
          value,
          label: toPlural(interval, label),
        })),
      [interval],
    )

    const byMonthSelectOptions = useMemo(() => {
      const dow = BzDateFns.format(date, 'eeee')
      const options: {
        value: ByMonthOption
        label: string
      }[] = [
        {
          value: 'BY_DAY',
          label: `The ${getSuffixForNumber(
            startingDayOfMonth,
          )} day of the month`,
        },
        {
          value: 'BY_WEEKDAY',
          label: `The ${getSuffixForNumber(
            getNthDOWOfMonth(date),
          )} ${dow} of the month`,
        },
      ]
      if (isLastDOWOfMonth(date)) {
        options.push({
          value: 'BY_LAST_WEEKDAY',
          label: `The last ${dow} of the month`,
        })
      }
      return options
    }, [date, startingDayOfMonth])

    return (
      <Form className={className} layout="vertical">
        <Form.Item label="Repeat Every">
          <div className="flex flex-row items-center justify-start space-x-2">
            <InputNumber
              min={1}
              defaultValue={1}
              value={interval}
              onChange={val => setRRule({ interval: val ?? 1 })}
            />
            <Select
              popupMatchSelectWidth={false}
              options={freqOptions}
              onChange={freq => setRRule({ freq })}
              value={freq}
              className="flex-[0]"
            />
            {freq === Frequency.MONTHLY && (
              <Select
                popupMatchSelectWidth={false}
                options={byMonthSelectOptions}
                onChange={setByMonthOption}
                value={byMonthOption}
              />
            )}
          </div>
        </Form.Item>
        {freq === Frequency.WEEKLY &&
          (exclusiveDayOfWeek ? (
            <Form.Item label="On">
              <Select
                popupMatchSelectWidth={false}
                className="max-w-fit"
                defaultValue={startingDOW}
                onChange={setWeekdayMap}
                options={WEEKDAY_BUTTON_OPTIONS.map(({ fullName }, i) => ({
                  label: fullName,
                  value: i,
                }))}
              />
            </Form.Item>
          ) : (
            <Form.Item label="On These Days">
              {/* I'm aware that radio buttons are meant to be mutually exclusive and what we really want is
                  "checkboxes" or a "button group". But antd doesn't provide a component that looks exactly like their
                  radio buttons but where multiple are selectable, so here we are. */}
              <Radio.Group size="large" value="">
                {WEEKDAY_BUTTON_OPTIONS.map(({ label }, i) => {
                  // The options start on Sunday, but the indexes of the days start on monday
                  const weekdayIndex = (7 + i - 1) % 7
                  return (
                    <Radio.Button
                      key={label}
                      onClick={() => setWeekdayMap(weekdayIndex)}
                      className={classNames(
                        'h-[30px] select-none pt-1 align-middle text-sm',
                        {
                          'ant-radio-button-wrapper-checked':
                            weekdayMap[weekdayIndex],
                        },
                      )}
                    >
                      {label}
                    </Radio.Button>
                  )
                })}
              </Radio.Group>
            </Form.Item>
          ))}
      </Form>
    )
  },
)
