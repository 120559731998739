import { isNullish } from '@breezy/shared'

/**
 * Converts an image blob to its base 64 encoded representation.
 *
 * `RcFile`, the type returned from Antd's `Upload` component, is compatable with `Blob`. `RcFile` is an extension of `File`,
 * which itself is a specific type of `Blob`.
 */
export const imageToBase64 = (image: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      if (isNullish(reader.result)) {
        reject('Result is null')
      } else {
        resolve(reader.result.toString())
      }
    }

    // This comes last in the case that readAsDataURL loads instantly. If it loads instantly and onload isn't set,
    // we won't be able to resolve the promise
    reader.readAsDataURL(image)
  })
}
