import { PageHeader } from '@ant-design/pro-components'
import { IsoDateString, isNullish, nextGuid } from '@breezy/shared'
import { faBillboard } from '@fortawesome/pro-light-svg-icons'
import { Button, Input, Tabs } from 'antd'
import { useMemo, useState } from 'react'
import { useMutation, useQuery } from 'urql'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import { Page } from '../../components/Page/Page'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'
import { useDebouncedSearchText } from '../../hooks/useDebouncedSearchText'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import {
  LEAD_SOURCES_QUERY,
  UPSERT_LEAD_SOURCE_MUTATION,
} from './LeadSourcesSettingsPage.gql'
import { ActiveLeadSources } from './components/ActiveLeadSources'
import { AddLeadSourceFormDrawer } from './components/AddLeadSourceDrawer'
import { ArchivedLeadSources } from './components/ArchivedLeadSources'
import { EditLeadSourceDrawer } from './components/EditLeadSourceDrawer'

export const LeadSourcesSettingsPage = () => {
  const { companyGuid } = useExpectedCompany()
  const message = useMessage()

  const [addLeadSourceDrawerOpen, setAddLeadSourceDrawerOpen] = useState(false)
  const [toEditLeadSource, setToEditLeadSource] = useState<string | null>(null)
  const { searchText, debouncedSearchText, onSearch } = useDebouncedSearchText({
    wait: 250,
  })

  const leadSourcesQuery = useQuery({
    query: LEAD_SOURCES_QUERY,
    variables: { companyGuid },
  })

  const [, upsertLeadSourceMutation] = useMutation(UPSERT_LEAD_SOURCE_MUTATION)

  const activeLeadSources = useMemo(() => {
    if (isNullish(leadSourcesQuery[0].data?.activeCompanyLeadSources)) {
      return []
    }

    return (
      leadSourcesQuery[0].data?.activeCompanyLeadSources.filter(leadSource => {
        const search = debouncedSearchText.trim().toLowerCase()
        if (!isNullish(leadSource.canonicalLeadSourceNameOverride)) {
          return leadSource.canonicalLeadSourceNameOverride
            .toLowerCase()
            .includes(search)
        }

        return leadSource.canonicalLeadSourceName.toLowerCase().includes(search)
      }) ?? []
    )
  }, [debouncedSearchText, leadSourcesQuery])

  const archivedLeadSources = useMemo(() => {
    if (isNullish(leadSourcesQuery[0].data?.archivedCompanyLeadSources)) {
      return []
    }

    return (
      leadSourcesQuery[0].data?.archivedCompanyLeadSources.filter(
        leadSource => {
          const search = debouncedSearchText.trim().toLowerCase()
          if (!isNullish(leadSource.canonicalLeadSourceNameOverride)) {
            return leadSource.canonicalLeadSourceNameOverride
              .toLowerCase()
              .includes(search)
          }

          return leadSource.canonicalLeadSourceName
            .toLowerCase()
            .includes(search)
        },
      ) ?? []
    )
  }, [debouncedSearchText, leadSourcesQuery])

  return (
    <Page className="flex flex-col p-0" requiresCompanyUser>
      <PageHeader
        title={<PageTitle title="Lead Sources" icon={faBillboard} />}
      />

      <ScrollCard
        className="mt-auto flex h-full w-full flex-col gap-3"
        hasPageHeading
      >
        <GqlQueryLoader
          query={leadSourcesQuery}
          render={() => (
            <>
              <span className="text-sm text-bz-gray-700">
                Lead sources are the places or ways where your company finds
                potential customers or leads, like online ads, social media,
                word-of-mouth referrals, or emails.
              </span>

              <Tabs
                destroyInactiveTabPane
                items={[
                  {
                    key: 'activeLeadSources',
                    label: 'Active',
                    children: (
                      <ActiveLeadSources
                        leadSources={activeLeadSources.map(leadSource => ({
                          leadSourceGuid: leadSource.companyLeadSourceGuid,
                          leadSourceType: leadSource.canonicalLeadSourceName,
                          name:
                            leadSource.canonicalLeadSourceNameOverride ??
                            leadSource.canonicalLeadSourceName,
                          ordinal:
                            leadSource.ordinal ??
                            leadSource.canonicalLeadSourceName,
                          count:
                            leadSource.companyLeadSourceLinksAggregate.aggregate
                              ?.count ?? 0,
                        }))}
                        onEditLeadSourceClicked={setToEditLeadSource}
                      />
                    ),
                  },
                  {
                    key: 'archivedLeadSources',
                    label: 'Archived',
                    children: (
                      <ArchivedLeadSources
                        leadSources={archivedLeadSources.map(leadSource => ({
                          leadSourceGuid: leadSource.companyLeadSourceGuid,
                          leadSourceType: leadSource.canonicalLeadSourceName,
                          name:
                            leadSource.canonicalLeadSourceNameOverride ??
                            leadSource.canonicalLeadSourceName,
                          ordinal:
                            leadSource.ordinal ??
                            leadSource.canonicalLeadSourceName,
                          count:
                            leadSource.companyLeadSourceLinksAggregate.aggregate
                              ?.count ?? 0,
                        }))}
                        onEditLeadSourceClicked={setToEditLeadSource}
                      />
                    ),
                  },
                ]}
                tabBarExtraContent={{
                  right: (
                    <div className="flex w-max flex-row gap-2">
                      <Input
                        className="w-52"
                        placeholder="Search for lead source..."
                        value={searchText}
                        onChange={onSearch}
                      />

                      <Button
                        type="primary"
                        onClick={() => setAddLeadSourceDrawerOpen(true)}
                      >
                        + Add Lead Source
                      </Button>
                    </div>
                  ),
                }}
              />

              <AddLeadSourceFormDrawer
                open={addLeadSourceDrawerOpen}
                onAddLeadSourceClicked={async ({
                  canonicalLeadSource,
                  overrides,
                }) => {
                  await upsertLeadSourceMutation({
                    companyGuid,
                    companyLeadSourceGuid: nextGuid(),
                    canonicalLeadSourceName:
                      canonicalLeadSource.canonicalLeadSourceName,
                    canonicalLeadSourceNameOverride: overrides.name ?? null,
                    attributionLinkingStrategyOverride:
                      overrides.attributionLinkingStrategy ?? null,
                    attributionPromptOverride:
                      overrides.attributionPrompt ?? null,
                  })

                  leadSourcesQuery[1]()
                  message.success(
                    `Created Lead Source "${
                      overrides.name ??
                      canonicalLeadSource.canonicalLeadSourceName
                    }"!`,
                  )
                  setAddLeadSourceDrawerOpen(false)
                }}
                onCancel={() => setAddLeadSourceDrawerOpen(false)}
              />

              <EditLeadSourceDrawer
                open={!isNullish(toEditLeadSource)}
                companyLeadSourceGuid={toEditLeadSource ?? ''}
                onEditLeadSourceClicked={async data => {
                  await upsertLeadSourceMutation({
                    companyGuid,
                    companyLeadSourceGuid: toEditLeadSource ?? '',
                    canonicalLeadSourceName: data.canonicalLeadSourceName,
                    canonicalLeadSourceNameOverride:
                      data.canonicalLeadSourceNameOverride ??
                      data.canonicalLeadSourceName,
                    attributionLinkingStrategyOverride:
                      data.attributionLinkingStrategyOverride ??
                      data.attributionLinkingStrategy,
                    attributionPromptOverride:
                      data.attributionPromptOverride ?? data.attributionPrompt,
                    archivedAt: (data.archivedAt as IsoDateString) ?? undefined,
                  })

                  leadSourcesQuery[1]()
                  message.success(
                    `Updated Lead Source "${
                      data.canonicalLeadSourceNameOverride ??
                      data.canonicalLeadSourceName
                    }"!`,
                  )
                  setToEditLeadSource(null)
                }}
                onCancel={() => setToEditLeadSource(null)}
              />
            </>
          )}
        />
      </ScrollCard>
    </Page>
  )
}
