import {
  noOp,
  PhotoRecord,
  PricebookItemGuid,
  PricebookItemTypeEnum,
} from '@breezy/shared'
import { Button, Form } from 'antd'
import { useForm, useWatch } from 'antd/lib/form/Form'
import { useCallback, useMemo, useState } from 'react'
import ThinDivider from '../../../../elements/ThinDivider'
import { trpc } from '../../../../hooks/trpc'
import { useIsPricebookPhotosEnabled } from '../../../../hooks/useIsPricebookPhotosEnabled'
import { useExpectedCompany } from '../../../../providers/PrincipalUser'
import { useMessage } from '../../../../utils/antd-utils'
import { useIsQboEnabled } from '../../../Quickbooks/QuickbooksSyncButton'
import {
  AsyncPhotoUploadWithThumbnail,
  OnPhotoUploadChange,
} from '../../../Upload/AsyncPhotoUpload'
import PhotoCropModal, {
  usePhotoCrop,
} from '../../../Upload/PhotoCropModal/PhotoCropModal'
import { useSynchronousUpload } from '../../../Upload/Upload'
import { ItemPriceFormItem } from '../../../form-fields/ItemPriceFormItem'
import { PricebookItemFormSchema } from '../../Utils/types'
import { usePricebookAdmin } from '../../hooks/usePricebookAdmin'
import { PricebookFormItemActive } from '../FormItems/PricebookFormItemActive'
import { PricebookFormItemCategoryTreeSelect } from '../FormItems/PricebookFormItemCategoryTreeSelect'
import { PricebookFormItemCost } from '../FormItems/PricebookFormItemCost'
import { PricebookFormItemDescription } from '../FormItems/PricebookFormItemDescription'
import { PricebookFormItemDiscountable } from '../FormItems/PricebookFormItemDiscountable'
import { PricebookFormItemIncomeAccountTreeSelect } from '../FormItems/PricebookFormItemIncomeAccountTreeSelect'
import { PricebookFormItemName } from '../FormItems/PricebookFormItemName'
import { PricebookFormItemTaxable } from '../FormItems/PricebookFormItemTaxable'
const DEFAULT_VALUES: Partial<PricebookItemFormSchema> = {
  name: '',
  description: '',
  costUSD: 0,
  priceUSD: 0,
  isTaxable: false,
  isActive: true,
  isDiscountable: true,
  sourcePhotoGuid: undefined,
  sourcePhotoUrl: undefined,
  photoGuid: undefined,
  photoUrl: undefined,
}

export type UpsertPricebookItemFormProps = {
  type: PricebookItemTypeEnum
  initialValues?: PricebookItemFormSchema & {
    pricebookItemGuid?: PricebookItemGuid
  }
}
export const UpsertPricebookItemForm = ({
  initialValues,
  type,
}: UpsertPricebookItemFormProps) => {
  const message = useMessage()
  const { categories, pricebookItemMutated, closeDrawer, defaultPhotos } =
    usePricebookAdmin()

  const [pricebookItemGuid, setPricebookItemGuid] = useState(
    initialValues?.pricebookItemGuid,
  )

  const defaultPhoto = useMemo(() => defaultPhotos[type], [defaultPhotos, type])

  const company = useExpectedCompany()
  const [form] = useForm<PricebookItemFormSchema>()
  const isActive = useWatch('isActive', form)
  const onActiveChange = useCallback(
    (checked: boolean) => {
      form.setFieldsValue({
        isActive: !checked,
      })
    },
    [form],
  )

  const savePricebookItemMutation =
    trpc.pricebook['pricebook:save-item'].useMutation()
  const deletePricebookItemMutation =
    trpc.pricebook['pricebook:delete-item'].useMutation()

  const onFinish = useCallback(
    (values: PricebookItemFormSchema) => {
      savePricebookItemMutation.mutate(
        {
          pricebookItemGuid,
          companyGuid: company.companyGuid,
          itemType: type,
          ...values,
        },
        {
          onSuccess: result => {
            if (pricebookItemGuid) {
              message.success('Successfully updated item in Pricebook')
            } else {
              message.success('Successfully added item to Pricebook')
            }

            setPricebookItemGuid(result.pricebookItemGuid)
            pricebookItemMutated()
          },
          onError: e => {
            if (pricebookItemGuid) {
              message.error(
                'There was an error updating the Pricebook Item. Please try again.',
              )
            } else {
              message.error(
                'There was an error adding the Pricebook Item. Please try again.',
              )
            }
          },
        },
      )
    },
    [
      savePricebookItemMutation,
      pricebookItemGuid,
      company.companyGuid,
      type,
      pricebookItemMutated,
      message,
    ],
  )

  const onDelete = useCallback(() => {
    if (!pricebookItemGuid) return

    deletePricebookItemMutation.mutate(
      { companyGuid: company.companyGuid, pricebookItemGuid },
      {
        onSuccess: () => {
          message.success('Successfully deleted item from Pricebook')
          pricebookItemMutated()
        },
        onError: () => {
          message.error(
            'There was an error deleting the Pricebook Item. Please try again.',
          )
        },
      },
    )
  }, [
    pricebookItemGuid,
    company.companyGuid,
    deletePricebookItemMutation,
    pricebookItemMutated,
    message,
  ])

  const onCancel = useCallback(() => {
    closeDrawer()
  }, [closeDrawer])

  const isQboEnabled = useIsQboEnabled()

  const onUploadChange: OnPhotoUploadChange = useSynchronousUpload(record => {
    form.setFieldsValue({
      sourcePhotoGuid: record.photoGuid,
      sourcePhotoUrl: record.cdnUrl,
      photoGuid: record.photoGuid,
      photoUrl: record.cdnUrl,
    })
  })

  const onCropEdit = useCallback(
    (record: PhotoRecord) => {
      form.setFieldsValue({
        photoGuid: record.photoGuid,
        photoUrl: record.cdnUrl,
      })
    },
    [form],
  )

  const sourcePhotoUrl = useWatch('sourcePhotoUrl', form)
  const photoUrl = useWatch('photoUrl', form)

  const onRevertToDefaultPhoto = useCallback(() => {
    form.setFieldsValue({
      sourcePhotoGuid: defaultPhoto?.photoGuid,
      sourcePhotoUrl: defaultPhoto?.cdnUrl,
      photoGuid: defaultPhoto?.photoGuid,
      photoUrl: defaultPhoto?.cdnUrl,
    })
  }, [form, defaultPhoto])

  const onRemovePhoto = useCallback(() => {
    form.setFieldsValue({
      sourcePhotoGuid: undefined,
      sourcePhotoUrl: undefined,
      photoGuid: undefined,
      photoUrl: undefined,
    })
  }, [form])

  const { pricebookPhotosEnabled } = useIsPricebookPhotosEnabled()

  const { cropModalVisible, openCropModal, photoCropModalProps } = usePhotoCrop(
    {
      sourcePhotoUrl,
      onCropEdit,
      onClose: noOp,
    },
  )

  if (!categories) return null

  return (
    <>
      <Form
        form={form}
        initialValues={{
          ...DEFAULT_VALUES,
          ...(initialValues ?? {}),
          sourcePhotoGuid:
            initialValues?.sourcePhotoGuid ?? defaultPhoto?.photoGuid,
          sourcePhotoUrl: initialValues?.sourcePhotoUrl ?? defaultPhoto?.cdnUrl,
          photoGuid: initialValues?.photoGuid ?? defaultPhoto?.photoGuid,
          photoUrl: initialValues?.photoUrl ?? defaultPhoto?.cdnUrl,
        }}
        layout="vertical"
        validateTrigger="onBlur"
        onFinish={onFinish}
        disabled={savePricebookItemMutation.isLoading}
        className="grid grid-cols-2 items-center gap-4"
      >
        <Form.Item name="sourcePhotoGuid" hidden />
        <Form.Item name="sourcePhotoUrl" hidden />
        <Form.Item name="photoGuid" hidden />
        <Form.Item name="photoUrl" hidden />
        {pricebookPhotosEnabled && (
          <div className="col-span-2">
            <div className="flex items-center gap-3">
              <AsyncPhotoUploadWithThumbnail
                thumbnail={{
                  width: 104,
                  height: 104,
                }}
                defaultPhotoUrl={defaultPhoto?.cdnUrl}
                sourcePhotoUrl={sourcePhotoUrl}
                photoUrl={photoUrl}
                onPhotoUploadChange={onUploadChange}
                onOpenCrop={openCropModal}
                onRevertToDefaultPhoto={onRevertToDefaultPhoto}
                onRemovePhoto={onRemovePhoto}
              />
              <div className="flex max-w-[400px] flex-col">
                <div className="text-sm font-semibold leading-[22px]">
                  Item Image
                </div>
                <div className="text-sm text-bz-text-secondary">
                  Square aspect ratio, .PNG and .JPG file types, maximum file
                  size 25 MB.
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="col-span-2">
          <PricebookFormItemName />
        </div>
        <div className="col-span-2">
          <PricebookFormItemCategoryTreeSelect categories={categories} />
        </div>

        <div className="col-span-2">
          <PricebookFormItemDescription />
        </div>

        <PricebookFormItemCost />
        <ItemPriceFormItem />

        <PricebookFormItemTaxable />
        <PricebookFormItemDiscountable />

        {isQboEnabled && (
          <div className="col-span-2">
            <PricebookFormItemIncomeAccountTreeSelect />
          </div>
        )}

        <div className="col-span-2">
          <ThinDivider widthPx={8} />
          <div className="flex items-center justify-between">
            <PricebookFormItemActive
              value={isActive}
              onChange={onActiveChange}
            />
            <div className="flex justify-end space-x-4">
              {pricebookItemGuid && (
                <Button danger onClick={onDelete}>
                  Delete
                </Button>
              )}
              <Button onClick={onCancel}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                {pricebookItemGuid ? 'Update' : 'Add'}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {cropModalVisible && <PhotoCropModal {...photoCropModalProps} />}
    </>
  )
}
