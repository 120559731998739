import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Spin, TreeSelect } from 'antd'
import { useEffect, useRef } from 'react'
import { useModal } from '../../../../utils/antd-utils'
import { m } from '../../../../utils/react-utils'
import {
  QuickbooksAuthorizeButton,
  useQuickbooksAuthorizeButtonProps,
} from '../../../Quickbooks/QuickbooksAuthorizeButton'
import { usePricebookAdmin } from '../../hooks/usePricebookAdmin'

// The antd TreeSelect types aren't very accessible to me.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filter = (inputValue: string, treeNode: any) => {
  return (treeNode.accountName ?? '')
    .toLowerCase()
    .includes(inputValue.toLowerCase())
}

export const PricebookFormItemIncomeAccountTreeSelect = m(() => {
  const {
    qboIncomeAccountTreeData,
    qboIncomeAccountsLoading,
    // qboAuthorized,
    refetchQboIncomeAccounts,
  } = usePricebookAdmin()
  const Modal = useModal()

  const qboAuthorizeButtonProps = useQuickbooksAuthorizeButtonProps()

  const prevQboAuthorizedRef = useRef(qboAuthorizeButtonProps.isAuthorized)

  useEffect(() => {
    if (!prevQboAuthorizedRef.current && qboAuthorizeButtonProps.isAuthorized) {
      prevQboAuthorizedRef.current = true
      ;(async () => {
        const res = await refetchQboIncomeAccounts()
        if (res.isError) {
          Modal.error({
            title: 'Could not fetch Quickbooks income accounts',
            content:
              res.error?.message ||
              'Please try again later or contact support.',
          })
        }
      })()
    }
  }, [Modal, qboAuthorizeButtonProps.isAuthorized, refetchQboIncomeAccounts])

  if (!qboAuthorizeButtonProps.isAuthorized) {
    return <QuickbooksAuthorizeButton {...qboAuthorizeButtonProps} />
  }

  return (
    <Spin
      spinning={qboIncomeAccountsLoading}
      indicator={<FontAwesomeIcon icon={faSpinnerThird} spin />}
    >
      <Form.Item
        name="qboIncomeAccountId"
        label={
          <div className="flex items-center justify-center">
            <img
              alt="Sync with Quickbooks"
              src="https://d3j5nzmmhjc8v6.cloudfront.net/quickbooks_logo.png"
              className="mr-2 h-4 w-4"
            />
            Income Account
          </div>
        }
      >
        <TreeSelect
          allowClear
          showSearch
          treeData={qboIncomeAccountTreeData?.filter(
            x => x.accountType === 'Income',
          )}
          placeholder="Select an Income Account"
          treeDefaultExpandAll
          filterTreeNode={filter}
        />
      </Form.Item>
    </Spin>
  )
})
