import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from 'antd'
import { useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { OnsiteBasicModal } from '../../../../adam-components/OnsiteModal/OnsiteModal'
import TrpcQueryLoader from '../../../../components/TrpcQueryLoader'
import { useReactHookFormSubmit } from '../../../../elements/Forms/useReactHookFormSubmit'
import { trpc } from '../../../../hooks/trpc'
import { NewJobTypeStep } from './ChangeJobTypeForm/NewJobTypeStep'
import { ReasonForChangeStep } from './ChangeJobTypeForm/ReasonForChangeStep'
import { ChangeJobTypeModalContext } from './ChangeJobTypeModalContext'
import { ChangeJobTypeModalFooter } from './ChangeJobTypeModalFooter'
import {
  ChangeJobTypeFormSchema,
  changeJobTypeFormSchema,
} from './change-job-type-form-schema'
import { ChangeJobTypeModalStep, ChangeJobTypeModalStepName } from './types'

const FORM_STEPS: Record<ChangeJobTypeModalStepName, ChangeJobTypeModalStep> = {
  newJobType: { name: 'newJobType', prev: null, next: 'reasonForChange' },
  reasonForChange: { name: 'reasonForChange', prev: 'newJobType', next: null },
} as const

export type ChangeJobTypeModalProps = {
  open: boolean
  onClose: () => void
}

export const ChangeJobTypeModal = ({
  open,
  onClose,
}: ChangeJobTypeModalProps) => {
  const form = useForm<ChangeJobTypeFormSchema>({
    resolver: zodResolver(changeJobTypeFormSchema),
    defaultValues: {
      preexistingEquipment: [],
      newEquipment: [],
      summary: '',
      reasonForChange: {
        description: '',
        reason: 'CLERICAL_ERROR',
      },
    },
  })

  const jobTypeQuery = trpc.jobTypes['job-types:get'].useQuery(undefined, {
    enabled: open,
  })

  const [step, setStep] = useState<ChangeJobTypeModalStep>(
    FORM_STEPS.newJobType,
  )

  const modalTitle = useMemo(() => {
    switch (step.name) {
      case 'newJobType':
        return 'Change job type'
      case 'reasonForChange':
        return 'Reason for change'
      default:
        return 'Unknown'
    }
  }, [step.name])

  const [btnSubmit, triggerSubmit] = useReactHookFormSubmit()

  const onSubmit = form.handleSubmit(
    _ => {
      // TODO: Add successful validation handler
    },
    err => console.error(err),
  )

  const onCloseWithFormClear = () => {
    form.reset({}, { keepDefaultValues: true })
    setStep(FORM_STEPS.newJobType)
    onClose()
  }

  return (
    <ChangeJobTypeModalContext.Provider
      value={{
        formSteps: FORM_STEPS,
        currFormStep: step,
        setCurrFormStep: setStep,
        onSubmit: triggerSubmit,
        onClose: onCloseWithFormClear,
      }}
    >
      <FormProvider {...form}>
        <OnsiteBasicModal
          open={open}
          onClose={onCloseWithFormClear}
          header={modalTitle}
          footer={<ChangeJobTypeModalFooter />}
        >
          <TrpcQueryLoader
            query={jobTypeQuery}
            render={data => (
              <Form layout="vertical" onSubmitCapture={onSubmit}>
                {step.name === 'newJobType' && (
                  <NewJobTypeStep
                    jobTypes={data.map(jobType => ({
                      jobTypeGuid: jobType.jobTypeGuid,
                      name: jobType.name,
                      jobClass: jobType.jobClass,
                    }))}
                  />
                )}

                {step.name === 'reasonForChange' && <ReasonForChangeStep />}

                {btnSubmit}
              </Form>
            )}
          />
        </OnsiteBasicModal>
      </FormProvider>
    </ChangeJobTypeModalContext.Provider>
  )
}
