import { PageHeader } from '@ant-design/pro-components'
import {
  BzDateFns,
  Guid,
  IsoDateString,
  PermissionV2,
  bzExpect,
  getTimesheetEntryActivityNameOrCustom,
  getTotalMinutesPerDayForTimesheetEntries,
  nextGuid,
} from '@breezy/shared'
import { Button } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import { Page } from '../../components/Page/Page'
import { Authorized } from '../../components/Permissions/Authorized/Authorized'
import { UserPersonResourceSelect } from '../../components/PersonResourceView/UserPersonResourceSelect'
import { TimesheetPeriodPicker } from '../../components/Timesheets/TimesheetPeriodPicker'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ThinDivider from '../../elements/ThinDivider'
import { FetchTimesheetEntriesQuery } from '../../generated/user/graphql'
import { usePayPeriodConfig } from '../../hooks/fetch/useFetchTimesheetsConfig'
import useAppNav from '../../hooks/useAppNav'
import { useRequiredRouteParam } from '../../hooks/useRouteParam'
import {
  useExpectedCompany,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { TimesheetsIcon } from '../../utils/feature-icons'
import { m } from '../../utils/react-utils'
import {
  TimesheetEntriesTable,
  TimesheetEntriesTableProps,
  TimesheetEntryItem,
} from './TimesheetEntriesTable'
import {
  TimesheetAddEditFormProps,
  TimesheetAddEditModal,
} from './TimesheetEntryAddEditForm'
import { FETCH_TIMESHEET_ENTRIES_GQL } from './Timesheets.gql'

export type TimesheetDetailsPageProps = TimesheetEntriesTableProps

const TimesheetDetailsPageContent = m<TimesheetDetailsPageProps>(props => {
  const { items } = props

  const { timezone } = useExpectedCompany()

  const totalHours: number = useMemo(
    () =>
      Object.values(
        getTotalMinutesPerDayForTimesheetEntries(
          items.map(item => ({
            startTime: item.startTime,
            endTime: item.endTime,
            isPayable: item.isPayable,
            deletedAt: item.deletedAt,
          })),
          timezone,
        ),
      ).reduce((acc, curr) => acc + curr, 0) / 60,
    [items, timezone],
  )

  if (items.length === 0)
    return (
      <div className="min-h-[300px] w-full">
        <h2 className="text-center">No entries found for this Pay Period</h2>
      </div>
    )

  return (
    <>
      <TimesheetEntriesTable {...props} />
      <h2 className="mt-6">Payable Hours: {totalHours.toFixed(2)}</h2>
    </>
  )
})

type TimesheetDetailsPayPeriodLoaderProps = {
  userGuid: Guid
  payPeriod: { start: IsoDateString; end: IsoDateString }
  refetchVersion: number
  onEdit: (item: TimesheetEntryItem) => void
}

const TimesheetDetailsPayPeriodLoader = m<TimesheetDetailsPayPeriodLoaderProps>(
  ({ userGuid, payPeriod, refetchVersion, onEdit }) => {
    const company = useExpectedCompany()
    // NOTE: Only includes entires that are fully contained within the time window.
    // Any straddlers are not returned/shown.
    const startTime = BzDateFns.startOfDay(
      BzDateFns.parseISO(payPeriod.start, company.timezone),
    )
    const endTime = BzDateFns.endOfDay(
      BzDateFns.parseISO(payPeriod.end, company.timezone),
    )
    const query = useQuery({
      query: FETCH_TIMESHEET_ENTRIES_GQL,
      variables: {
        where: {
          companyGuid: { _eq: company.companyGuid },
          userGuid: { _eq: userGuid },
          finalStartTime: {
            _gte: BzDateFns.formatISO(startTime, company.timezone),
          },
          finalEndTime: {
            _lte: BzDateFns.formatISO(endTime, company.timezone),
          },
          deletedAt: { _isNull: true },
        },
      },
    })

    const refetch = query[1]
    useEffect(
      () => refetch({ requestPolicy: 'network-only' }),
      [refetch, refetchVersion],
    )

    return (
      <GqlQueryLoader
        query={query}
        render={data => (
          <TimesheetDetailsPageContent
            items={mapToTimesheetEntryItems(data)}
            refetch={refetch}
            onEdit={onEdit}
          />
        )}
      />
    )
  },
)

export const TimesheetDetailsPageLoader = () => {
  const tzId = useExpectedCompanyTimeZoneId()
  const userGuid = useRequiredRouteParam('userGuid')
  const appNav = useAppNav()

  const [addEditOpen, setAddEditOpen] = useState<TimesheetAddEditFormProps>()
  const [refetchVersion, setRefetchVersion] = useState(0)

  const payPeriodConfig = usePayPeriodConfig()
  const [timeWindow, setTimeWindow] = useState(
    payPeriodConfig?.currentPayPeriod,
  )
  useEffect(() => {
    if (!timeWindow) setTimeWindow(payPeriodConfig?.currentPayPeriod)
  }, [timeWindow, payPeriodConfig])

  const closeAddEdit = useCallback(
    () => setAddEditOpen(undefined),
    [setAddEditOpen],
  )

  const onAddEditSubmit = useCallback(() => {
    setRefetchVersion(v => v + 1)
    closeAddEdit()
  }, [closeAddEdit])

  const addNewEntry = useCallback(() => {
    setAddEditOpen({
      mode: 'add',
      userGuid,
      timesheetEntryGuid: nextGuid(),
      onSubmit: onAddEditSubmit,
      onCancel: closeAddEdit,
      payPeriod: bzExpect(timeWindow),
    })
  }, [userGuid, onAddEditSubmit, closeAddEdit, timeWindow])

  const editEntry = useCallback(
    (item: TimesheetEntryItem) => {
      if (!item.timesheetEntryActivityGuid) {
        console.error('TimesheetEntryActivityGuid is undefined')
        return
      }

      setAddEditOpen({
        mode: 'edit',
        userGuid,
        timesheetEntryGuid: item.timesheetEntryGuid,
        timesheetEntryActivityGuid: item.timesheetEntryActivityGuid,
        payPeriod: bzExpect(timeWindow),
        item: {
          localDate: BzDateFns.parseISO(item.startTime, tzId),
          startTimeOfDay: BzDateFns.parseISO(item.startTime, tzId),
          endTimeOfDay: item.endTime
            ? BzDateFns.parseISO(item.endTime, tzId)
            : undefined,
          activityType: getTimesheetEntryActivityNameOrCustom(
            item.activityName,
          ),
          customActivityName: item.activityName,
          isPayable: item.isPayable,
          jobGuid: item.links?.jobGuid,
          jobAppointmentGuid: item.links?.jobAppointmentGuid,
          jobAppointmentAssignmentGuid:
            item.links?.jobAppointmentAssignmentGuid,
        },
        onSubmit: onAddEditSubmit,
        onCancel: closeAddEdit,
      })
    },
    [userGuid, timeWindow, tzId, onAddEditSubmit, closeAddEdit],
  )

  return (
    <Page requiresCompanyUser>
      <Authorized to={PermissionV2.OFFICE_TIMESHEETS_VIEW}>
        <div className="card-no-fixed-height pt-2">
          <PageHeader
            className="pl-2"
            title={
              <PageTitle title={`Timesheet Details`} icon={TimesheetsIcon} />
            }
            extra={
              <div className="row w-full gap-x-4">
                {payPeriodConfig && timeWindow && (
                  <TimesheetPeriodPicker
                    payPeriodConfig={payPeriodConfig}
                    window={timeWindow}
                    setTimeWindow={setTimeWindow}
                  />
                )}
              </div>
            }
          />
          <ThinDivider />
          <div className="row center-children-h space-between mb-6 mt-4 gap-x-4">
            <UserPersonResourceSelect
              userGuid={userGuid}
              setUserGuid={x => appNav.navigateToTimesheetDetailsPage(x)}
            />
            <Authorized to={PermissionV2.OFFICE_TIMESHEETS_MANAGE}>
              <Button type="primary" onClick={addNewEntry} className="center-v">
                + Add Entry
              </Button>
            </Authorized>
          </div>
          {timeWindow && (
            <TimesheetDetailsPayPeriodLoader
              userGuid={userGuid}
              payPeriod={timeWindow}
              refetchVersion={refetchVersion}
              onEdit={editEntry}
            />
          )}
        </div>
      </Authorized>
      <Authorized to={PermissionV2.OFFICE_TIMESHEETS_MANAGE}>
        <TimesheetAddEditModal item={addEditOpen} />
      </Authorized>
    </Page>
  )
}

const mapToTimesheetEntryItems = (
  data: FetchTimesheetEntriesQuery,
): TimesheetEntryItem[] =>
  data.timesheetEntries
    .filter(e => !!e.finalStartTime)
    .map(e => ({
      timesheetEntryGuid: e.timesheetEntryGuid,
      timesheetEntryActivityGuid:
        e.timesheetEntryActivity?.timesheetEntryActivityGuid,
      startTime: bzExpect(e.finalStartTime),
      endTime: e.finalEndTime,
      entryLengthInMinutes: e.entryLengthInMinutes ?? 0,
      activityName: e.timesheetEntryActivity?.activityName ?? 'Missing',
      isPayable: e.timesheetEntryActivity?.isPayable ?? false,
      links: {
        ...e.timesheetEntryLinks,
        jobDisplayId: e.timesheetEntryLinks?.job?.displayId,
      },
      deletedAt: e.deletedAt,
    }))

export default TimesheetDetailsPageLoader
