import classNames from 'classnames'
import React from 'react'

type MapPinProps = {
  content?: React.ReactNode
  colorHex: string
  className?: string
  onMouseOver?: () => void
  onMouseOut?: () => void
  ddActionName?: string
}

const MapPin = React.memo<MapPinProps>(
  ({ content, colorHex, className, onMouseOver, onMouseOut, ddActionName }) => {
    return (
      <div
        className={classNames('relative', className)}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        data-dd-action-name={ddActionName}
      >
        {content && (
          <div
            className={classNames(
              'absolute flex items-center justify-center text-center text-sm font-semibold text-white',
              // This is used to position the content in the center of the circle of the pin
              'left-1/2 top-[25px] -translate-x-1/2 -translate-y-1/2',
            )}
          >
            {content}
          </div>
        )}
        <svg
          width="58"
          height="56"
          viewBox="0 0 58 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_ddd_99_20428)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M33.2441 44.5488C42.2512 42.6025 49 34.5894 49 25C49 13.9543 40.0457 5 29 5C17.9543 5 9 13.9543 9 25C9 34.5894 15.7488 42.6025 24.7559 44.5488L27.1755 49.937C27.8824 51.5113 30.1176 51.5113 30.8245 49.937L33.2441 44.5488Z"
              fill="white"
            />
            <path
              d="M33.2441 44.5488L33.0329 43.5714L32.5389 43.6781L32.3318 44.1391L33.2441 44.5488ZM24.7559 44.5488L25.6682 44.1391L25.4611 43.6781L24.9671 43.5714L24.7559 44.5488ZM27.1755 49.937L28.0878 49.5274L27.1755 49.937ZM30.8245 49.937L29.9122 49.5274L30.8245 49.937ZM48 25C48 34.1087 41.5893 41.7225 33.0329 43.5714L33.4553 45.5262C42.913 43.4826 50 35.0701 50 25H48ZM29 6C39.4934 6 48 14.5066 48 25H50C50 13.402 40.598 4 29 4V6ZM10 25C10 14.5066 18.5066 6 29 6V4C17.402 4 8 13.402 8 25H10ZM24.9671 43.5714C16.4107 41.7225 10 34.1087 10 25H8C8 35.0701 15.087 43.4826 24.5447 45.5262L24.9671 43.5714ZM28.0878 49.5274L25.6682 44.1391L23.8437 44.9584L26.2633 50.3466L28.0878 49.5274ZM29.9122 49.5274C29.5588 50.3145 28.4412 50.3145 28.0878 49.5274L26.2633 50.3466C27.3237 52.7081 30.6763 52.7081 31.7367 50.3466L29.9122 49.5274ZM32.3318 44.1391L29.9122 49.5274L31.7367 50.3466L34.1563 44.9584L32.3318 44.1391Z"
              fill="white"
            />
            <g filter="url(#filter1_i_99_20428)">
              <circle cx="29" cy="25" r="19" fill={colorHex} />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_ddd_99_20428"
              x="0"
              y="0"
              width="58"
              height="64"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_99_20428"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_dropShadow_99_20428"
                result="effect2_dropShadow_99_20428"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect3_dropShadow_99_20428"
                result="shape"
              />
            </filter>

            {/* Circle filter */}
            <filter
              id="filter1_i_99_20428"
              x="10"
              y="6"
              width="38"
              height="38"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_99_20428"
              />
            </filter>
          </defs>
        </svg>
      </div>
    )
  },
)
export default MapPin
