import {
  BzDateFns,
  MaintenanceOpportunity,
  R,
  bzOptional,
  isNullish,
} from '@breezy/shared'
import {
  faChevronLeft,
  faChevronRight,
  faClose,
  faSliders,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { useVirtualizer } from '@tanstack/react-virtual'
import { Button, Radio, Select } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { ListPageFilterButton } from '../../../adam-components/ListPage/ListPageFilterButton'
import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import Switch from '../../../elements/Switch/Switch'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import MaintenanceOpportunityCard from './MaintenanceOpportunityCard'
import './MaintenanceOpportunityPane.less'
import { useScheduleMap } from './ScheduleMapContext'

const MAINT_OPPORTUNITY_SORT_OPTIONS = [
  { label: 'Distance (Closest)', value: 'distance' },
  { label: 'Last Maint. Date (Oldest)', value: 'lastMaintenanceDate' },
  { label: 'Last Visit Date (Farthest)', value: 'lastVisitDate' },
] as const

type MaintenanceOpportunitySortOption =
  (typeof MAINT_OPPORTUNITY_SORT_OPTIONS)[number]['value']

export const MaintenanceOpportunityPane = React.memo(() => {
  const tzId = useExpectedCompanyTimeZoneId()
  const {
    activePopup,
    filteredMaintOpportunities,
    filters,
    clearFilters,
    activeAppointment,
    clearActiveAppointment,
    loading,
    clearActivePopup,
    resetRadius,
  } = useScheduleMap()

  const prevActiveAppointmentRef = useRef(activeAppointment)
  const maintOppListRef = useRef<HTMLDivElement>(null)

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
  const [collapsed, setCollapsed] = useState(false)

  const [maintOpportunitySortOption, setMaintOpportunitySortOption] =
    useState<MaintenanceOpportunitySortOption>(
      MAINT_OPPORTUNITY_SORT_OPTIONS[0].value,
    )

  useEffect(() => {
    // Check if activeAppointment has just become defined
    if (
      isNullish(prevActiveAppointmentRef.current) &&
      !isNullish(activeAppointment)
    ) {
      setCollapsed(false)
    }
    // Update the ref to the current activeAppointment at the end of the effect
    prevActiveAppointmentRef.current = activeAppointment
  }, [activeAppointment, resetRadius])

  const numFilters = useMemo(
    () => filters.reduce((acc, curr) => acc + curr.optionsSelected.length, 0),
    [filters],
  )

  const sortedMaintenanceOpportunities = useMemo(() => {
    return [...filteredMaintOpportunities].sort((a, b) => {
      switch (maintOpportunitySortOption) {
        case 'distance':
          return a.distFromMeters - b.distFromMeters
        case 'lastVisitDate':
          if (isNullish(a.lastVisitedAt)) return 1
          if (isNullish(b.lastVisitedAt)) return -1
          return BzDateFns.compareAsc(
            BzDateFns.parseISO(a.lastVisitedAt, tzId),
            BzDateFns.parseISO(b.lastVisitedAt, tzId),
          )
        case 'lastMaintenanceDate':
          if (isNullish(a.lastMaintenanceVisitAt)) return 1
          if (isNullish(b.lastMaintenanceVisitAt)) return -1
          return BzDateFns.compareAsc(
            BzDateFns.parseISO(a.lastMaintenanceVisitAt, tzId),
            BzDateFns.parseISO(b.lastMaintenanceVisitAt, tzId),
          )
        default:
          return 0
      }
    })
  }, [filteredMaintOpportunities, maintOpportunitySortOption, tzId])

  const rowVirtualizer = useVirtualizer({
    count: sortedMaintenanceOpportunities.length,
    getScrollElement: () => maintOppListRef.current,
    gap: 12,
    estimateSize: () => 500,
    overscan: 5,
  })

  const scrollToTop = useCallback(() => {
    if (!rowVirtualizer || sortedMaintenanceOpportunities.length) return
    rowVirtualizer.scrollToIndex(0)
  }, [rowVirtualizer, sortedMaintenanceOpportunities])

  // If a maintenance opportunity is clicked on the map, scroll it into view in the list
  useEffect(() => {
    if (activePopup?.type === 'maintenanceOpportunity') {
      const index = sortedMaintenanceOpportunities.findIndex(
        mo => mo.locationGuid === activePopup.locationGuid,
      )
      if (index !== -1) {
        rowVirtualizer.scrollToIndex(index)
      }
    }
  }, [activePopup, rowVirtualizer, sortedMaintenanceOpportunities])

  const resetPanel = useCallback(() => {
    clearFilters()
    setMaintOpportunitySortOption(MAINT_OPPORTUNITY_SORT_OPTIONS[0].value)
    scrollToTop()
  }, [clearFilters, scrollToTop])

  const onSidebarCollapse = useCallback(() => {
    clearActiveAppointment()
    resetPanel()
  }, [clearActiveAppointment, resetPanel])

  const onSortChange = useCallback(
    (value: MaintenanceOpportunitySortOption) => {
      setMaintOpportunitySortOption(value)
      scrollToTop()
    },
    [scrollToTop, setMaintOpportunitySortOption],
  )

  const onFilterChangeSubmit = useCallback(() => {
    clearActivePopup()
    setIsFilterModalOpen(false)
    scrollToTop()
  }, [clearActivePopup, scrollToTop, setIsFilterModalOpen])

  if (!activeAppointment) return null

  return (
    <div
      className={classNames(
        'bz-maint-opportunity-pane card relative min-h-0 overflow-hidden transition-all',
        collapsed
          ? 'min-w-[80px]  max-w-[80px]'
          : 'flex min-w-[380px] max-w-[380px] flex-col',
      )}
    >
      <div
        className={classNames(
          'mb-2 flex items-center',
          collapsed ? 'flex-col' : 'flex-row',
        )}
      >
        <Button
          className={classNames({ '-ml-[15px]': !collapsed })}
          type="text"
          onClick={() => setCollapsed(R.not)}
        >
          <FontAwesomeIcon icon={collapsed ? faChevronLeft : faChevronRight} />
        </Button>
        <div
          className={classNames(
            'card-title-md flex origin-center flex-row items-center',
            {
              'mt-28 rotate-90 whitespace-nowrap': collapsed,
            },
          )}
        >
          <div className="mr-2">{`Maintenance Opportunities${
            filteredMaintOpportunities.length
              ? ` (${filteredMaintOpportunities.length})`
              : ''
          }`}</div>
        </div>
        {!collapsed && (
          <div className="flex flex-1 justify-end">
            <Button
              type="text"
              className="mr-[-8px]"
              onClick={onSidebarCollapse}
              icon={<FontAwesomeIcon className="h-5 w-5" icon={faClose} />}
            />
          </div>
        )}
      </div>
      {!collapsed && (
        <div
          className={classNames(
            'mx-[-24px] flex min-h-0 flex-grow flex-col pl-6 pr-2',
            {
              'min-h-full': loading,
            },
          )}
        >
          <div className="mb-2 flex flex-row items-center">
            <ListPageFilterButton
              hasFilters={numFilters > 0}
              data-dd-action-name={`BZ Schedule Map - Filters`}
              icon={
                <FontAwesomeIcon
                  className={classNames('h-4 w-4', {
                    'text-[#1677FF]': numFilters > 0,
                  })}
                  icon={faSliders}
                />
              }
              onClick={() => setIsFilterModalOpen(true)}
            >
              {numFilters > 0 ? `Filters (${numFilters})` : 'Filters'}
            </ListPageFilterButton>
            {numFilters > 0 && (
              <div
                className="cursor-pointer pl-2 font-semibold text-[#1677FF]"
                onClick={resetPanel}
                data-dd-action-name={`BZ Schedule Map - Clear Filters`}
              >
                Clear all
              </div>
            )}
            <div className="mx-3 my-2 block h-6 w-px bg-bz-gray-500"></div>
            <div>
              <Select
                value={maintOpportunitySortOption}
                options={[...MAINT_OPPORTUNITY_SORT_OPTIONS]}
                onChange={onSortChange}
                dropdownStyle={{ minWidth: '248px' }}
                data-dd-action-name={`BZ Schedule Map - Sort`}
              />
            </div>
          </div>
          <div
            ref={maintOppListRef}
            style={{
              contain: 'strict',
            }}
            className={classNames(
              'flex min-h-0 w-full min-w-0 flex-1 flex-col space-y-3',
              {
                'overflow-auto': !loading,
                'items-center justify-center': loading,
              },
            )}
          >
            <Switch value={loading.toString()}>
              {{
                default: () => <LoadingSpinner />,
                true: () => <LoadingSpinner />,
                false: () => {
                  return !sortedMaintenanceOpportunities.length ? (
                    <div className="flex rounded-lg border border-solid border-bz-gray-500 p-3 text-sm text-gray-700">
                      No opportunities exist. Try adjusting the distance radius
                      or any selected filters.
                    </div>
                  ) : (
                    <div
                      style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        width: '100%',
                        position: 'relative',
                      }}
                    >
                      {rowVirtualizer.getVirtualItems().map(virtualItem => {
                        return (
                          <div
                            key={
                              sortedMaintenanceOpportunities[virtualItem.index]
                                .locationGuid
                            }
                            data-index={virtualItem.index}
                            ref={node => {
                              rowVirtualizer.measureElement(node)
                            }}
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              transform: `translateY(${virtualItem.start}px)`,
                            }}
                          >
                            <MaintenanceOpportunityCardWrapper
                              maintenanceOpportunity={
                                sortedMaintenanceOpportunities[
                                  virtualItem.index
                                ]
                              }
                            />
                          </div>
                        )
                      })}
                    </div>
                  )
                },
              }}
            </Switch>
          </div>
        </div>
      )}
      {isFilterModalOpen && (
        <MaintenanceOpportunityFilterModal
          onClose={() => setIsFilterModalOpen(false)}
          onSubmitted={onFilterChangeSubmit}
        />
      )}
    </div>
  )
})

type MaintenanceOpportunityCardProps = {
  maintenanceOpportunity: MaintenanceOpportunity
}
const MaintenanceOpportunityCardWrapper =
  React.memo<MaintenanceOpportunityCardProps>(({ maintenanceOpportunity }) => {
    const { activePopup, setActivePopup } = useScheduleMap()

    const isActive = useMemo(() => {
      if (activePopup?.type === 'maintenanceOpportunity') {
        return activePopup.locationGuid === maintenanceOpportunity.locationGuid
      }
      return false
    }, [activePopup, maintenanceOpportunity.locationGuid])

    const onClick = useCallback(() => {
      if (isActive) {
        setActivePopup(undefined)
      } else {
        setActivePopup({
          type: 'maintenanceOpportunity',
          locationGuid: maintenanceOpportunity.locationGuid,
        })
      }
    }, [isActive, setActivePopup, maintenanceOpportunity.locationGuid])

    return (
      <MaintenanceOpportunityCard
        className={classNames('cursor-pointer rounded-lg', {
          'bg-bz-green-100': isActive,
        })}
        data-dd-action-name={`BZ Schedule Map - Maintenance Opportunity Card`}
        border={isActive ? 'active' : 'default'}
        key={maintenanceOpportunity.locationGuid}
        maintenanceOpportunity={maintenanceOpportunity}
        onClick={onClick}
      />
    )
  })

const MaintenanceOpportunityFiltersFormSchema = z.object({
  membersStatus: bzOptional(z.enum(['isMember', 'isNotMember'])),
  selectedTags: z.array(z.string()),
})

type MaintenanceOpportunityFiltersFormData = z.infer<
  typeof MaintenanceOpportunityFiltersFormSchema
>

type MaintenanceOpportunityFilterModalProps = {
  onClose: () => void
  onSubmitted: () => void
}

const MaintenanceOpportunityFilterModal =
  React.memo<MaintenanceOpportunityFilterModalProps>(
    ({ onClose, onSubmitted }) => {
      const { filters, setFilters, accountTags } = useScheduleMap()
      const formRef = useRef<HTMLFormElement>(null)

      const onSubmit = useCallback(
        (data: MaintenanceOpportunityFiltersFormData) => {
          const filters = []

          if (data.membersStatus) {
            filters.push({
              key: 'maintenancePlan',
              optionsSelected: [data.membersStatus],
            })
          }

          if (data.selectedTags.length) {
            filters.push({
              key: 'tags',
              optionsSelected: data.selectedTags,
            })
          }

          setFilters(filters)
          onSubmitted()
        },
        [setFilters, onSubmitted],
      )

      const {
        control,
        formState: { isDirty },
        handleSubmit,
        reset,
      } = useForm({
        defaultValues: {
          membersStatus:
            (filters.find(f => f.key === 'maintenancePlan')
              ?.optionsSelected[0] as 'isMember' | 'isNotMember') ?? undefined,
          selectedTags:
            filters.find(f => f.key === 'tags')?.optionsSelected ?? [],
        },
        resolver: zodResolver(MaintenanceOpportunityFiltersFormSchema),
      })

      const onSubmitInner = handleSubmit(onSubmit)
      return (
        <OnsiteBasicModal
          open
          onClose={onClose}
          header="Maintenance opportunity filters"
          headerBordered={false}
          children={
            <form
              ref={formRef}
              onSubmit={onSubmitInner}
              className="flex flex-col gap-6"
            >
              <div className="flex flex-col gap-2">
                <div className="text-sm font-semibold">
                  Maintenance Plan Member Status
                </div>
                <Controller
                  control={control}
                  name="membersStatus"
                  render={({ field }) => (
                    <Radio.Group
                      {...field}
                      value={field.value}
                      optionType="button"
                    >
                      <Radio value="isMember">Member</Radio>
                      <Radio value="isNotMember">Non-member</Radio>
                    </Radio.Group>
                  )}
                />
              </div>

              <div className="flex flex-col gap-2">
                <div className="text-sm font-semibold">Account Tags</div>
                <Controller
                  control={control}
                  name="selectedTags"
                  render={({ field }) => (
                    <Select
                      {...field}
                      mode="multiple"
                      placeholder="Select tags to filter by..."
                      value={field.value}
                      options={accountTags.map(tag => ({
                        label: tag.name,
                        value: tag.tagGuid,
                      }))}
                    />
                  )}
                />
              </div>
            </form>
          }
          footer={
            <div className="flex w-full flex-row items-center gap-3">
              <Button
                className="flex-1"
                htmlType="button"
                size="large"
                data-dd-action-name={`BZ Schedule Map - Cancel Filters`}
                onClick={() => {
                  reset()
                  onClose()
                }}
              >
                Cancel
              </Button>
              <Button
                className="flex-1"
                type="primary"
                size="large"
                htmlType="submit"
                disabled={!isDirty}
                data-dd-action-name={`BZ Schedule Map - Apply Filters`}
                onClick={() => {
                  formRef.current?.dispatchEvent(
                    new Event('submit', { cancelable: true, bubbles: true }),
                  )
                }}
              >
                Apply
              </Button>
            </div>
          }
        />
      )
    },
  )
