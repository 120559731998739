import { faEnvelope } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import { gray7 } from '../../themes/theme'
import './EmailAddressView.less'

export type EmailAddressViewProps = {
  emailAddress: string
  iconOnly?: boolean
  displayType?: 'compact' | 'circle-button'
}
export const CompactEmailAddressView = ({
  emailAddress,
  iconOnly,
}: EmailAddressViewProps) => (
  <Tooltip className="email__address" title={emailAddress} placement="bottom">
    <a
      className="email__address__link"
      href={`mailto:${emailAddress}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon
        icon={faEnvelope}
        color={gray7}
        style={{ marginRight: 12, fontSize: '16px' }}
      />
      {!iconOnly && <p>{emailAddress}</p>}
    </a>
  </Tooltip>
)

export const CircleButtonEmailAddressView = ({
  emailAddress,
}: EmailAddressViewProps) => (
  <Tooltip className="email__address" title={emailAddress} placement="bottom">
    <a
      className="email__address__link"
      href={`mailto:${emailAddress}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        borderRadius: '50%',
        border: '1px solid',
        borderColor: gray7,
        width: 36,
        height: 36,
        marginRight: 12,
      }}
    >
      <FontAwesomeIcon
        icon={faEnvelope}
        color={gray7}
        style={{ fontSize: '16px', margin: 'auto' }}
      />
    </a>
  </Tooltip>
)

export const EmailAddressView = ({
  emailAddress,
  iconOnly,
  displayType = 'compact',
}: EmailAddressViewProps) => {
  if (displayType === 'compact')
    return (
      <CompactEmailAddressView
        emailAddress={emailAddress}
        iconOnly={iconOnly}
      />
    )
  return <CircleButtonEmailAddressView emailAddress={emailAddress} />
}

export default EmailAddressView
