import { Divider } from 'antd'
import cn from 'classnames'
import React from 'react'
import { useDrop } from 'react-dnd'
import { OnDropHandler, StatusBoxDragItem } from '../types'

export type DropTargetProps = React.PropsWithChildren<{
  onDrop: OnDropHandler
  dropId: string
  isDragging?: boolean
}>

export const DropTarget = React.memo<DropTargetProps>(
  ({ children, onDrop, dropId, isDragging }) => {
    const [{ isOver }, drop] = useDrop(
      () => ({
        accept: 'STATUS_BOX',
        drop: (item: StatusBoxDragItem) => {
          onDrop({
            droppedJobLifecycleStatusGuid: item.jobLifecycleStatusGuid,
            dropTargetId: dropId,
          })
        },
        canDrop: (item: StatusBoxDragItem) =>
          dropId !== item.jobLifecycleStatusGuid,
        collect: monitor => ({
          isOver: !!monitor.isOver(),
        }),
      }),
      [dropId, onDrop],
    )
    return (
      <>
        <div ref={drop} className="w-full">
          {children}
        </div>
        <Divider
          className={cn(
            'my-2 border-2 transition-all',
            isOver && !isDragging ? 'border-black' : 'border-transparent',
          )}
        />
      </>
    )
  },
)
