import { PermissionV2, RoleId } from '@breezy/shared'
import { usePrincipalUser } from '../providers/PrincipalUser'
import { useIsPrincipalAuthorized } from './permission/useIsPrincipalAuthorized'
import { useFeatureFlagReader } from './useFeatureFlags'

const showToRoles: RoleId[] = [
  RoleId.DISPATCHER,
  RoleId.ADMIN,
  RoleId.SALES_TECHNICIAN,
]

export const useShouldShowAiRecommendations = () => {
  const ffReader = useFeatureFlagReader()
  const user = usePrincipalUser()
  const userIsSuperAdmin = useIsPrincipalAuthorized(
    user.principal,
    PermissionV2.ALL_ACTIONS,
  )
  const userRoles = (user?.principal?.roles ?? []).map(r => r.role)

  return (
    ffReader('useAiRecommendations') &&
    ffReader('showAiRecommendations') &&
    (userIsSuperAdmin || userRoles.some(role => showToRoles.includes(role)))
  )
}
