import {
  EquipmentType,
  JobToEquipmentRelationship,
  bzOptional,
  guidSchema,
} from '@breezy/shared'
import { z } from 'zod'

export const changeJobTypeFormSchema = z.object({
  jobGuid: guidSchema,
  jobTypeGuid: guidSchema,
  jobLifecycle: bzOptional(
    z.object({
      jobLifecycleGuid: guidSchema,
      jobLifecycleStatusGuid: guidSchema,
    }),
  ),
  summary: z.string().min(1),
  preexistingEquipment: z
    .object({
      equipmentType: z.nativeEnum(EquipmentType),
      equipmentAge: z.string(),
      relationshipType: z.nativeEnum(JobToEquipmentRelationship),
    })
    .array(),
  newEquipment: z
    .object({
      equipmentType: z.nativeEnum(EquipmentType),
      relationshipType: z.nativeEnum(JobToEquipmentRelationship),
    })
    .array(),
  isOpportunity: z.boolean(),
  isHotLead: z.boolean(),
  isMembershipOpportunity: z.boolean(),
  reasonForChange: z.object({
    description: z.string(),
    reason: z.enum([
      'CLERICAL_ERROR',
      'JOB_FLIPPED_OR_TURNED_OVER',
      'CUSTOMER_PROVIDED_INCORRECT_INFORMATION',
    ]),
  }),
})

export type ChangeJobTypeFormSchema = z.infer<typeof changeJobTypeFormSchema>
