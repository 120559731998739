import { ReactNode, memo } from 'react'

type BasicJobCardSectionProps = {
  children?: ReactNode
  visible: boolean
}

export const BasicJobCardSection = memo(
  ({ children, visible }: BasicJobCardSectionProps) => {
    if (!visible) {
      return null
    }

    return <>{children}</>
  },
)
