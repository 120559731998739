import { Row } from 'antd'
import React, { useCallback, useRef } from 'react'
import { Page } from '../../components/Page/Page'
import { AvgInvoiceByJobTypeWidget } from './AvgInvoiceByJobTypeWidget'
import { EarnedRevenueThisWeekTile } from './EarnedRevenueThisWeekTile'
import { EarnedRevenueWidget } from './EarnedRevenueWidget'
import { JobsByClassWidget } from './JobsByClassWidget'
import { JobsByLeadSourceWidget } from './JobsByLeadSourceWidget'
import { MaintenancePlansTrendsWidget } from './MaintenancePlanTrendsWidget'
import { MaintenancePlansUnusedCreditsWidget } from './MaintenancePlanUnusedCreditsWidget'
import { UpcomingAppointmentsWidget } from './UpcomingAppointmentsWidget'

import { useCanViewTechnicianPerformance } from '../../hooks/permission/useCanViewTechnicianPerformance'
import { useCompanyMaintenancePlansEnabled } from '../../hooks/useCompanyMaintenancePlansEnabled'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { useShouldShowAiRecommendations } from '../../hooks/useShouldShowAiRecommendations'
import { FullWidthColumn, HalfWidthColumn } from './Columns'
import { CustomerReviewTrendsWidget } from './CustomerReviewTrendsWidget'
import { CustomerReviewsWidget } from './CustomerReviewsWidget'
import { DashboardDivider } from './DashboardDivider'
import { EarnedRevenueThisMonthTile } from './EarnedRevenueThisMonthTile'
import './ReportingDashboardPage.less'
import { StaleAccountsTile } from './StaleAccountsTile'
import { TeamPerformanceDashboard } from './TeamPerformance/TeamPerformanceDashboard'
import { TileGroup } from './TileGroup'
import TodayRecommendationsWidget from './TodayRecommendationsWidget'
import { UnpaidInvoicesV2Tile } from './UnpaidInvoicesV2Tile'
import { RefetchableAppointmentsRef } from './utils'

const CARD_HEIGHT_CLASS_NAME = 'h-[423px]' as const
const TALL_CARD_HEIGHT_CLASS_NAME = 'h-[460px]' as const

export const ReportingDashboardPage = React.memo(() => {
  const upcomingAppointmentsWidgetRef = useRef<RefetchableAppointmentsRef>(null)
  const jobsByClassWidgetRef = useRef<RefetchableAppointmentsRef>(null)

  const refetchAppointments = useCallback(() => {
    upcomingAppointmentsWidgetRef.current?.refetchAppointments()
    jobsByClassWidgetRef.current?.refetchAppointments()
  }, [])

  const isCompanyMaintenancePlansEnabled = useCompanyMaintenancePlansEnabled()
  const showAiRecommendations = useShouldShowAiRecommendations()
  const canViewTechnicianPerformance = useCanViewTechnicianPerformance()

  const customerReviewsEnabled = useFeatureFlag('customer-reviews')

  return (
    <Page
      requiresCompanyUser
      className="reporting-dashboard-page min-w-[600px] p-6"
    >
      <Row gutter={[24, 24]}>
        {showAiRecommendations && (
          <>
            <FullWidthColumn>
              <TodayRecommendationsWidget />
            </FullWidthColumn>
            <DashboardDivider />
          </>
        )}
        {customerReviewsEnabled && (
          <>
            <HalfWidthColumn>
              <CustomerReviewsWidget className={TALL_CARD_HEIGHT_CLASS_NAME} />
            </HalfWidthColumn>
            <HalfWidthColumn>
              <CustomerReviewTrendsWidget
                className={TALL_CARD_HEIGHT_CLASS_NAME}
              />
            </HalfWidthColumn>
            <DashboardDivider />
          </>
        )}
        <FullWidthColumn>
          <UpcomingAppointmentsWidget
            refetchableAppointmentsRef={upcomingAppointmentsWidgetRef}
            refetchAppointments={refetchAppointments}
          />
        </FullWidthColumn>
        <HalfWidthColumn>
          <JobsByClassWidget
            refetchableAppointmentsRef={jobsByClassWidgetRef}
            className={CARD_HEIGHT_CLASS_NAME}
          />
        </HalfWidthColumn>
        <HalfWidthColumn>
          <AvgInvoiceByJobTypeWidget className={CARD_HEIGHT_CLASS_NAME} />
        </HalfWidthColumn>
        <DashboardDivider />
        <HalfWidthColumn>
          <TileGroup title="Earned Revenue">
            <EarnedRevenueThisWeekTile />
            <EarnedRevenueThisMonthTile />
          </TileGroup>
        </HalfWidthColumn>
        <HalfWidthColumn>
          <TileGroup title="Opportunities">
            <UnpaidInvoicesV2Tile />
            <StaleAccountsTile />
          </TileGroup>
        </HalfWidthColumn>
        <DashboardDivider />
        <HalfWidthColumn>
          <EarnedRevenueWidget className={CARD_HEIGHT_CLASS_NAME} />
        </HalfWidthColumn>
        <HalfWidthColumn>
          <JobsByLeadSourceWidget className={CARD_HEIGHT_CLASS_NAME} />
        </HalfWidthColumn>

        {canViewTechnicianPerformance && (
          <>
            <DashboardDivider />
            <TeamPerformanceDashboard />
          </>
        )}

        <DashboardDivider />
        {isCompanyMaintenancePlansEnabled && (
          <>
            <HalfWidthColumn>
              <MaintenancePlansTrendsWidget
                className={CARD_HEIGHT_CLASS_NAME}
              />
            </HalfWidthColumn>
            <HalfWidthColumn>
              <MaintenancePlansUnusedCreditsWidget
                className={CARD_HEIGHT_CLASS_NAME}
              />
            </HalfWidthColumn>
          </>
        )}
      </Row>
    </Page>
  )
})
