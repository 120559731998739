import {
  JobLifecycleStatus,
  JobLifecycleStatusWithoutLifecycleGuid,
  JobLifecycleType,
} from '@breezy/shared'
import { faCircle } from '@fortawesome/pro-light-svg-icons'
import {
  Button,
  ColorPicker,
  Divider,
  Form,
  FormInstance,
  Input,
  Switch,
} from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useCallback, useContext, useMemo } from 'react'
import BzDrawer from '../../elements/BzDrawer/BzDrawer'
import { COLORS } from '../../utils/color-utils'
import { JobLifecycleManagementContext } from './lifecycleUtils'

type SwitchInputProps = {
  name: string
  label: string
  description: string
  disabledWhenInitializedToTrue: boolean
  initialValue?: boolean
  form: FormInstance
}

const SwitchInput = React.memo<SwitchInputProps>(
  ({ name, label, description, form, disabledWhenInitializedToTrue }) => {
    return (
      <Form.Item name={name} valuePropName="checked">
        <div className="flex flex-row">
          <Switch
            className="mt-1"
            defaultChecked={form.getFieldValue(name)}
            // If it starts off checked, you can't uncheck it
            disabled={form.getFieldValue(name) && disabledWhenInitializedToTrue}
            onChange={e => form.setFieldValue(name, e)}
          />
          <div className="ml-4">
            <div className="text-base">{label}</div>
            <div className="text-sm text-bz-gray-700">{description}</div>
          </div>
        </div>
      </Form.Item>
    )
  },
)

const VALIDATE_MESSAGES = {
  // This is the format antd wants
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required.',
}

type EditJobLifecycleStatusSidebarProps = {
  initialStatus: Partial<JobLifecycleStatus>
  jobLifecycleType: JobLifecycleType
  onClose: () => void
}

export const EditJobLifecycleStatusSidebar =
  React.memo<EditJobLifecycleStatusSidebarProps>(
    ({ onClose, initialStatus, jobLifecycleType }) => {
      const { changeStatus, addNewStatus } = useContext(
        JobLifecycleManagementContext,
      )
      const drawerItem = useMemo(() => ({ onCancel: onClose }), [onClose])

      const [form] = Form.useForm<JobLifecycleStatus>()

      const initialValues = useMemo<
        Omit<JobLifecycleStatusWithoutLifecycleGuid, 'jobLifecycleStatusGuid'>
      >(
        () => ({
          name: '',
          description: '',
          isDefault: false,
          isWorkComplete: false,
          isLeadWon: false,
          isFirstAppointmentBookedAutomationEnabled: false,
          isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
          isTechnicianDispatchedAutomationEnabled: false,
          isTechnicianArrivesAtAppointmentAutomationEnabled: false,
          isAllAppointmentsCompletedAutomationEnabled: false,
          isCreateLinkedJobAutomationEnabled: false,
          isNoAdditionalWorkRequiredAutomationEnabled: false,
          ...initialStatus,
          color:
            initialStatus.color ||
            COLORS[Math.floor(Math.random() * COLORS.length)],
          stage: 'Opportunity',
        }),
        [initialStatus],
      )

      const onSave = useCallback(async () => {
        try {
          await form.validateFields()
        } catch (e) {
          return
        }

        if (initialStatus.jobLifecycleStatusGuid) {
          changeStatus(form.getFieldsValue(true))
        } else {
          addNewStatus(form.getFieldsValue(true))
        }
        onClose()
      }, [
        addNewStatus,
        changeStatus,
        form,
        initialStatus.jobLifecycleStatusGuid,
        onClose,
      ])

      return (
        <BzDrawer
          title={`${
            initialStatus.jobLifecycleStatusGuid ? 'Edit' : 'New'
          } Status`}
          icon={faCircle}
          item={drawerItem}
          preferredWidth={720}
          footer={
            <div className="flex flex-row justify-between py-4">
              <span />
              <div className="flex flex-row space-x-2">
                <Button size="large" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="primary" size="large" onClick={onSave}>
                  Save
                </Button>
              </div>
            </div>
          }
        >
          <Form
            form={form}
            layout="vertical"
            requiredMark="optional"
            initialValues={initialValues}
            validateMessages={VALIDATE_MESSAGES}
          >
            <Form.Item
              label="Name"
              name="name"
              required
              rules={[{ required: true }]}
            >
              <Input
                disabled={!!initialStatus.specialStatus}
                placeholder="Status name"
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              extra="An internal description to keep your lifecycles organized"
            >
              <TextArea
                placeholder="Purpose of the status (optional)"
                rows={2}
              />
            </Form.Item>
            <div className="flex flex-row items-start">
              <Form.Item className="mb-0" label="Color" name="color" required>
                <ColorPicker
                  onChange={color =>
                    // The value being passed to Antd's form is actually a Color object that's returned from Antd's
                    // ColorPicker component. For some reason, this is not caught by the form's validator for whatever
                    // reason and allows it to be passed up to the mutation that upserts the JobLifecycleStatus,
                    // causing the color not being changed when the user changes it.
                    //
                    // The following is needed to ensure the proper hex value is being set by Antd's form object.
                    // See: https://ant.design/components/color-picker#color
                    form.setFieldValue('color', color.toHexString())
                  }
                />
              </Form.Item>
            </div>
            <Divider className="border-bz-gray-500" />
            <SwitchInput
              form={form}
              name="isDefault"
              label="Set as default"
              disabledWhenInitializedToTrue
              description="This is the default status for any new jobs created in this lifecycle."
            />
            {jobLifecycleType === 'SALES' && (
              <SwitchInput
                form={form}
                name="isLeadWon"
                label="Set as Lead Won"
                disabledWhenInitializedToTrue
                description="This is the status which indicates when a lead is won and the job is sold. This is important for accurate reporting."
              />
            )}
            <SwitchInput
              form={form}
              name="isWorkComplete"
              label="Set as Work Complete"
              disabledWhenInitializedToTrue
              description="This is the status when the work is complete and accrual-based revenue will be synced in accounting."
            />
            <Divider className="border-bz-gray-500" />
            <div>
              <div className="mb-4 text-lg font-semibold">Automation</div>
              <SwitchInput
                form={form}
                name="isFirstAppointmentBookedAutomationEnabled"
                label="Transition job to this status on first booked appointment"
                disabledWhenInitializedToTrue={false}
                description="The system will automatically update the status when the first appointment for this job is booked"
              />
              <SwitchInput
                form={form}
                name="isAllOutstandingAppointmentsAssignedAutomationEnabled"
                label="Transition job to this status when all outstanding appointments for a job have been assigned to a technician"
                disabledWhenInitializedToTrue={false}
                description="The system will automatically update the status when all outstanding appointments for the job have been assigned to a technician"
              />
              <SwitchInput
                form={form}
                name="isTechnicianDispatchedAutomationEnabled"
                label="Transition job to this status when a technician is dispatched"
                disabledWhenInitializedToTrue={false}
                description="The system will automatically update the status when a technician indicates they are on their way to the appointment"
              />
              <SwitchInput
                form={form}
                name="isTechnicianArrivesAtAppointmentAutomationEnabled"
                label="Transition job to this status when a technician arrives at the job site"
                disabledWhenInitializedToTrue={false}
                description="The system will automatically update the status when a technician indicates the appointment is in-progress"
              />
              <SwitchInput
                form={form}
                name="isAllAppointmentsCompletedAutomationEnabled"
                label="Transition job to this status when all appointments are completed"
                disabledWhenInitializedToTrue={false}
                description="The system will automatically update the status when the an appointment completes and there aren't any outstanding appointments"
              />
              <SwitchInput
                form={form}
                name="isNoAdditionalWorkRequiredAutomationEnabled"
                label="Transition job to this status when all appointments are complete and no onsite follow-up work is required"
                disabledWhenInitializedToTrue={false}
                description="The system will automatically update the status when all appointments are complete and the technician indicates that no additional work is required at the end of an appointment"
              />

              {jobLifecycleType === 'SALES' && (
                <SwitchInput
                  form={form}
                  name="isCreateLinkedJobAutomationEnabled"
                  label="Auto-generate linked job"
                  disabledWhenInitializedToTrue={false}
                  description="Enable this setting to automatically launch a workflow to create a subsequent job when a job is set to this status."
                />
              )}
            </div>
          </Form>
        </BzDrawer>
      )
    },
  )
