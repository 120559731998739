import { debounce } from '@breezy/shared'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryParamState } from '../utils/react-utils'

export const useDebouncedSearchText = ({
  wait = 300,
  searchQueryKey = 'search',
}) => {
  const [debouncedSearchText, setDebouncedSearchText] = useQueryParamState(
    searchQueryKey,
    '',
  )

  const [searchText, setSearchText] = useState(debouncedSearchText ?? '')

  useEffect(() => {
    setSearchText(debouncedSearchText ?? '')
  }, [debouncedSearchText])

  const onSearchDebounce = useMemo(
    () =>
      debounce(
        (searchText: string) => {
          setDebouncedSearchText(searchText)
        },
        wait,
        {
          trailing: true,
          leading: false,
        },
      ),
    [setDebouncedSearchText, wait],
  )

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value)
      onSearchDebounce(e.target.value)
    },
    [onSearchDebounce],
  )

  return {
    searchText,
    debouncedSearchText: debouncedSearchText ?? '',
    onSearch,
  }
}
