import { UserGuidContainer } from '@breezy/shared'
import { useFetchPersonResource } from '../../hooks/fetch/useFetchUserDetails'
import { m } from '../../utils/react-utils'
import { LoadingSpinner } from '../LoadingSpinner'
import PersonResourceView from './PersonResourceView'

export const UserPersonResourceView = m<UserGuidContainer>(({ userGuid }) => {
  const person = useFetchPersonResource(userGuid)
  return (
    (person && <PersonResourceView {...person} />) || (
      <div className="max-h-[39px] w-[160px]">
        <LoadingSpinner />
      </div>
    )
  )
})
