import {
  BzDateTime,
  IsoDateString,
  R,
  ReportingDateRange,
  Technician,
  TimePastNReportRequest,
  UTC_TIME_ZONE,
  formatMoney,
  getDateGroupingTypeForRange,
  getPastNReportingTimeWindowsFor,
  isNullish,
  usCentsToUsd,
} from '@breezy/shared'
import { memo, useMemo } from 'react'
import { LineChart, LineData } from '../../../elements/Charts/LineChart'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { getStandardXAxisValue, useStandardXAxisFormatter } from '../utils'
import { TeamPerformanceJob } from './team-performance-queries.gql'

type IndividualTechnicianPerformanceAvgTicketSizeChartProps = {
  technicianUserGuidToTechnicianMap: Record<string, Technician>
  technicianUserGuidToAssignedJobsMap: Record<string, TeamPerformanceJob[]>
  dateRange: ReportingDateRange
  timeUnit: TimePastNReportRequest['timeUnit']
  timePastN: TimePastNReportRequest['timePastN']
}

type AvgTicketSizeChartData = {
  readonly periodStart: IsoDateString
  readonly periodEnd: IsoDateString
  readonly technicianGuidToAvgTicketSizeMap: Record<string, number>
}

const Y_AXIS_FORMATTER = (ticketSizeUsd: number) => formatMoney(ticketSizeUsd)

export const IndividualTechnicianPerformanceAvgTicketSizeChart =
  memo<IndividualTechnicianPerformanceAvgTicketSizeChartProps>(
    ({
      technicianUserGuidToTechnicianMap,
      technicianUserGuidToAssignedJobsMap,
      dateRange,
      timeUnit,
      timePastN,
    }) => {
      const tzId = useExpectedCompanyTimeZoneId()

      const reportingTimeWindows = useMemo(
        () =>
          getPastNReportingTimeWindowsFor(
            {
              timeUnit: timeUnit,
              timePastN: timePastN,
            },
            UTC_TIME_ZONE,
          ),
        [timePastN, timeUnit],
      )

      const reportingData = useMemo(() => {
        const data: Record<IsoDateString, AvgTicketSizeChartData> = {}

        for (let i = 0; i < reportingTimeWindows.length; ++i) {
          const { start, end } = reportingTimeWindows[i]

          const startDateTime = BzDateTime.fromIsoString(start, tzId)
          const endDateTime = BzDateTime.fromIsoString(end, tzId)

          const technicianGuidToAvgTicketSizeMap: Record<string, number> = {}
          Object.entries(technicianUserGuidToAssignedJobsMap).forEach(
            ([technicianUserGuid, allAssignedJobs]) => {
              const assignedJobsForThisTimeWindow = allAssignedJobs.filter(
                job => {
                  if (isNullish(job.workCompletedAt)) {
                    return false
                  }

                  const workCompletedAtDateTime = BzDateTime.fromIsoString(
                    job.workCompletedAt,
                    tzId,
                  )
                  if (
                    workCompletedAtDateTime.isAfter(startDateTime) &&
                    workCompletedAtDateTime.isBefore(endDateTime)
                  ) {
                    return true
                  }

                  return false
                },
              )

              let totalInvoicedRevenueUsd = 0
              assignedJobsForThisTimeWindow.forEach(jobForThisTimeWindow => {
                jobForThisTimeWindow.jobAppointmentAssignments.forEach(
                  jobAppointmentAssignment => {
                    const {
                      technicianUserGuid: assignmentTechUserGuid,
                      jobAppointment,
                    } = jobAppointmentAssignment

                    if (assignmentTechUserGuid !== technicianUserGuid) {
                      return
                    }

                    jobAppointment.jobAppointmentInvoices.forEach(
                      ({ invoice }) => {
                        if (invoice.status === 'PAID') {
                          totalInvoicedRevenueUsd += usCentsToUsd(
                            invoice.subtotalUsc,
                          )
                        }
                      },
                    )
                  },
                )
              })

              technicianGuidToAvgTicketSizeMap[technicianUserGuid] =
                assignedJobsForThisTimeWindow.length === 0
                  ? 0
                  : totalInvoicedRevenueUsd /
                    assignedJobsForThisTimeWindow.length
            },
          )

          data[start] = {
            periodStart: start,
            periodEnd: end,
            technicianGuidToAvgTicketSizeMap,
          }
        }

        return data
      }, [reportingTimeWindows, technicianUserGuidToAssignedJobsMap, tzId])

      const xAxisValues = useMemo(() => {
        const orderedBucketKeys = R.keys(reportingData).sort()

        return orderedBucketKeys.map(bucketTimestamp =>
          getStandardXAxisValue(
            tzId,
            bucketTimestamp,
            getDateGroupingTypeForRange(dateRange, tzId),
          ),
        )
      }, [dateRange, reportingData, tzId])

      const xAxisFormatter = useStandardXAxisFormatter(dateRange, tzId)
      const lineChartData = useMemo<LineData[]>(() => {
        const orderedBucketKeys = R.keys(reportingData).sort()

        const chartData: LineData[] = []

        Object.entries(technicianUserGuidToTechnicianMap).forEach(
          ([technicianUserGuid, technician]) => {
            const technicianConversionRateData = orderedBucketKeys.map(
              bucketTimestamp => {
                return reportingData[bucketTimestamp]
                  .technicianGuidToAvgTicketSizeMap[technicianUserGuid]
              },
            )

            chartData.push({
              label: `${
                technician.user.firstName
              } ${technician.user.lastName.substring(0, 1)}.`,
              data: technicianConversionRateData,
              renderTooltipValue: (ticketSizeUsd: number) =>
                formatMoney(ticketSizeUsd),
            })
          },
        )

        return chartData
      }, [reportingData, technicianUserGuidToTechnicianMap])

      return (
        <LineChart
          data={lineChartData}
          yAxisFormatter={Y_AXIS_FORMATTER}
          xAxisValues={xAxisValues}
          xAxisFormatter={xAxisFormatter}
          showLegend={true}
          allowDecimals={true}
        />
      )
    },
  )
