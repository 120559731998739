import { bzOptional, noOp, PhotoRecord } from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  OnsiteModal,
  OnsiteModalContent,
  OnsiteModalFooter,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import { CloseConfirmModal } from '../../adam-components/OnsiteModal/useCloseConfirmModal'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { TextField } from '../../elements/Forms/TextField'
import { useReactHookFormSubmit } from '../../elements/Forms/useReactHookFormSubmit'
import { useIsPricebookPhotosEnabled } from '../../hooks/useIsPricebookPhotosEnabled'
import { useModalState } from '../../utils/react-utils'
import {
  AsyncPhotoUploadWithThumbnail,
  OnPhotoUploadChange,
} from '../Upload/AsyncPhotoUpload'
import PhotoCropModal, {
  usePhotoCrop,
} from '../Upload/PhotoCropModal/PhotoCropModal'
import { useSynchronousUpload } from '../Upload/Upload'

const UpsertPricebookCategoryFormSchema = z.object({
  name: z.string().min(1, 'Category name is required'),
  sourcePhotoGuid: bzOptional(z.string()),
  sourcePhotoUrl: bzOptional(z.string()),
  photoGuid: bzOptional(z.string()),
  photoUrl: bzOptional(z.string()),
})

export type UpsertPricebookCategoryFormValues = z.infer<
  typeof UpsertPricebookCategoryFormSchema
>

type UpsertPricebookCategoryModalProps = {
  onClose: () => void
  onSubmit: (category: UpsertPricebookCategoryFormValues) => void
  mode: 'create' | 'edit'
  initialValues?: Partial<UpsertPricebookCategoryFormValues>
  loading?: boolean
}

export const UpsertPricebookCategoryModal =
  React.memo<UpsertPricebookCategoryModalProps>(
    ({ onClose, onSubmit, mode = 'create', initialValues, loading }) => {
      const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting, isDirty },
        setValue,
        watch,
      } = useForm<UpsertPricebookCategoryFormValues>({
        resolver: zodResolver(UpsertPricebookCategoryFormSchema),
        defaultValues: initialValues,
      })
      const [submitElement, triggerSubmit] = useReactHookFormSubmit()

      const [confirmCancelOpen, openConfirmCancel, closeConfirmCancel] =
        useModalState()

      const onCloseWithConfirm = useCallback(() => {
        if (isDirty) {
          openConfirmCancel()
        } else {
          onClose()
        }
      }, [isDirty, onClose, openConfirmCancel])

      const onUploadChange: OnPhotoUploadChange = useSynchronousUpload(
        record => {
          setValue('sourcePhotoGuid', record.photoGuid)
          setValue('sourcePhotoUrl', record.cdnUrl)
          setValue('photoGuid', record.photoGuid)
          setValue('photoUrl', record.cdnUrl)
        },
      )

      const onCropEdit = useCallback(
        (record: PhotoRecord) => {
          setValue('photoGuid', record.photoGuid)
          setValue('photoUrl', record.cdnUrl)
        },
        [setValue],
      )

      const sourcePhotoUrl = watch('sourcePhotoUrl')
      const sourcePhotoGuid = watch('sourcePhotoGuid')
      const photoUrl = watch('photoUrl')

      const onRevertToDefaultPhoto = useCallback(() => {
        setValue('photoGuid', sourcePhotoGuid)
        setValue('photoUrl', sourcePhotoUrl)
      }, [setValue, sourcePhotoGuid, sourcePhotoUrl])

      const onRemovePhoto = useCallback(() => {
        setValue('sourcePhotoGuid', undefined)
        setValue('sourcePhotoUrl', undefined)
        setValue('photoGuid', undefined)
        setValue('photoUrl', undefined)
      }, [setValue])

      const onFormSubmit = useCallback(
        (data: UpsertPricebookCategoryFormValues) => {
          onSubmit(data)
        },
        [onSubmit],
      )

      const { cropModalVisible, openCropModal, photoCropModalProps } =
        usePhotoCrop({
          sourcePhotoUrl,
          onCropEdit,
          onClose: noOp,
          mode: 'embedded',
        })

      const { pricebookPhotosEnabled } = useIsPricebookPhotosEnabled()

      const content = useMemo(() => {
        if (cropModalVisible) {
          return <PhotoCropModal {...photoCropModalProps} />
        }

        return (
          <OnsiteModalContent
            header={mode === 'create' ? 'Create Category' : 'Edit Category'}
            onClose={onCloseWithConfirm}
            footer={
              <OnsiteModalFooter
                onCancel={onCloseWithConfirm}
                onSubmit={triggerSubmit}
                submitText="Save"
                submitIsLoading={isSubmitting || loading}
              />
            }
          >
            <Form
              onSubmitCapture={handleSubmit(onFormSubmit)}
              layout="vertical"
            >
              {pricebookPhotosEnabled && (
                <div className="mb-4">
                  <div className="flex items-center gap-3">
                    <AsyncPhotoUploadWithThumbnail
                      thumbnail={{
                        width: 104,
                        height: 104,
                      }}
                      sourcePhotoUrl={sourcePhotoUrl}
                      photoUrl={photoUrl}
                      onPhotoUploadChange={onUploadChange}
                      onOpenCrop={openCropModal}
                      onRevertToDefaultPhoto={onRevertToDefaultPhoto}
                      onRemovePhoto={onRemovePhoto}
                    />
                    <div className="flex max-w-[400px] flex-col">
                      <div className="text-sm font-semibold leading-[22px]">
                        Category Image
                      </div>
                      <div className="text-sm text-bz-text-secondary">
                        Square aspect ratio, .PNG and .JPG file types, maximum
                        file size 25 MB.
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <ReactHookFormItem
                control={control}
                name="name"
                label="Category Name"
                errors={errors}
                required
                render={({ field }) => <TextField {...field} />}
              />
              {submitElement}
            </Form>
          </OnsiteModalContent>
        )
      }, [
        control,
        cropModalVisible,
        errors,
        handleSubmit,
        isSubmitting,
        loading,
        mode,
        onCloseWithConfirm,
        onFormSubmit,
        onRemovePhoto,
        onRevertToDefaultPhoto,
        onUploadChange,
        openCropModal,
        photoCropModalProps,
        photoUrl,
        pricebookPhotosEnabled,
        sourcePhotoUrl,
        submitElement,
        triggerSubmit,
      ])

      return (
        <>
          <OnsiteModal open={true} onClose={onCloseWithConfirm}>
            {content}
          </OnsiteModal>
          {confirmCancelOpen && (
            <CloseConfirmModal
              onCancelCancel={closeConfirmCancel}
              onConfirmCancel={() => {
                closeConfirmCancel()
                onClose()
              }}
            />
          )}
        </>
      )
    },
  )
