import { CompanyCreatorV2Input } from '@breezy/shared'
import { Input, InputNumber } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'

export const CompanyDetailsSectionInvoicesEstimatesCollapsible = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext<CompanyCreatorV2Input>()

  return (
    <div className="flex w-full flex-col gap-3">
      <Controller
        control={control}
        name="billingProfile.invoiceAndEstimates.invoiceTerm"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Default Invoice Term</span>
            <Input
              {...field}
              placeholder="Enter default invoice term here..."
              status={
                errors.billingProfile?.invoiceAndEstimates?.invoiceMemo
                  ? 'error'
                  : undefined
              }
            />
          </label>
        )}
      />

      <Controller
        control={control}
        name="billingProfile.defaultPricebookTaxRate.rate"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Default Tax Rate</span>
            <InputNumber
              {...field}
              placeholder="Enter default tax rate here..."
              status={
                errors.billingProfile?.defaultPricebookTaxRate?.rate
                  ? 'error'
                  : undefined
              }
            />
          </label>
        )}
      />

      <Controller
        control={control}
        name="billingProfile.invoiceAndEstimates.invoiceMemo"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Default Invoice Term</span>
            <Input.TextArea
              {...field}
              placeholder="Enter invoice memo here......"
              status={
                errors.billingProfile?.invoiceAndEstimates?.invoiceMemo
                  ? 'error'
                  : undefined
              }
            />
          </label>
        )}
      />

      <Controller
        control={control}
        name="billingProfile.invoiceAndEstimates.estimateDisclaimer"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Default Estimate Disclaimer</span>
            <Input.TextArea
              {...field}
              rows={4}
              placeholder="Enter estimate disclaimer here..."
              status={
                errors.billingProfile?.invoiceAndEstimates?.estimateDisclaimer
                  ? 'error'
                  : undefined
              }
            />
          </label>
        )}
      />
      <Controller
        control={control}
        name="billingProfile.invoiceAndEstimates.invoiceDisclaimer"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Default Invoice Term</span>
            <Input.TextArea
              {...field}
              rows={4}
              placeholder="Enter invoice disclaimer here..."
              status={
                errors.billingProfile?.invoiceAndEstimates?.invoiceDisclaimer
                  ? 'error'
                  : undefined
              }
            />
          </label>
        )}
      />
    </div>
  )
}
