import { Tag, nextGuid } from '@breezy/shared'
import { Form, Input, Modal } from 'antd'
import { useCallback, useEffect } from 'react'
import { useMutation } from 'urql'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { usePrincipalUser } from '../../../providers/PrincipalUser'
import { useMessage } from '../../../utils/antd-utils'
import { COLORS } from '../../../utils/color-utils'
import { TAG_UPSERT_ONE } from '../Tags.gql'

type EditTagModalProps = {
  tag?: Tag
  open?: boolean
  /**
   * This function is called after the tag has been saved
   */
  onTagSaved?: () => void
  onClose?: () => void
}

export const EditTagModal = ({
  tag,
  open,
  onTagSaved,
  onClose,
}: EditTagModalProps) => {
  const message = useMessage()
  const [upsertResult, executeTagUpsert] = useMutation(TAG_UPSERT_ONE)

  const companyUser = usePrincipalUser().expectCompanyUserPrincipal()
  const [form] = Form.useForm<Tag>()

  const onSave = useCallback(async () => {
    try {
      const validatedForm = await form.validateFields()
      const tagToSave = tag
        ? {
            tagGuid: tag.tagGuid,
            companyGuid: tag.companyGuid,
            createdByUserGuid: tag.createdByUserGuid,
            name: tag.name,
            color: tag.color,
            ...(validatedForm as Partial<Tag>),
          }
        : {
            ...validatedForm,
            tagGuid: nextGuid(),
            companyGuid: companyUser.company.companyGuid,
            createdByUserGuid: companyUser.userGuid,
            color: COLORS[Math.floor(Math.random() * COLORS.length)],
          }

      const res = await executeTagUpsert({
        tag: tagToSave,
      })

      if (res.error) {
        if (
          res.error.graphQLErrors.some(
            error => error.extensions.code === 'constraint-violation',
          )
        ) {
          message.error(`Tag already has name "${tagToSave.name}"`)
          return
        }

        message.error(res.error.message)
        return
      }

      message.success('Tag created!')

      form.setFieldsValue({
        tagGuid: nextGuid(),
        name: '',
        companyGuid: companyUser.company.companyGuid,
        createdByUserGuid: companyUser.userGuid,
        color: COLORS[Math.floor(Math.random() * COLORS.length)],
      })

      onTagSaved?.()
    } catch (err) {
      message.error('Failed to create tag')
      return
    }
  }, [
    companyUser.company.companyGuid,
    companyUser.userGuid,
    executeTagUpsert,
    form,
    message,
    onTagSaved,
    tag,
  ])

  useEffect(() => {
    if (tag) {
      form.setFieldsValue(tag)
    } else {
      form.setFieldsValue({
        tagGuid: nextGuid(),
        name: '',
        companyGuid: companyUser.company.companyGuid,
        createdByUserGuid: companyUser.userGuid,
        color: COLORS[Math.floor(Math.random() * COLORS.length)],
      })
    }
  }, [companyUser.company.companyGuid, companyUser.userGuid, form, tag])

  return (
    <Modal
      open={open}
      title={tag ? `Edit ${tag.name}` : 'Create New Tag'}
      okText={
        upsertResult.fetching ? (
          <LoadingSpinner noMinHeight spinnerClassName="h-4 w-4" />
        ) : (
          'Save'
        )
      }
      onOk={onSave}
      onCancel={onClose}
      okButtonProps={{
        disabled: upsertResult.fetching,
      }}
    >
      <Form layout="vertical" form={form} data-form-name="tagForm">
        <Form.Item
          label="Name"
          name={'name'}
          required
          data-tag-form-id="name"
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (!(typeof value === 'string')) {
                  return Promise.reject('Invalid value')
                }

                if (value.trim() === '') {
                  return Promise.reject('Name cannot be empty')
                }

                if (value.trim() === tag?.name) {
                  return Promise.reject('Name is the same')
                }

                return Promise.resolve()
              },
            },
          ]}
        >
          <Input type="text" placeholder="Enter tag name here..." />
        </Form.Item>
      </Form>
    </Modal>
  )
}
