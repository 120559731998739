import {
  BzDateFns,
  EquipmentType,
  LocalDateString,
  formatEquipmentType,
} from '@breezy/shared'
import React, { useMemo } from 'react'
import { SmallInfoCard } from '../../elements/Card/SmallInfoCard'

type EquipmentInfoCardProps = {
  equipmentType: EquipmentType
  manufacturer?: string
  installDate?: LocalDateString
  modelNumber?: string
  serialNumber?: string
  estimatedEndOfLifeDate?: LocalDateString
}

export const EquipmentInfoCard = React.memo<EquipmentInfoCardProps>(
  ({
    equipmentType,
    manufacturer,
    installDate,
    modelNumber,
    serialNumber,
    estimatedEndOfLifeDate,
  }) => {
    const age = useMemo(() => {
      if (!installDate) {
        return undefined
      }
      return BzDateFns.differenceInYears(
        BzDateFns.now(BzDateFns.UTC),
        BzDateFns.parseLocalDate(installDate),
      )
    }, [installDate])

    const info = useMemo(
      () => [
        { label: 'Age', value: age ? `${age} yrs` : undefined },
        { label: 'Model #', value: modelNumber },
        { label: 'Serial #', value: serialNumber },
        {
          label: 'Est. End of Life',
          value: estimatedEndOfLifeDate
            ? BzDateFns.format(
                BzDateFns.parseLocalDate(estimatedEndOfLifeDate),
                'yyyy',
              )
            : undefined,
        },
      ],
      [age, estimatedEndOfLifeDate, modelNumber, serialNumber],
    )

    return (
      <SmallInfoCard
        header={`${formatEquipmentType(equipmentType)}${
          manufacturer ? ` (${manufacturer})` : ''
        }`}
        info={info}
      />
    )
  },
)
