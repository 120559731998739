import { QboCartItemTypeAccounts, isNullish } from '@breezy/shared'
import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useCallback } from 'react'
import { useMutation, useQuery } from 'urql'
import { BzTitleAndExplanation } from '../../elements/BzTitleAndExplanation/BzTitleAndExplanation'
import { useQboAccountTreeData } from '../../hooks/Quickbooks/useQboAccountTreeData'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { m } from '../../utils/react-utils'
import { LoadingSpinner } from '../LoadingSpinner'
import { FormCancelSubmitButtons } from '../form-fields/FormCancelSubmitButtons/FormCancelSubmitButtons'
import { QuickbooksAccountSelectionFormItem } from './QuickbooksAccountSelectionFormItem'
import {
  FETCH_QUICKBOOKS_CART_ITEM_TYPE_ACCOUNTS_GQL,
  UPSERT_QUICKBOOKS_CART_ITEM_TYPE_ACCOUNTS_GQL,
} from './QuickbooksCartItemTypeAccountsConfig.gql'
import { useIsQboEnabled } from './QuickbooksSyncButton'

const UPSERT_SAVE_ERROR_MESSAGE =
  'There was an unexpected error saving the Cart Item Accounts Config'

type QuickbooksCartItemTypeAccountsConfigProps = {
  cfg?: QboCartItemTypeAccounts
}

export const QuickbooksCartItemAccountsConfig =
  m<QuickbooksCartItemTypeAccountsConfigProps>(({ cfg }) => {
    const companyGuid = useExpectedCompanyGuid()
    const message = useMessage()
    const isQboEnabled = useIsQboEnabled()
    const qboAccounts = useQboAccountTreeData()
    const [{ data, fetching }, refetch] = useQuery({
      query: FETCH_QUICKBOOKS_CART_ITEM_TYPE_ACCOUNTS_GQL,
      variables: { companyGuid },
    })
    const [{ fetching: upserting }, upsert] = useMutation(
      UPSERT_QUICKBOOKS_CART_ITEM_TYPE_ACCOUNTS_GQL,
    )
    const isAsync = fetching || upserting

    const [form] = useForm<QboCartItemTypeAccounts>()
    const onFinish = useCallback(
      async (values: QboCartItemTypeAccounts) => {
        try {
          await upsert({ object: { ...values, companyGuid } })
        } catch (e) {
          console.error(UPSERT_SAVE_ERROR_MESSAGE, e)
          message.error(UPSERT_SAVE_ERROR_MESSAGE)
        } finally {
          refetch()
        }
      },
      [companyGuid, message, refetch, upsert],
    )

    if (isAsync || isNullish(qboAccounts))
      return (
        <div className="flex min-h-[320px] min-w-[240px] items-center">
          <LoadingSpinner />
        </div>
      )

    if (!isQboEnabled || !qboAccounts || qboAccounts.length < 1) return null

    return (
      <Form
        layout="vertical"
        form={form}
        initialValues={
          cfg ?? data?.qboCartItemTypeAccountsConfig[0] ?? undefined
        }
        onFinish={onFinish}
      >
        <BzTitleAndExplanation
          title="Default Pricebook Income Accounts"
          description="Configuring these accounts will provide default QBO Accounts for your Pricebook Item Types. These will be used for Pricebook items that don't have an account configured, or for ad-hoc items added to an invoice."
          attentionType="info"
        />
        <QuickbooksAccountSelectionFormItem
          required
          formItemName="defaultItemQboIncomeAccountId"
          formItemLabel="Default/Fallback Income Account"
          financeAccountsTreeData={qboAccounts.filter(
            x => x.accountType === 'Income',
          )}
          explanationTooltip="Select the QBO income account to be used as a default for all pricebook item types. This account will be used if a more specific account is not defined for a particular item type."
        />
        <QuickbooksAccountSelectionFormItem
          formItemName="equipmentItemDefaultQboIncomeAccountId"
          formItemLabel="Equipment Item Income Account"
          financeAccountsTreeData={qboAccounts.filter(
            x => x.accountType === 'Income',
          )}
          explanationTooltip="Select the QBO income account specifically for income generated from equipment sales. Overrides the default income account for equipment item types."
        />
        <QuickbooksAccountSelectionFormItem
          formItemName="serviceItemDefaultQboIncomeAccountId"
          formItemLabel="Service Item Income Account"
          financeAccountsTreeData={qboAccounts.filter(
            x => x.accountType === 'Income',
          )}
          explanationTooltip="Select the QBO income account for earnings from service offerings. Overrides the default income account for service-related item types."
        />
        <QuickbooksAccountSelectionFormItem
          formItemName="materialItemDefaultQboIncomeAccountId"
          formItemLabel="Material Item Income Account"
          financeAccountsTreeData={qboAccounts.filter(
            x => x.accountType === 'Income',
          )}
          explanationTooltip="Select the QBO income account for income from material sales. Overrides the default income account for material item types."
        />
        <QuickbooksAccountSelectionFormItem
          formItemName="laborItemDefaultQboIncomeAccountId"
          formItemLabel="Labor Item Income Account"
          financeAccountsTreeData={qboAccounts.filter(
            x => x.accountType === 'Income',
          )}
          explanationTooltip="Select the QBO income account for labor income. Overrides the default income account when labor is the item type."
        />
        <QuickbooksAccountSelectionFormItem
          formItemName="membershipItemDefaultQboIncomeAccountId"
          formItemLabel="Maintenance Plan Item Income Account"
          financeAccountsTreeData={qboAccounts.filter(
            x => x.accountType === 'Income',
          )}
          explanationTooltip="Select the QBO income account for revenue from maintenance or service plans. Overrides the default income account for maintenance plan item types."
        />
        <FormCancelSubmitButtons />
      </Form>
    )
  })
