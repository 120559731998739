import { bzExpect } from '@breezy/shared'
import api from '@flatfile/api'
import {
  FlatfileSheetReader,
  FlatfileSheetRecordsReader,
} from './FlatfileTypes'

export const createFlatfileSheetRecordsReader =
  (readSheet: FlatfileSheetReader): FlatfileSheetRecordsReader =>
  async input => {
    try {
      const sheet = await readSheet({
        workbookId: input.workbookId,
        slug: input.slug,
      })

      const res = await api.records.get(
        bzExpect(sheet, 'sheet', 'Sheet not found').id,
        { ...input.request, pageSize: 100_000 },
        input.requestOptions,
      )
      return res
    } catch (error) {
      console.error('Error reading sheet records', error)
      throw error
    }
  }
