import {
  cloneDeep,
  PermissionV2,
  R,
  ThisShouldNeverHappenError,
} from '@breezy/shared'
import { PermissionControlsActions } from './actions'
import {
  getInheritedPermissions,
  getNestedPermissionGroupSelectedPermissions,
  getPermissionCategory,
} from './getters'
import {
  FieldPermissionCategory,
  isFieldExperienceCategory,
  isOfficeExperienceCategory,
  isRankedPermissionGroup,
  PermissionCategory,
  PermissionCategoryState,
  PermissionControlsState,
  PermissionGroup,
} from './types'

export const permissionControlsReducer = (
  state: PermissionControlsState,
  { type, payload }: PermissionControlsActions,
): PermissionControlsState => {
  const nextState = cloneDeep(state)
  switch (type) {
    case '@@TOGGLE_SUPER_ADMIN':
      return toggleSuperAdminReducer(nextState, payload)
    case '@@TOGGLE_DESKTOP_APP_PERMISSIONS':
      return toggleOfficeAppPermissionsReducer(nextState, payload)
    case '@@TOGGLE_FIELD_APP_PERMISSIONS':
      return toggleFieldExperiencePermissionsReducer(nextState, payload)
    case '@@TOGGLE_CAPABILITY_CUSTOM_DISCOUNT_CREATION_ENABLED':
      return toggleCapabilityCustomDiscountCreationReducer(nextState, payload)
    case '@@TOGGLE_PERMISSION_CATEGORY_PERMISSIONS':
      return togglePermissionCategoryReducer(nextState, payload)
    case '@@SELECT_PERMISSION_CATEGORY_PERMISSION':
      return setPermissionCategoryPermissionReducer(nextState, payload)
    case '@@CHECKED_PERMISSIONS_PERMISSION_CATEGORY':
      return checkedPermissionsReducer(nextState, payload)
    case '@@SELECT_OFFICE_FINANCIAL_INFORMATION_NESTED_PERMISSION':
      return selectOfficeFinancialInformationNestedPermissionReducer(
        nextState,
        payload,
      )
    case '@@CHECKED_NESTED_PERMISSIONS_OFFICE_FINANCIAL_INFORMATION': {
      return checkedNestedPermissionsOfficeFinancialInformationReducer(
        nextState,
        payload,
      )
    }
    default:
      return state
  }
}

const toggleSuperAdminReducer = (
  state: PermissionControlsState,
  payload: { enabled: boolean },
): PermissionControlsState => {
  return {
    ...state,
    isSuperAdmin: payload.enabled,
    selectedPermissions: payload.enabled
      ? [PermissionV2.ALL_ACTIONS]
      : state.selectedPermissions.filter(
          permission => permission !== PermissionV2.ALL_ACTIONS,
        ),
  }
}

const toggleOfficeAppPermissionsReducer = (
  state: PermissionControlsState,
  payload: { enabled: boolean },
): PermissionControlsState => {
  return {
    ...state,
    OFFICE_EXPERIENCE: {
      ...state.OFFICE_EXPERIENCE,
      enabled: payload.enabled,
      selectedPermissions: payload.enabled
        ? [PermissionV2.USE_OFFICE_EXPERIENCE]
        : [],
    },
  }
}

const toggleCapabilityCustomDiscountCreationReducer = (
  state: PermissionControlsState,
  payload: { enabled: boolean },
): PermissionControlsState => {
  return {
    ...state,
    CAPABILITIES: {
      ...state.CAPABILITIES,
      customDiscounts: payload.enabled,
    },
    selectedPermissions: payload.enabled
      ? [PermissionV2.CAPABILITY_CUSTOM_DISCOUNT_CREATION_ENABLED]
      : state.selectedPermissions.filter(
          permission =>
            permission !==
            PermissionV2.CAPABILITY_CUSTOM_DISCOUNT_CREATION_ENABLED,
        ),
  }
}

const toggleFieldExperiencePermissionsReducer = (
  state: PermissionControlsState,
  payload: { enabled: boolean },
): PermissionControlsState => {
  for (const categoryKey of Object.keys(
    state.FIELD_EXPERIENCE.permissionCategories,
  ) as FieldPermissionCategory[]) {
    state = updatePermissionCategory(state, categoryKey, currentCategory => ({
      ...currentCategory,
      permissionGroups: currentCategory.permissionGroups.map(group => {
        if (R.isEmpty(group.selectedPermissions)) {
          return {
            ...group,
            selectedPermissions: [...group.defaultPermissions],
          }
        }
        return group
      }),
    }))
  }

  return {
    ...state,
    FIELD_EXPERIENCE: {
      ...state.FIELD_EXPERIENCE,
      enabled: payload.enabled,
      permissionCategories: {
        ...state.FIELD_EXPERIENCE.permissionCategories,
      },
      selectedPermissions: payload.enabled
        ? [PermissionV2.USE_FIELD_EXPERIENCE]
        : [],
    },
  }
}

const togglePermissionCategoryReducer = (
  state: PermissionControlsState,
  { category, enabled }: { category: PermissionCategory; enabled: boolean },
): PermissionControlsState => {
  return updatePermissionCategory(state, category, currentCategory => ({
    ...currentCategory,
    enabled: enabled,
    permissionGroups: currentCategory.permissionGroups.map(group => ({
      ...group,
      selectedPermissions:
        enabled && R.isEmpty(group.selectedPermissions)
          ? group.defaultPermissions
          : group.selectedPermissions,
    })),
  }))
}

const setPermissionCategoryPermissionReducer = (
  state: PermissionControlsState,
  {
    category,
    permission,
    permissionGroupIndex,
  }: {
    category: PermissionCategory
    permission: PermissionV2
    permissionGroupIndex: number
  },
): PermissionControlsState => {
  return updatePermissionGroup(state, category, (group, index) =>
    index === permissionGroupIndex
      ? {
          ...group,
          selectedPermissions: isRankedPermissionGroup(group)
            ? getInheritedPermissions(permission, group.rankedPermissions)
            : group.selectedPermissions,
        }
      : group,
  )
}

const checkedPermissionsReducer = (
  state: PermissionControlsState,
  {
    category,
    permissions,
    permissionGroupIndex,
  }: {
    category: PermissionCategory
    permissions: PermissionV2[]
    permissionGroupIndex: number
  },
): PermissionControlsState => {
  return updatePermissionGroup(state, category, (group, index) =>
    index === permissionGroupIndex
      ? {
          ...group,
          selectedPermissions: permissions,
        }
      : group,
  )
}

const selectOfficeFinancialInformationNestedPermissionReducer = (
  state: PermissionControlsState,
  {
    permissionGroupIndex,
    value,
  }: {
    permissionGroupIndex: number
    value: PermissionV2 | number
  },
) => {
  return updatePermissionGroup(
    state,
    'OFFICE_FINANCIAL_INFORMATION',
    (group, index) => {
      return index === permissionGroupIndex
        ? {
            ...group,
            selectedPermissions: getNestedPermissionGroupSelectedPermissions(
              group,
              value,
            ),
          }
        : group
    },
  )
}

const checkedNestedPermissionsOfficeFinancialInformationReducer = (
  state: PermissionControlsState,
  {
    permissionGroupIndex,
    permissions,
  }: {
    permissionGroupIndex: number
    permissions: PermissionV2[]
  },
) => {
  return updatePermissionGroup(
    state,
    'OFFICE_FINANCIAL_INFORMATION',
    (group, index) => {
      if (index === permissionGroupIndex) {
        return {
          ...group,
          selectedPermissions: permissions,
        }
      } else {
        return group
      }
    },
  )
}

const updatePermissionCategory = (
  state: PermissionControlsState,
  category: PermissionCategory,
  updateCallback: (
    category: PermissionCategoryState,
  ) => PermissionCategoryState,
): PermissionControlsState => {
  let experience: 'OFFICE_EXPERIENCE' | 'FIELD_EXPERIENCE'

  if (isOfficeExperienceCategory(category)) {
    experience = 'OFFICE_EXPERIENCE'
  } else if (isFieldExperienceCategory(category)) {
    experience = 'FIELD_EXPERIENCE'
  } else {
    throw new Error(`Category ${category} not found in PermissionControlsState`)
  }

  const currentCategory = getPermissionCategory(state, category)

  return {
    ...state,
    [experience]: {
      ...state[experience],
      permissionCategories: {
        ...state[experience].permissionCategories,
        [category]: updateCallback(currentCategory),
      },
    },
  }
}

const updatePermissionGroup = (
  state: PermissionControlsState,
  category: PermissionCategory,
  updateCallback: (group: PermissionGroup, index: number) => PermissionGroup,
): PermissionControlsState => {
  if (isOfficeExperienceCategory(category)) {
    return {
      ...state,
      OFFICE_EXPERIENCE: {
        ...state.OFFICE_EXPERIENCE,
        permissionCategories: {
          ...state.OFFICE_EXPERIENCE.permissionCategories,
          [category]: {
            ...state.OFFICE_EXPERIENCE.permissionCategories[category],
            permissionGroups:
              state.OFFICE_EXPERIENCE.permissionCategories[
                category
              ].permissionGroups.map(updateCallback),
          },
        },
      },
    }
  } else if (isFieldExperienceCategory(category)) {
    return {
      ...state,
      FIELD_EXPERIENCE: {
        ...state.FIELD_EXPERIENCE,
        permissionCategories: {
          ...state.FIELD_EXPERIENCE.permissionCategories,
          [category]: {
            ...state.FIELD_EXPERIENCE.permissionCategories[category],
            permissionGroups:
              state.FIELD_EXPERIENCE.permissionCategories[
                category
              ].permissionGroups.map(updateCallback),
          },
        },
      },
    }
  } else {
    throw new ThisShouldNeverHappenError(
      `Category ${category} not found in PermissionControlsState`,
    )
  }
}
