import { BzDateFns, IsoDateString, R, SimplePriority } from '@breezy/shared'
import { UnassignedAppointment } from '../Schedule.gql'

export enum UnscheduledSortFilterType {
  SMART = 'Smart',
  PRIORITY = 'Priority',
  ARRIVAL_WINDOW = 'Arrival Window',
  ACTIVITY_DURATION = 'Activity Duration',
}

export type EnhancedUnassignedAppointment = UnassignedAppointment & {
  priority: SimplePriority
  estimatedDurationMinutes: number
}

type Comparator = Parameters<
  typeof R.sortWith<EnhancedUnassignedAppointment>
>[0][number]

const priorityValue = {
  [SimplePriority.HIGH]: 99,
  [SimplePriority.MEDIUM]: 50,
  [SimplePriority.LOW]: 1,
}

const sortByPriorityDescending = R.descend<EnhancedUnassignedAppointment>(
  appointment => priorityValue[appointment.priority],
)

const sortByActivityDurationDescending =
  R.descend<EnhancedUnassignedAppointment>(R.prop('estimatedDurationMinutes'))

// The sort is going to do a lot of date parsing, so might as well memoize
const parsedDateMemo: Record<IsoDateString, Date> = {}

const parseDate = (dateStr: IsoDateString) => {
  if (parsedDateMemo[dateStr]) {
    return parsedDateMemo[dateStr]
  }
  const parsed = BzDateFns.parseISO(dateStr, BzDateFns.UTC)
  parsedDateMemo[dateStr] = parsed
  return parsed
}

const sortByArrivalWindowChronologically = [
  R.ascend<EnhancedUnassignedAppointment>(appointment =>
    parseDate(appointment.appointmentWindowStart),
  ),
  R.ascend<EnhancedUnassignedAppointment>(appointment =>
    parseDate(appointment.appointmentWindowEnd),
  ),
]

export const sortFilterByFilterType = {
  [UnscheduledSortFilterType.SMART]: [
    sortByActivityDurationDescending,
    ...sortByArrivalWindowChronologically,
    sortByPriorityDescending,
  ],
  [UnscheduledSortFilterType.PRIORITY]: [sortByPriorityDescending],
  [UnscheduledSortFilterType.ACTIVITY_DURATION]: [
    sortByActivityDurationDescending,
    sortByPriorityDescending,
  ],
  [UnscheduledSortFilterType.ARRIVAL_WINDOW]: [
    ...sortByArrivalWindowChronologically,
    sortByPriorityDescending,
  ],
} satisfies Record<UnscheduledSortFilterType, Comparator[]>
