import { JobClass } from '@breezy/shared'
import { Card, Divider, Form, Select } from 'antd'
import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useQuery } from 'urql'
import GqlQueryLoader from '../../../../../../components/GqlQueryLoader/GqlQueryLoader'
import { ChangeJobTypeFormSchema } from '../../change-job-type-form-schema'
import { JOB_TYPE_WITH_JOB_LIFECYCLE_QUERY } from './ChangeJobPipelineCardSelect.gql'

export type ChangeJobPipelineCardSelectProps = {
  selectedJobType: { jobTypeGuid: string; name: string; jobClass: JobClass }
}

export const ChangeJobPipelineCardSelect = ({
  selectedJobType,
}: ChangeJobPipelineCardSelectProps) => {
  const { control, formState, setValue } =
    useFormContext<ChangeJobTypeFormSchema>()

  const jobTypeWithLifecycleQuery = useQuery({
    query: JOB_TYPE_WITH_JOB_LIFECYCLE_QUERY,
    variables: { jobTypeGuid: selectedJobType.jobTypeGuid },
  })

  const jobTypeWithLifecycleQueryRes = jobTypeWithLifecycleQuery[0]

  useEffect(() => {
    if (!jobTypeWithLifecycleQueryRes.data) {
      return
    }

    setValue(
      'jobLifecycle.jobLifecycleGuid',
      jobTypeWithLifecycleQueryRes.data.jobTypesByPk?.jobLifecycle
        .jobLifecycleGuid ?? '',
    )

    setValue('jobLifecycle.jobLifecycleStatusGuid', '')
  }, [jobTypeWithLifecycleQueryRes, setValue])

  return (
    <Card
      bodyStyle={{ padding: '0px', margin: '0px' }}
      className="bg-[#FAFAFA] px-4 py-3"
    >
      <GqlQueryLoader
        query={jobTypeWithLifecycleQuery}
        render={data => (
          <>
            <h3 className="m-0 text-sm font-semibold text-bz-gray-1000">
              Change Job Pipeline
            </h3>

            <span className="text-xs leading-5 text-bz-gray-1000">
              The updated job type means that this job will now be displayed in
              a different pipeline. Select which status it should be placed in.
            </span>

            <Divider />

            <div className="flex w-full flex-row gap-3">
              <Controller
                control={control}
                name="jobLifecycle.jobLifecycleGuid"
                render={({ field }) => (
                  <Form.Item label="Job Pipeline" className="grow">
                    <Select
                      {...field}
                      disabled
                      value={data.jobTypesByPk?.jobLifecycle.jobLifecycleGuid}
                      options={[
                        {
                          label: data.jobTypesByPk?.jobLifecycle.name,
                          value:
                            data.jobTypesByPk?.jobLifecycle.jobLifecycleGuid,
                        },
                      ]}
                      status={
                        formState.errors.jobLifecycle?.jobLifecycleGuid
                          ? 'error'
                          : undefined
                      }
                    />
                  </Form.Item>
                )}
              />

              <Controller
                control={control}
                name="jobLifecycle.jobLifecycleStatusGuid"
                render={({ field }) => (
                  <Form.Item label="Pipeline Status" className="grow">
                    <Select
                      {...field}
                      value={field.value}
                      options={
                        data.jobTypesByPk?.jobLifecycle.jobLifecycleStatuses.map(
                          lifecycleStatus => ({
                            label: lifecycleStatus.name,
                            value: lifecycleStatus.jobLifecycleStatusGuid,
                          }),
                        ) ?? []
                      }
                      status={
                        formState.errors.jobLifecycle?.jobLifecycleStatusGuid
                          ? 'error'
                          : undefined
                      }
                    />
                  </Form.Item>
                )}
              />
            </div>
          </>
        )}
      />
    </Card>
  )
}
