import { faCircleCheck, faXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { notification } from 'antd'

import { polarGreen6 } from '../../themes/theme'

export const notifySaveUserSuccess = (message: string, description: string) =>
  notification.success({
    message: <div className="font-semibold">{message}</div>,
    icon: (
      <FontAwesomeIcon
        className="rounded-full"
        color={polarGreen6}
        icon={faCircleCheck}
      />
    ),
    description: <div className="text-bz-gray-700">{description}</div>,
    duration: 3,
    closeIcon: <FontAwesomeIcon size="lg" icon={faXmark} className="" />,
  })
