import { useEffect } from 'react'

type NavigateToExternalProps = {
  readonly url: string
}

const NavigateToExternal = ({ url }: NavigateToExternalProps) => {
  useEffect(() => {
    window.location.href = url
  })

  return null
}

export default NavigateToExternal
