import { memo } from 'react'
import { useQuery } from 'urql'
import { useCompanyMaintenancePlansEnabled } from '../../../hooks/useCompanyMaintenancePlansEnabled'
import { useExpectedCompanyGuid } from '../../../providers/PrincipalUser'
import { FullWidthColumn } from '../Columns'
import { DashboardDivider } from '../DashboardDivider'
import {
  StandardReportingDateRangePicker,
  useStandardReportingDateRangePickerState,
} from '../ReportingDateRangePicker/StandardReportingDateRangePicker'
import { TileGroup } from '../TileGroup'
import { AverageTicketSizeTile } from './AverageTicketSizeTile'
import { IndividualTechnicianPerformanceWidget } from './IndividualTechnicianPerformanceWidget'
import { JobConversionRateTile } from './JobConversionRateTile'
import { MaintenancePlansTile } from './MaintenancePlansTile'
import { TeamPerformanceByJobClassWidget } from './TeamPerformanceByJobClassWidget'
import { TechGeneratedLeadsTile } from './TechGeneratedLeadsTile'
import { TotalJobRevenueTile } from './TotalJobRevenueTile'
import { TotalNumberOfJobsTile } from './TotalNumberOfJobsTile'
import { TEAM_PERFORMANCE_DASHBOARD_METRICS_QUERY } from './team-performance-queries.gql'

export const TeamPerformanceDashboard = memo(() => {
  const companyGuid = useExpectedCompanyGuid()

  const maintenancePlansEnabled = useCompanyMaintenancePlansEnabled()

  const [dateRange, setDateRange] = useStandardReportingDateRangePickerState()

  const [{ data, fetching }] = useQuery({
    query: TEAM_PERFORMANCE_DASHBOARD_METRICS_QUERY,
    variables: {
      where: {
        companyGuid: { _eq: companyGuid },
        _and: [
          { revenueRecognizedAt: { _gte: dateRange.start } },
          { revenueRecognizedAt: { _lte: dateRange.end } },
        ],
      },
    },
  })

  return (
    <>
      <FullWidthColumn>
        <TileGroup
          title="Team Performance"
          extra={
            <StandardReportingDateRangePicker
              range={dateRange}
              setRange={setDateRange}
            />
          }
          marginBottomClass="mb-3"
        >
          <TotalNumberOfJobsTile loading={fetching} jobs={data?.jobs ?? []} />
          <JobConversionRateTile loading={fetching} jobs={data?.jobs ?? []} />
          <TotalJobRevenueTile loading={fetching} jobs={data?.jobs ?? []} />
          {maintenancePlansEnabled && (
            <AverageTicketSizeTile loading={fetching} jobs={data?.jobs ?? []} />
          )}
        </TileGroup>
      </FullWidthColumn>
      <FullWidthColumn>
        <TileGroup title="">
          {maintenancePlansEnabled ? (
            <MaintenancePlansTile loading={fetching} jobs={data?.jobs ?? []} />
          ) : (
            <AverageTicketSizeTile loading={fetching} jobs={data?.jobs ?? []} />
          )}
          <TechGeneratedLeadsTile loading={fetching} jobs={data?.jobs ?? []} />
        </TileGroup>
      </FullWidthColumn>
      <FullWidthColumn>
        <TeamPerformanceByJobClassWidget jobs={data?.jobs ?? []} />
      </FullWidthColumn>
      <DashboardDivider />
      <FullWidthColumn>
        <IndividualTechnicianPerformanceWidget />
      </FullWidthColumn>
    </>
  )
})
