import api from '@flatfile/api'
import { FlatfileSheetReader } from './FlatfileTypes'

export const createFlatfileSheetReader =
  (): FlatfileSheetReader =>
  async ({ workbookId, slug }) => {
    try {
      const res = await api.sheets.list({
        workbookId,
      })

      const sheet = res.data.find(sheet => sheet.config.slug === slug)

      return sheet
    } catch (error) {
      console.error('Error reading sheet', error)
      throw error
    }
  }
