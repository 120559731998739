import { gql } from 'src/generated'

export const CANONICAL_LEAD_SOURCES_QUERY = gql(/* GraphQL */ `
  query CreateCompanyDrawerLeadSourcesSectionCanonicalLeadSourcesQuery {
    canonicalLeadSources {
      canonicalLeadSourceName
      attributionLinkingStrategy
      attributionPrompt
    }
  }
`)
