import cn from 'classnames'
import { useDrag, useDrop } from 'react-dnd'

import React, { useEffect, useRef } from 'react'
import { JobLifecycleScrollPosition } from '../../hooks/useJobLifecyclePersistedDisplay'
import { Styled } from '../../utils/Stylable'
import { typedMemo } from '../../utils/react-utils'
import './Kanban.less'
import {
  BasicKanbanData,
  Board,
  BoardIdToStatusIdMap,
  useBoardIdToStatusIdMap,
  useGroupedKanbanData,
  useKanbanStatuses,
} from './kanbanUtils'

export const BASIC_KANBAN_CARD_STYLES =
  'kanban-card kanban-card-hover-shadow rounded-md border border-solid border-bz-gray-500 bg-white p-4 hover:border-bz-gray-600'

type KanbanCardProps<T extends BasicKanbanData> = {
  item: T
  disabled?: boolean
  isLoading?: boolean
  renderCardContent: (data: T) => React.ReactNode
  onClick?: () => void
}

const KanbanCard = typedMemo(
  <T extends BasicKanbanData>({
    item,
    disabled,
    isLoading,
    renderCardContent,
    onClick,
  }: KanbanCardProps<T>) => {
    const [{ isDragging }, drag] = useDrag(
      () => ({
        type: 'KANBAN_CARD',
        item,
        canDrag: !disabled,
        collect: monitor => ({
          isDragging: !!monitor.isDragging(),
        }),
      }),
      [disabled],
    )

    return (
      <div
        className={cn(
          BASIC_KANBAN_CARD_STYLES,
          'mx-3 mt-3',
          isLoading
            ? 'cursor-progress'
            : onClick || disabled
            ? 'cursor-pointer'
            : 'cursor-grab',
          {
            'cursor-grabbing opacity-30': isDragging,
          },
        )}
        ref={drag}
        onClick={onClick}
      >
        {renderCardContent(item)}
      </div>
    )
  },
)

type KanbanColumnProps<T extends BasicKanbanData> = React.PropsWithChildren<
  Styled<{
    statusName: string
    columnWidth: number
    onChange: (datum: T, statusId: string) => void
    boardIdToStatusIdMap: BoardIdToStatusIdMap
  }>
>

const KanbanColumn = typedMemo(
  <T extends BasicKanbanData>({
    statusName,
    children,
    columnWidth,
    onChange,
    className,
    style,
    boardIdToStatusIdMap,
  }: KanbanColumnProps<T>) => {
    const [{ isOver, canDrop, isDragging }, drop] = useDrop(
      () => ({
        accept: 'KANBAN_CARD',
        drop: (item: T) => {
          const statusId = boardIdToStatusIdMap[item.boardId]?.[statusName]
          if (statusId) {
            onChange(item, statusId)
          }
        },
        collect: monitor => ({
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop(),
          isDragging: !!monitor.getItem(),
        }),
        canDrop: (item: T) => {
          return !!boardIdToStatusIdMap[item.boardId]?.[statusName]
        },
      }),
      [onChange, statusName, boardIdToStatusIdMap],
    )
    return (
      <div
        ref={drop}
        className={cn(
          'relative flex flex-1 flex-col border-y-0 pr-0.5',
          {
            'kanban-column-drag-over': canDrop && isOver,
          },
          {
            'bg-daybreak-blue-100': canDrop,
          },
          className,
        )}
        style={{
          minWidth: `${columnWidth}px`,
          ...(style ?? {}),
        }}
      >
        {children}
        {isDragging && !canDrop && (
          <div className="pointer-events-none absolute inset-0 bg-red-100 opacity-30" />
        )}
      </div>
    )
  },
)

type KanbanProps<T extends BasicKanbanData> = {
  disabled?: boolean
  isLoading?: boolean
  board: Board
  data: readonly T[]
  onChange: (datum: T, statusId: string) => void
  columnWidth?: number
  hideEmptyStatuses?: boolean
  renderCardContent: (data: T) => React.ReactNode
  onCardClick?: (data: T) => void
  scrollPos?: JobLifecycleScrollPosition
  setScrollPos?: (newScrollPos: JobLifecycleScrollPosition) => void
}

export const Kanban = typedMemo(
  <T extends BasicKanbanData>({
    disabled,
    isLoading,
    board,
    data,
    onChange,
    renderCardContent,
    hideEmptyStatuses,
    onCardClick,
    scrollPos,
    setScrollPos,
    columnWidth = 300,
  }: KanbanProps<T>) => {
    const groupedData = useGroupedKanbanData(data, board)
    const boardIdToStatusIdMap = useBoardIdToStatusIdMap(board)
    const statuses = useKanbanStatuses(groupedData, board, hideEmptyStatuses)

    const kanban = useRef<HTMLDivElement>(null)
    useEffect(() => {
      if (!kanban.current || !scrollPos) {
        return
      }

      const { offsetTop, offsetLeft } = scrollPos
      kanban.current.scrollTop = offsetTop
      kanban.current.scrollLeft = offsetLeft

      // We should only run this when the kanban reference is updated
      // eslint-disable-next-line
    }, [kanban])

    return (
      <div
        ref={kanban}
        className="kanban relative flex h-full min-h-0 w-full max-w-full flex-1 flex-col overflow-auto"
        onScroll={event => {
          if (!setScrollPos) {
            return
          }

          setScrollPos({
            offsetTop: event.currentTarget.scrollTop,
            offsetLeft: event.currentTarget.scrollLeft,
          })
        }}
      >
        <div className="sticky top-0 z-10 flex w-full flex-col bg-white text-base font-semibold text-bz-gray-900">
          <div className="flex min-w-fit flex-row divide-x bg-bz-gray-200">
            {statuses.map(({ statusName, icon }, statusIndex) => (
              <React.Fragment key={`${statusName}-${statusIndex}`}>
                <div
                  key={statusName}
                  className={cn(
                    'flex flex-1 flex-row items-center justify-between border-0 border-y-0 border-solid p-4 text-sm font-semibold',
                    statusIndex === 0
                      ? 'border-bz-gray-900'
                      : 'border-bz-gray-500',
                    {
                      'border-l': statusIndex !== 0 || statusIndex !== 0,
                    },
                  )}
                  style={{
                    minWidth: `${columnWidth}px`,
                  }}
                >
                  <div className="flex flex-row items-center">
                    {icon ? <span className="mr-2">{icon}</span> : null}
                    {statusName}
                  </div>
                  <div className="text-xs">
                    {groupedData?.[statusName]?.length ?? 0}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="flex w-full min-w-fit flex-1 flex-row bg-bz-gray-200">
          {statuses.map((status, statusIndex) => (
            <React.Fragment key={`${status.statusName}-${statusIndex}`}>
              <KanbanColumn
                key={`${status.statusName}-${statusIndex}`}
                statusName={status.statusName}
                columnWidth={columnWidth}
                boardIdToStatusIdMap={boardIdToStatusIdMap}
                onChange={onChange}
                className={cn(
                  'border-0 border-solid',
                  statusIndex === 0
                    ? 'border-bz-gray-900'
                    : 'border-bz-gray-500',
                  {
                    'border-l': statusIndex !== 0,
                  },
                )}
              >
                {status.items.map(item => (
                  <KanbanCard
                    key={item.id}
                    disabled={disabled}
                    isLoading={isLoading}
                    item={item}
                    renderCardContent={renderCardContent}
                    onClick={() => onCardClick?.(item)}
                  />
                ))}
              </KanbanColumn>
            </React.Fragment>
          ))}
        </div>
        <div className="sticky bottom-0 z-10 flex w-full flex-col bg-white text-base font-semibold text-bz-gray-900">
          <div className="flex min-w-fit flex-row divide-x bg-bz-gray-200">
            {statuses.some(status => status.summary) &&
              statuses.map(({ summary }, statusIndex) => (
                <div
                  key={statusIndex}
                  className={cn(
                    'flex flex-1 flex-row items-center justify-between border-0 border-y-0 border-solid p-3 text-sm font-semibold',
                    statusIndex === 0
                      ? 'border-bz-gray-900'
                      : 'border-bz-gray-500',
                    {
                      'border-l': statusIndex !== 0 || statusIndex !== 0,
                    },
                  )}
                  style={{
                    minWidth: `${columnWidth}px`,
                  }}
                >
                  {summary}
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  },
)
