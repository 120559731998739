import { BulkPricebookItemRow, isNullish } from '@breezy/shared'
import { RecordsWithLinks } from '@flatfile/api/api'
import {
  fromRecordToBulkPricebookItemRow,
  toFlatfilePricebookItemRecord,
} from './FlatfileConversions'

export const fromFlatfileRecordToBulkPricebookItemRow = (
  records: RecordsWithLinks,
): BulkPricebookItemRow[] => {
  return records
    .filter(r => r.valid)
    .map(toFlatfilePricebookItemRecord)
    .map(fromRecordToBulkPricebookItemRow)
    .filter((record): record is BulkPricebookItemRow => !isNullish(record))
}
