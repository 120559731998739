import classNames from 'classnames'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { OnResize, useResizeObserver } from '../../hooks/useResizeObserver'

type AbbreviatedListProps = React.PropsWithChildren<{
  renderMore?: (n: number) => React.ReactNode
  spacingClassName?: string
  backgroundColorClassName?: string
}>

export const AbbreviatedList = React.memo<AbbreviatedListProps>(
  ({
    children,
    renderMore = n => <div>+{n}</div>,
    spacingClassName = 'space-x-1',
    backgroundColorClassName = 'bg-white',
  }) => {
    const containerRef = useRef<HTMLDivElement>(null)

    const moreRef = useRef<HTMLDivElement>(null)

    // We need to keep this outside of the big calculation memo and updated with a `useLayoutEffect` because we need
    // them to potentially trigger each other. The calculation memo tells us what to put inside that more component.
    // That causes the component to rerender (if it changed). If it changed, then that triggers the layout effect (after
    // it's rendered, because layout effect) and sets this state variable. That will trigger the memo again, which may
    // need to hide more elements if the new size of the "more" element takes up too much space.
    const [moreWidth, setMoreWidth] = useState(0)

    const [containerWidth, setContainerWidth] = useState(0)

    const onResize = useCallback<OnResize>(({ width }) => {
      setContainerWidth(width)
    }, [])

    useResizeObserver(containerRef, onResize)

    const [hiderInfo, setHiderInfo] = useState({ numHidden: 0, hiderWidth: 0 })
    const { numHidden, hiderWidth } = hiderInfo

    useLayoutEffect(() => {
      if (!containerRef.current || !containerWidth) {
        return
      }

      const elems = containerRef.current.children

      // The problem is we want to take a `spacingClassName` so we can add spacing between the elements. We can't take a
      // number because we can't dynamically create a style/class that applies to children (tailwind won't work with
      // dynamic values). We could iterate through the children and set the margin, or wrap them in <span> tags, but
      // that's hard. With the `space-x-n` classes in Tailwind, they'll apply a left margin to all but the first
      // element. So we take the left margin of the second element (what if there's only one element? More on that
      // later). We use that number two places: we use it to calculate the space "used" by each child to figure out how
      // much space we have for the next child, and we use it to offset the "more" so it isn't butting up against the
      // last element. But what if the list only has one element? First notice that we check if the length is greater
      // than 2. The container renders the `children` then renders our "hider" element. Because of the hider element,
      // the size of that array is always `n + 1`. So if we only had one element the length would be 2 (or 1 if we had
      // no elements). So if it's more than 2 then we at least have a second element. If we have one element, then that
      // element either fits or it doesn't. If it fits, neither of the reasons we need the space (calculating the
      // remaining space and offsetting the "more") are relevant: we don't need to calculate "space left over for the
      // next element" because there is no next element because we only had one element, and since we have no more
      // elements we won't have a "more" because there won't be a "+n" more that are hidden, so the offset isn't
      // relevant. The other scenario is the first element itself is too big to fit in the container. In that case we'd
      // show no elements and just show "+1". But we'd want that to have a 0 offset anyway because we'd want it to butt
      // against the left of the container.
      const spacing =
        elems.length > 2 ? parseInt(getComputedStyle(elems[1]).marginLeft) : 0

      const availableWidthWithMore = containerWidth - moreWidth - spacing
      const availableWidthWithoutMore = containerWidth

      let totalWidth = 0
      let numVisible = 0
      // We go to `elems.length - 1` because the last element is the "more" element, which we aren't measuring here.
      for (let i = 0; i < elems.length - 1; i++) {
        const elem = elems[i]

        const width = (elem as HTMLElement).offsetWidth

        const candidateNewTotalWidth = totalWidth + width
        // If it's bigger than the container (even without the "more" element), then we can't fit this element and we
        // stop the loop.
        if (candidateNewTotalWidth > availableWidthWithoutMore) {
          break
        }
        // There is a scenario where the last element is such a perfect size that it would just fit the full
        // container, but wouldn't fit with a "+1" box there. We don't care that it doesn't fit with the "more" box
        // because we don't need a more box. But if we aren't the last element, then we have to take the "more" box
        // into account when deciding if we're over. The last element will be index length - 2, because the last
        // element is length - 1 and we don't count the hider.
        if (
          candidateNewTotalWidth > availableWidthWithMore &&
          i < elems.length - 2
        ) {
          break
        }
        // At this point, we know that our element fits, so we'll count it and continue

        // The width from the bounding client react doesn't include the margin/spacing. However, we want to count the
        // margin/spacing in our ongoing width sum.
        totalWidth = candidateNewTotalWidth + spacing
        numVisible++
      }
      // Minus -1 because of the "hider" element
      const numHidden = elems.length - numVisible - 1

      // Our container has `whitespace-nowrap` and `overflow-hidden`. If we have too many elements, the last element
      // will be "cut off". We kind of "spoof" not rendering the overflowing elements by having an absolutely-positioned
      // "hider" element with a white background (can be overridden as a prop to this component if the background isn't
      // white) that covers the last element. It will be the width of the container minus the sum of the widths of the
      // visible elements. Inside the hider we render the "more" element. Because of how we compute `totalWidth`, the
      // "hider" element doesn't quite butt up against the last element; it doesn't cover the spacing to the right of
      // it. This is good, though, because we want to give the "more" element that same amount of spacing, so we kind of
      // get that for free. We also add 1, which solves some edge cases where things are off by one.
      const hiderWidth = numHidden === 0 ? 0 : containerWidth - totalWidth + 1

      setHiderInfo({ numHidden, hiderWidth })
      // Note that even though we don't use `children` directly, we need to include it. We use
      // `containerRef.current.children`, but changes to ref values don't cause rerenders. But that value only
      // meaningfully changes when the children change anyway.
    }, [moreWidth, children, containerWidth])

    useLayoutEffect(() => {
      if (moreRef.current) {
        setMoreWidth(Math.ceil(moreRef.current.getBoundingClientRect().width))
      }
      // The number of hidden elements is what changes the size of the "more" element, so when it changes we need to
      // re-measure.
    }, [numHidden])

    const moreContent = useMemo(() => {
      if (numHidden === 0) {
        return null
      }
      return renderMore(numHidden)
    }, [numHidden, renderMore])

    return (
      <div
        ref={containerRef}
        className={classNames(
          'relative w-full max-w-full flex-1 overflow-hidden whitespace-nowrap',
          spacingClassName,
        )}
      >
        {children}
        <div
          className={classNames(
            'absolute inset-y-0 right-0 flex flex-row items-center overflow-hidden',
            backgroundColorClassName,
          )}
          style={{ width: `${hiderWidth}px` }}
        >
          <div ref={moreRef}>{moreContent}</div>
        </div>
      </div>
    )
  },
)
