import { SearchableRecord } from '@breezy/shared'
import { faRotate } from '@fortawesome/pro-solid-svg-icons'
import { Button, Checkbox } from 'antd'
import { memo, useCallback, useState } from 'react'
import BzDrawer from '../../elements/BzDrawer/BzDrawer'
import { trpc } from '../../hooks/trpc'
import { useMessage } from '../../utils/antd-utils'
import { FormCancelSubmitButtons } from '../form-fields/FormCancelSubmitButtons/FormCancelSubmitButtons'

type SyncRecordsProgressTextProps = {
  status: 'loading' | 'error' | 'success' | 'idle'
}
const SyncRecordsProgressText = memo(
  ({ status }: SyncRecordsProgressTextProps) => {
    switch (status) {
      case 'loading':
        return <span className="text-bz-gray-600">(syncing...)</span>
      case 'error':
        return <span className="text-bz-red-600">(syncing failed)</span>
      case 'success':
        return <span className="text-bz-green-600">(syncing succeeded)</span>
      default:
        return null
    }
  },
)

export type ResyncSearchRecordsDrawerProps = {
  companyGuid?: string
  open?: boolean
  onClose?: () => void
}

export const ResyncSearchRecordsDrawer = memo(
  ({ companyGuid, open, onClose }: ResyncSearchRecordsDrawerProps) => {
    const message = useMessage()
    const [selectedToSync, setSelectedToSync] = useState<
      Record<SearchableRecord['recordType'], boolean>
    >({
      ACCOUNT: false,
      JOB: false,
      LOCATION: false,
      INVOICE: false,
      ESTIMATE: false,
      NOTE: false,
      EQUIPMENT: false,
    })

    const syncAccounts = trpc.searcher['searcher:resync-accounts'].useMutation()
    const syncJobs = trpc.searcher['searcher:resync-jobs'].useMutation()
    const syncInvoices = trpc.searcher['searcher:resync-invoices'].useMutation()
    const syncNotes = trpc.searcher['searcher:resync-notes'].useMutation()
    const syncEstimates =
      trpc.searcher['searcher:resync-estimates'].useMutation()
    const syncLocations =
      trpc.searcher['searcher:resync-locations'].useMutation()

    const onToggleSelectAll = useCallback(() => {
      if (Object.values(selectedToSync).includes(true)) {
        setSelectedToSync({
          ACCOUNT: false,
          JOB: false,
          LOCATION: false,
          INVOICE: false,
          ESTIMATE: false,
          NOTE: false,
          EQUIPMENT: false,
        })
      } else {
        setSelectedToSync({
          ACCOUNT: true,
          JOB: true,
          LOCATION: true,
          INVOICE: true,
          ESTIMATE: true,
          NOTE: true,
          EQUIPMENT: true,
        })
      }
    }, [selectedToSync, setSelectedToSync])

    const onCheckboxToggled = (recordType: SearchableRecord['recordType']) => {
      setSelectedToSync({
        ...selectedToSync,
        [recordType]: !selectedToSync[recordType],
      })
    }

    const onResyncRecords = (companyGuid: string) => {
      for (const [recordType, isSelected] of Object.entries(selectedToSync)) {
        if (!isSelected) {
          continue
        }

        switch (recordType as SearchableRecord['recordType']) {
          case 'ACCOUNT':
            syncAccounts.mutate({ companyGuid })
            break
          case 'JOB':
            syncJobs.mutate({ companyGuid })
            break
          case 'INVOICE':
            syncInvoices.mutate({ companyGuid })
            break
          case 'NOTE':
            syncNotes.mutate({ companyGuid })
            break
          case 'ESTIMATE':
            syncEstimates.mutate({ companyGuid })
            break
          case 'LOCATION':
            syncLocations.mutate({ companyGuid })
            break
          default:
            break
        }
      }
    }

    return (
      <BzDrawer
        title="Resync Search Records"
        icon={faRotate}
        item={open ? { onCancel: () => onClose?.() } : undefined}
        footer={
          <FormCancelSubmitButtons
            confirmWarning={
              "This is an expensive operation! This will update EVERY record for each selected record type, so make sure to only select what's absolutely necessary. Are you sure you want to proceed?"
            }
            onSubmit={() => {
              if (!companyGuid) {
                message.error('No company selected!')
                return
              }

              if (!Object.values(selectedToSync).includes(true)) {
                message.error('No record types selected!')
                return
              }

              onResyncRecords(companyGuid)
            }}
            onCancel={onClose}
          />
        }
      >
        <div className="flex h-full flex-col space-y-2">
          <span>Select which record types to sync:</span>
          <div className="flex flex-col">
            <Checkbox
              onChange={() => onCheckboxToggled('ACCOUNT')}
              checked={selectedToSync.ACCOUNT}
            >
              Accounts <SyncRecordsProgressText status={syncAccounts.status} />
            </Checkbox>
            <Checkbox
              onChange={() => onCheckboxToggled('JOB')}
              checked={selectedToSync.JOB}
            >
              Jobs <SyncRecordsProgressText status={syncJobs.status} />
            </Checkbox>
            <Checkbox
              onChange={() => onCheckboxToggled('INVOICE')}
              checked={selectedToSync.INVOICE}
            >
              Invoices <SyncRecordsProgressText status={syncInvoices.status} />
            </Checkbox>
            <Checkbox
              onChange={() => onCheckboxToggled('ESTIMATE')}
              checked={selectedToSync.ESTIMATE}
            >
              Estimates{' '}
              <SyncRecordsProgressText status={syncEstimates.status} />
            </Checkbox>
            <Checkbox
              onChange={() => onCheckboxToggled('NOTE')}
              checked={selectedToSync.NOTE}
            >
              Notes <SyncRecordsProgressText status={syncNotes.status} />
            </Checkbox>
            <Checkbox
              onChange={() => onCheckboxToggled('LOCATION')}
              checked={selectedToSync.LOCATION}
            >
              Locations{' '}
              <SyncRecordsProgressText status={syncLocations.status} />
            </Checkbox>
          </div>

          <Button onClick={onToggleSelectAll}>
            {Object.values(selectedToSync).includes(true)
              ? 'Unselect All'
              : 'Select All'}
          </Button>
        </div>
      </BzDrawer>
    )
  },
)
