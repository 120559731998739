import { noOp } from '@breezy/shared'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import cn from 'classnames'
import { useState } from 'react'
import { dayBreakBlue1, dayBreakBlue6, gray8 } from '../../themes/theme'

export type FaIconButtonsProps = {
  onClick?: React.MouseEventHandler<HTMLDivElement>
  icon: IconProp
  tooltip?: string
  className?: string
  style?: React.CSSProperties
  disabled?: boolean
}

export const FaIconButtonAdam = ({
  style = {},
  className,
  tooltip,
  onClick,
  icon,
  disabled,
}: FaIconButtonsProps) => {
  const [hovered, setHovered] = useState(false)
  const hoverStyle = hovered ? { backgroundColor: dayBreakBlue1 } : {}
  const disabledBackgroundStyle = disabled ? { backgroundColor: '#F5F5F5' } : {}

  return (
    <Tooltip title={tooltip} mouseEnterDelay={tooltip ? 0.1 : 999999}>
      <div
        onClick={disabled ? noOp : onClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{
          ...hoverStyle,
          ...style,
          ...disabledBackgroundStyle,
          minWidth: 32,
          width: 32,
          height: 32,
          borderRadius: 6,
          cursor: disabled ? 'not-allowed' : 'pointer',
          border: '1px solid #D9D9D9',
          boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.02)',
        }}
        className={cn(className, 'center-children-vh')}
      >
        <FontAwesomeIcon
          icon={icon}
          style={{
            color: disabled
              ? '#BFBFBF'
              : style.color ?? (hovered ? dayBreakBlue6 : gray8),
            fontSize: 16,
          }}
        />
      </div>
    </Tooltip>
  )
}

export default FaIconButtonAdam
