import { Guid, hasTechnicianRole, MatchToShape, R, User } from '@breezy/shared'
import { Form, Select } from 'antd'
import React, { useMemo } from 'react'
import { toRoleShortName } from '../../components/Scheduler/scheduleUtils'

export type TechOptionsTech = MatchToShape<
  User,
  {
    userGuid: true
    firstName: true
    lastName: true
    deactivatedAt: true
    roles: {
      role: true
    }
  }
>

const makeTechOptions = (
  technicians: TechOptionsTech[],
  selectedTechGuids: readonly Guid[],
  techsOnly?: boolean,
) => {
  const techs: { value: string; label: string }[] = []

  const selectedGuidMap = selectedTechGuids.reduce(
    (acc, guid) => ({ ...acc, [guid]: true }),
    {} as Record<string, boolean>,
  )

  for (const user of technicians) {
    if (techsOnly) {
      if (!hasTechnicianRole(R.pluck('role', user.roles))) {
        continue
      }
    }
    const tech = {
      label: `${user.firstName} ${user.lastName} - ${user.roles
        .map(role => toRoleShortName(role.role))
        .join(', ')}`,
      value: user.userGuid,
    }
    if (user.deactivatedAt) {
      if (selectedGuidMap[user.userGuid]) {
        tech.label = `(DEACTIVATED) ${tech.label}`
      } else {
        continue
      }
    }
    techs.push(tech)
  }
  return techs
}

type TechMultiSelectFormProps = {
  techsOnly?: boolean
  technicians: TechOptionsTech[]
  selectedTechGuids: readonly Guid[]
  onChange: (selectedTechGuids: Guid[]) => void
  className?: string
}

export const TechMultiSelectForm = React.memo<TechMultiSelectFormProps>(
  ({ technicians, selectedTechGuids, onChange, techsOnly, className }) => {
    const technicianOptions = useMemo(
      () => makeTechOptions(technicians, selectedTechGuids, techsOnly),
      [selectedTechGuids, technicians, techsOnly],
    )

    return (
      <Form.Item label="Team Members" className={className}>
        <Select
          mode="multiple"
          showSearch
          className="w-full"
          placeholder="Select technicians"
          optionFilterProp="label"
          value={selectedTechGuids as Guid[]}
          onChange={onChange}
          options={technicianOptions}
        />
      </Form.Item>
    )
  },
)
