import { useAuth } from '../../../hooks/useAuth'

type Props = {
  children: JSX.Element
}

export default function Authenticated(props: Props) {
  const { isAuthenticated, loading } = useAuth()
  return isAuthenticated && !loading ? props.children : null
}
