import { AccountReminderEmail, TimeZoneId, getReminderDisplayDate, getReminderOverdueStatus } from '@breezy/shared'
import { Column, Link, Row, Section } from '@react-email/components'
import cn from 'classnames'
import React from 'react'
import ReminderStatusIcon from '../ReminderStatusIcon/ReminderStatusIcon'
import Tailwind from '../Tailwind/Tailwind'

type ReminderProps = {
  accountReminderEmail: AccountReminderEmail
  accountDetailsUrl: string
  companyTimeZoneId: TimeZoneId
}

const AccountReminder = React.memo<ReminderProps>(({ accountReminderEmail, accountDetailsUrl, companyTimeZoneId }) => {
  const displayDate = getReminderDisplayDate(accountReminderEmail.dueAt, companyTimeZoneId)
  const isOverdue = getReminderOverdueStatus(accountReminderEmail.dueAt, companyTimeZoneId)
  return (
    <Tailwind>
      <Link href={accountDetailsUrl} target="_blank">
        <Section>
          <Column className="w-[1px] whitespace-nowrap">
            <div className="mr-3 w-fit">
              <ReminderStatusIcon status={accountReminderEmail.status} />
            </div>
          </Column>
          <Column className="border-b border-l-0 border-r-0 border-t-0 border-solid border-bz-gray-400 pt-[10px] pb-[14px]">
            <Row className="mb-2">
              <div className="text-md text-bz-gray-900">{accountReminderEmail.description}</div>
            </Row>
            <Row>
              <Column align="left" className="w-[1px] whitespace-nowrap">
                <div className="rounded-md bg-bz-gray-300 p-[2px] px-2 text-xs leading-5 mr-1">
                  <div
                    className={cn('text-bz-green-800', {
                      'text-bz-red-800': isOverdue,
                    })}
                  >
                    {displayDate}
                  </div>
                </div>
              </Column>
              <Column align="left" className="w-[1px] whitespace-nowrap">
                <div className="rounded-md bg-bz-gray-300 p-[2px] px-2 text-xs leading-5 text-bz-gray-900">
                  {accountReminderEmail.reminderAssignment.user.firstNameInitial}
                </div>
              </Column>
              <Column>
                <div className="ml-1 text-xs text-bz-gray-700">{`Created by ${accountReminderEmail.createdByUser.firstNameInitial}`}</div>
              </Column>
            </Row>
          </Column>
        </Section>
      </Link>
    </Tailwind>
  )
})

export default AccountReminder
