import { isNullish } from '@breezy/shared'
import { Alert, Button, Divider, Select } from 'antd'
import { useCallback, useState } from 'react'
import BzDrawer from '../../../elements/BzDrawer/BzDrawer'
import { useMessage } from '../../../utils/antd-utils'

export type JobLifecycleDeleteLifecycleStatusDrawerProps = {
  open: boolean
  lifecycleStatus: {
    lifecycleStatusGuid: string
    name: string
    numJobs: number
    isDefaultLifecycleStatus: boolean
  } | null
  transitionStatuses: { lifecycleStatusGuid: string; name: string }[]
  isLoading: boolean
  onLifecycleStatusDeleteClicked: (data: {
    toDeleteLifecycleStatusGuid: string
    transitionLifecycleStatusGuid: string
    newDefaultLifecycleStatus?: string
  }) => void
  onCancel: () => void
}

export const JobLifecycleDeleteLifecycleStatusDrawer = ({
  open,
  lifecycleStatus,
  transitionStatuses,
  isLoading,
  onLifecycleStatusDeleteClicked,
  onCancel,
}: JobLifecycleDeleteLifecycleStatusDrawerProps) => {
  const message = useMessage()

  const [
    selectedTransitionLifecycleStatus,
    setSelectedTransitionLifecycleStatus,
  ] = useState('')

  const [
    selectedNewDefaultLifecycleStatus,
    setSelectedNewDefaultLifecycleStatus,
  ] = useState('')

  const onDeleteClicked = useCallback(() => {
    if (isNullish(lifecycleStatus)) {
      message.error('No lifecycle status selected to delete!')
      return
    }

    if (selectedTransitionLifecycleStatus === '') {
      message.error('No lifecycle status selected to transition jobs to!')
      return
    }

    if (
      lifecycleStatus.isDefaultLifecycleStatus &&
      selectedNewDefaultLifecycleStatus === ''
    ) {
      message.error('No new default lifecycle status selected!')
      return
    }

    onLifecycleStatusDeleteClicked({
      toDeleteLifecycleStatusGuid: lifecycleStatus.lifecycleStatusGuid,
      transitionLifecycleStatusGuid: selectedTransitionLifecycleStatus,
      newDefaultLifecycleStatus:
        selectedNewDefaultLifecycleStatus === ''
          ? undefined
          : selectedNewDefaultLifecycleStatus,
    })
  }, [
    lifecycleStatus,
    message,
    onLifecycleStatusDeleteClicked,
    selectedNewDefaultLifecycleStatus,
    selectedTransitionLifecycleStatus,
  ])

  return (
    <BzDrawer
      title="Delete Status"
      item={open ? { onCancel } : undefined}
      preferredWidth={720}
      footer={
        <div className="flex flex-row gap-3">
          <Button className="ml-auto" onClick={onCancel} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            type="primary"
            danger
            disabled={isNullish(lifecycleStatus) || isLoading}
            onClick={onDeleteClicked}
          >
            Delete Status
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-6">
        {isNullish(lifecycleStatus) ? (
          <>
            <p>No lifecycle status selected to delete!</p>
          </>
        ) : (
          <>
            <Alert
              type="warning"
              message={
                <p className="m-0 text-sm text-bz-gray-900">
                  <span className="font-semibold">IMPORTANT:</span> This status
                  is currently being used for{' '}
                  <span className="font-semibold">
                    {lifecycleStatus.numJobs} jobs
                  </span>
                  . Either change the status of these jobs manually or choose a
                  lifecycle status to transition all of these jobs below.
                </p>
              }
            />

            <div className="flex w-max flex-row items-center gap-6">
              <div className="flex flex-col gap-2">
                <span className="text-base font-semibold text-bz-gray-900">
                  {lifecycleStatus.name}
                </span>
                <span className="text-base text-bz-gray-800">
                  Status to be deleted
                </span>
              </div>

              <Divider type="vertical" className="h-9" />

              <div className="flex flex-col gap-2">
                <span className="text-base font-semibold text-bz-gray-900">
                  {lifecycleStatus.numJobs} jobs
                </span>
                <span className="text-base text-bz-gray-800">
                  Current jobs in lifecycle status
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <span className="text-sm font-semibold text-bz-gray-900">
                Choose transition status
              </span>

              <Select
                defaultActiveFirstOption={true}
                value={selectedTransitionLifecycleStatus}
                options={transitionStatuses
                  .filter(
                    status =>
                      status.lifecycleStatusGuid !==
                      lifecycleStatus.lifecycleStatusGuid,
                  )
                  .map(status => ({
                    label: status.name,
                    value: status.lifecycleStatusGuid,
                  }))}
                onChange={value => setSelectedTransitionLifecycleStatus(value)}
              />
            </div>

            {lifecycleStatus.isDefaultLifecycleStatus && (
              <div className="flex flex-col gap-3">
                <span className="text-sm font-semibold text-bz-gray-900">
                  Choose new default status
                </span>

                <Select
                  defaultActiveFirstOption={true}
                  value={selectedNewDefaultLifecycleStatus}
                  options={transitionStatuses
                    .filter(
                      status =>
                        status.lifecycleStatusGuid !==
                        lifecycleStatus.lifecycleStatusGuid,
                    )
                    .map(status => ({
                      label: status.name,
                      value: status.lifecycleStatusGuid,
                    }))}
                  onChange={value =>
                    setSelectedNewDefaultLifecycleStatus(value)
                  }
                />
              </div>
            )}
          </>
        )}
      </div>
    </BzDrawer>
  )
}
