import { BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG, bzExpect } from '@breezy/shared'
import api from '@flatfile/api'
import { convertToFlatfilePricebookCategoryRecords } from './FlatfileConversions'
import {
  FlatfilePricebookCategoriesWriter,
  FlatfileSheetReader,
} from './FlatfileTypes'
export const createFlatfilePricebookCategoriesWriter =
  (readSheet: FlatfileSheetReader): FlatfilePricebookCategoriesWriter =>
  async input => {
    try {
      const sheet = await readSheet({
        workbookId: input.workbookId,
        slug: BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG,
      })

      if (!input.categories) return
      await api.records.insert(
        bzExpect(sheet, 'sheet', 'Sheet not found').id,
        convertToFlatfilePricebookCategoryRecords(input.categories),
      )
    } catch (err) {
      console.error('Error seeding pricebook category cells', err)
      throw err
    }
  }
