import { Point } from '@breezy/shared'
import { useMapsLibrary } from '@vis.gl/react-google-maps'
import { useCallback } from 'react'

export const useCenterMapOnPointWithRadius = (
  map: google.maps.Map | null,
  point: Point | undefined,
  radiusMeters: number,
) => {
  const geometryLib = useMapsLibrary('geometry')

  return useCallback(() => {
    const coords = point?.coordinates

    if (!map || !geometryLib || !coords) return
    const bounds = new google.maps.LatLngBounds()
    const latLng = new google.maps.LatLng(coords[1], coords[0])
    bounds.extend(latLng)

    const left = geometryLib.spherical.computeOffset(latLng, radiusMeters, 0)
    const right = geometryLib.spherical.computeOffset(latLng, radiusMeters, 180)

    bounds.extend(left)
    bounds.extend(right)

    map.fitBounds(bounds)
  }, [point, map, geometryLib, radiusMeters])
}
