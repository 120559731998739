import { BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG } from '@breezy/shared'
import {
  FlatfileSheetRecordsReader,
  PricebookCategoryGuidReader,
} from './FlatfileTypes'

export const createPricebookCategoryGuidReader =
  (readSheetRecords: FlatfileSheetRecordsReader): PricebookCategoryGuidReader =>
  async ({ workbookId, name }) => {
    try {
      const parentCategoryNameRecordRes = await readSheetRecords({
        workbookId,
        slug: BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG,
        request: {
          searchField: 'name',
          searchValue: name,
        },
      })

      const parentCategoryRecord = parentCategoryNameRecordRes.data.records[0]
      return parentCategoryRecord.values?.pricebookCategoryGuid?.value as
        | string
        | undefined
    } catch (error) {
      console.error('Error reading pricebook category guid', error)
      throw error
    }
  }
