import { JobClass, MaintenanceOpportunity } from '@breezy/shared'
import {
  faAdd,
  faLocationCrosshairs,
  faScrewdriverWrench,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider } from 'antd'
import React, { useCallback, useMemo } from 'react'
import useAppNavigation from '../../../hooks/useAppNav'
import tailwindConfig from '../../../tailwind.config'
import MaintenanceOpportunityCard from './MaintenanceOpportunityCard'

import BzAdvancedMarker from '../../../components/MapView/BzAdvancedMarker/BzAdvancedMarker'
import { ActivePopup } from './ScheduleMapContext'

type MaintenanceOpportunityPinProps = {
  maintenanceOpportunity: MaintenanceOpportunity
  filteredMaintOpportunitiesGuids: string[]
  activeAppointmentGuid: string | undefined
  activePopup: ActivePopup | undefined
  setActivePopup: (popup: ActivePopup | undefined) => void
}

const MaintenanceOpportunityPin = React.memo<MaintenanceOpportunityPinProps>(
  ({
    maintenanceOpportunity,
    filteredMaintOpportunitiesGuids,
    activeAppointmentGuid,
    activePopup,
    setActivePopup,
  }) => {
    const isFilteredOut = useMemo(
      () =>
        !filteredMaintOpportunitiesGuids.includes(
          maintenanceOpportunity.locationGuid,
        ),
      [filteredMaintOpportunitiesGuids, maintenanceOpportunity.locationGuid],
    )

    const isActivePopup = useMemo(() => {
      return (
        activePopup?.type === 'maintenanceOpportunity' &&
        activePopup.locationGuid === maintenanceOpportunity.locationGuid
      )
    }, [activePopup, maintenanceOpportunity.locationGuid])

    const markerState = useMemo(() => {
      if (activePopup?.type === 'maintenanceOpportunity') {
        if (isActivePopup) {
          return 'active'
        }
        return 'background'
      }

      if (
        activePopup?.type === 'appointment' &&
        activePopup.appointmentGuid !== activeAppointmentGuid
      ) {
        return 'background'
      }

      return 'default'
    }, [activeAppointmentGuid, activePopup, isActivePopup])

    const onClick = useCallback(() => {
      if (isActivePopup) {
        setActivePopup(undefined)
      } else {
        setActivePopup({
          type: 'maintenanceOpportunity',
          locationGuid: maintenanceOpportunity.locationGuid,
        })
      }
    }, [isActivePopup, setActivePopup, maintenanceOpportunity.locationGuid])

    const isMaintPlanMember = useMemo(
      () => !!maintenanceOpportunity.maintenancePlan,
      [maintenanceOpportunity],
    )

    if (isFilteredOut) return null

    return (
      <BzAdvancedMarker
        markerState={markerState}
        onClick={onClick}
        position={{
          lng: maintenanceOpportunity.address?.geoLocation?.coordinates[0] || 0,
          lat: maintenanceOpportunity.address?.geoLocation?.coordinates[1] || 0,
        }}
        title={'AdvancedMarker with customized pin.'}
        pinIcon={isMaintPlanMember ? faScrewdriverWrench : faLocationCrosshairs}
        pinColorConfig={{
          active: tailwindConfig.theme.extend.colors['bz-green'][700],
          default: tailwindConfig.theme.extend.colors['bz-gray'][400],
        }}
        pinIconColorConfig={{
          active: 'white',
          default: 'text-bz-green-700',
        }}
        renderPopupIf={
          activePopup?.type === 'maintenanceOpportunity' &&
          activePopup.locationGuid === maintenanceOpportunity.locationGuid
        }
        ddPinActionName={`BZ Schedule Map - Maintenance Opportunity Pin`}
        popupContent={
          <MaintenanceOpportunityCard
            maintenanceOpportunity={maintenanceOpportunity}
            className="w-[304px] max-w-[304px] pb-0"
          >
            <Divider className="my-2" />
            <CreateMaintenanceJobButton
              accountGuid={maintenanceOpportunity.accountGuid}
              locationGuid={maintenanceOpportunity.locationGuid}
            />
          </MaintenanceOpportunityCard>
        }
        zIndex={
          markerState === 'active' ? 11 : markerState === 'default' ? 1 : -1
        }
      />
    )
  },
)

type CreateMaintenanceJobButtonProps = {
  accountGuid?: string
  locationGuid: string
}
const CreateMaintenanceJobButton = React.memo<CreateMaintenanceJobButtonProps>(
  ({ accountGuid, locationGuid }) => {
    const appNav = useAppNavigation()
    return (
      <Button
        type="primary"
        onClick={() => {
          appNav.navigateToCreateNewJob({
            accountGuid,
            locationGuid,
            jobClass: JobClass.MAINTENANCE,
          })
        }}
        icon={<FontAwesomeIcon icon={faAdd} />}
      >
        Create a Maintenance Job
      </Button>
    )
  },
)

export default MaintenanceOpportunityPin
