import { isNullish, kebabCase } from '@breezy/shared'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, DropdownProps } from 'antd'
import { memo, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useFeatureFlag } from '../../../hooks/useFeatureFlags'
import { JobLifecycle } from '../JobsPage.gql'

type JobMenuDropdownItemKey =
  | 'edit'
  | 'createLinkedJob'
  | 'updatePipelineStatus'
  | 'changeJobType'

export type JobMenuDropdownOnEditJobHandler = (jobGuid: string) => void

export type JobMenuDropdownOnCreateLinkedJobHandler = (data: {
  accountGuid: string
  locationGuid: string
  linkedJobGuid: string
}) => void

export type JobMenuDropdownOnUpdatePipelineStatusHandler = (
  jobGuid: string,
  transitionToLifecycleStatusGuid: string,
) => void

export type JobMenuDropdownOnChangeJobTypeHandler = (data: {
  jobGuid: string
}) => void

export type JobMenuDropdownProps = {
  job: {
    jobGuid: string
    accountGuid: string
    locationGuid: string
    jobLifecycleStatus: {
      jobLifecycleGuid: string
    }
    linkedJob?: {
      jobGuid: string
      displayId: number
      jobType: string
    }
    isCreatedLinkedJob: boolean
  }
  lifecycles: JobLifecycle[]
  onEditJob: JobMenuDropdownOnEditJobHandler
  onCreateLinkedJob: JobMenuDropdownOnCreateLinkedJobHandler
  onUpdatePipelineStatus: JobMenuDropdownOnUpdatePipelineStatusHandler
  onChangeJobType: JobMenuDropdownOnChangeJobTypeHandler
}

export const JobMenuDropdown = memo(
  ({
    job,
    lifecycles,
    onEditJob,
    onCreateLinkedJob,
    onUpdatePipelineStatus,
    onChangeJobType,
  }: JobMenuDropdownProps) => {
    const linkedJobsEnabled = useFeatureFlag('linkedJobs')
    const changeJobTypeEnabled = useFeatureFlag('changeJobType')

    const jobCardMenuItems: NonNullable<DropdownProps['menu']>['items'] =
      useMemo(() => {
        const lifecycle = lifecycles.find(
          lifecycle =>
            lifecycle.jobLifecycleGuid ===
            job.jobLifecycleStatus.jobLifecycleGuid,
        )

        const lifecycleStatusesMap = new Map<
          string,
          JobLifecycle['statuses'][number]
        >()

        const lifecycleStatusMenuOptions: NonNullable<
          DropdownProps['menu']
        >['items'] = []

        if (!isNullish(lifecycle)) {
          lifecycle.statuses.forEach(status => {
            lifecycleStatusesMap.set(status.jobLifecycleStatusGuid, status)
          })

          lifecycle.sequence.forEach(joblifecycleStatusGuid => {
            const status = lifecycleStatusesMap.get(joblifecycleStatusGuid)
            if (!status) {
              return
            }

            lifecycleStatusMenuOptions.push({
              key: status.jobLifecycleStatusGuid,
              label: (
                <span
                  className="inline-block w-full"
                  data-dd-action-name={`bz-jobs-menu-update-pipeline-status-${kebabCase(
                    status.name,
                  )}`}
                >
                  {status.name}
                </span>
              ),
            })
          })
        }

        const items: NonNullable<DropdownProps['menu']>['items'] = [
          {
            key: 'edit',
            label: (
              <span
                className="inline-block w-full"
                data-dd-action-name="bz-jobs-menu-edit"
              >
                Edit
              </span>
            ),
          },
          {
            key: 'updatePipelineStatus',
            label: 'Update Pipeline Status',
            children: [
              {
                key: 'jobLifecycleStatuses',
                type: 'group',
                label: lifecycle?.name ?? 'Unknown Lifecycle',
                children: lifecycleStatusMenuOptions,
              },
            ],
          },
          {
            type: 'divider',
          },
        ]

        if (changeJobTypeEnabled) {
          items.push({
            key: 'changeJobType',
            label: (
              <span
                className="inline-block w-full"
                data-dd-action-name="bz-jobs-menu-change-job-type"
              >
                Change Job Type
              </span>
            ),
          })
        }

        if (linkedJobsEnabled) {
          if (job.linkedJob) {
            items.push({
              key: 'navigateToLinkedJob',
              label: (
                <span
                  className="inline-block w-full"
                  data-dd-action-name="bz-jobs-menu-navigate-to-linked-job"
                >
                  Linked Job:{' '}
                  <Link to={`/jobs/${job.linkedJob.jobGuid}`}>
                    #{job.linkedJob.displayId} ({job.linkedJob.jobType})
                  </Link>
                </span>
              ),
            })
          } else if (!job.isCreatedLinkedJob) {
            items.push({
              key: 'createLinkedJob',
              label: (
                <span
                  className="inline-block w-full"
                  data-dd-action-name="bz-jobs-menu-create-linked-job"
                >
                  Create Linked Job
                </span>
              ),
            })
          } else {
            items.push({
              key: 'alreadyLinkedJob',
              disabled: true,
              label: (
                <span
                  className="inline-block w-full"
                  data-dd-action-name="bz-jobs-menu-create-linked-job"
                >
                  Cannot create linked job from a linked job
                </span>
              ),
            })
          }
        }

        return items
      }, [
        lifecycles,
        changeJobTypeEnabled,
        linkedJobsEnabled,
        job.jobLifecycleStatus.jobLifecycleGuid,
        job.linkedJob,
        job.isCreatedLinkedJob,
      ])

    const jobCardMenuOnClick: NonNullable<
      NonNullable<DropdownProps['menu']>['onClick']
    > = useCallback(
      event => {
        event.domEvent.stopPropagation()

        if (
          event.keyPath.includes(
            'updatePipelineStatus' as JobMenuDropdownItemKey,
          )
        ) {
          onUpdatePipelineStatus(job.jobGuid, event.key)
        } else {
          switch (event.key as JobMenuDropdownItemKey) {
            case 'edit':
              onEditJob(job.jobGuid)
              break
            case 'createLinkedJob':
              onCreateLinkedJob({
                accountGuid: job.accountGuid,
                locationGuid: job.locationGuid,
                linkedJobGuid: job.jobGuid,
              })
              break
            case 'changeJobType':
              onChangeJobType({ jobGuid: job.jobGuid })
              break
            default:
              break
          }
        }
      },
      [
        onUpdatePipelineStatus,
        job.jobGuid,
        job.accountGuid,
        job.locationGuid,
        onEditJob,
        onCreateLinkedJob,
        onChangeJobType,
      ],
    )

    return (
      <Dropdown
        overlayClassName="w-56"
        placement="bottomRight"
        trigger={['click']}
        menu={{
          items: jobCardMenuItems,
          onClick: jobCardMenuOnClick,
        }}
      >
        <Button
          className="ml-auto"
          icon={<FontAwesomeIcon icon={faEllipsis} />}
          size="small"
          shape="circle"
          onClick={e => e.stopPropagation()}
        />
      </Dropdown>
    )
  },
)
