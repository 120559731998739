import { BzAddress, formatJobClass } from '@breezy/shared'
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import classNames from 'classnames'
import React, { memo } from 'react'
import { useDrag } from 'react-dnd'
import {
  KanbanJob,
  getNextAppointmentAndTechs,
} from '../../../../components/Kanban/kanbanUtils'
import { useExpectedCompanyTimeZoneId } from '../../../../providers/PrincipalUser'
import { JobMenuDropdown } from '../JobMenuDropdown'
import { TableJob, useJobTableContext } from './JobsTableContext'

interface JobsListStatusSectionTableRowProps
  extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string
}

// https://ant.design/components/table?theme=happy-work#components-table-demo-drag-sorting-handler
const JobsListStatusSectionTableRow =
  React.memo<JobsListStatusSectionTableRowProps>(({ children, ...props }) => {
    const {
      jobMap,
      disabled,
      isLoading,
      lifecycles,
      onEditJob,
      onCreateLinkedJob,
      onUpdateJobPipelineStatus,
      onChangeJobType,
    } = useJobTableContext()
    const [{ isDragging }, drag, preview] = useDrag(
      () => ({
        type: 'JOBS_LIST_CARD',
        item: jobMap[props['data-row-key']],
        canDrag: !disabled && !isLoading,
        collect: monitor => ({
          isDragging: !!monitor.isDragging(),
        }),
      }),
      [disabled, isLoading, jobMap, props],
    )

    return (
      <tr
        {...props}
        ref={preview}
        className={isDragging ? 'cursor-grabbing opacity-20' : ''}
      >
        {React.Children.map(children, child => {
          // The child being passed here contains a "record" field with the data from provided to Antd's Table component.
          // We want to explicitly type this as their are no exported types from Antd to infer from
          if (
            (child as React.ReactElement<{ record: TableJob }>).key === 'sort'
          ) {
            const { props } = child as React.ReactElement<{ record: TableJob }>
            const { record: job } = props

            return React.cloneElement(child as React.ReactElement, {
              children: (
                <div
                  key="sort"
                  ref={drag}
                  className={classNames(
                    'flex flex-row items-center gap-3 text-center text-bz-gray-900',
                    isLoading
                      ? 'cursor-progress'
                      : !disabled
                      ? 'cursor-grab'
                      : 'cursor-normal',
                  )}
                >
                  <FontAwesomeIcon size="lg" icon={faGripDotsVertical} />

                  <JobMenuDropdown
                    job={{
                      jobGuid: job.jobGuid,
                      accountGuid: job.accountGuid,
                      locationGuid: job.locationGuid,
                      jobLifecycleStatus: {
                        jobLifecycleGuid: job.jobLifecycleGuid,
                      },
                      isCreatedLinkedJob: job.isCreatedLinkedJob,
                    }}
                    lifecycles={lifecycles}
                    onEditJob={onEditJob}
                    onCreateLinkedJob={onCreateLinkedJob}
                    onUpdatePipelineStatus={onUpdateJobPipelineStatus}
                    onChangeJobType={onChangeJobType}
                  />
                </div>
              ),
            })
          }
          return child
        })}
      </tr>
    )
  })

export type JobsListStatusSectionTableProps = {
  jobs: KanbanJob[]
  columns: ColumnsType<TableJob>
}

export const JobsListStatusSectionTable = memo(
  ({ jobs, columns }: JobsListStatusSectionTableProps) => {
    const tzId = useExpectedCompanyTimeZoneId()

    return (
      <Table<TableJob>
        size="small"
        pagination={false}
        className="m-[-16px] mt-2 max-w-[calc(100%+32px)] rounded-none"
        components={{
          body: {
            row: JobsListStatusSectionTableRow,
          },
        }}
        rowKey="key"
        columns={columns}
        dataSource={jobs.map(({ id, job }) => {
          const primaryContact = job.account.accountContacts.find(
            contact => contact.primary,
          )?.contact

          const [nextAppointment, technicians] = getNextAppointmentAndTechs(
            job.appointments,
            tzId,
          )
          return {
            key: id,
            displayId: job.displayId,
            jobGuid: job.jobGuid,
            jobType: job.jobType.name,
            accountDisplayName: job.account.accountDisplayName,
            location: BzAddress.formatAddressSingleLine(job.location.address),
            jobClassFormatted: formatJobClass(job.jobType.jobClass),
            contact: primaryContact,
            contactFormatted: primaryContact
              ? `${primaryContact.firstName} ${primaryContact.lastName} - ${
                  primaryContact.primaryPhoneNumber?.phoneNumber ??
                  'No phone number'
                }`
              : 'No primary contact',
            nextAppointment,
            technicians,
            tags: job.tags ? job.tags.map(({ tag }) => tag) : undefined,
            locationGuid: job.location.locationGuid,
            accountGuid: job.account.accountGuid,
            jobLifecycleGuid: job.jobLifecycleStatus.jobLifecycleGuid,
            isCreatedLinkedJob: job.isCreatedLinkedJob,
          }
        })}
      />
    )
  },
)
