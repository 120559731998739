import { CompanyFinancialConfig, bzOptional } from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Collapse, Input, Switch } from 'antd'
import { forwardRef, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import BzDrawer from '../../elements/BzDrawer/BzDrawer'
import { trpc } from '../../hooks/trpc'
import { useMessage } from '../../utils/antd-utils'
import TrpcQueryLoader from '../TrpcQueryLoader'

const financialConfigFormSchema = z.object({
  wisetack: z.object({
    enabled: z.boolean(),
    merchantId: bzOptional(z.string()),
  }),
  tilled: z.object({
    merchantId: bzOptional(z.string()),
  }),
})

type FinancialConfigFormSchema = z.infer<typeof financialConfigFormSchema>

type UpdateFinancialConfigDrawerContentProps = {
  companyGuid: string
  financialConfig: CompanyFinancialConfig
  onFinish: () => void
}

const UpdateFinancialConfigDrawerContent = forwardRef<
  HTMLInputElement,
  UpdateFinancialConfigDrawerContentProps
>(
  (
    {
      companyGuid,
      financialConfig,
      onFinish,
    }: UpdateFinancialConfigDrawerContentProps,
    ref,
  ) => {
    const message = useMessage()
    const { control, handleSubmit } = useForm<FinancialConfigFormSchema>({
      resolver: zodResolver(financialConfigFormSchema),
      defaultValues: {
        wisetack: {
          enabled: financialConfig.wisetackEnabled ?? false,
          merchantId: financialConfig.wisetackMerchantId,
        },
        tilled: {
          merchantId: financialConfig.merchantId,
        },
      },
    })

    const financialConfigMutation =
      trpc.companies['companies:financial-config:update'].useMutation()

    const onSubmit = handleSubmit(async data => {
      await financialConfigMutation.mutateAsync({
        companyGuid,
        wisetackEnabled: data.wisetack.enabled,
        wisetackMerchantId: data.wisetack.merchantId?.trim() ?? undefined,
        merchantId: data.tilled.merchantId?.trim() ?? undefined,
      })

      message.success(
        `Successfully updated financial config for company ${companyGuid}`,
      )
      onFinish()
    })

    return (
      <form className="flex flex-col gap-3" onSubmit={onSubmit}>
        <Collapse
          items={[
            {
              key: 'wisetack',
              label: 'Wisetack',
              children: (
                <div className="flex flex-col gap-3">
                  <Controller
                    control={control}
                    name="wisetack.enabled"
                    render={({ field }) => (
                      <label className="flex flex-row gap-2">
                        <span>Wisetack Enabled?:</span>

                        <Switch {...field} />
                      </label>
                    )}
                  />

                  <Controller
                    control={control}
                    name="wisetack.merchantId"
                    render={({ field }) => (
                      <label className="flex flex-col gap-2">
                        <span>Wisetack Merchant ID</span>

                        <Input
                          {...field}
                          placeholder="Enter Wisetack Merchant ID here..."
                        />
                      </label>
                    )}
                  />
                </div>
              ),
            },
            {
              key: 'tilled',
              label: 'Tilled',
              children: (
                <div className="flex flex-col gap-3">
                  <Controller
                    control={control}
                    name="tilled.merchantId"
                    render={({ field }) => (
                      <label className="flex flex-col gap-2">
                        <span>Tilled Merchant ID</span>

                        <Input
                          {...field}
                          placeholder="Enter Tilled Merchant ID here..."
                        />
                      </label>
                    )}
                  />
                </div>
              ),
            },
          ]}
        />

        <input ref={ref} type="submit" className="hidden" />
      </form>
    )
  },
)

export type UpdateFinancialConfigDrawerProps = {
  open: boolean
  companyGuid: string
  onFinancialConfigUpdated: () => void
  onCancel: () => void
}

export const UpdateFinancialConfigDrawer = ({
  open,
  companyGuid,
  onFinancialConfigUpdated,
  onCancel,
}: UpdateFinancialConfigDrawerProps) => {
  const financialConfigQuery = trpc.companies[
    'companies:financial-config'
  ].useQuery({ companyGuid }, { enabled: companyGuid !== '', cacheTime: 0 })

  const btnFormSubmitRef = useRef<HTMLInputElement>(null)

  if (companyGuid === '') {
    return (
      <BzDrawer
        title="Update Financial Configuration"
        item={open ? { onCancel } : undefined}
        preferredWidth={720}
        footer={
          <div className="flex flex-row">
            <Button className="ml-auto" htmlType="button" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        }
      >
        <span>No Company Selected</span>
      </BzDrawer>
    )
  }

  return (
    <BzDrawer
      title="Update Financial Configuration"
      item={open ? { onCancel } : undefined}
      preferredWidth={720}
      footer={
        <div className="flex flex-row gap-2">
          <Button className="ml-auto" htmlType="button" onClick={onCancel}>
            Cancel
          </Button>

          <Button
            type="primary"
            htmlType="button"
            onClick={() => btnFormSubmitRef.current?.click()}
          >
            Save
          </Button>
        </div>
      }
    >
      <TrpcQueryLoader
        query={financialConfigQuery}
        render={financialConfig => (
          <UpdateFinancialConfigDrawerContent
            ref={btnFormSubmitRef}
            companyGuid={companyGuid}
            financialConfig={financialConfig}
            onFinish={onFinancialConfigUpdated}
          />
        )}
      />
    </BzDrawer>
  )
}
