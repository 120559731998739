import { PageHeader } from '@ant-design/pro-components'
import {
  BzDateFns,
  Dfns,
  JOBS_V2_CLASSES,
  JobClass,
  JobLifecycle,
  R,
  isNullish,
  jobClassDisplayNames,
} from '@breezy/shared'
import { faBriefcase } from '@fortawesome/pro-light-svg-icons'
import { faArchive, faEdit } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Input, Switch, Tabs, Tag, Tooltip } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { useExpectedCompany } from 'src/providers/PrincipalUser'
import { useMessage } from 'src/utils/antd-utils'
import { useMutation, useQuery } from 'urql'
import { Page } from '../../components/Page/Page'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'
import { FetchJobTypesSettingsJobTypeQuery } from '../../generated/user/graphql'
import { trpc } from '../../hooks/trpc'
import { Styled } from '../../utils/Stylable'
import { StateSetter, useQueryParamState } from '../../utils/react-utils'
import { EditJobTypeSidebar } from './EditJobTypeSidebar'
import {
  JOB_TYPE_ARCHIVE_MUTATION,
  JOB_TYPE_SETTINGS_PAGE_DATA,
  JOB_TYPE_UNARCHIVE_MUTATION,
} from './JobTypeSettingsListPage.gql'
import { JobTypeArchiveDrawer } from './components/JobTypeArchiveDrawer'
import { JobTypeUnarchiveDrawer } from './components/JobTypeUnarchiveDrawer'
import { JobTypesSettingsContext } from './jobTypesSettingsUtils'

type FullJobType = FetchJobTypesSettingsJobTypeQuery['jobTypes'][number]

type JobTypeItemSectionProps = React.PropsWithChildren<
  Styled<{
    label: string
  }>
>

const JobTypeItemSection = React.memo<JobTypeItemSectionProps>(
  ({ className, label, children }) => (
    <div className={classNames('flex-1', className)}>
      <div className="mb-2 text-base font-semibold">{children}</div>
      <div className="text-bz-gray-700">{label}</div>
    </div>
  ),
)

type JobTypeItemProps = FullJobType & {
  jobCount: number
  setSelectedJobType: (guid: string) => void
  setSelectedJobTypeForArchival: (jobType: {
    name: string
    jobTypeGuid: string
  }) => void
  setSelectedJobTypeForUnarchival: (jobType: {
    name: string
    jobTypeGuid: string
  }) => void
  lifecycleName: string
}

const JobTypeItem = React.memo<JobTypeItemProps>(
  ({
    jobTypeGuid,
    name,
    description,
    updatedAt,
    setSelectedJobType,
    setSelectedJobTypeForArchival,
    setSelectedJobTypeForUnarchival,
    lifecycleName,
    jobCount,
    archivedAt,
  }) => {
    const formattedUpdatedAt = useMemo(
      () => R.pipe(Dfns.parseISO, Dfns.format('MM/dd/yy'))(updatedAt),
      [updatedAt],
    )

    return (
      <div className="flex flex-row items-center space-x-6 rounded-lg border border-solid border-bz-gray-500 px-6 py-4">
        <div className="mr-4 rounded-full bg-orange-100 p-3">
          <FontAwesomeIcon
            icon={faBriefcase}
            className="text-base text-orange-900"
          />
        </div>
        <JobTypeItemSection label={description ?? ''} className="flex-[3]">
          <div className="flex flex-row gap-3">
            <span>{name}</span>
            {!isNullish(archivedAt) && (
              <Tag icon={<FontAwesomeIcon icon={faArchive} className="mr-1" />}>
                Archived
              </Tag>
            )}
          </div>
        </JobTypeItemSection>
        <JobTypeItemSection label="Active Jobs">{jobCount}</JobTypeItemSection>
        <JobTypeItemSection label="Job Pipeline">
          {lifecycleName}
        </JobTypeItemSection>
        <JobTypeItemSection label="Last updated">
          {formattedUpdatedAt}
        </JobTypeItemSection>
        <Button
          icon={<FontAwesomeIcon icon={faEdit} />}
          onClick={() => setSelectedJobType(jobTypeGuid)}
        >
          Edit
        </Button>
        <div className="flex flex-row gap-2">
          <Tooltip title={isNullish(archivedAt) ? 'Archive' : 'Unarchive'}>
            <Button
              icon={<FontAwesomeIcon icon={faArchive} />}
              onClick={() => {
                if (isNullish(archivedAt)) {
                  setSelectedJobTypeForArchival({ name, jobTypeGuid })
                } else {
                  setSelectedJobTypeForUnarchival({ name, jobTypeGuid })
                }
              }}
            ></Button>
          </Tooltip>
        </div>
      </div>
    )
  },
)

type JobTypesSettingsListProps = {
  jobTypes: FullJobType[]
  jobLifecycles: JobLifecycle[]
}
const JobTypesSettingsList = React.memo<JobTypesSettingsListProps>(
  ({ jobTypes, jobLifecycles }) => {
    const company = useExpectedCompany()
    const message = useMessage()

    const { refetch } = useContext(JobTypesSettingsContext)

    const jobLifecycleMap = useMemo(() => {
      const map: Record<string, JobLifecycle> = {}
      for (const lifecycle of jobLifecycles) {
        map[lifecycle.jobLifecycleGuid] = lifecycle
      }
      return map
    }, [jobLifecycles])

    const [editingJobTypeGuid, setEditingJobTypeGuid] = useQueryParamState(
      'id',
      '',
    )
    const resetEditingJobType = useCallback(() => {
      setEditingJobTypeGuid(undefined)
    }, [setEditingJobTypeGuid])

    const editingJobType = useMemo<Partial<FullJobType> | undefined>(() => {
      if (editingJobTypeGuid === 'new') {
        return {}
      }
      return jobTypes.find(
        jobType => jobType.jobTypeGuid === editingJobTypeGuid,
      )
    }, [editingJobTypeGuid, jobTypes])

    const [searchTerm, setSearchTerm] = useState('')

    const groupedTypes = useMemo(() => {
      const lcSearchTerm = searchTerm.toLowerCase()
      const map: Partial<Record<JobClass, FullJobType[]>> = {}
      for (const jobType of jobTypes) {
        if (!jobType.name.toLowerCase().includes(lcSearchTerm)) {
          continue
        }
        map[jobType.jobClass] = [...(map[jobType.jobClass] ?? []), jobType]
      }
      return map
    }, [jobTypes, searchTerm])

    const tabItems = useMemo(
      () =>
        JOBS_V2_CLASSES.map(jobClass => ({
          label: `${jobClassDisplayNames[jobClass]} (${
            groupedTypes[jobClass]?.length ?? 0
          })`,
          key: jobClass,
        })),
      [groupedTypes],
    )

    const [selectedJobClass, setSelectedJobClass] = useState(JobClass.INSTALL)
    const [selectedJobTypeForArchival, setSelectedJobTypeForArchival] =
      useState<{ name: string; jobTypeGuid: string } | null>(null)
    const [selectedJobTypeForUnarchival, setSelectedJobTypeForUnarchival] =
      useState<{ name: string; jobTypeGuid: string } | null>(null)

    const [jobTypeArchiveMutationRes, jobTypeArchiveMutation] = useMutation(
      JOB_TYPE_ARCHIVE_MUTATION,
    )

    const [jobTypeUnarchiveMutationRes, jobTypeUnarchiveMutation] = useMutation(
      JOB_TYPE_UNARCHIVE_MUTATION,
    )

    const [showArchivedJobTypes, setShowArchivedJobTypes] = useState(false)

    const jobTypeItems: FullJobType[] = useMemo(() => {
      if (showArchivedJobTypes) {
        return groupedTypes[selectedJobClass] ?? []
      }

      return (
        groupedTypes[selectedJobClass]?.filter(jobType =>
          isNullish(jobType.archivedAt),
        ) ?? []
      )
    }, [groupedTypes, selectedJobClass, showArchivedJobTypes])

    return (
      <>
        <div className="mb-4 flex flex-row items-center">
          <div className="flex flex-1 flex-col">
            {/* TODO: Learn more button */}
            <div className="text-sm text-bz-gray-700">
              Job types allow you to classify unique properties, lifecycles, and
              settings for types of jobs within the same class.
            </div>
          </div>
        </div>
        <div className="flex flex-row items-end gap-4">
          <Tabs
            className="flex-1"
            type="card"
            activeKey={selectedJobClass}
            onChange={setSelectedJobClass as StateSetter<string>}
            items={tabItems}
          />
          <div className="flex flex-row gap-2">
            <Input
              placeholder="Search job types..."
              prefix={<AiOutlineSearch />}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              allowClear
              className="mb-4 ml-4 w-64"
            />
            <Button type="primary" onClick={() => setEditingJobTypeGuid('new')}>
              + Add New Job Type
            </Button>
          </div>
        </div>
        <div className="flex min-h-0 flex-1 flex-col space-y-4 overflow-auto">
          <div className="flex flex-row gap-2">
            <span>Show Archived</span>
            <Switch defaultChecked={false} onChange={setShowArchivedJobTypes} />
          </div>
          {jobTypeItems
            .sort((a, b) => a.name.localeCompare(b.name))
            // Sort Archived Job Types last
            .sort((a, b) => {
              if (!isNullish(a.archivedAt)) {
                return 1
              } else if (!isNullish(b.archivedAt)) {
                return -1
              } else {
                return 0
              }
            })
            .map(jobType => (
              <JobTypeItem
                key={jobType.jobTypeGuid}
                setSelectedJobType={setEditingJobTypeGuid}
                setSelectedJobTypeForArchival={setSelectedJobTypeForArchival}
                setSelectedJobTypeForUnarchival={
                  setSelectedJobTypeForUnarchival
                }
                lifecycleName={
                  jobLifecycleMap[jobType.jobLifecycleGuid]?.name ?? 'Unknown'
                }
                jobCount={jobType.jobsAggregate?.aggregate?.count ?? 0}
                {...jobType}
              />
            ))}
        </div>
        {editingJobType && (
          <EditJobTypeSidebar
            onClose={resetEditingJobType}
            initialJobType={editingJobType}
            initialJobClass={selectedJobClass}
          />
        )}

        <JobTypeArchiveDrawer
          open={!isNullish(selectedJobTypeForArchival)}
          jobType={selectedJobTypeForArchival}
          isLoading={jobTypeArchiveMutationRes.fetching}
          onJobTypeArchiveClicked={async jobTypeGuid => {
            const now = BzDateFns.formatISO(
              BzDateFns.now(company.timezone),
              company.timezone,
            )
            try {
              await jobTypeArchiveMutation({
                companyGuid: company.companyGuid,
                jobTypeGuid,
                archivedAt: now,
              })
              message.success(
                `Job Type "${selectedJobTypeForArchival?.name}" has been successfully archived`,
              )
            } catch (err) {
              message.error(
                `Failed to archive Job Type "${selectedJobTypeForArchival?.name}"! Please try again later`,
              )
            }

            refetch()
            setSelectedJobTypeForArchival(null)
          }}
          onCancel={() => setSelectedJobTypeForArchival(null)}
        />

        <JobTypeUnarchiveDrawer
          open={!isNullish(selectedJobTypeForUnarchival)}
          jobType={selectedJobTypeForUnarchival}
          isLoading={jobTypeUnarchiveMutationRes.fetching}
          onJobTypeUnarchiveClicked={async jobTypeGuid => {
            const now = BzDateFns.formatISO(
              BzDateFns.now(company.timezone),
              company.timezone,
            )
            try {
              await jobTypeUnarchiveMutation({
                companyGuid: company.companyGuid,
                jobTypeGuid,
                unarchivedAt: now,
              })
              message.success(
                `Job Type "${selectedJobTypeForUnarchival?.name}" has been successfully unarchived`,
              )
            } catch (err) {
              message.error(
                `Failed to unarchive Job Type "${selectedJobTypeForUnarchival?.name}"! Please try again later`,
              )
            }

            refetch()
            setSelectedJobTypeForUnarchival(null)
          }}
          onCancel={() => setSelectedJobTypeForUnarchival(null)}
        />
      </>
    )
  },
)

export const JobTypesSettingsListPage = React.memo(() => {
  const lifecycleQuery = trpc.jobLifecycles['job-lifecycles:get'].useQuery()

  const [{ data: jobTypesData }, refetch] = useQuery({
    query: JOB_TYPE_SETTINGS_PAGE_DATA,
  })

  return (
    <Page requiresCompanyUser className="overflow-hidden p-0">
      <PageHeader title={<PageTitle title="Job Types" icon={faBriefcase} />} />
      <ScrollCard className="relative flex flex-col" hasPageHeading>
        <JobTypesSettingsContext.Provider
          value={{
            refetch,
          }}
        >
          <TrpcQueryLoader
            query={lifecycleQuery}
            render={(jobLifecycles: JobLifecycle[]) => (
              <JobTypesSettingsList
                jobTypes={jobTypesData?.jobTypes ?? []}
                jobLifecycles={jobLifecycles}
              />
            )}
          />
        </JobTypesSettingsContext.Provider>
      </ScrollCard>
    </Page>
  )
})
