import {
  BzDateFns,
  CalculatePaths,
  Guid,
  IsoDateString,
  PermissionV2,
  TimeZoneId,
  formatTimesheetActivityName,
} from '@breezy/shared'
import { faEdit, faTrash } from '@fortawesome/pro-light-svg-icons'
import { Table, message } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ColumnType } from 'antd/lib/table'
import { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from 'urql'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import {
  booleanYesOrNoColumn,
  dateColumn,
  dateTimeColumn,
  textColumn,
} from '../../components/datatables/CommonColumnDefinitions'
import { EmDash } from '../../elements/EmDash/EmDash'
import FaIconButton from '../../elements/FaIconButton/FaIconButton'
import { FaIconButtonWithConfirm } from '../../elements/FaIconButtonWithConfirm/FaIconButtonWithConfirm'
import { useIsAuthorizedTo } from '../../hooks/permission/useIsAuthorized'
import {
  useExpectedCompanyTimeZoneId,
  useExpectedPrincipal,
} from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'
import { DELETE_TIMESHEET_ENTRY_GQL } from './Timesheets.gql'

type TableItemType = {
  isUploading?: boolean
  timesheetEntryGuid: Guid
  timesheetEntryActivityGuid?: Guid

  startTime: IsoDateString
  endTime?: IsoDateString
  deletedAt?: IsoDateString
  entryLengthInMinutes: number

  activityName: string
  isPayable: boolean

  links?: {
    jobAppointmentAssignmentGuid?: string
    jobAppointmentGuid?: string
    jobGuid?: string
    jobDisplayId?: number
  }
}

export type TimesheetEntryItem = TableItemType

export const getTableColumns = (
  items: TableItemType[],
  tzId: TimeZoneId,
  onDelete?: (item: TableItemType) => void,
  onEdit?: (item: TableItemType) => void,
): ColumnsType<TableItemType> => {
  const cols: ColumnType<TableItemType>[] = []
  if (onEdit || onDelete) {
    cols.push({
      title: '',
      dataIndex: 'timesheetEntryGuid',
      key: 'timesheetEntryGuid',
      render: (_: Guid, item: TableItemType) => (
        <div className="flex flex-row">
          {!item.isUploading && (
            <>
              {onEdit && item.timesheetEntryActivityGuid && (
                <FaIconButton icon={faEdit} onClick={() => onEdit(item)} />
              )}
              {onDelete && (
                <FaIconButtonWithConfirm
                  icon={faTrash}
                  onClick={() => onDelete(item)}
                  confirmModalTitle="Delete Timesheet Entry?"
                  confirmModalPromptText="Are you sure you want to delete this timesheet entry?"
                  confirmModalOkButtonText="Delete"
                />
              )}
            </>
          )}
          {item.isUploading && (
            <LoadingSpinner noMinHeight spinnerClassName="h-4 w-4 my-auto" />
          )}
        </div>
      ),
    })
  }

  const yearsAreDifferent = BzDateFns.anyDifferentYears(
    items.map(x => x.startTime),
    tzId,
  )
  cols.push(
    dateColumn(
      tzId,
      'Day',
      'startTime',
      yearsAreDifferent ? 'eeee, MMM d, yyyy' : 'eeee, MMM d',
    ),
  )
  cols.push(dateTimeColumn(tzId, 'Start', 'startTime', 'h:mm a'))
  cols.push(dateTimeColumn(tzId, 'End', 'endTime', 'h:mm a'))
  cols.push({
    title: 'Duration',
    dataIndex: 'startTime',
    key: 'startTime',
    render: (startTime: IsoDateString, item: TableItemType) => {
      const start = new Date(startTime)
      const end = item.endTime ? new Date(item.endTime) : new Date()
      const minutesBetween = Math.floor(
        (end.getTime() - start.getTime()) / 1000 / 60,
      )
      const durationHours = Math.floor(minutesBetween / 60)
      const remainderMinutes = minutesBetween % 60
      return (
        <div>
          {durationHours.toFixed(0)}h {remainderMinutes.toFixed(0)}m
        </div>
      )
    },
  })
  cols.push(textColumn('Activity', 'activityName', formatTimesheetActivityName))
  cols.push(booleanYesOrNoColumn('Payable', 'isPayable'))
  cols.push({
    title: 'Item',
    dataIndex: 'links',
    key: 'links',
    render: (links: TableItemType['links']) => {
      if (links?.jobGuid && links?.jobDisplayId) {
        return (
          <Link to={CalculatePaths.jobDetails(links)}>
            Job #{links.jobDisplayId}
          </Link>
        )
      } else {
        return <EmDash />
      }
    },
  })

  return cols
}

export type TimesheetEntriesTableProps = {
  items: TimesheetEntryItem[]
  onEdit: (item: TimesheetEntryItem) => void
  refetch: () => void
}

export const TimesheetEntriesTable = m<TimesheetEntriesTableProps>(
  ({ items, refetch, onEdit }) => {
    const actingUserGuid = useExpectedPrincipal().userGuid
    const tzId = useExpectedCompanyTimeZoneId()
    const canManage = useIsAuthorizedTo(PermissionV2.OFFICE_TIMESHEETS_MANAGE)
    const [deletingItems, setDeletingItems] = useState<Guid[]>([])

    const asyncItems = useMemo(
      () =>
        items.map(item => {
          const deletingItemsSet = new Set(deletingItems)
          return {
            ...item,
            isUploading: deletingItemsSet.has(item.timesheetEntryGuid),
          }
        }),
      [items, deletingItems],
    )

    const [, deleteEntryMut] = useMutation(DELETE_TIMESHEET_ENTRY_GQL)
    const deleteEntry = useCallback(
      async (timesheetEntryGuid: Guid) =>
        deleteEntryMut({
          timesheetEntryGuid,
          deletedAt: BzDateFns.nowISOString(),
          deletedByUserGuid: actingUserGuid,
        }),
      [actingUserGuid, deleteEntryMut],
    )

    const onDelete = useCallback(
      async (item: TableItemType) => {
        setDeletingItems(prev => [...prev, item.timesheetEntryGuid])
        try {
          await deleteEntry(item.timesheetEntryGuid)
          refetch()
        } catch (e) {
          console.error(e)
          message.error('Failed to delete timesheet entry')
        } finally {
          setDeletingItems(prev =>
            prev.filter(guid => guid !== item.timesheetEntryGuid),
          )
        }
      },
      [setDeletingItems, deleteEntry, refetch],
    )

    return (
      <Table
        rowKey="payoutGuid"
        dataSource={asyncItems}
        scroll={{ x: true }}
        columns={getTableColumns(
          asyncItems,
          tzId,
          canManage ? onDelete : undefined,
          canManage ? onEdit : undefined,
        )}
        size="small"
        pagination={false}
      />
    )
  },
)
