import { DiscountType } from '@breezy/shared'
import { Form, Radio } from 'antd'

export const PricebookFormDiscountType = () => (
  <Form.Item label="Discount Type" name="type" required>
    <Radio.Group optionType="button" buttonStyle="solid">
      <Radio.Button value={DiscountType.RATE}>% Percentage Off</Radio.Button>
      <Radio.Button value={DiscountType.FLAT}>$ Fixed Amount</Radio.Button>
    </Radio.Group>
  </Form.Item>
)
