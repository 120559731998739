import { JobClass, bzExpect, isNullish } from '@breezy/shared'
import { Divider, Form, Input } from 'antd'
import { useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ChangeJobTypeFormSchema } from '../change-job-type-form-schema'
import { ChangeJobPipelineCardSelect } from './form-elements/ChangeJobPipelineCardSelect'
import { JobTypeSelect } from './form-elements/JobTypeSelect'
import { NewEquipmentSelect } from './form-elements/NewEquipmentSelect'
import { PreexistingEquipmentSelect } from './form-elements/PreExistingEquipmentSelect'
import { PreexistingEquipmentLinkInputList } from './form-elements/PreexistingEquipmentLinkInputList'

export const newEquipementRequiredForJobClass = (jobClass: JobClass) =>
  jobClass === JobClass.INSTALL ||
  jobClass === JobClass.ESTIMATE_REPLACE ||
  jobClass === JobClass.SALES

export const installProjectTypeRequiredForJobClass = (jobClass: JobClass) =>
  jobClass === JobClass.INSTALL || jobClass === JobClass.ESTIMATE_REPLACE

export type NewJobTypeStepProps = {
  jobTypes: { jobTypeGuid: string; name: string; jobClass: JobClass }[]
}

export const NewJobTypeStep = ({ jobTypes }: NewJobTypeStepProps) => {
  const { control, formState } = useFormContext<ChangeJobTypeFormSchema>()

  const [selectedJobType, setSelectedJobType] = useState<{
    jobTypeGuid: string
    name: string
    jobClass: JobClass
  } | null>(null)

  const isPreexistingEquipmentSelectVisible = useMemo(() => {
    if (isNullish(selectedJobType)) {
      return false
    }

    return !installProjectTypeRequiredForJobClass(selectedJobType.jobClass)
  }, [selectedJobType])

  const isNewEquipmentSelectVisible = useMemo(() => {
    if (isNullish(selectedJobType)) {
      return false
    }

    return newEquipementRequiredForJobClass(selectedJobType.jobClass)
  }, [selectedJobType])

  return (
    <>
      <JobTypeSelect
        jobTypes={jobTypes}
        onJobTypeChanged={setSelectedJobType}
      />

      {isPreexistingEquipmentSelectVisible && (
        <PreexistingEquipmentSelect
          selectedJobType={bzExpect(selectedJobType)}
        />
      )}

      <PreexistingEquipmentLinkInputList />

      {isNewEquipmentSelectVisible && (
        <NewEquipmentSelect selectedJobType={bzExpect(selectedJobType)} />
      )}

      <Divider />

      <span>Maintenance Plan Membership Info</span>

      <Divider />

      <Controller
        control={control}
        name="summary"
        render={({ field }) => (
          <Form.Item label="Summary">
            <Input.TextArea
              {...field}
              allowClear
              rows={5}
              placeholder="Addition information about the customer request"
              status={formState.errors.summary ? 'error' : undefined}
            />
          </Form.Item>
        )}
      />

      {!isNullish(selectedJobType) && (
        <ChangeJobPipelineCardSelect selectedJobType={selectedJobType} />
      )}
    </>
  )
}
