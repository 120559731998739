import { PageHeader } from '@ant-design/pro-components'
import {
  RecommendationType,
  RecommendationViewModel,
  nextGuid,
  noOp,
} from '@breezy/shared'
import { faSandwich } from '@fortawesome/pro-regular-svg-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  createTsForm,
  useStringFieldInfo,
  useTsController,
} from '@ts-react/form'
import { Button, Form, Input, Select, message } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Page } from '../../components/Page/Page'
import { RecommendationCard } from '../../components/Recommendations/RecommendationCard'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { trpc } from '../../hooks/trpc'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getRecommendationValue = (data: any) => {
  return data.recommendationValue
}

const getCtaButtonText = (recommendationType: RecommendationType) => {
  switch (recommendationType) {
    case 'COLLECT_OVERDUE_INVOICE':
      return 'View Invoice'
    case 'FOLLOW_UP_ON_OPEN_ESTIMATE':
      return 'View Estimate'
    case 'MAINTENANCE_VISIT_OPPORTUNITY':
      return 'View Plan'
    default:
      return 'View Account'
  }
}

const convertToViewModel = (
  recommendationType: RecommendationType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
): RecommendationViewModel => ({
  recommendationGuid: nextGuid(),
  recommendationType,
  ctaMarkdown: data.recommendationText,
  ctaButtonText: getCtaButtonText(recommendationType),
  ctaButtonUrl: './',
  description: data.recommendationDescription,
  valueExpectedUsc: data.expectedValueUsc,
  valueExpectedDescription: data.expectedValueExplanation,
})

const TextArea = React.memo(() => {
  const {
    field: { onChange, value, name },
    error,
  } = useTsController<string>()

  const { label, placeholder } = useStringFieldInfo()

  return (
    <Form.Item
      label={label}
      validateStatus={error ? 'error' : 'success'}
      help={error?.errorMessage}
    >
      {' '}
      <Input.TextArea
        id={name}
        rows={7}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        value={value}
      />
    </Form.Item>
  )
})
const PromptSandboxFormSchema = z.object({
  prompt: z.string().describe('Prompt'),
  // eslint-disable-next-line breezy/no-zod-optional
  queryContext: z.string().optional().describe('Query Context'),
})

type PromptSandboxFormData = z.infer<typeof PromptSandboxFormSchema>

const mapping = [[z.string(), TextArea]] as const

type CustomFormProps = React.PropsWithChildren<{
  onSubmit: () => void
  disabled?: boolean
  loading?: boolean
}>

const CustomForm = ({
  children,
  onSubmit,
  disabled,
  loading,
}: CustomFormProps) => {
  return (
    <Form
      onSubmitCapture={onSubmit}
      disabled={disabled || loading}
      layout="vertical"
    >
      {children}
      <Button htmlType="submit" type="primary" loading={loading}>
        Generate
      </Button>
    </Form>
  )
}

const PromptSandboxForm = createTsForm(mapping, { FormComponent: CustomForm })

const PromptSandbox = React.memo(() => {
  const [recommendationType, setRecommendationType] =
    useState<RecommendationType>('MAINTENANCE_VISIT_OPPORTUNITY')
  const [resp, setResp] = useState<string>()
  const [prompt, setPrompt] = useState<string>('')
  const [isUploading, setIsUploading] = useState(false)

  const form = useForm<z.infer<typeof PromptSandboxFormSchema>>({
    resolver: zodResolver(PromptSandboxFormSchema),
  })

  trpc.devTools['devtools:get-llm-completion'].useQuery(
    { value: prompt },
    {
      enabled: !!prompt && prompt.length > 0,
      onSuccess: v => {
        setResp(v.value)
        setIsUploading(false)
      },
      onError: e => {
        message.error(e.message)
        setIsUploading(false)
      },
    },
  )

  const onSubmit = useCallback((data: PromptSandboxFormData) => {
    setIsUploading(true)
    const prompt = `${data.prompt}. ${
      (data.queryContext ?? '').length > 0
        ? `Here is the context data: ${data.queryContext}`
        : ''
    }`
    setPrompt(prompt)
  }, [])

  const recommendationValue = useMemo(
    () => getRecommendationValue(JSON.parse(resp ?? '{}')),
    [resp],
  )

  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <Select
        value={recommendationType}
        onChange={setRecommendationType}
        className="mb-2"
      >
        <Select.Option value="COLLECT_OVERDUE_INVOICE">
          Collect Overdue Invoice
        </Select.Option>
        <Select.Option value="MAINTENANCE_VISIT_OPPORTUNITY">
          Maintenance Visit Opportunity
        </Select.Option>
        <Select.Option value="FOLLOW_UP_ON_OPEN_ESTIMATE">
          Follow Up On Open Estimate
        </Select.Option>
        <Select.Option value="INSTALL_LEAD">Install Lead</Select.Option>
        <Select.Option value="MULTI_SYSTEM_LEAD">
          Multi System Lead
        </Select.Option>
        <Select.Option value="EQUIPMENT_UPSELL_LEAD">
          Equipment Upsell Lead
        </Select.Option>
        <Select.Option value="MAINTENANCE_PLAN_LEAD">
          Maintenance Plan Lead
        </Select.Option>
      </Select>
      <PromptSandboxForm
        form={form}
        schema={PromptSandboxFormSchema}
        onSubmit={onSubmit}
      />
      {isUploading && <LoadingSpinner />}
      {!isUploading && resp && (
        <div className="flex flex-col pt-4 ">
          <div className="flex flex-row gap-x-6">
            <div className="flex flex-col items-center justify-center rounded-xl border border-solid border-gray-200 p-4 shadow-md">
              <h2>Recommendation Value</h2>
              <p className="text-4xl">{recommendationValue}</p>
            </div>
            <RecommendationCard
              viewModel={convertToViewModel(
                recommendationType,
                JSON.parse(resp),
              )}
              longestTitleText={''}
              onDismiss={noOp}
              onComplete={noOp}
              onInteract={noOp}
            />
          </div>
          <h3 className="mb-1 mt-6 text-sm">Raw Response</h3>
          <Input.TextArea className="mb-6" rows={7} value={resp} readOnly />
        </div>
      )}
    </div>
  )
})

const AiRecommendationSandboxPage = React.memo(() => {
  return (
    <Page requiresCompanyUser={false}>
      <div className="card-no-fixed-height pt-2">
        <PageHeader
          className="pl-0"
          title={
            <PageTitle title="AI Sandbox - Recommendation" icon={faSandwich} />
          }
        />
        <PromptSandbox />
      </div>
    </Page>
  )
})

export default AiRecommendationSandboxPage
