import { memo, useMemo } from 'react'
import { useMutation } from 'urql'
import { FeatureEnableView } from '../../components/FeatureEnableView/FeatureEnableView'
import { UPSERT_COMPANY_CONFIG_MAINTENANCE_PLANS_ENABLED_MUTATION } from '../../gql/CompanyConfig.gql'
import { useQueryMaxAttempts } from '../../hooks/useQueryMaxAttempts'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { CHECK_IF_COMPANY_HAS_ACTIVE_MAINTENANCE_PLANS } from './MaintenancePlanConfig.gql'

type MaintenancePlanEnableViewProps = {
  readonly companyMaintenancePlansEnabled: boolean
  readonly refetchMaintenancePlanConfig: () => void
}

export const MaintenancePlanEnableView = memo<MaintenancePlanEnableViewProps>(
  ({ companyMaintenancePlansEnabled, refetchMaintenancePlanConfig }) => {
    const companyGuid = useExpectedCompanyGuid()

    const [
      {
        data: activeMaintenancePlansData,
        fetching: fetchingActiveMaintenancePlans,
      },
      ,
      failedToFetchActiveMaintenancePlansData,
    ] = useQueryMaxAttempts({
      query: CHECK_IF_COMPANY_HAS_ACTIVE_MAINTENANCE_PLANS,
      variables: {
        companyGuid,
      },
    })

    const disableConfirmPrompt = useMemo(() => {
      const companyHasActiveMaintenancePlans =
        activeMaintenancePlansData &&
        activeMaintenancePlansData.maintenancePlansQueryable.length > 0

      if (failedToFetchActiveMaintenancePlansData) {
        return 'You are about to disable the Maintenance Plan feature. This will hide all related functionality, even if you have active plans. Are you sure you want to proceed?'
      } else if (companyHasActiveMaintenancePlans) {
        return 'We noticed that you have active Maintenance Plans. Disabling this feature will hide all Maintenance Plan functionality and could affect these plans. Are you sure you want to disable this?'
      } else {
        return undefined
      }
    }, [activeMaintenancePlansData, failedToFetchActiveMaintenancePlansData])

    const [{ fetching: upserting }, upsertCompanyConfigMutation] = useMutation(
      UPSERT_COMPANY_CONFIG_MAINTENANCE_PLANS_ENABLED_MUTATION,
    )

    return (
      <FeatureEnableView
        featureTitle="Maintenance Plans"
        featureEnabled={companyMaintenancePlansEnabled}
        isLoading={fetchingActiveMaintenancePlans || upserting}
        disableConfirmPrompt={disableConfirmPrompt}
        setValue={async maintenancePlansEnabled => {
          upsertCompanyConfigMutation({
            config: {
              companyGuid,
              maintenancePlansEnabled,
            },
          })
        }}
        refetch={refetchMaintenancePlanConfig}
      />
    )
  },
)
