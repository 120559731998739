import {
  CompanyCreatorV2Input,
  EquipmentType,
  formatEquipmentType,
  isNullish,
  toCamelCase,
} from '@breezy/shared'
import { faUpload } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Select, Upload, UploadFile } from 'antd'
import Papa from 'papaparse'
import { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useMessage } from 'src/utils/antd-utils'
import { fromError } from 'zod-validation-error'
import { equipmentTypesFormSchema } from '../schema'

export const CreateCompanyDrawerEquipmentTypesSection = () => {
  const message = useMessage()

  const { control, watch, setValue, reset, trigger } =
    useFormContext<CompanyCreatorV2Input>()

  const formValues = watch()

  const onFileUploaded = useCallback(
    (file: UploadFile, fileList: UploadFile[]) => {
      if (isNullish(file.originFileObj)) {
        message.error('Could not open file')
        return
      }

      if (fileList.length === 0) {
        return
      }

      Papa.parse(file.originFileObj as File, {
        header: true,
        transformHeader: header => toCamelCase(header),
        complete: results => {
          const parsed = equipmentTypesFormSchema
            .array()
            .safeParse(results.data)
          if (!parsed.success) {
            message.error(
              `Failed to validate Team Members CSV with errors ${fromError(
                parsed.error,
              )}`,
            )
            reset({
              ...formValues,
              hiddenEquipmentTypes: [],
            })
          } else {
            setValue(
              'hiddenEquipmentTypes',
              parsed.data
                .filter(curr => curr.enabled === 'No')
                .map(curr => curr.equipmentType),
            )
            trigger('hiddenEquipmentTypes')
          }
        },
      })
    },
    [formValues, message, reset, setValue, trigger],
  )

  const onFileRemoved = () => {
    reset({
      ...formValues,
      leadSources: [],
    })
  }

  return (
    <div className="flex w-full flex-col gap-3 overflow-y-scroll">
      <label className="flex flex-col gap-2">
        <span>Upload Equipment Types CSV</span>

        <Upload
          accept="text/csv"
          maxCount={1}
          customRequest={({ onSuccess }) => {
            onSuccess?.('ok')
          }}
          onChange={({ file, fileList }) => onFileUploaded(file, fileList)}
          onRemove={onFileRemoved}
        >
          <Button icon={<FontAwesomeIcon icon={faUpload} />}>
            Upload File
          </Button>
        </Upload>
      </label>

      {!isNullish(formValues.hiddenEquipmentTypes) && (
        <Controller
          control={control}
          name="hiddenEquipmentTypes"
          render={({ field }) => (
            <label className="flex flex-col gap-1">
              <span>Hidden Equipment Types</span>

              <Select
                {...field}
                mode="tags"
                options={Object.values(EquipmentType).map(equipmentType => ({
                  label: formatEquipmentType(equipmentType),
                  value: equipmentType,
                }))}
              />
            </label>
          )}
        />
      )}
    </div>
  )
}
