import { Popover } from 'antd'
import { m } from '../../utils/react-utils'
import { UserTimeClockStatusCardV2 } from './UserTimeClockStatusCardV2'

type UserTimeClockPopoverProps = {
  onOpenChange?: () => void
  children: React.ReactNode
}

export const UserTimeClockPopover = m<UserTimeClockPopoverProps>(
  ({ children, onOpenChange }) => {
    return (
      <Popover
        placement="bottomRight"
        trigger="hover"
        content={<UserTimeClockStatusCardV2 />}
        onOpenChange={onOpenChange}
      >
        {children}
      </Popover>
    )
  },
)

export default UserTimeClockPopover
