import { QboIncomeAccount } from '../QboSync/QboSyncTypes'
import { Guid } from '../common-schemas'

export type PricebookQboIncomeAccount = {
  id: string
  parentId?: string
  accountType: 'Income'
  displayName: string
}

export const convertToPricebookQboIncomeAccounts = (accounts: QboIncomeAccount[]): PricebookQboIncomeAccount[] => {
  const parentIdToNameMap: Record<string, string> = {}

  // Create a map for parent ID to Name for direct accounts (no parents)
  accounts.forEach(account => {
    if (!account.parentId && account.name) {
      parentIdToNameMap[account.id] = account.name
    }
  })

  // Filter for 'Income' type accounts and map to the desired structure
  return accounts
    .map(account => {
      const displayName =
        account.parentId && parentIdToNameMap[account.parentId]
          ? `${parentIdToNameMap[account.parentId]} > ${account.name}`
          : account.name
      return {
        id: account.id,
        parentId: account.parentId,
        accountType: account.accountType,
        displayName,
      }
    })
    .filter(account => account.accountType === 'Income')
    .map(a => ({
      ...a,
      accountType: 'Income', // FOR THE TYPESCRIPTs
    }))
}

export type PricebookQboIncomeAccountMap = Record<Guid, PricebookQboIncomeAccount>

export const getPricebookQboIncomeAccountMap = (accounts: QboIncomeAccount[]): PricebookQboIncomeAccountMap => {
  return convertToPricebookQboIncomeAccounts(accounts).reduce((acc, account) => {
    acc[account.id] = account
    return acc
  }, {} as PricebookQboIncomeAccountMap)
}
