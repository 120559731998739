import { BasicBrandedEmailData, Email, phoneUtils } from '@breezy/shared'
import { Body, Container, Head, Heading, Hr, Html, Img, Section, Text } from '@react-email/components'
import React from 'react'
import Tailwind from './Tailwind/Tailwind'

type BasicBrandedEmailProps = BasicBrandedEmailData & Pick<Email, 'message'>

export const BasicBrandedEmail = React.memo<BasicBrandedEmailProps>(
  ({
    message,
    companyLogoUrl,
    companyName,
    contactName,
    contactPhoneNumber,
    contactEmailAddress,
    websiteUrl,
    websiteDisplayName,
  }) => {
    return (
      <Html>
        <Tailwind>
          <Head />
          <Body
            className="bg-white font-body p-6 text-bz-gray-1000 text-base"
            style={{
              WebkitFontSmoothing: 'antialiased',
            }}
          >
            <Container>
              <Section className="mb-6">
                <Img
                  alt={companyName}
                  src={companyLogoUrl}
                  className="block border-0 h-[72px] w-auto leading-100 outline-none decoration-none object-contain object-left max-w-full"
                />
              </Section>
              <Section>
                <Text className="text-base text-bz-gray-1000" dangerouslySetInnerHTML={{ __html: message }} />
              </Section>
              <Section>
                <Heading className="font-semibold text-base text-bz-gray-1000">
                  Contact {contactName ?? companyName}
                </Heading>
                <Hr />
              </Section>
              <Section>
                {(
                  [
                    [
                      'https://d3j5nzmmhjc8v6.cloudfront.net/estimateReviewEmail/phone-icon.png',
                      'Phone',
                      contactPhoneNumber ? phoneUtils.tryFormat(contactPhoneNumber) : undefined,
                      contactPhoneNumber ? `tel:${contactPhoneNumber.replace(/\D/g, '')}` : undefined,
                    ],
                    [
                      'https://d3j5nzmmhjc8v6.cloudfront.net/estimateReviewEmail/email-icon.png',
                      'Email',
                      contactEmailAddress,
                      contactEmailAddress ? `mailto:${contactEmailAddress}` : contactEmailAddress,
                    ],
                    [
                      'https://d3j5nzmmhjc8v6.cloudfront.net/estimateReviewEmail/globe-icon.png',
                      'Website',
                      websiteDisplayName,
                      websiteUrl,
                    ],
                  ] as const
                )
                  .filter(([, , , link]) => !!link)
                  .map(([icon, label, value, link]) => (
                    <div className="flex flex-row items-start mt-4" key={label}>
                      <Img alt={label} src={icon} className="w-[25px] h-[25px] mr-4" />

                      <div className="text-sm">
                        <div className="font-semibold">{label}</div>
                        <a className="text-bz-gray-800 leading-7" href={link}>
                          {value ?? link}
                        </a>
                      </div>
                    </div>
                  ))}
              </Section>
            </Container>
          </Body>
        </Tailwind>
      </Html>
    )
  },
)

export default BasicBrandedEmail
