import {
  BzDateFns,
  getLastVisitedAtMaintPlansV3,
  IsoDateString,
  VisitViewModel,
} from '@breezy/shared'
import { faScrewdriverWrench, faTag } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import { DetailChip } from '../../adam-components/ListPage/DetailChip'
import { TagRow } from '../../elements/TagRow/TagRow'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { VisitMiniCard } from '../Visits/VisitMiniCard'
const MAX_NAME_LENGTH_CHARS = 40
type MaintenancePlanTagRowProps = {
  maintenancePlan: {
    color?: string
    name: string
    visits: VisitViewModel[]
    activatedAt?: IsoDateString
  }
  tags: string[]
  chipClassName?: string
}

export const MaintenancePlanTagRow = React.memo<MaintenancePlanTagRowProps>(
  ({ maintenancePlan, tags, chipClassName }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const formattedActivatedAt = useMemo(
      () =>
        maintenancePlan.activatedAt
          ? `${BzDateFns.format(
              BzDateFns.parseISO(maintenancePlan.activatedAt, tzId),
              'MMM d, yyyy',
            )}`
          : '',
      [maintenancePlan.activatedAt, tzId],
    )

    const lastVisitAt = useMemo(
      () => getLastVisitedAtMaintPlansV3(maintenancePlan.visits),
      [maintenancePlan.visits],
    )

    const formattedLastVisitAt = useMemo(
      () =>
        lastVisitAt
          ? `${BzDateFns.format(
              BzDateFns.parseISO(lastVisitAt, tzId),
              'MMM d, yyyy',
            )}`
          : ``,
      [lastVisitAt, tzId],
    )

    const popoverContent = (
      <div className="flex w-[322px] max-w-md flex-col gap-3">
        <div className="flex flex-col gap-2">
          <div className="text-sm font-semibold">Plan Details</div>
          <p className="mb-0">
            {maintenancePlan.activatedAt && (
              <>
                The plan was created on{' '}
                <span className="font-semibold">{formattedActivatedAt}</span>.
              </>
            )}
            {maintenancePlan.activatedAt && lastVisitAt && ' '}
            {lastVisitAt && (
              <>
                The last maintenance visit was on{' '}
                <span className="font-semibold">{formattedLastVisitAt}</span>.
              </>
            )}
          </p>
        </div>
        <div className="space-y-2">
          {maintenancePlan.visits.map((visit, index) => (
            <VisitMiniCard
              key={visit.visitGuid}
              visitIndex={index}
              visit={visit}
              leftAlign
            />
          ))}
        </div>
      </div>
    )

    const truncateName = (name: string, maxLength: number) => {
      return name.length > maxLength
        ? `${name.slice(0, maxLength - 3)}...`
        : name
    }

    return (
      <TagRow chipClassName={chipClassName}>
        <DetailChip
          className={chipClassName}
          accentColor={maintenancePlan.color}
          icon={
            <FontAwesomeIcon
              icon={faScrewdriverWrench}
              style={{ color: maintenancePlan.color }}
            />
          }
          popoverContent={popoverContent}
          popoverPlacement="right"
          maxPopoverHeight={600}
        >
          {truncateName(maintenancePlan.name, MAX_NAME_LENGTH_CHARS)}
        </DetailChip>
        {tags.map(tag => (
          <DetailChip
            className={chipClassName}
            key={tag}
            icon={<FontAwesomeIcon icon={faTag} className="text-bz-gray-900" />}
          >
            {tag}
          </DetailChip>
        ))}
      </TagRow>
    )
  },
)
