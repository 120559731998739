import { useCallback, useEffect, useMemo, useState } from 'react'

export const queryStringToFilters = (
  queryString: string,
): { key: string; optionsSelected: string[] }[] => {
  return Array.from(new URLSearchParams(queryString).entries()).map(
    ([key, options]) => ({
      key,
      optionsSelected: options.split(','),
    }),
  )
}

export const filtersToQueryString = (
  filters: { key: string; optionsSelected: string[] }[],
): string => {
  return filters
    .map(({ key, optionsSelected }) => `${key}=${optionsSelected.join(',')}`)
    .join('&')
}

export type FilterConfigGroupItem = {
  type: 'group'
  key: string
  label: string

  /**
   * The keys of the other {@link FilterConfigGroupItem} or {@link FilterConfigOptionsItem} that should be a child of this group.
   */
  children: string[]
}

export type FilterConfigOptionsItem<Item> = {
  type: 'options'
  key: string
  label: string
  options: {
    /**
     * The name of the option
     */
    option: string

    /**
     * The filter function for the specific option. Returns true if the item is accepted, false otherwise.
     */
    filter: (item: Item) => boolean
  }[]
}

export type FilterConfig<Item> = (
  | FilterConfigGroupItem
  | FilterConfigOptionsItem<Item>
)[]

export type Filter = { key: string; optionsSelected: string[] }

type UseFilterInput<Item> = {
  data: Item[]
  config: FilterConfig<Item>
  initialFilters?: Filter[]
}

export type SetFilters = (newFilters: Filter[]) => void

export type AddFilter = (filterKey: string, option: string) => void
export type RemoveFilter = (filterKey: string, option: string) => void

export type UseFilterOutput<Item> = {
  filtered: Item[]
  filters: Filter[]
  config: FilterConfig<Item>
  addFilter: AddFilter
  removeFilter: RemoveFilter
  setFilters: SetFilters
  clearFilters: () => void
}

/**
 * Allows you to filter an array of items with any number of filters.
 *
 * Basic example usage:
 * @example
 * ```typescript
 * const data: {
 *  title: string
 *  content: string
 *  editors: { id: string, name: string }[]
 * }[] = [
 *  { title: "Test", content: "Test content", editors: [{ id: "123", name: "Test User" }] },
 *  { title: "Test2", content: "Test content 2", editors: [{ id: "345", name: "Test User 2" }] },
 *  { title: "Test3", content: "Test content 3", editors: [{ id: "123", name: "Test User" }] },
 * ]
 *
 * const { filtered, filters, config, addFilter, removeFilter, clearFilters } = useFilter({
 *  data,
 *  config: [
 *    {
 *      type: "options",
 *      key: "uniqueKey",
 *      label: "Filter By User Property",
 *      options: [
 *         // Only shows items if the user ID is 123
 *        { option: "From User 123", filter: item => item.editors.some(user => user.id === 123) },
 *         // Only shows items if the user ID is 345
 *        { option: "From User 345", filter: item => item.editors.some(user => user.id === 345) },
 *         // Only shows items if the title contains the substring "Test"
 *        { option: "Title contains Test", filter: item => item.title.includes("Test") },
 *       ],
 *    }
 *  ]
 * })
 *
 * // This hook gives you control in how you render your component.
 * // (add render content here...)
 * ```
 * @returns
 */
export const useFilter = <Item>({
  data,
  config,
  initialFilters,
}: UseFilterInput<Item>): UseFilterOutput<Item> => {
  const [filters, setFilters] = useState<
    { key: string; optionsSelected: string[] }[]
  >(initialFilters ?? [])

  const filtered: Item[] = useMemo(() => {
    if (filters.length === 0) {
      return data
    }

    const filterConfigs = config.filter(
      filterConfig => filterConfig.type === 'options',
    ) as FilterConfigOptionsItem<Item>[]

    const filterFns: Map<string, ((item: Item) => boolean)[]> = new Map()
    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i]
      const filterConfigIdx = filterConfigs.findIndex(
        curr => curr.key === filter.key,
      )
      if (filterConfigIdx < 0) {
        continue
      }

      const filterConfig = filterConfigs[filterConfigIdx]
      for (let j = 0; j < filter.optionsSelected.length; j++) {
        const currOption = filter.optionsSelected[j]
        const filterOptionIdx = filterConfig.options.findIndex(
          option => option.option === currOption,
        )

        if (filterOptionIdx < 0) {
          continue
        }

        const filterFn = filterConfig.options[filterOptionIdx].filter
        const currFilterFns = filterFns.get(filter.key)
        if (!currFilterFns) {
          filterFns.set(filter.key, [filterFn])
        } else {
          currFilterFns.push(filterFn)
        }
      }
    }

    const filterFnGroups = Array.from(filterFns.values())
    return data.filter(item => {
      return !filterFnGroups
        // This effectively does a logical OR within each group
        .map(filterFnGroup => filterFnGroup.some(filterFn => filterFn(item)))
        // This effectively does a logical AND on all the groups
        .includes(false)
    })
  }, [data, config, filters])

  const addFilter: UseFilterOutput<Item>['addFilter'] = useCallback(
    (filterKey, option) => {
      setFilters(prev => {
        const filterIdx = prev.findIndex(curr => curr.key === filterKey)
        if (filterIdx < 0) {
          return [...prev, { key: filterKey, optionsSelected: [option] }]
        }

        const newFilters = prev.map(({ key, optionsSelected }) => ({
          key,
          optionsSelected: [...optionsSelected],
        }))

        newFilters[filterIdx].optionsSelected = Array.from(
          new Set([...newFilters[filterIdx].optionsSelected, option]),
        )
        return newFilters
      })
    },
    [],
  )

  const removeFilter: UseFilterOutput<Item>['removeFilter'] = useCallback(
    (filterKey, option) => {
      setFilters(prev => {
        const filterIdx = prev.findIndex(curr => curr.key === filterKey)
        if (filterIdx < 0) {
          return prev
        }

        const optionIdx = prev[filterIdx].optionsSelected.findIndex(
          curr => curr === option,
        )
        if (optionIdx < 0) {
          return prev
        }

        const newFilters = prev.map(({ key, optionsSelected }) => ({
          key,
          optionsSelected: [...optionsSelected],
        }))

        newFilters[filterIdx].optionsSelected.splice(optionIdx, 1)
        if (newFilters[filterIdx].optionsSelected.length === 0) {
          newFilters.splice(filterIdx, 1)
        }

        return newFilters
      })
    },
    [],
  )

  const clearFilters = useCallback(() => setFilters([]), [])

  const setFiltersInternal = useCallback(
    (newFilters: { key: string; optionsSelected: string[] }[]) => {
      setFilters(newFilters)
    },
    [],
  )

  useEffect(() => {
    if (!initialFilters) {
      return
    }

    setFilters(initialFilters)
  }, [initialFilters])

  return {
    filtered,
    filters,
    config,
    addFilter,
    removeFilter,
    setFilters: setFiltersInternal,
    clearFilters,
  }
}
