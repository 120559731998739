import { BzDateFns } from '@breezy/shared'
import { faRotate } from '@fortawesome/pro-solid-svg-icons'
import { Button } from 'antd'
import { useCallback, useMemo, useRef, useState } from 'react'
import BzDrawer, { CancelProps } from '../../../elements/BzDrawer/BzDrawer'
import ElapsedSecondsTimer from '../../../elements/ElapsedSecondsTimer/ElapsedSecondsTimer'
import { trpc } from '../../../hooks/trpc'
import { useMessage } from '../../../utils/antd-utils'
import { m } from '../../../utils/react-utils'
import DatePicker, { DatePickerProps } from '../../DatePicker/DatePicker'
import { LoadingSpinner } from '../../LoadingSpinner'

export type SyncPaymentsOrPayoutsProps = CancelProps & {
  type: 'payment' | 'payouts'
  companyGuid: string
  merchantId: string
}

type SyncPaymentsOrPayoutsDrawerProps = {
  readonly item: SyncPaymentsOrPayoutsProps | undefined
}

export const SyncPaymentsOrPayoutsDrawer = m<SyncPaymentsOrPayoutsDrawerProps>(
  ({ item }) => {
    const message = useMessage()
    const [isUploading, setIsUploading] = useState(false)
    const startDate = useRef(BzDateFns.startOfMonth(new Date()))
    const endDate = useRef(BzDateFns.endOfMonth(new Date()))

    const onStartDateSelected: DatePickerProps['onChange'] = useCallback(
      (m: Date | null) => {
        if (m) startDate.current = m
      },
      [],
    )

    const onEndDateSelected: DatePickerProps['onChange'] = useCallback(
      (m: Date | null) => {
        if (m) endDate.current = m
      },
      [],
    )

    const syncPayoutMut = trpc.payouts['payouts:sync'].useMutation()
    const syncPaymentMut = trpc.payments['payments:sync'].useMutation()

    const itemType = useMemo(
      () => ((item?.type ?? '') === 'payouts' ? 'Payouts' : 'Payments'),
      [item],
    )
    const operationWord = useMemo(
      () => ((item?.type ?? '') === 'payouts' ? 'sync enqueued' : 'synced'),
      [item],
    )
    const beginSync = useCallback(async () => {
      if (!item) return

      setIsUploading(true)
      const start = BzDateFns.formatISO(startDate.current, BzDateFns.UTC)
      const end = BzDateFns.formatISO(endDate.current, BzDateFns.UTC)
      const syncMut = itemType === 'Payouts' ? syncPayoutMut : syncPaymentMut

      await syncMut.mutateAsync(
        {
          companyGuid: item.companyGuid,
          merchantId: item.merchantId,
          start,
          end,
        },
        {
          onSuccess: () => {
            setIsUploading(false)
            message.success(
              `${itemType} ${operationWord} successfully - ${start} to ${end}`,
            )
            item.onCancel()
          },
          onError: e => {
            setIsUploading(false)
            console.error(e)
            message.error(`${itemType} Sync Failed - See Console`)
          },
        },
      )
    }, [item, itemType, message, operationWord, syncPaymentMut, syncPayoutMut])

    return (
      <BzDrawer
        title={`Sync ${itemType}`}
        icon={faRotate}
        item={item}
        destroyOnClose
      >
        {isUploading && (
          <div className="col">
            <LoadingSpinner />
            <h2 className="center-vh">...Syncing {itemType}...</h2>
            <ElapsedSecondsTimer />
          </div>
        )}
        {!isUploading && (
          <div className="col">
            <div style={{ height: 32, padding: '4px 0' }}>Start Date</div>
            <DatePicker
              onChange={onStartDateSelected}
              defaultValue={startDate.current}
              format="MM/dd/yyyy"
              allowClear={false}
              style={{
                minWidth: 160,
                marginLeft: 2,
                marginBottom: 24,
                marginTop: 8,
              }}
            />
            <div style={{ height: 32, padding: '4px 0' }}>End Date</div>
            <DatePicker
              onChange={onEndDateSelected}
              defaultValue={endDate.current}
              format="MM/dd/yyyy"
              allowClear={false}
              style={{
                minWidth: 160,
                marginLeft: 2,
                marginBottom: 24,
                marginTop: 8,
              }}
            />

            <Button type="primary" onClick={beginSync}>
              Sync {itemType}
            </Button>
          </div>
        )}
      </BzDrawer>
    )
  },
)
