import {
  BzDateFns,
  BzDateTime,
  IsoDateString,
  PayPeriodConfig,
  TimeWindowDto,
  getPayPeriodWindowForDay,
} from '@breezy/shared'
import {
  faArrowLeft,
  faArrowRight,
  faCalendar,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback } from 'react'
import FaIconButton from '../../elements/FaIconButton/FaIconButton'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'

type TimesheetPeriodPickerProps = {
  payPeriodConfig: PayPeriodConfig
  window: TimeWindowDto
  setTimeWindow: (window: TimeWindowDto) => void
}

export const sampleCurrentPayPeriod = {
  start: '2024-01-06T09:00:00.000Z' as IsoDateString,
  end: '2024-01-19T17:00:00.000Z' as IsoDateString,
}

export const TimesheetPeriodPicker = m<TimesheetPeriodPickerProps>(
  ({ payPeriodConfig, window, setTimeWindow }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const onNext = useCallback(() => {
      const dayAfterCurrentWindowEnd = BzDateFns.withTimeZone(
        window.end,
        tzId,
        d => BzDateFns.addDays(d, 1),
      )
      const nextWindow = getPayPeriodWindowForDay(
        payPeriodConfig.baseConfig,
        dayAfterCurrentWindowEnd,
        tzId,
      )
      if (nextWindow) setTimeWindow(nextWindow)
    }, [payPeriodConfig, window, setTimeWindow, tzId])

    const onPrev = useCallback(() => {
      const dayBeforeCurrentWindowStart = BzDateFns.withTimeZone(
        window.start,
        tzId,
        // NOTE: I think there is a bug here. 2 days works, but 1 day doesn't.
        // I'm not sure precisely what the issue is, and it's hard to track down due
        // to the hacky way Date Fns fakes Time Zone information.
        // It might be a real time zone math issue.
        d => BzDateFns.subDays(d, 2),
      )
      const prevWindow = getPayPeriodWindowForDay(
        payPeriodConfig.baseConfig,
        dayBeforeCurrentWindowStart,
        tzId,
      )
      if (prevWindow) setTimeWindow(prevWindow)
    }, [payPeriodConfig, window, setTimeWindow, tzId])

    const endYear = BzDateFns.parseISO(window.end, tzId).getFullYear()
    const startYear = BzDateFns.parseISO(window.start, tzId).getFullYear()
    const yearsAreDifferent = BzDateFns.anyDifferentYears(
      [window.start, window.end],
      tzId,
    )

    return (
      <div className="flex items-center justify-between gap-x-4 rounded py-2 pl-4">
        <FaIconButton icon={faArrowLeft} onClick={onPrev} />
        <div className="text-center">
          <FontAwesomeIcon
            icon={faCalendar}
            className="text-gray-800"
            style={{ marginRight: 12 }}
          />
          <span className="font-medium text-gray-800">
            {`${BzDateTime.fromIsoString(
              window.start,
              tzId,
            ).toHumanFriendlyMonthDay()}${
              yearsAreDifferent ? `, ${startYear}` : ''
            } - ${BzDateTime.fromIsoString(
              window.end,
              tzId,
            ).toHumanFriendlyMonthDay()}, ${endYear}`}
          </span>
        </div>
        <FaIconButton icon={faArrowRight} onClick={onNext} className="mr-0" />
      </div>
    )
  },
)
