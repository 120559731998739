import { memoize, normalizeStringIncludingMoney } from '@breezy/shared'
import { useCallback, useRef } from 'react'
import { useDebouncedSearchText } from './useDebouncedSearchText'

const indexedContentMatchesQuery = memoize(
  (indexedContent: string, query: string) => {
    return indexedContent.includes(query)
  },
  (indexedContent, query) => `${indexedContent}__${query}`,
)

type UseInMemorySearchProps<T> = {
  wait?: number
  searchQueryKey?: string
  keyMapper: (t: T) => string
  computeIndex: (t: T) => string
}

export const useInMemorySearch = <T>({
  wait = 300,
  keyMapper,
  searchQueryKey = 'search',
  computeIndex,
}: UseInMemorySearchProps<T>) => {
  const searchCache = useRef<Record<string, string>>({})
  const { searchText, debouncedSearchText, onSearch } = useDebouncedSearchText({
    wait,
    searchQueryKey,
  })

  const searchFilter = useCallback(
    (things: T[]) => {
      if (debouncedSearchText) {
        const includeText = normalizeStringIncludingMoney(debouncedSearchText)

        type ReducerObj = {
          cache: Record<string, string>
          matches: T[]
        }

        ///
        /// Important: We're using react ref's so we are taking special care to mutate a single mutable object that gets
        /// passed through the reducer
        ///
        const reducerResult = things.reduce(
          (memo: ReducerObj, thing) => {
            let indexableSearchContent: string
            const cacheKey = keyMapper(thing)
            if (memo.cache[cacheKey]) {
              indexableSearchContent = memo.cache[cacheKey]
            } else {
              indexableSearchContent = computeIndex(thing)
              memo.cache[cacheKey] = indexableSearchContent
            }

            if (
              indexedContentMatchesQuery(indexableSearchContent, includeText)
            ) {
              memo.matches.push(thing)
            }

            return memo
          },
          { cache: searchCache.current, matches: [] },
        )

        return reducerResult.matches
      }

      return things
    },
    [computeIndex, debouncedSearchText, keyMapper],
  )

  return {
    searchText,
    debouncedSearchText,
    onSearch,
    searchFilter,
  }
}
