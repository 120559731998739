import {
  JobLifecycleStatusWithoutLifecycleGuid,
  JobLifecycleType,
  isNullish,
} from '@breezy/shared'
import {
  faBolt,
  faEdit,
  faGripDotsVertical,
  faTrash,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tag, Tooltip } from 'antd'
import cn from 'classnames'
import React, { useMemo } from 'react'
import { useDrag } from 'react-dnd'
import { LifecycleStatusCircle } from '../../../components/LifecycleStatusCircle/LifecycleStatusCircle'
import { OnDropHandler, OnEditClickHandler, StatusBoxDragItem } from '../types'
import { DropTarget } from './DropTarget'

export type JobLifecycleStatusBoxProps = Omit<
  JobLifecycleStatusWithoutLifecycleGuid,
  'stage'
> &
  StatusBoxDragItem & {
    onDrop: OnDropHandler
    onEditClick: OnEditClickHandler
    onDeleteClick: (lifecycleStatusGuid: string) => void
  } & {
    jobLifecycleType: JobLifecycleType
  }

export const JobLifecycleStatusBox = React.memo<JobLifecycleStatusBoxProps>(
  ({
    jobLifecycleStatusGuid,
    name,
    description,
    isDefault,
    isLeadWon,
    isWorkComplete,
    isAllAppointmentsCompletedAutomationEnabled,
    isAllOutstandingAppointmentsAssignedAutomationEnabled,
    isFirstAppointmentBookedAutomationEnabled,
    isTechnicianArrivesAtAppointmentAutomationEnabled,
    isTechnicianDispatchedAutomationEnabled,
    isNoAdditionalWorkRequiredAutomationEnabled,
    color,
    specialStatus,
    jobLifecycleType,
    jobCount,
    onDrop,
    onEditClick,
    onDeleteClick,
  }) => {
    const [{ isDragging }, drag, preview] = useDrag(
      () => ({
        type: 'STATUS_BOX',
        item: () => {
          const item: StatusBoxDragItem = { jobLifecycleStatusGuid }
          return item
        },
        collect: monitor => ({
          isDragging: monitor.isDragging(),
        }),
      }),
      [jobLifecycleStatusGuid],
    )

    const automationTooltipTitle: string[] = useMemo(() => {
      const tips: string[] = []

      if (isFirstAppointmentBookedAutomationEnabled) {
        tips.push(
          'When the first appointment is booked the job will transition to this status',
        )
      }

      if (isAllOutstandingAppointmentsAssignedAutomationEnabled) {
        tips.push(
          'When all outstanding appointments are assigned then the job will transition to this status',
        )
      }

      if (isTechnicianArrivesAtAppointmentAutomationEnabled) {
        tips.push(
          'When the technician arrives at an appointment the job will transition to this status',
        )
      }

      if (isTechnicianDispatchedAutomationEnabled) {
        tips.push(
          'When the technician indicates they are their way to the appointment then the job will transition to this status',
        )
      }

      if (isAllAppointmentsCompletedAutomationEnabled) {
        tips.push(
          'When all appointments are completed then the job will transition to this status',
        )
      }

      if (isNoAdditionalWorkRequiredAutomationEnabled) {
        tips.push(
          'When all appointments are completed and the technician indicates no follow-up work is required, then the job will transition to this status',
        )
      }

      return tips
    }, [
      isAllAppointmentsCompletedAutomationEnabled,
      isAllOutstandingAppointmentsAssignedAutomationEnabled,
      isFirstAppointmentBookedAutomationEnabled,
      isNoAdditionalWorkRequiredAutomationEnabled,
      isTechnicianArrivesAtAppointmentAutomationEnabled,
      isTechnicianDispatchedAutomationEnabled,
    ])

    return (
      <DropTarget
        onDrop={onDrop}
        dropId={jobLifecycleStatusGuid}
        isDragging={isDragging}
      >
        <div
          ref={preview}
          className={cn(
            'flex flex-row items-center space-x-4 rounded-lg border border-solid border-bz-gray-500 p-4',
            { 'opacity-10': isDragging },
          )}
        >
          <div
            ref={drag}
            className="flex cursor-grab items-center self-stretch px-2"
          >
            <FontAwesomeIcon icon={faGripDotsVertical} />
          </div>
          <div className="px-2">
            <LifecycleStatusCircle
              color={color}
              specialStatus={specialStatus}
            />
          </div>

          <div className="flex w-3/4 flex-row items-center">
            <div className="flex flex-1 flex-col">
              <div className="flex flex-row gap-3">
                <div className="font-medium">{name}</div>
                <div className="flex flex-row">
                  {isDefault && <Tag color="blue">Default</Tag>}
                  {isLeadWon && jobLifecycleType === 'SALES' && (
                    <Tag color="green">Lead - Won</Tag>
                  )}
                  {isWorkComplete && jobLifecycleType === 'JOB' && (
                    <Tag color="geekblue">Work complete</Tag>
                  )}

                  {automationTooltipTitle.length > 0 && (
                    <Tooltip
                      title={
                        <div>
                          <ul>
                            {automationTooltipTitle.map(tip => {
                              return (
                                <li key={`${jobLifecycleStatusGuid}_${tip}`}>
                                  {tip}
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      }
                    >
                      <FontAwesomeIcon size="lg" icon={faBolt} />
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="mt-1 text-sm font-thin">{description}</div>
            </div>

            <div className="flex flex-col items-start">
              <span className="text-sm font-semibold text-bz-gray-900">
                {jobCount ?? 0}
              </span>
              <span className="text-sm text-bz-gray-700"># of jobs</span>
            </div>
          </div>

          <div className="flex w-1/4 flex-row justify-end gap-2">
            <Button
              icon={<FontAwesomeIcon icon={faEdit} />}
              onClick={() => onEditClick(jobLifecycleStatusGuid)}
            >
              Edit
            </Button>

            {isNullish(specialStatus) && (
              <Button
                danger
                icon={<FontAwesomeIcon icon={faTrash} />}
                onClick={() => onDeleteClick(jobLifecycleStatusGuid)}
              />
            )}
          </div>
        </div>
      </DropTarget>
    )
  },
)
