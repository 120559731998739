import { Button, ButtonProps } from 'antd'
import classNames from 'classnames'
import React from 'react'

type ListPageFilterButtonProps = ButtonProps & {
  hasFilters: boolean
}

export const ListPageFilterButton = React.memo<ListPageFilterButtonProps>(
  ({ className, hasFilters, ...rest }) => (
    <Button
      {...rest}
      className={classNames(
        'rounded-md border',
        hasFilters
          ? 'border-[#91CAFF] bg-[#E6F4FF] text-[#1677FF] hover:border-bz-primary-hover hover:text-bz-primary-hover'
          : 'text-bz-gray-900 hover:text-bz-primary-hover',
        className,
      )}
    />
  ),
)
