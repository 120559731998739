import {
  BzDateFns,
  changeRRuleUntil,
  Guid,
  IsoDateString,
  Merge,
  nextGuid,
  R,
  TimeWindowDto,
  TimeZoneId,
} from '@breezy/shared'
import { RecurringChangeType } from '../RecurringChangeModal'
import {
  InternalEvent,
  ScheduleAppointment,
  ScheduleAssignment,
} from '../Schedule.gql'
import {
  BlockCalendarEvent,
  BzBlockInfo,
  fixMbscDate,
  NonBlockCalendarEvent,
} from '../scheduleUtils'

type BlockGuid = string
type AppointmentGuid = string
type AssignmentGuid = string

type DeletionMetadata =
  | {
      appointmentGuid: AppointmentGuid
      isCancellingAppointment?: boolean
      blockGuid?: never
    }
  | {
      appointmentGuid?: never
      blockGuid: BlockGuid
    }

export const isBlockChange = (
  change: EventChangeInfo | BlockChangeInfo,
): change is BlockChangeInfo => 'blockGuid' in change

export type EventChangeInfo = {
  assignmentGuid: AssignmentGuid
  appointmentGuid: AppointmentGuid
  userGuids: Guid[]
  start: IsoDateString
  end: IsoDateString
}

export type BlockChangeInfo = BzBlockInfo

export type BzChangeInfo = EventChangeInfo | BlockChangeInfo

type FixDates<T> = Merge<
  {
    [K in keyof T as K extends 'start' | 'end' ? K : never]-?: IsoDateString
  } & {
    [K in keyof T as K extends 'start' | 'end' ? never : K]: K extends
      | 'start'
      | 'end'
      ? IsoDateString
      : T[K]
  }
>

export type FixedBlockCalendarEvent = FixDates<BlockCalendarEvent>

export type FixedNonBlockCalendarEvent = FixDates<NonBlockCalendarEvent>

export type NewEvent = FixedBlockCalendarEvent | FixedNonBlockCalendarEvent

export type ArrivalWindowMap = Record<AppointmentGuid, TimeWindowDto>
export type PendingScheduleChanges = {
  eventChangeMap: Record<AssignmentGuid | BlockGuid, BzChangeInfo | undefined>
  newEventMap: Record<AssignmentGuid | BlockGuid, NewEvent>
  deletedEventMap: Record<AssignmentGuid | BlockGuid, DeletionMetadata>
  arrivalWindowChangeMap: ArrivalWindowMap
}

export const DEFAULT_PENDING_CHANGES = {
  eventChangeMap: {},
  newEventMap: {},
  deletedEventMap: {},
  arrivalWindowChangeMap: {},
} satisfies PendingScheduleChanges

export const hasPendingChanges = (changes: PendingScheduleChanges) =>
  R.keys(changes).some(key => R.keys(changes[key]).length > 0)

// Ideally I could take a generic like "T extends keyof PendingScheduleChanges" and have typescript figure out what type
// the value needs to be, but I couldn't get that to work.
export type SetPendingChangesArg =
  | {
      field: 'eventChangeMap'
      key: AssignmentGuid | BlockGuid
      value: EventChangeInfo | BlockChangeInfo
    }
  | {
      field: 'newEventMap'
      key: AssignmentGuid | BlockGuid
      value: NewEvent
    }
  | {
      field: 'deletedEventMap'
      key: AssignmentGuid | BlockGuid
      value: DeletionMetadata
    }
  | {
      field: 'arrivalWindowChangeMap'
      key: AppointmentGuid
      value: TimeWindowDto
    }

type AssignmentMap = Record<AssignmentGuid, ScheduleAssignment | undefined>
type AppointmentMap = Record<AssignmentGuid, ScheduleAppointment | undefined>
type InternalEventMap = Record<BlockGuid, InternalEvent | undefined>

export type SetPendingChanges = {
  (changes: SetPendingChangesArg | SetPendingChangesArg[]): void
}

export const applyChanges = (
  { field, key, value }: SetPendingChangesArg,
  pendingChanges: PendingScheduleChanges,
  originalAssignmentMap: AssignmentMap,
  originalBlockMap: InternalEventMap,
  originalAppointmentMap: AppointmentMap,
): PendingScheduleChanges => {
  if (field === 'eventChangeMap') {
    const newEventLookupKey = isBlockChange(value)
      ? value.blockGuid
      : value.assignmentGuid
    // If we're changing a new event, instead of adding it to the change map, update
    // the new thing.
    if (pendingChanges.newEventMap[newEventLookupKey]) {
      return R.assocPath(
        ['newEventMap', newEventLookupKey],
        {
          ...pendingChanges.newEventMap[newEventLookupKey],
          userGuids: value.userGuids,
          start: value.start,
          end: value.end,
        },
        pendingChanges,
      )
    }

    if (isBlockChange(value)) {
      const existingBlock = originalBlockMap[key]
      if (existingBlock) {
        // If everything (important) is the same as the original, just remove this pending change from the change map.
        const hasSameUsers = !R.symmetricDifference(
          existingBlock.userGuids,
          value.userGuids,
        ).length
        const hasSameStartTime =
          new Date(existingBlock.start).getTime() ===
          new Date(value.start)?.getTime()
        const hasSameEndTime =
          new Date(existingBlock.end).getTime() ===
          new Date(value.end)?.getTime()
        const hasSameRecurrenceRule =
          existingBlock.recurrenceRule === value.recurrenceRule
        const hasSameRecurrenceExceptions =
          existingBlock.recurrenceRuleExceptions ===
          value.recurrenceRuleExceptions
        const hasSameReasonType = existingBlock.reasonType === value.reasonType
        const hasSameReasonDescription =
          existingBlock.reasonDescription === value.reasonDescription
        if (
          hasSameUsers &&
          hasSameStartTime &&
          hasSameEndTime &&
          hasSameRecurrenceRule &&
          hasSameRecurrenceExceptions &&
          hasSameReasonType &&
          hasSameReasonDescription
        ) {
          return R.dissocPath(['eventChangeMap', key], pendingChanges)
        }
      }
    } else {
      const existingAssignment = originalAssignmentMap[key]

      if (existingAssignment) {
        // If this change is the same as the original data (they changed an assignment, then changed it back) then
        // remove from the map instead of updating it.
        const hasSameTech =
          existingAssignment.technicianUserGuid === value.userGuids?.[0]
        const hasSameStart =
          new Date(existingAssignment.start).getTime() ===
          new Date(value.start)?.getTime()
        const hasSameEnd =
          new Date(existingAssignment.end).getTime() ===
          new Date(value.end)?.getTime()
        if (hasSameTech && hasSameStart && hasSameEnd) {
          return R.dissocPath(['eventChangeMap', key], pendingChanges)
        }
      }
    }

    // If it isn't a change to a new event and it isn't a change that reverts a previous change back to the original
    // value, then add it to the change map.
    return R.assocPath(['eventChangeMap', key], value, pendingChanges)
  } else if (field === 'newEventMap') {
    // If it's a new event just add it to the new event map
    return R.assocPath(['newEventMap', key], value, pendingChanges)
  } else if (field === 'deletedEventMap') {
    // If we are deleting a new event, instead of adding to `deletedEventMap` just remove from `newEventMap`.
    const newEventKey = R.keys(pendingChanges.newEventMap).find(
      newEventKey =>
        pendingChanges.newEventMap[newEventKey].assignmentGuid === key ||
        pendingChanges.newEventMap[newEventKey].blockGuid === key,
    )
    if (newEventKey) {
      return R.dissocPath(['newEventMap', newEventKey], pendingChanges)
    }
    return {
      ...pendingChanges,
      // If this happens to have had changes, this removes them. If there's no match for key, then it's a no-op.
      eventChangeMap: R.dissoc(key, pendingChanges.eventChangeMap),
      // This adds it to the delete
      deletedEventMap: R.assoc(key, value, pendingChanges.deletedEventMap),
    }
  } else if (field === 'arrivalWindowChangeMap') {
    if (
      originalAppointmentMap[key]?.appointmentWindowStart === value.start &&
      originalAppointmentMap[key]?.appointmentWindowEnd === value.end
    ) {
      return R.dissocPath(['arrivalWindowChangeMap', key], pendingChanges)
    }
    return {
      ...pendingChanges,
      arrivalWindowChangeMap: R.assoc(
        key,
        value,
        pendingChanges.arrivalWindowChangeMap,
      ),
    }
  }
  throw new Error('Invalid change type')
}

export const resolveRecurringEventUpdate = (
  setPendingChanges: SetPendingChanges,
  blockChangeInfo: BlockChangeInfo,
  originalEvent: BlockCalendarEvent,
  originalOccurrenceStart: IsoDateString,
  recurringChangeType: RecurringChangeType,
  tzId: TimeZoneId,
) => {
  // If it's all events, then we just update the original and the rest follow.
  if (recurringChangeType === 'ALL_EVENTS') {
    // If they dragged an event that isn't the original, we need to use the
    // original's date with the new event's time. If it was the original these will
    // be no-ops so we don't have to check.
    const newStartTime = BzDateFns.parseISO(blockChangeInfo.start, tzId)
    const newEndTime = BzDateFns.parseISO(blockChangeInfo.end, tzId)
    const originalStart = BzDateFns.parseISO(
      fixMbscDate(originalEvent.unmodifiedStart ?? originalEvent.start),
      tzId,
    )
    const originalEnd = BzDateFns.parseISO(
      fixMbscDate(originalEvent.unmodifiedEnd ?? originalEvent.end),
      tzId,
    )

    const newStart = BzDateFns.copyTime(originalStart, newStartTime)
    const newEnd = BzDateFns.copyTime(originalEnd, newEndTime)

    setPendingChanges({
      field: 'eventChangeMap',
      key: blockChangeInfo.blockGuid,
      value: {
        ...blockChangeInfo,
        start: BzDateFns.formatISO(newStart, tzId),
        end: BzDateFns.formatISO(newEnd, tzId),
      },
    })
  } else if (recurringChangeType === 'THIS_EVENT_ONLY') {
    // If it's this event only, we set an exception on the original event and create a new, ad hoc event
    const newBlockGuid = nextGuid()

    const exceptions =
      originalEvent.unmodifiedRecurrenceExceptions ??
      originalEvent.recurringException

    setPendingChanges([
      {
        field: 'eventChangeMap',
        key: blockChangeInfo.blockGuid,
        value: {
          blockGuid: blockChangeInfo.blockGuid,
          userGuids: originalEvent.userGuids,
          start: fixMbscDate(originalEvent.start),
          end: fixMbscDate(originalEvent.end),
          reasonType: originalEvent.reasonType,
          reasonDescription: originalEvent.reasonDescription,
          recurrenceRule: originalEvent.recurring
            ? `${originalEvent.recurring}`
            : undefined,
          recurrenceRuleExceptions: exceptions
            ? `${exceptions},${originalOccurrenceStart}`
            : originalOccurrenceStart,
        },
      },
      {
        field: 'newEventMap',
        key: newBlockGuid,
        value: {
          ...originalEvent,
          ...blockChangeInfo,
          blockGuid: newBlockGuid,
          recurring: undefined,
          recurrenceRule: undefined,
        },
      },
    ])
  } else if (recurringChangeType === 'THIS_AND_FUTURE') {
    // If it's this and future, we update the recurrence rule on the original to end a day earlier, then create a new
    // one that starts today.
    const newBlockGuid = nextGuid()

    const exceptions =
      originalEvent.unmodifiedRecurrenceExceptions ??
      originalEvent.recurringException

    const revisedPreviousRecurrenceRule = originalEvent.recurring
      ? changeRRuleUntil(
          `${originalEvent.recurring}`,
          blockChangeInfo.start,
          tzId,
        )
      : undefined

    const newResolvedRecurrenceRule =
      blockChangeInfo.recurrenceRule ?? originalEvent.recurring
    const newRecurrenceRule = newResolvedRecurrenceRule
      ? `${newResolvedRecurrenceRule}`
      : undefined

    setPendingChanges([
      // Updates the rule to end yesterday
      {
        field: 'eventChangeMap',
        key: blockChangeInfo.blockGuid,
        value: {
          blockGuid: blockChangeInfo.blockGuid,
          userGuids: originalEvent.userGuids,
          start: fixMbscDate(
            originalEvent.unmodifiedStart ?? originalEvent.start,
          ),
          end: fixMbscDate(originalEvent.unmodifiedEnd ?? originalEvent.end),
          reasonType: originalEvent.reasonType,
          reasonDescription: originalEvent.reasonDescription,
          recurrenceRule: revisedPreviousRecurrenceRule,
          recurrenceRuleExceptions: exceptions ? `${exceptions}` : undefined,
        },
      },
      // Adds the new recurring event starting today
      {
        field: 'newEventMap',
        key: newBlockGuid,
        value: {
          ...originalEvent,
          ...blockChangeInfo,
          blockGuid: newBlockGuid,
          recurring: newRecurrenceRule,
          recurrenceRule: newRecurrenceRule,
          recurringException: undefined,
        },
      },
    ])
  } else {
    throw new Error(`Unknown recurring change type: ${recurringChangeType}`)
  }
}
