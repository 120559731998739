import {
  BzDateTime,
  CalculatePaths,
  TimeZoneId,
  UserGuid,
} from '@breezy/shared'
import { Table } from 'antd'
import { ColumnsType, TableProps } from 'antd/es/table'
import { ColumnType } from 'antd/lib/table'
import TechnicianResourceView from '../../components/PersonResourceView/TechnicianResourceView'
import { viewDetailsLinkColumn } from '../../components/datatables/CommonColumnDefinitions'
import { EmDash } from '../../elements/EmDash/EmDash'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { RenderableTechnicianResource } from '../../utils/TechnicianResource'
import { m } from '../../utils/react-utils'
import { TechnicianColumnFilterDropdown } from './components/TechnicianColumnFilterDropdown'

type UserDetails = RenderableTechnicianResource

type TableItemType = {
  userGuid: UserGuid
  technician: UserDetails
  daysHoursSummaries: Record<string, number> // Local Date String. Unstamped for easy of use
}

const dayColumnHeader = (localDateStr: string, tzId: TimeZoneId) => {
  const dt = BzDateTime.fromDateString(localDateStr, tzId)
  const dayName = dt.toHumanFriendlyShortDayName()
  const dateStr = dt.toHumanFriendlyMonthDay()

  return (
    <div className="column">
      <div className="regular_14_22 text-right">{dayName}</div>
      <div className="text-right">{dateStr}</div>
    </div>
  )
}

export type TimesheetReportItem = TableItemType

export const getTableColumns = (
  items: TableItemType[],
  tzId: TimeZoneId,
): ColumnsType<TableItemType> => {
  const cols: ColumnType<TableItemType>[] = []
  cols.push(
    viewDetailsLinkColumn(
      (item: TableItemType) => CalculatePaths.timesheetDetailsForUser(item),
      'userGuid',
    ),
  )
  cols.push({
    title: 'Technician',
    dataIndex: 'technician',
    key: 'technician',
    filterDropdown: props => <TechnicianColumnFilterDropdown {...props} />,
    render: (technician: UserDetails) => {
      return <TechnicianResourceView {...technician} userGuid={technician.id} />
    },
  })

  const allDays = new Set<string>()
  items.toSorted().forEach(item => {
    Object.keys(item.daysHoursSummaries).forEach(day => {
      allDays.add(day)
    })
  })
  const sortedDays = Array.from(allDays).sort((a, b) => a.localeCompare(b))
  for (const day of sortedDays) {
    cols.push({
      title: dayColumnHeader(day, tzId),
      dataIndex: 'daysHoursSummaries',
      key: day,
      render: (_: string, item: TableItemType) => {
        return item.daysHoursSummaries[day] &&
          item.daysHoursSummaries[day] >= 0 ? (
          <div className="text-right">
            {(item.daysHoursSummaries[day] ?? 0).toFixed(2)}
          </div>
        ) : (
          <div className="text-right">
            <EmDash />
          </div>
        )
      },
    })
  }

  cols.push({
    title: () => {
      return (
        <div className="flex flex-col items-center">
          <span className="self-end">Total</span>
        </div>
      )
    },
    dataIndex: 'daysHoursSummaries',
    key: 'totalHours',
    render: (dayHoursSummary: Record<string, number>) => {
      return (
        <div className="flex flex-col">
          <span className="self-end">
            {Object.entries(dayHoursSummary)
              .reduce((acc, curr) => {
                if (curr[1] < 0) {
                  return acc
                }
                return acc + curr[1]
              }, 0)
              .toFixed(2)}
          </span>
        </div>
      )
    },
  })

  return cols
}

export type TimesheetSummaryTableProps = {
  items: TableItemType[]
  onChange?: TableProps<TableItemType>['onChange']
}
export const TimesheetsSummaryTable = m<TimesheetSummaryTableProps>(
  ({ items, onChange }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    return (
      <Table
        rowKey="userGuid"
        dataSource={items}
        scroll={{ x: true }}
        columns={getTableColumns(items, tzId)}
        size="small"
        onChange={onChange}
        pagination={false}
      />
    )
  },
)
