import { Button, Drawer, Input } from 'antd'
import { useState } from 'react'
import { UseQueryResponse } from 'urql'
import { useAppContext } from '../../../providers/AppContextWrapper'
import { Applications } from '../../../utils/application-type'
import GqlQueryLoader from '../../GqlQueryLoader/GqlQueryLoader'
import { LoadingSpinner } from '../../LoadingSpinner'
import PricebookSingleLineItemView from './PricebookSingleLineItemView'

type HeaderProps = { title: string }
const Header = ({ title }: HeaderProps) => <h2 className="m0 gray7">{title}</h2>

export type PricebookSelectionDrawerInputProps<TSubmissionItem> =
  PricebookItemActions<TSubmissionItem> & {
    readonly open: boolean
  }

type PricebookSelectionDrawerProps<TInputItem, TQuery, TSubmissionItem> =
  PricebookItemActions<TSubmissionItem> & {
    readonly open: boolean
    readonly query: UseQueryResponse<TQuery, Record<string, never>>
    readonly itemTypeName: string
    readonly getItems: (data: TQuery) => TInputItem[]
    readonly getRenderableItem: (
      item: TInputItem,
    ) => RenderableSearchableLineItem<TSubmissionItem>
  }

type PricebookItemActions<TSubmissionItem> = {
  readonly title: string
  readonly hideTitle?: boolean
  readonly onSubmit: (item: TSubmissionItem) => void
  readonly onCancel: () => void
  readonly onManualEntry?: () => void
}

type PricebookSelectionViewProps<TInputItem, TSubmissionItem> =
  PricebookItemActions<TSubmissionItem> & {
    readonly itemTypeName: string
    readonly items: TInputItem[]
    readonly getRenderableItem: (
      item: TInputItem,
    ) => RenderableSearchableLineItem<TSubmissionItem>
  }

type RenderableSearchableLineItem<TSubmissionItem> = {
  readonly selectionItem: TSubmissionItem
  readonly displayName: string
  readonly displayAmount: string
  readonly searchableTerm: string
}

const PricebookSelectionView = <TInputItem, TSubmissionItem>({
  title,
  hideTitle,
  items,
  itemTypeName,
  onSubmit,
  onCancel,
  onManualEntry,
  getRenderableItem,
}: PricebookSelectionViewProps<TInputItem, TSubmissionItem>) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const renderableItems = items.map(getRenderableItem)
  const filteredItems = renderableItems.filter(item =>
    item.searchableTerm.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  return (
    <div className="mx-1">
      {!hideTitle && <Header title={title} />}
      <div className="min-h-12 py-2">
        {filteredItems.map((item, index) => (
          <PricebookSingleLineItemView
            name={item.displayName}
            amountStr={item.displayAmount}
            key={index}
            onClick={() => onSubmit(item.selectionItem)}
          />
        ))}
        {filteredItems.length === 0 && (
          <div>No Pricebook {itemTypeName} Found</div>
        )}
        {items.length > 0 && (
          <Input
            placeholder="Search tax rate options..."
            onChange={e => setSearchTerm(e.target.value)}
            className="mt-2"
          />
        )}
      </div>
      {onManualEntry && (
        <Button type="primary" className="mt-2 w-full" onClick={onManualEntry}>
          Set Manual {itemTypeName}
        </Button>
      )}
      {!onManualEntry && items.length === 0 && (
        <h3>
          Contact Your Back Office Team to setup Appropriate {itemTypeName}
        </h3>
      )}
      <Button className="mt-2 w-full" onClick={onCancel}>
        Cancel
      </Button>
    </div>
  )
}

const PricebookItemLoader = <TInputItem, TQuery, TSubmissionItem>(
  input: PricebookSelectionDrawerProps<TInputItem, TQuery, TSubmissionItem>,
) => {
  const { query, onCancel, getItems } = input

  return (
    <GqlQueryLoader
      query={query}
      loadingComponent={
        <div className="mx-1">
          <Header title={input.title} />
          <div
            className="column center-children-vh py-2"
            style={{ minHeight: '6em' }}
          >
            <LoadingSpinner />
          </div>
          <Button className="mt-2 w-full" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      }
      render={data => (
        <PricebookSelectionView<TInputItem, TSubmissionItem>
          {...input}
          items={getItems(data)}
        />
      )}
    />
  )
}

const PricebookSelectionDrawer = <TInputItem, TQuery, TSubmissionItem>(
  input: PricebookSelectionDrawerProps<TInputItem, TQuery, TSubmissionItem>,
) => {
  const appType = useAppContext().appType

  return appType === Applications.TECHNICIAN ? (
    <Drawer
      placement={'bottom'}
      height="large"
      closable={true}
      onClose={input.onCancel}
      styles={{ header: { display: 'none' } }}
      open={input.open}
    >
      <div style={{ maxWidth: 500 }} className="center-h">
        <PricebookItemLoader {...input} />
      </div>
    </Drawer>
  ) : (
    <Drawer
      closable={true}
      title={input.title}
      width={720}
      onClose={input.onCancel}
      open={input.open}
      styles={{ body: { paddingBottom: 80 } }}
      destroyOnClose
    >
      <PricebookItemLoader {...input} hideTitle />
    </Drawer>
  )
}

export default PricebookSelectionDrawer
