import { createContext, useContext } from 'react'
import { ChangeJobTypeModalStep, ChangeJobTypeModalStepName } from './types'

export type ChangeJobTypeModalContextProps = {
  formSteps: Record<ChangeJobTypeModalStepName, ChangeJobTypeModalStep>
  currFormStep: ChangeJobTypeModalStep
  setCurrFormStep: (step: ChangeJobTypeModalStep) => void
  onSubmit: () => void
  onClose: () => void
}

export const ChangeJobTypeModalContext =
  createContext<ChangeJobTypeModalContextProps>({
    formSteps: {
      newJobType: { prev: null, next: null, name: 'newJobType' },
      reasonForChange: { prev: null, next: null, name: 'reasonForChange' },
    },
    currFormStep: { prev: null, next: null, name: 'newJobType' },
    setCurrFormStep: () => {},
    onSubmit: () => {},
    onClose: () => {},
  })

export const useChangeJobTypeModalContext = () =>
  useContext(ChangeJobTypeModalContext)
