import { Point, isNullish } from '@breezy/shared'
import { useMemo } from 'react'

export const generateLatLngBoundsLiteralFromPoints = (
  points: Point[],
): google.maps.LatLngBoundsLiteral | undefined => {
  if (!points.length) return undefined

  // Initialize bounds values
  let south: number = points[0].coordinates[0],
    west: number = points[0].coordinates[0],
    north: number = points[0].coordinates[1],
    east: number = points[0].coordinates[0]

  points.forEach(point => {
    const [lng, lat] = point.coordinates
    // Set initial values based on the first coordinate
    if (isNullish(south) || lat < south) south = lat
    if (isNullish(north) || lat > north) north = lat
    if (isNullish(west) || lng < west) west = lng
    if (isNullish(east) || lng > east) east = lng
  })

  return { south, west, north, east }
}

export const useDefaultBounds = (points: Point[]) => {
  return useMemo(() => generateLatLngBoundsLiteralFromPoints(points), [points])
}
