import { faMessage } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import { gray7 } from '../../../themes/theme'

export type SmsViewProps = {
  phoneNumber: string
  iconOnly?: boolean
  displayType?: 'compact' | 'circle-button'
}

export const CompactSmsView = ({ phoneNumber, iconOnly }: SmsViewProps) => {
  const phoneLink = phoneNumber.replace('-', '').replace('+', '')
  return (
    <Tooltip className="phone__number" title={phoneNumber} placement="bottom">
      <a className="phone__number__link" href={`sms:${phoneLink}`}>
        <FontAwesomeIcon
          icon={faMessage}
          color={gray7}
          style={{ fontSize: '16px' }}
        />
        {!iconOnly && <p>{phoneNumber}</p>}
      </a>
    </Tooltip>
  )
}

export const CircleButtonSmsView = ({ phoneNumber }: SmsViewProps) => (
  <Tooltip className="phone__number" title={phoneNumber} placement="bottom">
    <a
      className="phone__number__link"
      href={`sms:${phoneNumber.replace('-', '').replace('+', '')}`}
      style={{
        borderRadius: '50%',
        border: '1px solid',
        borderColor: gray7,
        width: 36,
        height: 36,
        marginRight: 12,
      }}
    >
      <FontAwesomeIcon
        icon={faMessage}
        color={gray7}
        style={{ fontSize: '16px', margin: 'auto' }}
      />
    </a>
  </Tooltip>
)

export const SmsView = ({
  phoneNumber,
  iconOnly,
  displayType = 'compact',
}: SmsViewProps) => {
  if (displayType === 'compact')
    return <CompactSmsView phoneNumber={phoneNumber} iconOnly={iconOnly} />
  return <CircleButtonSmsView phoneNumber={phoneNumber} />
}
