import { BzDateFns } from '@breezy/shared'
import {
  faDownload,
  faInfoCircle,
  faUpload,
} from '@fortawesome/pro-light-svg-icons'
import {
  faArrowDown,
  faPaperclip,
  faRotateLeft,
} from '@fortawesome/pro-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import cn from 'classnames'
import React, { useCallback, useState } from 'react'
import { useSubscription } from 'urql'
import { OnsiteBasicModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import UrqlSubscriptionLoader from '../UrqlSubscriptionLoader/UrqlSubscriptionLoader'
import { PRICEBOOK_VERSION_HISTORY_SUBSCRIPTION } from './PricebookVersionHistory.gql'
import { useBulkExportModal } from './hooks/useBulkExportModal'
import { useBulkImportWizard } from './hooks/useBulkImportWizard'
import { usePricebookAdmin } from './hooks/usePricebookAdmin'

type RestorePricebookModalProps = {
  visible: boolean
  close: () => void
  submit: () => void
  loading?: boolean
}

const RestorePricebookModal = React.memo<RestorePricebookModalProps>(
  ({ visible, close, submit, loading = false }) => {
    return (
      <OnsiteBasicModal
        headerBordered={false}
        onClose={close}
        open={visible}
        header="Restore Pricebook"
        footer={
          <div className="flex min-w-0 flex-1 justify-end gap-2">
            <Button size="large" disabled={loading} onClick={close}>
              Cancel
            </Button>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              disabled={loading}
              className={cn('bg-bz-red-600 text-white', {
                'opacity-50': loading,
              })}
              loading={loading}
              onClick={submit}
            >
              Restore
            </Button>
          </div>
        }
      >
        <div className="flex flex-col">
          <p>
            This action will overwrite your existing pricebook and restore it to
            a previous version. Are you sure you’d like to restore this version
            of your pricebook?
          </p>
          <div className="flex gap-3 rounded-lg border border-solid border-bz-gray-500 bg-bz-gray-300 p-4">
            <div className="flex h-[36px] w-[36px] items-center justify-center rounded-full bg-bz-gray-500 p-4">
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="text-bz-gray-800"
              />
            </div>
            <p className="mb-0 text-sm">
              The system will automatically take a snapshot of your current
              pricebook before you restore a pricebook version in case you
              change your mind.
            </p>
          </div>
        </div>
      </OnsiteBasicModal>
    )
  },
)

const PricebookVersionHistory = React.memo(() => {
  const { closeDrawer, refetchAll } = usePricebookAdmin()
  const [pricebookVersionsLiveQuery] = useSubscription({
    query: PRICEBOOK_VERSION_HISTORY_SUBSCRIPTION,
  })
  const message = useMessage()
  const [restoreModalVisible, setRestoreModalVisible] = useState(false)
  const [selectedPricebookVersion, setSelectedPricebookVersion] = useState<
    string | undefined
  >('')
  const tzId = useExpectedCompanyTimeZoneId()

  const restorePricebook = trpc.pricebook['pricebook:restore'].useMutation({
    onSuccess: () => {
      setRestoreModalVisible(false)
      setSelectedPricebookVersion(undefined)
      closeDrawer()
      refetchAll()
      message.success('Pricebook restored successfully!')
    },
    onError: () => {
      message.error('Failed to restore pricebook')
    },
  })

  const restore = useCallback(() => {
    if (!selectedPricebookVersion) {
      return
    }
    restorePricebook.mutate({ pricebookVersionGuid: selectedPricebookVersion })
  }, [restorePricebook, selectedPricebookVersion])

  return (
    <UrqlSubscriptionLoader
      subscription={pricebookVersionsLiveQuery}
      render={data => (
        <div className="flex h-full min-h-0 flex-1 flex-col gap-3">
          {data.pricebookVersions.length === 0 && (
            <PricebookVersionHistoryEmpty />
          )}
          {data.pricebookVersions.map((pv, i) => {
            const importTime = BzDateFns.format(
              BzDateFns.parseISO(pv.createdAt, tzId),
              'MM/dd/yyyy h:mm a',
            )
            const betterPricebookItemsFileName = `pricebook-items-version-${importTime}.csv`
            const betterPricebookCategoriesFileName = `pricebook-categories-version-${importTime}.csv`
            return (
              <div className="flex flex-col">
                <div className="mb-2 text-sm font-semibold">
                  {`Version #${pv.displayId}${pv.name ? ` - ${pv.name}` : ''}`}
                </div>
                <div className="mb-2 flex items-center justify-between">
                  <p className="mb-0 text-xs text-bz-gray-800">
                    Created by{' '}
                    <span className="font-semibold">
                      {pv.createdByUser?.firstNameInitial}
                    </span>{' '}
                    at {importTime}
                  </p>
                  <Button
                    className="text-xs text-bz-gray-800"
                    type="text"
                    size="small"
                    icon={<FontAwesomeIcon icon={faRotateLeft} />}
                    onClick={() => {
                      setRestoreModalVisible(true)
                      setSelectedPricebookVersion(pv.pricebookVersionGuid)
                    }}
                  >
                    Restore
                  </Button>
                </div>
                <div className="mb-2 flex w-full items-center justify-between rounded-[8px] bg-bz-gray-200 px-4 py-2">
                  <div className="flex items-center space-x-2">
                    <FontAwesomeIcon icon={faPaperclip} />
                    <a
                      href={pv.pricebookItemsFile?.cdnUrl}
                      download={betterPricebookItemsFileName}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Pricebook Items
                    </a>
                  </div>
                </div>
                <div className="mb-2 flex w-full items-center justify-between rounded-[8px] bg-bz-gray-200 px-4 py-2">
                  {pv.pricebookCategoriesFile && (
                    <div className="flex items-center space-x-2">
                      <FontAwesomeIcon icon={faPaperclip} />
                      <a
                        href={pv.pricebookCategoriesFile?.cdnUrl}
                        download={betterPricebookCategoriesFileName}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Pricebook Categories
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )
          })}
          <RestorePricebookModal
            visible={restoreModalVisible}
            close={() => {
              setRestoreModalVisible(false)
              setSelectedPricebookVersion(undefined)
            }}
            loading={restorePricebook.isLoading}
            submit={restore}
          />
        </div>
      )}
    />
  )
})

export const PricebookVersionHistoryEmpty = React.memo(() => {
  const { toggleWizard: toggleImport, wizard } = useBulkImportWizard()
  const { toggle: toggleExport, ExportModal } = useBulkExportModal()
  return (
    <div className="flex min-h-0 flex-1 flex-col gap-3">
      <div className="text-xl font-semibold">
        Looks like you haven't created any pricebook versions yet!
      </div>
      <p className="mb-0">
        Pricebook versions inside Breezy are used as a mechanism to restore your
        pricebook to a previous state. This can be useful if you need to{' '}
        <span className="font-semibold">undo changes </span> made to your
        pricebook.
      </p>
      <div className="flex w-full  gap-3 rounded-lg border border-solid border-daybreak-blue-500 bg-daybreak-blue-300 p-4">
        <div className="flex h-[36px] w-[36px] items-center justify-center rounded-full bg-daybreak-blue-200 p-4">
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="text-daybreak-blue-800"
          />
        </div>
        <div className="text-md">
          <p>
            Versions of your pricebook are automatically created when you:
            <ul>
              <li>Bulk import a new pricebook.</li>
              <li>Restore a previous pricebook version.</li>
            </ul>
          </p>
        </div>
      </div>
      <div className="mt-6 flex flex-1 flex-col items-center gap-8">
        <div className="text-xl font-semibold">
          Create Your First Version! 🌱
        </div>
        <div className="font-semibold">
          1. Export your current pricebook to a CSV file.
        </div>
        <Button
          onClick={toggleExport}
          icon={<FontAwesomeIcon icon={faDownload} />}
        >
          Export
        </Button>

        <div className="font-semibold">2. Make some changes</div>
        <FontAwesomeIcon className="h-[24px]" icon={faArrowDown} />

        <div className="font-semibold">3. Import the CSV file</div>
        <Button
          onClick={toggleImport}
          icon={<FontAwesomeIcon icon={faUpload} />}
        >
          Import
        </Button>
        {wizard}
        {ExportModal}
      </div>
    </div>
  )
})

export default PricebookVersionHistory
