import React from 'react'
import { SmallCard } from './SmallCard'

type SmallInfoCardProps = {
  header: React.ReactNode
  info: { label: string; value?: string }[]
}

export const SmallInfoCard = React.memo<SmallInfoCardProps>(
  ({ header, info }) => {
    return (
      <SmallCard header={header}>
        <div className="grid grid-cols-[auto_auto] gap-2">
          {info.map(({ label, value }) => (
            <React.Fragment key={label}>
              <div className="text-bz-gray-700">{label}</div>
              <div className="text-right text-bz-gray-1000">
                {value ?? <span className="text-bz-gray-700">-</span>}
              </div>
            </React.Fragment>
          ))}
        </div>
      </SmallCard>
    )
  },
)
