import { PermissionV2, R, cloneDeep, isPermissionV2 } from '@breezy/shared'
import { DEFAULT_PERMISSION_CONTROLS_STATE } from './state'
import {
  ChildPermissionGroup,
  FieldPermissionCategory,
  OfficePermissionCategory,
  PermissionCategory,
  PermissionControlsState,
  PermissionGroup,
  RankedPermission,
  isCheckablePermissionGroup,
  isFieldExperienceCategory,
  isOfficeExperienceCategory,
  isRankedPermissionGroup,
} from './types'

export const GLOBAL_PERMISSIONS = [
  PermissionV2.READ_COMPANY,
  PermissionV2.READ_COMPANY_USERS,
]

export const getInitialState = (permissions?: PermissionV2[]) => {
  const initialState = {
    ...cloneDeep(DEFAULT_PERMISSION_CONTROLS_STATE),
    initialPermissions: permissions ?? [],
  }

  if (!permissions) {
    return initialState
  }

  const enablePermissionCategory = (category: PermissionCategory) => {
    if (isOfficeExperienceCategory(category)) {
      initialState.OFFICE_EXPERIENCE.permissionCategories[category].enabled =
        true
    } else if (isFieldExperienceCategory(category)) {
      initialState.FIELD_EXPERIENCE.permissionCategories[category].enabled =
        true
    }
  }

  const uniqueAdd = (arr: PermissionV2[], permission: PermissionV2) => {
    if (!arr.includes(permission)) {
      arr.push(permission)
    }
  }

  permissions.forEach(permission => {
    if (permission === PermissionV2.ALL_ACTIONS) {
      initialState.isSuperAdmin = true
      initialState.selectedPermissions = [PermissionV2.ALL_ACTIONS]
    }

    if (
      permission === PermissionV2.CAPABILITY_CUSTOM_DISCOUNT_CREATION_ENABLED
    ) {
      initialState.CAPABILITIES.customDiscounts = true
      initialState.selectedPermissions.push(
        PermissionV2.CAPABILITY_CUSTOM_DISCOUNT_CREATION_ENABLED,
      )
    }

    ;(['OFFICE_EXPERIENCE', 'FIELD_EXPERIENCE'] as const).forEach(
      experience => {
        if (permission === PermissionV2.USE_OFFICE_EXPERIENCE) {
          initialState.OFFICE_EXPERIENCE.enabled = true
          uniqueAdd(
            initialState.OFFICE_EXPERIENCE.selectedPermissions,
            permission,
          )
          return
        }

        if (permission === PermissionV2.USE_FIELD_EXPERIENCE) {
          initialState.FIELD_EXPERIENCE.enabled = true
          uniqueAdd(
            initialState.FIELD_EXPERIENCE.selectedPermissions,
            permission,
          )
          return
        }

        for (const pc of Object.keys(
          initialState[experience].permissionCategories,
        ) as PermissionCategory[]) {
          const permissionGroups = getPermissionCategoryPermissionGroups(
            initialState,
            pc,
          )

          for (const pg of permissionGroups) {
            if (isRankedPermissionGroup(pg)) {
              const rankedPermissions = getPermissionGroupRankedPermissions(pg)
              const topLevelPermissions =
                getPermissionsFromRankedPermissions(rankedPermissions)

              if (topLevelPermissions.includes(permission)) {
                uniqueAdd(pg.selectedPermissions, permission)
                enablePermissionCategory(pc)
                break
              }

              const nestedRankedPermissions =
                getAllNestedRankedPermissions(rankedPermissions)

              const flattenedChildPermissions =
                getRankedPermissionsFlattenedChildPermissions(
                  nestedRankedPermissions,
                )

              if (flattenedChildPermissions.includes(permission)) {
                uniqueAdd(pg.selectedPermissions, permission)
                enablePermissionCategory(pc)
                break
              }
            } else if (isCheckablePermissionGroup(pg)) {
              const checkablePermissions = getCheckablePermissions(pg)

              if (checkablePermissions.includes(permission)) {
                uniqueAdd(pg.selectedPermissions, permission)
                enablePermissionCategory(pc)
                break
              }
            } else {
              throw new Error(
                `PermissionGroup ${pg} not found in PermissionControlsState`,
              )
            }
          }
        }
      },
    )
  })

  return initialState
}

export const getInitialPermissions = (
  state: PermissionControlsState,
): PermissionV2[] => {
  return state.initialPermissions
}

export function getAllV2Permissions(
  state: PermissionControlsState,
): PermissionV2[] {
  let permissions: PermissionV2[] = [...GLOBAL_PERMISSIONS]

  if (state.isSuperAdmin) {
    return [PermissionV2.ALL_ACTIONS]
  }

  if (
    state.selectedPermissions.includes(
      PermissionV2.CAPABILITY_CUSTOM_DISCOUNT_CREATION_ENABLED,
    )
  ) {
    permissions.push(PermissionV2.CAPABILITY_CUSTOM_DISCOUNT_CREATION_ENABLED)
  }

  for (const experience of ['OFFICE_EXPERIENCE', 'FIELD_EXPERIENCE'] as const) {
    if (state[experience].enabled) {
      permissions = [...permissions, ...state[experience].selectedPermissions]

      for (const category of Object.keys(
        state[experience].permissionCategories,
      ) as PermissionCategory[]) {
        if (isOfficeExperienceCategory(category)) {
          if (!state.OFFICE_EXPERIENCE.permissionCategories[category].enabled) {
            continue
          }
          for (const permissionGroup of state.OFFICE_EXPERIENCE
            .permissionCategories[category].permissionGroups) {
            permissions = [
              ...permissions,
              ...getPermissionGroupSelectedPermissions(permissionGroup),
            ]
          }
        } else if (isFieldExperienceCategory(category)) {
          if (!state.FIELD_EXPERIENCE.permissionCategories[category].enabled) {
            continue
          }
          for (const permissionGroup of state.FIELD_EXPERIENCE
            .permissionCategories[category].permissionGroups) {
            permissions = [
              ...permissions,
              ...getPermissionGroupSelectedPermissions(permissionGroup),
            ]
          }
        } else {
          throw new Error(
            `Category ${category} not found in PermissionControlsState`,
          )
        }
      }
    }
  }

  return permissions
}

export const getIsSuperAdmin = (state: PermissionControlsState): boolean => {
  return state.isSuperAdmin
}

export const getCapabilityCustomDiscountCreationEnabled = (
  state: PermissionControlsState,
): boolean => {
  return state.CAPABILITIES.customDiscounts
}

export const getOfficeExperienceEnabled = (
  state: PermissionControlsState,
): boolean => {
  return state.OFFICE_EXPERIENCE.enabled
}

export const getFieldExperienceEnabled = (
  state: PermissionControlsState,
): boolean => {
  return state.FIELD_EXPERIENCE.enabled
}

export function getOfficeExperienceCategory<T extends OfficePermissionCategory>(
  state: PermissionControlsState,
  category: T,
): PermissionControlsState['OFFICE_EXPERIENCE']['permissionCategories'][T] {
  return state.OFFICE_EXPERIENCE.permissionCategories[category]
}

export function getFieldExperienceCategory<T extends FieldPermissionCategory>(
  state: PermissionControlsState,
  category: T,
): PermissionControlsState['FIELD_EXPERIENCE']['permissionCategories'][T] {
  return state.FIELD_EXPERIENCE.permissionCategories[category]
}

export const getPermissionCategory = (
  state: PermissionControlsState,
  category: PermissionCategory,
) => {
  if (isOfficeExperienceCategory(category)) {
    return getOfficeExperienceCategory(state, category)
  } else if (isFieldExperienceCategory(category)) {
    return getFieldExperienceCategory(state, category)
  } else {
    throw new Error(`Category ${category} not found in PermissionControlsState`)
  }
}

export const getPermissionCategoryEnabled = (
  state: PermissionControlsState,
  category: PermissionCategory,
) => {
  return getPermissionCategory(state, category).enabled
}

export const getPermissionCategoryPermissionGroups = (
  state: PermissionControlsState,
  category: PermissionCategory,
) => {
  return getPermissionCategory(state, category).permissionGroups
}

export const getPermissionCategoryNestedPermissionGroupVisibleValue = (
  state: PermissionControlsState,
  permissionCategory: PermissionCategory,
  permissionGroupIndex: number,
) => {
  const permissionGroups = getPermissionCategoryPermissionGroups(
    state,
    permissionCategory,
  )

  const permissionGroup = permissionGroups[permissionGroupIndex]

  const selectedPermissions =
    getPermissionGroupSelectedPermissions(permissionGroup)
  const rankedPermissions = getPermissionGroupRankedPermissions(permissionGroup)
  const defaultPermission = getPermissionGroupDefaultPermission(permissionGroup)

  const topLevelPermissions =
    getPermissionsFromRankedPermissions(rankedPermissions)

  const hasTopLevelPermissions = R.any(
    R.includes(R.__, selectedPermissions),
    topLevelPermissions,
  )

  if (!hasTopLevelPermissions) {
    return getNestedRankedPermissionRank(rankedPermissions)
  }

  return getVisiblePermission(
    selectedPermissions,
    rankedPermissions,
    defaultPermission,
  )
}

export const getPermissionCategoryPermissionGroup = (
  state: PermissionControlsState,
  category: PermissionCategory,
  permissionGroupIndex: number,
) => {
  const permissionGroups = getPermissionCategoryPermissionGroups(
    state,
    category,
  )

  return permissionGroups[permissionGroupIndex]
}

export const getPermissionCategoryPermissionGroupVisiblePermission = (
  state: PermissionControlsState,
  category: PermissionCategory,
  permissionGroupIndex: number,
): PermissionV2 => {
  const permissionGroup = getPermissionCategoryPermissionGroup(
    state,
    category,
    permissionGroupIndex,
  )

  const selectedPermissions =
    getPermissionGroupSelectedPermissions(permissionGroup)

  const rankedPermissions = getPermissionGroupRankedPermissions(permissionGroup)

  const defaultPermission = getPermissionGroupDefaultPermission(permissionGroup)

  return getVisiblePermission(
    selectedPermissions,
    rankedPermissions,
    defaultPermission,
  )
}

export const getPermissionCategoryPermissionGroupRankedPermissions = (
  state: PermissionControlsState,
  category: PermissionCategory,
  permissionGroupIndex: number,
) => {
  const permissionGroups = getPermissionCategoryPermissionGroups(
    state,
    category,
  )

  const permissionGroup = permissionGroups[permissionGroupIndex]

  return getPermissionGroupRankedPermissions(permissionGroup)
}

export const getPermissionGroupCheckablePermissions = (
  state: PermissionControlsState,
  category: PermissionCategory,
  permissionGroupIndex: number,
) => {
  const permissionGroups = getPermissionCategoryPermissionGroups(
    state,
    category,
  )

  const permissionGroup = permissionGroups[permissionGroupIndex]

  return getCheckablePermissions(permissionGroup)
}

export const getPermissionGroupCheckedPermissions = (
  state: PermissionControlsState,
  category: PermissionCategory,
  permissionGroupIndex: number,
) => {
  const permissionGroups = getPermissionCategoryPermissionGroups(
    state,
    category,
  )

  const permissionGroup = permissionGroups[permissionGroupIndex]

  return getSelectedPermissions(permissionGroup)
}

export const getPermissionsFromRankedPermissions = (
  rankedPermissions: RankedPermission[],
): PermissionV2[] => {
  return R.reject(
    R.isNil,
    rankedPermissions.map(rp => rp.permission),
  )
}

export const getNestedRankedPermissionRank = (
  rankedPermissions: RankedPermission[],
) => {
  const nestedRankedPermission = getNestedRankedPermission(rankedPermissions)

  return nestedRankedPermission?.rank ?? -1
}

export const getNestedRankedPermission = (
  rankedPermissions: RankedPermission[],
) => {
  const nestedRankedPermission = R.find(
    R.both(
      R.pipe(R.prop('permission'), R.isNil),
      R.has('childPermissionGroups'),
    ),
    rankedPermissions,
  )

  return nestedRankedPermission || null
}

export const getAllNestedRankedPermissions = (
  rankedPermissions: RankedPermission[],
) => {
  const nestedRankedPermissions = rankedPermissions.filter(
    rp => rp.childPermissionGroups,
  )

  return nestedRankedPermissions
}

export const getChildPermissions = (
  childPermissionGroups: ChildPermissionGroup[],
) => {
  let permissions: PermissionV2[] = []

  for (const childPermissionGroup of childPermissionGroups) {
    if (isCheckablePermissionGroup(childPermissionGroup)) {
      permissions = permissions.concat(
        childPermissionGroup.checkablePermissions,
      )
    } else if (isRankedPermissionGroup(childPermissionGroup)) {
      permissions = permissions.concat(
        getPermissionsFromRankedPermissions(
          childPermissionGroup.rankedPermissions,
        ),
      )
    }
  }

  return permissions
}

export const getInheritedPermissions = (
  permission: PermissionV2,
  rankedPermissions: RankedPermission[],
): PermissionV2[] => {
  const targetRankedPermission = rankedPermissions.find(
    rp => rp.permission === permission,
  )

  if (!targetRankedPermission?.permission) {
    return []
  }

  // Filter out permissions that have a rank equal to or higher than the given permission
  const equalOrHigherRankPermissions = rankedPermissions
    .filter(rp => rp.rank >= targetRankedPermission.rank)
    .reduce((acc, rp) => {
      let permissions: PermissionV2[] = []

      if (rp.permission) {
        permissions.push(rp.permission)
      }

      // add any child permissions for higher ranked permissions
      if (rp.childPermissionGroups && !R.isEmpty(rp.childPermissionGroups)) {
        const childPermissions = getChildPermissions(rp.childPermissionGroups)
        permissions = permissions.concat(childPermissions)
      }

      return acc.concat(permissions)
    }, [] as PermissionV2[])

  return equalOrHigherRankPermissions
}

export const getPermissionGroupRankedPermissions = (
  permissionGroup: PermissionGroup,
): RankedPermission[] => {
  return isRankedPermissionGroup(permissionGroup)
    ? permissionGroup.rankedPermissions
    : []
}

export const getCheckablePermissions = (
  permissionGroup: PermissionGroup,
): PermissionV2[] => {
  return isCheckablePermissionGroup(permissionGroup)
    ? permissionGroup.checkablePermissions
    : []
}

export const getPermissionGroupDefaultPermission = (
  permissionGroup: PermissionGroup,
) => {
  return permissionGroup.defaultPermissions[0]
}

export const getPermissionGroupSelectedPermissions = (
  permissionGroup: PermissionGroup,
) => {
  return permissionGroup.selectedPermissions
}

export const getSelectedPermissions = (permissionGroup: PermissionGroup) => {
  return permissionGroup.selectedPermissions
}

export const getNestedPermissionGroupSelectedPermissions = (
  group: PermissionGroup,
  value: PermissionV2 | number,
) => {
  let selectedPermissions: PermissionV2[] = []
  if (isRankedPermissionGroup(group)) {
    if (isPermissionV2(value)) {
      selectedPermissions = getInheritedPermissions(
        value,
        group.rankedPermissions,
      )
    } else if (
      !R.isEmpty(
        getRankedPermissionByRank(group.rankedPermissions, value)
          ?.childPermissionGroups,
      )
    ) {
      const rankedPermission = getRankedPermissionByRank(
        group.rankedPermissions,
        value,
      )

      selectedPermissions = getChildPermissionGroupDefaultPermissions(
        rankedPermission?.childPermissionGroups ?? [],
      )
    } else {
      selectedPermissions = group.selectedPermissions
    }
  } else {
    selectedPermissions = group.selectedPermissions
  }

  return selectedPermissions
}

const getRankedPermissionByRank = (
  rankedPermissions: RankedPermission[],
  rank: number,
): RankedPermission | undefined => {
  return rankedPermissions.find(permission => permission.rank === rank)
}

export const getRankedPermissionChildPermissionGroups = (
  rankedPermission: RankedPermission,
) => {
  return rankedPermission.childPermissionGroups ?? []
}

export const getChildPermissionsGroupPermissions = (
  cpg: ChildPermissionGroup[],
) => {
  return cpg.map(c =>
    isCheckablePermissionGroup(c)
      ? c.checkablePermissions
      : isRankedPermissionGroup(c)
      ? getPermissionsFromRankedPermissions(c.rankedPermissions)
      : [],
  )
}

export const getRankedPermissionsFlattenedChildPermissions = (
  rankedPermissions: RankedPermission[],
) => {
  return rankedPermissions.flatMap(rankedPermissions => {
    const childPermissionGroups =
      getRankedPermissionChildPermissionGroups(rankedPermissions)
    return getChildPermissionsGroupPermissions(childPermissionGroups).flat()
  })
}

export const getChildPermissionGroupDefaultPermissions = (
  childPermissionGroups: ChildPermissionGroup[],
) => childPermissionGroups.map(cpg => cpg.defaultPermissions).flat()

export const getVisiblePermission = (
  selectedPermissions: PermissionV2[],
  rankedPermissions: RankedPermission[],
  defaultPermission: PermissionV2,
) => {
  if (R.isEmpty(selectedPermissions)) {
    return defaultPermission
  }

  const sortedRankedPermissions = R.sortBy(R.prop('rank'), rankedPermissions)
  const lowestRankedPermission = R.find(
    R.propSatisfies(
      permission => selectedPermissions.includes(permission),
      'permission',
    ),
    sortedRankedPermissions,
  )

  return lowestRankedPermission?.permission ?? defaultPermission
}
