export const GettingStartedMd = `
# Getting Started with Bulk Pricebook Import/Export

Welcome to the Bulk Pricebook Import/Export feature! This tool is designed to streamline the process of managing your pricebook data, allowing you to import or export large volumes of pricebook items and categories efficiently.

## Overview

The Bulk Pricebook Import/Export feature supports two main operations:

1. **Bulk Import**: Allows you to import pricebook items and categories from a structured file (e.g., CSV) into Breezy. This is particularly useful for making sweeping changes across your pricebook without having to manually update each item or category individually.
1. **Bulk Export**: Enables you to export your existing pricebook items and categories into a structured file, making it easy to export your pricebook outside of Breezy to make bulk updates in a spreadsheet and integrate with 3rd party pricing information.


## How to Use

### Exporting Pricebook Data

1. Go to the Pricebook section under Settings.
2. Click on the "Export" button.
3. Choose the data you wish to export (items or categories) and confirm the download.

### Importing Pricebook Data

1. Prepare your data file according to the required format. You can export your existing data to use as a template.
2. Navigate to the Pricebook section under Settings.
3. Click on the "Import" button and follow the instructions to upload your file.
4. Fix any validation errors and complete the import process inside the embedded spreadsheet.
5. Review the changes and confirm the import.

## Version History and Restore

Our system includes a Version History and Restore feature, allowing you to view all changes to your pricebook and revert to previous versions if needed.

### Viewing Version History
- Access this feature in the **Version History** section.
- Each version shows its creation date and the user who made it.

### Restoring a Previous Version
- Click **Restore** next to the version you want to revert to.
- **Note**: This action overwrites your current pricebook but a snapshot is taken beforehand for safety.

This ensures you can manage your pricebook's history and revisions with ease and security.

## FAQ

**Q: What file format is supported for import/export?**  
A: Currently, the system supports CSV files for both importing and exporting pricebook data.

**Q: Can I import data with QuickBooks Online (QBO) income accounts?**  
A: Yes, if the QBO integration is enabled, you can import pricebook items with associated QBO income accounts.

**Q: How can I ensure my data file is in the correct format?**  
A: Refer to the 'Data checklist' page in the sidebar. Ensure your data matches the structure and data types specified.

**Q: What happens if there are errors in my import file?**  
A: The system will validate your file and report any errors. You will need to correct these errors inside the embedded spreadsheet or make the edits in your file and re-upload it.

**Q: Can I update existing pricebook items through import?**  
A: Yes, the import process can update existing items based on the pricebook item name and pricebook category. New items will be added, and unmatched existing items will be deleted.

**Q: I've added a new category in my import file but I'm getting a validation error. How can I fix this?**
A: Ensure that the category name matches an existing category in the system. If the category does not exist, you will need to create it manually inside the 'Pricebook Categories' sheet in the embedded spreadsheet.

For more detailed instructions and support, please reach out via Intercom to contact our support team.
`
