import { PageHeader } from '@ant-design/pro-components'
import { faSandwich } from '@fortawesome/pro-regular-svg-icons'
import React from 'react'
import { Page } from '../../components/Page/Page'
import PromptSandbox from '../../components/PromptSandbox/PromptSandbox'
import PageTitle from '../../elements/PageTitle/PageTitle'

const AISandboxPage = React.memo(() => {
  return (
    <Page requiresCompanyUser={false}>
      <div className="card-no-fixed-height pt-2">
        <PageHeader
          className="pl-0"
          title={<PageTitle title="AI Sandbox" icon={faSandwich} />}
        />
        <PromptSandbox />
      </div>
    </Page>
  )
})

export default AISandboxPage
