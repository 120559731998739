import { Point } from '@breezy/shared'
import { useCallback } from 'react'

export const useCenterMapOnVisiblePoints = (
  map: google.maps.Map | null,
  points: Point[],
) => {
  return useCallback(() => {
    if (!map || !points.length) return

    // If the map is loaded and there are visible markers, fit the map bounds to the markers
    const bounds = new google.maps.LatLngBounds()

    points
      .map(p => p.coordinates)
      .forEach(([lng, lat]) => {
        bounds.extend(new google.maps.LatLng(lat, lng))
      })

    map.fitBounds(bounds)
  }, [map, points])
}
