import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Input, InputRef, Radio, Space } from 'antd'
import { useCallback, useMemo, useRef, useState } from 'react'
import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import './BulkImportModal.less'

import { BzDateFns, kebabCase } from '@breezy/shared'
import { Form } from 'antd'
import React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  useExpectedCompany,
  useExpectedCompanyTimeZoneId,
} from '../../../providers/PrincipalUser'
import { useDownloadPricebookCategoriesCsv } from './useDownloadPricebookCategoriesCsv'
import { useDownloadPricebookItemsCsv } from './useDownloadPricebookItemsCsv'

const ExportPricebookFormSchema = z.object({
  exportOption: z.enum(['item', 'category']),
  pricebookItemsFilename: z.string().nonempty(),
  pricebookCategoriesFilename: z.string().nonempty(),
})

type ExportPricebookFormData = z.infer<typeof ExportPricebookFormSchema>

type ExportPricebookModalProps = {
  visible: boolean
  onClose: () => void
}

const ExportPricebookModal = React.memo<ExportPricebookModalProps>(
  ({ visible, onClose }) => {
    const formRef = useRef<HTMLFormElement>(null)
    const pricebookFilenameRef = useRef<InputRef>(null)
    const pricebookCategoriesFilenameRef = useRef<InputRef>(null)

    const tzId = useExpectedCompanyTimeZoneId()
    const downloadPricebookItemsCsv = useDownloadPricebookItemsCsv()
    const downloadPricebookCategoriesCsv = useDownloadPricebookCategoriesCsv()
    const company = useExpectedCompany()

    const todaysDate = BzDateFns.format(BzDateFns.now(tzId), 'yyyy-MM-dd')

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
      reset,
    } = useForm<ExportPricebookFormData>({
      resolver: zodResolver(ExportPricebookFormSchema),
      defaultValues: {
        exportOption: 'item',
        pricebookItemsFilename: `${kebabCase(
          company.name,
        )}-pricebook-items-${todaysDate}`,
        pricebookCategoriesFilename: `${kebabCase(
          company.name,
        )}-pricebook-categories-${todaysDate}`,
      },
      mode: 'onChange',
    })

    const onSubmit: SubmitHandler<ExportPricebookFormData> = useCallback(
      data => {
        if (data.exportOption === 'item') {
          downloadPricebookItemsCsv(data.pricebookItemsFilename)
        } else if (data.exportOption === 'category') {
          downloadPricebookCategoriesCsv(data.pricebookCategoriesFilename)
        }
        reset()
        onClose()
      },
      [
        reset,
        onClose,
        downloadPricebookItemsCsv,
        downloadPricebookCategoriesCsv,
      ],
    )

    const close = useCallback(() => {
      reset()
      onClose()
    }, [reset, onClose])

    return (
      <OnsiteBasicModal
        headerBordered={false}
        onClose={close}
        open={visible}
        header="Export Pricebook"
        footer={
          <div className="flex min-w-0 flex-1 gap-2">
            <Button className="flex-1 " onClick={close} size="large">
              Close
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="flex-1 "
              disabled={!isValid}
              onClick={() =>
                formRef.current?.dispatchEvent(
                  new Event('submit', { cancelable: true, bubbles: true }),
                )
              }
            >
              Export
            </Button>
          </div>
        }
      >
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="exportOption"
            control={control}
            render={({ field }) => (
              <Radio.Group
                {...field}
                className="bz-bulk-import-radio-group w-full"
              >
                <Space className="w-full" direction="vertical">
                  <Radio className="p-3" value="item">
                    <div className="mb-2 text-sm font-semibold">
                      Export Pricebook Items
                    </div>
                    <Controller
                      name="pricebookItemsFilename"
                      control={control}
                      rules={{ required: 'Filename cannot be empty' }}
                      render={({ field }) => (
                        <Form.Item
                          className="m-0"
                          validateStatus={
                            errors.pricebookItemsFilename && 'error'
                          }
                          help={errors.pricebookItemsFilename?.message}
                        >
                          <Input
                            className="min-w-[250px]"
                            {...field}
                            addonAfter=".csv"
                            size="large"
                            ref={pricebookFilenameRef}
                            onFocus={_ =>
                              pricebookFilenameRef.current?.select()
                            }
                          />
                        </Form.Item>
                      )}
                    />
                    <div className="mt-2 text-bz-gray-800">
                      This will export all items in the pricebook, including
                      their prices, descriptions, and categories.
                    </div>
                  </Radio>
                  <Radio className="p-3" {...field} value="category">
                    <div className="mb-2 text-sm font-semibold">
                      Export Pricebook Categories
                    </div>
                    <Controller
                      name="pricebookCategoriesFilename"
                      control={control}
                      rules={{ required: 'Filename cannot be empty' }}
                      render={({ field }) => (
                        <Form.Item
                          className="m-0"
                          validateStatus={
                            errors.pricebookCategoriesFilename && 'error'
                          }
                          help={errors.pricebookCategoriesFilename?.message}
                        >
                          <Input
                            className="min-w-[250px]"
                            {...field}
                            ref={pricebookCategoriesFilenameRef}
                            size="large"
                            onFocus={_ =>
                              pricebookCategoriesFilenameRef.current?.select()
                            }
                            addonAfter=".csv"
                          />
                        </Form.Item>
                      )}
                    />
                    <div className="mt-2 text-bz-gray-800">
                      This will export all categories in the pricebook,
                      including their names and parent categories.
                    </div>
                  </Radio>
                </Space>
              </Radio.Group>
            )}
          />
        </form>
      </OnsiteBasicModal>
    )
  },
)

export const useBulkExportModal = () => {
  const [visible, setVisible] = useState(false)

  const toggle = useCallback(() => {
    setVisible(prev => !prev)
  }, [])

  const ExportModal = useMemo(() => {
    return <ExportPricebookModal visible={visible} onClose={toggle} />
  }, [toggle, visible])

  return { toggle, ExportModal }
}
