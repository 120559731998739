import {
  ComprehensiveMaintenancePlanConfigViewModel,
  EquipmentCoverageDefinition,
  EquipmentType,
  formatEquipmentType,
  formatUsdWholeDollars,
  R,
} from '@breezy/shared'
import { faBoxArchive, faEdit } from '@fortawesome/pro-light-svg-icons'
import { Row, Tooltip } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import FaIconButton from '../../elements/FaIconButton/FaIconButton'
import { trpc } from '../../hooks/trpc'
import { gray4 } from '../../themes/theme'
import { useMessage } from '../../utils/antd-utils'
import AddOrEditEquipmentCoverageDrawer from './AddOrEditEquipmentCoverageDrawer'
import {
  MaintenanceConfigSection,
  MaintenanceItemContainer,
  MaintenanceNumberLabelPair,
  maintenancePlanItemStandardWidth,
} from './MaintenancePlanElements'

type MaintenanceEquipmentTypeConfigProps = {
  readonly viewModel?: ComprehensiveMaintenancePlanConfigViewModel
  readonly refetch: () => void
}

export const MaintenanceEquipmentConfigSection = ({
  viewModel,
  refetch,
}: MaintenanceEquipmentTypeConfigProps) => {
  const [drawerContent, setDrawerContent] = useState<
    EquipmentCoverageDefinition | 'create new'
  >()
  const unconfiguredEquipmentTypes = useMemo(
    () =>
      R.difference(
        R.values(EquipmentType),
        R.pluck('equipmentType', viewModel?.equipmentCoverageDefinitions ?? []),
      ),
    [viewModel],
  )

  return (
    <>
      <AddOrEditEquipmentCoverageDrawer
        refetch={refetch}
        open={!!drawerContent}
        onClose={() => setDrawerContent(undefined)}
        equipmentCoverage={
          drawerContent === 'create new' ? undefined : drawerContent
        }
        allowedEquipmentCoverageTypes={unconfiguredEquipmentTypes}
      />
      <MaintenanceConfigSection
        title="Equipment Types"
        description="Manage all the equipment types that will be available on your maintenance plans. Each customer will have the option to choose which specific equipment options they would like to include in their plan."
        buttonText="Cover New Equipment Type"
        buttonOnClick={() => setDrawerContent('create new')}
        empty={
          <MaintenanceItemContainer>
            <p className="regular_14_22 grey7 center-vh text-center font-light">
              No equipment types have been configured yet.
              <br />
              <br />
              Click the button above to configure a new equipment type.
            </p>
          </MaintenanceItemContainer>
        }
        children={
          viewModel &&
          // NOTE: Length Check is necessary to correctly cause the Empty Element to be Rendered
          viewModel.equipmentCoverageDefinitions.length > 0 &&
          R.sort(
            R.ascend(def => def.equipmentType.toUpperCase()),
            viewModel?.equipmentCoverageDefinitions ?? [],
          ).map(equipmentCoverage => (
            <MaintenanceEquipmentTypeConfigItem
              key={equipmentCoverage.equipmentType}
              beginEdit={() => setDrawerContent(equipmentCoverage)}
              {...equipmentCoverage}
              refetch={refetch}
            />
          ))
        }
      />
    </>
  )
}

type MaintenanceEquipmentTypeConfigItemProps = EquipmentCoverageDefinition & {
  readonly beginEdit: () => void
  readonly refetch: () => void
}

const MaintenanceEquipmentTypeConfigItem = (
  props: MaintenanceEquipmentTypeConfigItemProps,
) => {
  const message = useMessage()
  const {
    equipmentType,
    planPricePerServiceUsd,
    regularPricePerServiceUsd,
    isAvailableForSale: originalIsAvailableForSale,
  } = props
  const [isAvailableForSale, setIsAvailableForSale] = useState(
    originalIsAvailableForSale,
  )
  const mutateActive =
    trpc.maintenancePlans[
      'maintenance-plans-config:upsert-equipment'
    ].useMutation()
  const toggleActive = useCallback(() => {
    setIsAvailableForSale(!isAvailableForSale)
    mutateActive.mutateAsync(
      {
        ...props,
        isAvailableForSale: !isAvailableForSale,
      },
      {
        onSuccess: () => {
          message.success('Updated equipment coverage active status')
          props.refetch()
        },
        onError: () => {
          setIsAvailableForSale(isAvailableForSale)
          message.error('Failed to update equipment coverage active status')
        },
      },
    )
  }, [isAvailableForSale, mutateActive, props, message])

  return (
    <Tooltip
      title={'Not Available For Sale'}
      // NOTE: Do not visibly show when Active. No ability to disable the Tooltip and
      // also did not desire to have a different structure of DOM Tree for this branch
      mouseEnterDelay={isAvailableForSale ? 99999999 : 0.1}
    >
      {/* This div is necessary for the tooltip to work */}
      <div>
        <MaintenanceItemContainer
          className="row center-children-v w-full"
          style={{ backgroundColor: isAvailableForSale ? '' : gray4 }}
        >
          <Row className="row center-children-v flex-between flex w-full">
            <div
              className={`semibold_16_24 ${maintenancePlanItemStandardWidth}`}
            >
              {formatEquipmentType(equipmentType)}
            </div>
            <MaintenanceNumberLabelPair
              kpi={`${formatUsdWholeDollars(
                planPricePerServiceUsd,
              )} per service`}
              description="Plan price"
            />
            <MaintenanceNumberLabelPair
              kpi={`${formatUsdWholeDollars(
                regularPricePerServiceUsd,
              )} per service`}
              description="Regular price"
            />
            <div className="min-w-[32px]" />
            <Row className="flex gap-2">
              {isAvailableForSale && (
                <FaIconButton
                  icon={faEdit}
                  onClick={props.beginEdit}
                  tooltip="Edit"
                />
              )}
              <FaIconButton
                icon={faBoxArchive}
                onClick={toggleActive}
                tooltip={isAvailableForSale ? 'Deactivate' : 'Activate'}
              />
            </Row>
          </Row>
        </MaintenanceItemContainer>
      </div>
    </Tooltip>
  )
}
