import { memo } from 'react'

type BzStatCardProps = {
  readonly title: string
  readonly value: string
  readonly valueClassName?: string
}

const BzStatCard = ({ title, value, valueClassName }: BzStatCardProps) => (
  <div className="stat-box">
    <div className={`semibold_24_32 ${valueClassName || 'grey9'}`}>{value}</div>
    <div className="regular_10_20 grey7 font-normal">{title}</div>
  </div>
)

const BzStatCardMemo = memo(BzStatCard)

export default BzStatCardMemo
