import { PricebookItemCostUSDSchema } from '@breezy/shared'
import { Form, InputNumber } from 'antd'

export const PricebookFormItemCost = () => (
  <Form.Item
    name="costUSD"
    label="Cost"
    required
    rules={[
      {
        validator: async (_, value) => {
          const result = PricebookItemCostUSDSchema.safeParse(value)
          if (!result.success) {
            throw new Error(
              result.error.issues[0]?.message || result.error.message,
            )
          }
        },
      },
    ]}
  >
    <InputNumber
      style={{ width: '100%' }}
      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={value =>
        !value ? 0 : Number.parseFloat(value.replace(/\$\s?|(,*)/g, ''))
      }
    />
  </Form.Item>
)
