import { metersToMiles, milesToMeters } from '@breezy/shared'
import { ControlPosition, MapControl } from '@vis.gl/react-google-maps'
import { InputNumber, Slider } from 'antd'
import React from 'react'

type RadiusControlProps = {
  radiusMeters: number
  defaultRadiusMiles: number
  onRadiusChanged: (newRadius: number) => void
  loading: boolean
}
const RadiusControl = React.memo<RadiusControlProps>(
  ({ radiusMeters, defaultRadiusMiles, onRadiusChanged, loading }) => {
    return (
      <MapControl position={ControlPosition.LEFT_BOTTOM}>
        <div className="mb-4 ml-4 min-w-[200px] rounded-md bg-white p-[14px] shadow-md">
          <div className="text-sm text-black">Radius Distance</div>
          <div className="flex flex-col gap-1">
            <Slider
              marks={{ 1: 1, 3: 3, 5: 5, 10: 10, 15: 15 }}
              max={15}
              defaultValue={defaultRadiusMiles}
              disabled={loading}
              min={1}
              step={null}
              value={metersToMiles(radiusMeters)}
              onChange={value => onRadiusChanged(milesToMeters(value))}
            />
            <div className="flex flex-row items-end">
              <InputNumber
                className="w-[60px] rounded-md"
                min={1}
                max={15}
                size="small"
                step={1}
                disabled={loading}
                value={Number(metersToMiles(radiusMeters).toFixed(1))}
                onChange={value => {
                  if (value) {
                    onRadiusChanged(milesToMeters(value))
                  }
                }}
              />
              <span className="ml-2 pb-[1px] text-sm text-black">Miles</span>
            </div>
          </div>
        </div>
      </MapControl>
    )
  },
)

export default RadiusControl
