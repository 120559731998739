import { gql } from '../../generated'

export const ACCOUNTS_LIST_PAGE_FRAGMENT = gql(/* GraphQL */ `
  fragment AccountsListPageAccountsFragment on Accounts {
    accountDisplayName
    accountType
    archived
    accountContacts(where: { primary: { _eq: true } }) {
      contact {
        fullName
        primaryEmailAddress {
          emailAddress
        }
        primaryPhoneNumber {
          type
          phoneNumber
        }
      }
    }
    lastJobDate
    jobsAggregate {
      aggregate {
        count
      }
    }
    accountLocations(orderBy: { createdAt: ASC }) {
      location {
        address {
          line1
          line2
          city
          stateAbbreviation
          zipCode
        }
        locationGuid
        installedEquipment(orderBy: { equipmentType: ASC }) {
          modelNumber
          serialNumber
          estimatedEndOfLifeDate
          manufacturer
          equipmentType
          installationDate
          installedEquipmentGuid
        }
      }
      locationGuid
    }
    tags {
      tag {
        name
      }
    }
    accountGuid
    maintenancePlans(
      where: { canceledAt: { _isNull: true } }
      orderBy: { yearlyPriceUsc: DESC }
    ) {
      maintenancePlanDefinition {
        marketingInfo {
          name
        }
        flare {
          primaryColorHex
        }
      }
    }
  }
`)

export const ACCOUNTS_LIST_PAGE_DATA_QUERY = gql(/* GraphQL */ `
  query AccountsListPageData(
    $where: AccountsBoolExp!
    $orderBy: [AccountsOrderBy!]!
    $limit: Int!
    $offset: Int!
  ) {
    accounts(limit: $limit, offset: $offset, where: $where, orderBy: $orderBy) {
      ...AccountsListPageAccountsFragment
    }
    accountsAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`)

export const ACCOUNTS_LIST_PAGE_DATA_BY_RECENTLY_VIEWED_QUERY =
  gql(/* GraphQL */ `
    query AccountsListPageDataByRecentlyViewed(
      $where: AccountsBoolExp!
      $companyGuid: uuid!
      $userGuid: uuid!
      $limit: Int!
      $offset: Int!
    ) {
      accounts: accountsSortedByRecentlyViewedForUser(
        where: $where
        args: { _company_guid: $companyGuid, _user_guid: $userGuid }
        limit: $limit
        offset: $offset
      ) {
        ...AccountsListPageAccountsFragment
      }
      accountsAggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `)

export const ACCOUNTS_LIST_PAGE_COUNT_ACCOUNTS_QUERY = gql(/* GraphQL */ `
  query AccountsListPageTotalAccounts($where: AccountsBoolExp!) {
    accountsAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`)
