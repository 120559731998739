import {
  BzDateFns,
  ESTIMATE_V2_STATUSES,
  EstimateV2Status,
  JOBS_V2_CLASSES,
  OfficeRoutes,
  R,
  UserGuid,
  formatAbbreviatedUsc,
  formatUsc,
  isNullish,
  jobClassDisplayNames,
  streetAddressLine1And2Condensed,
} from '@breezy/shared'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Popover } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from 'urql'
import { ContactCell } from '../../adam-components/ListPage/ContactCell'
import { DetailsCell } from '../../adam-components/ListPage/DetailsCell'
import { ListPageContainer } from '../../adam-components/ListPage/ListPageContainer'
import { ListPageFilter } from '../../adam-components/ListPage/ListPageFilter'
import { ListPageFilterBar } from '../../adam-components/ListPage/ListPageFilterBar'
import {
  ListPageTable,
  ListPageTableColsConfig,
  usePagination,
} from '../../adam-components/ListPage/ListPageTable'
import { SummaryStatBox } from '../../adam-components/ListPage/SummaryStats'
import { TeamMemberCell } from '../../adam-components/ListPage/TeamMemberCell'
import {
  OnsiteConfirmModal,
  OnsiteModal,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import { useDownloadableInvoice } from '../../components/DownloadableInvoice/useDownloadableInvoice'
import { BaseLoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'
import { Page } from '../../components/Page/Page'
import { Link } from '../../elements/Link/Link'
import StatusTag from '../../elements/StatusTag/StatusTag'
import {
  EstimatesBoolExp,
  EstimatesOrderBy,
  GetEstimateListQuery,
} from '../../generated/user/graphql'
import { trpc } from '../../hooks/trpc'
import { useIsLargeScreen } from '../../hooks/useIsMobile'
import {
  useExpectedCompany,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import {
  useModalState,
  useQueryParamState,
  useQueryParamStateArray,
  useQueryParamStateEnumArray,
  useQueryParamStateWithOptions,
} from '../../utils/react-utils'
import { DELETE_ESTIMATE_QUERY } from '../EstimatesFlow/EstimatesFlow.gql'
import { DownloadableEstimate } from '../EstimatesFlow/components/DownloadableEstimate'
import { SelectOptionForm } from '../EstimatesFlow/components/SelectOptionForm'
import {
  EstimateDataContext,
  EstimatesContext,
  EstimatesContextType,
  RelevantEstimateData,
  getEstimateV2StatusDisplayInfo,
  useEstimateStatusUpdater,
  useOptionsFromEstimateQuery,
  useRelevantEstimateData,
} from '../EstimatesFlow/estimatesFlowUtils'
import {
  ESTIMATE_LISTING_DOWNLOAD_PDF_DATA_QUERY,
  ESTIMATE_LISTING_OPTIONS_QUERY,
  ESTIMATE_LISTING_QUERY,
  ESTIMATE_LISTING_USERS_QUERY,
} from './EstimatesListing.gql'

type OptionRectangleProps = React.PropsWithChildren<{
  littleContent?: React.ReactNode
  popoverContent?: React.ReactNode
  isAccepted?: boolean
}>

const OptionRectangle = React.memo<OptionRectangleProps>(
  ({ children, littleContent, popoverContent, isAccepted }) => {
    const content = (
      <div
        className={classNames(
          'rounded-md border border-solid px-2 py-1 transition-colors ease-in-out',
          isAccepted
            ? 'border-bz-green-400 bg-bz-green-100 text-bz-green-900'
            : 'border-bz-gray-500 ',
          {
            'hover:border-bz-gray-700': popoverContent,
          },
        )}
      >
        {isNullish(littleContent) ? null : (
          <span
            className={classNames('text-xs font-semibold text-bz-gray-700', {
              'mr-2': children,
            })}
          >
            {littleContent}
          </span>
        )}
        {children}
      </div>
    )
    if (popoverContent) {
      return <Popover content={popoverContent}>{content}</Popover>
    }
    return content
  },
)

type EstimateActionsDropdownProps =
  GetEstimateListQuery['estimates'][number] & {
    refetch: () => unknown
    relevantEstimateData: RelevantEstimateData
  }

const EstimateActionsDropdown = React.memo<EstimateActionsDropdownProps>(
  ({
    estimateGuid,
    status,
    job,
    displayId,
    createdAt,
    refetch: externalRefetch,
    relevantEstimateData,
  }) => {
    const message = useMessage()
    const company = useExpectedCompany()
    const navigate = useNavigate()

    const downloadableElementRef = useRef<HTMLDivElement>(null)

    const [downloadEstimate, estimateDownloading] = useDownloadableInvoice(
      downloadableElementRef,
    )

    const [{ data: pdfData, fetching: fetchingPdfData }, fetchPdfData] =
      useQuery({
        query: ESTIMATE_LISTING_DOWNLOAD_PDF_DATA_QUERY,
        pause: true,
        variables: {
          estimateGuid,
        },
      })

    const [
      { data: optionsData, fetching: fetchingOptionsData },
      refetchOptions,
    ] = useQuery({
      query: ESTIMATE_LISTING_OPTIONS_QUERY,
      variables: {
        estimateGuid,
      },
    })

    // Fun bug: if you accept an estimate, then go to change the selected option, it doesn't know which one you
    // selected. So we need to make sure when we refresh, we refresh this too.
    const refetch = useCallback(() => {
      externalRefetch()
      refetchOptions()
    }, [externalRefetch, refetchOptions])

    const options = useOptionsFromEstimateQuery(
      optionsData?.estimatesByPk?.estimateOptions,
    )

    const [downloadPdfState, setDownloadPdfState] = useState<
      'none' | 'save' | 'print'
    >('none')

    useEffect(() => {
      if (downloadPdfState === 'none') {
        return
      }
      if (!pdfData) {
        return
      }
      if (pdfData.estimatesByPk) {
        setDownloadPdfState('none')
        downloadEstimate({
          fileName: `estimate-${displayId}`,
          openInNewTab: downloadPdfState === 'print',
        })
      } else {
        message.error('Could not download PDF. Try again later.')
      }
    }, [
      displayId,
      downloadEstimate,
      downloadPdfState,
      fetchingPdfData,
      message,
      pdfData,
    ])

    const onDownloadClick = useCallback(
      (type: typeof downloadPdfState) => {
        fetchPdfData()
        setDownloadPdfState(type)
      },
      [fetchPdfData],
    )

    const [deleteConfirmOpen, openDeleteConfirm, closeDeleteConfirm] =
      useModalState()

    const [{ fetching: deleteLoading }, deleteEstimate] = useMutation(
      DELETE_ESTIMATE_QUERY,
    )

    const onDelete = useCallback(() => {
      deleteEstimate({
        estimateGuid,
      })
      refetch()
    }, [deleteEstimate, estimateGuid, refetch])

    const [updateStatus, statusUpdating] = useEstimateStatusUpdater(status)

    const [statusUpdatingTo, setStatusUpdatingTo] = useState<
      EstimateV2Status | undefined
    >(undefined)

    const [selectOptionFormMode, setSelectedOptionFormMode] = useState<
      'closed' | 'accept' | 'change'
    >('closed')

    const closeSelectOptionForm = useCallback(
      () => setSelectedOptionFormMode('closed'),
      [],
    )

    const dropdownItems = useMemo(() => {
      const { markAsOptions, canDelete } =
        getEstimateV2StatusDisplayInfo(status)
      const items: ItemType[] = []
      if (!['VOIDED', 'CLOSED', 'EXPIRED'].includes(status)) {
        items.push({
          key: 'Edit',
          label: (
            <div onClick={() => navigate(`/estimates/${estimateGuid}/edit`)}>
              Edit
            </div>
          ),
        })
      }
      items.push({
        key: 'Download PDF',
        label: <div onClick={() => onDownloadClick('save')}>Download PDF</div>,
      })
      items.push({
        key: 'Print',
        label: <div onClick={() => onDownloadClick('print')}>Print</div>,
      })
      if (markAsOptions) {
        if (items.length > 0) {
          items.push({
            type: 'divider',
          })
        }
        items.push({
          key: 'change status',
          label: 'Change Status',
          children: markAsOptions.map(newStatus => ({
            key: newStatus,
            label: (
              <div
                onClick={() =>
                  newStatus === 'ACCEPTED'
                    ? setSelectedOptionFormMode('accept')
                    : setStatusUpdatingTo(newStatus)
                }
              >
                {getEstimateV2StatusDisplayInfo(newStatus).label}
              </div>
            ),
          })),
        })
        if (status === 'ACCEPTED') {
          items.push({
            key: 'change option',
            label: (
              <div onClick={() => setSelectedOptionFormMode('change')}>
                Change Selected Option...
              </div>
            ),
          })
        }
      }
      if (canDelete) {
        if (items.length > 0) {
          items.push({
            type: 'divider',
          })
        }
        items.push({
          key: 'delete',
          label: (
            <div onClick={openDeleteConfirm} className="text-bz-red-500">
              Delete
            </div>
          ),
        })
      }
      return items
    }, [estimateGuid, navigate, onDownloadClick, openDeleteConfirm, status])
    const loading =
      estimateDownloading ||
      fetchingPdfData ||
      fetchingOptionsData ||
      statusUpdating

    return (
      <>
        <Dropdown
          disabled={loading}
          className="mb-4"
          menu={{
            items: dropdownItems,
          }}
        >
          <Button
            shape="circle"
            disabled={loading}
            icon={
              loading ? (
                <BaseLoadingSpinner size={5} />
              ) : (
                <FontAwesomeIcon icon={faEllipsis} />
              )
            }
          />
        </Dropdown>
        {deleteConfirmOpen && (
          <OnsiteConfirmModal
            danger
            header="Delete estimate?"
            onCancel={closeDeleteConfirm}
            onConfirm={onDelete}
            confirmText="Delete Estimate"
            loading={deleteLoading}
          >
            Are you sure you want to delete this estimate?
          </OnsiteConfirmModal>
        )}
        {statusUpdatingTo && (
          <OnsiteConfirmModal
            header="Change estimate status"
            onCancel={() => setStatusUpdatingTo(undefined)}
            onConfirm={async () => {
              await updateStatus(statusUpdatingTo, true)
              setStatusUpdatingTo(undefined)
            }}
            confirmText="Change Status"
            loading={statusUpdating}
          >
            Are you sure you want to change the status to "
            {getEstimateV2StatusDisplayInfo(statusUpdatingTo).label}"?
          </OnsiteConfirmModal>
        )}
        {selectOptionFormMode !== 'closed' && (
          <OnsiteModal drawer onClose={closeSelectOptionForm} open>
            <SelectOptionForm
              onClose={closeSelectOptionForm}
              onSuccess={refetch}
              changeMode={selectOptionFormMode === 'change'}
              options={options}
              estimateGuid={estimateGuid}
              accountGuid={job.accountGuid}
              displayId={displayId}
              contactFirstName={job.pointOfContact.firstName}
              emailAddress={
                job.pointOfContact.primaryEmailAddress?.emailAddress
              }
              phoneNumber={job.pointOfContact.primaryPhoneNumber?.phoneNumber}
            />
          </OnsiteModal>
        )}
        {pdfData?.estimatesByPk && options && (
          <EstimateDataContext.Provider
            value={{
              messageHtml: pdfData.estimatesByPk.messageHtml ?? '',
              taxRate: {
                pricebookTaxRateGuid: pdfData.estimatesByPk.taxRateGuid,
                rate: pdfData.estimatesByPk.taxRate,
                name: pdfData.estimatesByPk.taxRateName,
              },
              options,
              photoRecords: [],
              dynamicPricingType: pdfData.estimatesByPk.dynamicPricingType,
            }}
          >
            <DownloadableEstimate
              downloadableElementRef={downloadableElementRef}
              messageHtml={pdfData.estimatesByPk.messageHtml}
              jobGuid={job.jobGuid}
              displayId={displayId}
              createdAt={createdAt}
              status={status}
              options={options}
              companyName={company.name}
              contactFullName={
                job.pointOfContact.fullName ?? 'the account holder'
              }
              signatureFileUrl={pdfData.estimatesByPk.signatureFileUrl}
              acceptedOnBehalfByUser={
                pdfData.estimatesByPk.acceptedOnBehalfByUser
              }
              acceptedAt={pdfData.estimatesByPk.acceptedAt}
              disclaimer={relevantEstimateData.disclaimer}
            />
          </EstimateDataContext.Provider>
        )}
      </>
    )
  },
)

type EstimateRowWrapperProps = React.PropsWithChildren<{
  estimate: GetEstimateListQuery['estimates'][number]
  refetch: () => unknown
  relevantEstimateData: RelevantEstimateData
}>

const EstimateRowWrapper = React.memo<EstimateRowWrapperProps>(
  ({ estimate, children, refetch, relevantEstimateData }) => {
    const { job, estimateGuid, jobAppointmentGuid } = estimate

    const estimateData = useMemo<EstimatesContextType>(
      () => ({
        estimateGuid,
        jobGuid: job.jobGuid,
        accountGuid: job.accountGuid,
        locationGuid: job.location.locationGuid,
        jobAppointmentGuid,
        refetch,
        ...relevantEstimateData,
      }),
      [
        estimateGuid,
        job.accountGuid,
        job.jobGuid,
        job.location.locationGuid,
        jobAppointmentGuid,
        refetch,
        relevantEstimateData,
      ],
    )

    return (
      <EstimatesContext.Provider value={estimateData}>
        {children}
      </EstimatesContext.Provider>
    )
  },
)

type EstimateDetailsCellProps = {
  estimate: GetEstimateListQuery['estimates'][number]
  refetch: () => unknown
  relevantEstimateData: RelevantEstimateData
}

const EstimateDetailsCell = React.memo<EstimateDetailsCellProps>(
  ({ refetch, relevantEstimateData, estimate }) => {
    const { estimateOptions, displayId, job, status, estimateGuid } = estimate

    const isLargeScreen = useIsLargeScreen()

    const numOptionRectangles =
      status === 'ACCEPTED' ? 1 : isLargeScreen ? 3 : 1

    const [shownOptions, moreOptions] = useMemo(() => {
      if (status === 'ACCEPTED') {
        const selectedIndex = estimateOptions.findIndex(
          option => option.isSelected,
        )
        return [
          [estimateOptions[selectedIndex]],
          R.remove(selectedIndex, 1, estimateOptions),
        ]
      }
      const numOptionRectangles = isLargeScreen ? 3 : 1

      return [
        estimateOptions.slice(0, numOptionRectangles),
        estimateOptions.slice(numOptionRectangles),
      ]
    }, [estimateOptions, isLargeScreen, status])

    const footer = useMemo(
      () => (
        <div className="flex flex-row space-x-2">
          {shownOptions.map(option => (
            <OptionRectangle
              key={option.optionGuid}
              littleContent={option.seq + 1}
              isAccepted={status === 'ACCEPTED'}
            >
              {formatUsc(option.totalUsc)}
            </OptionRectangle>
          ))}
          {moreOptions.length > 0 && (
            <OptionRectangle
              littleContent={`+${estimateOptions.length - numOptionRectangles}`}
              popoverContent={
                <div>
                  <div className="mb-2 font-semibold">Additional options</div>
                  <div className="flex flex-row space-x-2">
                    {moreOptions.map(option => (
                      <OptionRectangle
                        key={option.optionGuid}
                        littleContent={option.seq + 1}
                      >
                        {formatUsc(option.totalUsc)}
                      </OptionRectangle>
                    ))}
                  </div>
                </div>
              }
            />
          )}
        </div>
      ),
      [
        estimateOptions.length,
        moreOptions,
        numOptionRectangles,
        shownOptions,
        status,
      ],
    )

    const detailItems = useMemo(
      () => [
        <Link blue={false} bold={false} to={`/accounts/${job.accountGuid}`}>
          {job.pointOfContact.fullName}
        </Link>,
        <Link blue={false} bold={false} to={`/jobs/${job.jobGuid}`}>
          {job.jobType.name} (#{job.displayId})
        </Link>,
        <Link
          blue={false}
          bold={false}
          to={`/locations/${job.location.locationGuid}`}
        >
          {streetAddressLine1And2Condensed(
            job.location.address.line1,
            job.location.address.line2,
          )}
        </Link>,
      ],
      [
        job.accountGuid,
        job.displayId,
        job.jobGuid,
        job.jobType.name,
        job.location.address.line1,
        job.location.address.line2,
        job.location.locationGuid,
        job.pointOfContact.fullName,
      ],
    )

    return (
      <DetailsCell
        dropdown={
          <EstimateActionsDropdown
            {...estimate}
            refetch={refetch}
            relevantEstimateData={relevantEstimateData}
          />
        }
        link={{
          to: OfficeRoutes.ESTIMATE_V2_DETAILS.build({
            params: { estimateGuid },
          }),
          label: `#${displayId}`,
        }}
        detailItems={detailItems}
        footer={footer}
      />
    )
  },
)

const FILTER_LABELS = {
  jobClasses: 'Job Class',
  status: 'Estimate Status',
  users: 'Created by',
}

const JOBS_V2_CLASS_OPTIONS = JOBS_V2_CLASSES.map(key => ({
  key,
  label: jobClassDisplayNames[key],
}))

const ESTIMATE_STATUS_OPTIONS = ESTIMATE_V2_STATUSES.filter(
  // TODO: https://getbreezyapp.atlassian.net/browse/BZ-1491
  status => status !== 'EXPIRED',
).map(key => ({
  key,
  label: getEstimateV2StatusDisplayInfo(key).label,
}))

const SORT_TYPES = ['NEWEST', 'OLDEST', 'TOTAL', 'CREATED_BY'] as const

type SortType = (typeof SORT_TYPES)[number]

const PRETTY_SORT_TYPE_MAP: Record<SortType, string> = {
  NEWEST: 'Issue Date (Newest)',
  OLDEST: 'Issue Date (Oldest)',
  TOTAL: 'Dollar Amount (Highest)',
  CREATED_BY: 'Created By (A-Z)',
}

const SORT_TYPE_OPTIONS = SORT_TYPES.map(type => ({
  key: type,
  label: PRETTY_SORT_TYPE_MAP[type],
}))

const START_DATE_TYPES = [
  'LAST_7_DAYS',
  'LAST_30_DAYS',
  'LAST_90_DAYS',
  'LAST_6_MONTHS',
  'ALL_TIME',
] as const

type StartDateType = (typeof START_DATE_TYPES)[number]

const PRETTY_START_DATE_MAP: Record<StartDateType, string> = {
  LAST_7_DAYS: 'Last 7 days',
  LAST_30_DAYS: 'Last 30 days',
  LAST_90_DAYS: 'Last 90 days',
  LAST_6_MONTHS: 'Last 6 months',
  ALL_TIME: 'All time',
}

const START_DATE_OPTIONS = START_DATE_TYPES.map(type => ({
  key: type,
  label: PRETTY_START_DATE_MAP[type],
}))

export const EstimatesV2ListPage = React.memo(() => {
  const { page, pageSize, setPage, setPageSize, resetPage, limit, offset } =
    usePagination('50')

  const [startDateOption, setStartDateOption] = useQueryParamStateWithOptions(
    'created',
    'LAST_90_DAYS',
    START_DATE_TYPES,
  )

  const startDate = useMemo(
    () =>
      startDateOption === 'ALL_TIME'
        ? undefined
        : BzDateFns.nowUtcTransform(date => {
            switch (startDateOption) {
              case 'LAST_7_DAYS':
                return BzDateFns.startOfDay(BzDateFns.subDays(date, 7))
              case 'LAST_30_DAYS':
                return BzDateFns.startOfDay(BzDateFns.subDays(date, 30))
              case 'LAST_90_DAYS':
                return BzDateFns.startOfDay(BzDateFns.subDays(date, 90))
              case 'LAST_6_MONTHS':
                return BzDateFns.startOfDay(BzDateFns.subMonths(date, 6))
            }
          }),
    [startDateOption],
  )

  const [searchTerm, setSearchTerm] = useQueryParamState('search', '')

  const [selectedJobClasses, setSelectedJobClasses] =
    useQueryParamStateEnumArray('jobClass', [], JOBS_V2_CLASSES)

  const [selectedStatuses, setSelectedStatuses] = useQueryParamStateEnumArray(
    'status',
    [],
    ESTIMATE_V2_STATUSES,
  )

  const [selectedUserGuids, setSelectedUserGuids] =
    useQueryParamStateArray<UserGuid>('users', [])

  const [sortType, setSortTypeRaw] = useQueryParamStateWithOptions(
    'sort',
    SORT_TYPES[0],
    SORT_TYPES,
  )

  const setSortType = useCallback(
    (type: SortType) => {
      setSortTypeRaw(type)
      resetPage()
    },
    [resetPage, setSortTypeRaw],
  )

  const orderByClause = useMemo<EstimatesOrderBy>(() => {
    switch (sortType) {
      case 'NEWEST':
        return { createdAt: 'DESC' }
      case 'OLDEST':
        return { createdAt: 'ASC' }
      case 'TOTAL':
        return { estimateOptionsAggregate: { max: { totalUsc: 'DESC' } } }
      case 'CREATED_BY':
        return { createdByUser: { firstName: 'ASC' } }
    }
  }, [sortType])

  const whereClause = useMemo<EstimatesBoolExp>(() => {
    if (searchTerm) {
      const _ilike = `%${searchTerm}%`

      return {
        _or: [
          {
            displayIdString: { _ilike },
          },
          {
            job: { displayIdString: { _ilike } },
          },
          {
            job: {
              pointOfContact: {
                fullName: { _ilike },
              },
            },
          },
          {
            job: {
              jobType: {
                name: { _ilike },
              },
            },
          },
          {
            job: {
              location: {
                address: {
                  canonicalFullAddress: { _ilike },
                },
              },
            },
          },
          {
            createdByUser: {
              fullName: { _ilike },
            },
          },
          {
            createdByUser: {
              emailAddress: { _ilike },
            },
          },
        ],
      }
    }
    const whereClause: EstimatesBoolExp = {}
    if (startDate) {
      whereClause.createdAt = { _gte: startDate }
    }
    if (selectedJobClasses.length) {
      whereClause.job = {
        jobType: {
          jobClass: { _in: selectedJobClasses },
        },
      }
    }
    if (selectedStatuses.length) {
      whereClause.status = { _in: selectedStatuses }
    }
    if (selectedUserGuids.length) {
      whereClause.createdBy = { _in: selectedUserGuids }
    }
    return whereClause
  }, [
    searchTerm,
    selectedJobClasses,
    selectedStatuses,
    selectedUserGuids,
    startDate,
  ])

  const [{ data, fetching }, refetchListingData] = useQuery({
    query: ESTIMATE_LISTING_QUERY,
    variables: {
      limit,
      offset,
      orderBy: orderByClause,
      whereClause,
    },
  })

  const [{ data: userData, fetching: fetchingUsers }] = useQuery({
    query: ESTIMATE_LISTING_USERS_QUERY,
  })

  const userOptions = useMemo(
    () =>
      userData?.companyUsers.map(({ userGuid, userByUserGuid }) => ({
        key: userGuid,
        label: userByUserGuid.fullName,
      })) ?? [],
    [userData?.companyUsers],
  )

  const userGuidToNameMap = useMemo(
    () =>
      userData?.companyUsers.reduce(
        (map, { userGuid, userByUserGuid }) => ({
          ...map,
          [userGuid]: userByUserGuid.fullName ?? userGuid,
        }),
        {} as Record<UserGuid, string>,
      ) ?? {},
    [userData?.companyUsers],
  )

  const statsQuery = trpc.invoice[
    'invoicing:estimatev2:summary-stats'
  ].useQuery({
    startDate,
    createdByGuids: selectedUserGuids,
    jobClasses: selectedJobClasses,
    statuses: selectedStatuses,
  })

  const relevantEstimateData = useRelevantEstimateData()

  const refetch = useCallback(() => {
    refetchListingData()
    statsQuery.refetch()
  }, [refetchListingData, statsQuery])

  const filters = useMemo(() => {
    const filters: { key: string; optionsSelected: string[] }[] = []
    if (selectedJobClasses.length) {
      filters.push({
        key: 'jobClasses',
        optionsSelected: selectedJobClasses.map(
          jobClass => jobClassDisplayNames[jobClass],
        ),
      })
    }
    if (selectedStatuses.length) {
      filters.push({
        key: 'status',
        optionsSelected: selectedStatuses.map(
          status => getEstimateV2StatusDisplayInfo(status).label,
        ),
      })
    }

    if (selectedUserGuids.length) {
      filters.push({
        key: 'users',
        optionsSelected: selectedUserGuids.map(guid => userGuidToNameMap[guid]),
      })
    }

    return filters
  }, [
    selectedJobClasses,
    selectedStatuses,
    selectedUserGuids,
    userGuidToNameMap,
  ])

  const hasFilters = useMemo(
    () =>
      selectedJobClasses.length +
        selectedUserGuids.length +
        selectedStatuses.length >
      0,
    [
      selectedJobClasses.length,
      selectedStatuses.length,
      selectedUserGuids.length,
    ],
  )

  const clearAllFilters = useCallback(() => {
    setSelectedJobClasses([])
    setSelectedStatuses([])
    setSelectedUserGuids([])
  }, [setSelectedJobClasses, setSelectedStatuses, setSelectedUserGuids])

  const clearFilter = useCallback(
    (filterKey: string, option: string) => {
      switch (filterKey) {
        case 'jobClasses':
          setSelectedJobClasses(
            selectedJobClasses.filter(
              jc => jobClassDisplayNames[jc] !== option,
            ),
          )
          break
        case 'status':
          setSelectedStatuses(
            selectedStatuses.filter(
              status => getEstimateV2StatusDisplayInfo(status).label !== option,
            ),
          )
          break
        case 'users':
          setSelectedUserGuids(
            selectedUserGuids.filter(
              guid => userGuidToNameMap[guid] !== option,
            ),
          )
          break
      }
    },
    [
      selectedJobClasses,
      selectedStatuses,
      selectedUserGuids,
      setSelectedJobClasses,
      setSelectedStatuses,
      setSelectedUserGuids,
      userGuidToNameMap,
    ],
  )

  const tzId = useExpectedCompanyTimeZoneId()

  const cols = useMemo<
    ListPageTableColsConfig<GetEstimateListQuery['estimates'][number]>
  >(
    () => [
      {
        header: 'Details',
        flex: 1,
        minWidth: 300,
        render: estimate => (
          <EstimateDetailsCell
            estimate={estimate}
            refetch={refetch}
            relevantEstimateData={relevantEstimateData}
          />
        ),
      },
      {
        header: 'Status',
        cellClassName: 'align-middle',
        minWidth: 150,
        render: ({ status }) => {
          const statusInfo = getEstimateV2StatusDisplayInfo(status)
          return (
            <StatusTag
              color={statusInfo.statusTagColor}
              text={statusInfo.label}
            />
          )
        },
      },
      {
        header: 'Contact',
        minWidth: 250,
        render: ({ job }) => <ContactCell job={job} account={job.account} />,
      },
      {
        header: 'Created Date',
        minWidth: 180,
        render: ({ createdAt }) => (
          <>
            {BzDateFns.format(
              BzDateFns.parseISO(createdAt, tzId),
              'MMM d, yyyy',
            )}
          </>
        ),
      },

      {
        header: 'Created By',
        minWidth: 120,
        render: ({ createdByUser }) => <TeamMemberCell user={createdByUser} />,
      },
    ],
    [refetch, relevantEstimateData, tzId],
  )

  const renderRowWrapper = useCallback(
    (
      estimate: GetEstimateListQuery['estimates'][number],
      children: React.ReactNode,
    ) => (
      <EstimateRowWrapper
        estimate={estimate}
        refetch={refetch}
        relevantEstimateData={relevantEstimateData}
      >
        {children}
      </EstimateRowWrapper>
    ),
    [refetch, relevantEstimateData],
  )

  const totalItems = data?.estimatesAggregate.aggregate?.count ?? 0

  return (
    <Page requiresCompanyUser className="flex min-w-[1024px] overflow-auto">
      <ListPageContainer
        summaryStats={
          <>
            <SummaryStatBox
              label="Outstanding"
              tooltipContent="Outstanding estimates include those that have a status of “Created”, “Sent”, or “Reviewed”."
              doubleWide
              bottomBarColorClasses={[
                'bg-bz-purple-300',
                'bg-daybreak-blue-300',
                'bg-bz-cyan-300',
              ]}
              loading={statsQuery.isFetching}
              secondaryContent={statsQuery.data?.outstanding.count ?? 0}
            >
              {formatAbbreviatedUsc(statsQuery.data?.outstanding.totalUsc ?? 0)}
            </SummaryStatBox>
            {/* TODO: https://getbreezyapp.atlassian.net/browse/BZ-1491 */}
            {/* <SummaryStatBox
              label="Expired"
              tooltipContent="Estimates that have been inactive for more than 30 days"
              bottomBarColorClasses={['bg-bz-orange-300']}
              loading={statsQuery.isFetching}
              secondaryContent={statsQuery.data?.expired.count ?? 0}
            >
              {formatAbbreviatedUsc(statsQuery.data?.expired.totalUsc ?? 0)}
            </SummaryStatBox> */}
            <SummaryStatBox
              label="Accepted"
              tooltipContent="Estimates that have been accepted by or on the behalf of the customer."
              bottomBarColorClasses={['bg-bz-green-300']}
              loading={statsQuery.isFetching}
              secondaryContent={statsQuery.data?.accepted.count ?? 0}
            >
              {formatAbbreviatedUsc(statsQuery.data?.accepted.totalUsc ?? 0)}
            </SummaryStatBox>
            <SummaryStatBox
              label="Voided"
              tooltipContent="Estimates that have been voided."
              bottomBarColorClasses={['bg-bz-red-300']}
              loading={statsQuery.isFetching}
              secondaryContent={statsQuery.data?.voided.count ?? 0}
            >
              {formatAbbreviatedUsc(statsQuery.data?.voided.totalUsc ?? 0)}
            </SummaryStatBox>
            <SummaryStatBox
              label="Closed - Lost"
              tooltipContent="Estimates that have been manually marked as “Lost”."
              bottomBarColorClasses={['bg-bz-gray-500']}
              loading={statsQuery.isFetching}
              secondaryContent={statsQuery.data?.closed.count ?? 0}
            >
              {formatAbbreviatedUsc(statsQuery.data?.closed.totalUsc ?? 0)}
            </SummaryStatBox>
          </>
        }
        filterBar={
          <ListPageFilterBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            secondaryFilters={
              <ListPageFilter
                options={START_DATE_OPTIONS}
                value={startDateOption}
                valueLabel={PRETTY_START_DATE_MAP[startDateOption]}
                onChange={setStartDateOption}
              />
            }
            totalItems={totalItems}
            sortByControl={
              <ListPageFilter
                label="Sort by"
                options={SORT_TYPE_OPTIONS}
                value={sortType}
                onChange={setSortType}
              />
            }
            hasFilters={hasFilters}
            filters={filters}
            filterLabels={FILTER_LABELS}
            clearAllFilters={clearAllFilters}
            clearFilter={clearFilter}
          >
            <ListPageFilter
              multi
              label="Job Class"
              options={JOBS_V2_CLASS_OPTIONS}
              value={selectedJobClasses}
              onChange={setSelectedJobClasses}
            />
            <ListPageFilter
              multi
              label="Status"
              options={ESTIMATE_STATUS_OPTIONS}
              value={selectedStatuses}
              onChange={setSelectedStatuses}
            />
            <ListPageFilter
              multi
              disabled={fetchingUsers}
              label="Created by"
              options={userOptions}
              value={selectedUserGuids}
              onChange={setSelectedUserGuids}
            />
          </ListPageFilterBar>
        }
        table={
          <ListPageTable
            data={data?.estimates}
            fetching={fetching || relevantEstimateData.isFetching}
            cols={cols}
            rowKey="estimateGuid"
            page={page}
            pageSize={pageSize}
            totalItems={totalItems}
            setPage={setPage}
            setPageSize={setPageSize}
            hasFilters={hasFilters || !!searchTerm}
            itemsDescriptor="estimates"
            renderRowWrapper={renderRowWrapper}
          />
        }
      />
    </Page>
  )
})
