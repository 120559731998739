import type { PickerTimeProps } from 'antd/es/date-picker/generatePicker'
import * as React from 'react'
import DatePicker from '../../components/DatePicker/DatePicker'

export type TimePickerProps = Omit<PickerTimeProps<Date>, 'picker'>

export const BzTimePicker = React.forwardRef<unknown, TimePickerProps>(
  (props, ref) => (
    <DatePicker {...props} picker="time" mode={undefined} ref={ref} />
  ),
)

BzTimePicker.displayName = 'TimePicker'

export default BzTimePicker
