import React from 'react'
import { Link } from 'react-router-dom'

type ViewResourceButtonProps = {
  children?: React.ReactNode
} & (
  | {
      linkTo: string
      onClick?: never
    }
  | {
      linkTo?: never
      onClick: () => void
    }
)

export const ViewResourceButton = React.memo<ViewResourceButtonProps>(
  ({ children = 'View', linkTo, onClick }) => {
    const className =
      'view-details-button regular_14_22 text cursor-pointer rounded border-[0.75px] border-solid border-gray-400 bg-[#ffffff] pl-2 pr-2 text-bz-gray-800 hover:bg-[#f4f4f4]'
    return linkTo ? (
      <Link to={linkTo} className={className}>
        {children}
      </Link>
    ) : (
      <div onClick={onClick} className={className}>
        {children}
      </div>
    )
  },
)
