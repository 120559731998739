import {
  Guid,
  JobLifecycleView,
  OfficeRoutes,
  isNullish,
  isNullishOrEmpty,
} from '@breezy/shared'
import { createContext, useCallback, useContext, useMemo } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { trpc } from '../../hooks/trpc'
import { usePrincipalUser } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'

// The default view that shows all jobs
export const JOBS_VIEWS_ALL_JOBS: JobLifecycleView = {
  jobLifecycleViewGuid: '',
  title: 'All Jobs',
  view: {
    filters: [],
    filtersV2: '',
  },
}

export const DEFAULT_JOBS_VIEWS_GUID = JOBS_VIEWS_ALL_JOBS.jobLifecycleViewGuid

export type JobsViewsContextProps = {
  jobsViews: JobLifecycleView[]
  selectedJobsView: JobLifecycleView
  setSelectedJobsViewName: (guid: Guid) => void
  upsertJobsView: (view: JobLifecycleView) => Promise<void>
  deleteJobsView: (jobsViewGuid: Guid) => Promise<void>
  isLoading: boolean
}

export const JobsViewsContext = createContext<JobsViewsContextProps>({
  jobsViews: [],
  selectedJobsView: JOBS_VIEWS_ALL_JOBS,
  setSelectedJobsViewName: () => {},
  upsertJobsView: () => Promise.resolve(),
  deleteJobsView: () => Promise.resolve(),
  isLoading: false,
})

export const useJobsViewsContext = () => useContext(JobsViewsContext)

export const useJobsViewsContextValue = (): JobsViewsContextProps => {
  const message = useMessage()
  const { principal } = usePrincipalUser()

  const location = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()

  const jobsViewsQuery = trpc.jobLifecycles[
    'job-lifecycles:views:get'
  ].useQuery(undefined, {
    enabled:
      !isNullishOrEmpty(principal?.company?.companyGuid) &&
      location.pathname === OfficeRoutes.JOBS_V2.path,
  })

  const selectedJobsView = useMemo(() => {
    const viewParam = searchParams.get('view')
    if (!isNullish(viewParam)) {
      const viewName = viewParam.trim()
      if (viewName !== '') {
        return (
          jobsViewsQuery.data?.find(view => view.title === viewName) ??
          JOBS_VIEWS_ALL_JOBS
        )
      }
    }

    return JOBS_VIEWS_ALL_JOBS
  }, [jobsViewsQuery.data, searchParams])

  const setSelectedJobsView = useCallback(
    (viewName: string) => {
      if (viewName === '') {
        setSearchParams({})
      } else {
        setSearchParams({
          view: viewName,
        })
      }
    },
    [setSearchParams],
  )

  const addJobsViewMutation = trpc.jobLifecycles[
    'job-lifecycles:views:upsert'
  ].useMutation({
    onSuccess: async (_, variables) => {
      await jobsViewsQuery.refetch()
      const newTitle = variables.title

      if (newTitle) {
        setSelectedJobsView(newTitle)
      }
      message.success('View saved')
    },
    onError: e => {
      console.error(e)
      message.error('Something went wrong')
    },
  })

  const deleteJobsViewMutation = trpc.jobLifecycles[
    'job-lifecycles:views:delete'
  ].useMutation({
    onSuccess: (_, variables) => {
      jobsViewsQuery.refetch()
      // If we were looking at the one we deleted, reset to the default. And we don't need to wait for the refetch
      // because we have everything we need.
      if (
        selectedJobsView.jobLifecycleViewGuid === variables.jobLifecycleViewGuid
      ) {
        setSelectedJobsView(JOBS_VIEWS_ALL_JOBS.title)
      }
      message.success('View deleted')
    },
    onError: e => {
      console.error(e)
      message.error('Something went wrong')
    },
  })

  const jobsViews = useMemo(
    () => [JOBS_VIEWS_ALL_JOBS, ...(jobsViewsQuery.data ?? [])],
    [jobsViewsQuery.data],
  )
  const upsertJobsView = useCallback(
    (view: JobLifecycleView) =>
      new Promise<void>(resolve => {
        addJobsViewMutation.mutate(view, {
          onSuccess: () => resolve(),
        })
      }),

    [addJobsViewMutation],
  )

  const deleteJobsView = useCallback(
    (jobsViewGuid: Guid) =>
      new Promise<void>(resolve => {
        deleteJobsViewMutation.mutate(
          {
            jobLifecycleViewGuid: jobsViewGuid,
          },
          {
            onSuccess: () => resolve(),
          },
        )
      }),

    [deleteJobsViewMutation],
  )

  return {
    jobsViews,
    selectedJobsView,
    setSelectedJobsViewName: setSelectedJobsView,
    upsertJobsView,
    deleteJobsView,
    isLoading:
      jobsViewsQuery.isLoading ||
      addJobsViewMutation.isLoading ||
      deleteJobsViewMutation.isLoading,
  }
}
