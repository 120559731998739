import {
  DiscountType,
  ForCompany,
  PricebookDiscountGuid,
  SavePricebookDiscountDto,
} from '@breezy/shared'
import { Button, Form } from 'antd'
import { useForm, useWatch } from 'antd/lib/form/Form'
import { useCallback, useState } from 'react'
import ThinDivider from '../../../../elements/ThinDivider'
import { trpc } from '../../../../hooks/trpc'
import { useExpectedCompany } from '../../../../providers/PrincipalUser'
import { useMessage } from '../../../../utils/antd-utils'
import { PricebookDiscountFormSchema } from '../../Utils/types'
import { usePricebookAdmin } from '../../hooks/usePricebookAdmin'
import { PricebookFormDiscountDescription } from '../FormItems/PricebookFormDiscountDescription'
import { PricebookFormDiscountName } from '../FormItems/PricebookFormDiscountName'
import { PricebookFormDiscountType } from '../FormItems/PricebookFormDiscountType'
import { PricebookFormItemActive } from '../FormItems/PricebookFormItemActive'
import { PricebookFormItemDiscountAmount } from '../FormItems/PricebookFormItemDiscountAmount'
import { PricebookFormItemDiscountRate } from '../FormItems/PricebookFormItemDiscountRate'

const DEFAULT_VALUES: Partial<PricebookDiscountFormSchema> = {
  name: '',
  description: '',
  type: DiscountType.RATE,
  discountRate: 0,
  discountAmountUsd: 0,
  isActive: true,
}

export type UpsertPricebookDiscountFormProps = {
  initialValues?: PricebookDiscountFormSchema & {
    pricebookDiscountGuid?: PricebookDiscountGuid
  }
}
export const UpsertPricebookDiscountForm = ({
  initialValues,
}: UpsertPricebookDiscountFormProps) => {
  const message = useMessage()
  const { pricebookDiscountMutated, closeDrawer } = usePricebookAdmin()
  const [pricebookDiscountGuid, setPricebookDiscountGuid] = useState(
    initialValues?.pricebookDiscountGuid,
  )
  const company = useExpectedCompany()
  const [form] = useForm<PricebookDiscountFormSchema>()
  const savePricebookItemMutation =
    trpc.pricebook['pricebook:save-discount'].useMutation()
  const typeValue = Form.useWatch('type', form)

  const onFinish = useCallback(
    (values: PricebookDiscountFormSchema) => {
      let payload
      if (values.type === DiscountType.FLAT) {
        payload = {
          pricebookDiscountGuid: pricebookDiscountGuid,
          companyGuid: company.companyGuid,
          name: values.name,
          description: values.description,
          type: DiscountType.FLAT,
          discountAmountUsd: values.discountAmountUsd ?? 0,
          discountRate: null,
          isActive: values.isActive,
        } satisfies ForCompany<SavePricebookDiscountDto>
      } else if (values.type === DiscountType.RATE) {
        payload = {
          pricebookDiscountGuid: pricebookDiscountGuid,
          companyGuid: company.companyGuid,
          name: values.name,
          description: values.description,
          type: DiscountType.RATE,
          discountAmountUsd: null,
          discountRate: (values.discountRate ?? 0) / 100,
          isActive: values.isActive,
        } satisfies ForCompany<SavePricebookDiscountDto>
      } else {
        throw new Error(`MissingCaseError: ${values.type}`)
      }

      savePricebookItemMutation.mutate(payload, {
        onSuccess: result => {
          if (pricebookDiscountGuid) {
            message.success('Successfully updated Discount in Pricebook')
          } else {
            message.success('Successfully added Discount to Pricebook')
          }

          setPricebookDiscountGuid(result.pricebookDiscountGuid)
          pricebookDiscountMutated()
        },
        onError: e => {
          if (pricebookDiscountGuid) {
            message.error(
              'There was an error updating the Pricebook Discount. Please try again.',
            )
          } else {
            message.error(
              'There was an error adding the Pricebook Discount. Please try again.',
            )
          }
        },
      })
    },
    [
      company.companyGuid,
      pricebookDiscountGuid,
      pricebookDiscountMutated,
      savePricebookItemMutation,
      message,
    ],
  )

  const onCancel = useCallback(() => {
    closeDrawer()
  }, [closeDrawer])
  const isActive = useWatch('isActive', form)

  const onActiveChange = useCallback(
    (checked: boolean) => {
      form.setFieldsValue({
        isActive: !checked,
      })
    },
    [form],
  )

  return (
    <Form
      form={form}
      initialValues={{
        ...DEFAULT_VALUES,
        ...(initialValues ?? {}),
      }}
      layout="vertical"
      validateTrigger="onBlur"
      onFinish={onFinish}
      disabled={savePricebookItemMutation.isLoading}
      className="grid grid-cols-2 gap-4"
    >
      <div className="col-span-2">
        <PricebookFormDiscountName />
      </div>
      <div className="col-span-2">
        <PricebookFormDiscountDescription />
      </div>
      <div className="col-span-2">
        <PricebookFormDiscountType />
      </div>
      {typeValue === DiscountType.RATE && (
        <div>
          <PricebookFormItemDiscountRate />
        </div>
      )}
      {typeValue === DiscountType.FLAT && (
        <div>
          <PricebookFormItemDiscountAmount />
        </div>
      )}

      <div className="col-span-2">
        <ThinDivider widthPx={8} />
      </div>
      <div className="col-span-2 flex items-center justify-between">
        <PricebookFormItemActive value={isActive} onChange={onActiveChange} />
        <div className="flex justify-end space-x-4">
          <Form.Item>
            <Button onClick={onCancel}>Cancel</Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {pricebookDiscountGuid ? 'Update' : 'Add'}
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  )
}
