import { BzAddress, Guid, StateAbbreviation, nextGuid } from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Collapse } from 'antd'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'urql'
import { trpc } from '../../../hooks/trpc.ts'
import {
  useExpectedCompany,
  useExpectedPrincipal,
} from '../../../providers/PrincipalUser.tsx'
import { useMessage } from '../../../utils/antd-utils.ts'
import {
  BILLING_PROFILE_UPSERT_MUTATION,
  BillingProfile,
} from '../BillingProfileSettingsPage.gql'
import {
  BillingProfileFormSchema,
  COMPANY_LOGO_MAX_SIZE,
  billingProfileFormSchema,
} from '../billing-profile-form'
import { resizeImage } from '../util'
import { BillingProfileSettingsBusinessInfoSection } from './BillingProfileSettingsBusinessInfoSection'
import { BillingProfileSettingsCompanyInfoSection } from './BillingProfileSettingsCompanyInfoSection'
import { BillingProfileSettingsInvoicesEstimatesSection } from './BillingProfileSettingsInvoicesEstimatesSection.tsx'

export type BillingProfileSettingsPageContentProps = {
  billingProfile: BillingProfile
  refresh: () => void
}

export const BillingProfileSettingsPageContent = ({
  billingProfile,
  refresh,
}: BillingProfileSettingsPageContentProps) => {
  const { companyGuid } = useExpectedCompany()
  const { userGuid } = useExpectedPrincipal()

  const message = useMessage()

  const form = useForm<BillingProfileFormSchema>({
    resolver: zodResolver(billingProfileFormSchema),
    defaultValues: {
      business: {
        fullLegalName: billingProfile.businessFullLegalName,
        contractorLicenseNumber: billingProfile.contractorLicenseNumber,
        emailAddress: billingProfile.emailAddress.emailAddress,
        phoneNumber: billingProfile.phoneNumber.phoneNumber,
        address: {
          line1: billingProfile.businessAddress?.line1,
          line2: billingProfile.businessAddress?.line2,
          zipCode: billingProfile.businessAddress?.zipCode,
          city: billingProfile.businessAddress?.city,
          stateAbbreviation: billingProfile.businessAddress
            ?.stateAbbreviation as StateAbbreviation,
        },
      },
      company: {
        logoUrl: billingProfile.logoUrl,
      },
      invoicesAndEstimates: {
        defaultTaxRateGuid: billingProfile.defaultPricebookTaxRateGuid,
        defaultInvoiceTerm: billingProfile.invoiceTerm,
        defaultInvoiceMemo: billingProfile.invoiceMemo,
        defaultEstimateDisclaimer: billingProfile.estimateDisclaimer,
        defaultInvoiceDisclaimer: billingProfile.invoiceDisclaimer,
      },
    },
  })

  const [billingProfileUpsertRes, billingProfileUpsertMutation] = useMutation(
    BILLING_PROFILE_UPSERT_MUTATION,
  )

  const tenantPublicFilesUpload =
    trpc.files['files:public:upload'].useMutation()
  const photosUpload = trpc.photos['photos:upload'].useMutation()

  const onSubmit = form.handleSubmit(async data => {
    const { business, company, invoicesAndEstimates } = data

    // We only need to upload these if they are different images. If they aren't don't reupload the same image.
    let companyLogoUrl: string = billingProfile.logoUrl
    let logoPhotoGuid: Guid = billingProfile.logoPhotoGuid
    if (company.logoUrl !== companyLogoUrl) {
      const logoPhotoRecord = await photosUpload.mutateAsync({
        photoGuid: nextGuid(),
        base64Image: company.logoUrl.split(',')[1],
        extension: 'jpg',
        contentType: 'image/jpeg',
      })
      logoPhotoGuid = logoPhotoRecord.photoGuid
      const companyLogoResizedBase64 = await resizeImage({
        fileUrl: company.logoUrl,
        maxWidth: COMPANY_LOGO_MAX_SIZE.width,
        maxHeight: COMPANY_LOGO_MAX_SIZE.height,
      })

      companyLogoUrl = await tenantPublicFilesUpload.mutateAsync({
        fileUrn: `${business.fullLegalName}_logo.png`,
        base64EncodedFile: companyLogoResizedBase64.split(',')[1],
        contentType: 'image/png',
      })
    }

    const defaultBillingProfile = { ...billingProfile }
    delete defaultBillingProfile.__typename

    await billingProfileUpsertMutation({
      object: {
        ...defaultBillingProfile,
        companyGuid,
        businessFullLegalName: business.fullLegalName,
        contractorLicenseNumber: business.contractorLicenseNumber,
        logoUrl: companyLogoUrl,
        logoPhotoGuid,
        defaultPricebookTaxRateGuid: invoicesAndEstimates.defaultTaxRateGuid,
        invoiceMemo: invoicesAndEstimates.defaultInvoiceMemo,
        invoiceTerm: invoicesAndEstimates.defaultInvoiceTerm,
        estimateDisclaimer: invoicesAndEstimates.defaultEstimateDisclaimer,
        invoiceDisclaimer: invoicesAndEstimates.defaultInvoiceDisclaimer,
        businessAddress: {
          data: {
            ...business.address,
            addressGuid: nextGuid(),
            canonicalFullAddress: BzAddress.makeCanonicalFullAddress(
              business.address,
            ),
          },
          onConflict: {
            constraint: 'addresses_canonical_full_address_key',
            updateColumns: ['canonicalFullAddress'],
          },
        },
        emailAddress: {
          data: {
            companyGuid,
            emailAddressGuid: nextGuid(),
            emailAddress: business.emailAddress,
            createdByUserGuid: userGuid,
          },
        },
        phoneNumber: {
          data: {
            companyGuid,
            phoneNumber: business.phoneNumber,
          },
        },
      },
    })

    message.success('Billing Profile updated!')
    refresh()
  })

  return (
    <FormProvider {...form}>
      <form className="flex h-full flex-col gap-3" onSubmit={onSubmit}>
        <div className="flex flex-col gap-3 overflow-y-scroll">
          <Collapse
            ghost
            defaultActiveKey={['Business', 'Company', 'Invoices and Estimates']}
            items={[
              {
                key: 'Business',
                label: 'Business',
                children: <BillingProfileSettingsBusinessInfoSection />,
              },
              {
                key: 'Company',
                label: 'Company',
                children: <BillingProfileSettingsCompanyInfoSection />,
              },
              {
                key: 'Invoices and Estimates',
                label: 'Invoices and Estimates',
                children: <BillingProfileSettingsInvoicesEstimatesSection />,
              },
            ]}
          />
        </div>

        <div className="mt-auto flex flex-row gap-3 self-end">
          <Button
            htmlType="button"
            disabled={
              !form.formState.isDirty || billingProfileUpsertRes.fetching
            }
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={
              !form.formState.isDirty || billingProfileUpsertRes.fetching
            }
          >
            {billingProfileUpsertRes.fetching ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}
