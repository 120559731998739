import cn from 'classnames'
import { Composable } from '../../utils/Composable'
import { Styled } from '../../utils/Stylable'

type ScrollCardProps = Styled<Composable> & {
  hasPageHeading?: boolean
}

const ScrollCard = ({
  children,
  style,
  hasPageHeading = false,
  className,
}: ScrollCardProps) => (
  <div
    className={`scrollable-y
    ${cn({
      card: !hasPageHeading,
      'card-with-page-heading': hasPageHeading,
    })}
    ${className}
  `}
    style={style}
  >
    {children}
  </div>
)

export default ScrollCard
