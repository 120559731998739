import {
  JobLifecycleStatus,
  JobLifecycleStatusWithoutLifecycleGuid,
  R,
} from '@breezy/shared'
import React from 'react'
import { JobLifecycleStatusGuid } from './types'

export type AddNewStatus = (
  status: Omit<JobLifecycleStatus, 'jobLifecycleStatusGuid'>,
) => void

type JobLifecycleManagementContextType = {
  addNewStatus: AddNewStatus
  changeStatus: (status: JobLifecycleStatus) => void
  deleteStatus: (statusGuid: string) => void
}

export const JobLifecycleManagementContext =
  React.createContext<JobLifecycleManagementContextType>({
    addNewStatus: () => {},
    changeStatus: () => {},
    deleteStatus: () => {},
  })

const unsetFlag = <T extends JobLifecycleStatusWithoutLifecycleGuid>(
  oldStatus: T,
  newStatus: T,
  key:
    | 'isDefault'
    | 'isLeadWon'
    | 'isWorkComplete'
    | 'isFirstAppointmentBookedAutomationEnabled'
    | 'isAllOutstandingAppointmentsAssignedAutomationEnabled'
    | 'isTechnicianDispatchedAutomationEnabled'
    | 'isTechnicianArrivesAtAppointmentAutomationEnabled'
    | 'isAllAppointmentsCompletedAutomationEnabled'
    | 'isNoAdditionalWorkRequiredAutomationEnabled',
) => (newStatus[key] ? false : oldStatus[key])

// Each flag can only be on one status. If we have a new or changed status, it's possible one or more of the flags were
// set on. If so, we need to go through and turn those flags off on the other statuses.
export const updateStatusLabelsInMap = <
  T extends JobLifecycleStatusWithoutLifecycleGuid,
>(
  newStatus: T,
  map: Record<string, T>,
  onlyIncludeChanges?: boolean,
): Record<string, T> => {
  const newMap: Record<string, T> = {}

  for (const key of R.keys(map)) {
    const item = map[key]
    const transformed = {
      ...item,
      isDefault: unsetFlag(item, newStatus, 'isDefault'),
      isLeadWon: unsetFlag(item, newStatus, 'isLeadWon'),
      isWorkComplete: unsetFlag(item, newStatus, 'isWorkComplete'),
    }
    if (!onlyIncludeChanges || !R.equals(item, transformed)) {
      newMap[key] = transformed
    }
  }
  return newMap
}

// When you add or change a status, you might turned on one or more of the special
// flags. If you did, we need to turn them OFF for all other statuses. This means we
// have to check all existing status to see if they need their flags change, adding the
// relevant ones to the change map. We ALSO need to check the EXISTING changes and
// change them FURTHER if they need their flags changed. Finally, if any changes are
// changing a status BACK to the original, we remove it from the change list. Note: for
// "newStatus" you can use either an existing, changed status or a brand new status; the
// way we check the guid it will apply the new changes if relevant or not add if it's
// new. Also note that we do it this currying way because we know it's going straight
// into `setStatusChangeMap`.
export const reconcileFlags =
  <T extends JobLifecycleStatusWithoutLifecycleGuid>(
    newStatus: T,
    originalMap: Record<string, T>,
  ) =>
  (existingChangeMap: Record<string, T>): Record<string, T> => {
    const newMap: Record<string, T> = {}

    for (const key of R.keys(originalMap)) {
      const currentItem = existingChangeMap[key] ?? originalMap[key]
      const transformed =
        key === newStatus.jobLifecycleStatusGuid
          ? newStatus
          : {
              ...currentItem,
              isDefault: unsetFlag(currentItem, newStatus, 'isDefault'),
              isLeadWon: unsetFlag(currentItem, newStatus, 'isLeadWon'),
              isFirstAppointmentBookedAutomationEnabled: unsetFlag(
                currentItem,
                newStatus,
                'isFirstAppointmentBookedAutomationEnabled',
              ),
              isAllOutstandingAppointmentsAssignedAutomationEnabled: unsetFlag(
                currentItem,
                newStatus,
                'isAllOutstandingAppointmentsAssignedAutomationEnabled',
              ),
              isTechnicianDispatchedAutomationEnabled: unsetFlag(
                currentItem,
                newStatus,
                'isTechnicianDispatchedAutomationEnabled',
              ),
              isTechnicianArrivesAtAppointmentAutomationEnabled: unsetFlag(
                currentItem,
                newStatus,
                'isTechnicianArrivesAtAppointmentAutomationEnabled',
              ),
              isAllAppointmentsCompletedAutomationEnabled: unsetFlag(
                currentItem,
                newStatus,
                'isAllAppointmentsCompletedAutomationEnabled',
              ),
              isNoAdditionalWorkRequiredAutomationEnabled: unsetFlag(
                currentItem,
                newStatus,
                'isNoAdditionalWorkRequiredAutomationEnabled',
              ),
              isWorkComplete: unsetFlag(
                currentItem,
                newStatus,
                'isWorkComplete',
              ),
            }
      if (!R.equals(originalMap[key], transformed)) {
        newMap[key] = transformed
      }
    }
    return newMap
  }

export const areSequencesEqual = (
  sequence1: JobLifecycleStatusGuid[],
  sequence2: JobLifecycleStatusGuid[],
): boolean => {
  if (sequence1.length !== sequence2.length) return false
  return sequence1.every((guid, index) => guid === sequence2[index])
}
