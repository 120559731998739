import { gql } from 'src/generated'

export const INVOICE_SUMMARY_STATISTICS_QUERY = gql(/* GraphQL */ `
  query InvoicesListPageInvoiceSummaryStatisticsQuery(
    $where: InvoicesQueryableBoolExp
  ) {
    invoicesQueryableAggregate(where: $where) {
      aggregate {
        sum {
          totalAmountUsd
        }
        count
      }
    }
  }
`)

export const INVOICE_SUMMARY_STATISTICS_AVG_VALUE_QUERY = gql(/* GraphQL */ `
  query InvoicesListPageInvoiceSummaryStatisticAvgValuesQuery(
    $where: InvoicesQueryableBoolExp
  ) {
    invoicesQueryableAggregate(where: $where) {
      aggregate {
        avg {
          totalAmountUsd
        }
      }
    }
  }
`)

export const INVOICE_AVG_UNPAID_DAYS_QUERY = gql(/* GraphQL */ `
  query InvoicesListPageInvoiceAvgUnpaidDaysQuery(
    $where: InvoicesQueryableBoolExp
  ) {
    invoicesQueryableAggregate(where: $where) {
      aggregate {
        avg {
          daysUnpaid
        }
      }
    }
  }
`)

export const INVOICES_V2_QUERY = gql(/* GraphQL */ `
  query InvoicesListPageInvoicesV2Query(
    $where: AggregatableInvoicesBoolExp
    $orderBy: [AggregatableInvoicesOrderBy!]
    $limit: Int
    $offset: Int
  ) {
    aggregatableInvoices(
      where: $where
      orderBy: $orderBy
      limit: $limit
      offset: $offset
    ) {
      accountGuid
      companyGuid
      createdAt
      dueAt
      invoiceGuid
      status
      invoiceTerm
      issuedAt
      issuedOrCreatedAt
      displayId
      totalUsc
      serviceCompletionDate
      updatedAt
      account {
        accountDisplayName
        accountContacts(where: { primary: { _eq: true } }) {
          contact {
            fullName
            primaryPhoneNumber {
              phoneNumber
              type
            }
            primaryEmailAddress {
              emailAddress
            }
          }
        }
        accountManagerUserGuid
      }
      locationLink {
        locationGuid
        location {
          address {
            line1
            line2
          }
        }
      }
      jobLink {
        jobGuid
        job {
          displayId
          jobType {
            name
          }
          pointOfContact {
            fullName
            primaryEmailAddress {
              emailAddress
            }
            primaryPhoneNumber {
              phoneNumber
              type
            }
          }
          jobLeadSource {
            companyLeadSource {
              companyLeadSourceGuid
              canonicalLeadSourceName
              canonicalLeadSourceNameOverride
            }
          }
        }
      }
      paidUsd
      processingUsd
      numDaysOverdue
      isTerminalStatus
      createdBy
      maintenancePlanLink {
        maintenancePlan {
          createdByUserGuid
          maintenancePlanDefinition {
            marketingInfo {
              name
            }
          }
        }
        maintenancePlanGuid
      }
    }
    aggregatableInvoicesAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`)

export const INVOICE_V2_SUMMARY_STATISTICS_QUERY = gql(/* GraphQL */ `
  query InvoicesListPageInvoiceV2SummaryStatisticsQuery(
    $where: AggregatableInvoicesBoolExp
  ) {
    aggregatableInvoicesAggregate(where: $where) {
      aggregate {
        count
        sum {
          totalUsc
        }
      }
    }
  }
`)

export const INVOICE_V2_SUMMARY_STATISTICS_AVG_VALUE_QUERY = gql(/* GraphQL */ `
  query InvoicesListPageInvoiceV2SummaryStatisticAvgValuesQuery(
    $where: AggregatableInvoicesBoolExp
  ) {
    aggregatableInvoicesAggregate(where: $where) {
      aggregate {
        avg {
          totalUsc
        }
      }
    }
  }
`)

export const INVOICE_V2_AVG_UNPAID_DAYS_QUERY = gql(/* GraphQL */ `
  query InvoicesListPageInvoiceV2AvgUnpaidDaysQuery(
    $where: AggregatableInvoicesBoolExp
  ) {
    aggregatableInvoicesAggregate(where: $where) {
      aggregate {
        avg {
          daysUnpaid
        }
      }
    }
  }
`)

export const INVOICE_LISTING_USERS_QUERY = gql(/* GraphQL */ `
  query GetInvoiceListUsers {
    companyUsers {
      userGuid
      userByUserGuid {
        firstName
        lastName
        fullName
        deactivatedAt
        userRoles {
          role
        }
      }
    }
  }
`)

export const COMPANY_CONFIG_QUERY = gql(/* GraphQL */ `
  query InvoiceListPageCompanyConfigQuery($companyGuid: uuid!) {
    companyConfigByPk(companyGuid: $companyGuid) {
      companyGuid
      accountManagerEnabled
    }
  }
`)
