import { isPoint, metersToMiles, R } from '@breezy/shared'
import { useMap } from '@vis.gl/react-google-maps'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useCenterMapOnPointWithRadius } from '../../../components/MapView/hooks/useCenterMapOnPointWithRadius'
import { useCenterMapOnVisiblePoints } from '../../../components/MapView/hooks/useCenterMapOnVisiblePoints'
import { useDefaultBounds } from '../../../components/MapView/hooks/useDefaultBounds'
import RadiusControl from '../../../components/MapView/RadiusControl/RadiusControl'
import ReactMapView from '../../../components/MapView/ReactMapView'
import RenderIf from '../../../elements/RenderIf/RenderIf'
import { useStrictContext } from '../../../utils/react-utils'
import { useSchedulePendingChanges } from '../PendingChanges/SchedulePendingChangesContext'
import { SchedulePageContext, TechnicianResource } from '../scheduleUtils'
import { AppointmentPin } from './AppointmentPin'
import MaintenanceOpportunityPin from './MaintenanceOpportunityPin'
import { DEFAULT_RADIUS_MILES, useScheduleMap } from './ScheduleMapContext'

type ScheduleMapViewProps = {
  techList: TechnicianResource[]
}

export const ScheduleMapView = React.memo<ScheduleMapViewProps>(
  ({ techList }) => {
    const {
      activeAppointment,
      radiusMeters,
      setRadiusMeters,
      maintOpportunities,
      filteredMaintOpportunities,
      setActivePopup,
      activePopup,
      loading,
    } = useScheduleMap()

    const { selectedTechGuids } = useStrictContext(SchedulePageContext)

    const { allAppointments } = useSchedulePendingChanges()

    const appointments = useMemo(() => {
      const techGuidFilterMap = R.indexBy(R.identity, selectedTechGuids)
      return allAppointments.filter(a => {
        if (!selectedTechGuids.length) {
          return true
        }
        if (!a.assignments?.length) {
          return true
        }
        for (const assignment of a.assignments) {
          if (techGuidFilterMap[assignment.technicianUserGuid]) {
            return true
          }
        }
        return false
      })
    }, [allAppointments, selectedTechGuids])

    // TODO use the actual GCP mapId
    const map = useMap('default')

    const filteredMaintOpportunitiesGuids = useMemo(
      () => filteredMaintOpportunities.map(mo => mo.locationGuid),
      [filteredMaintOpportunities],
    )

    const visibleAppointmentPoints = useMemo(
      () =>
        appointments.length > 0
          ? appointments
              .map(a => a.job.location.address.geoLocation)
              .filter(isPoint)
          : [],
      [appointments],
    )

    const activeAppointmentPoint = useMemo(
      () =>
        activeAppointment?.job.location.address.geoLocation ||
        appointments[0]?.job.location.address.geoLocation,
      [activeAppointment?.job.location.address.geoLocation, appointments],
    )

    const centerMapOnVisibleAppointments = useCenterMapOnVisiblePoints(
      map,
      visibleAppointmentPoints,
    )

    const centerMapOnActiveAppointment = useCenterMapOnPointWithRadius(
      map,
      activeAppointmentPoint,
      radiusMeters,
    )
    const defaultBounds = useDefaultBounds(visibleAppointmentPoints)

    const centerMap = useCallback(() => {
      // If a maint. opportunity popup is open, let Google Maps handle the centering/pan based
      // on the InfoWindow position
      if (!map || activePopup?.type === 'maintenanceOpportunity') return
      if (activeAppointment || visibleAppointmentPoints.length === 1) {
        centerMapOnActiveAppointment()
      } else {
        centerMapOnVisibleAppointments()
      }
    }, [
      map,
      activeAppointment,
      visibleAppointmentPoints.length,
      activePopup,
      centerMapOnActiveAppointment,
      centerMapOnVisibleAppointments,
    ])

    // Center map on mount and whenever any deps change
    useEffect(() => {
      centerMap()
    }, [centerMap])

    const onRadiusChanged = useCallback(
      (newRadius: number) => {
        setActivePopup(undefined)
        setRadiusMeters(newRadius)
      },
      [setActivePopup, setRadiusMeters],
    )

    const techMap = useMemo(
      () => R.indexBy(R.prop('userGuid'), techList),
      [techList],
    )

    return (
      <div className="flex min-h-0 flex-1 flex-row">
        <ReactMapView
          key="DEMO_MAP_ID"
          // passing in a tailwind classname bricks the react-google-maps styles so I'm passing in
          // this border radius here
          style={{ borderRadius: '8px' }}
          // TODO use the actual GCP mapId
          mapId="default"
          defaultBounds={defaultBounds}
          onClick={() => setActivePopup(undefined)}
          mapTypeControl={false}
          streetViewControl={false}
          fullscreenControl={false}
          clickableIcons={false}
        >
          <RenderIf if={!!activeAppointment}>
            <RadiusControl
              radiusMeters={radiusMeters}
              defaultRadiusMiles={metersToMiles(DEFAULT_RADIUS_MILES)}
              onRadiusChanged={onRadiusChanged}
              loading={loading}
            />
            {maintOpportunities.map(mo => {
              return (
                <MaintenanceOpportunityPin
                  key={mo.locationGuid}
                  maintenanceOpportunity={mo}
                  activeAppointmentGuid={activeAppointment?.appointmentGuid}
                  filteredMaintOpportunitiesGuids={
                    filteredMaintOpportunitiesGuids
                  }
                  activePopup={activePopup}
                  setActivePopup={setActivePopup}
                />
              )
            })}
          </RenderIf>

          {appointments.map(appointment => (
            <AppointmentPin
              key={appointment.appointmentGuid}
              appointment={appointment}
              setRadius={onRadiusChanged}
              techMap={techMap}
            />
          ))}
        </ReactMapView>
      </div>
    )
  },
)
