import {
  BzDateFns,
  getYTDGroupingType,
  STANDARD_REPORTING_TIME_HORIZON_OPTIONS,
  StandardReportingDateRange,
  StandardReportingTimeHorizon,
  TimeZoneId,
} from '@breezy/shared'
import React, { useState } from 'react'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { BaseReportingDateRangePicker } from './BaseReportingDateRangePicker'
import './ReportingDateRangePicker.less'
import { CommonReportingDateRangePickerProps } from './utils'

const getRangeForDays = (startOfToday: Date, days: number): [Date, Date] => {
  const start = BzDateFns.subDays(startOfToday, days)
  const end = BzDateFns.endOfDay(startOfToday)
  return [start, end]
}

const getRangeForWeeks = (startOfToday: Date, weeks: number): [Date, Date] => {
  const startOfWeek = BzDateFns.startOfWeek(startOfToday, {
    weekStartsOn: 1,
  })
  const start = BzDateFns.subWeeks(startOfWeek, weeks)
  const end = BzDateFns.endOfDay(BzDateFns.subDays(startOfWeek, 1))

  return [start, end]
}

const getRangeForMonths = (
  startOfToday: Date,
  months: number,
): [Date, Date] => {
  const start = BzDateFns.startOfMonth(
    BzDateFns.subMonths(startOfToday, months),
  )
  const end = BzDateFns.endOfMonth(BzDateFns.subMonths(startOfToday, 1))
  return [start, end]
}

const getDateRangeFromStandardTimeHorizon = (
  timeHorizon: StandardReportingTimeHorizon,
  tzId: TimeZoneId,
): StandardReportingDateRange => {
  const endOfToday = BzDateFns.endOfDay(BzDateFns.now(tzId))
  const startOfToday = BzDateFns.startOfDay(endOfToday)

  const [start, end] = (() => {
    switch (timeHorizon) {
      case 'Last 7 days':
        return getRangeForDays(startOfToday, 7)
      case 'Last 15 days':
        return getRangeForDays(startOfToday, 15)
      case 'Last 6 weeks':
        return getRangeForWeeks(startOfToday, 6)
      case 'Last 3 months': {
        const startOfWeek = BzDateFns.startOfWeek(startOfToday, {
          weekStartsOn: 1,
        })
        const start = BzDateFns.startOfWeek(
          // I want it to be just under 3 months to get the units to be weeks.
          BzDateFns.addWeeks(BzDateFns.subMonths(startOfWeek, 3), 1),
          {
            weekStartsOn: 1,
          },
        )
        const end = BzDateFns.endOfDay(BzDateFns.subDays(startOfWeek, 1))

        return [start, end]
      }
      case 'Last 6 months':
        return getRangeForMonths(startOfToday, 6)
      case 'Last 12 months':
        return getRangeForMonths(startOfToday, 12)
      case 'Year to Date': {
        const start = BzDateFns.startOfYear(startOfToday)
        const timeUnit = getYTDGroupingType(tzId)

        if (timeUnit === 'days') {
          return getRangeForDays(
            startOfToday,
            BzDateFns.differenceInDays(startOfToday, start),
          )
        } else if (timeUnit === 'weeks') {
          return getRangeForWeeks(
            startOfToday,
            BzDateFns.differenceInWeeks(startOfToday, start),
          )
        } else if (timeUnit === 'months') {
          return getRangeForMonths(
            startOfToday,
            BzDateFns.differenceInMonths(startOfToday, start),
          )
        } else {
          throw new Error(`Unhandled time unit: ${timeUnit}`)
        }
      }
    }
    throw new Error(`Unrecognized time horizon: ${timeHorizon}`)
  })()

  return {
    start: BzDateFns.formatISO(BzDateFns.startOfDay(start), tzId),
    end: BzDateFns.formatISO(BzDateFns.endOfDay(end), tzId),
    timeHorizon,
  }
}

export const StandardReportingDateRangePicker = React.memo<
  CommonReportingDateRangePickerProps<StandardReportingTimeHorizon>
>(props => (
  <BaseReportingDateRangePicker
    {...props}
    presets={STANDARD_REPORTING_TIME_HORIZON_OPTIONS}
    getRangeFromPreset={getDateRangeFromStandardTimeHorizon}
  />
))

export const useStandardReportingDateRangePickerState = (
  defaultTimeHorizon: StandardReportingTimeHorizon = 'Last 6 weeks',
) => {
  const tzId = useExpectedCompanyTimeZoneId()
  return useState(() =>
    getDateRangeFromStandardTimeHorizon(defaultTimeHorizon, tzId),
  )
}
