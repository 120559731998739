import {
  APPOINTMENT_CHECKLIST_ITEM_TYPE_CONFIG,
  APPOINTMENT_TYPES,
  AppointmentChecklist,
  AppointmentType,
  R,
  aOrAn,
  nextGuid,
} from '@breezy/shared'
import {
  faArrowLeft,
  faArrowRight,
  faChevronDown,
  faChevronUp,
  faListCheck,
  faTrash,
} from '@fortawesome/pro-light-svg-icons'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, Form, Input, Popconfirm, Select, Tooltip } from 'antd'
import cn from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import BzCheckBox from '../../elements/BzCheckBox/BzCheckBox'
import BzDrawer from '../../elements/BzDrawer/BzDrawer'
import { BzStepper } from '../../elements/BzStepper/BzStepper'
import { trpc } from '../../hooks/trpc'
import { LoadingSpinner } from '../LoadingSpinner'

const STEPS = ['Setup', 'Configure'] as const

const ITEM_TYPE_SELECT_OPTIONS = R.keys(
  APPOINTMENT_CHECKLIST_ITEM_TYPE_CONFIG,
).map(value => ({
  label: APPOINTMENT_CHECKLIST_ITEM_TYPE_CONFIG[value].label,
  value,
}))

const YES_NO_SELECT_OPTIONS = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
]

const APPOINTMENT_TYPE_SELECT_OPTIONS: {
  value: AppointmentType | 'Any'
  label: string
}[] = [
  ...APPOINTMENT_TYPES.map(value => ({
    label: value,
    value,
  })),
  {
    value: 'Any',
    label: 'Any',
  },
]

type EditAppointmentChecklistSidebarProps = {
  checklist?: AppointmentChecklist
  onClose: () => void
  refetch: () => Promise<unknown>
}

export const EditAppointmentChecklistSidebar =
  React.memo<EditAppointmentChecklistSidebarProps>(
    ({ checklist, onClose, refetch }) => {
      const drawerItem = useMemo(() => ({ onCancel: onClose }), [onClose])

      const [currentStep, setCurrentStep] = useState(0)

      const [name, setName] = useState(checklist?.name ?? '')

      const [items, setItems] = useState(
        () =>
          checklist?.items.map(item => ({ ...item, key: nextGuid() })) ?? [],
      )

      const jobTypeQuery = trpc.jobTypes['job-types:get'].useQuery()

      const editItem = useCallback(
        <K extends keyof (typeof items)[number]>(
          index: number,
          key: K,
          value: (typeof items)[number][K],
        ) => {
          setItems(R.adjust(index, item => ({ ...item, [key]: value })))
        },
        [],
      )

      const moveStep = useCallback((index: number, up: boolean) => {
        setItems(items => {
          if ((index === 0 && up) || (index === items.length - 1 && !up)) {
            return items
          }
          const item = items[index]
          const newIndex = index + (up ? -1 : 1)
          const itemsCopy = [...items]
          itemsCopy[index] = itemsCopy[newIndex]
          itemsCopy[newIndex] = item
          return itemsCopy
        })
      }, [])

      const deleteItem = useCallback((index: number) => {
        setItems(R.remove(index, 1))
      }, [])

      const addItem = useCallback(
        () =>
          setItems(items => [
            ...items,
            {
              key: nextGuid(),
              type: 'CHECKBOX',
              name: '',
            },
          ]),
        [],
      )

      const hasInvalidItems = useMemo(() => {
        for (const item of items) {
          if (!item.name) {
            return true
          }
        }
        return false
      }, [items])

      const duplicatedItemName = useMemo(() => {
        const usedNameMap: Record<string, true> = {}
        for (const item of items) {
          if (usedNameMap[item.name]) {
            return item.name
          }
          usedNameMap[item.name] = true
        }
        return ''
      }, [items])

      const [associations, setAssociations] = useState(
        () =>
          checklist?.associationRules.map(associationRule => ({
            ...associationRule,
            key: nextGuid(),
          })) ?? [],
      )

      const editAssociation = useCallback(
        <K extends keyof (typeof associations)[number]>(
          index: number,
          key: K,
          value: (typeof associations)[number][K],
        ) => {
          setAssociations(
            R.adjust(index, association => ({
              ...association,
              [key]: value === 'Any' ? undefined : value,
            })),
          )
        },
        [],
      )

      const deleteAssociation = useCallback((index: number) => {
        setAssociations(R.remove(index, 1))
      }, [])

      const addAssociation = useCallback(
        () =>
          setAssociations(associations => [
            ...associations,
            {
              key: nextGuid(),
            },
          ]),
        [],
      )

      const duplicatedAssociation = useMemo(() => {
        const usedPairMap: Record<string, true> = {}
        for (const association of associations) {
          const associationStr = `${
            association.jobType ? association.jobType.name : 'Any'
          } / ${association.appointmentType ?? 'Any'}`
          if (usedPairMap[associationStr]) {
            return associationStr
          }
          usedPairMap[associationStr] = true
        }
        return ''
      }, [associations])

      const upsertChecklistMutation = trpc.appointmentChecklist[
        'appointment-checklists:upsert'
      ].useMutation({
        onSuccess: () => {
          refetch()
          onClose()
        },
      })

      const upsertChecklist = useCallback(() => {
        upsertChecklistMutation.mutate({
          appointmentChecklistGuid: nextGuid(),
          ...checklist,
          name,
          items,
          associationRules: associations,
        })
      }, [associations, checklist, items, name, upsertChecklistMutation])

      const deleteChecklistMutation = trpc.appointmentChecklist[
        'appointment-checklists:delete'
      ].useMutation({
        onSuccess: () => {
          refetch()
          onClose()
        },
      })

      const deleteChecklist = useCallback(() => {
        if (checklist) {
          deleteChecklistMutation.mutate({
            appointmentChecklistGuid: checklist.appointmentChecklistGuid,
          })
        }
      }, [checklist, deleteChecklistMutation])

      const footerContent = useMemo(() => {
        const deleteButton = checklist ? (
          <Popconfirm
            title="Are you sure you want to delete this checklist?"
            okText="Delete"
            onConfirm={deleteChecklist}
            okButtonProps={{ danger: true }}
          >
            <Button danger type="primary" size="large">
              Delete Checklist
            </Button>
          </Popconfirm>
        ) : (
          <span />
        )
        if (currentStep === 0) {
          let disabledReason = ''
          if (hasInvalidItems) {
            disabledReason = 'All checklist items must have a name'
          }
          if (duplicatedItemName) {
            disabledReason = `You have "${duplicatedItemName}" twice.`
          }
          if (!items.length) {
            disabledReason = 'Your checklist must have at least one item'
          }
          if (name.trim().length === 0) {
            disabledReason = 'Your checklist must have a name'
          }
          let nextButton = (
            <Button
              type="primary"
              size="large"
              onClick={() => setCurrentStep(1)}
              disabled={!!disabledReason}
            >
              Next <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </Button>
          )
          if (disabledReason) {
            nextButton = (
              <Tooltip title={disabledReason}>
                <span>{nextButton}</span>
              </Tooltip>
            )
          }

          return (
            <>
              {deleteButton}
              {nextButton}
            </>
          )
        }
        const invalid = !associations.length || !!duplicatedAssociation
        let saveButton = (
          <Button
            type="primary"
            size="large"
            disabled={invalid}
            onClick={upsertChecklist}
          >
            Save Checklist
          </Button>
        )
        if (!associations.length) {
          saveButton = (
            <Tooltip title="Your checklist must have at least one association">
              <span>{saveButton}</span>
            </Tooltip>
          )
        }
        if (duplicatedAssociation) {
          saveButton = (
            <Tooltip
              title={`You can't have two identical associations (${duplicatedAssociation})`}
            >
              <span>{saveButton}</span>
            </Tooltip>
          )
        }
        return (
          <>
            <Button onClick={() => setCurrentStep(0)} size="large">
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
              Back
            </Button>
            <div className="space-x-2">
              {deleteButton}
              {saveButton}
            </div>
          </>
        )
      }, [
        associations.length,
        checklist,
        currentStep,
        deleteChecklist,
        duplicatedAssociation,
        duplicatedItemName,
        hasInvalidItems,
        items.length,
        upsertChecklist,
        name,
      ])

      const mainContent = useMemo(() => {
        if (currentStep === 0) {
          return (
            <Form layout="vertical">
              <Form.Item label="Checklist name">
                <Input value={name} onChange={e => setName(e.target.value)} />
              </Form.Item>
              <Divider />
              <h3>Checklist Items</h3>
              <p className="text-gray-70 font-extralight">
                Add items that should be completed when this checklist is added
                to an appointment.
              </p>
              {items.map(({ key, type, name, notRequired }, i) => (
                <div key={key} className="flex flex-row items-center gap-2">
                  <div className="flex flex-col">
                    <Button type="text" onClick={() => moveStep(i, true)}>
                      <FontAwesomeIcon icon={faChevronUp} />
                    </Button>
                    <Button type="text" onClick={() => moveStep(i, false)}>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </Button>
                  </div>
                  <Form.Item label="Type" className="min-w-[150px]">
                    <Select
                      value={type}
                      onChange={value => editItem(i, 'type', value)}
                      options={ITEM_TYPE_SELECT_OPTIONS}
                    />
                  </Form.Item>
                  <Form.Item label="Name" className="flex-1">
                    <Input
                      value={name}
                      onChange={e => editItem(i, 'name', e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item label="Required?" className="min-w-[85px]">
                    <Select
                      value={notRequired ? 'no' : 'yes'}
                      onChange={value =>
                        editItem(i, 'notRequired', value === 'no')
                      }
                      options={YES_NO_SELECT_OPTIONS}
                    />
                  </Form.Item>
                  <Button type="text" onClick={() => deleteItem(i)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </div>
              ))}
              <Button type="primary" onClick={addItem}>
                + Add checklist item
              </Button>
            </Form>
          )
        }

        if (jobTypeQuery.isLoading || !jobTypeQuery.data) {
          return <LoadingSpinner />
        }

        return (
          <Form layout="vertical">
            <h3>Associations</h3>
            <p className="text-gray-70 font-extralight">
              This checklist will be automatically added to any appointments
              that match these criteria.
            </p>
            <div className="divide-y divide-slate-200">
              {associations.map(
                ({ jobType, appointmentType, required, key }, i) => (
                  <div key={key} className="mb-4 border-0 border-solid pt-4">
                    <div className="flex w-full flex-row items-center space-x-2">
                      <Form.Item label="Job Type" className="flex-1">
                        <Select
                          value={jobType?.jobTypeGuid ?? 'Any'}
                          onChange={value => {
                            if (value === 'Any') {
                              editAssociation(i, 'jobType', undefined)
                            } else {
                              editAssociation(
                                i,
                                'jobType',
                                jobTypeQuery.data.find(
                                  jt => jt.jobTypeGuid === value,
                                ),
                              )
                            }
                          }}
                          options={jobTypeQuery.data.map(jt => ({
                            value: jt.jobTypeGuid,
                            label: jt.name,
                          }))}
                        />
                      </Form.Item>

                      <Form.Item label="Visit Type" className="flex-1">
                        <Select
                          value={appointmentType ?? 'Any'}
                          onChange={value =>
                            editAssociation(
                              i,
                              'appointmentType',
                              value === 'Any'
                                ? undefined
                                : (value as AppointmentType),
                            )
                          }
                          options={APPOINTMENT_TYPE_SELECT_OPTIONS}
                        />
                      </Form.Item>

                      <Button type="text" onClick={() => deleteAssociation(i)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>

                    <BzCheckBox
                      label='Prevent technician from marking an appointment as "Complete" if they have not finished the checklist.'
                      value={!!required}
                      onChange={checked =>
                        editAssociation(i, 'required', checked)
                      }
                    />
                  </div>
                ),
              )}
            </div>
            <Button type="primary" size="large" onClick={addAssociation}>
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add Association
            </Button>
          </Form>
        )
      }, [
        addAssociation,
        addItem,
        associations,
        currentStep,
        deleteAssociation,
        deleteItem,
        editAssociation,
        editItem,
        items,
        jobTypeQuery.data,
        jobTypeQuery.isLoading,
        moveStep,
        name,
      ])

      const associationExplanation = useMemo(() => {
        if (!associations.length) {
          return 'Add some associations'
        }
        const requiredExplanations: string[] = []
        const notRequiredExplanations: string[] = []

        for (const association of associations) {
          const str = `it is ${
            association.jobType
              ? association.jobType.name.toLowerCase()
              : 'any kind of'
          } job and is ${
            association.appointmentType
              ? `${aOrAn(
                  association.appointmentType,
                )} ${association.appointmentType.toLowerCase()}`
              : 'any kind of'
          } appointment`
          if (association.required) {
            requiredExplanations.push(str)
          } else {
            notRequiredExplanations.push(str)
          }
        }

        if (requiredExplanations.length >= 2) {
          requiredExplanations[requiredExplanations.length - 1] = `or ${
            requiredExplanations[requiredExplanations.length - 1]
          }`
        }
        if (notRequiredExplanations.length >= 2) {
          notRequiredExplanations[notRequiredExplanations.length - 1] = `or ${
            notRequiredExplanations[notRequiredExplanations.length - 1]
          }`
        }

        let str = ''

        if (notRequiredExplanations.length) {
          str = `An appointment will have this checklist if ${notRequiredExplanations.join(
            ', ',
          )}.`
        }
        if (requiredExplanations) {
          str = `${
            str ? `${str} Also, a` : 'A'
          }n appointment will have this checklist (and be unable to be completed unless it's filled out) if ${requiredExplanations.join(
            ', ',
          )}.`
        }

        return str
      }, [associations])

      const isLoading =
        upsertChecklistMutation.isLoading || deleteChecklistMutation.isLoading

      return (
        <BzDrawer
          title="Edit Checklist"
          icon={faListCheck}
          item={drawerItem}
          preferredWidth={720}
          footer={
            <div
              className={cn('flex flex-row justify-between py-4', {
                'pointer-events-none opacity-50': isLoading,
              })}
            >
              {footerContent}
            </div>
          }
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="flex h-full min-h-0 flex-col">
              <BzStepper
                steps={STEPS}
                currentStep={currentStep}
                hidePreviousNextButtons
                // None of the props below matter because we're hiding the buttons
                goToStep={() => {
                  // noop
                }}
                canReverse={() => true}
                finishActionButtonText=""
                canProceed={() => true}
                onFinish={() => {
                  // noop
                }}
              />
              <div className="mt-4 min-h-0 flex-1 overflow-auto">
                {mainContent}
              </div>
              {currentStep === 1 && (
                <>
                  <Divider />
                  <p className="text-gray-70 font-extralight">
                    {associationExplanation}
                  </p>
                </>
              )}
            </div>
          )}
        </BzDrawer>
      )
    },
  )
