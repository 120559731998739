import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Board,
  KanbanJob,
  useBoardIdToStatusIdMap,
  useGroupedKanbanData,
  useKanbanStatuses,
} from '../../../../components/Kanban/kanbanUtils'
import { JobLifecycleScrollPosition } from '../../../../hooks/useJobLifecyclePersistedDisplay'
import {
  OnResize,
  useResizeObserver,
} from '../../../../hooks/useResizeObserver'
import { JobKanbanContext } from '../../JobKanbanContext'
import { JobLifecycle } from '../../JobsPage.gql'
import {
  JobMenuDropdownOnChangeJobTypeHandler,
  JobMenuDropdownOnCreateLinkedJobHandler,
  JobMenuDropdownOnEditJobHandler,
  JobMenuDropdownOnUpdatePipelineStatusHandler,
} from '../JobMenuDropdown'
import { JobsListStatusSection } from './JobsListStatusSection'
import { JobTableContext } from './JobsTableContext'

type JobsListViewProps = {
  data: KanbanJob[]
  board: Board
  hideEmptyStatuses?: boolean
  isLoading?: boolean
  disabled?: boolean
  scrollPos?: JobLifecycleScrollPosition
  lifecycles: JobLifecycle[]
  onChange: (datum: KanbanJob, statusId: string) => void
  onEditJob: JobMenuDropdownOnEditJobHandler
  onCreateLinkedJob: JobMenuDropdownOnCreateLinkedJobHandler
  onUpdateJobPipelineStatus: JobMenuDropdownOnUpdatePipelineStatusHandler
  onChangeJobType: JobMenuDropdownOnChangeJobTypeHandler
  setScrollPos?: (newScrollPos: JobLifecycleScrollPosition) => void
}

export const JobsList = React.memo<JobsListViewProps>(
  ({
    data,
    board,
    hideEmptyStatuses,
    isLoading,
    disabled,
    scrollPos,
    lifecycles,
    onChange,
    onEditJob,
    onCreateLinkedJob,
    onUpdateJobPipelineStatus,
    onChangeJobType,
    setScrollPos,
  }) => {
    const { displayPropertiesSettings } = useContext(JobKanbanContext)

    const groupedData = useGroupedKanbanData(data, board)

    const jobMap = useMemo(() => {
      const jobMap: Record<string, KanbanJob> = {}
      for (const job of data) {
        jobMap[job.id] = job
      }
      return jobMap
    }, [data])

    const boardIdToStatusIdMap = useBoardIdToStatusIdMap(board)

    const statuses = useKanbanStatuses(groupedData, board, hideEmptyStatuses)

    const divRef = useRef<HTMLDivElement | null>(null)

    const [width, setWidth] = useState(0)

    const onResize = useCallback<OnResize>(({ width }) => {
      setWidth(width)
    }, [])

    useResizeObserver(divRef, onResize)

    useEffect(() => {
      if (!divRef.current || !scrollPos) {
        return
      }

      const { offsetTop, offsetLeft } = scrollPos
      divRef.current.scrollTop = offsetTop
      divRef.current.scrollLeft = offsetLeft
      // We only want this to run when the list ref is first set or whenever it changes
      // eslint-disable-next-line
    }, [divRef])

    return (
      <JobTableContext.Provider
        value={{
          boardIdToStatusIdMap,
          jobMap,
          disabled,
          isLoading,
          lifecycles,
          onChange,
          onEditJob,
          onCreateLinkedJob,
          onUpdateJobPipelineStatus: onUpdateJobPipelineStatus,
          onChangeJobType,
        }}
      >
        <div
          className="space-y-4 overflow-auto"
          ref={divRef}
          onScroll={event => {
            if (!setScrollPos) {
              return
            }

            setScrollPos({
              offsetTop: event.currentTarget.scrollTop,
              offsetLeft: event.currentTarget.scrollLeft,
            })
          }}
        >
          {statuses.map(status => (
            <React.Fragment key={status.statusName}>
              <JobsListStatusSection
                key={status.statusName}
                statusName={status.statusName}
                icon={null}
                jobs={groupedData[status.statusName] ?? []}
                tableWidth={width}
                displayPropertiesSettings={displayPropertiesSettings}
                summary={status.summary}
              />
            </React.Fragment>
          ))}
        </div>
      </JobTableContext.Provider>
    )
  },
)
