import { PageHeader } from '@ant-design/pro-components'
import { toRoleDisplayName } from '@breezy/shared'
import { faUser } from '@fortawesome/pro-light-svg-icons'
import { Button, Col, Form, Popconfirm, Row } from 'antd'
import { useState } from 'react'
import { Page } from '../../components/Page/Page'
import { EmailAddressFormItem } from '../../components/form-fields/EmailAddressFormItem/EmailAddressFormItem'
import { FirstNameField } from '../../components/form-fields/FirstNameField/FirstNameField'
import { LastNameField } from '../../components/form-fields/LastNameField/LastNameField'
import { PhoneNumberFormItem } from '../../components/form-fields/PhoneNumberFormItem/PhoneNumberFormItem'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { trpc } from '../../hooks/trpc'
import { useExpectedPrincipal } from '../../providers/PrincipalUser'

type UserProfilePageFormSchema = {
  firstName: string
  lastName: string
  phoneNumber: string
  emailAddress: string
}

export const UserProfilePage = () => {
  const user = useExpectedPrincipal()
  const [form] = Form.useForm<UserProfilePageFormSchema>()
  const [passwordResetPopoverOpen, setPasswordResetPopoverOpen] =
    useState(false)

  const updatePasswordMutation =
    trpc.user['users:update-office-user-password'].useMutation()

  const confirm = () => {
    updatePasswordMutation.mutate(
      {},
      {
        onSuccess: (redirectUrl: string) => {
          window.open(redirectUrl, '_self')
        },
      },
    )
  }

  return (
    <Page requiresCompanyUser>
      <div className="w-1/2 content-center">
        <PageHeader title={<PageTitle title="User Profile" icon={faUser} />} />
        <Form
          layout="vertical"
          disabled
          form={form}
          className="mt-4"
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            emailAddress: user.emailAddress,
            phoneNumber: user.phoneNumbers[0]?.phoneNumber ?? '',
          }}
        >
          <Row>
            <Col xs={24}>
              <FirstNameField />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <LastNameField />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <EmailAddressFormItem required />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <PhoneNumberFormItem required />
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <div className="col mb-4">
            <h3>Your Roles</h3>
            <ul>
              {user.roles.map(r => (
                <li key={r.role}>{toRoleDisplayName(r.role)}</li>
              ))}
            </ul>
          </div>
        </Form>
        <div className="mt4">
          <p className="semibold_16_24 grey9">Password Management</p>
          <Popconfirm
            title={`Are you sure you want to update your password?`}
            onConfirm={confirm}
            open={passwordResetPopoverOpen}
            onCancel={() => setPasswordResetPopoverOpen(false)}
            okText="Yes"
            okButtonProps={{ loading: updatePasswordMutation.isLoading }}
            cancelText="Cancel"
          >
            <Button onClick={() => setPasswordResetPopoverOpen(true)}>
              Update Password
            </Button>
          </Popconfirm>
        </div>
      </div>
    </Page>
  )
}
