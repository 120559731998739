import { ReactNode } from 'react'

type ItemSegmentProps = {
  title: ReactNode
  children: ReactNode
}

export const ItemSegment = ({ title, children }: ItemSegmentProps) => (
  <div className="flex flex-col">
    {title}
    {children}
  </div>
)

export type ItemSegmentTitleProps = {
  title: string
  darkTitle?: boolean
}

export const ItemSegmentTitle = ({
  title,
  darkTitle,
}: ItemSegmentTitleProps) => {
  return (
    <h4 className={darkTitle ? 'gray9' : 'gray7'} style={{ marginBottom: 4 }}>
      {title}
    </h4>
  )
}
