import { noOp } from '@breezy/shared'
import React from 'react'

type JobTypesSettingsContextType = {
  refetch: () => void
}

export const JobTypesSettingsContext =
  React.createContext<JobTypesSettingsContextType>({
    refetch: noOp,
  })
