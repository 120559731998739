import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

type CallAvatarProps = {
  avatarText?: string
}

export const CallAvatar = React.memo<CallAvatarProps>(({ avatarText }) => {
  return (
    <div className="flex h-[64px] w-[64px] items-center justify-center rounded-full bg-gray-200 text-xl font-semibold">
      {avatarText && avatarText !== '?' ? (
        <div className="text-2xl font-semibold leading-8 text-[#595959]">
          {avatarText}
        </div>
      ) : (
        <FontAwesomeIcon icon={faUser} className="text-2xl text-[#595959]" />
      )}
    </div>
  )
})
