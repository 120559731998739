import NotACompanyUserWarning from '../components/Permissions/NotACompanyUserWarning/NotACompanyUserWarning'

/// Icons

import {
  CalculatePaths,
  OfficeRoutes,
  PermissionV1,
  PermissionV2,
  SharedRoutes,
  createRoute,
} from '@breezy/shared'
import { Navigate } from 'react-router-dom'
import { getConfig } from '../config'
import NavigateToExternal from '../elements/NavigateToExternal/NavigateToExternal'

import PaymentsListPage from '../pages/PaymentsListPage/PaymentsListPage'
import { Placeholder } from './Placeholder'
import { SharedReactRoutes } from './SharedRoutes'

import { StopImpersonatingLimbo } from '../components/Admin/AdminImpersonateUser/StopImpersonatingLimbo'
import AISandboxPage from '../pages/AISandboxPage/AISandboxPage'
import AccountCreationPage from '../pages/AccountCreationPage/AccountCreationPage'
import { AccountsListPage } from '../pages/AccountsListPage/AccountsListPage'
import AdminPage from '../pages/AdminPage'
import AiRecommendationSandboxPage from '../pages/AiRecommendationSandboxPage/AiRecommendationSandboxPage'
import { RecommendationsAnalyticsPage } from '../pages/Analytics/RecommendationsAnalyticsPage'
import { BookkeeperListPage } from '../pages/BookkeeperListPage/BookkeeperListPage'
import { CommsPage } from '../pages/CommsPage/CommsPage'
import EntityHistoryPage from '../pages/EntityHistoryPage/EntityHistoryPage'
import { EstimatesV2CreationPage } from '../pages/EstimatesV2CreationPage/EstimatesV2CreationPage'
import { EstimatesV2DetailsPage } from '../pages/EstimatesV2DetailsPage/EstimatesV2DetailsPage'
import { EstimatesV2ListPage } from '../pages/EstimatesV2ListPage/EstimatesV2ListPage'
import { WebAppInvoiceCreationPage } from '../pages/Invoices/webAppPages/WebAppInvoiceCreationPage'
import { WebAppInvoiceOverviewPage } from '../pages/Invoices/webAppPages/WebAppInvoiceOverviewPage'
import { InvoiceListPageV2 } from '../pages/InvoicesListPage/InvoiceListPageV2'
import { JobLeadsPage } from '../pages/JobLeadsPage/JobLeadsPage'
import JobsPageV2 from '../pages/JobPages/JobsPageV2'
import { NewMaintenancePlansListPage } from '../pages/NewMaintenancePlansListPage/NewMaintenancePlansListPage'
import { NotificationsPage } from '../pages/NotificationsPage/NotificationsPage'
import { PayoutDetailsPage } from '../pages/PayoutDetailsPage/PayoutDetailsPage'
import PayoutsListPage from '../pages/PayoutsListPage/PayoutsListPage'
import { OfficeRefundPage } from '../pages/RefundPage/OfficeRefundPage'
import { ReportingDashboardPage } from '../pages/ReportingDashboardPage/ReportingDashboardPage'
import { ScheduleV2Page } from '../pages/ScheduleV2Page/ScheduleV2Page'
import SettingsLayout from '../pages/SettingsPage/SettingsLayout'
import TimesheetDetailsPage from '../pages/TimesheetDetailsPage/TimesheetDetailsPage'
import TimesheetsSummaryPage from '../pages/TimesheetsSummaryPage/TimesheetsSummaryPage'
import UserDetailsPage from '../pages/UserDetailsPage/UserDetailsPage'
import { ReactRouteMap } from './ReactRoute'
import { SettingsReactRoutes } from './SettingsRoutes'

const { technicianExperienceUrl } = getConfig()
const Home = () => <Navigate to="/jobs" replace />

const NoCompany = () => (
  <NotACompanyUserWarning>
    <Navigate to="/admin" replace />
  </NotACompanyUserWarning>
)

const noPermissionRequired = undefined

export const OfficeReactRoutes = {
  ...SharedReactRoutes,
  NOT_FOUND: {
    ...OfficeRoutes.NOT_FOUND,
    element: (
      <Placeholder>The page you are looking for does not exist.</Placeholder>
    ),
    permission: noPermissionRequired,
    principalUserRequiresCompany: false,
  },
  HOME: {
    ...OfficeRoutes.HOME,
    element: <Home />,
    permission: PermissionV2.OFFICE_FINANCIAL_INFORMATION_MANAGE,
    unauthedElement: <Navigate to="/jobs" replace />,
    principalUserRequiresCompany: true,
  },
  DASHBOARD: {
    ...OfficeRoutes.DASHBOARD,
    element: <ReportingDashboardPage />,
    permission: PermissionV2.OFFICE_FINANCIAL_INFORMATION_MANAGE,
    unauthedElement: <Navigate to="/jobs" replace />,
    principalUserRequiresCompany: true,
  },
  NO_COMPANY: {
    ...OfficeRoutes.NO_COMPANY,
    element: <NoCompany />,
    permission: noPermissionRequired,
    principalUserRequiresCompany: false,
  },
  STOP_IMPERSONATING: {
    ...OfficeRoutes.STOP_IMPERSONATING,
    element: <StopImpersonatingLimbo />,
    permission: noPermissionRequired,
    principalUserRequiresCompany: false,
  },
  SCHEDULE: {
    ...OfficeRoutes.SCHEDULE,
    element: <ScheduleV2Page />,
    permission: PermissionV2.OFFICE_SCHEDULE_APPOINTMENTS_VIEW,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: true,
    header: 'Schedule',
  },
  ACCOUNTS: {
    ...OfficeRoutes.ACCOUNTS,
    element: <AccountsListPage />,
    permission: PermissionV2.OFFICE_ACCOUNTS_JOBS_VIEW,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: true,
    header: 'Accounts',
  },
  ACCOUNT_CREATION: {
    ...SharedRoutes.ACCOUNT_CREATION,
    element: <AccountCreationPage />,
    permission: PermissionV2.OFFICE_ACCOUNTS_JOBS_MANAGE,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: true,
  },
  JOBS_V2: {
    ...OfficeRoutes.JOBS_V2,
    element: <JobsPageV2 />,
    permission: PermissionV2.OFFICE_ACCOUNTS_JOBS_VIEW,
    unauthedElement: <Navigate to="/settings" replace />,
    principalUserRequiresCompany: true,
    header: 'Jobs',
  },
  SETTINGS: {
    ...OfficeRoutes.SETTINGS,
    element: <SettingsLayout />,
    permission: noPermissionRequired,
    principalUserRequiresCompany: true,
    children: [
      ...Object.values(SettingsReactRoutes),
      {
        ...createRoute('*', {
          label: 'Settings',
        }),
        element: <Navigate replace to={`/settings/user-profile`} />,
        principalUserRequiresCompany: true,
      },
    ],
  },

  TECHNICIAN_EXPERIENCE: {
    ...OfficeRoutes.TECHNICIAN_EXPERIENCE,
    element: <NavigateToExternal url={technicianExperienceUrl} />,
    permission: PermissionV2.USE_FIELD_EXPERIENCE,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: false,
  },
  ADMIN: {
    ...OfficeRoutes.ADMIN,
    element: <AdminPage />,
    permission: PermissionV2.EXECUTE_DEV_TOOLS,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: false,
  },
  USER_DETAILS: {
    ...OfficeRoutes.USER_DETAILS,
    element: <UserDetailsPage />,
    permission: noPermissionRequired,
    principalUserRequiresCompany: true,
  },

  REFUND_PAYMENT: {
    ...OfficeRoutes.REFUND_PAYMENT,
    element: <OfficeRefundPage />,
    permission: PermissionV2.OFFICE_FINANCIAL_INFORMATION_MANAGE,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: true,
  },
  INVOICES: {
    ...OfficeRoutes.INVOICES,
    element: <InvoiceListPageV2 />,
    permission: PermissionV2.OFFICE_FINANCIAL_INFORMATION_INVOICES_VIEW,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: true,
  },
  ESTIMATES: {
    ...OfficeRoutes.ESTIMATES,
    element: <EstimatesV2ListPage />,
    permission: PermissionV2.OFFICE_FINANCIAL_INFORMATION_ESTIMATES_VIEW,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: true,
    header: 'Estimates',
  },
  ESTIMATE_V2_DETAILS: {
    ...OfficeRoutes.ESTIMATE_V2_DETAILS,
    element: <EstimatesV2DetailsPage />,
    permission: PermissionV2.OFFICE_FINANCIAL_INFORMATION_ESTIMATES_VIEW,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: true,
    header: 'Estimate',
  },
  ESTIMATE_CREATION: {
    ...OfficeRoutes.ESTIMATE_CREATION,
    calculate: CalculatePaths.jobDetails,
    element: <EstimatesV2CreationPage />,
    permission: PermissionV2.OFFICE_FINANCIAL_INFORMATION_MANAGE,
    principalUserRequiresCompany: true,
    header: 'New Estimate',
  },
  NEW_INVOICE_V2: {
    ...SharedRoutes.NEW_INVOICE_V2,
    element: <WebAppInvoiceCreationPage />,
    permission: PermissionV2.OFFICE_FINANCIAL_INFORMATION_MANAGE,
    principalUserRequiresCompany: true,
  },
  INVOICE_OVERVIEW: {
    ...SharedRoutes.INVOICE_OVERVIEW,
    element: <WebAppInvoiceOverviewPage />,
    permission: PermissionV2.OFFICE_FINANCIAL_INFORMATION_INVOICES_VIEW,
    principalUserRequiresCompany: true,
  },
  NOTIFICATIONS: {
    ...OfficeRoutes.NOTIFICATIONS,
    element: <NotificationsPage />,
    permission: noPermissionRequired,
    principalUserRequiresCompany: true,
  },
  MAINTENANCE_PLANS: {
    ...OfficeRoutes.MAINTENANCE_PLANS,
    element: <NewMaintenancePlansListPage />,
    permission: PermissionV1.VIEW_MAINTENANCE_PLANS,
    principalUserRequiresCompany: true,
  },
  PAYMENTS: {
    ...OfficeRoutes.PAYMENTS,
    element: <PaymentsListPage />,
    permission: PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYMENTS_VIEW,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: true,
  },
  ENTITY_HISTORY: {
    ...OfficeRoutes.ENTITY_HISTORY,
    element: <EntityHistoryPage />,
    permission: PermissionV2.OFFICE_FINANCIAL_INFORMATION_MANAGE,
    principalUserRequiresCompany: true,
  },
  PAYOUT_DETAILS: {
    ...OfficeRoutes.PAYOUT_DETAILS,
    element: <PayoutDetailsPage />,
    permission: PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYOUTS_VIEW,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: true,
  },
  PAYOUTS: {
    ...OfficeRoutes.PAYOUTS,
    element: <PayoutsListPage />,
    permission: PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYOUTS_VIEW,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: true,
  },
  TIMESHEETS: {
    ...OfficeRoutes.TIMESHEETS,
    element: <TimesheetsSummaryPage />,
    permission: PermissionV2.OFFICE_TIMESHEETS_VIEW,
    principalUserRequiresCompany: true,
  },
  TIMESHEET_DETAILS: {
    ...OfficeRoutes.TIMESHEET_DETAILS,
    element: <TimesheetDetailsPage />,
    permission: PermissionV2.OFFICE_TIMESHEETS_VIEW,
    principalUserRequiresCompany: true,
  },
  AI_SANDBOX: {
    ...OfficeRoutes.AI_SANDBOX,
    element: <AISandboxPage />,
    permission: PermissionV2.EXECUTE_DEV_TOOLS,
    principalUserRequiresCompany: false,
  },
  AI_RECOMMENDATION_SANDBOX: {
    ...OfficeRoutes.AI_RECOMMENDATION_SANDBOX,
    element: <AiRecommendationSandboxPage />,
    permission: PermissionV2.EXECUTE_DEV_TOOLS,
    principalUserRequiresCompany: false,
  },
  ANALYTICS_RECOMMENDATIONS: {
    ...OfficeRoutes.ANALYTICS_RECOMMENDATIONS,
    element: <RecommendationsAnalyticsPage />,
    permission: PermissionV2.EXECUTE_DEV_TOOLS,
    principalUserRequiresCompany: false,
  },
  BOOKKEEPING: {
    ...OfficeRoutes.BOOKKEEPING,
    element: <BookkeeperListPage />,
    permission: PermissionV2.OFFICE_FINANCIAL_INFORMATION_INVOICES_VIEW,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: true,
  },
  INTEGRATED_PHONE: {
    ...OfficeRoutes.INTEGRATED_PHONE,
    element: <CommsPage />,
    permission: PermissionV2.OFFICE_INTEGRATED_PHONE,
  },
  JOB_LEADS: {
    ...OfficeRoutes.JOB_LEADS,
    element: <JobLeadsPage />,
    permission: PermissionV2.OFFICE_ACCOUNTS_JOBS_MANAGE,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: true,
    header: 'Inbox',
  },
} satisfies ReactRouteMap

export type OfficeReactRoutesInfo = typeof OfficeReactRoutes
