import { gql } from '../../generated'

export const MAINTENANCE_PLAN_NEXT_VISIT_FRAGMENT = gql(/* GraphQL */ `
  fragment MaintenancePlanNextVisit on MaintenancePlanNextVisits {
    maintenancePlanVisitGuid
    maintenancePlanGuid
    affinityDate
    companyGuid
    completedAtOverride
    dueAt
    expiresAt
    isExpiredOverride
    isCompletedOverride
    issuedAt
    jobGuid
    name
    status: visitStatus
    visitNumber
    job {
      jobGuid
      displayId
      jobType {
        name
      }
      appointments {
        jobAppointmentGuid
      }
      jobLifecycleStatus {
        specialStatus
      }
      workCompletedAt
    }
    maintenancePlanVisitInstalledEquipments {
      installedEquipment {
        manufacturer
        installedEquipmentGuid
        equipmentType
        installationDate
        estimatedEndOfLifeDate
        averageLifeExpectancyYears
        modelNumber
        serialNumber
      }
    }
  }
`)

export const MAINTENANCE_PLANS_LIST_PAGE_QUERY_FRAGMENT = gql(/* GraphQL */ `
  fragment MaintenancePlansListPageFragment on MaintenancePlansQueryable {
    maintenancePlanGuid
    status
    paymentInterval
    paymentFlow
    activatedAt
    lapsedAt
    terminatesAt
    accountGuid
    paymentSubscriptionGuid
    numUnusedVisits
    account {
      accountDisplayName
      accountType
      accountContacts(where: { primary: { _eq: true } }) {
        contact {
          fullName
          primaryEmailAddress {
            emailAddress
          }
          primaryPhoneNumber {
            type
            phoneNumber
          }
        }
      }
      tags {
        tag {
          name
        }
      }
    }
    location {
      address {
        line1
        line2
        city
        stateAbbreviation
        zipCode
      }
      locationGuid
      installedEquipment(orderBy: { equipmentType: ASC }) {
        modelNumber
        serialNumber
        estimatedEndOfLifeDate
        manufacturer
        equipmentType
        installationDate
        installedEquipmentGuid
      }
    }
    locationGuid

    maintenancePlanDefinition {
      marketingInfo {
        name
      }
      flare {
        primaryColorHex
      }
    }

    maintenancePlanVisits {
      ...MaintenancePlanVisit
    }
    nextVisit {
      ...MaintenancePlanNextVisit
    }
  }
`)

export const NEW_MAINTENANCE_PLANS_LIST_PAGE_QUERY = gql(/* GraphQL */ `
  query MaintenancePlanListPageData(
    $where: MaintenancePlansQueryableBoolExp!
    $orderBy: [MaintenancePlansQueryableOrderBy!]!
    $limit: Int!
    $offset: Int!
  ) {
    maintenancePlansQueryable(
      limit: $limit
      offset: $offset
      where: $where
      orderBy: $orderBy
    ) {
      ...MaintenancePlansListPageFragment
    }
    maintenancePlansQueryableAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`)

export const MAINTENANCE_PLAN_COUNT_SUMMARY_QUERY = gql(/* GraphQL */ `
  query MaintenancePlanActivePlansSummary(
    $where: MaintenancePlansQueryableBoolExp!
  ) {
    maintenancePlansQueryableAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`)

export const MAINTENANCE_PLAN_YEARLY_PRICE_SUMMARY_QUERY = gql(/* GraphQL */ `
  query MaintenancePlanYearlyPriceSummary(
    $where: MaintenancePlansQueryableBoolExp!
  ) {
    maintenancePlansQueryableAggregate(where: $where) {
      aggregate {
        sum {
          yearlyPriceUsc
        }
      }
    }
  }
`)

export const MAINTENANCE_PLAN_OVERDUE_VISITS_SUMMARY_QUERY = gql(/* GraphQL */ `
  query MaintenancePlanOverdueVisitsSummary(
    $where: MaintenancePlansQueryableBoolExp!
  ) {
    maintenancePlansQueryableAggregate(where: $where) {
      aggregate {
        sum {
          numOverdueVisits
        }
      }
    }
  }
`)

export const COMPANY_MAINTENANCE_PLAN_TYPES_QUERY = gql(/* GraphQL */ `
  query CompanyMaintenancePlanTypes(
    $where: MaintenancePlanDefinitionsBoolExp!
  ) {
    maintenancePlanDefinitions(where: $where) {
      maintenancePlanDefinitionGuid
      marketingInfo {
        name
      }
    }
  }
`)

export const COMPANY_UNIQUE_ZIP_CODES_QUERY = gql(/* GraphQL */ `
  query GetCompanyUniqueZipCodes($companyGuid: uuid!) {
    locations(
      where: { companyGuid: { _eq: $companyGuid } }
      distinctOn: [addressGuid]
    ) {
      address {
        zipCode
      }
    }
  }
`)
