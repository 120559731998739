import {
  JobClass,
  MaintenancePlanDefinition,
  MaintenancePlanPaymentInterval,
  Mutable,
  formatJobClass,
  isNullish,
  nextGuid,
  usCentsToUsd,
  usdToUsCents,
} from '@breezy/shared'
import { faBox } from '@fortawesome/pro-light-svg-icons'
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
} from 'antd'
import { useForm, useWatch } from 'antd/lib/form/Form'
import TextArea from 'antd/lib/input/TextArea'
import { useCallback, useMemo, useState } from 'react'
import { BehindFeatureFlag } from '../../components/BehindFeatureFlag'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { FormCancelSubmitButtons } from '../../components/form-fields/FormCancelSubmitButtons/FormCancelSubmitButtons'
import { ItemPriceFormItem } from '../../components/form-fields/ItemPriceFormItem'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ThinDivider from '../../elements/ThinDivider'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { m } from '../../utils/react-utils'
import {
  validatorFloatNumberBetweenZeroAndOneHundred,
  validatorPositiveNonZeroInteger,
} from '../../utils/validators'

const { Option } = Select

type PricingType = 'STATIC' | 'EQUIPMENT_BASED'

export type MaintenancePlanDefinitionWithIntent = MaintenancePlanDefinition & {
  readonly intent: 'create' | 'edit'
}

type AddOrEditPlanDefinitionDrawerProps = {
  readonly refetch: () => void
  readonly onClose: () => void
  readonly open: boolean
  readonly planDefinition: MaintenancePlanDefinitionWithIntent | undefined
}

type AddOrEditPlanDefinitionFormModel = Omit<
  MaintenancePlanDefinitionWithIntent,
  'yearlyStaticPriceUsc'
> & {
  allowedPaymentIntervalValues: MaintenancePlanPaymentInterval[]
  defaultAllowedPaymentInterval: MaintenancePlanPaymentInterval
  yearlyStaticPriceUsd?: number | null
}

const AddOrEditPlanDefinitionForm = m(
  ({
    onClose,
    refetch,
    planDefinition,
  }: AddOrEditPlanDefinitionDrawerProps) => {
    const message = useMessage()
    const companyGuid = useExpectedCompanyGuid()
    const [form] = useForm<Mutable<AddOrEditPlanDefinitionFormModel>>()
    const isAddMode = !planDefinition || planDefinition.intent === 'create'
    const isEditMode = !isAddMode
    const [pricingType, setPricingType] = useState<PricingType>(
      planDefinition && isNullish(planDefinition.yearlyStaticPriceUsc)
        ? 'EQUIPMENT_BASED'
        : 'STATIC',
    )

    const allowedPaymentIntervalValues = useWatch(
      'allowedPaymentIntervalValues',
      form,
    )

    const mutation =
      trpc.maintenancePlans[
        'maintenance-plans-config:upsert-plan-definition'
      ].useMutation()

    const submit = useCallback(
      (values: AddOrEditPlanDefinitionFormModel) => {
        // TODO: BZ-1441 - Going to improve the Flare Styling experience, then can remove the Flare bits from this form completely
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const anonValues = values as any
        const flare = anonValues.primaryColorHex
          ? {
              imageUrl: '',
              primaryColorHex:
                typeof anonValues.primaryColorHex === 'string'
                  ? anonValues.primaryColorHex
                  : anonValues.primaryColorHex.toHexString(),
            }
          : undefined

        // Default allowed payment interval for old V1 Maintenance Plans.
        // We hardcode this here for backwards-compatibility when the V2 feature flag is off
        let allowedPaymentIntervals = isEditMode
          ? planDefinition?.allowedPaymentIntervals
          : [
              {
                maintenancePlanDefinitionAllowedPaymentIntervalGuid: nextGuid(),
                paymentInterval: MaintenancePlanPaymentInterval.MONTHLY,
                isDefault: true,
              },
            ]

        if (isAddMode && values.allowedPaymentIntervalValues) {
          if (values.allowedPaymentIntervalValues.length > 1) {
            allowedPaymentIntervals = values.allowedPaymentIntervalValues.map(
              value => ({
                paymentInterval: value,
                isDefault:
                  value === values.defaultAllowedPaymentInterval ? true : false,
                maintenancePlanDefinitionAllowedPaymentIntervalGuid: nextGuid(),
              }),
            )
          } else if (values.allowedPaymentIntervalValues.length === 1) {
            allowedPaymentIntervals = values.allowedPaymentIntervalValues.map(
              value => ({
                paymentInterval: value,
                isDefault: true,
                maintenancePlanDefinitionAllowedPaymentIntervalGuid: nextGuid(),
              }),
            )
          } else {
            message.error('Must select at least one billing period')
            return
          }
        }

        const req = {
          ...values,
          companyGuid,
          maintenancePlanDefinitionGuid: isAddMode
            ? nextGuid()
            : planDefinition?.maintenancePlanDefinitionGuid,
          benefits:
            values.benefits?.map(b => ({
              name: b.name,
              description: b.description,
              maintenancePlanDefinitionBenefitGuid:
                b.maintenancePlanDefinitionBenefitGuid ?? nextGuid(),
            })) ?? [],
          discounts:
            values.discounts?.map(d => ({
              discountJobClass: d.discountJobClass,
              discountRate: (d.discountRate ?? 0) / 100,
              maintenancePlanDefinitionDiscountGuid:
                d.maintenancePlanDefinitionDiscountGuid ?? nextGuid(),
            })) ?? [],
          allowedPaymentIntervals,
          yearlyStaticPriceUsc:
            pricingType === 'STATIC' && !isNullish(values.yearlyStaticPriceUsd)
              ? usdToUsCents(values.yearlyStaticPriceUsd)
              : null,
          numVisitCreditsPerYear: Number(values.numVisitCreditsPerYear),
          numDaysUntilVisitCreditExpiration: Number(
            values.numDaysUntilVisitCreditExpiration,
          ),
          isAvailableForSale: values.isAvailableForSale ?? true,
          flare,
        }

        mutation.mutateAsync(req, {
          onSuccess: () => {
            message.success('Plan successfully saved')
            onClose()
            refetch()
          },
          onError: () => {
            message.error('There was an error saving this plan')
          },
        })
      },
      [
        isEditMode,
        planDefinition?.allowedPaymentIntervals,
        planDefinition?.maintenancePlanDefinitionGuid,
        isAddMode,
        companyGuid,
        pricingType,
        mutation,
        message,
        onClose,
        refetch,
      ],
    )

    const onChangePricingType = useCallback(
      ({ target: { value } }: RadioChangeEvent) => {
        setPricingType(value)
      },
      [],
    )

    const initialAllowedPaymentIntervalValues: MaintenancePlanPaymentInterval[] =
      useMemo(
        () =>
          !isNullish(planDefinition)
            ? planDefinition?.allowedPaymentIntervals.map(
                pi => pi.paymentInterval,
              )
            : [
                MaintenancePlanPaymentInterval.YEARLY,
                MaintenancePlanPaymentInterval.MONTHLY,
              ],
        [planDefinition],
      )

    const initialDefaultPaymentInterval: MaintenancePlanPaymentInterval =
      useMemo(
        () =>
          !isNullish(planDefinition)
            ? planDefinition?.allowedPaymentIntervals.find(pi => pi.isDefault)
                ?.paymentInterval ?? MaintenancePlanPaymentInterval.YEARLY
            : MaintenancePlanPaymentInterval.YEARLY,
        [planDefinition],
      )

    const initialValues = useMemo(
      () =>
        ({
          ...planDefinition,
          allowedPaymentIntervalValues: initialAllowedPaymentIntervalValues,
          defaultAllowedPaymentInterval: initialDefaultPaymentInterval,
          yearlyStaticPriceUsd:
            planDefinition && !isNullish(planDefinition.yearlyStaticPriceUsc)
              ? usCentsToUsd(planDefinition.yearlyStaticPriceUsc)
              : 0,
          discounts: planDefinition?.discounts.map(d => ({
            ...d,
            discountRate: Number((d.discountRate * 100).toFixed(2)),
          })),
        } as Mutable<AddOrEditPlanDefinitionFormModel>),
      [
        initialAllowedPaymentIntervalValues,
        initialDefaultPaymentInterval,
        planDefinition,
      ],
    )

    return (
      <div className="h-full">
        {mutation.isLoading && <LoadingSpinner />}
        {!mutation.isLoading && (
          <Form
            form={form}
            layout="vertical"
            onFinish={submit}
            validateTrigger="onBlur"
            initialValues={initialValues}
            className="h-full"
            data-testid="maintenance-plan-type-form"
          >
            <div className="flex-between column h-full">
              <div className="col">
                <div className="row">
                  <div className="col mr-2 w-1/4">
                    <h3 className="semibold_16_24 gray9">Plan Details</h3>
                    <div className="regular_14_22 gray7">
                      General information and details about this maintenance
                      plan.
                    </div>
                  </div>
                  <div className="col w-3/4">
                    <Form.Item
                      name="name"
                      label="Plan Name"
                      extra={
                        <div className="regular_12_20 gray7 mt-[2px]">
                          Public facing name for this plan. This is displayed to
                          customers when choosing a plan
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: 'Please a name for this plan',
                        },
                      ]}
                    >
                      <Input placeholder="Ex. Gold Plan" />
                    </Form.Item>

                    <Form.Item
                      name="shortDescription"
                      label="Plan Description"
                      extra={
                        <div className="regular_12_20 gray7 mt-[2px]">
                          General description of this plan to describe plan
                          details and why a customer should choose this plan
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: 'Please enter a description for this plan',
                        },
                      ]}
                    >
                      <TextArea
                        placeholder="Ex. The gold plan provides the highest level of confidence and customer service for your HVAC systems..."
                        rows={4}
                      />
                    </Form.Item>

                    <div className="row gap-x-2">
                      <Form.Item
                        className="w-1/2"
                        name="numVisitCreditsPerYear"
                        label="Maintenance appointments per year"
                        extra={
                          <div className="regular_12_20 gray7 mt-[2px]">
                            The number of maintenance appointments per year
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message:
                              'Please enter the number of maintenance appointments per year',
                            validator: validatorPositiveNonZeroInteger(
                              'Number of Maintenance Appointments',
                            ),
                          },
                        ]}
                      >
                        <Input placeholder="Ex. 2" />
                      </Form.Item>
                      <Form.Item
                        className="mr-2 w-1/2"
                        name="numDaysUntilVisitCreditExpiration"
                        label="Number of Days Until Visit Expires"
                        extra={
                          <div className="regular_12_20 gray7 mt-[2px]">
                            The number of days before a visit credit expires
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message:
                              'Please enter the number of days before a visit credit expires',
                            validator: validatorPositiveNonZeroInteger(
                              'Number of Days Until Visit Expires',
                            ),
                          },
                        ]}
                      >
                        <Input placeholder="Ex. 365" />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <ThinDivider />
                <div className="row">
                  <div className="col mr-2 w-1/4">
                    <h3 className="semibold_16_24 gray9">
                      Plan Pricing & Billing
                    </h3>
                    <div className="regular_14_22 gray7">
                      Configure how the plan is priced and which billing periods
                      are allowed when a customer selects this plan
                    </div>
                  </div>
                  <div className="col w-3/4 py-2">
                    <BehindFeatureFlag
                      enabledFeatureFlag="createMaintenancePlanV2"
                      render={<></>}
                      fallback={
                        <Form.Item label="Pricing">
                          <Radio.Group
                            options={[
                              { label: 'Flat', value: 'STATIC' },
                              {
                                label: 'Equipment-Based',
                                value: 'EQUIPMENT_BASED',
                              },
                            ]}
                            onChange={onChangePricingType}
                            value={pricingType}
                            optionType="button"
                            buttonStyle="solid"
                          />
                        </Form.Item>
                      }
                    />

                    {pricingType === 'STATIC' && (
                      <>
                        <div className="col regular_14_22 gray7">
                          Customers will be charged a flat rate, regardless of
                          what equipment is covered in their plan
                        </div>
                        <ItemPriceFormItem
                          name="yearlyStaticPriceUsd"
                          label=""
                          extra="Annual Price (USD)"
                          className="mr-2 w-1/2 pr-2"
                          min={0}
                          data-testid="yearlyStaticPriceUsd"
                        />
                      </>
                    )}
                    {pricingType === 'EQUIPMENT_BASED' && (
                      <div className="col regular_14_22 gray7">
                        Customers will be charged based on the cost of servicing
                        equipment that is covered by the plan
                      </div>
                    )}

                    <div className="h-2"></div>
                    <Form.Item
                      // This causes the allowedPaymentIntervals property to show up as a value
                      // on form submission. Defining the value in Form's initialValues is not
                      // sufficient otherwise
                      name="allowedPaymentIntervals"
                      hidden={true}
                    ></Form.Item>
                    <BehindFeatureFlag
                      enabledFeatureFlag="createMaintenancePlanV2"
                      render={<></>}
                      fallback={
                        <Form.Item
                          name="allowedPaymentIntervalValues"
                          label="Billing Periods"
                          rules={[
                            {
                              validator: (
                                _,
                                paymentIntervalValues: MaintenancePlanPaymentInterval[],
                              ) => {
                                if (paymentIntervalValues.length <= 0) {
                                  return Promise.reject(
                                    new Error(
                                      'You must select at least one billing period',
                                    ),
                                  )
                                }
                                return Promise.resolve()
                              },
                            },
                          ]}
                        >
                          <Checkbox.Group className="-ml-2">
                            <Row>
                              <Col>
                                <Checkbox
                                  value={MaintenancePlanPaymentInterval.YEARLY}
                                >
                                  Annually
                                </Checkbox>
                              </Col>
                              <Col>
                                <Checkbox
                                  value={MaintenancePlanPaymentInterval.MONTHLY}
                                >
                                  Monthly
                                </Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      }
                    />
                    {allowedPaymentIntervalValues &&
                      allowedPaymentIntervalValues.length > 1 && (
                        <Row>
                          <Form.Item
                            name="defaultAllowedPaymentInterval"
                            extra={
                              <div className="regular_12_20 gray7 mt-[2px]">
                                Default Billing Period
                              </div>
                            }
                          >
                            <Select>
                              <Option value="YEARLY" key={'YEARLY'}>
                                Annually
                              </Option>
                              <Option value="MONTHLY" key={'MONTHLY'}>
                                Monthly
                              </Option>
                            </Select>
                          </Form.Item>
                        </Row>
                      )}
                  </div>
                </div>

                <ThinDivider />
                <div className="row">
                  <div className="col mr-2 w-1/4">
                    <h3 className="semibold_16_24 gray9">Plan Discounts</h3>
                    <div className="regular_14_22 gray7">
                      Discounts included with the plan, to be automatically
                      applied to estimates and invoices of the matching job
                      class
                    </div>
                  </div>
                  <div className="col w-3/4">
                    <Form.List name="discounts">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <div className="col" key={key}>
                              <div className="row gap-2">
                                <Form.Item
                                  className="w-1/2"
                                  {...restField}
                                  label="Discount Job Class"
                                  name={[name, 'discountJobClass']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Discount Job Class is required',
                                    },
                                  ]}
                                >
                                  <Select>
                                    {[
                                      JobClass.INSTALL,
                                      JobClass.SERVICE,
                                      JobClass.MAINTENANCE,
                                    ].map(jobClass => (
                                      <Select.Option
                                        value={jobClass}
                                        key={jobClass}
                                      >
                                        {formatJobClass(jobClass)}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  className="w-1/2"
                                  {...restField}
                                  label="Discount (%)"
                                  name={[name, 'discountRate']}
                                  extra={
                                    <div className="regular_12_20 gray7 mt-[2px]">
                                      Discount percentage for this job class.
                                      Ranges from 0-100%
                                    </div>
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        'Discount percentage is required',
                                      validator:
                                        validatorFloatNumberBetweenZeroAndOneHundred(
                                          'Discount (%)',
                                        ),
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    placeholder="5"
                                    min={0}
                                    max={100}
                                    suffix="%"
                                    controls={false}
                                  />
                                </Form.Item>
                              </div>
                              <Form.Item>
                                <Button
                                  danger
                                  onClick={() => remove(name)}
                                  className="mt-2"
                                >
                                  Remove
                                </Button>
                              </Form.Item>
                              <ThinDivider />
                            </div>
                          ))}
                          <Form.Item className="mt-2">
                            <Button
                              type="primary"
                              onClick={() => add()}
                              block
                              icon="+ " // NOTE: Needed to add space
                              data-testid="add-discount-button"
                            >
                              Add Discount
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </div>
                </div>
                <ThinDivider />

                <div className="row">
                  <div className="col mr-2 w-1/4">
                    <h3 className="semibold_16_24 gray9">
                      Additional Plan Benefits
                    </h3>
                    <div className="regular_14_22 gray7">
                      Additional benefits and value provided to the customer for
                      being a member of this maintenance plan.
                    </div>
                  </div>
                  <div className="col w-3/4">
                    <Form.List name="benefits">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <div className="col" key={key}>
                              <Form.Item
                                className="max-w-[360px] pr-2"
                                {...restField}
                                label="Benefit Name"
                                name={[name, 'name']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Benefit name is required',
                                  },
                                ]}
                              >
                                <Input placeholder="Ex. Priority customer service" />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                label="Benefit Description"
                                name={[name, 'description']}
                                extra={
                                  <div className="regular_12_20 gray7 mt-[2px]">
                                    Additional details about this benefit that
                                    explain the terms or value to the customer
                                  </div>
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: 'Benefit description is required',
                                  },
                                ]}
                              >
                                <TextArea
                                  placeholder="Ex. Priority scheduling for service calls and appointments..."
                                  rows={6}
                                />
                              </Form.Item>
                              <Form.Item>
                                <Button
                                  danger
                                  onClick={() => remove(name)}
                                  className="mt-2"
                                >
                                  Remove
                                </Button>
                              </Form.Item>
                              <ThinDivider />
                            </div>
                          ))}
                          <Form.Item className="mt-2">
                            <Button
                              type="primary"
                              onClick={() => add()}
                              block
                              icon="+ " // NOTE: Needed to add space
                            >
                              Add Benefit
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </div>
                </div>
                <ThinDivider />
                <div className="row">
                  <div className="col mr-2 w-1/4">
                    <h3 className="semibold_16_24 gray9">Terms & Conditions</h3>
                    <div className="regular_14_22 gray7">
                      Include any legal agreements or terms & conditions
                      applicable to this plan.
                    </div>
                  </div>
                  <div className="col w-3/4">
                    <Form.Item
                      name="termsAndConditions"
                      label="Terms & Conditions"
                      rules={[
                        {
                          required: true,
                          message:
                            'Please enter terms and descriptions for this plan',
                        },
                      ]}
                    >
                      <TextArea
                        placeholder="Enter your terms & conditions here..."
                        rows={12}
                      />
                    </Form.Item>
                  </div>
                </div>
                <ThinDivider />
                <div className="row">
                  <div className="col mr-2 w-1/4">
                    <h3 className="semibold_16_24 gray9">Visual Flare</h3>
                    <div className="regular_14_22 gray7">
                      Custom styling for clear customer and account
                      visualization in the app and on customer-facing
                      documentation
                    </div>
                  </div>
                  <div className="col w-3/4">
                    <Form.Item
                      className="w-1/2"
                      name="primaryColorHex"
                      label="Plan Primary Color"
                      initialValue={
                        planDefinition?.flare?.primaryColorHex ?? '#d9d9d9'
                      }
                      extra={
                        <div className="regular_12_20 gray7 mt-[2px]">
                          Plan primary color. Used for Breezy App VIP
                          visualization.
                        </div>
                      }
                    >
                      <ColorPicker defaultFormat="hex" disabledAlpha />
                    </Form.Item>
                  </div>
                </div>
                <ThinDivider />
              </div>
              <FormCancelSubmitButtons
                onCancel={onClose}
                primaryButtonText="Save"
              />
            </div>
          </Form>
        )}
      </div>
    )
  },
)

const AddOrEditPlanDefinitionDrawer = m(
  ({
    open,
    refetch,
    onClose,
    planDefinition,
  }: AddOrEditPlanDefinitionDrawerProps) => (
    <Drawer
      title={
        <PageTitle
          icon={faBox}
          title={
            planDefinition?.intent !== 'edit'
              ? 'Create New Maintenance Plan Type'
              : 'Edit Maintenance Plan Type'
          }
        />
      }
      onClose={onClose}
      open={open}
      width={1024}
      destroyOnClose={true}
    >
      <AddOrEditPlanDefinitionForm
        open={open}
        refetch={refetch}
        onClose={onClose}
        planDefinition={planDefinition}
      />
    </Drawer>
  ),
)

export default AddOrEditPlanDefinitionDrawer
