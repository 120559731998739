import { convertPricebookCategoriesToCsvFile } from '@breezy/shared'
import { useCallback } from 'react'
import { useMessage } from '../../../utils/antd-utils'
import { downloadCsv } from '../../../utils/export-to-csv'
import { usePricebookAdmin } from './usePricebookAdmin'

export const useDownloadPricebookCategoriesCsv = () => {
  const { categories } = usePricebookAdmin()
  const message = useMessage()

  return useCallback(
    (fileName: string) => {
      if (!categories) {
        message.error('No pricebook categories to export.')
        return
      }
      const csvFile = convertPricebookCategoriesToCsvFile(categories)
      downloadCsv(csvFile, `${fileName}.csv`)
      message.success('Pricebook categories exported successfully.')
    },
    [categories, message],
  )
}
