import { gql } from '../../generated'

export const FETCH_QUICKBOOKS_CART_ITEM_TYPE_ACCOUNTS_GQL = gql(/* GraphQL */ `
  query FetchQboCartItemTypeAccounts($companyGuid: uuid!) {
    qboCartItemTypeAccountsConfig(
      where: { companyGuid: { _eq: $companyGuid } }
    ) {
      defaultItemQboIncomeAccountId
      equipmentItemDefaultQboIncomeAccountId
      laborItemDefaultQboIncomeAccountId
      materialItemDefaultQboIncomeAccountId
      membershipItemDefaultQboIncomeAccountId
      serviceItemDefaultQboIncomeAccountId
    }
  }
`)

export const UPSERT_QUICKBOOKS_CART_ITEM_TYPE_ACCOUNTS_GQL = gql(/* GraphQL */ `
  mutation UpsertQuickbooksCartItemAccountsConfig(
    $object: QboCartItemTypeAccountsConfigInsertInput!
  ) {
    insertQboCartItemTypeAccountsConfigOne(
      object: $object
      onConflict: {
        constraint: qbo_cart_item_type_accounts_config_company_guid_key
        updateColumns: [
          defaultItemQboIncomeAccountId
          equipmentItemDefaultQboIncomeAccountId
          serviceItemDefaultQboIncomeAccountId
          laborItemDefaultQboIncomeAccountId
          materialItemDefaultQboIncomeAccountId
          membershipItemDefaultQboIncomeAccountId
          updatedAt
        ]
      }
    ) {
      __typename
    }
  }
`)
