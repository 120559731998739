import {
  PhoneNumberType,
  phoneUtils,
  prettifyPhoneNumberType,
} from '@breezy/shared'
import React from 'react'

type BaseContactCellProps = {
  contactName?: string
  contactPhoneNumber?: string
  contactPhoneType?: PhoneNumberType
  contactEmail?: string
}

export const BaseContactCell = React.memo<BaseContactCellProps>(
  ({ contactName, contactPhoneNumber, contactPhoneType, contactEmail }) => {
    return (
      <>
        <div>{contactName ?? 'Unknown'}</div>
        {contactPhoneNumber ? (
          <div className="mt-1 text-bz-gray-900">
            <span className="whitespace-nowrap">
              {phoneUtils.tryFormat(contactPhoneNumber)}
            </span>
            <span className="ml-2 text-bz-gray-700">
              ({prettifyPhoneNumberType(contactPhoneType ?? 'MOBILE')})
            </span>
          </div>
        ) : contactEmail ? (
          <a
            className="mt-1 whitespace-nowrap text-bz-gray-900"
            href={`mailto:${contactEmail}`}
          >
            {contactEmail}
          </a>
        ) : null}
      </>
    )
  },
)

type ContactCellProps = {
  job?: {
    pointOfContact: {
      fullName?: string
      primaryPhoneNumber?: {
        phoneNumber: string
        type: PhoneNumberType
      }
      primaryEmailAddress?: {
        emailAddress: string
      }
    }
  }
  account?: {
    accountContacts: {
      contact: {
        fullName?: string
        primaryPhoneNumber?: {
          phoneNumber: string
          type: PhoneNumberType
        }
        primaryEmailAddress?: {
          emailAddress: string
        }
      }
    }[]
  }
}

export const ContactCell = React.memo<ContactCellProps>(({ job, account }) => {
  const contactName =
    job?.pointOfContact.fullName ??
    account?.accountContacts[0]?.contact.fullName

  const contactPhone =
    job?.pointOfContact.primaryPhoneNumber ??
    account?.accountContacts[0]?.contact.primaryPhoneNumber

  const contactEmail =
    job?.pointOfContact.primaryEmailAddress?.emailAddress ??
    account?.accountContacts[0]?.contact.primaryEmailAddress?.emailAddress
  return (
    <BaseContactCell
      contactName={contactName}
      contactEmail={contactEmail}
      contactPhoneNumber={contactPhone?.phoneNumber}
      contactPhoneType={contactPhone?.type}
    />
  )
})
