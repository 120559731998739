import { PageHeader } from '@ant-design/pro-components'
import { AppointmentChecklist, Guid, R } from '@breezy/shared'
import { faListCheck } from '@fortawesome/pro-light-svg-icons'
import { Button, ConfigProvider, Empty, Input, Table, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React, { useCallback, useMemo, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { ViewResourceButton } from '../../elements/ViewResourceButton/ViewResourceButton'
import { trpc } from '../../hooks/trpc'
import { useQueryParamState, useSetQueryParam } from '../../utils/react-utils'
import { Page } from '../Page/Page'
import TrpcQueryLoader from '../TrpcQueryLoader'
import { EditAppointmentChecklistSidebar } from './EditAppointmentChecklistSidebar'

type TableChecklistRow = {
  appointmentChecklistGuid: Guid
  name: string
  associations: string[]
}

type ChecklistViewButtonProps = {
  checklistGuid: Guid
}

const ChecklistViewButton = React.memo<ChecklistViewButtonProps>(
  ({ checklistGuid }) => {
    const setQueryParam = useSetQueryParam('checklist')
    const onClick = useCallback(
      () => setQueryParam(checklistGuid),
      [checklistGuid, setQueryParam],
    )
    return <ViewResourceButton onClick={onClick} />
  },
)

type AppointmentChecklistSettingsPageInnerProps = {
  data: AppointmentChecklist[]
  refetch: () => Promise<unknown>
}

const AppointmentChecklistSettingsPageInner =
  React.memo<AppointmentChecklistSettingsPageInnerProps>(
    ({ data, refetch }) => {
      const getChecklistsQuery =
        trpc.appointmentChecklist['appointment-checklists:get'].useQuery()

      const [searchText, setSearchText] = useState('')
      const processedData = useMemo<TableChecklistRow[]>(
        () =>
          data.map(({ appointmentChecklistGuid, name, associationRules }) => ({
            appointmentChecklistGuid,
            name,
            associations: associationRules.map(
              ({ jobType, appointmentType, required }) =>
                `${jobType ? jobType.name : 'Any'} / ${
                  appointmentType ?? 'Any'
                }`,
            ),
          })) || [],
        [data],
      )

      const filteredData = useMemo(() => {
        const lcSearchText = searchText.toLowerCase()
        return processedData.filter(row => {
          for (const value of R.values(row)) {
            // Filtering based on the strings "true" and "false" is not helpful
            if (typeof value === 'boolean') {
              continue
            }
            if (`${value}`.toLowerCase().includes(lcSearchText)) {
              return true
            }
          }

          return false
        })
      }, [processedData, searchText])

      const [selectedChecklistGuid, setSelectedChecklistGuid] =
        useQueryParamState('checklist', '')

      const selectedChecklist = useMemo(
        () =>
          getChecklistsQuery.data?.find(
            checklist =>
              checklist.appointmentChecklistGuid === selectedChecklistGuid,
          ),
        [getChecklistsQuery.data, selectedChecklistGuid],
      )

      const columns = useMemo(() => {
        const columns: ColumnsType<TableChecklistRow> = [
          {
            title: '',
            dataIndex: 'appointmentChecklistGuid',
            key: 'appointmentChecklistGuid',
            render: (_, record) => (
              <ChecklistViewButton
                checklistGuid={record.appointmentChecklistGuid}
              />
            ),
            width: 64,
            fixed: 'left',
          },
          {
            title: 'Name',
            dataIndex: 'name',
            sorter: R.ascend(R.prop('name')),
          },

          {
            title: 'Job / Appointment Associations',
            dataIndex: 'associations',
            width: 500,
            sorter: R.ascend(checklist => checklist.associations.join(', ')),
            render: (_, checklist) => {
              return (
                <div>
                  {checklist.associations.map((association, i) => (
                    <Tag key={i}>{association}</Tag>
                  ))}
                </div>
              )
            },
          },
        ]
        return columns
      }, [])

      const [addingNew, setAddingNew] = useState(false)

      const onSidebarClose = useCallback(() => {
        setSelectedChecklistGuid()
        setAddingNew(false)
      }, [setSelectedChecklistGuid])

      return (
        <Page requiresCompanyUser className="overflow-hidden p-0">
          <PageHeader
            title={
              <PageTitle title="Appointment Checklists" icon={faListCheck} />
            }
          />
          <div className="card flex flex-col">
            <div className="mb-4 flex flex-row items-end">
              <div className="flex-1">
                <p className="text-gray-70 mb-0 font-extralight">
                  Appointment checklists allow you to create steps that need to
                  be completed by members of your team. Use checklists to
                  automatically assign responsibilities during appointments for
                  your team.
                </p>
              </div>
              <div className="flex flex-1 flex-row justify-end gap-2">
                <Input
                  placeholder="Search"
                  value={searchText}
                  className="w-64"
                  prefix={<AiOutlineSearch />}
                  onChange={e => setSearchText(e.target.value)}
                />
                <Button type="primary" onClick={() => setAddingNew(true)}>
                  + New Checklist
                </Button>
              </div>
            </div>
            <ConfigProvider
              renderEmpty={() => (
                <Empty
                  description="No checklists"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            >
              <Table
                columns={columns}
                dataSource={filteredData}
                rowKey="appointmentChecklistGuid"
              />
            </ConfigProvider>
            {(selectedChecklist || addingNew) && (
              <EditAppointmentChecklistSidebar
                checklist={selectedChecklist}
                onClose={onSidebarClose}
                refetch={refetch}
              />
            )}
          </div>
        </Page>
      )
    },
  )

export const AppointmentChecklistSettingsPage = React.memo(() => {
  const getChecklistsQuery =
    trpc.appointmentChecklist['appointment-checklists:get'].useQuery()

  return (
    <TrpcQueryLoader
      query={getChecklistsQuery}
      render={data => (
        <AppointmentChecklistSettingsPageInner
          data={data}
          refetch={getChecklistsQuery.refetch}
        />
      )}
    />
  )
})
