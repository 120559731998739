import cn from 'classnames'
import { m } from '../../utils/react-utils'

type BzMonospaceProps = {
  text: string
  align?: 'text-left' | 'text-center' | 'text-right'
}

export const BzMonospace = m<BzMonospaceProps>(
  ({ text, align = 'text-left' }) => (
    <div className={cn('mono', align)}>{text}</div>
  ),
)
