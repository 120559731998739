import { PageHeader } from '@ant-design/pro-components'
import { bzExpect } from '@breezy/shared'
import { faUser } from '@fortawesome/pro-light-svg-icons'
import React from 'react'
import { useParams } from 'react-router-dom'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Page } from '../../components/Page/Page'
import { PermissionControlsProvider } from '../../components/PermissionControls/usePermissionControls'
import SettingsCreateEditUserForm from '../../components/SettingsTeam/SettingsCreateEditTeamMemberForm'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'
import { trpc } from '../../hooks/trpc'
import { useMessage } from '../../utils/antd-utils'

const SettingsEditTeamMemberPage = React.memo(() => {
  const message = useMessage()
  const userGuid = bzExpect(
    useParams<{ userGuid: string }>().userGuid,
    'userGuid',
    'User ID is required',
  )

  const query = trpc.user['users:get-by-guid'].useQuery({ userGuid })

  return (
    <Page requiresCompanyUser className="overflow-hidden p-0">
      <PageHeader
        title={<PageTitle title="Edit Team Member" icon={faUser} />}
      />
      <ScrollCard
        className="relative flex flex-col overflow-auto p-9"
        hasPageHeading
      >
        <TrpcQueryLoader
          query={query}
          errorMessage={`Failed to load user by ID: ${userGuid}`}
          loadingComponent={
            <div className="flex h-full w-full items-center justify-center">
              <LoadingSpinner />
            </div>
          }
          render={({ user }) => (
            <PermissionControlsProvider
              permissions={Array.from(user.permissionsV2)}
            >
              <SettingsCreateEditUserForm
                user={user}
                savedUser={() => {
                  message.success('Your changes have been saved.')
                }}
              />
            </PermissionControlsProvider>
          )}
        />
      </ScrollCard>
    </Page>
  )
})

export default SettingsEditTeamMemberPage
