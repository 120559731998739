import {
  BzDateFns,
  TechnicianPerformanceReportRequest,
  TechnicianPerformanceReportRequestSchema,
  generateTechnicianPerformanceCsv,
} from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Col, DatePicker, Form, Row } from 'antd'
import dayjs from 'dayjs'
import React, { useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { downloadCsv } from '../../utils/export-to-csv'
import './TechnicianPerformanceGenerateReportForm.less'

const TechnicianPerformanceGenerateReportForm = React.memo(() => {
  const btnFormSubmit = useRef<HTMLInputElement>(null)
  const tzId = useExpectedCompanyTimeZoneId()
  const message = useMessage()
  const todaysDate = BzDateFns.format(BzDateFns.now(tzId), 'yyyy-MM-dd')

  const generateReportMut =
    trpc.technicianPerformance[
      'technician-performance:generate-report'
    ].useMutation()

  const form = useForm<TechnicianPerformanceReportRequest>({
    resolver: zodResolver(TechnicianPerformanceReportRequestSchema),
  })

  const onSubmit = form.handleSubmit(async values => {
    try {
      const report = await generateReportMut.mutateAsync({
        dateRange: values.dateRange,
      })

      downloadCsv(
        generateTechnicianPerformanceCsv(report, tzId),
        `technician-performance-report-${todaysDate}.csv`,
      )

      // Reset the form to non-dirty state without resetting values
      form.reset(form.getValues())

      message.success('Successfully generated Technician Performance report')
    } catch (e) {
      message.error(
        'Failed to generate Technician Performance report. Please reload the application and try again, or contact support if the problem persists.',
      )
      console.error(e)
    }
  })

  return (
    <form
      className="bz-technician-performance-generate-report-form"
      onSubmit={onSubmit}
    >
      <Row justify="space-between">
        <Col span={8}>
          <h3 className="grey9">Generate Report</h3>
          <p className="regular_14_22 grey8 max-w-[700px] font-light">
            Export a report that contains performance-related data (jobs,
            revenue, ticket size, conversion rate, etc.) for each technician.
          </p>
        </Col>
        <Col span={8}>
          <Controller
            name="dateRange"
            control={form.control}
            render={({ field }) => (
              <Form.Item
                className="bz-date-picker-form-item m-0"
                label="Select Date Range"
                colon={false}
                required
                validateStatus={form.formState.errors.dateRange && 'error'}
                help={form.formState.errors.dateRange?.message}
              >
                <DatePicker.RangePicker
                  className="w-full"
                  picker="date"
                  format="MM/DD/YYYY"
                  onChange={dates => {
                    const [start, end] = dates || []
                    field.onChange({
                      startAt: start
                        ? BzDateFns.formatISO(start.toDate(), tzId)
                        : undefined,
                      endAt: end
                        ? BzDateFns.formatISO(end.toDate(), tzId)
                        : undefined,
                    })
                  }}
                  value={[
                    field.value?.startAt
                      ? dayjs(BzDateFns.parseISO(field.value.startAt, tzId))
                      : null,
                    field.value?.endAt
                      ? dayjs(BzDateFns.parseISO(field.value.endAt, tzId))
                      : null,
                  ]}
                />
              </Form.Item>
            )}
          />
          <div className="mt-1 leading-6 text-bz-gray-700">
            The report will be in .CSV format.
          </div>
          <Button
            loading={generateReportMut.isLoading}
            className="mt-3"
            type="primary"
            htmlType="submit"
          >
            Generate Report
          </Button>
          {/* Submitting our react-hook-form directly through antd component doesn't seem
                to work, so we create this hidden button with an attached ref so we can use it
                to trigger a form submission */}
          <input ref={btnFormSubmit} type="submit" className="hidden" />
        </Col>
      </Row>
    </form>
  )
})

export default TechnicianPerformanceGenerateReportForm
