import { PageHeader } from '@ant-design/pro-components'
import { faUser } from '@fortawesome/pro-light-svg-icons'
import React from 'react'
import { Page } from '../../components/Page/Page'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'

import { PermissionControlsProvider } from '../../components/PermissionControls/usePermissionControls'
import SettingsCreateEditUserForm from '../../components/SettingsTeam/SettingsCreateEditTeamMemberForm'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { notifySaveUserSuccess } from './utils'

const SettingsCreateTeamMemberPage = React.memo(() => {
  return (
    <Page requiresCompanyUser className="overflow-hidden p-0">
      <PageHeader title={<PageTitle title="Add Team Member" icon={faUser} />} />
      <ScrollCard
        className="relative flex flex-col overflow-auto p-9"
        hasPageHeading
      >
        <PermissionControlsProvider>
          <SettingsCreateEditUserForm
            savedUser={() =>
              notifySaveUserSuccess(
                'New team member successfully created',
                'An invite email has been sent to the team member to join your account.',
              )
            }
          />
        </PermissionControlsProvider>
      </ScrollCard>
    </Page>
  )
})

export default SettingsCreateTeamMemberPage
