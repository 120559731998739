import { PageHeader } from '@ant-design/pro-components'
import {
  BzDateFns,
  bzExpect,
  getTotalMinutesPerDayForTimesheetEntries,
  isNullish,
  toRoleDisplayName,
} from '@breezy/shared'
import { useEffect, useMemo, useState } from 'react'
import { useExpectedCompany } from 'src/providers/PrincipalUser'
import { useQuery } from 'urql'
import { Page } from '../../components/Page/Page'
import { TimesheetPeriodPicker } from '../../components/Timesheets/TimesheetPeriodPicker'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { usePayPeriodConfig } from '../../hooks/fetch/useFetchTimesheetsConfig'
import { TimesheetsIcon } from '../../utils/feature-icons'
import { TIMESHEET_DETAILS_SUMMARY_QUERY } from './TimesheetsSummaryPage.gql'
import {
  TimesheetReportItem,
  TimesheetsSummaryTable,
} from './TimesheetsSummaryTable'
import { generateDayHeadersForSummary } from './util'

export const TimesheetsSummaryPage = () => {
  const company = useExpectedCompany()
  const payPeriodConfig = usePayPeriodConfig()
  const [timeWindow, setTimeWindow] = useState(
    payPeriodConfig?.currentPayPeriod,
  )

  const [timesheetDetailsSummaryRes] = useQuery({
    query: TIMESHEET_DETAILS_SUMMARY_QUERY,
    variables: {
      companyGuid: company.companyGuid,
      startDate: BzDateFns.formatISO(
        BzDateFns.startOfDay(
          BzDateFns.parseISO(bzExpect(timeWindow?.start), company.timezone),
        ),
        company.timezone,
      ),
      endDate: BzDateFns.formatISO(
        BzDateFns.endOfDay(
          BzDateFns.parseISO(bzExpect(timeWindow?.end), company.timezone),
        ),
        company.timezone,
      ),
    },
    pause: isNullish(timeWindow),
  })

  const [filteredTechsGuids, setFilteredTechsGuids] = useState<string[]>([])

  const tableItems: TimesheetReportItem[] = useMemo(() => {
    if (isNullish(timesheetDetailsSummaryRes.data) || isNullish(timeWindow)) {
      return []
    }

    return timesheetDetailsSummaryRes.data.users
      .filter(user =>
        filteredTechsGuids.length > 0
          ? filteredTechsGuids.includes(user.userGuid)
          : true,
      )
      .map(
        user =>
          ({
            userGuid: user.userGuid,
            technician: {
              id: user.userGuid,
              avatarData: {
                userGuid: user.userGuid,
                avatarAltShortString: `${user.firstName.charAt(
                  0,
                )}${user.lastName.charAt(0)}`,
              },
              categories: user.userRoles.map(({ role }) =>
                toRoleDisplayName(role),
              ),
              displayName: `${user.firstName} ${user.lastName}`,
              displayCategory:
                user.userRoles.length > 0
                  ? toRoleDisplayName(user.userRoles[0].role)
                  : '',
            },
            daysHoursSummaries: {
              ...generateDayHeadersForSummary(timeWindow, company.timezone),
              ...Object.entries(
                getTotalMinutesPerDayForTimesheetEntries(
                  user.timesheetEntries.map(entry => ({
                    startTime: bzExpect(entry.finalStartTime),
                    endTime: entry.finalEndTime,
                    deletedAt: entry.deletedAt,
                    isPayable: entry.timesheetEntryActivity?.isPayable ?? false,
                  })),
                  company.timezone,
                ),
              ).reduce<Record<string, number>>((acc, [day, minutes]) => {
                acc[day] = minutes / 60
                return acc
              }, {}),
            },
          } satisfies TimesheetReportItem),
      )
  }, [
    company.timezone,
    filteredTechsGuids,
    timeWindow,
    timesheetDetailsSummaryRes.data,
  ])

  useEffect(() => {
    if (!timeWindow) setTimeWindow(payPeriodConfig?.currentPayPeriod)
  }, [timeWindow, payPeriodConfig])

  return (
    <Page requiresCompanyUser>
      <div className="card-no-fixed-height pt-2">
        <PageHeader
          className="pl-2"
          title={<PageTitle title="Timesheets" icon={TimesheetsIcon} />}
          extra={
            <div className="row gap-x-4">
              {payPeriodConfig && timeWindow && (
                <TimesheetPeriodPicker
                  payPeriodConfig={payPeriodConfig}
                  window={timeWindow}
                  setTimeWindow={setTimeWindow}
                />
              )}
            </div>
          }
        />
        <TimesheetsSummaryTable
          items={tableItems}
          onChange={(_, filter) => {
            if (!filter['technician']) {
              setFilteredTechsGuids([])
              return
            }

            setFilteredTechsGuids(
              Array.from(new Set(filter['technician'] as string[])),
            )
          }}
        />
      </div>
    </Page>
  )
}

export default TimesheetsSummaryPage
