import {
  BULK_PRICEBOOK_QBO_INCOME_ACCOUNTS_SHEET_SLUG,
  bzExpect,
} from '@breezy/shared'
import api from '@flatfile/api'
import { convertToFlatfileQboIncomeAccountRecords } from './FlatfileConversions'
import {
  FlatfileQboIncomeAccountsWriter,
  FlatfileSheetReader,
} from './FlatfileTypes'
export const createFlatfileQboIncomeAccountsWriter =
  (readSheet: FlatfileSheetReader): FlatfileQboIncomeAccountsWriter =>
  async input => {
    try {
      const sheet = await readSheet({
        workbookId: input.workbookId,
        slug: BULK_PRICEBOOK_QBO_INCOME_ACCOUNTS_SHEET_SLUG,
      })

      if (!input.qboIncomeAccounts?.length) return
      await api.records.insert(
        bzExpect(sheet, 'sheet', 'Sheet not found').id,
        convertToFlatfileQboIncomeAccountRecords(input.qboIncomeAccounts),
      )
    } catch (err) {
      console.error('Error seeding pricebook category cells', err)
      throw err
    }
  }
