import { gql } from '../../generated'

export const FETCH_QBO_PAYOUT_ACCOUNTS = gql(/* GraphQL */ `
  query FetchPayoutQboAccounts($companyGuid: uuid!) {
    payoutQboAccountsConfig(where: { companyGuid: { _eq: $companyGuid } }) {
      companyGuid
      payoutQboDepositAccountId
      payoutQboFeesExpenseAccountId
      payoutQboRefundsExpenseAccountId
      payoutQboPaymentProcessorBalanceAccountId
    }
  }
`)
