import { Form, Switch } from 'antd'

export const PricebookFormItemDiscountable = () => (
  <Form.Item>
    <Form.Item name="isDiscountable" valuePropName="checked" noStyle>
      <Switch id="isDiscountable" />
    </Form.Item>
    <label htmlFor="isDiscountable" className="ml-2 font-semibold">
      Item is discountable
    </label>
  </Form.Item>
)
