import React, { useContext } from 'react'
import { CSModeContext } from './CSModeWrapper'

export const WhenCSMode = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const { isCSMode } = useContext(CSModeContext)
    if (!isCSMode) {
      return null
    }
    return (
      <div className="border-3 w-min border-solid border-red-500 bg-[#ff000040] p-1">
        {children}
      </div>
    )
  },
)
