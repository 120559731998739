import { DocumentType, gql } from 'src/generated'

export const BOOKKEEPER_INVOICE_V2_QUERY = gql(/* GraphQL */ `
  query BookkeeperListPageQueryInvoiceV2(
    $where: InvoicesBoolExp
    $orderBy: [InvoicesOrderBy!]
    $limit: Int
    $offset: Int
  ) {
    invoices(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
      accountGuid
      companyGuid
      createdAt
      createdBy
      dueAt
      estimateLink {
        estimateGuid
      }

      invoiceGuid
      status
      invoiceTerm
      issuedAt

      locationLink {
        locationGuid
      }

      subtotalUsc
      totalUsc

      displayId
      taxRate
      dynamicPricingType
      serviceCompletionDate
      taxRateName
      taxRateGuid
      billingContactGuid
      updatedAt
      jobLink {
        jobGuid
        job {
          workCompletedAt
        }
      }
      messageHtml

      updatedAt
      invoiceReminderData {
        remindersSent
      }

      invoiceDiscounts(orderBy: { seq: ASC }) {
        name
        discountType
        discountAmountUsc
        discountRate
        invoiceDiscountGuid
        descriptionHtml
      }
      invoiceCartItems(orderBy: { seq: ASC }) {
        seq
        cartItem {
          cartItemGuid
          name
          description
          quantity
          unitPriceUsc
          isTaxable
          isDiscountable
          cartItemType
        }
      }

      account {
        accountDisplayName
        accountContacts(where: { primary: { _eq: true } }) {
          contact {
            fullName
            primaryPhoneNumber {
              phoneNumber
              type
            }
            primaryEmailAddress {
              emailAddress
            }
          }
        }
        maintenancePlans {
          maintenancePlanGuid
          status
          paymentFlow
          maintenancePlanDefinition {
            marketingInfo {
              name
            }
            flare {
              imageUrl
              primaryColorHex
            }
          }
        }
      }
      qboStaleInfo {
        syncedAt: qboSyncedAt
        stale: isQboStale
        updatedAt
      }
      qboSync {
        qboId
      }
      paymentRecords {
        paymentRecordGuid
        paymentMethod
        occurredAt
        paymentRefunds {
          amountUsd
          occurredAt
        }
        paymentStatuses(
          limit: 1
          orderBy: { occurredAt: DESC, createdAt: DESC }
        ) {
          paymentStatus
        }
        amountUsd
        payoutItem {
          payout {
            paidAt
            payoutGuid
            qboSync {
              qboId
            }
          }
        }
        qboSync {
          qboId
        }
      }
    }
    invoicesAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`)

export type BookkeeperInvoiceV2QueryResult = DocumentType<
  typeof BOOKKEEPER_INVOICE_V2_QUERY
>
export type InvoiceV2QboStatus =
  BookkeeperInvoiceV2QueryResult['invoices'][number]['qboStaleInfo']
