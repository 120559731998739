import { PermissionV2 } from '@breezy/shared'
import { Button, Popconfirm } from 'antd'
import React, { useMemo } from 'react'
import { Authorized } from '../../components/Permissions/Authorized/Authorized'
import {
  DEFAULT_PENDING_CHANGES,
  hasPendingChanges,
} from './PendingChanges/pendingChanges'
import { useSchedulePendingChanges } from './PendingChanges/SchedulePendingChangesContext'
import { useSchedulePlaygroundMode } from './SchedulePlaygroundModeContext'
import { ScheduleStatusTag } from './ScheduleStatusTag'

const SchedulePlaygroundModeButtons = React.memo(() => {
  const { pendingChanges, setPendingChangesRaw } = useSchedulePendingChanges()
  const { playgroundMode, setPlaygroundMode } = useSchedulePlaygroundMode()

  const hasChanges = useMemo(
    () => hasPendingChanges(pendingChanges),
    [pendingChanges],
  )

  if (!playgroundMode) {
    return (
      <Authorized to={PermissionV2.OFFICE_SCHEDULE_APPOINTMENTS_MANAGE}>
        <div className="flex flex-row items-center gap-2">
          <ScheduleStatusTag status="Live Edit" />
          <Button onClick={() => setPlaygroundMode(true)}>
            Edit in Draft Mode
          </Button>
        </div>
      </Authorized>
    )
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <ScheduleStatusTag status="Draft" />
      {hasChanges ? (
        <Popconfirm
          title="Are you sure you want to discard all your changes?"
          okText="Yes"
          onConfirm={() => {
            setPlaygroundMode(false)
            setPendingChangesRaw(DEFAULT_PENDING_CHANGES)
          }}
        >
          <Button>Cancel</Button>
        </Popconfirm>
      ) : (
        <Button onClick={() => setPlaygroundMode(false)}>Cancel</Button>
      )}
      <Button
        type="primary"
        onClick={() => {
          // The CommitScheduleChangesListener will commit the changes once we flip this
          setPlaygroundMode(false)
        }}
      >
        Save
      </Button>
    </div>
  )
})

export default SchedulePlaygroundModeButtons
