import {
  CompanyCreatorV2Input,
  RoleId,
  toRoleDisplayName,
} from '@breezy/shared'
import { Input, Select, Switch } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'

export type TeamMembersUserCollapsibleItemProps = {
  index: number
}

export const TeamMembersUserCollapsibleItem = ({
  index,
}: TeamMembersUserCollapsibleItemProps) => {
  const { control } = useFormContext<CompanyCreatorV2Input>()

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row gap-3">
        <Controller
          control={control}
          name={`teamMembers.${index}.firstName`}
          render={({ field }) => (
            <label className="flex flex-1 flex-col gap-1">
              <span>First Name</span>
              <Input {...field} placeholder="Enter first name here..." />
            </label>
          )}
        />

        <Controller
          control={control}
          name={`teamMembers.${index}.lastName`}
          render={({ field }) => (
            <label className="flex flex-1 flex-col gap-1">
              <span>Last Name</span>
              <Input {...field} placeholder="Enter last name here..." />
            </label>
          )}
        />
      </div>

      <Controller
        control={control}
        name={`teamMembers.${index}.emailAddress`}
        render={({ field }) => (
          <label className="flex flex-1 flex-col gap-1">
            <span>Email Address</span>
            <Input {...field} placeholder="Enter email address here..." />
          </label>
        )}
      />

      <Controller
        control={control}
        name={`teamMembers.${index}.phoneNumber`}
        render={({ field }) => (
          <label className="flex flex-1 flex-col gap-1">
            <span>Phne Number</span>
            <Input {...field} placeholder="Enter phoneNumber here..." />
          </label>
        )}
      />

      <Controller
        control={control}
        name={`teamMembers.${index}.isSuperAdmin`}
        render={({ field }) => (
          <label className="flex flex-row gap-4">
            <span>Is Super Admin (root user)?: </span>
            <Switch {...field} />
          </label>
        )}
      />

      <Controller
        control={control}
        name={`teamMembers.${index}.roles`}
        render={({ field }) => (
          <label className="flex flex-col gap-2">
            <span>Roles</span>

            <Select
              {...field}
              mode="tags"
              options={Object.values(RoleId).map(role => ({
                label: toRoleDisplayName(role),
                value: role,
              }))}
            />
          </label>
        )}
      />
    </div>
  )
}
