import { PricebookItemDescriptionSchema } from '@breezy/shared'
import { Form, Input } from 'antd'

export const PricebookFormItemDescription = () => (
  <Form.Item
    name="description"
    label="Description"
    rules={[
      {
        required: false,
        validator: async (_, value) => {
          const result = PricebookItemDescriptionSchema.safeParse(value)
          if (!result.success) {
            throw new Error(
              result.error.issues[0]?.message || result.error.message,
            )
          }
        },
      },
    ]}
  >
    <Input.TextArea rows={6} />
  </Form.Item>
)
