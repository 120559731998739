import { CompanyCreatorV2Input, isNullish, toCamelCase } from '@breezy/shared'
import { faUpload } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Collapse, Upload, UploadFile } from 'antd'
import Papa from 'papaparse'
import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import GqlQueryLoader from 'src/components/GqlQueryLoader/GqlQueryLoader'
import { useMessage } from 'src/utils/antd-utils'
import { useQuery } from 'urql'
import { fromError } from 'zod-validation-error'
import { leadSourcesFormSchema } from '../schema'
import { CANONICAL_LEAD_SOURCES_QUERY } from './CreateCompanyDrawerLeadSourcesSection.gql'
import { LeadSourcesSectionLeadSourceCollapsible } from './LeadSourcesSectionLeadSourceCollapsible'

export const CreateCompanyDrawerLeadSourcesSection = () => {
  const message = useMessage()
  const { watch, setValue, reset, trigger } =
    useFormContext<CompanyCreatorV2Input>()

  const formValues = watch()

  const canonicalLeadSourcesQuery = useQuery({
    query: CANONICAL_LEAD_SOURCES_QUERY,
  })

  const onFileUploaded = useCallback(
    (file: UploadFile, fileList: UploadFile[]) => {
      if (isNullish(file.originFileObj)) {
        message.error('Could not open file')
        return
      }

      if (fileList.length === 0) {
        return
      }

      Papa.parse(file.originFileObj as File, {
        header: true,
        transformHeader: header => toCamelCase(header),
        transform: value => {
          if (typeof value === 'string' && value.trim() === '') {
            return null
          }

          return value
        },
        complete: results => {
          const parsed = leadSourcesFormSchema.array().safeParse(results.data)
          if (!parsed.success) {
            message.error(
              `Failed to validate Lead Sources CSV with errors ${fromError(
                parsed.error,
              )}`,
            )
            reset({
              ...formValues,
              leadSources: [],
            })
          } else {
            setValue(
              'leadSources',
              parsed.data.map(leadSource => ({
                canonicalLeadSourceName: leadSource.canonicalCategory,
                leadSourceNameOverride: leadSource.leadSourceNameOverride,
                attributionLinkingStrategy:
                  leadSource.defaultAttributionLinkingStrategy,
                attributionLinkingStrategyOverride:
                  leadSource.attributionLinkingStrategyOverride,
                attributionPrompt: leadSource.defaultAttributionPrompt,
                attributionPromptOverride: leadSource.attributionPromptOverride,
              })),
            )
            trigger('leadSources')
          }
        },
      })
    },
    [formValues, message, reset, setValue, trigger],
  )

  const onFileRemoved = () => {
    reset({
      ...formValues,
      leadSources: [],
    })
  }

  return (
    <div className="flex w-full flex-col gap-3 overflow-y-scroll">
      <label className="flex flex-col gap-2">
        <span>Upload Lead Sources CSV</span>

        <Upload
          accept="text/csv"
          maxCount={1}
          customRequest={({ onSuccess }) => {
            onSuccess?.('ok')
          }}
          onChange={({ file, fileList }) => onFileUploaded(file, fileList)}
          onRemove={onFileRemoved}
        >
          <Button icon={<FontAwesomeIcon icon={faUpload} />}>
            Upload File
          </Button>
        </Upload>
      </label>

      {!isNullish(formValues.leadSources) && (
        <GqlQueryLoader
          query={canonicalLeadSourcesQuery}
          render={data => (
            <Collapse
              activeKey={formValues.leadSources.map(
                (_, idx) => `leadSources.${idx}`,
              )}
              items={formValues.leadSources.map((_, idx) => ({
                key: `leadSources.${idx}`,
                label: `Lead Source ${idx + 1}`,
                children: (
                  <LeadSourcesSectionLeadSourceCollapsible
                    index={idx}
                    canonicalLeadSources={data.canonicalLeadSources}
                  />
                ),
              }))}
            />
          )}
        />
      )}
    </div>
  )
}
