import { Form, Radio } from 'antd'
import React, { useCallback, useState } from 'react'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'

export type RecurringChangeType =
  | 'THIS_EVENT_ONLY'
  | 'THIS_AND_FUTURE'
  | 'ALL_EVENTS'

type RecurringChangeFormProps = {
  changeType: RecurringChangeType
  setChangeType: (changeType: RecurringChangeType) => void
  label?: string
  className?: string
}

export const RecurringChangeForm = React.memo<RecurringChangeFormProps>(
  ({
    changeType,
    setChangeType,
    label = 'Change recurring event',
    className,
  }) => (
    <Form layout="vertical" className={className}>
      <Form.Item label={label} className="mb-0">
        <Radio.Group
          value={changeType}
          onChange={e => setChangeType(e.target.value)}
          options={[
            {
              label: 'This event only',
              value: 'THIS_EVENT_ONLY',
            },
            {
              label: 'This and following events',
              value: 'THIS_AND_FUTURE',
            },
            {
              label: 'All events',
              value: 'ALL_EVENTS',
            },
          ]}
        />
      </Form.Item>
    </Form>
  ),
)

type RecurringChangeModalProps = {
  onCancel: () => void
  onOk: (changeType: RecurringChangeType) => void
}

export const RecurringChangeModal = React.memo<RecurringChangeModalProps>(
  ({ onCancel, onOk }) => {
    const [changeType, setChangeType] =
      useState<RecurringChangeType>('THIS_EVENT_ONLY')

    const ourOnOk = useCallback(() => onOk(changeType), [onOk, changeType])

    return (
      <OnsiteConfirmModal
        open
        header="Change recurring event"
        onCancel={onCancel}
        onConfirm={ourOnOk}
        confirmText="Change Event"
      >
        <RecurringChangeForm
          changeType={changeType}
          setChangeType={setChangeType}
        />
      </OnsiteConfirmModal>
    )
  },
)
