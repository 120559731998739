import { Address, BzAddress } from '@breezy/shared'
import { Tooltip } from 'antd'

export enum AddressViewType {
  Compact_Zip5,
  Compact_ZipCity,
  Compact_Street,
  Compact_Line1,
}

export type AddressViewProps = {
  displayName: string | undefined
  viewType: AddressViewType
  address: Address
}

export const LocationAddressView = ({
  displayName,
  viewType,
  address,
}: AddressViewProps) => {
  const formattedAddress = BzAddress.formatAddressSingleLine(address)
  let text
  const displayNamePostfix = `${displayName ? ` • ${displayName}` : ''}`
  switch (viewType) {
    case AddressViewType.Compact_Line1:
      text = `${address.line1.trim()}`
      break
    case AddressViewType.Compact_ZipCity:
      text = `${address.zipCode.trim()} - ${address.city}`
      break
    case AddressViewType.Compact_Street:
      text = `${BzAddress.formatAddressLine1And2Condensed(
        address,
      )}${displayNamePostfix}`
      break
    case AddressViewType.Compact_Zip5:
      text = address.zipCode.substring(0, 5).trim()
      break
    default:
      text = `${address.line1.trim()} ${address.line2 ?? ''}`.trimEnd()
      break
  }

  return (
    <Tooltip placement="bottom" title={formattedAddress}>
      <span>{text}</span>
    </Tooltip>
  )
}
