import classNames from 'classnames'
import React, { ReactNode } from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Card } from '../../elements/Card/Card'

type TileProps = React.PropsWithChildren<{
  title: ReactNode
  footerText?: string
  info?: string
  loading?: boolean
  onClick?: () => void
}>

export const Tile = React.memo<TileProps>(
  ({ children, title, footerText, info, loading, onClick }) => {
    return (
      <div
        onClick={onClick}
        className={classNames('flex-1', onClick ? 'cursor-pointer' : undefined)}
      >
        <Card title={title} hideTitleDivider info={info} className="h-full">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="pb-2 text-2xl font-semibold text-bz-gray-1000">
                {children}
              </div>
              {footerText && (
                <div className="text-xs text-bz-gray-800">{footerText}</div>
              )}
            </>
          )}
        </Card>
      </div>
    )
  },
)
