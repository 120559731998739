import { CartItem, Guid, isNullish } from '@breezy/shared'
import { Button } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { PricebookItemPicker } from '../../components/Pricebook/PricebookItemPicker'
import { useIntercom } from '../../hooks/useIntercom'
import { useUpsertCompanyBillingProfile } from '../../hooks/useUpsertCompanyBillingProfile'
import { useMessage } from '../../utils/antd-utils'
import { MaintenancePlanBillingProfileViewProps } from './maintenancePlanConfigUtils'

type NamedGuid = {
  readonly name: string
  readonly guid: Guid
}

const MaintenancePlanServiceItemManageView =
  React.memo<MaintenancePlanBillingProfileViewProps>(
    ({ companyGuid, billingProfiles, onMutate }) => {
      const message = useMessage()
      const [isSelecting, setIsSelecting] = useState(false)
      const [isUploading, setIsUploading] = useState(false)
      const [justPicked, setJustPicked] = useState<NamedGuid>()
      const item = useMemo(() => {
        if (!isNullish(justPicked)) return justPicked
        if (billingProfiles.length < 1) return undefined
        return billingProfiles[0].maintenancePlanServiceItem
      }, [justPicked, billingProfiles])
      const { upsert } = useUpsertCompanyBillingProfile()

      const onSelect = useCallback(
        async (c: CartItem) => {
          setIsSelecting(false)
          setJustPicked({ name: c.name, guid: c.itemGuid })
          setIsUploading(true)
          const {
            __typename,
            emailAddress,
            phoneNumber,
            businessAddress,
            defaultTaxRate,
            maintenancePlanTaxRate,
            maintenancePlanServiceItem,
            ...originalRow
          } = billingProfiles[0]
          try {
            await upsert({
              object: {
                ...originalRow,
                companyGuid,
                maintenancePlanServiceItemGuid: c.itemGuid,
                maintenancePlanServiceItem: undefined,
                updatedAt: new Date().toISOString(),
              },
            })
            onMutate()
          } catch (e) {
            console.error(`Failed to set Item`, e)
            message.error(`Failed to set Item`)
          }
          setIsUploading(false)
        },
        [upsert, billingProfiles, companyGuid, onMutate, message],
      )

      useIntercom({ isLauncherVisible: !isSelecting })

      return (
        <>
          {isSelecting && (
            <PricebookItemPicker
              onSubmit={items => onSelect(items[0])}
              onCancel={() => setIsSelecting(false)}
              isSubmitting={isUploading}
              disableManualItemCreation
              canSelectMultiple={false}
            />
          )}
          <div className="col w-full px-2 pt-2">
            <div className="row flex-between ">
              <div className="col">
                <h3 className="grey9">Maintenance Service</h3>
                <p className="regular_14_22 grey8 max-w-[700px] font-light">
                  Pick which service you will offer for free in your Maintenance
                  Plans
                </p>
              </div>
              <div className="row gap-x-2">
                <Button type="primary" onClick={() => setIsSelecting(true)}>
                  Select Item
                </Button>
              </div>
            </div>
            {!isUploading && (
              <p className="grey9 w-full text-center text-[20px] font-semibold">
                {item?.name ?? 'None Set'}
              </p>
            )}
            {isUploading && <LoadingSpinner />}
          </div>
        </>
      )
    },
  )

export default MaintenancePlanServiceItemManageView
