import { isNullish } from '@breezy/shared'
import { memo, useCallback, useContext } from 'react'
import { Kanban } from '../../../components/Kanban/Kanban'
import { Board, KanbanJob } from '../../../components/Kanban/kanbanUtils'
import { Job } from '../../../gql/queries/Jobs.gql'
import { JobLifecycleKanbanView } from '../../../hooks/useJobLifecyclePersistedDisplay'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { JobKanbanContext } from '../JobKanbanContext'
import { JobLifecycle } from '../JobsPage.gql'
import { BasicJobCard } from './BasicJobCard/BasicJobCard'
import {
  JobMenuDropdownOnChangeJobTypeHandler,
  JobMenuDropdownOnCreateLinkedJobHandler,
  JobMenuDropdownOnEditJobHandler,
  JobMenuDropdownOnUpdatePipelineStatusHandler,
} from './JobMenuDropdown'
import { JobsList } from './JobsList/JobsList'
import { JobsMapView } from './JobsMapView'

export const JobCard = memo<{
  job: Job
  lifecycles: JobLifecycle[]
  onEditClicked: JobMenuDropdownOnEditJobHandler
  onCreateLinkedJobClicked: JobMenuDropdownOnCreateLinkedJobHandler
  onUpdateJobPipelineStatus: JobMenuDropdownOnUpdatePipelineStatusHandler
  onChangeJobType: JobMenuDropdownOnChangeJobTypeHandler
}>(
  ({
    job,
    lifecycles,
    onEditClicked,
    onCreateLinkedJobClicked,
    onUpdateJobPipelineStatus,
    onChangeJobType,
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const { displayPropertiesSettings } = useContext(JobKanbanContext)

    return (
      <BasicJobCard
        job={job}
        lifecycles={lifecycles}
        tzId={tzId}
        displayPropertiesSettings={displayPropertiesSettings}
        onEditClicked={onEditClicked}
        onCreateLinkedJobClicked={onCreateLinkedJobClicked}
        onUpdateJobPipelineStatus={onUpdateJobPipelineStatus}
        onChangeJobType={onChangeJobType}
      />
    )
  },
)

type JobsViewProps = {
  kanbanView: JobLifecycleKanbanView
  board: Board
  jobs: KanbanJob[]
  lifecycles: JobLifecycle[]
  hideEmptyStatuses: boolean
  disabled: boolean
  isLoading: boolean
  onChange: (job: KanbanJob, statusId: string) => void
  onCardClick: (job: KanbanJob) => void
  onEditClicked: JobMenuDropdownOnEditJobHandler
  onCreateLinkedJobClicked: JobMenuDropdownOnCreateLinkedJobHandler
  onChangeJobType: JobMenuDropdownOnChangeJobTypeHandler
}

export const JobsView = memo<JobsViewProps>(
  ({
    kanbanView,
    board,
    jobs,
    lifecycles,
    hideEmptyStatuses,
    disabled,
    isLoading,
    onChange,
    onCardClick,
    onEditClicked,
    onCreateLinkedJobClicked,
    onChangeJobType,
  }) => {
    const { scrollPos, setScrollPos } = useContext(JobKanbanContext)

    const onUpdateJobPipelineStatus: JobMenuDropdownOnUpdatePipelineStatusHandler =
      useCallback(
        (jobGuid, transitionToStatusGuid) => {
          // TODO: Potentially very slow if there are a lot of jobs. Refactor so that
          // JobMenuDropdownOnUpdatePipelineStatusHandler directly takes in a KanbanJob object instead of a jobGuid string
          const job = jobs.find(job => job.job.jobGuid === jobGuid)

          if (isNullish(job)) {
            return
          }

          onChange(job, transitionToStatusGuid)
        },
        [jobs, onChange],
      )

    if (kanbanView === 'kanban') {
      return (
        <Kanban
          disabled={disabled}
          isLoading={isLoading}
          data={jobs}
          board={board}
          onChange={onChange}
          hideEmptyStatuses={hideEmptyStatuses}
          renderCardContent={kanbanJob => (
            <JobCard
              job={kanbanJob.job}
              lifecycles={lifecycles}
              onEditClicked={onEditClicked}
              onCreateLinkedJobClicked={onCreateLinkedJobClicked}
              onUpdateJobPipelineStatus={onUpdateJobPipelineStatus}
              onChangeJobType={onChangeJobType}
            />
          )}
          onCardClick={onCardClick}
          scrollPos={scrollPos}
          setScrollPos={setScrollPos}
        />
      )
    }
    if (kanbanView === 'map') {
      return (
        <JobsMapView
          jobs={jobs}
          lifecycles={lifecycles}
          onCardEditClicked={onEditClicked}
          onCardCreateLinkedJobClicked={onCreateLinkedJobClicked}
          onUpdateJobPipelineStatus={onUpdateJobPipelineStatus}
          onChangeJobType={onChangeJobType}
        />
      )
    }

    return (
      <JobsList
        data={jobs}
        board={board}
        hideEmptyStatuses={hideEmptyStatuses}
        disabled={disabled}
        isLoading={isLoading}
        lifecycles={lifecycles}
        onChange={onChange}
        onEditJob={onEditClicked}
        onCreateLinkedJob={onCreateLinkedJobClicked}
        onUpdateJobPipelineStatus={onUpdateJobPipelineStatus}
        onChangeJobType={onChangeJobType}
      />
    )
  },
)
