import { gql } from '../../generated'

export const READ_ACTIVE_RECOMMENDATIONS = gql(/* GraphQL */ `
  query ReadMyActiveRecommendations(
    $where: RecommendationsBoolExp!
    $userGuid: uuid!
  ) {
    recommendations(where: $where, orderBy: { valueExpectedUsc: DESC }) {
      recommendationGuid
      recommendationType
      ctaButtonText
      ctaButtonUrl
      ctaMarkdown
      ctaText
      description
      recommendationDismissals(where: { userGuid: { _eq: $userGuid } }) {
        dismissedAt
      }
      valueExpectedUsc
      valueExpectedDescription
    }
  }
`)
