import { JobClass, isNullish } from '@breezy/shared'
import { Form, Select } from 'antd'
import { useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ChangeJobTypeFormSchema } from '../../change-job-type-form-schema'

export type JobTypeSelectProps = {
  jobTypes: { jobTypeGuid: string; name: string; jobClass: JobClass }[]
  onJobTypeChanged: (
    jobType: { jobTypeGuid: string; name: string; jobClass: JobClass } | null,
  ) => void
}

export const JobTypeSelect = ({
  jobTypes,
  onJobTypeChanged,
}: JobTypeSelectProps) => {
  const { control, formState, watch } =
    useFormContext<ChangeJobTypeFormSchema>()

  const jobTypeOptions: { label: string; value: string }[] = useMemo(() => {
    const jobTypesSorted = [...jobTypes]
    jobTypesSorted.sort((a, b) => {
      if (a.jobClass === b.jobClass) {
        return a.name.localeCompare(b.name)
      }

      return a.jobClass.localeCompare(b.jobClass)
    })

    return jobTypesSorted.map(jobType => ({
      label: jobType.name,
      value: jobType.jobTypeGuid,
    }))
  }, [jobTypes])

  const selectedJobTypeGuid: string | undefined = watch('jobTypeGuid')

  useEffect(() => {
    if (isNullish(selectedJobTypeGuid)) {
      onJobTypeChanged(null)
    }

    const selectedJobType = jobTypes.find(
      jobType => selectedJobTypeGuid === jobType.jobTypeGuid,
    )

    onJobTypeChanged(selectedJobType ?? null)
  }, [selectedJobTypeGuid, jobTypes, onJobTypeChanged])

  return (
    <Controller
      control={control}
      name="jobTypeGuid"
      render={({ field }) => (
        <Form.Item label="New Job Type">
          <Select
            {...field}
            status={formState.errors.jobTypeGuid ? 'error' : undefined}
            options={jobTypeOptions}
          />
        </Form.Item>
      )}
    />
  )
}
