import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { Button } from 'antd'
import React from 'react'
import { useLocalStorage } from 'react-use'
import { m } from '../../utils/react-utils'
import FaIconButton from '../FaIconButton/FaIconButton'

interface ActionButtonProps {
  onClick: () => void
  className?: string
  text: string
}

interface ActionBannerProps {
  icon?: React.ReactNode
  dismissKey?: string
  text: React.ReactNode
  actionButton?: ActionButtonProps
}

const Banner = m<ActionBannerProps>(
  ({ icon, text, actionButton, dismissKey }) => {
    const [isDismissed, setIsDismissed] = useLocalStorage<boolean>(
      `bannerDismissed-${dismissKey || 'none'}`,
      false,
    )
    const buttonClassNames = `ml-4 ${actionButton?.className ?? ''}`

    return isDismissed ? null : (
      <div className="flex justify-between bg-[#FFE7BA] px-6 py-4">
        <div className="flex">
          {icon}
          <div className="center-v ml-2 text-black">{text}</div>
          {actionButton && (
            <Button
              type="primary"
              className={buttonClassNames}
              onClick={actionButton.onClick}
            >
              {actionButton.text}
            </Button>
          )}
        </div>
        {dismissKey && (
          <FaIconButton
            className="ml-4"
            icon={faXmark}
            onClick={() => setIsDismissed(true)}
          />
        )}
      </div>
    )
  },
)

export default Banner
