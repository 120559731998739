import { useCallback, useEffect, useRef, useState } from 'react'
import { m } from '../../utils/react-utils'

const ElapsedSecondsTimer = m(() => {
  const startTime = useRef(Date.now())
  const timerRef = useRef<number>(0)
  const frameId = useRef<number | null>(null)
  const [time, setTime] = useState<number>(0)

  const animate = useCallback(() => {
    timerRef.current = Date.now() - startTime.current
    setTime(timerRef.current)
    frameId.current = requestAnimationFrame(animate)
  }, [])

  useEffect(() => {
    frameId.current = requestAnimationFrame(animate)
    return () => {
      if (frameId.current) {
        cancelAnimationFrame(frameId.current)
      }
    }
  }, [animate])

  return (
    <div className="center-children-vh w-full">
      <label id="timer-label" className="font-bold">
        {' ' + (time / 1000).toFixed(2)}
      </label>
    </div>
  )
})

export default ElapsedSecondsTimer
