import { OfficeRoutes, PermissionV2 } from '@breezy/shared'
import {
  faBillboard,
  faBriefcase,
  faBuildingMemo,
  faCalendarCheck,
  faClock,
  faEnvelopeOpen,
  faGear,
  faListCheck,
  faScrewdriverWrench,
  faShuffle,
  faTag,
  faTags,
  faUser,
  faUsers,
} from '@fortawesome/pro-light-svg-icons'
import { faBell } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useIsQboEnabled } from '../../components/Quickbooks/QuickbooksSyncButton'
import { SubNavSidebar } from '../../components/SubNavSidebar/SubNavSidebar'
import { useIsAuthorized } from '../../hooks/permission/useIsAuthorized'
import { useCompanyMaintenancePlansEnabled } from '../../hooks/useCompanyMaintenancePlansEnabled'
import { useFeatureFlagReader } from '../../hooks/useFeatureFlags'
import { useTabFromPath } from '../../hooks/useTabFromPath'
import { useIsImpersonating } from '../../providers/PrincipalUser'
import { Placeholder } from '../../utils/Placeholder'
import {
  TechnicianPerformanceIcon,
  TimesheetsIcon,
} from '../../utils/feature-icons'

export const SettingsUnauthorized = React.memo<{ label: string }>(
  ({ label }) => {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Placeholder>Unauthorized to manage {label}</Placeholder>
      </div>
    )
  },
)

const SettingsLayout = () => {
  const tab = useTabFromPath()
  const ffReader = useFeatureFlagReader()

  const navigate = useNavigate()
  const isAuthorized = useIsAuthorized()
  const isQboEnabled = useIsQboEnabled()
  const isImpersonating = useIsImpersonating()

  const maintenancePlansEnabled = useCompanyMaintenancePlansEnabled()

  const sidebarItems = [
    {
      group: 'Personal',
      icon: <FontAwesomeIcon icon={faUser} />,
      text: OfficeRoutes.USER_PROFILE.label,
      id: OfficeRoutes.USER_PROFILE.path,
      enabled: true,
    },
    {
      group: 'Personal',
      icon: <FontAwesomeIcon icon={faGear} />,
      text: OfficeRoutes.USER_SETTINGS.label,
      id: OfficeRoutes.USER_SETTINGS.path,
      enabled: true,
    },
    {
      group: 'Company',
      icon: <FontAwesomeIcon icon={faBuildingMemo} />,
      text: OfficeRoutes.BILLING_PROFILE.label,
      id: OfficeRoutes.BILLING_PROFILE.path,
      enabled: ffReader('selfServeBillingProfile') && isImpersonating,
    },
    {
      group: 'Company',
      icon: <FontAwesomeIcon icon={faUsers} />,
      text: OfficeRoutes.TEAM.label,
      id: OfficeRoutes.TEAM.path,
      enabled: isAuthorized(PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TEAM),
    },
    {
      group: 'Company',
      icon: <FontAwesomeIcon icon={faTags} />,
      text: OfficeRoutes.PRICEBOOK.label,
      id: OfficeRoutes.PRICEBOOK.path,
      style: { transform: 'scale(-1.2, 1.1)' },
      enabled: isAuthorized(
        PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_PRICEBOOK,
      ),
    },
    {
      group: 'Company',
      icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
      text: OfficeRoutes.MAINTENANCE_PLANS_SETTINGS.label,
      id: OfficeRoutes.MAINTENANCE_PLANS_SETTINGS.path,
      enabled:
        isAuthorized(
          PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_MAINTENANCE_PLANS,
        ) && maintenancePlansEnabled,
    },
    {
      group: 'Company',
      icon: <FontAwesomeIcon icon={faClock} />,
      text: OfficeRoutes.COMPANY_WORKING_HOURS.label,
      id: OfficeRoutes.COMPANY_WORKING_HOURS.path,
      enabled: isAuthorized(
        PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_COMPANY_HOURS,
      ),
    },
    {
      group: 'Company',
      icon: <FontAwesomeIcon icon={TimesheetsIcon} />,
      text: OfficeRoutes.TIMESHEETS_SETTINGS.label,
      id: OfficeRoutes.TIMESHEETS_SETTINGS.path,
      enabled: isAuthorized(PermissionV2.OFFICE_TIMESHEETS_MANAGE),
    },
    {
      group: 'Company',
      icon: <FontAwesomeIcon icon={TechnicianPerformanceIcon} />,
      text: OfficeRoutes.TECHNICIAN_PERFORMANCE.label,
      id: OfficeRoutes.TECHNICIAN_PERFORMANCE.path,
      enabled: isAuthorized(PermissionV2.OFFICE_TECHNICIAN_PERFORMANCE_VIEW),
    },
    {
      group: 'Company',
      icon: <FontAwesomeIcon icon={faGear} />,
      text: OfficeRoutes.COMPANY_CONFIG.label,
      id: OfficeRoutes.COMPANY_CONFIG.path,
      enabled: isImpersonating,
    },
    {
      group: 'Integrations',
      icon: (
        <img
          alt="Sync with Quickbooks"
          src="https://d3j5nzmmhjc8v6.cloudfront.net/quickbooks_logo.png"
          className="h-4 w-4"
        />
      ),
      text: OfficeRoutes.QUICKBOOKS.label,
      id: OfficeRoutes.QUICKBOOKS.path,
      enabled: isQboEnabled,
    },
    {
      group: 'Configuration',
      icon: <FontAwesomeIcon icon={faBillboard} />,
      text: OfficeRoutes.LEAD_SOURCES.label,
      id: OfficeRoutes.LEAD_SOURCES.path,
      enabled:
        ffReader('selfServeLeadSources') &&
        isAuthorized(PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_LEAD_SOURCES),
    },
    {
      group: 'Configuration',
      icon: <FontAwesomeIcon icon={faShuffle} />,
      text: OfficeRoutes.LIFECYCLES.label,
      id: OfficeRoutes.LIFECYCLES.path,
      enabled: isAuthorized(
        PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_LIFECYCLES,
      ),
    },
    {
      group: 'Configuration',
      icon: <FontAwesomeIcon icon={faBriefcase} />,
      text: OfficeRoutes.JOB_TYPES.label,
      id: OfficeRoutes.JOB_TYPES.path,
      enabled: isAuthorized(
        PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_TYPES,
      ),
    },
    {
      group: 'Configuration',
      icon: <FontAwesomeIcon icon={faListCheck} />,
      text: OfficeRoutes.CHECKLISTS.label,
      id: OfficeRoutes.CHECKLISTS.path,
      enabled: isAuthorized(
        PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_CHECKLISTS,
      ),
    },
    {
      group: 'Configuration',
      icon: <FontAwesomeIcon icon={faTag} />,
      text: OfficeRoutes.TAGS.label,
      id: OfficeRoutes.TAGS.path,
      enabled: isAuthorized(PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TAGS),
    },
    {
      group: 'Configuration',
      icon: <FontAwesomeIcon icon={faBell} />,
      text: OfficeRoutes.NOTIFICATIONS_SETTINGS.label,
      id: OfficeRoutes.NOTIFICATIONS_SETTINGS.path,
      enabled: isAuthorized(
        PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_NOTIFICATIONS,
      ),
    },
    {
      group: 'Configuration',
      icon: <FontAwesomeIcon icon={faEnvelopeOpen} />,
      text: OfficeRoutes.DRIP_MARKETING_SETTINGS.label,
      id: OfficeRoutes.DRIP_MARKETING_SETTINGS.path,
      enabled:
        ffReader('dripMarketing') &&
        isAuthorized(
          PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_DRIP_MARKETING,
        ),
    },
    {
      group: 'Configuration',
      icon: <FontAwesomeIcon icon={faCalendarCheck} />,
      text: OfficeRoutes.APPOINTMENT_SETTINGS.label,
      id: OfficeRoutes.APPOINTMENT_SETTINGS.path,
      enabled:
        ffReader('end-of-appointment-next-steps') &&
        isAuthorized(
          PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_APPOINTMENT_SETTINGS,
        ),
    },
  ].filter(i => i.enabled)

  return (
    <div className="flex h-full flex-1 flex-row overflow-y-auto">
      <SubNavSidebar
        tab={tab}
        items={sidebarItems}
        select={(id: string) => navigate(id)}
      />
      <div className="flex w-auto flex-1 overflow-y-auto p-4">
        <Outlet />
      </div>
    </div>
  )
}

export default SettingsLayout
