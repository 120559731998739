import { R, debounce } from '@breezy/shared'
import classNames from 'classnames'
import React, {
  ComponentProps,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart as RechartsLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { TooltipProps } from 'recharts/types/component/Tooltip'
import { useResizeObserver } from '../../hooks/useResizeObserver'
import { getNextColor } from '../../utils/color-utils'
import { ChartTooltipContent } from './ChartTooltip'

const X_AXIS_KEY = 'x'
const Y_AXIS_KEY = 'y'
const RIGHT_AXIS_ID = 'right'

const DOT_RADIUS = 7
const ACTIVE_DOT_STROKE = 2

const ANIMATION_DURATION = 1500

type TooltipValueRenderer = (value: number) => React.ReactNode

type CustomerTooltipProps = TooltipProps<number, string> & {
  tooltipValueRenderers?: (TooltipValueRenderer | undefined)[]
  formattedLabelsMap: Record<string, React.ReactNode | undefined>
}

const CustomTooltip = React.memo<CustomerTooltipProps>(
  ({ label, payload, tooltipValueRenderers, formattedLabelsMap }) => {
    const data = useMemo(
      () =>
        payload?.map(({ name, value, color }, i) => ({
          label: name ?? 'Unknown',
          formattedLabel: name ? formattedLabelsMap[name] : undefined,
          value: tooltipValueRenderers?.[i]
            ? tooltipValueRenderers[i]?.(value as number)
            : value,
          color: color as string,
        })) ?? [],
      [formattedLabelsMap, payload, tooltipValueRenderers],
    )
    return <ChartTooltipContent title={label} data={data} />
  },
)

export type LineData = {
  label: string
  formattedLabel?: React.ReactNode
  data: number[]
  color?: string
  rightAxis?: boolean
  renderTooltipValue?: TooltipValueRenderer
  dashed?: boolean
}

type LineChartProps = {
  data: LineData[]
  xAxisValues: string[]
  xAxisFormatter?: (value: string) => string
  showLegend?: boolean
  yAxisFormatter?: (value: number) => string
  yAxisRightFormatter?: (value: number) => string
  yAxisDomain?: ComponentProps<typeof YAxis>['domain'] //! If specified, this will override the `startAtZero` prop
  yAxisTicks?: number[]
  allowDecimals?: boolean
  startAtZero?: boolean
  overflowHidden?: boolean
  truncateLeadingZeros?: boolean
}

// NOTE: make sure you memoize everything coming in here! Particularly the formatters!!! If you don't, you might run
// into a strange bug where the dots don't appear. https://github.com/recharts/recharts/issues/1426
export const LineChart = React.memo<LineChartProps>(
  ({
    data,
    xAxisValues,
    xAxisFormatter,
    showLegend,
    yAxisFormatter,
    yAxisRightFormatter,
    yAxisDomain,
    yAxisTicks,
    allowDecimals = false,
    startAtZero = true,
    overflowHidden = true,
    truncateLeadingZeros = false,
  }) => {
    const [chartData, hasRightAxis] = useMemo(() => {
      let hasRightAxis = false
      const chartData: Record<string, number | string>[] = []
      const seenNonZeroMap: Record<string, boolean> = {}
      for (let i = 0; i < xAxisValues.length; ++i) {
        const label = xAxisValues[i]
        const datum: Record<string, number | string> = { [X_AXIS_KEY]: label }
        for (const { label, data: seriesData, rightAxis } of data) {
          if (rightAxis) {
            hasRightAxis = true
          }
          if (
            !truncateLeadingZeros ||
            seriesData[i] !== 0 ||
            seenNonZeroMap[label]
          ) {
            datum[label] = seriesData[i]
            seenNonZeroMap[label] = true
          }
        }
        chartData.push(datum)
      }
      return [chartData, hasRightAxis]
    }, [data, truncateLeadingZeros, xAxisValues])

    const colorMap = useMemo(() => {
      let colorCounter = 0
      const colorMap: Record<string, string> = {}
      for (const { label, color } of data) {
        colorMap[label] = color ?? getNextColor(colorCounter++)
      }
      return colorMap
    }, [data])

    const valueFormatters = useMemo(
      () => R.pluck('renderTooltipValue', data),
      [data],
    )

    const containerRef = useRef<HTMLDivElement>(null)

    const [leftAxisWidth, setLeftAxisWidth] = useState(60)
    const [rightAxisWidth, setRightAxisWidth] = useState(60)

    // While we're waiting to calculate the axis widths, we don't want to show the chart. If we do, then after we
    // measure and set those widths, it will "lurch" when they change. So just don't show until we've got everything
    // settled
    const [preLoadHidden, setPreLoadHidden] = useState(true)

    // I know how this looks. I don't like it either. The issue is recharts doesn't give you a way to say "make the y
    // axis width the same as its content". All we can do is render the chart, look how wide the axis is, save that
    // value, then rerender with that as the hard-coded width. We can't set an ID for the axes so we have to do a CSS
    // selector on the y axis class and assume that the first one listed is the left and the second one is the right.
    // Now normally a `useLayoutEffect` triggers AFTER the DOM has loaded, but recharts is weird and on that first tick,
    // the axes aren't loaded yet. They must be doing the same `setTimeout(..., 0)` trick to get this at the bottom of
    // the JavaScript event stack. Because of that, instead of doing `setTimeout(..., 0)` we have to do `setTimeout(...,
    // 1)` so we're AFTER their `setTimeout` with `0`. BUT, that doesn't work in Firefox for some reason! If I set it to
    // 2, it doesn't work. If I set it to 100, it works, if I set it to 10, it SOMETIMES works. So I load this at `1`
    // and it will work for Chrome. If we don't find any `.recharts-yAxis` elements then we haven't loaded them yet, so
    // wait longer (10 ms arbitrarily chosen. Funny enough, when I choose 1 here it works in my tests. Very weird since
    // 2 didn't work! But instead of 1 I'm doing 10 here just to be safe. I don't want too many loops and 10ms for all
    // intents and purposes is basically the same amount of time to wait for the chart to load on the page as 1ms). Once
    // they're loaded, we proceed like we do with Chrome. Finally, I do the `isMounted` trick. This `useLayoutEffect`
    // has `[]` as its dependencies, meaning it will only happen once: on mount. In the callback of `useEffect` or
    // `useLayoutEffect`, you can return a function. When the hook reruns (because the dependencies change), it will
    // first run that function (meant for "cleanup"). If you have one like this where the dependencies are `[]`, that
    // only happens on unmount. So if we have `let isMount = true` scoped like this, when this unmounts, that will get
    // set to `false` (this used to be a super common pattern pre React 18 because if you had a `useEffect` that called
    // a `useState` state setter and happened to go after the component unmounts, you'd get this warning saying "you
    // can't set the state of an unmounted component". In React 18 they did this huge behind-the-scenes refactor so
    // setting the state of an unmounted component no longer matters). When our `setTimeout` loop is going every 10 ms
    // and it doesn't terminate before this unmounts (they ninja it and change the page really quickly, there's a bug
    // that's causing an infinite loop, etc) it will see that change to `false` and stop looping.
    useLayoutEffect(() => {
      // Hide the chart while we wait to measure the y axes
      setPreLoadHidden(true)
      // The `isMounted` trick described above. We start off mounted. If we unmount we'll set this to false
      let isMounted = true
      // This is the function we're going to loop
      const tryLoadingAxes = () => {
        // If we're unmounted, stop the infinite loop
        if (!isMounted) {
          return
        }
        if (containerRef.current) {
          const yAxes =
            containerRef.current?.querySelectorAll('.recharts-yAxis')

          // If `querySelectorAll` returns an empty array, then they aren't loaded yet and we have to reloop
          if (!yAxes?.length) {
            // Like mentioned above, 10 is an arbitrary choice here. When I tested 1 seems to work, but I'm worried that
            // might be slightly machine-dependent. I figured 10ms increments are pretty good because loading every 1ms
            // is kind of a lot, especially if they're on a machine that didn't go fast enough to be ready to go on the
            // first iteration.
            setTimeout(tryLoadingAxes, 10)
            // Return because if we're looping, we don't want to do all that other stuff
            return
          }

          // +2 to give it a little padding.
          const leftWidth = yAxes[0].getBoundingClientRect().width + 2
          setLeftAxisWidth(leftWidth)
          // If there's a second y axis, do that one too
          if (yAxes.length === 2) {
            const rightWidth = yAxes[1].getBoundingClientRect().width + 2
            setRightAxisWidth(rightWidth)
          }
          // We've loaded, so we can show the chart!
          setPreLoadHidden(false)
        }
      }
      // First time we're doing this and waiting for other recharts stuff to settle.
      setTimeout(tryLoadingAxes, 1)
      return () => {
        isMounted = false
      }
    }, [])

    const [animationsDisabled, setAnimationsDisabled] = useState(false)

    const debouncedDisableAnimations = useMemo(
      () => debounce(() => setAnimationsDisabled(true), ANIMATION_DURATION),
      [],
    )

    useResizeObserver(containerRef, debouncedDisableAnimations)

    const formattedLabelsMap = useMemo(() => {
      const formattedLabelsMap: Record<string, React.ReactNode | undefined> = {}
      for (const { label, formattedLabel } of data) {
        if (formattedLabel) {
          formattedLabelsMap[label] = formattedLabel
        }
      }
      return formattedLabelsMap
    }, [data])

    return (
      <div
        className={classNames('relative flex h-full w-full', {
          invisible: preLoadHidden,
          'overflow-hidden': overflowHidden,
        })}
        ref={containerRef}
      >
        {/* If I don't do this with the key, then it will render, shift a bit, then draw the amount shifted as part of
            the line on the right side. It will then animate the rest of the line. This forces it to reset. */}
        <ResponsiveContainer key={`${leftAxisWidth}_${rightAxisWidth}`}>
          <RechartsLineChart data={chartData} throttleDelay={500}>
            <XAxis
              dataKey={X_AXIS_KEY}
              tickLine={false}
              axisLine={{
                stroke: '#D9D9D9',
              }}
              padding={{ left: 10, right: 20 }}
              tickFormatter={xAxisFormatter}
              dy={12}
            />
            <YAxis
              domain={
                yAxisDomain
                  ? yAxisDomain
                  : startAtZero
                  ? undefined
                  : ['auto', 'auto']
              }
              allowDecimals={allowDecimals}
              yAxisId={Y_AXIS_KEY}
              axisLine={false}
              ticks={yAxisTicks}
              tickLine={false}
              tickFormatter={yAxisFormatter}
              stroke="#595959"
              width={leftAxisWidth}
              padding={{ top: 10 }}
            />
            {hasRightAxis && (
              <YAxis
                domain={startAtZero ? undefined : ['auto', 'auto']}
                allowDecimals={allowDecimals}
                yAxisId={RIGHT_AXIS_ID}
                orientation="right"
                axisLine={false}
                tickLine={false}
                tickFormatter={yAxisRightFormatter}
                stroke="#595959"
                width={rightAxisWidth}
                padding={{ top: 10 }}
              />
            )}
            <CartesianGrid
              vertical={false}
              strokeDasharray={3}
              stroke="#D9D9D9"
              horizontalCoordinatesGenerator={props =>
                yAxisTicks
                  ? yAxisTicks.map(props.yAxis.scale)
                  : // Only show lines for the actual ticks (otherwise it will put a weird one at the top even if there's no
                    // tick there). Also, remove the first one since it always overlaps with the x axis and looks weird.
                    props.yAxis.niceTicks.slice(1).map(props.yAxis.scale)
              }
            />
            <Tooltip
              content={
                <CustomTooltip
                  tooltipValueRenderers={valueFormatters}
                  formattedLabelsMap={formattedLabelsMap}
                />
              }
            />
            {showLegend && (
              <Legend
                wrapperStyle={{
                  paddingTop: '16px',
                }}
                formatter={value => (
                  <span className="inline-block translate-y-[1px] text-bz-gray-1000">
                    {formattedLabelsMap[value] ?? value}
                  </span>
                )}
              />
            )}
            {data.map(({ label, rightAxis, dashed }) => (
              <Line
                key={label}
                animationDuration={ANIMATION_DURATION}
                isAnimationActive={!animationsDisabled}
                type="monotone"
                legendType="square"
                dataKey={label}
                stroke={colorMap[label]}
                fill={colorMap[label]}
                strokeWidth={4}
                strokeDasharray={dashed ? '5 5' : undefined}
                yAxisId={rightAxis ? RIGHT_AXIS_ID : Y_AXIS_KEY}
                dot={{
                  r: DOT_RADIUS,
                  strokeWidth: ACTIVE_DOT_STROKE,
                  stroke: 'transparent',
                }}
                activeDot={{
                  r: DOT_RADIUS + ACTIVE_DOT_STROKE / 2,
                  strokeWidth: ACTIVE_DOT_STROKE,
                  stroke: 'white',
                  filter: 'drop-shadow(0px 0px 4px rgba(37, 37, 37, 0.25))',
                }}
              />
            ))}
          </RechartsLineChart>
        </ResponsiveContainer>
      </div>
    )
  },
)
