import {
  ESTIMATED_EQUIPMENT_AGE_OPTIONS,
  formatMaybeEquipmentType,
  isNullish,
} from '@breezy/shared'
import { Select } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'
import { ChangeJobTypeFormSchema } from '../../change-job-type-form-schema'

export const PreexistingEquipmentLinkInputList = () => {
  const { control, formState, watch } =
    useFormContext<ChangeJobTypeFormSchema>()

  const selectedPreexistingEquipment = watch('preexistingEquipment')

  if (
    isNullish(selectedPreexistingEquipment) ||
    selectedPreexistingEquipment.length === 0
  ) {
    return null
  }

  return (
    <div className="m-0 flex flex-col gap-4">
      {selectedPreexistingEquipment.map((preexistingEquipment, idx) => (
        <label
          key={preexistingEquipment.equipmentType}
          className="flex flex-row items-center gap-2"
        >
          <span>
            Age of{' '}
            <span className="font-semibold">
              {formatMaybeEquipmentType(preexistingEquipment.equipmentType)}:
            </span>
          </span>

          <Controller
            control={control}
            name={`preexistingEquipment.${idx}.equipmentAge`}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Select
                {...field}
                className="w-32"
                placeholder="Age"
                options={ESTIMATED_EQUIPMENT_AGE_OPTIONS.map(age => ({
                  label: age,
                  value: age,
                }))}
                status={
                  formState.errors.preexistingEquipment?.[idx]?.equipmentAge
                    ? 'error'
                    : undefined
                }
              />
            )}
          />
        </label>
      ))}
    </div>
  )
}
