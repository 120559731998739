import { BzDateFns, TimeZoneId } from '@breezy/shared'
import React, { useMemo } from 'react'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { Tile } from './Tile'

const getThisWeekRange = (tzId: TimeZoneId) => {
  const now = BzDateFns.now(tzId)

  const startOfWeek = BzDateFns.startOfWeek(now, { weekStartsOn: 1 })
  return {
    startDate: BzDateFns.formatISO(startOfWeek, tzId),
    endDate: BzDateFns.formatISO(now, tzId),
  }
}

export const EarnedRevenueThisWeekTile = React.memo(() => {
  const tzId = useExpectedCompanyTimeZoneId()

  const range = useMemo(() => getThisWeekRange(tzId), [tzId])

  const earnedRevenueQuery =
    trpc.reporting['aggregated-earned-revenue:get'].useQuery(range)

  const revenue = useMemo(
    () =>
      `$${(earnedRevenueQuery.data?.revenue ?? 0).toLocaleString('en-us', {
        maximumFractionDigits: 0,
      })}`,
    [earnedRevenueQuery.data],
  )

  return (
    <Tile
      title="This Week"
      loading={earnedRevenueQuery.isLoading}
      footerText="This is calculated from invoices that have been issued since Monday."
    >
      {revenue}
    </Tile>
  )
})
