import {
  BulkPricebookCategory,
  Guid,
  findItemsWithoutMatchingRows,
  findModifiedRows,
  findRowsWithoutMatchingItems,
} from '@breezy/shared'
import { Button, Tabs, Tree } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { usePricebookAdmin } from './hooks/usePricebookAdmin'

import {
  BulkPricebookItemRow,
  ChangedBulkPricebookRow,
  formatMoney,
  getChangedRow,
} from '@breezy/shared'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse, CollapseProps, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { DataNode } from 'antd/es/tree'
import {
  OnsiteBasicModal,
  OnsiteModal,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import ColoredCircle from '../../elements/ColoredCircle/ColoredCircle'
import { MaybeDataPoint } from '../../elements/MaybeDataPoint/MaybeDataPoint'
import { trpc } from '../../hooks/trpc'
import tailwindConfig from '../../tailwind.config'
import { useMessage } from '../../utils/antd-utils'
import {
  ItemPicker,
  ItemPickerCategory,
  ItemPickerItem,
} from '../Pricebook/ItemPicker'
import { useIsQboEnabled } from '../Quickbooks/QuickbooksSyncButton'

type ApplyChangesModalProps = {
  visible: boolean
  close: () => void
  submit: () => void
  loading?: boolean
  numChanges: number
}

const ApplyChangesModal = React.memo<ApplyChangesModalProps>(
  ({ visible, close, submit, numChanges, loading = false }) => {
    return (
      <OnsiteBasicModal
        headerBordered={false}
        onClose={close}
        open={visible}
        header="Apply pricebook changes"
        footer={
          <div className="flex min-w-0 flex-1 justify-end gap-2">
            <Button
              size="large"
              disabled={loading}
              loading={loading}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              disabled={loading}
              loading={loading}
              onClick={submit}
            >
              Update Pricebook
            </Button>
          </div>
        }
      >
        <div className="flex flex-col">
          <p>
            These changes will update{' '}
            <span className="font-semibold">{numChanges} items</span> in the
            pricebook. Are you sure you’d like to apply the changes?
          </p>
          <div className="flex gap-3 rounded-lg border border-solid border-daybreak-blue-300 bg-daybreak-blue-100 p-4">
            <div className="flex h-[36px] w-[36px] items-center justify-center rounded-full bg-daybreak-blue-200 p-4">
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="text-daybreak-blue-800"
              />
            </div>
            <p className="mb-0 text-sm">
              You’ll always be able to roll back any changes you apply by going
              to the <span className="font-semibold">‘Pricebook Settings’</span>{' '}
              page and clicking on{' '}
              <span className="font-semibold">‘Version History’.</span>
            </p>
          </div>
        </div>
      </OnsiteBasicModal>
    )
  },
)

const CollapseTitle = React.memo<React.PropsWithChildren>(({ children }) => {
  return <div className="flex items-center gap-1">{children}</div>
})

const ReviewCollapseTitle = React.memo<{
  color: string
  title: string
  itemCount: number
}>(({ color, title, itemCount }) => {
  return (
    <div className="flex justify-between">
      <CollapseTitle>
        <ColoredCircle color={color} />
        <span className="font-semibold">{title}</span>
        <span className="mt-[2px] text-xs text-bz-gray-700">
          {`${itemCount ?? 0} items`}
        </span>
      </CollapseTitle>
    </div>
  )
})

const MaybeRenderChangedField = React.memo<
  React.PropsWithChildren & {
    row: BulkPricebookItemRow | ChangedBulkPricebookRow
    attr: string
  }
>(({ children, row, attr }) => {
  const changedField = getChangedRow(row, attr)
  const tooltipText = useMemo(() => {
    if (!changedField) return ''
    let displayText = changedField.oldValue ? changedField.oldValue : 'Empty'
    if (displayText.length > 150) {
      displayText = `${displayText.substring(0, 97)}...`
    }
    return displayText
  }, [changedField])

  if (changedField) {
    return (
      // Override the parent td padding styles
      <div className="relative -m-4 flex bg-bz-yellow-100 p-4 pr-7">
        <span className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
          {children}
        </span>
        <Tooltip title={`Was ${tooltipText}`}>
          <FontAwesomeIcon
            className="absolute right-2 top-[50%] -translate-y-1/2 transform"
            icon={faInfoCircle}
          />
        </Tooltip>
      </div>
    )
  }
  return (
    <div className="flex">
      <span className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
        {children}
      </span>
    </div>
  )
})

const createItemCategoryTree = (
  items: BulkPricebookItemRow[],
  categories: BulkPricebookCategory[],
): (ItemPickerItem | ItemPickerCategory)[] => {
  const categoryMap: Record<Guid, BulkPricebookCategory> = {}
  categories.forEach(category => {
    categoryMap[category.pricebookCategoryGuid] = category
  })

  const tree: Record<Guid, ItemPickerCategory> = {}

  const getOrCreateCategoryNode = (guid: Guid): ItemPickerCategory => {
    if (!tree[guid]) {
      const category = categoryMap[guid]
      tree[guid] = {
        id: guid,
        name: category.name,
        items: [],
      }
      if (category?.parentCategoryGuid) {
        const parentNode = getOrCreateCategoryNode(category.parentCategoryGuid)
        parentNode.items.push(tree[guid])
      }
    }
    return tree[guid]
  }

  const rootItems: ItemPickerItem[] = []

  items.forEach(item => {
    if (!item.pricebookCategoryGuid) {
      rootItems.push({
        id: item.pricebookItemGuid,
        name: item.name,
        value: item.priceUsd,
      })
    } else {
      const categoryNode = getOrCreateCategoryNode(item.pricebookCategoryGuid)
      categoryNode.items.push({
        id: item.pricebookItemGuid,
        name: item.name,
        value: item.priceUsd,
        cdnUrl: item.sourcePhotoUrl,
      })
    }
  })

  const rootNodes = Object.values(tree).filter(
    node => !categoryMap[node.id]?.parentCategoryGuid,
  )

  return [...rootNodes, ...rootItems]
}

type PreviewItemPickerModalProps = {
  onClose: () => void
}
const PreviewItemPickerModal = React.memo<PreviewItemPickerModalProps>(
  ({ onClose }) => {
    const { pendingPricebookItems, pendingPricebookCategories } =
      usePricebookAdmin()

    const [selectedItemCountMap, setSelectedItemCountMap] = useState<
      Record<Guid, number>
    >({})

    const onItemSelect = useCallback((id: Guid, count: number) => {
      setSelectedItemCountMap(map => ({ ...map, [id]: count }))
    }, [])

    const pickerItems = useMemo(() => {
      if (!pendingPricebookItems || !pendingPricebookCategories) return []
      return createItemCategoryTree(
        pendingPricebookItems,
        pendingPricebookCategories,
      )
    }, [pendingPricebookCategories, pendingPricebookItems])
    return (
      <OnsiteModal onClose={onClose} size="large">
        <ItemPicker
          canSelectMultiple
          title="Add Line Item"
          items={pickerItems}
          onCancel={() => {
            onClose()
          }}
          selectedItemCountMap={selectedItemCountMap}
          onItemSelect={onItemSelect}
          hideSubmitButton
          cancelText="Close Preview"
        />
      </OnsiteModal>
    )
  },
)

type ReviewPricebookItemsTabProps = {
  modifiedPricebookItems: ChangedBulkPricebookRow[]
  deletedPricebookItems: BulkPricebookItemRow[]
  newPricebookItems: BulkPricebookItemRow[]
}
const ReviewPricebookItemsTab = React.memo<ReviewPricebookItemsTabProps>(
  ({ modifiedPricebookItems, deletedPricebookItems, newPricebookItems }) => {
    const qboEnabled = useIsQboEnabled()
    const columns: ColumnsType<BulkPricebookItemRow | ChangedBulkPricebookRow> =
      useMemo(() => {
        const cols: ColumnsType<
          BulkPricebookItemRow | ChangedBulkPricebookRow
        > = [
          {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            ellipsis: {
              showTitle: true,
            },
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.name.localeCompare(b.name),
            width: '20%',
          },
          {
            title: 'Description',
            key: 'description',
            render: (item: BulkPricebookItemRow | ChangedBulkPricebookRow) => {
              return (
                <MaybeRenderChangedField row={item} attr="description">
                  {item.description}
                </MaybeRenderChangedField>
              )
            },
            ellipsis: {
              showTitle: true,
            },
            width: '20%',
          },
          {
            title: 'Category',
            key: 'category',
            render: (item: BulkPricebookItemRow | ChangedBulkPricebookRow) => {
              return (
                <MaybeRenderChangedField row={item} attr="category">
                  <MaybeDataPoint>{item.category}</MaybeDataPoint>
                </MaybeRenderChangedField>
              )
            },
            sorter: (a, b) =>
              (a.category ?? '').localeCompare(b.category ?? ''),
            ellipsis: {
              showTitle: true,
            },
            width: '15%',
          },
          {
            title: 'Cost',
            key: 'costUsd',
            render: (item: BulkPricebookItemRow | ChangedBulkPricebookRow) => {
              return (
                <MaybeRenderChangedField row={item} attr="costUsd">
                  {formatMoney(item.costUsd)}
                </MaybeRenderChangedField>
              )
            },
            ellipsis: {
              showTitle: true,
            },
            sorter: (a, b) => a.costUsd - b.costUsd,
          },
          {
            title: 'Price',
            key: 'priceUsd',
            render: (item: BulkPricebookItemRow | ChangedBulkPricebookRow) => {
              return (
                <MaybeRenderChangedField row={item} attr="priceUsd">
                  {formatMoney(item.priceUsd)}
                </MaybeRenderChangedField>
              )
            },
            ellipsis: {
              showTitle: true,
            },
            sorter: (a, b) => a.priceUsd - b.priceUsd,
          },
          {
            title: 'Is Taxable?',
            key: 'isTaxable',
            render: (item: BulkPricebookItemRow | ChangedBulkPricebookRow) => {
              return (
                <MaybeRenderChangedField row={item} attr="isTaxable">
                  {item.isTaxable ? 'Yes' : 'No'}
                </MaybeRenderChangedField>
              )
            },
            ellipsis: {
              showTitle: true,
            },
          },
          {
            title: 'Is Discountable?',
            key: 'isDiscountable',
            render: (item: BulkPricebookItemRow | ChangedBulkPricebookRow) => {
              return (
                <MaybeRenderChangedField row={item} attr="isDiscountable">
                  {item.isDiscountable ? 'Yes' : 'No'}
                </MaybeRenderChangedField>
              )
            },
            ellipsis: {
              showTitle: true,
            },
          },
          {
            title: 'Status',
            key: 'isActive',
            render: (item: BulkPricebookItemRow | ChangedBulkPricebookRow) => {
              return (
                <MaybeRenderChangedField row={item} attr="isActive">
                  <div style={{ display: 'flex' }}>
                    <ColoredCircle
                      color={item.isActive ? 'limegreen' : 'lightgrey'}
                    />
                    <div
                      style={{
                        display: 'inline-block',
                        color: item.isActive ? 'black' : 'lightgrey',
                      }}
                    >
                      {item.isActive ? 'Active' : 'Disabled'}
                    </div>
                  </div>
                </MaybeRenderChangedField>
              )
            },
            ellipsis: {
              showTitle: true,
            },
            filters: [
              { text: 'Active', value: true },
              { text: 'Disabled', value: false },
            ],
            onFilter: (value, record) => record.isActive === value,
          },
          {
            title: 'Photo',
            key: 'sourcePhotoUrl',
            render: (item: BulkPricebookItemRow | ChangedBulkPricebookRow) => {
              return (
                <MaybeRenderChangedField row={item} attr="sourcePhotoUrl">
                  {item.sourcePhotoUrl}
                </MaybeRenderChangedField>
              )
            },
            ellipsis: {
              showTitle: true,
            },
          },
        ]
        if (qboEnabled) {
          cols.push({
            title: 'Qbo Income Account',
            key: 'qboIncomeAccountName',
            render: (item: BulkPricebookItemRow | ChangedBulkPricebookRow) => {
              return (
                <MaybeRenderChangedField row={item} attr="qboIncomeAccountName">
                  <MaybeDataPoint>{item.qboIncomeAccountName}</MaybeDataPoint>
                </MaybeRenderChangedField>
              )
            },
            ellipsis: {
              showTitle: true,
            },
          })
        }

        return cols
      }, [qboEnabled])

    const pricebookCollapseItems: CollapseProps['items'] = useMemo(
      () => [
        {
          key: 'modified',
          label: (
            <ReviewCollapseTitle
              color={tailwindConfig.theme.extend.colors['bz-yellow'][500]}
              title="Modified"
              itemCount={modifiedPricebookItems.length}
            />
          ),
          children: (
            <Table
              rowKey="name"
              pagination={{
                defaultPageSize: 15,
                pageSizeOptions: [15, 30, 100, 250],
              }}
              columns={columns}
              dataSource={modifiedPricebookItems}
            />
          ),
        },
        {
          key: 'deleted',
          label: (
            <ReviewCollapseTitle
              color={tailwindConfig.theme.extend.colors['bz-red'][500]}
              title="Deleted"
              itemCount={deletedPricebookItems.length}
            />
          ),
          children: (
            <Table
              rowKey="name"
              pagination={{
                defaultPageSize: 15,
                pageSizeOptions: [15, 30, 100, 250],
              }}
              columns={columns}
              dataSource={deletedPricebookItems}
            />
          ),
        },
        {
          key: 'new',
          label: (
            <ReviewCollapseTitle
              color={tailwindConfig.theme.extend.colors['bz-lime'][500]}
              title="New"
              itemCount={newPricebookItems.length}
            />
          ),
          children: (
            <Table
              rowKey="name"
              pagination={{
                defaultPageSize: 15,
                pageSizeOptions: [15, 30, 100, 250],
              }}
              columns={columns}
              dataSource={newPricebookItems}
            />
          ),
        },
      ],
      [
        columns,
        deletedPricebookItems,
        modifiedPricebookItems,
        newPricebookItems,
      ],
    )

    const numItemsUpdated = useMemo(
      () =>
        modifiedPricebookItems.length +
        deletedPricebookItems.length +
        newPricebookItems.length,
      [
        deletedPricebookItems.length,
        modifiedPricebookItems.length,
        newPricebookItems.length,
      ],
    )

    const defaultActiveKeys = useMemo(() => {
      const activeKeys: string[] = []
      if (modifiedPricebookItems.length > 0) activeKeys.push('modified')
      if (deletedPricebookItems.length > 0) activeKeys.push('deleted')
      if (newPricebookItems.length > 0) activeKeys.push('new')
      return activeKeys
    }, [
      deletedPricebookItems.length,
      modifiedPricebookItems.length,
      newPricebookItems.length,
    ])

    return (
      <div className="grid w-full grid-cols-5 gap-4">
        <div className="col-span-5 flex flex-col">
          <div className="mb-3 font-semibold">{`${numItemsUpdated} items updated`}</div>
          <Collapse
            className="bold-collapse-header-text w-full bg-inherit"
            collapsible="header"
            defaultActiveKey={defaultActiveKeys}
            size="large"
            items={pricebookCollapseItems}
          />
        </div>
      </div>
    )
  },
)

type BulkPricebookCategoryNode = BulkPricebookCategory & {
  children: BulkPricebookCategoryNode[]
}

const PricebookCategoryReviewTab = React.memo(() => {
  const { pendingPricebookCategories } = usePricebookAdmin()

  const categoryTreeData = useMemo(() => {
    if (!pendingPricebookCategories) return []
    const treeData: BulkPricebookCategoryNode[] = []
    const categoryMap: Record<string, BulkPricebookCategoryNode> = {}

    // First, map all categories by their guid
    pendingPricebookCategories.forEach(category => {
      categoryMap[category.pricebookCategoryGuid] = {
        ...category,
        children: [],
      }
    })

    // Then, build the tree structure
    pendingPricebookCategories.forEach(category => {
      const node = categoryMap[category.pricebookCategoryGuid]
      if (category.parentCategoryGuid) {
        const parentKey = category.parentCategoryGuid
        if (typeof parentKey === 'string' && categoryMap[parentKey]) {
          categoryMap[parentKey].children.push(node)
        }
      } else {
        treeData.push(node)
      }
    })

    // Recursive function to convert nodes to tree data format
    const convertToTreeData = (
      nodes: BulkPricebookCategoryNode[],
    ): DataNode[] | undefined =>
      nodes.map(node => ({
        title: node.name,
        key: node.pricebookCategoryGuid,
        children:
          node.children.length > 0
            ? convertToTreeData(node.children)
            : undefined,
      }))

    // Convert to the format expected by the Tree component
    return convertToTreeData(treeData)
  }, [pendingPricebookCategories])

  return (
    <div className="flex flex-col">
      <div className="mb-3 font-semibold ">Pricebook Category Organization</div>
      <Tree
        showLine={{ showLeafIcon: false }}
        blockNode
        selectable={false}
        treeData={categoryTreeData}
        defaultExpandAll
      />
    </div>
  )
})

type PricebookImportReviewProps = {
  onBack: () => void
  onClose: () => void
}
const PricebookImportReview = React.memo<PricebookImportReviewProps>(
  ({ onClose, onBack }) => {
    const [isConfirmOpen, setIsConfirmOpen] = useState(false)
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)
    const {
      pendingPricebookItems,
      pendingPricebookCategories,
      pricebookItems,
      categories,
      refetchAll,
      qboIncomeAccounts,
    } = usePricebookAdmin()
    const message = useMessage()
    const importPricebookMut =
      trpc.pricebook['pricebook:bulk-import'].useMutation()
    const importPricebook = useCallback(() => {
      if (!pendingPricebookItems || !pendingPricebookCategories) return

      importPricebookMut.mutate(
        {
          items: pendingPricebookItems,
          categories: pendingPricebookCategories,
        },
        {
          onSuccess: () => {
            message.success('Pricebook import successful')
            setIsConfirmOpen(false)
            onClose()
            refetchAll()
          },
          onError: err => {
            message.error('Pricebook import failed')
            console.error(err)
          },
        },
      )
    }, [
      importPricebookMut,
      message,
      onClose,
      pendingPricebookCategories,
      pendingPricebookItems,
      refetchAll,
    ])

    const newPricebookItems = useMemo(() => {
      if (!pendingPricebookItems) return []
      return findRowsWithoutMatchingItems(pendingPricebookItems, pricebookItems)
    }, [pendingPricebookItems, pricebookItems])

    const deletedPricebookItems = useMemo(() => {
      if (!pendingPricebookItems) return []
      return findItemsWithoutMatchingRows(
        pricebookItems,
        pendingPricebookItems,
        categories,
      )
    }, [categories, pendingPricebookItems, pricebookItems])

    const modifiedPricebookItems = useMemo(() => {
      if (!pendingPricebookItems) return []
      return findModifiedRows(
        pendingPricebookItems,
        pricebookItems,
        categories,
        qboIncomeAccounts,
      )
    }, [categories, pendingPricebookItems, pricebookItems, qboIncomeAccounts])

    const numChanges = useMemo(
      () =>
        modifiedPricebookItems.length +
        newPricebookItems.length +
        deletedPricebookItems.length,
      [
        deletedPricebookItems.length,
        modifiedPricebookItems.length,
        newPricebookItems.length,
      ],
    )

    const tabs = useMemo(
      () => [
        {
          label: 'Pricebook Items',
          key: 'pricebookItems',
          children: (
            <ReviewPricebookItemsTab
              modifiedPricebookItems={modifiedPricebookItems}
              deletedPricebookItems={deletedPricebookItems}
              newPricebookItems={newPricebookItems}
            />
          ),
        },
        {
          label: 'Pricebook Categories',
          key: 'pricebookCategories',
          children: <PricebookCategoryReviewTab />,
        },
      ],
      [deletedPricebookItems, modifiedPricebookItems, newPricebookItems],
    )

    return (
      <div className="flex flex-col">
        <Tabs
          items={tabs}
          className="mb-4"
          tabBarExtraContent={
            <div className="flex items-center gap-2">
              <Button
                onClick={onBack}
                size="large"
                icon={<FontAwesomeIcon icon={faArrowLeft} />}
              >
                Go Back
              </Button>
              <Button onClick={() => setIsPreviewOpen(true)} size="large">
                Preview
              </Button>
              <Button
                type="primary"
                size="large"
                onClick={() => setIsConfirmOpen(true)}
              >
                Submit
              </Button>
            </div>
          }
        />
        <ApplyChangesModal
          visible={isConfirmOpen}
          close={() => setIsConfirmOpen(false)}
          submit={importPricebook}
          numChanges={numChanges}
          loading={importPricebookMut.isLoading}
        />
        {isPreviewOpen && (
          <PreviewItemPickerModal onClose={() => setIsPreviewOpen(false)} />
        )}
      </div>
    )
  },
)

export default PricebookImportReview
