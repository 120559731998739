import { PricebookCategory } from '@breezy/shared'
import { Form, TreeSelect } from 'antd'
import { useMemo } from 'react'
import {
  buildSortedPricebookCategoryTreeStructure,
  toDefaultOptionTypeTreeSelectDataNode,
} from '../../Utils/utils'

export type PricebookFormItemCategoryTreeSelectProps = {
  categories: PricebookCategory[]
}
export const PricebookFormItemCategoryTreeSelect = ({
  categories,
}: PricebookFormItemCategoryTreeSelectProps) => {
  const sortedPricebookCategoriesTreeData = useMemo(
    () => buildSortedPricebookCategoryTreeStructure(categories),
    [categories],
  )

  const treeData = sortedPricebookCategoriesTreeData.map(
    toDefaultOptionTypeTreeSelectDataNode,
  )

  return (
    <Form.Item name="pricebookCategoryGuid" label="Item Category">
      <TreeSelect
        style={{ width: '100%' }}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeData={treeData}
        placeholder="Select a category"
        treeDefaultExpandAll
      />
    </Form.Item>
  )
}
