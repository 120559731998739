import { Tabs } from 'antd'
import { memo } from 'react'
import { TileGroup } from '../TileGroup'
import { TeamPerformanceByJobClassTiles } from './TeamPerformanceByJobClassTiles'
import { TeamPerformanceJob } from './team-performance-queries.gql'

type TeamPerformanceByJobClassWidgetProps = {
  jobs: TeamPerformanceJob[]
}

export const TeamPerformanceByJobClassWidget =
  memo<TeamPerformanceByJobClassWidgetProps>(({ jobs }) => {
    const opportunities = jobs.filter(job => job.isOpportunity)
    const hotLeads = jobs.filter(job => job.isHotLead)
    return (
      <>
        <div className="font-semibold text-bz-gray-1000">
          Team Performance by Job Class
        </div>
        <Tabs
          defaultActiveKey="1"
          tabBarStyle={{ marginBottom: '12px' }}
          items={[
            {
              label: 'All Jobs',
              key: 'All Jobs',
              children: (
                <TileGroup title="">
                  <TeamPerformanceByJobClassTiles
                    jobs={jobs}
                    showSalesAndInstallTiles={true}
                  />
                </TileGroup>
              ),
            },
            {
              label: 'Opportunities',
              key: 'Opportunities',
              children: (
                <TileGroup title="">
                  <TeamPerformanceByJobClassTiles
                    jobs={opportunities}
                    showSalesAndInstallTiles={false}
                  />
                </TileGroup>
              ),
            },
            {
              label: 'Hot Leads',
              key: 'Hot Leads',
              children: (
                <TileGroup title="">
                  <TeamPerformanceByJobClassTiles
                    jobs={hotLeads}
                    showSalesAndInstallTiles={false}
                  />
                </TileGroup>
              ),
            },
          ]}
        />
      </>
    )
  })
