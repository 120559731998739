import {
  changeRRuleUntil,
  formatTechnicianCapacityBlockReasonType,
  IsoDateString,
} from '@breezy/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { useSchedulePendingChanges } from './PendingChanges/SchedulePendingChangesContext'
import {
  RecurringChangeForm,
  RecurringChangeType,
} from './RecurringChangeModal'
import { BlockCalendarEvent, fixMbscDate } from './scheduleUtils'

export type DeleteEventData = {
  event: BlockCalendarEvent
  occurrenceStart: IsoDateString
}

type DeleteEventModalProps = {
  data: DeleteEventData
  onClose: () => void
}

export const DeleteEventModal = React.memo<DeleteEventModalProps>(
  ({ onClose, data }) => {
    const { event, occurrenceStart } = data

    const tzId = useExpectedCompanyTimeZoneId()
    const recurrenceRule = event.recurring ?? event.recurrenceRule
    const isRecurring = !!recurrenceRule
    const recurrenceRuleExceptions =
      event.recurringException ?? event.recurrenceRuleExceptions

    const { setPendingChanges } = useSchedulePendingChanges()

    const [changeType, setChangeType] =
      useState<RecurringChangeType>('THIS_EVENT_ONLY')

    const [start, end] = useMemo(
      () => [
        fixMbscDate(event.original?.start ?? event.start),
        fixMbscDate(event.original?.end ?? event.end),
      ],
      [event.end, event.original?.end, event.original?.start, event.start],
    )

    const onConfirm = useCallback(async () => {
      if (isRecurring) {
        // If it's all events, then we just delete the original and the rest follow. If
        // it's this and future, we update the "UNTIL" part of the RRule. If it's this
        // event only, we just add it to the recurrence exceptions list.
        switch (changeType) {
          case 'ALL_EVENTS':
            setPendingChanges({
              field: 'deletedEventMap',
              key: event.blockGuid,
              value: {
                blockGuid: event.blockGuid,
              },
            })
            break
          case 'THIS_AND_FUTURE':
            setPendingChanges({
              field: 'eventChangeMap',
              key: event.blockGuid,
              value: {
                blockGuid: event.blockGuid,
                userGuids: event.userGuids,
                start,
                end,
                reasonType: event.reasonType,
                reasonDescription: event.reasonDescription,
                recurrenceRule: recurrenceRule
                  ? changeRRuleUntil(recurrenceRule, occurrenceStart, tzId)
                  : undefined,
                recurrenceRuleExceptions,
              },
            })
            break
          case 'THIS_EVENT_ONLY':
            setPendingChanges({
              field: 'eventChangeMap',
              key: event.blockGuid,
              value: {
                blockGuid: event.blockGuid,
                userGuids: event.userGuids,
                start,
                end,
                reasonType: event.reasonType,
                reasonDescription: event.reasonDescription,
                recurrenceRule,
                recurrenceRuleExceptions: recurrenceRuleExceptions
                  ? `${recurrenceRuleExceptions},${occurrenceStart}`
                  : occurrenceStart,
              },
            })
            break
        }
      } else {
        setPendingChanges({
          field: 'deletedEventMap',
          key: event.blockGuid,
          value: {
            blockGuid: event.blockGuid,
          },
        })
      }
      onClose()
    }, [
      isRecurring,
      onClose,
      changeType,
      setPendingChanges,
      event,
      recurrenceRule,
      start,
      end,
      occurrenceStart,
      tzId,
      recurrenceRuleExceptions,
    ])

    return (
      <OnsiteConfirmModal
        danger
        header="Delete event"
        onCancel={onClose}
        onConfirm={onConfirm}
        confirmText="Delete Event"
      >
        <div>
          Are you sure you want to delete "
          {formatTechnicianCapacityBlockReasonType(event.reasonType)}"? This
          action cannot be undone.
        </div>
        {isRecurring && (
          <RecurringChangeForm
            className="mt-4"
            changeType={changeType}
            setChangeType={setChangeType}
            label="Delete recurring event"
          />
        )}
      </OnsiteConfirmModal>
    )
  },
)
