import {
  BzDateFns,
  CompanyTimesheetConfig,
  DefaultPayPeriodConfig,
  FallbackCompanyTimesheetsConfig,
  PayPeriodConfig,
  getPayPeriodWindowForDay,
  parseRRule,
} from '@breezy/shared'
import { useMemo } from 'react'
import { gql, useQuery } from 'urql'
import {
  useExpectedCompanyTimeZoneId,
  usePrincipalUser,
} from '../../providers/PrincipalUser'

export const FETCH_TIMESHEETS_CONFIG_GQL = gql(/* GraphQL */ `
  query FetchTimesheetsConfig($companyGuid: uuid!) {
    companyConfig(where: { companyGuid: { _eq: $companyGuid } }) {
      timesheetsEnabled
      timesheetPayPeriodBeginRrule
      timesheetPayPeriodBeginAnchor
    }
  }
`)

export const useFetchTimesheetsConfig = (): CompanyTimesheetConfig => {
  const companyGuid = usePrincipalUser()?.principal?.company?.companyGuid

  const [{ data, fetching, error }] = useQuery({
    query: FETCH_TIMESHEETS_CONFIG_GQL,
    pause: !companyGuid,
    variables: { companyGuid: companyGuid ?? '' },
    requestPolicy: 'network-only',
  })

  if (fetching) {
    return FallbackCompanyTimesheetsConfig
  }

  if (error) {
    console.error('Error fetching timesheets config:', error.message)
    return FallbackCompanyTimesheetsConfig // Return fallback config and stop here to prevent re-render loop.
  }

  return data?.companyConfig[0] ?? FallbackCompanyTimesheetsConfig
}

export const usePayPeriodConfig = (): PayPeriodConfig => {
  const tzId = useExpectedCompanyTimeZoneId()
  const timesheetsConfig = useFetchTimesheetsConfig()
  const rrule = useMemo(
    () => parseRRule(timesheetsConfig.timesheetPayPeriodBeginRrule),
    [timesheetsConfig.timesheetPayPeriodBeginRrule],
  )

  const currentPayPeriod = useMemo(
    () =>
      getPayPeriodWindowForDay(
        timesheetsConfig,
        BzDateFns.nowISOString(),
        tzId,
      ),
    [timesheetsConfig, tzId],
  )

  return currentPayPeriod
    ? { currentPayPeriod, rrule, baseConfig: timesheetsConfig }
    : DefaultPayPeriodConfig
}
