import { gql } from '../../generated'

export const COMPANY_DRIP_MARKETING_CONFIG_QUERY = gql(/* GraphQL */ `
  query CompanyDripMarketingConfig($companyGuid: uuid!) {
    companyDripMarketingConfigByPk(companyGuid: $companyGuid) {
      yelpReviewUrl
      googleReviewUrl
      facebookReviewUrl
      jobCompletedEnabled
      jobCompletedDelayMs
      jobCompletedCustomEmailContent
      maintenanceRemindersEnabled
      maintenanceRemindersDelayMonths
      maintenanceRemindersCustomEmailContent
    }
  }
`)

export const BILLING_PROFILE_FOR_BASIC_BRANDED_EMAIL_PREVIEW_QUERY =
  gql(/* GraphQL */ `
    query BillingProfileForBasicBrandedEmailPreview($companyGuid: uuid!) {
      billingProfilesByPk(companyGuid: $companyGuid) {
        logoUrl
        phoneNumber {
          phoneNumber
        }
        emailAddress {
          emailAddress
        }
        websiteUrl
        websiteDisplayName
      }
      companiesByPk(companyGuid: $companyGuid) {
        name
      }
    }
  `)
