import {
  AppointmentChecklistInstanceChecklist,
  appointmentChecklistInstanceChecklistSchema,
  BzDateFns,
  CalculatePaths,
  dates,
  toPlural,
} from '@breezy/shared'
import { faClose } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Drawer } from 'antd'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { AppointmentChecklistInstanceForm } from '../../components/AppointmentChecklists/AppointmentChecklistInstanceForm'
import { Link } from '../../elements/Link/Link'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { Item } from './AppointmentDetailsDrawer'
import {
  FullScheduleAppointment,
  getColorForJobClass,
  TechnicianResource,
} from './scheduleUtils'

type ChecklistsDrawerProps = {
  open: boolean
  appointment: FullScheduleAppointment
  techMap: Record<string, TechnicianResource>
  onClose: () => void
}

export const ChecklistsDrawer = React.memo<ChecklistsDrawerProps>(
  ({ open, appointment, onClose, techMap }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const {
      job,
      appointmentType,
      appointmentChecklistInstances,
      appointmentWindowStart,
      appointmentWindowEnd,
      assignments,
      appointmentGuid,
    } = appointment

    const { jobType, location } = job
    const { locationGuid, address } = location

    const range = useMemo(() => {
      return dates.calculateDateTimeWindow(
        appointmentWindowStart,
        appointmentWindowEnd,
        tzId,
        {
          includeDate: true,
        },
      )
    }, [appointmentWindowEnd, appointmentWindowStart, tzId])

    const techs = useMemo(
      () =>
        assignments
          ?.map(({ technicianUserGuid }) => {
            const tech = techMap[technicianUserGuid]
            return `${tech.firstName} ${tech.lastName}`
          })
          .join(', ') || 'N/A',
      [assignments, techMap],
    )

    const parsedChecklists = useMemo(() => {
      const checklists: {
        checklist: AppointmentChecklistInstanceChecklist
        lastEditedBy: string
        lastEditedOn: string
      }[] = []

      for (const instance of appointmentChecklistInstances) {
        try {
          const checklist = appointmentChecklistInstanceChecklistSchema.parse(
            instance.checklist,
          )
          const tech = techMap[instance.lastEditorUserGuid ?? '']
          checklists.push({
            checklist,
            lastEditedBy: tech
              ? `${tech.firstName} ${tech.lastName}`
              : 'Unknown',
            lastEditedOn: BzDateFns.formatFromISO(
              instance.updatedAt,
              'MMM dd, yyyy @ h:mma',
              tzId,
            ),
          })
        } catch (e) {
          console.error('Could not parse checklist', {
            appointmentGuid,
            instance,
          })
        }
      }
      return checklists
    }, [appointmentChecklistInstances, appointmentGuid, techMap, tzId])
    return (
      <Drawer
        open={open}
        closeIcon={false}
        styles={{ body: { padding: 0 } }}
        width={624}
        onClose={onClose}
      >
        <div className="flex max-h-screen min-h-screen flex-col">
          <div
            className={classNames(
              'min-h-2 w-full',
              getColorForJobClass(jobType.jobClass).ribbon,
            )}
          />
          <div className="mb-3 mt-6 flex flex-row items-center px-8">
            <div className="flex-1 text-xl font-semibold">
              Checklist Details
            </div>

            <Button
              shape="circle"
              icon={<FontAwesomeIcon icon={faClose} />}
              onClick={onClose}
            />
          </div>
          <div className="relative min-h-0 flex-1 overflow-auto px-8">
            <div className="border-0 border-b border-solid border-b-bz-border-secondary pb-4">
              <div className="mb-3 text-base font-semibold">
                Appointment Details
              </div>
              <div className="grid grid-cols-2 gap-x-2 gap-y-3">
                <Item title="Appointment Info">
                  {appointmentType} for {jobType.name}
                </Item>

                <Item title="Location">
                  <Link
                    bold={false}
                    to={CalculatePaths.locationDetails({
                      locationGuid,
                    })}
                  >
                    {[
                      address.line1,
                      address.line2,
                      `${address.city}, ${address.stateAbbreviation}, ${address.zipCode}`,
                    ]
                      .filter(Boolean)
                      .map((line, i) => (
                        <div key={i}>{line}</div>
                      ))}
                  </Link>
                </Item>
                <Item title={toPlural(assignments?.length ?? 0, 'Technician')}>
                  {techs}
                </Item>
                <Item title="Arrival Window">{range}</Item>
              </div>
            </div>
            <div className="divide-y divide-bz-border-secondary">
              {parsedChecklists.length ? (
                parsedChecklists.map(
                  ({ checklist, lastEditedBy, lastEditedOn }, i) => (
                    <div
                      key={`${checklist.name}_${i}`}
                      className="mb-4 border-0 border-solid pt-4"
                    >
                      <div className="mb-4 text-base font-semibold">
                        {checklist.name}
                      </div>
                      <div className="mb-4 grid grid-cols-2 gap-x-2 gap-y-3">
                        <Item title="Last Edited By">{lastEditedBy}</Item>
                        <Item title="Last Edited On">{lastEditedOn}</Item>
                      </div>
                      <AppointmentChecklistInstanceForm
                        items={checklist.items}
                      />
                    </div>
                  ),
                )
              ) : (
                <div>No checklists available</div>
              )}
            </div>
          </div>
        </div>
      </Drawer>
    )
  },
)
