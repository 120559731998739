import { PageHeader } from '@ant-design/pro-components'
import { faBuildingMemo } from '@fortawesome/pro-light-svg-icons'
import { useQuery } from 'urql'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import { Page } from '../../components/Page/Page'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { BILLING_PROFILE_QUERY } from './BillingProfileSettingsPage.gql'
import { BillingProfileSettingsPageContent } from './components/BillingProfileSettingsPageContent'

export const BillingProfileSettingsPage = () => {
  const { companyGuid } = useExpectedCompany()

  const billingProfileQuery = useQuery({
    query: BILLING_PROFILE_QUERY,
    variables: { companyGuid },
  })

  return (
    <Page className="flex flex-col p-0" requiresCompanyUser>
      <PageHeader
        title={<PageTitle title="Billing Profile" icon={faBuildingMemo} />}
      />

      <ScrollCard
        className="mt-auto flex h-full w-full flex-col gap-3"
        hasPageHeading
      >
        <GqlQueryLoader
          query={billingProfileQuery}
          render={data => (
            <BillingProfileSettingsPageContent
              billingProfile={data.billingProfiles[0]}
              refresh={billingProfileQuery[1]}
            />
          )}
        />
      </ScrollCard>
    </Page>
  )
}
