import {
  fromCamelCaseToPascalCase,
  withSpacesBetweenWords,
} from '@breezy/shared'
import Papa from 'papaparse'

const downloadStringToFile = (
  data: string,
  fileName: string,
  fileType: string,
) => {
  const blob = new Blob([data], { type: fileType })

  // Create a temporary anchor element
  const a = document.createElement('a')
  a.style.display = 'none'
  document.body.appendChild(a)

  // Set attributes and trigger download
  const url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = fileName
  a.click()

  // Cleanup
  window.URL.revokeObjectURL(url)
  document.body.removeChild(a)
}

export const downloadCsv = (csvString: string, fileName: string) =>
  downloadStringToFile(csvString, fileName, 'text/csv')

const CSV_DELIMITER = ','

export const downloadDataAsCsv = <T extends Record<string, unknown>>(
  data: T[],
  filename: string,
) => {
  if (data.length < 1) {
    console.error('No data to export to CSV')
    return
  }

  const csvHeader = Object.keys(data[0])
    .map(key => withSpacesBetweenWords(fromCamelCaseToPascalCase(key)))
    .join(CSV_DELIMITER)

  const csvRows = Papa.unparse(data, {
    delimiter: CSV_DELIMITER,
    header: false,
  })
  downloadCsv(`${csvHeader}\r\n${csvRows}`, filename)
}
