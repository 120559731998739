import {
  RecommendationType,
  RecommendationViewModel,
  formatUsc,
} from '@breezy/shared'
import {
  faCheckCircle,
  faQuestionCircle,
  faXmarkCircle,
} from '@fortawesome/pro-light-svg-icons'
import {
  faAirConditioner,
  faDollarSign,
  faScrewdriverWrench,
  faServer,
  faSparkles,
} from '@fortawesome/pro-solid-svg-icons'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { Button, Popover } from 'antd'
import { MouseEvent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import ThinDivider from '../../elements/ThinDivider'
import { m } from '../../utils/react-utils'

type IconProps = {
  icon: FontAwesomeIconProps['icon']
  colorClassName: string
}

const Icon = m<IconProps>(({ icon, colorClassName }) => (
  <div
    className={`h-8 min-w-8 rounded-full border-2 border-solid border-${colorClassName} center-children-vh`}
  >
    <FontAwesomeIcon icon={icon} className={`h-5 w-5 text-${colorClassName}`} />
  </div>
))

const iconForType = (type: RecommendationType): JSX.Element => {
  const color = 'gray-200'
  switch (type) {
    case 'COLLECT_OVERDUE_INVOICE':
      return <Icon icon={faDollarSign} colorClassName={color} />
    case 'FOLLOW_UP_ON_OPEN_ESTIMATE':
      return <Icon icon={faDollarSign} colorClassName={color} />
    case 'MAINTENANCE_VISIT_OPPORTUNITY':
      return <Icon icon={faScrewdriverWrench} colorClassName={color} />
    case 'INSTALL_LEAD':
      return <Icon icon={faAirConditioner} colorClassName={color} />
    case 'MULTI_SYSTEM_LEAD':
      return <Icon icon={faServer} colorClassName={color} />
    case 'EQUIPMENT_UPSELL_LEAD':
      return <Icon icon={faAirConditioner} colorClassName={color} />
    case 'MAINTENANCE_PLAN_LEAD':
      return <Icon icon={faScrewdriverWrench} colorClassName={color} />
    default:
      return <></>
  }
}

export type RecommendationCardProps = {
  viewModel: RecommendationViewModel
  longestTitleText: string
  onDismiss: (recommendationGuid: string) => void
  onComplete: (recommendationGuid: string) => void
  onInteract: (recommendationGuid: string) => void
}

export const RecommendationCard = m<RecommendationCardProps>(
  ({ viewModel: vm, onDismiss, onComplete, onInteract, longestTitleText }) => {
    const {
      recommendationGuid,
      recommendationType,
      ctaMarkdown,
      ctaButtonText,
      ctaButtonUrl,
      valueExpectedDescription,
      valueExpectedUsc,
      description,
    } = vm
    const navigate = useNavigate()

    const useCta = useCallback(
      (e: MouseEvent) => {
        e.preventDefault()
        onInteract(recommendationGuid)
        navigate(ctaButtonUrl)
      },
      [ctaButtonUrl, onInteract, navigate, recommendationGuid],
    )

    const titleNumLines = Math.max(2, Math.ceil(longestTitleText.length / 40))
    const titleMinHeight = `${titleNumLines * 28}px`

    return (
      <div className="w-[440px] min-w-[320px] flex-col items-center justify-between gap-y-2 rounded-lg border border-solid border-gray-200 bg-white pb-4 pl-4 pr-4 pt-0 shadow-md">
        <div className="flex-between mt-4 flex w-full flex-row">
          <h3
            className="mr-4 text-lg font-semibold"
            dangerouslySetInnerHTML={{ __html: ctaMarkdown }}
            style={{ minHeight: titleMinHeight }}
          />
          {iconForType(recommendationType)}
        </div>
        <p className="min-h-[84px] text-xs text-gray-500">{description}</p>
        {valueExpectedUsc && (
          <div className="flex-between flex items-center">
            <div className="flex items-center">
              <span className="mr-2 text-gray-500">Expected Revenue</span>
              <span className="text-md font-semibold">
                {formatUsc(valueExpectedUsc || 0)}
              </span>
              {valueExpectedDescription && (
                <Popover
                  content={valueExpectedDescription || ''}
                  overlayClassName="max-w-[300px]"
                >
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="mb-2 ml-2 text-gray-400"
                    style={{ fontSize: '12px' }}
                  />
                </Popover>
              )}
            </div>
            <div className="flex items-center">
              <Popover
                content="Recommendation Generated by AI"
                overlayClassName="max-w-[300px]"
              >
                <FontAwesomeIcon
                  icon={faSparkles}
                  color="#f7d14a"
                  className="h-5 w-5"
                />
              </Popover>
            </div>
          </div>
        )}
        <ThinDivider />
        <div className="mt-4 flex w-full flex-row items-center">
          <Button type="primary" ghost onClick={e => useCta(e)}>
            {ctaButtonText}
          </Button>
          <Button
            onClick={() => onComplete(recommendationGuid)}
            type="text"
            className="ml-2 flex flex-row items-center text-gray-500"
          >
            <FontAwesomeIcon icon={faCheckCircle} className="mr-2 h-5 w-5" />
            Complete
          </Button>
          <Button
            onClick={() => onDismiss(recommendationGuid)}
            type="text"
            className="flex flex-row items-center text-gray-500"
          >
            <FontAwesomeIcon icon={faXmarkCircle} className="mr-2 h-5 w-5" />
            Dismiss
          </Button>
        </div>
      </div>
    )
  },
)
