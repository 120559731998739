import { Button, Modal } from 'antd'

export type UnsavedChangesModalProps = {
  open: boolean
  onSavePressed: () => void
  onDiscardPressed: () => void
  onCancel: () => void
}

export const UnsavedChangesModal = ({
  open,
  onSavePressed,
  onDiscardPressed,
  onCancel,
}: UnsavedChangesModalProps) => {
  return (
    <Modal
      open={open}
      title="Unsaved Changes"
      onCancel={onCancel}
      footer={
        <div className="flex w-full flex-col">
          <div className="flex flex-row self-end">
            <Button type="primary" onClick={onSavePressed}>
              Save
            </Button>
            <Button danger onClick={onDiscardPressed}>
              Discard
            </Button>
          </div>
        </div>
      }
    >
      <p>
        You have unsaved changes on this page. Would you like to save your
        changes before navigating away or discard them?
      </p>
    </Modal>
  )
}
