import { RemindersWeeklyDigestEmailData, isDueLastWeek, isDueThisWeek } from '@breezy/shared'
import {
  Body,
  Button,
  Column,
  Container,
  Head,
  Heading,
  Hr,
  Html,
  Img,
  Row,
  Section,
  Text,
} from '@react-email/components'
import React from 'react'
import AccountReminder from '../AccountReminder/AccountReminder'
import ReminderStatusIcon from '../ReminderStatusIcon/ReminderStatusIcon'
import Tailwind from '../Tailwind/Tailwind'

const BREEZY_LOGO_URL = 'https://s3.us-west-1.amazonaws.com/www.getbreezyapp.com/images/Breezy-01.png'

const RemindersWeeklyDigestEmail = React.memo<RemindersWeeklyDigestEmailData>(
  ({ userFirstName, breezyAppUrl, companyTimeZoneId, accountReminderEmails, companyLogoUrl }) => {
    const lastWeeksReminders = accountReminderEmails.filter(reminder =>
      isDueLastWeek(reminder.dueAt, companyTimeZoneId),
    )
    const thisWeeksReminders = accountReminderEmails.filter(reminder =>
      isDueThisWeek(reminder.dueAt, companyTimeZoneId),
    )

    return (
      <Html>
        <Tailwind>
          <Head />
          <Body className="bg-white font-body p-6">
            <Container>
              <Section className="mb-6">
                <Img
                  alt="Company logo"
                  src={companyLogoUrl ?? BREEZY_LOGO_URL}
                  width={164}
                  className="block border-0 h-auto leading-100 outline-none decoration-none object-contain object-left max-w-full"
                />
              </Section>
              <Section>
                <Text className="font-semibold text-[16px] text-bz-gray-1000">Hello {userFirstName},</Text>
                <Text className="text-[16px] text-bz-gray-1000">
                  We hope you’re having a great day. Here’s an overview of the reminders that you have due this week.
                </Text>
              </Section>
              <Section className="mt-3 mb-6">
                <Button
                  href={breezyAppUrl}
                  className="bg-bz-primary rounded-[4px] text-[14px] px-6 py-3 text-white font-semibold leading-6 text-center align-middle"
                >
                  Go to Breezy
                </Button>
              </Section>
              <Section>
                <Heading className="font-semibold text-[16px] text-bz-gray-1000">Due This Week</Heading>
                <Hr />
              </Section>
              <Section>
                {thisWeeksReminders?.length ? (
                  thisWeeksReminders.map(reminder => {
                    return (
                      <AccountReminder
                        key={reminder.accountGuid}
                        accountReminderEmail={reminder}
                        accountDetailsUrl={`${breezyAppUrl}/accounts/${reminder.accountGuid}`}
                        companyTimeZoneId={companyTimeZoneId}
                      />
                    )
                  })
                ) : (
                  <Row>
                    <Column className="w-[1px] whitespace-nowrap">
                      <div className="mr-3">
                        <ReminderStatusIcon status="COMPLETE" />
                      </div>
                    </Column>
                    <Column>
                      <Text className="text-[16px] text-bz-gray-1000">Hooray! You're all clear this week.</Text>
                    </Column>
                  </Row>
                )}
              </Section>
              <Section>
                <Heading className="font-semibold text-[16px] text-bz-gray-1000 mt-8">Due Last Week</Heading>
                <Hr />
              </Section>
              <Section>
                {lastWeeksReminders?.length ? (
                  lastWeeksReminders.map(reminder => {
                    return (
                      <AccountReminder
                        key={reminder.accountGuid}
                        accountReminderEmail={reminder}
                        accountDetailsUrl={`${breezyAppUrl}/accounts/${reminder.accountGuid}`}
                        companyTimeZoneId={companyTimeZoneId}
                      />
                    )
                  })
                ) : (
                  <Row>
                    <Column className="w-[1px] whitespace-nowrap">
                      <div className="mr-3">
                        <ReminderStatusIcon status="COMPLETE" />
                      </div>
                    </Column>
                    <Column>
                      <Text className="text-[16px] text-bz-gray-1000">
                        Nothing overdue - great job staying on top of things!
                      </Text>
                    </Column>
                  </Row>
                )}
              </Section>
            </Container>
          </Body>
        </Tailwind>
      </Html>
    )
  },
)

export default RemindersWeeklyDigestEmail
