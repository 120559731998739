import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faHandshake, faWrench } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OnsiteBasicModal } from 'src/adam-components/OnsiteModal/OnsiteModal'

type CreateNewPipelineModalPipelineOptionButtonProps = {
  title: string
  description: string
  icon: IconProp
  onClick: () => void
}

const CreateNewPipelineModalPipelineOptionButton = ({
  title,
  description,
  icon,
  onClick,
}: CreateNewPipelineModalPipelineOptionButtonProps) => {
  return (
    <button
      className="flex flex-row items-center gap-3 rounded-sm border-none bg-transparent p-2 hover:cursor-pointer hover:bg-gray-50"
      onClick={onClick}
    >
      <div className="flex h-[44px] w-[44px] flex-col items-center justify-center rounded-full bg-gray-200 p-3">
        <FontAwesomeIcon icon={icon} />
      </div>

      <div className="flex flex-col items-start">
        <span className="text-left text-base font-semibold">{title}</span>
        <span className="text-left text-sm text-bz-gray-800">
          {description}
        </span>
      </div>
    </button>
  )
}

export type CreateNewPipelineModalProps = {
  open: boolean
  onCreateJobPipeline: () => void
  onCreateSalesPipeline: () => void
  onClose: () => void
}

export const CreateNewPipelineModal = ({
  open,
  onCreateJobPipeline,
  onCreateSalesPipeline,
  onClose,
}: CreateNewPipelineModalProps) => {
  return (
    <OnsiteBasicModal
      open={open}
      onClose={onClose}
      header={'Create pipeline'}
      headerBordered
    >
      <div className="flex flex-col gap-4">
        <CreateNewPipelineModalPipelineOptionButton
          title="Job Pipeline"
          description="Manage install, service, and maintenance teams and processes"
          icon={faWrench}
          onClick={onCreateJobPipeline}
        />

        <CreateNewPipelineModalPipelineOptionButton
          title="Sales Pipeline"
          description="Manage sales teams and processes"
          icon={faHandshake}
          onClick={onCreateSalesPipeline}
        />
      </div>
    </OnsiteBasicModal>
  )
}
