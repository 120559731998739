import { useMutation } from 'urql'
import { FeatureEnableView } from '../../components/FeatureEnableView/FeatureEnableView'
import { UPSERT_COMPANY_CONFIG_TIMESHEETS_ENABLED_MUTATION } from '../../gql/CompanyConfig.gql'
import { useFetchTimesheetsConfig } from '../../hooks/fetch/useFetchTimesheetsConfig'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'

type TimesheetsEnableViewProps = {
  readonly refetch: () => void
}

export const TimesheetsEnableView = m<TimesheetsEnableViewProps>(
  ({ refetch }) => {
    const companyGuid = useExpectedCompanyGuid()
    const timesheetsConfig = useFetchTimesheetsConfig()

    const [{ fetching }, upsert] = useMutation(
      UPSERT_COMPANY_CONFIG_TIMESHEETS_ENABLED_MUTATION,
    )

    return (
      <FeatureEnableView
        featureTitle="Timesheets"
        featureEnabled={timesheetsConfig?.timesheetsEnabled ?? false}
        isLoading={fetching || !timesheetsConfig}
        setValue={async timesheetsEnabled => {
          await upsert({ config: { companyGuid, timesheetsEnabled } })
        }}
        refetch={refetch}
        toggleContainerClassName=""
      />
    )
  },
)
