import { isNullish, noOp } from '@breezy/shared'
import { Upload, UploadFile } from 'antd'
import ImgCrop from 'antd-img-crop'
import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useMessage } from '../../../utils/antd-utils'
import { imageToBase64 } from '../../../utils/file-utils'
import {
  BillingProfileFormSchema,
  COMPANY_LOGO_MAX_SIZE,
} from '../billing-profile-form'

export const BillingProfileSettingsCompanyInfoSection = () => {
  const message = useMessage()

  const { control, watch } = useFormContext<BillingProfileFormSchema>()

  const formValues = watch()

  const selectedCompanyLogo: UploadFile | null = useMemo(() => {
    if (
      isNullish(formValues.company.logoUrl) ||
      formValues.company.logoUrl === ''
    ) {
      return null
    }

    return {
      uid: '-1',
      name: 'companyLogo',
      url: formValues.company.logoUrl,
    }
  }, [formValues.company.logoUrl])

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row gap-6">
        <span className="w-32">Company Logo</span>

        <Controller
          control={control}
          name="company.logoUrl"
          render={({ field }) => (
            <ImgCrop
              aspect={
                COMPANY_LOGO_MAX_SIZE.width / COMPANY_LOGO_MAX_SIZE.height
              }
              minZoom={0.1}
              maxZoom={3}
              cropperProps={{
                style: {},
                zoomSpeed: 1,
                mediaProps: {},
                restrictPosition: false,
              }}
            >
              <Upload
                {...field}
                maxCount={1}
                listType="picture-card"
                showUploadList
                fileList={
                  isNullish(selectedCompanyLogo) ? [] : [selectedCompanyLogo]
                }
                customRequest={({ onSuccess }) => onSuccess?.('Ok')}
                beforeUpload={async file => {
                  try {
                    const base64 = await imageToBase64(file)
                    field.onChange(base64)
                    return false
                  } catch {
                    message.error('Could not upload file')
                    field.onChange('')
                    return Upload.LIST_IGNORE
                  }
                }}
                // For some reason without an onChange function defined, beforeUpload breaks when trying to update the
                // form value (field.onChange). So I guess this is required?? This doesn't make any sense and I'm not
                // gonna investigate further because Antd already gives me enough hell as it is.
                onChange={noOp}
              >
                + Add Logo
              </Upload>
            </ImgCrop>
          )}
        />
      </div>
    </div>
  )
}
