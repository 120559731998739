import { useToken } from '@ant-design/pro-components'
import { JobLifecycleType } from '@breezy/shared'
import { faChevronDown, faTableColumns } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Select, Switch } from 'antd'
import React, { useCallback, useContext, useMemo } from 'react'
import {
  JobLifecycleColumnDisplayPropertySettings,
  JobLifecycleDisplayPropertySettings,
  JobLifecycleTimePeriod,
  JobLifecycleTimePeriodOptions,
  getDefaultJobLifecycleColumnDisplayPropertiesForLifecycleType,
} from '../../../hooks/useJobLifecyclePersistedDisplay'
import { JobKanbanContext } from '../JobKanbanContext'

type DisplayDropdownProps = {
  selectedJobLifecycleType?: JobLifecycleType
  closedJobTimePeriod: JobLifecycleTimePeriod
  setClosedJobTimePeriod: (timePeriod: JobLifecycleTimePeriod) => void
  showEmptyStatuses: boolean
  setShowEmptyStatuses: (newStatus: boolean) => void
  setColumnPropertiesSettings: (
    newSettings: JobLifecycleColumnDisplayPropertySettings,
  ) => void
  setDisplayPropertiesSettings: (
    newSettings: JobLifecycleDisplayPropertySettings,
  ) => void
  hiddenDisplayPropertySettings?: (keyof JobLifecycleDisplayPropertySettings)[]
}

export const DisplayDropdown = React.memo<DisplayDropdownProps>(
  ({
    selectedJobLifecycleType,
    closedJobTimePeriod,
    setClosedJobTimePeriod,
    showEmptyStatuses,
    setShowEmptyStatuses,
    setColumnPropertiesSettings,
    setDisplayPropertiesSettings,
    hiddenDisplayPropertySettings = [],
  }) => {
    const {
      selectedLifecycleGuid,
      displayPropertiesSettings,
      columnPropertiesSettings,
    } = useContext(JobKanbanContext)
    // https://ant.design/components/dropdown#components-dropdown-demo-custom-dropdown
    const { token } = useToken()

    const selectedLifecycleColumnPropertySettings = useMemo(() => {
      return (
        columnPropertiesSettings[selectedLifecycleGuid] ??
        getDefaultJobLifecycleColumnDisplayPropertiesForLifecycleType(
          selectedJobLifecycleType,
        )
      )
    }, [
      columnPropertiesSettings,
      selectedJobLifecycleType,
      selectedLifecycleGuid,
    ])

    const dropdownRender = useCallback(() => {
      const contentStyle: React.CSSProperties = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
      }
      return (
        <div style={contentStyle} className="w-[300px] space-y-4 p-4">
          {(
            [
              [
                'Show closed jobs',
                <Select
                  value={closedJobTimePeriod}
                  onChange={setClosedJobTimePeriod}
                  className="min-w-[100px]"
                  popupMatchSelectWidth={false}
                  options={JobLifecycleTimePeriodOptions.map(value => ({
                    value,
                  }))}
                />,
              ],
              [
                'Show empty statuses',
                <Switch
                  checked={showEmptyStatuses}
                  onChange={setShowEmptyStatuses}
                />,
              ],
            ] as const
          ).map(([label, component]) => (
            <div
              key={label}
              className="flex flex-row items-center justify-between"
            >
              <div className="mr-2 text-sm font-semibold text-bz-gray-900">
                {label}
              </div>
              {component}
            </div>
          ))}
          <div className="text-sm font-semibold text-bz-gray-900">
            Column properties
          </div>
          <div className="flex flex-wrap gap-1">
            {Object.entries(selectedLifecycleColumnPropertySettings).map(
              ([property, isSelected]) => (
                <Button
                  key={property}
                  className="text-xs"
                  type={isSelected ? 'primary' : 'default'}
                  onClick={() =>
                    setColumnPropertiesSettings({
                      ...columnPropertiesSettings,
                      [selectedLifecycleGuid]: {
                        ...selectedLifecycleColumnPropertySettings,
                        [property]: !isSelected,
                      },
                    })
                  }
                >
                  {property}
                </Button>
              ),
            )}
          </div>
          <div className="text-sm font-semibold text-bz-gray-900">
            Job card properties
          </div>
          <div className="flex flex-wrap gap-1">
            {Object.entries(displayPropertiesSettings)
              .filter(
                ([displayPropertyName]) =>
                  !hiddenDisplayPropertySettings.some(
                    hiddenProperty => hiddenProperty === displayPropertyName,
                  ),
              )
              .map(([property, isSelected]) => (
                <Button
                  key={property}
                  className="text-xs"
                  type={isSelected ? 'primary' : 'default'}
                  onClick={() =>
                    setDisplayPropertiesSettings({
                      ...displayPropertiesSettings,
                      [property]: !isSelected,
                    })
                  }
                >
                  {property}
                </Button>
              ))}
          </div>
        </div>
      )
    }, [
      closedJobTimePeriod,
      columnPropertiesSettings,
      displayPropertiesSettings,
      hiddenDisplayPropertySettings,
      selectedLifecycleColumnPropertySettings,
      selectedLifecycleGuid,
      setClosedJobTimePeriod,
      setColumnPropertiesSettings,
      setDisplayPropertiesSettings,
      setShowEmptyStatuses,
      showEmptyStatuses,
      token.borderRadiusLG,
      token.boxShadowSecondary,
      token.colorBgElevated,
    ])

    return (
      <Dropdown dropdownRender={dropdownRender}>
        <Button
          size="large"
          className="flex flex-row items-center rounded text-bz-gray-900"
        >
          <FontAwesomeIcon icon={faTableColumns} className="mr-2" />
          Display <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
        </Button>
      </Dropdown>
    )
  },
)
