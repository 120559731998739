import { withArticlePrefix } from '@breezy/shared'
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Tooltip, TreeSelect } from 'antd'
import { FinanceAccountTreeData } from '../../hooks/Quickbooks/useQboAccountTreeData'
import { m } from '../../utils/react-utils'
import { validatorAny, validatorNotFalsy } from '../../utils/validators'
import { QuickbooksLogoIcon } from './QuickbooksLogoIcon'

type QuickbooksAccountSelectionFormItemProps = {
  formItemName: string
  formItemLabel: string
  financeAccountsTreeData: FinanceAccountTreeData[]
  required?: boolean
  accountTypeFilter?: string
  explanationTooltip?: string
}

const PREFERRED_WIDTH = 500

// The antd TreeSelect types aren't very accessible to me.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filter = (inputValue: string, treeNode: any) => {
  return (treeNode.accountName ?? '')
    .toLowerCase()
    .includes(inputValue.toLowerCase())
}

export const QuickbooksAccountSelectionFormItem =
  m<QuickbooksAccountSelectionFormItemProps>(
    ({
      formItemName,
      formItemLabel,
      financeAccountsTreeData,
      required,
      explanationTooltip,
    }) => {
      return (
        <Form.Item
          name={formItemName}
          label={
            <div className="flex items-center justify-center">
              <QuickbooksLogoIcon />
              {formItemLabel}
              {explanationTooltip && (
                <Tooltip title={explanationTooltip}>
                  <FontAwesomeIcon icon={faCircleInfo} className="ml-2" />
                </Tooltip>
              )}
            </div>
          }
          required={required}
          rules={[
            {
              validator: required
                ? validatorNotFalsy(formItemLabel)
                : validatorAny(),
            },
          ]}
        >
          <TreeSelect
            allowClear
            showSearch
            treeData={financeAccountsTreeData}
            style={{ width: PREFERRED_WIDTH }}
            dropdownStyle={{ width: PREFERRED_WIDTH }}
            placeholder={`Select ${withArticlePrefix(formItemLabel)} `}
            treeDefaultExpandAll
            filterTreeNode={filter}
          />
        </Form.Item>
      )
    },
  )
