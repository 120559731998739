import { Tag, isNullish } from '@breezy/shared'
import { Button, Input } from 'antd'
import { useMemo, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { useMutation } from 'urql'
import DangerConfirmModal from '../../../components/DangerConfirmModal/DangerConfirmModal'
import { useMessage } from '../../../utils/antd-utils'
import { TAG_DELETE_ONE } from '../Tags.gql'
import { EditTagModal } from './EditTagModal'
import { TagsSettingsList } from './TagsSettingsList'

type TagsSettingsProps = {
  tags: Tag[]
  refetchTags?: () => void
}

export const TagsSettings = ({ tags, refetchTags }: TagsSettingsProps) => {
  const message = useMessage()

  const [editTagModelOpen, setEditTagModalOpen] = useState(false)
  const [tagForEdit, setTagForEdit] = useState<Tag | undefined>()
  const [tagForDelete, setTagForDelete] = useState<Tag | undefined>()
  const [tagSearch, setTagSearch] = useState('')
  const filteredTags = useMemo(() => {
    const search = tagSearch.trim().toLowerCase()
    if (search === '') {
      return tags
    }

    return tags.filter(tag => tag.name.toLowerCase().includes(search))
  }, [tagSearch, tags])

  const tagDeleteOne = useMutation(TAG_DELETE_ONE)

  return (
    <>
      <span className="text-sm text-bz-gray-700">
        Tags are a handy tool that allows you to easily categorize your data
      </span>

      <div className="flex w-full flex-row items-center space-x-4">
        <div className="ml-auto flex flex-row items-center space-x-2">
          <Input
            data-tag-search-input
            placeholder="Search tags..."
            prefix={<AiOutlineSearch />}
            allowClear
            className="w-64"
            value={tagSearch}
            onChange={event => setTagSearch(event.currentTarget.value)}
          />
          <Button type="primary" onClick={() => setEditTagModalOpen(true)}>
            + Add New Tag
          </Button>
        </div>
      </div>

      <TagsSettingsList
        tags={filteredTags}
        onTagEditClicked={tag => {
          setTagForEdit(tag)
          setEditTagModalOpen(true)
        }}
        onTagDeleteClicked={async tag => {
          setTagForDelete(tag)
        }}
      />

      <EditTagModal
        open={editTagModelOpen}
        tag={tagForEdit}
        onTagSaved={() => {
          setEditTagModalOpen(false)
          setTagForEdit(undefined)
          refetchTags?.()
        }}
        onClose={() => {
          setTagForEdit(undefined)
          setEditTagModalOpen(false)
        }}
      />

      <DangerConfirmModal
        open={!isNullish(tagForDelete)}
        title="Delete Tag"
        onOk={async () => {
          if (!tagForDelete) {
            return
          }

          try {
            const res = await tagDeleteOne[1]({
              companyGuid: tagForDelete.companyGuid,
              tagGuid: tagForDelete.tagGuid,
            })
            if (res.error) {
              message.error(res.error.message)
              return
            }

            message.success(`Successfully deleted tag "${tagForDelete.name}"`)
            refetchTags?.()
            setTagForDelete(undefined)
          } catch (err) {
            message.error(`Failed to delete tag "${tagForDelete.name}"`)
          }
        }}
        onCancel={() => setTagForDelete(undefined)}
        promptText={`Are you sure you want to delete the tag "${tagForDelete?.name}"?`}
      />
    </>
  )
}
