import {
  ArgumentException,
  BasicBrandedEmailData,
  Email,
  ReminderAssignmentEmailData,
  RemindersWeeklyDigestEmailData,
} from '@breezy/shared'
import { render } from '@react-email/render'
import { BasicBrandedEmail } from './components/BasicBrandedEmail'
import BasicLeftAlignEmail from './components/BasicLeftAlignEmail/BasicLeftAlignEmail'
import { default as ReminderAssignmentEmailComponent } from './components/ReminderAssignmentEmail/ReminderAssignmentEmail'
import { default as RemindersWeeklyDigestEmail } from './components/RemindersWeeklyDigestEmail/RemindersWeeklyDigestEmail'
export const ReminderAssignmentEmailTemplate = (email: Email): string => {
  if (!email.messageMetadata)
    throw new ArgumentException('Missing required metadata for email template ReminderAssignmentEmailTemplate.')
  const props: ReminderAssignmentEmailData = email.messageMetadata as ReminderAssignmentEmailData

  return render(<ReminderAssignmentEmailComponent {...props} />)
}

export const RemindersWeeklyDigestEmailTemplate = (email: Email): string => {
  if (!email.messageMetadata)
    throw new ArgumentException('Missing required metadata for email template RemindersWeeklyDigestEmailTemplate.')
  const props: RemindersWeeklyDigestEmailData = email.messageMetadata as RemindersWeeklyDigestEmailData

  return render(<RemindersWeeklyDigestEmail {...props} />)
}

export const BasicLeftAlignEmailTemplate = (email: Email): string => {
  if (!email.message)
    throw new ArgumentException('Missing required message for email template BasicLeftAlignEmailTemplate.')

  return render(<BasicLeftAlignEmail {...email} />)
}

export const BasicBrandedEmailTemplate = (email: Email): string => {
  if (!email.messageMetadata)
    throw new ArgumentException('Missing required metadata for email template BasicBrandedEmailData.')
  const props: BasicBrandedEmailData = email.messageMetadata as BasicBrandedEmailData

  return render(<BasicBrandedEmail {...email} {...props} />)
}
