import { SimpleContactInfo } from '@breezy/shared'
import { Row } from 'antd'
import { EmailAddressView } from '../../EmailAddressView'
import { PhoneNumberView } from '../../Phones/PhoneNumberView'
import { SmsView } from '../../Phones/SmsView/SmsView'

export type ContactRowCircleButtonsProps = {
  contact: SimpleContactInfo
  showEmail?: boolean
  showPhone?: boolean
  showSms?: boolean
}

const ContactRowCircleButtons = ({
  contact,
  showEmail = true,
  showPhone = true,
  showSms = true,
}: ContactRowCircleButtonsProps) => (
  <Row>
    {showSms && contact.phone?.number && contact.phone.type === 'MOBILE' && (
      <SmsView phoneNumber={contact.phone.number} displayType="circle-button" />
    )}
    {showPhone && contact.phone?.number && (
      <PhoneNumberView
        phoneNumber={contact.phone.number}
        displayType="circle-button"
      />
    )}
    {showEmail && contact.email && (
      <EmailAddressView
        emailAddress={contact.email}
        displayType="circle-button"
      />
    )}
  </Row>
)

export default ContactRowCircleButtons
