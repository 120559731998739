import { PricebookPriceUSDSchema, isNullish } from '@breezy/shared'
import { Form, FormRule } from 'antd'
import { ReactNode, useMemo } from 'react'
import { Styled } from '../../utils/Stylable'
import { ItemPriceInputNumber } from './ItemPriceInputNumber'

type ItemPriceFormItemProps = Readonly<{
  name?: string
  label?: string
  extra?: ReactNode
  style?: React.CSSProperties
  min?: number
  disabled?: boolean
}>

const RULES: FormRule[] = [
  {
    validator: async (_, value) => {
      const result = PricebookPriceUSDSchema.safeParse(value)
      if (!result.success) {
        throw new Error(result.error.issues[0]?.message || result.error.message)
      }
    },
  },
]

export const ItemPriceFormItem = ({
  name = 'priceUSD',
  label = 'Price',
  extra,
  style,
  className,
  min,
  disabled,
}: Styled<ItemPriceFormItemProps>) => {
  const rules = useMemo(() => {
    if (!isNullish(min)) {
      return RULES.concat({
        validator: async (_, value) => {
          if (value < min) {
            throw new Error(`Price must be at least $${min}`)
          }
        },
      })
    } else {
      return RULES
    }
  }, [min])

  return (
    <Form.Item
      style={style}
      className={className}
      name={name}
      label={label}
      extra={extra}
      rules={rules}
    >
      <ItemPriceInputNumber
        className="w-full"
        min={min}
        disabled={isNullish(disabled) ? undefined : disabled}
      />
    </Form.Item>
  )
}
