import { RecommendationType } from '@breezy/shared'
import { useState } from 'react'
import { useInterval } from 'react-use'
import { useQuery } from 'urql'
import {
  useExpectedCompanyGuid,
  useExpectedPrincipal,
} from '../../providers/PrincipalUser'
import { READ_ACTIVE_RECOMMENDATIONS } from './useFetchMyActiveRecommendations.gql'

export const useFetchMyActiveRecommendations = () => {
  const userGuid = useExpectedPrincipal().userGuid
  const companyGuid = useExpectedCompanyGuid()
  const [now, setNow] = useState(new Date().toISOString())

  // NOTE: Reload every hour, to get the latest recommendations
  useInterval(() => {
    setNow(new Date().toISOString())
  }, 3600000) // 3600000 milliseconds = 1 hour

  const [{ data, fetching }, refetch] = useQuery({
    query: READ_ACTIVE_RECOMMENDATIONS,
    variables: {
      where: {
        companyGuid: { _eq: companyGuid },
        recommendationType: { _nin: ['COLLECT_OVERDUE_INVOICE'] },
        deletedAt: { _isNull: true },
        completedAt: { _isNull: true },
        recommendAt: { _lte: now },
        recommendUntil: { _gte: now },
      },
      userGuid,
    },
  })

  // NOTE: Filter out the ones that have been dismissed by the current user
  if (data) {
    const rawData = data
    const notDismissedItems = rawData.recommendations
      .filter(r => r.recommendationDismissals.length === 0)
      .map(r => ({
        ...r,
        recommendationType: r.recommendationType as RecommendationType,
      }))
    return { data: notDismissedItems, fetching, refetch }
  }

  return { data, fetching, refetch }
}
