import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row } from 'antd'
import { Styled } from '../../utils/Stylable'

type CardIconTitleHeaderProps = Styled<{
  readonly icon: IconProp
  readonly title: string
}>

const CardIconTitleHeader = ({
  icon,
  title,
  className,
}: CardIconTitleHeaderProps) => (
  <Row className={className}>
    <FontAwesomeIcon icon={icon} size="lg" className="m1" />
    <h2 className="card-title-lg ml1 semibold_20_28 grey9">{title}</h2>
  </Row>
)

export default CardIconTitleHeader
