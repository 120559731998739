import { Form, Switch } from 'antd'
import React from 'react'

interface PricebookFormItemActiveProps {
  value?: boolean
  onChange?: (checked: boolean) => void
}

export const PricebookFormItemActive = React.memo(
  ({ value, onChange }: PricebookFormItemActiveProps) => {
    return (
      <Form.Item>
        <Form.Item name="isActive" noStyle>
          <Switch
            id="activateItemSwitch"
            checked={!value}
            onChange={onChange}
          />
        </Form.Item>
        <label htmlFor="activateItemSwitch" className="ml-2 font-semibold">
          Deactivate item
        </label>
      </Form.Item>
    )
  },
)
