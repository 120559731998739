import { Tag as BreezyTag } from '@breezy/shared'
import { faEdit, faTrash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { Tag } from '../../../components/Tags'

export type TagsSettingsListItemProps = {
  tag: BreezyTag
  onTagEditClicked: (tag: BreezyTag) => void
  onTagDeleteClicked: (tag: BreezyTag) => void
}
export const TagsSettingsListItem = ({
  tag,
  onTagEditClicked,
  onTagDeleteClicked,
}: TagsSettingsListItemProps) => {
  return (
    <div
      className="flex flex-row items-center rounded-lg border border-solid border-bz-gray-500 px-6 py-4"
      data-tag-list-item={tag.name}
    >
      <Tag tag={tag} />
      <div className="ml-auto flex flex-row space-x-2">
        <Button
          icon={<FontAwesomeIcon icon={faEdit} />}
          onClick={() => onTagEditClicked(tag)}
        >
          Edit
        </Button>
        <Button
          danger
          icon={<FontAwesomeIcon icon={faTrash} />}
          onClick={() => onTagDeleteClicked(tag)}
        >
          Delete
        </Button>
      </div>
    </div>
  )
}

export type TagsSettingsListProps = {
  tags: BreezyTag[]
  onTagEditClicked: (tag: BreezyTag) => void
  onTagDeleteClicked: (tag: BreezyTag) => void
}
export const TagsSettingsList = ({
  tags,
  onTagEditClicked,
  onTagDeleteClicked,
}: TagsSettingsListProps) => {
  return (
    <div className="flex flex-col space-y-3" data-tag-list>
      {tags.map(tag => (
        <TagsSettingsListItem
          key={tag.tagGuid}
          tag={tag}
          onTagEditClicked={onTagEditClicked}
          onTagDeleteClicked={onTagDeleteClicked}
        />
      ))}
    </div>
  )
}
