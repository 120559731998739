import { SimplePriority, toTitleCase } from '@breezy/shared'
import './SimplePriorityTag.less'

export type SimplePriorityTagProps = {
  readonly priority: SimplePriority
}

const SimplePriorityTag = ({ priority }: SimplePriorityTagProps) => {
  const className = `mt-1 simple-priority-view priority-${priority.toLowerCase()} text-center`
  return <div className={className}>{toTitleCase(priority.toString())}</div>
}

export default SimplePriorityTag
