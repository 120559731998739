import { BzDateFns, TimeWindowDto, TimeZoneId } from '@breezy/shared'
import { TimesheetReportItem } from './TimesheetsSummaryTable'

export const generateDayHeadersForSummary = (
  timeWindow: TimeWindowDto,
  timeZoneId: TimeZoneId,
): TimesheetReportItem['daysHoursSummaries'] => {
  const dayHeaders: string[] = []
  let currTime = BzDateFns.parseISO(timeWindow.start, timeZoneId)
  const endTime = BzDateFns.addDays(
    BzDateFns.parseISO(timeWindow.end, timeZoneId),
    1,
  )

  do {
    dayHeaders.push(BzDateFns.format(currTime, 'yyyy-MM-dd'))
    currTime = BzDateFns.addDays(currTime, 1)
  } while (BzDateFns.isBefore(currTime, endTime))

  return dayHeaders.reduce<TimesheetReportItem['daysHoursSummaries']>(
    (acc, curr) => {
      acc[curr] = -1
      return acc
    },
    {},
  )
}
