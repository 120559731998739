import { faTimer } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CircleButton } from '../../elements/CircleButton/CircleButton'
import UserTimeClockPopover from './UserTimeClockPopover'
import { useTimeClock } from './useTimeClock'

export const UserTimeClockHoverButton = () => {
  const { isClockedIn, refetch } = useTimeClock({ realtime: false })
  const className = isClockedIn
    ? 'bg-[#D9F7BE] border-[#52C41A] active:bg-[#E4F9D1] active:border-[#7ee74a] hover:bg-[#E4F9D1] hover:border-[#7ee74a]'
    : ''

  const iconClassName = isClockedIn
    ? 'text-[#52C41A] text-[18px]'
    : 'grey8 text-[18px]'

  return (
    <CircleButton className={className}>
      <UserTimeClockPopover onOpenChange={refetch}>
        <FontAwesomeIcon icon={faTimer} className={iconClassName} />
      </UserTimeClockPopover>
    </CircleButton>
  )
}
