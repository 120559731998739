import ThinDivider from '../../../elements/ThinDivider'

export const TeamPerformanceDivider: React.FC = () => {
  return (
    <ThinDivider
      widthPx={1}
      styleOverrides={{ marginTop: '4px', marginBottom: '4px' }}
    />
  )
}
