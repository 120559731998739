import { Col, Row } from 'antd'
import { memo, useMemo } from 'react'
import { Tile } from '../Tile'
import { TeamPerformanceDivider } from './TeamPerformanceDivider'
import { TeamPerformanceJob } from './team-performance-queries.gql'

type TotalNumberOfJobsTileProps = {
  jobs: TeamPerformanceJob[]
  loading: boolean
}

export const TotalNumberOfJobsTile = memo<TotalNumberOfJobsTileProps>(
  ({ jobs, loading }) => {
    const numOpportunities = useMemo(
      () => jobs.filter(job => job.isOpportunity).length,
      [jobs],
    )

    const numHotLeads = useMemo(
      () => jobs.filter(job => job.isHotLead).length,
      [jobs],
    )

    return (
      <Tile title="Total Number of Jobs" loading={loading}>
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">All Jobs</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {jobs.length}
          </Col>
        </Row>
        <TeamPerformanceDivider />
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">Opportunities</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {numOpportunities}
          </Col>
        </Row>
        <TeamPerformanceDivider />
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">Hot Leads</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {numHotLeads}
          </Col>
        </Row>
      </Tile>
    )
  },
)
