import { PermissionV2, bzExpect } from '@breezy/shared'
import { useParams } from 'react-router-dom'
import { LayoutPage } from '../../components/Layout/LayoutPage'
import { LayoutSection } from '../../components/Layout/LayoutSection'
import { Authorized } from '../../components/Permissions/Authorized/Authorized'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'
import { PayoutDetailsItemsTable } from './PayoutDetailsItemsTable'
import { PayoutSummaryCard } from './PayoutSummaryCard'

export const PayoutDetailsPage = m(() => (
  <Authorized
    to={PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYOUTS_VIEW}
    notAuthorizedView={
      <>
        You do not have permission to view the resource that is being requested
      </>
    }
  >
    <PayoutDetailsLoader />
  </Authorized>
))

const PayoutDetailsLoader = m(() => {
  const companyGuid = useExpectedCompanyGuid()
  const payoutGuid = bzExpect(
    useParams().payoutGuid,
    'payoutGuid',
    'Payout Guid is required',
  )

  const query = trpc.payouts['payouts:get'].useQuery({
    companyGuid,
    entityGuid: payoutGuid,
  })

  return (
    <TrpcQueryLoader
      query={query}
      render={data => (
        <LayoutPage padded>
          <LayoutSection size={{ xs: 12, md: 6, xl: 12 * 0.3 }} card>
            <PayoutSummaryCard payout={data} />
          </LayoutSection>
          <LayoutSection size={{ xs: 12, md: 6, xl: 12 * 0.7 }} card>
            <PayoutDetailsItemsTable payout={data} />
          </LayoutSection>
        </LayoutPage>
      )}
    />
  )
})
