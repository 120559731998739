import { BzDateFns } from '@breezy/shared'
import { Modal } from 'antd'
import React, { useState } from 'react'
import { CardBody } from '../../elements/Card/Card'
import { Link } from '../../elements/Link/Link'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { Tile } from './Tile'

const CELL_CLASS = 'h-[68px]'

export const StaleAccountsTile = React.memo(() => {
  const tzId = useExpectedCompanyTimeZoneId()

  const staleAccountsQuery = trpc.reporting['stale-accounts:get'].useQuery()

  const [modalOpen, setModalOpen] = useState(false)
  return (
    <>
      <Tile
        title="Stale Accounts"
        loading={staleAccountsQuery.isLoading}
        footerText="Customers who haven't had a job in the last 12 months."
        onClick={() => {
          if (staleAccountsQuery.data?.length) {
            setModalOpen(true)
          }
        }}
      >
        {staleAccountsQuery.data?.length ?? 0}
      </Tile>
      {modalOpen && (
        <Modal
          open
          closeIcon={false}
          onCancel={() => setModalOpen(false)}
          footer={null}
          width="50%"
        >
          <CardBody
            hideTitleDivider
            title="Stale Accounts"
            className="mx-[-1.5rem] mb-[-1rem]"
          >
            <div className="max-h-[75vh] overflow-auto px-6">
              <table className="w-full">
                <thead>
                  <tr className="h-5">
                    {['Account', 'Last Job'].map(header => (
                      <th
                        key={header}
                        className="pb-4 text-left text-sm font-semibold uppercase text-bz-gray-800"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {staleAccountsQuery.data?.map(staleAccount => {
                    const lastJobDate = BzDateFns.formatFromISO(
                      staleAccount.jobCreatedAt,
                      'MMMM d, yyyy',
                      tzId,
                    )

                    return (
                      <tr
                        key={staleAccount.accountGuid}
                        className="border-0 border-t border-solid border-y-bz-gray-400 text-sm last:border-b"
                      >
                        <td className={CELL_CLASS}>
                          <Link to={`/accounts/${staleAccount.accountGuid}`}>
                            {staleAccount.displayName}
                          </Link>
                        </td>
                        <td className={CELL_CLASS}>{lastJobDate}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Modal>
      )}
    </>
  )
})
