import { PageHeader } from '@ant-design/pro-components'
import {
  PermissionV2,
  RoleId,
  User,
  upperPascalCaseToHumanReadable,
} from '@breezy/shared'
import { faAngleDown, faUsers } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Checkbox, Divider, Dropdown, Input, Menu, Table } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Authorized } from 'src/components/Permissions/Authorized/Authorized'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { trpc } from '../../hooks/trpc'

import { OfficeRoutes } from '@breezy/shared'
import { AiOutlineSearch } from 'react-icons/ai'
import { useLocalStorage } from 'react-use'
import useAppNavigation from '../../hooks/useAppNav'
import { userToAvatarData } from '../../utils/users'
import PersonAvatar from '../PersonAvatar/PersonAvatar'

type TableUser = User & {
  fullName: string
}

type RoleSelectMenuProps = {
  roles?: RoleId[]
  onRoleChange: (roles: RoleId[]) => void
}

const RoleSelectMenu = React.memo<RoleSelectMenuProps>(
  ({ roles = [], onRoleChange }) => {
    const options = Object.values(RoleId).map(name => ({
      label: upperPascalCaseToHumanReadable(name),
      value: name,
    }))

    const onChange = useCallback(
      (checkedValue: Array<string | number | boolean>) => {
        onRoleChange(checkedValue as RoleId[])
      },
      [onRoleChange],
    )

    const onSelectAll = useCallback(() => {
      onRoleChange(Object.values(RoleId))
    }, [onRoleChange])

    return (
      <Menu
        className="w-96 px-3 pb-1 pt-3"
        onSelect={e => {
          e.domEvent.preventDefault()
        }}
      >
        <Checkbox.Group
          className="flex flex-col gap-4"
          options={options}
          value={roles}
          onChange={onChange}
        />
        <Divider className="my-1" />
        <Button className="pl-2" type="link" onClick={onSelectAll}>
          Select All
        </Button>
      </Menu>
    )
  },
)

export const SettingsTeamPage = React.memo(() => {
  const { navigateToCreateTeamMemberPage, navigateToEditTeamMemberPage } =
    useAppNavigation()
  const usersQuery = trpc.user['users:get'].useQuery()

  const [searchTerm, setSearchTerm] = useState('')
  const [selectedRoles, setSelectedRoles] = useLocalStorage<RoleId[]>(
    'team-roles',
    Object.values(RoleId),
  )

  const onRoleChange = useCallback(
    (roles: RoleId[]) => {
      setSelectedRoles(roles)
    },
    [setSelectedRoles],
  )

  const users = useMemo<TableUser[]>(
    () =>
      (usersQuery.data?.users || [])
        .filter(user => {
          if (user.deactivatedAt) {
            return false
          }
          return true
        })
        .map(user => ({
          ...user,
          fullName: `${user.firstName} ${user.lastName}`,
        })),
    [usersQuery.data?.users],
  )

  const filteredUsers = useMemo(() => {
    const lcSearchTerm = searchTerm.toLowerCase()
    const roles = selectedRoles || Object.values(RoleId)

    return lcSearchTerm?.length > 0
      ? users.filter(user => user.fullName.toLowerCase().includes(lcSearchTerm))
      : users.filter(user => user.roles.some(role => roles.includes(role.role)))
  }, [users, searchTerm, selectedRoles])

  const tableColumns = useMemo(
    () => [
      {
        title: 'Name',
        key: 'fullName',
        dataIndex: 'fullName',
        render: (_: unknown, user: TableUser) => (
          <div className="flex flex-row items-center">
            <PersonAvatar size={36} {...userToAvatarData(user)} />
            <div className="ml-3">
              <Link
                className="font-semibold"
                to={OfficeRoutes.EDIT.build({
                  params: { userGuid: user.userGuid },
                })}
              >
                {user.fullName}
              </Link>
              <div>{user.emailAddress}</div>
            </div>
          </div>
        ),
      },
      {
        title: 'Roles',
        key: 'roles',
        render: (_: unknown, user: TableUser) => (
          <div>
            {user.roles
              .map(r => upperPascalCaseToHumanReadable(r.role))
              .join(' | ')}
          </div>
        ),
      },
    ],
    [],
  )

  const roleCount = useMemo(() => {
    if (searchTerm) return ''
    if (
      !selectedRoles?.length ||
      selectedRoles.length === Object.values(RoleId).length
    ) {
      return '(All)'
    }

    return `(${selectedRoles.length})`
  }, [selectedRoles, searchTerm])

  return (
    <>
      <PageHeader title={<PageTitle title="Team" icon={faUsers} />} />
      <div className="flex items-center justify-between gap-2 overflow-hidden"></div>
      <div className="card flex min-h-0 w-full flex-1 flex-col overflow-auto py-8">
        <div className="mb-4 flex flex-row text-bz-gray-700">
          Add new team members, customize permissions, and remove people from
          your account. Learn more
        </div>
        <div className="mb-3 flex flex-row justify-between">
          <div className="flex flex-row gap-3">
            <Input
              placeholder="Search team members..."
              prefix={<AiOutlineSearch />}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              allowClear
              className="w-96"
            />
            <Dropdown
              overlay={
                <RoleSelectMenu
                  roles={selectedRoles}
                  onRoleChange={onRoleChange}
                />
              }
              disabled={searchTerm?.length > 0}
              trigger={['click']}
              placement="bottomLeft"
            >
              <Button className="flex flex-row items-center pr-2">
                {`Roles ${roleCount}`}
                <FontAwesomeIcon className="ml-2" icon={faAngleDown} />
              </Button>
            </Dropdown>
          </div>
          <Authorized
            to={PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TEAM}
            allowsNonCompanyUser
          >
            <div className="flex">
              <Button
                type="primary"
                onClick={() => navigateToCreateTeamMemberPage()}
              >
                + Add Team Member
              </Button>
            </div>
          </Authorized>
        </div>
        <Table
          dataSource={filteredUsers}
          columns={tableColumns}
          rowKey="userGuid"
          rowClassName="cursor-pointer"
          pagination={{ pageSize: 25 }}
          onRow={user => ({
            onClick: () => navigateToEditTeamMemberPage(user.userGuid),
          })}
        />
      </div>
    </>
  )
})
