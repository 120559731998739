import { MaintenanceOpportunity } from '@breezy/shared'
import { useMemo } from 'react'
import { useQuery } from 'urql'
import { TAGS_MINIMAL_FOR_COMPANY_QUERY } from '../../../gql/queries/Tags.gql'
import { useFilter } from '../../../hooks/useFilter'
import { useExpectedCompanyGuid } from '../../../providers/PrincipalUser'

export const useMaintenanceOpportunityFilters = (
  maintOpportunities: MaintenanceOpportunity[],
) => {
  const companyGuid = useExpectedCompanyGuid()
  const [{ data: tagsData, fetching: loading }] = useQuery({
    query: TAGS_MINIMAL_FOR_COMPANY_QUERY,
    variables: { companyGuid },
  })

  const accountTags = useMemo(() => tagsData?.tags || [], [tagsData])

  return {
    ...useFilter({
      data: maintOpportunities,
      config: [
        {
          type: 'options',
          key: 'maintenancePlan',
          label: 'Maintenance Plan Member Status',
          options: [
            { option: 'isMember', filter: item => !!item.maintenancePlan },
            { option: 'isNotMember', filter: item => !item.maintenancePlan },
          ],
        },
        {
          type: 'options',
          key: 'tags',
          label: 'Tags',
          options: accountTags.map(tag => ({
            option: tag.tagGuid,
            filter: (item: MaintenanceOpportunity) =>
              item.accountTags.some(curr => curr.tagGuid === tag.tagGuid),
          })),
        },
      ],
    }),
    accountTags,
    loading,
  }
}
