import { Map, MapProps } from '@vis.gl/react-google-maps'
import React from 'react'
import { BREEZY_LAT_LNG } from './MapView'

import './ReactMapView.less'
const ReactMapView = React.memo<React.PropsWithChildren<MapProps>>(
  ({ children, ...rest }) => {
    return (
      <Map
        key={rest.mapId}
        {...rest}
        defaultCenter={BREEZY_LAT_LNG}
        defaultZoom={5}
      >
        {children}
      </Map>
    )
  },
)

export default ReactMapView
