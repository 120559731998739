import { R, toPlural } from '@breezy/shared'
import { faArrowRight, faChartSimple } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'antd'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Card, CardBody } from '../../elements/Card/Card'
import { BarChart } from '../../elements/Charts/BarChart'
import { Link } from '../../elements/Link/Link'
import { trpc } from '../../hooks/trpc'
import { DUMMY_BACKGROUND_AVG_INVOICE_BY_JOB_TYPE_DATA } from './dummyBackgroundData'
import { NoDataOverlay } from './NoDataOverlay'
import { NUM_INVOICES_QUERY } from './ReportingDashboard.gql'
import {
  StandardReportingDateRangePicker,
  useStandardReportingDateRangePickerState,
} from './ReportingDateRangePicker/StandardReportingDateRangePicker'

const MAX_ROWS = 5

type Datum = {
  label: string
  value: number
  totalJobs: number
}

const formatValue = (value: number, data: Datum) => (
  <div>
    <span>
      $
      {value.toLocaleString('en-us', {
        maximumFractionDigits: 0,
      })}
    </span>
    <span className="px-1 text-bz-gray-600">•</span>
    <span className="text-bz-gray-800">
      {data.totalJobs} {toPlural(data.totalJobs, 'job')}
    </span>
  </div>
)

const formatValueTooltip = (value: number, data: Datum) => (
  <div>
    <div>
      $
      {value.toLocaleString('en-us', {
        maximumFractionDigits: 0,
      })}
    </div>
    <div>
      {data.totalJobs} {toPlural(data.totalJobs, 'job')}
    </div>
  </div>
)

type AvgInvoiceByJobTypeWidgetProps = {
  // Not optional. I'm expecting at least a fixed height class
  className: string
}
export const AvgInvoiceByJobTypeWidget =
  React.memo<AvgInvoiceByJobTypeWidgetProps>(({ className }) => {
    const [dateRange, setDateRange] = useStandardReportingDateRangePickerState()

    const avgInvoiceByJobTypeQuery = trpc.reporting[
      'avg-invoice-by-job-type:get'
    ].useQuery({ dateRange })

    const [{ data: numInvoicesData, fetching: numInvoicesQueryLoading }] =
      useQuery({
        query: NUM_INVOICES_QUERY,
        variables: {},
      })

    const hasData = useMemo(
      () => (numInvoicesData?.invoicesAggregate.aggregate?.count ?? 0) > 0,
      [numInvoicesData?.invoicesAggregate.aggregate?.count],
    )

    const resolvedData = useMemo<Datum[]>(() => {
      const data =
        !avgInvoiceByJobTypeQuery.data || !hasData
          ? DUMMY_BACKGROUND_AVG_INVOICE_BY_JOB_TYPE_DATA
          : avgInvoiceByJobTypeQuery.data

      return R.keys(data).map(jobTypeName => {
        const jobTypeData = data[jobTypeName]
        return {
          label: jobTypeName,
          value: jobTypeData.avgInvoice,
          totalJobs: jobTypeData.totalJobs,
        }
      })
    }, [avgInvoiceByJobTypeQuery.data, hasData])

    const truncatedData = useMemo(
      () => resolvedData.slice(0, MAX_ROWS),
      [resolvedData],
    )

    const [viewAllOpen, setViewAllOpen] = useState(false)

    const dateRangePicker = useMemo(
      () => (
        <StandardReportingDateRangePicker
          range={dateRange}
          setRange={setDateRange}
        />
      ),
      [dateRange, setDateRange],
    )

    const hasNoDataOverlay = !hasData

    return (
      <Card
        title="Avg. Invoice by Job Type"
        className={className}
        titleAction={!hasNoDataOverlay && dateRangePicker}
      >
        {avgInvoiceByJobTypeQuery.isLoading || numInvoicesQueryLoading ? (
          <LoadingSpinner />
        ) : !resolvedData.length ? (
          <div className="flex flex-1 items-center justify-center text-base font-semibold">
            <div>No invoices in your chosen time period.</div>
          </div>
        ) : (
          //  Move the margin into the padding so a scrollbar would be inside the padding. Add padding back so it's like
          //  we didn't do any of this.
          <>
            <div className="flex h-full flex-col">
              <div className="flex-1">
                <BarChart
                  disableTooltip
                  data={truncatedData}
                  formatValue={formatValue}
                  formatValueTooltip={formatValueTooltip}
                />
              </div>
              {resolvedData.length > truncatedData.length && (
                <Link onClick={() => setViewAllOpen(true)}>
                  View {resolvedData.length - truncatedData.length} more
                  <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
                </Link>
              )}
            </div>
            {hasNoDataOverlay && (
              <NoDataOverlay
                className="inset-[-16px]"
                icon={faChartSimple}
                title="No invoices yet"
                link={{ to: '/jobs', text: 'View jobs' }}
              >
                <div className="text-center">
                  Create an invoice to view the average value by job class.
                </div>
              </NoDataOverlay>
            )}
          </>
        )}
        <Modal
          open={viewAllOpen}
          closeIcon={false}
          onCancel={() => setViewAllOpen(false)}
          footer={null}
          width="50%"
        >
          <CardBody
            title="Lead Sources"
            titleAction={dateRangePicker}
            className="mx-[-1.5rem] my-[-1rem]"
          >
            {avgInvoiceByJobTypeQuery.isLoading ? (
              <div className="py-10">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="max-h-[75vh] overflow-auto px-6 py-4">
                <BarChart
                  disableTooltip
                  data={resolvedData}
                  formatValue={formatValue}
                  formatValueTooltip={formatValueTooltip}
                />
              </div>
            )}
          </CardBody>
        </Modal>
      </Card>
    )
  })
