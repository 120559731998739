import { faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import { createContext, useContext, useMemo } from 'react'
import { useCollapse } from 'react-collapsed'
import { useAppMenu } from './AppMenu'
import { AppMenuItem } from './AppMenuItem'

import React, { useState } from 'react'

type KeyObj = Record<string, unknown>

type RegisterItem = (props: { isActive?: boolean; keyObj: KeyObj }) => void
type UnregisterItem = (props: { keyObj: KeyObj }) => void

type AppMenuGroupContextProps = {
  registerItem: RegisterItem
  unregisterItem: UnregisterItem
}

const AppMenuGroupContext = createContext<AppMenuGroupContextProps>({
  registerItem: () => {},
  unregisterItem: () => {},
})

export const useAppMenuGroup = () => useContext(AppMenuGroupContext)

export type AppMenuGroupProps = Pick<
  React.ComponentProps<typeof AppMenuItem>,
  'icon' | 'children'
> & {
  title: string
}

export const AppMenuGroup = ({ icon, title, children }: AppMenuGroupProps) => {
  const { isAppMenuOpen } = useAppMenu()
  const [isGroupExpanded, setIsGroupExpanded] = useState(false)

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: isAppMenuOpen && isGroupExpanded,
  })

  const [activeChildren, setActiveChildren] = useState<Set<KeyObj>>(
    () => new Set(),
  )

  const AppMenuGroupContextValue = useMemo(
    (): AppMenuGroupContextProps => ({
      registerItem: ({ keyObj, isActive }) => {
        setActiveChildren(prevActiveChildren => {
          if (isActive && !prevActiveChildren.has(keyObj)) {
            prevActiveChildren.add(keyObj)
            return new Set(prevActiveChildren)
          }
          if (!isActive && prevActiveChildren.has(keyObj)) {
            prevActiveChildren.delete(keyObj)
            return new Set(prevActiveChildren)
          }
          return prevActiveChildren
        })
      },
      unregisterItem: ({ keyObj }) => {
        setActiveChildren(prevActiveChildren => {
          if (prevActiveChildren.has(keyObj)) {
            prevActiveChildren.delete(keyObj)
            return new Set(prevActiveChildren)
          }
          return prevActiveChildren
        })
      },
    }),
    [],
  )

  const isActive = activeChildren.size > 0

  return (
    <div
      className={cn('appMenu__group', {
        'is-group-expanded': isGroupExpanded,
        'is-group-collapsed': !isGroupExpanded,
      })}
    >
      <div
        {...getToggleProps()}
        className="appMenu__group-toggle"
        onClick={() => setIsGroupExpanded(prevIsExpanded => !prevIsExpanded)}
      >
        <AppMenuItem icon={icon} isActive={isActive}>
          <span>{title}</span>
          <div className="appMenu__group-status">
            <FontAwesomeIcon icon={faChevronUp} size="sm" />
          </div>
        </AppMenuItem>
      </div>
      <div className="appMenu__group-items" {...getCollapseProps()}>
        <AppMenuGroupContext.Provider value={AppMenuGroupContextValue}>
          {children}
        </AppMenuGroupContext.Provider>
      </div>
    </div>
  )
}
