import {
  JobLifecycle,
  JobLifecycleStatus,
  JobLifecycleStatusWithoutLifecycleGuid,
  JobLifecycleType,
  R,
  generateDefaultJobLifecycleStatuses,
  generateDefaultSalesLifecycleStatuses,
  isNullish,
  nextGuid,
} from '@breezy/shared'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useCallback, useMemo, useState } from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { trpc } from '../../hooks/trpc'
import { useMessage } from '../../utils/antd-utils'
import { useQueryParamState } from '../../utils/react-utils'
import { EditJobLifecycleStatusSidebar } from './EditJobLifecycleStatusSidebar'
import { DropTarget } from './components/DropTarget'
import { JobLifecycleDeleteLifecycleStatusDrawer } from './components/JobLifecycleDeleteLifecycleStatusDrawer'
import { JobLifecycleStatusBox } from './components/JobLifecycleStatusBox'
import { UnsavedChangesModal } from './components/UnsavedChangesModal'
import {
  AddNewStatus,
  JobLifecycleManagementContext,
  areSequencesEqual,
  reconcileFlags,
  updateStatusLabelsInMap,
} from './lifecycleUtils'
import {
  JobLifecycleStatusGuid,
  OnDropHandler,
  OnEditClickHandler,
} from './types'

const VALIDATE_MESSAGES = {
  // This is the format antd wants
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required.',
}

type StatusMap = Record<
  JobLifecycleStatusGuid,
  JobLifecycleStatusWithoutLifecycleGuid
>

type JobLifecycleSettingsPageProps = {
  selectedLifecycle?: JobLifecycle
  resetSelectedLifecycle: () => void
}

export const JobLifecycleSettingsPage =
  React.memo<JobLifecycleSettingsPageProps>(
    ({ selectedLifecycle, resetSelectedLifecycle }) => {
      const message = useMessage()
      const [statusChangeMap, setStatusChangeMap] = useState<StatusMap>({})
      const [newStatusMap, setNewStatusMap] = useState<StatusMap>({})
      const [statusDeleteMap, setStatusDeleteMap] = useState<
        Record<string, { transitionStatus?: string }>
      >({})

      const [selectedLifecycleGuid] = useQueryParamState('id', '')

      const jobLifecycleType: JobLifecycleType = useMemo(() => {
        if (selectedLifecycleGuid === 'new-jobs-pipeline') {
          return 'JOB'
        }

        if (selectedLifecycleGuid === 'new-sales-pipeline') {
          return 'SALES'
        }

        return selectedLifecycle?.type ?? 'JOB'
      }, [selectedLifecycle?.type, selectedLifecycleGuid])

      const statuses = useMemo(() => {
        if (selectedLifecycleGuid === 'new-jobs-pipeline') {
          return generateDefaultJobLifecycleStatuses()
        }

        if (selectedLifecycleGuid === 'new-sales-pipeline') {
          return generateDefaultSalesLifecycleStatuses()
        }

        return selectedLifecycle
          ? selectedLifecycle.statuses
          : generateDefaultJobLifecycleStatuses()
      }, [selectedLifecycle, selectedLifecycleGuid])

      const originalStatusMap = useMemo(
        () =>
          statuses.reduce(
            (map, status) => ({
              ...map,
              [status.jobLifecycleStatusGuid]: status,
            }),
            {} as StatusMap,
          ),
        [statuses],
      )

      const originalSequence = useMemo(
        () => statuses.map(s => s.jobLifecycleStatusGuid),
        [statuses],
      )

      const [statusSequence, setStatusSequence] = useState<
        JobLifecycleStatusGuid[]
      >(statuses.map(s => s.jobLifecycleStatusGuid))

      // Coalesce all our various change states
      const getStatusByGuid = useCallback(
        (guid: string) => {
          if (statusDeleteMap[guid]) {
            return undefined
          }
          return (
            newStatusMap[guid] ??
            statusChangeMap[guid] ??
            originalStatusMap[guid]
          )
        },
        [newStatusMap, statusChangeMap, statusDeleteMap, originalStatusMap],
      )

      // This is adding from the sidebar. Add it to `newStatusMap` and in the correct sequence bucket
      const addNewStatus = useCallback<AddNewStatus>(status => {
        const newGuid = nextGuid()
        setNewStatusMap(map => ({
          ...map,
          [newGuid]: {
            jobLifecycleStatusGuid: newGuid,
            ...status,
          },
        }))
        setStatusSequence(prev => [...prev, newGuid])
      }, [])

      const changeStatus = useCallback(
        (status: JobLifecycleStatusWithoutLifecycleGuid) => {
          // Put the change in the statusChangeMap. If it's a new status, we also need to update the newStatusMap
          if (status.jobLifecycleStatusGuid in newStatusMap) {
            setNewStatusMap(map => ({
              ...updateStatusLabelsInMap(status, map),
              [status.jobLifecycleStatusGuid]: status,
            }))
            setStatusChangeMap(reconcileFlags(status, originalStatusMap))
          } else {
            setStatusChangeMap(reconcileFlags(status, originalStatusMap))
          }
        },
        [newStatusMap, originalStatusMap],
      )

      const deleteStatus = useCallback(
        (statusGuid: string, transitionStatus?: string) => {
          // If we're deleting something new, we just need to remove it from the new map
          if (statusGuid in newStatusMap) {
            setNewStatusMap(R.dissoc(statusGuid))
            return
          }
          // If there are pending changes, delete those too
          if (statusGuid in statusChangeMap) {
            setNewStatusMap(R.dissoc(statusGuid))
          }
          // Add to the delete map
          setStatusDeleteMap(map => ({
            ...map,
            [statusGuid]: { transitionStatus },
          }))

          // Remove it from the status sequence.
          setStatusSequence(prev => {
            return prev.filter(guid => guid !== statusGuid)
          })
        },
        [newStatusMap, statusChangeMap],
      )

      // Note: we can drop on another status OR a stage header. `dropTargetId` will either be the jobLifecycleStatusGuid
      // or a JobLifecycleStage
      const onDrop = useCallback<OnDropHandler>(
        ({ droppedJobLifecycleStatusGuid, dropTargetId }) => {
          setStatusSequence(prev => {
            const currentIndex = prev.indexOf(droppedJobLifecycleStatusGuid)
            if (currentIndex === -1) return prev // If not found, return the original array

            const newArray = [...prev]
            newArray.splice(currentIndex, 1) // Remove the item from its current position

            if (dropTargetId === 'first') {
              newArray.unshift(droppedJobLifecycleStatusGuid) // Add to the start if 'first'
            } else {
              const targetIndex = newArray.indexOf(dropTargetId)
              if (targetIndex === -1) return newArray // If target not found, return the array without insertion
              newArray.splice(targetIndex + 1, 0, droppedJobLifecycleStatusGuid) // Insert after the target ID
            }

            return newArray
          })
        },
        [],
      )

      const [editingStatus, setEditingStatus] =
        useState<Partial<JobLifecycleStatus>>()

      const onNewStatusClick = useCallback(() => {
        setEditingStatus({})
      }, [])

      const onNewJobLifecycleSidebarClose = useCallback(
        () => setEditingStatus(undefined),
        [],
      )

      const onEditClick = useCallback<OnEditClickHandler>(
        guid => {
          const status = getStatusByGuid(guid)
          if (status) {
            setEditingStatus(status)
          }
        },
        [getStatusByGuid],
      )

      const [form] = Form.useForm<JobLifecycleStatusWithoutLifecycleGuid>()
      const formLifecycleName = Form.useWatch('name', form)
      const formLifecycleDescription = Form.useWatch('description', form)

      const jobLifecycleUpsertMutation = trpc.jobLifecycles[
        'job-lifecycles:upsert'
      ].useMutation({
        onSuccess: () => {
          resetSelectedLifecycle()
        },
      })

      const isLifecycleDirty: boolean = useMemo(() => {
        if (isNullish(selectedLifecycle)) {
          return true
        }

        if (formLifecycleName !== selectedLifecycle.name) {
          return true
        }

        if (formLifecycleDescription !== selectedLifecycle.description) {
          return true
        }

        if (Object.entries(statusChangeMap).length > 0) {
          return true
        }

        if (Object.entries(statusDeleteMap).length > 0) {
          return true
        }

        if (Object.entries(newStatusMap).length > 0) {
          return true
        }

        if (!areSequencesEqual(originalSequence, statusSequence)) {
          return true
        }

        return false
      }, [
        formLifecycleDescription,
        formLifecycleName,
        newStatusMap,
        originalSequence,
        selectedLifecycle,
        statusChangeMap,
        statusDeleteMap,
        statusSequence,
      ])

      const onSave = useCallback(async () => {
        const statuses: JobLifecycleStatusWithoutLifecycleGuid[] = []
        for (const jobLifeCycleGuid of statusSequence) {
          const status = getStatusByGuid(jobLifeCycleGuid)

          if (status) {
            statuses.push(status)
          }
        }

        try {
          await form.validateFields()
        } catch (e) {
          return
        }

        const hasFlags = {
          isDefault: false,
          isLeadWon: false,
          isWorkComplete: false,
        }
        for (const status of statuses) {
          if (status.isDefault) {
            hasFlags.isDefault = true
          }
          if (status.isLeadWon) {
            hasFlags.isLeadWon = true
          }
          if (status.isWorkComplete) {
            hasFlags.isWorkComplete = true
          }
        }

        if (!hasFlags.isDefault) {
          message.error('You must have a "Default" status')
          return
        }

        if (jobLifecycleType === 'SALES' && !hasFlags.isLeadWon) {
          message.error('You must have a "Lead - Won" status')
          return
        }

        if (jobLifecycleType === 'JOB' && !hasFlags.isWorkComplete) {
          message.error('You must have a "Work Complete" status')
          return
        }

        jobLifecycleUpsertMutation.mutate({
          jobLifecycleGuid: selectedLifecycle?.jobLifecycleGuid ?? nextGuid(),
          name: form.getFieldValue('name'),
          description: form.getFieldValue('description'),
          statuses,
          type: jobLifecycleType,
          deletedStatuses: Object.entries(statusDeleteMap).map(
            ([jobLifecycleStatusGuid, data]) => ({
              jobLifecycleStatusGuidToDelete: jobLifecycleStatusGuid,
              jobLifecycleStatusGuidToTransitionTo: data.transitionStatus,
            }),
          ),
        })
      }, [
        jobLifecycleUpsertMutation,
        selectedLifecycle?.jobLifecycleGuid,
        form,
        jobLifecycleType,
        statusDeleteMap,
        statusSequence,
        getStatusByGuid,
        message,
      ])

      const [
        isBackToLifecycleListModalVisible,
        setIsBackToLifecycleListModalVisible,
      ] = useState(false)

      const onBackToLifecyclesListPressed = useCallback(() => {
        if (!isLifecycleDirty) {
          resetSelectedLifecycle()
        } else {
          setIsBackToLifecycleListModalVisible(true)
        }
      }, [isLifecycleDirty, resetSelectedLifecycle])

      const [deleteDrawerLifecycleStatus, setDeleteDrawerLifecycleStatus] =
        useState<{
          lifecycleStatusGuid: string
          name: string
          numJobs: number
          isDefaultLifecycleStatus: boolean
        } | null>(null)

      return (
        <JobLifecycleManagementContext.Provider
          value={{ addNewStatus, changeStatus, deleteStatus }}
        >
          <div className="flex min-h-0 flex-col">
            <div className="flex flex-row justify-between">
              <Button
                type="link"
                onClick={onBackToLifecyclesListPressed}
                className="mb-2 p-0"
              >
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                Back to all lifecycles
              </Button>
            </div>
            <div className="flex flex-row items-center justify-end">
              <div className="space-x-2">
                <Button onClick={onBackToLifecyclesListPressed}>Cancel</Button>
                <Button
                  type="primary"
                  onClick={onSave}
                  disabled={!isLifecycleDirty}
                >
                  Save
                </Button>
              </div>
            </div>

            <Form
              layout="vertical"
              className="mt-4"
              form={form}
              requiredMark="optional"
              validateMessages={VALIDATE_MESSAGES}
              initialValues={selectedLifecycle}
            >
              <Form.Item
                name="name"
                label="Pipeline Name"
                required
                rules={[{ required: true }]}
              >
                <Input placeholder="Pipeline name" />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                required
                rules={[{ required: true }]}
              >
                <TextArea
                  rows={4}
                  placeholder="Describe the purpose of this lifecycle"
                />
              </Form.Item>
            </Form>
            <Divider className="mb-4 mt-2 border-bz-gray-500" />
            <div className="items-top mb-4 flex flex-row">
              <div className="flex flex-1 flex-col">
                <div className="text-lg font-semibold">Statuses</div>
                <div className="gray7 mt-1 text-sm">
                  Customize your job pipelines. Tailor the design of each
                  pipeline to model how your business operates. Add and sequence
                  the statuses below to capture the lifecycle for a stream of
                  jobs.
                </div>
              </div>
              <Button type="primary" onClick={onNewStatusClick}>
                + Add Status
              </Button>
            </div>
            <div className="mx-[-16px] min-h-0 flex-1 px-4 ">
              {/* Drops into the first position of the list. Since we always put the item
            AFTER the element it's dropped on, to get it to the top of the list we have
            to drop it on the "-1"st item */}
              <DropTarget dropId="first" onDrop={onDrop}>
                <Divider className="mb-4" />
                <div className="text-base font-semibold">Status Order</div>
                {statuses.length === 0 && (
                  <div className="mt-3 rounded-lg border border-solid border-bz-gray-500 bg-bz-gray-200 p-4 text-center">
                    <div className="mb-2 text-sm font-semibold text-bz-gray-900">
                      There are no statuses for this job lifecycle
                    </div>
                    <div className="text-sm text-bz-gray-700">
                      Click "Add status" to add a status
                    </div>
                  </div>
                )}
              </DropTarget>
              <div className="flex flex-col pt-2">
                {statusSequence.map(guid => {
                  const status = getStatusByGuid(guid)
                  return (
                    status && (
                      <JobLifecycleStatusBox
                        key={status.jobLifecycleStatusGuid}
                        jobLifecycleType={jobLifecycleType}
                        onDrop={onDrop}
                        onEditClick={onEditClick}
                        onDeleteClick={deleteStatus}
                        {...status}
                      />
                    )
                  )
                })}
              </div>
            </div>

            {editingStatus && (
              <EditJobLifecycleStatusSidebar
                initialStatus={editingStatus}
                jobLifecycleType={jobLifecycleType}
                onClose={onNewJobLifecycleSidebarClose}
              />
            )}
          </div>
          {jobLifecycleUpsertMutation.isLoading && (
            <div className="absolute inset-0 bg-[#00000070]">
              <LoadingSpinner />
            </div>
          )}

          <UnsavedChangesModal
            open={isBackToLifecycleListModalVisible}
            onSavePressed={onSave}
            onDiscardPressed={resetSelectedLifecycle}
            onCancel={() => setIsBackToLifecycleListModalVisible(false)}
          />

          <JobLifecycleDeleteLifecycleStatusDrawer
            open={!isNullish(deleteDrawerLifecycleStatus)}
            lifecycleStatus={deleteDrawerLifecycleStatus}
            transitionStatuses={Object.values(originalStatusMap).map(
              status => ({
                lifecycleStatusGuid: status.jobLifecycleStatusGuid,
                name: status.name,
              }),
            )}
            isLoading={false}
            onLifecycleStatusDeleteClicked={({
              toDeleteLifecycleStatusGuid,
              transitionLifecycleStatusGuid,
              newDefaultLifecycleStatus,
            }) => {
              if (!isNullish(newDefaultLifecycleStatus)) {
                const status = originalStatusMap[newDefaultLifecycleStatus]
                if (!isNullish(status)) {
                  changeStatus({
                    ...status,
                    isDefault: true,
                  })
                }
              }

              deleteStatus(
                toDeleteLifecycleStatusGuid,
                transitionLifecycleStatusGuid,
                // newDefaultLifecycleStatus,
              )
              setDeleteDrawerLifecycleStatus(null)
            }}
            onCancel={() => setDeleteDrawerLifecycleStatus(null)}
          />
        </JobLifecycleManagementContext.Provider>
      )
    },
  )
