export type FlexItem = {
  flex: number
  minWidth: number
}

export const calculateFlex = (
  items: FlexItem[],
  containerWidth: number,
): [widths: number[], totalMinWidth: number] => {
  let totalMinWidth = 0
  let totalFlex = 0
  for (const item of items) {
    totalMinWidth += item.minWidth ?? 0
    totalFlex += item.flex ?? 0
  }
  const availableWidth = Math.max(0, containerWidth - totalMinWidth)

  return [
    items.map(item => {
      const flex = item.flex ?? 0
      const minWidth = item.minWidth ?? 0
      return (
        (totalFlex === 0 ? 1 / items.length : flex / totalFlex) *
          availableWidth +
        minWidth
      )
    }),
    totalMinWidth,
  ]
}
