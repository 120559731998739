import {
  PricebookCategoryGuid,
  containsAnyCase,
  nextGuid,
} from '@breezy/shared'
import { Button, Input, Tree } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { useModalState } from '../../utils/react-utils'
import { usePricebookAdmin } from './hooks/usePricebookAdmin'
import './PricebookAdmin'
import { PricebookCategories } from './PricebookAdminTypes'
import {
  UpsertPricebookCategoryFormValues,
  UpsertPricebookCategoryModal,
} from './UpsertPricebookCategoryModal'
import {
  buildSortedPricebookCategoryTreeStructure,
  getDataNodeParentKey,
  toTreeSelectDataNode,
} from './Utils/utils'

type PricebookCategoriesTreeProps = {
  categories: PricebookCategories
}

export const PricebookCategoriesTree = ({
  categories,
}: PricebookCategoriesTreeProps) => {
  const { companyGuid } = useExpectedCompany()
  const { categoriesMutated } = usePricebookAdmin()

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [autoExpandParent, setAutoExpandParent] = useState(true)
  const [isCreateTopLevelOpen, openCreateTopLevel, closeCreateTopLevel] =
    useModalState(false)

  const createCategoryMutation =
    trpc.pricebook['pricebook:save-category'].useMutation()

  const categoriesTree = useMemo(() => {
    const onNewChildCategoryAdded = (
      parentCategoryGuid: PricebookCategoryGuid,
    ) => {
      if (!expandedKeys.includes(parentCategoryGuid))
        setExpandedKeys([parentCategoryGuid, ...expandedKeys])
    }

    return buildSortedPricebookCategoryTreeStructure(categories).map(c =>
      toTreeSelectDataNode(
        searchValue,
        onNewChildCategoryAdded,
        categoriesMutated,
        c,
      ),
    )
  }, [
    categories,
    searchValue,
    expandedKeys,
    setExpandedKeys,
    categoriesMutated,
  ])

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const newExpandedKeys = categories
      .map(item => {
        if (containsAnyCase(item.name, value)) {
          return getDataNodeParentKey(
            item.pricebookCategoryGuid,
            categoriesTree,
          )
        }
        return null
      })
      .filter((item, i, self) => item && self.indexOf(item) === i)
    setExpandedKeys(newExpandedKeys as React.Key[])
    setSearchValue(value)
    setAutoExpandParent(true)
  }

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys)
    setAutoExpandParent(false)
  }

  const createNewTopLevelCategory = useCallback(
    (formValues: UpsertPricebookCategoryFormValues) => {
      createCategoryMutation.mutate(
        {
          companyGuid,
          name: formValues.name,
          pricebookCategoryGuid: nextGuid(),
          sourcePhotoGuid: formValues.sourcePhotoGuid,
          photoGuid: formValues.photoGuid,
        },
        {
          onSuccess: () => {
            closeCreateTopLevel()
            categoriesMutated()
          },
        },
      )
    },
    [
      createCategoryMutation,
      companyGuid,
      closeCreateTopLevel,
      categoriesMutated,
    ],
  )

  return (
    <div className="pricebook-category-drawer__container">
      <Input
        placeholder="Search categories..."
        onChange={onSearchChange}
        allowClear={true}
        prefix={<AiOutlineSearch />}
      />
      <div className="my-4 flex space-x-2">
        <Button type="primary" onClick={openCreateTopLevel}>
          Create Top Level Category
        </Button>
      </div>
      <Tree
        showLine={{ showLeafIcon: false }}
        treeData={categoriesTree}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onExpand={onExpand}
        selectable={false}
        blockNode={true}
      />
      {isCreateTopLevelOpen && (
        <UpsertPricebookCategoryModal
          onClose={closeCreateTopLevel}
          onSubmit={createNewTopLevelCategory}
          mode="create"
          loading={createCategoryMutation.isLoading}
        />
      )}
    </div>
  )
}
