import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { OnResize, useResizeObserver } from '../../hooks/useResizeObserver'
import { getNextColor } from '../../utils/color-utils'
import { ChartTooltipContent } from './ChartTooltip'

type DoughnutData = {
  label: string
  value: number
  color?: string
}

const DEFAULT_CUTOUT = 75

type DoughnutChartProps = React.PropsWithChildren<{
  data: DoughnutData[]
  // Percentage of the chart that's "cut out" in the middle. So 0% would be a pie chart and 100% would be invisible
  cutout?: number
  renderTooltipValue?: (value: number) => React.ReactNode
  hideTooltip?: boolean
}>

export const DoughnutChart = React.memo<DoughnutChartProps>(
  ({ data, cutout, renderTooltipValue, children, hideTooltip }) => {
    const processedData = useMemo(() => {
      let colorCounter = 0
      const processedData: { name: string; value: number; color: string }[] = []

      for (const { label, value, color } of data) {
        processedData.push({
          name: label,
          value,
          color: color ?? getNextColor(colorCounter++),
        })
      }
      return processedData
    }, [data])

    const containerRef = useRef<HTMLDivElement | null>(null)

    const [interiorPositioningStyle, setInteriorPositioningStyle] = useState({
      width: '0px',
      height: '0px',
      left: '0px',
      top: '0px',
    })

    const onResize = useCallback<OnResize>(
      ({ width, height }) => {
        const newWidth = (width * (cutout ?? DEFAULT_CUTOUT)) / 100
        const newHeight = (height * (cutout ?? DEFAULT_CUTOUT)) / 100

        setInteriorPositioningStyle({
          width: `${newWidth}px`,
          height: `${newHeight}px`,
          left: `${(width - newWidth) / 2}px`,
          top: `${(height - newHeight) / 2}px`,
        })
      },
      [cutout],
    )

    useResizeObserver(containerRef, onResize)

    return (
      <div
        className="relative h-full w-full overflow-hidden"
        ref={containerRef}
      >
        {children && (
          <div
            className="pointer-events-none absolute flex items-center justify-center overflow-hidden rounded-full"
            style={interiorPositioningStyle}
          >
            {children}
          </div>
        )}
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={processedData}
              dataKey="value"
              cx="50%"
              cy="50%"
              outerRadius="100%"
              innerRadius={`${cutout ?? DEFAULT_CUTOUT}%`}
            >
              {processedData.map(({ name, color }) => (
                <Cell key={name} fill={color} />
              ))}
            </Pie>
            {!hideTooltip && (
              <Tooltip
                content={props => {
                  return (
                    <ChartTooltipContent
                      data={
                        props.payload?.map(({ name, payload, value }) => ({
                          label: name as string,
                          color: payload.color,
                          value: renderTooltipValue
                            ? renderTooltipValue(value as number)
                            : (value as number),
                        })) ?? []
                      }
                    />
                  )
                }}
              />
            )}
          </PieChart>
        </ResponsiveContainer>
      </div>
    )
  },
)
