import { InvoiceTerm, invoiceTermDisplayNames, isNullish } from '@breezy/shared'
import { Input, Select } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'
import { useQuery } from 'urql'
import GqlQueryLoader from '../../../components/GqlQueryLoader/GqlQueryLoader'
import { useExpectedCompany } from '../../../providers/PrincipalUser'
import { PRICEBOOK_TAX_RATES_QUERY } from '../BillingProfileSettingsPage.gql'
import { BillingProfileFormSchema } from '../billing-profile-form'

export const BillingProfileSettingsInvoicesEstimatesSection = () => {
  const { control, setValue } = useFormContext<BillingProfileFormSchema>()

  const { companyGuid } = useExpectedCompany()

  const taxRatesQuery = useQuery({
    query: PRICEBOOK_TAX_RATES_QUERY,
    variables: { companyGuid },
  })

  return (
    <div className="flex flex-col gap-3">
      <GqlQueryLoader
        query={taxRatesQuery}
        render={data => (
          <Controller
            control={control}
            name="invoicesAndEstimates.defaultTaxRateGuid"
            render={({ field }) => (
              <label className="flex flex-col gap-1">
                <span>Default Tax Rate</span>

                <Select<string | undefined>
                  {...field}
                  allowClear
                  placeholder="Select default tax rate..."
                  value={field.value}
                  options={data.pricebookTaxRates.map(taxRate => ({
                    label: taxRate.name,
                    value: taxRate.pricebookTaxRateGuid,
                  }))}
                  onChange={value => {
                    if (isNullish(value)) {
                      setValue(
                        'invoicesAndEstimates.defaultTaxRateGuid',
                        undefined,
                      )
                      return
                    }

                    field.onChange(value)
                  }}
                />
              </label>
            )}
          />
        )}
      />

      <Controller
        control={control}
        name="invoicesAndEstimates.defaultInvoiceTerm"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Default Invoice Term</span>

            <Select<InvoiceTerm | undefined>
              {...field}
              allowClear
              placeholder="Select default invoice term..."
              value={field.value}
              options={[
                ...Object.keys(InvoiceTerm).map(invoiceTerm => ({
                  label: invoiceTermDisplayNames[invoiceTerm as InvoiceTerm],
                  value: InvoiceTerm[invoiceTerm as InvoiceTerm],
                })),
              ]}
              onChange={value => {
                if (isNullish(value)) {
                  setValue('invoicesAndEstimates.defaultInvoiceTerm', undefined)
                  return
                }

                field.onChange(value)
              }}
            />
          </label>
        )}
      />

      <Controller
        control={control}
        name="invoicesAndEstimates.defaultInvoiceMemo"
        render={({ field }) => (
          <div className="flex flex-col gap-1">
            <span>Default Invoice Memo</span>

            <Input.TextArea
              {...field}
              placeholder="Enter default invoice memo here..."
              rows={5}
            />
          </div>
        )}
      />

      <Controller
        control={control}
        name="invoicesAndEstimates.defaultEstimateDisclaimer"
        render={({ field }) => (
          <div className="flex flex-col gap-1">
            <span>Default Estimate Disclaimer</span>

            <Input.TextArea
              {...field}
              placeholder="Enter default estimate disclaimer here..."
              rows={5}
            />
          </div>
        )}
      />
      <Controller
        control={control}
        name="invoicesAndEstimates.defaultInvoiceDisclaimer"
        render={({ field }) => (
          <div className="flex flex-col gap-1">
            <span>Default Invoice Disclaimer</span>

            <Input.TextArea
              {...field}
              placeholder="Enter default invoice disclaimer here..."
              rows={5}
            />
          </div>
        )}
      />
    </div>
  )
}
