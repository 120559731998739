import { formatUscWholeDollars, usdToUsCents } from '@breezy/shared'
import { Col, Row } from 'antd'
import { memo, useMemo } from 'react'
import { Tile } from '../Tile'
import { TeamPerformanceDivider } from './TeamPerformanceDivider'
import { TeamPerformanceJob } from './team-performance-queries.gql'

type AverageTicketSizeTileProps = {
  jobs: TeamPerformanceJob[]
  loading: boolean
}

export const AverageTicketSizeTile = memo<AverageTicketSizeTileProps>(
  ({ jobs, loading }) => {
    const allJobsAverageTicketSizeUsd = useMemo(() => {
      const totalTicketAmountUsd = jobs.reduce((acc, job) => {
        return (
          acc + (job.jobFullyPaidInvoicesV2Summary?.totalPaidAmountUsd ?? 0)
        )
      }, 0)

      if (jobs.length === 0) {
        return 0
      }

      return Math.round(totalTicketAmountUsd / jobs.length)
    }, [jobs])

    const opportunitiesAverageTicketSizeUsd = useMemo(() => {
      const opportunities = jobs.filter(job => job.isOpportunity)
      if (opportunities.length === 0) {
        return 0
      }

      const totalTicketAmountUsd = opportunities.reduce((acc, job) => {
        return (
          acc + (job.jobFullyPaidInvoicesV2Summary?.totalPaidAmountUsd ?? 0)
        )
      }, 0)

      return Math.round(totalTicketAmountUsd / opportunities.length)
    }, [jobs])

    const hotLeadsAverageTicketSizeUsd = useMemo(() => {
      const hotLeads = jobs.filter(job => job.isHotLead)
      if (hotLeads.length === 0) {
        return 0
      }

      const totalTicketAmountUsd = hotLeads.reduce((acc, job) => {
        return (
          acc + (job.jobFullyPaidInvoicesV2Summary?.totalPaidAmountUsd ?? 0)
        )
      }, 0)

      return Math.round(totalTicketAmountUsd / hotLeads.length)
    }, [jobs])

    return (
      <Tile title="Avg. Ticket Size" loading={loading}>
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">All Jobs</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {formatUscWholeDollars(usdToUsCents(allJobsAverageTicketSizeUsd))}
          </Col>
        </Row>
        <TeamPerformanceDivider />
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">Opportunities</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {formatUscWholeDollars(
              usdToUsCents(opportunitiesAverageTicketSizeUsd),
            )}
          </Col>
        </Row>
        <TeamPerformanceDivider />
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">Hot Leads</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {formatUscWholeDollars(usdToUsCents(hotLeadsAverageTicketSizeUsd))}
          </Col>
        </Row>
      </Tile>
    )
  },
)
