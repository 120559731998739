import { ReminderAssignmentEmailData } from '@breezy/shared'
import { Body, Container, Head, Heading, Hr, Html, Img, Section, Text } from '@react-email/components'
import React from 'react'
import AccountReminder from '../AccountReminder/AccountReminder'
import Tailwind from '../Tailwind/Tailwind'

const BREEZY_LOGO_URL = 'https://s3.us-west-1.amazonaws.com/www.getbreezyapp.com/images/Breezy-01.png'

const ReminderAssignmentEmail = React.memo<ReminderAssignmentEmailData>(
  ({ accountReminderEmail, companyTimeZoneId, accountDetailsUrl, companyLogoUrl }) => {
    return (
      <Html>
        <Tailwind>
          <Head />
          <Body className="bg-white font-body p-6">
            <Container>
              <Section className="mb-6">
                <Img
                  alt="Company logo"
                  src={companyLogoUrl ?? BREEZY_LOGO_URL}
                  width={164}
                  className="block border-0 h-auto leading-100 outline-none decoration-none object-contain object-left max-w-full"
                />
              </Section>
              <Section>
                <Text className="font-semibold text-[16px] text-bz-gray-1000">
                  Hello {accountReminderEmail.reminderAssignment.user.firstName},
                </Text>
                <Text className="text-[16px] text-bz-gray-1000">
                  {accountReminderEmail.createdByUser.firstName} has assigned you a reminder. You can view the details
                  below.
                </Text>
              </Section>
              <Section>
                <Heading className="font-semibold text-[16px] text-bz-gray-1000">New Reminder</Heading>
                <Hr />
              </Section>
              <Section>
                <AccountReminder
                  accountReminderEmail={accountReminderEmail}
                  accountDetailsUrl={accountDetailsUrl}
                  companyTimeZoneId={companyTimeZoneId}
                />
              </Section>
            </Container>
          </Body>
        </Tailwind>
      </Html>
    )
  },
)

export default ReminderAssignmentEmail
