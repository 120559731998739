import { Row } from 'antd'

type PricebookSingleLineItemViewProps = {
  readonly name: string
  readonly amountStr: string
  readonly onClick: () => void
}

const PricebookSingleLineItemView = ({
  name,
  amountStr,
  onClick,
}: PricebookSingleLineItemViewProps) => (
  <Row
    className="space-between flex py-1"
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <div className="regular_12_22 font-semibold">{name}</div>
    <div className="regular_12_22 font-semibold">{amountStr}</div>
  </Row>
)

export default PricebookSingleLineItemView
