import { gql } from '../../generated'
export const PRICEBOOK_VERSION_HISTORY_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription PricebookVersionHistory {
    pricebookVersions(orderBy: { createdAt: DESC }) {
      pricebookVersionGuid
      createdAt
      createdByUserGuid
      displayId
      name
      createdByUser {
        firstNameInitial
      }
      dataSchemaVersion
      pricebookItemsFileGuid
      pricebookCategoriesFileGuid
      pricebookItemsFile {
        fileGuid
        fileName
        cdnUrl
      }
      pricebookCategoriesFile {
        fileGuid
        fileName
        cdnUrl
      }
    }
  }
`)
