import { JobLifecycleView, isNullish } from '@breezy/shared'
import { faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Select } from 'antd'
import React, { useState } from 'react'
import { CreateJobLifecycleViewModal } from '../../pages/JobPages/components/CreateJobLifecycleViewModal'
import DangerConfirmModal from '../DangerConfirmModal/DangerConfirmModal'
import { JOBS_VIEWS_ALL_JOBS, useJobsViewsContext } from './JobsViewsContext'

export const JobsViewsDropdown = React.memo(() => {
  const {
    jobsViews,
    selectedJobsView,
    setSelectedJobsViewName,
    deleteJobsView,
    isLoading,
  } = useJobsViewsContext()

  const [viewToDelete, setViewToDelete] = useState<
    JobLifecycleView | undefined
  >()

  const [viewToEdit, setViewToEdit] = useState<JobLifecycleView | undefined>()

  return (
    <>
      <Select
        size="middle"
        className="w-[200px]"
        value={selectedJobsView.jobLifecycleViewGuid}
        onClick={event => event.stopPropagation()}
        optionLabelProp="label"
        popupMatchSelectWidth={false}
        disabled={isLoading}
      >
        {jobsViews.map(view => (
          <Select.Option
            key={view.jobLifecycleViewGuid}
            label={view.title}
            value={view.jobLifecycleViewGuid}
          >
            <div
              className="flex w-full flex-row items-center"
              onClick={() =>
                setSelectedJobsViewName(
                  view.title === JOBS_VIEWS_ALL_JOBS.title ? '' : view.title,
                )
              }
            >
              <span className="min-w-0 flex-1 truncate">{view.title}</span>
              {view.jobLifecycleViewGuid !==
                JOBS_VIEWS_ALL_JOBS.jobLifecycleViewGuid && (
                <div className="ml-2 flex flex-row items-center space-x-3">
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="text-bz-gray-600 hover:text-bz-gray-800"
                    onClick={event => {
                      event.stopPropagation()
                      setViewToEdit(view)
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="text-bz-red-300 hover:text-bz-red-800"
                    onClick={event => {
                      event.stopPropagation()
                      setViewToDelete(view)
                    }}
                  />
                </div>
              )}
            </div>
          </Select.Option>
        ))}
      </Select>

      {!isNullish(viewToEdit) && (
        <CreateJobLifecycleViewModal
          viewToEdit={viewToEdit}
          onClose={() => setViewToEdit(undefined)}
        />
      )}

      <DangerConfirmModal
        open={!isNullish(viewToDelete)}
        title="Delete Job Lifecycle View"
        onOk={() => {
          if (!viewToDelete) {
            return
          }
          deleteJobsView(viewToDelete.jobLifecycleViewGuid)
          setViewToDelete(undefined)
        }}
        onCancel={() => setViewToDelete(undefined)}
        promptText={`Are you sure you want to delete the view "${viewToDelete?.title}"?`}
      />
    </>
  )
})
