import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import { useEffect, useRef } from 'react'
import './AppMenu.less'
import { useAppMenuGroup } from './AppMenuGroup'

export type NavLinkProps = {
  icon?: FontAwesomeIconProps['icon']
  iconClassName?: string
  spaceBefore?: boolean
  isText?: boolean
  isActive?: boolean
  children: React.ReactNode
}

export const AppMenuItem = ({
  icon,
  spaceBefore,
  isText,
  isActive,
  children,
  iconClassName,
}: NavLinkProps) => {
  const { current: keyObj } = useRef({})
  const { registerItem, unregisterItem } = useAppMenuGroup()

  useEffect(() => {
    registerItem({ keyObj, isActive })
  }, [isActive, keyObj, registerItem])

  useEffect(() => {
    return () => unregisterItem({ keyObj })
  }, [keyObj, unregisterItem])

  return (
    <div
      className={cn('appMenu__item', {
        'appMenu__item--text': isText,
        'appMenu__item--space-before': spaceBefore,
        'is-active': isActive,
      })}
    >
      <div className={cn('appMenu__item-icon', iconClassName)}>
        {icon && <FontAwesomeIcon icon={icon} size="xl" />}
      </div>
      <div className="appMenu__item-label">{children}</div>
    </div>
  )
}
