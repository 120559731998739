import { Button, Col, Row } from 'antd'
import { BzTitleAndExplanation } from '../../elements/BzTitleAndExplanation/BzTitleAndExplanation'
import { gray5 } from '../../themes/theme'
import { Composable } from '../../utils/Composable'
import { Styled } from '../../utils/Stylable'

type MaintenancePlanSectionProps = {
  readonly title: string
  readonly description: string
  readonly buttonText: string
  readonly buttonOnClick: () => void
  readonly children?: React.ReactNode
  readonly empty?: React.ReactNode
}

export const maintenancePlanItemStandardWidth = ' w-[140px]'

export const MaintenanceNumberLabelPair = ({
  kpi,
  description,
}: {
  kpi: string
  description: string
}) => (
  <div className={`flex flex-col ${maintenancePlanItemStandardWidth.trim()}`}>
    <div className="semibold_16_24 grey9">{kpi}</div>
    <div className="grey8 font-light">{description}</div>
  </div>
)

export const MaintenanceItemContainer = ({
  className,
  style,
  children,
}: Styled<Composable>) => (
  <div
    className={`column mb-4 flex min-h-[86px] w-full p-3 ${
      className?.trim() || ''
    }`}
    style={{
      border: '1px solid',
      borderColor: gray5,
      borderRadius: 8,
      ...style,
    }}
  >
    {children}
  </div>
)

export const MaintenanceConfigSection = ({
  title,
  description,
  buttonText,
  buttonOnClick,
  children,
  empty,
}: MaintenancePlanSectionProps) => {
  return (
    <Col className="w-full px-2 py-4">
      <Row className="row flex-between no-flex-wrap center-children-v flex w-full pb-4">
        <BzTitleAndExplanation title={title} description={description} />
        <Button type="primary" onClick={buttonOnClick}>
          {buttonText}
        </Button>
      </Row>
      {!!children && children}
      {!children && empty}
    </Col>
  )
}
