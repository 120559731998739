import { gql } from '../../generated'

export const UPDATE_END_OF_APPOINTMENT_WORKFLOW_ENABLED = gql(/* GraphQL */ `
  mutation UpdateEndOfJobAppointmentWorkflowEnabled(
    $companyGuid: uuid!
    $enabled: Boolean!
  ) {
    updateCompanyConfigByPk(
      _set: { endOfJobAppointmentWorkflowEnabled: $enabled }
      pkColumns: { companyGuid: $companyGuid }
    ) {
      __typename
    }
  }
`)
