import { formatAbbreviatedUsc } from '@breezy/shared'
import { IconDefinition } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'

type LifecycleFinanceSummaryStubProps = {
  icon: IconDefinition
  title: 'Invoices' | 'Estimates'
  valueUsc: number
  className?: string
  amountClassName?: string
}
const LifecycleFinanceSummaryStub =
  React.memo<LifecycleFinanceSummaryStubProps>(
    ({ icon, title, valueUsc, className, amountClassName }) => {
      return (
        <div
          className={classNames(
            'flex items-center gap-2 font-semibold',
            className,
          )}
        >
          <FontAwesomeIcon icon={icon} />
          <span className="inline-block text-bz-gray-900">{title}</span>
          <span
            className={classNames(
              'inline-block text-bz-text-tertiary',
              amountClassName,
            )}
          >
            {formatAbbreviatedUsc(valueUsc)}
          </span>
        </div>
      )
    },
  )

export default LifecycleFinanceSummaryStub
