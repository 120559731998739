import { R } from '@breezy/shared'
import React, { useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'
import { DEFAULT_FONT_FAMILY } from '../../themes/theme'

const memo: Record<string, google.maps.GeocoderResult | undefined> = {}

export const getGeocode = async (
  address: string,
): Promise<google.maps.GeocoderResult | undefined> => {
  const geocoder = new google.maps.Geocoder()

  if (memo[address]) {
    return memo[address]
  }
  try {
    const results = await geocoder.geocode({ address })
    const result = results.results[0]
    memo[address] = result
    return result
  } catch (e) {
    return undefined
  }
}

export const BREEZY_LAT_LNG = {
  lat: 39.7605371,
  lng: -105.009517,
}

export interface Place {
  id: string
  lat: number
  lng: number
  name: string
}

export interface MarkerInfo {
  place: Place
  marker: google.maps.marker.AdvancedMarkerElement
  infoWindow: google.maps.InfoWindow
}

export interface MapViewProps extends google.maps.MapOptions {
  mapId: string
  places: Place[]
  highlightedPlaceId?: string
  renderMarkerInfo: (place: Place) => React.ReactNode
}

const MapView = React.memo<MapViewProps>(
  ({ mapId, places, highlightedPlaceId, renderMarkerInfo, ...rest }) => {
    const ref = useRef<HTMLDivElement>(null)

    const mapRef = useRef<google.maps.Map | null>(null)

    const markerInfoMapRef = useRef<Record<string, MarkerInfo>>({})

    useEffect(() => {
      if (ref.current) {
        const map = new google.maps.Map(ref.current, {
          center: BREEZY_LAT_LNG,
          zoom: 5,
          mapId,
          ...rest,
        })
        mapRef.current = map
      }
    }, [mapId, rest])

    useEffect(() => {
      const map = mapRef.current
      if (!map) {
        return
      }

      const bounds = new google.maps.LatLngBounds()

      const newMarkerInfoMap: Record<string, MarkerInfo> = {}

      const seenPlaceMap: Record<string, true> = {}

      for (const place of places) {
        seenPlaceMap[place.id] = true
        const pos = {
          lat: place.lat,
          lng: place.lng,
        }
        bounds.extend(pos)

        const existingMarkerInfo = markerInfoMapRef.current[place.id]

        if (existingMarkerInfo) {
          existingMarkerInfo.marker.position = pos
          newMarkerInfoMap[place.id] = existingMarkerInfo
        } else {
          const marker = new google.maps.marker.AdvancedMarkerElement({
            position: pos,
            title: place.name,
            map,
          })
          const content = ReactDOMServer.renderToString(
            <div style={{ fontFamily: DEFAULT_FONT_FAMILY }}>
              {renderMarkerInfo(place)}
            </div>,
          )

          const infoWindow = new google.maps.InfoWindow({
            content,
            ariaLabel: place.name,
          })

          marker.addListener('click', () => {
            infoWindow.open({ anchor: marker, map })
          })
          newMarkerInfoMap[place.id] = {
            place,
            marker,
            infoWindow,
          }
        }
      }

      for (const oldId of R.keys(markerInfoMapRef.current)) {
        if (!seenPlaceMap[oldId]) {
          const { marker, infoWindow } = markerInfoMapRef.current[oldId]
          marker.map = null
          infoWindow.close()
        }
      }

      markerInfoMapRef.current = newMarkerInfoMap

      map.fitBounds(bounds)
    }, [places, renderMarkerInfo])

    // Handle toggling the selected place
    useEffect(() => {
      // Don't do this if we haven't loaded any markers yet
      if (mapRef.current && R.keys(markerInfoMapRef.current).length) {
        const map = mapRef.current

        // If they toggle the highlighting off, reset the bounds
        const resetBounds = new google.maps.LatLngBounds()

        for (const id of R.keys(markerInfoMapRef.current)) {
          const { marker, infoWindow, place } = markerInfoMapRef.current[id]
          const pos = {
            lat: place.lat,
            lng: place.lng,
          }

          if (!highlightedPlaceId) {
            resetBounds.extend(pos)
            infoWindow.close()
            continue
          }

          if (id === highlightedPlaceId) {
            infoWindow.open({ anchor: marker, map })
            map.panTo(pos)
          } else {
            infoWindow.close()
          }
        }
        if (!highlightedPlaceId) {
          map.fitBounds(resetBounds)
        }
      }
    }, [highlightedPlaceId])

    return <div ref={ref} className="flex-1 border border-red-500" />
  },
)

export default MapView
