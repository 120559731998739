import { IconDefinition } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'
import { Link } from '../../elements/Link/Link'

type LinkConfig = {
  text: string
  to: string
}

type LinkProp = React.ReactNode | LinkConfig

const linkIsJSON = (link: LinkProp): link is LinkConfig =>
  !React.isValidElement(link)

type NoDataOverlayProps = React.PropsWithChildren<{
  className?: string
  icon: IconDefinition
  title: string
  link?: LinkProp
}>

export const NoDataOverlay = React.memo<NoDataOverlayProps>(
  ({ className, icon, title, children, link }) => (
    <div
      className={classNames(
        'absolute inset-0 flex flex-col items-center justify-center backdrop-blur',
        className,
      )}
    >
      <div className="flex h-14 w-14 items-center justify-center rounded-full bg-bz-gray-100 text-daybreak-blue-400 drop-shadow">
        <FontAwesomeIcon icon={icon} className="h-6 w-6" />
      </div>
      <div className="mt-4 text-xl font-semibold text-bz-gray-900">{title}</div>
      <div className="mt-2 w-[50%] text-center  text-sm text-bz-gray-800">
        {children}
      </div>
      {link ? (
        <div className="mt-2">
          {linkIsJSON(link) ? <Link to={link.to}>{link.text}</Link> : link}
        </div>
      ) : null}
    </div>
  ),
)
