import {
  JobClass,
  formatPercentage,
  formatUscWholeDollars,
  usdToUsCents,
} from '@breezy/shared'
import { Col, Row } from 'antd'
import { memo, useMemo } from 'react'
import { Tile } from '../Tile'
import { TeamPerformanceDivider } from './TeamPerformanceDivider'
import { TeamPerformanceJob } from './team-performance-queries.gql'

type TeamPerformanceByJobClassTilesProps = {
  jobs: TeamPerformanceJob[]
  showSalesAndInstallTiles: boolean
}

export const TeamPerformanceByJobClassTiles =
  memo<TeamPerformanceByJobClassTilesProps>(
    ({ jobs, showSalesAndInstallTiles }) => {
      const salesJobs = useMemo(
        () => jobs.filter(job => job.jobType.jobClass === JobClass.SALES),
        [jobs],
      )
      const installJobs = useMemo(
        () => jobs.filter(job => job.jobType.jobClass === JobClass.INSTALL),
        [jobs],
      )
      const serviceJobs = useMemo(
        () => jobs.filter(job => job.jobType.jobClass === JobClass.SERVICE),
        [jobs],
      )
      const maintenanceJobs = useMemo(
        () => jobs.filter(job => job.jobType.jobClass === JobClass.MAINTENANCE),
        [jobs],
      )

      const salesJobsTotalEstimatesSoldUsc = useMemo(() => {
        return salesJobs.reduce((acc, job) => {
          let jobSalesTotalUsc = 0
          job.estimates
            .filter(estimate => estimate.status === 'ACCEPTED')
            .forEach(estimate => {
              estimate.estimateOptions.forEach(option => {
                if (option.isSelected) {
                  jobSalesTotalUsc += option.totalUsc
                }
              })
            })

          return acc + jobSalesTotalUsc
        }, 0)
      }, [salesJobs])

      const [installJobsTotalRevenueUsd, installJobsAvgTicketSizeUsd] =
        useMemo(() => {
          const totalRevenueUsd = installJobs.reduce((acc, job) => {
            return (
              acc + (job.jobFullyPaidInvoicesV2Summary?.totalPaidAmountUsd ?? 0)
            )
          }, 0)

          const avgTicketSizeUsd =
            installJobs.length === 0
              ? 0
              : Math.round(totalRevenueUsd / installJobs.length)

          return [totalRevenueUsd, avgTicketSizeUsd]
        }, [installJobs])

      const [serviceJobsTotalRevenueUsd, serviceJobsAvgTicketSizeUsd] =
        useMemo(() => {
          const totalRevenueUsd = serviceJobs.reduce((acc, job) => {
            return (
              acc + (job.jobFullyPaidInvoicesV2Summary?.totalPaidAmountUsd ?? 0)
            )
          }, 0)

          const avgTicketSizeUsd =
            serviceJobs.length === 0
              ? 0
              : Math.round(totalRevenueUsd / serviceJobs.length)

          return [totalRevenueUsd, avgTicketSizeUsd]
        }, [serviceJobs])

      const [maintenanceJobsTotalRevenueUsd, maintenanceJobsAvgTicketSizeUsd] =
        useMemo(() => {
          const totalRevenueUsd = maintenanceJobs.reduce((acc, job) => {
            return (
              acc + (job.jobFullyPaidInvoicesV2Summary?.totalPaidAmountUsd ?? 0)
            )
          }, 0)

          const avgTicketSizeUsd =
            maintenanceJobs.length === 0
              ? 0
              : Math.round(totalRevenueUsd / maintenanceJobs.length)

          return [totalRevenueUsd, avgTicketSizeUsd]
        }, [maintenanceJobs])

      const [
        installJobsEstimateCloseRateNumerator,
        installJobsEstimateCloseRateDenominator,
      ] = useMemo(() => {
        // We consider all jobs with an estimate unless the estimates are voided
        const jobsWithNonVoidedEstimates = installJobs.filter(job => {
          return job.estimates.some(estimate => estimate.status !== 'VOIDED')
        })

        const jobsWithAtLeastOneAcceptedEstimate =
          jobsWithNonVoidedEstimates.filter(job => {
            return job.estimates.some(
              estimate => estimate.status === 'ACCEPTED',
            )
          })

        return [
          jobsWithAtLeastOneAcceptedEstimate.length,
          jobsWithNonVoidedEstimates.length,
        ]
      }, [installJobs])

      const [
        salesJobsEstimateCloseRateNumerator,
        salesJobsEstimateCloseRateDenominator,
      ] = useMemo(() => {
        // We consider all jobs with an estimate unless the estimates are voided
        const jobsWithNonVoidedEstimates = salesJobs.filter(job => {
          return job.estimates.some(estimate => estimate.status !== 'VOIDED')
        })

        const jobsWithAtLeastOneAcceptedEstimate =
          jobsWithNonVoidedEstimates.filter(job => {
            return job.estimates.some(
              estimate => estimate.status === 'ACCEPTED',
            )
          })

        return [
          jobsWithAtLeastOneAcceptedEstimate.length,
          jobsWithNonVoidedEstimates.length,
        ]
      }, [salesJobs])

      const [
        serviceJobsConversionRateNumerator,
        serviceJobsConversionRateDenominator,
      ] = useMemo(() => {
        const serviceJobsConverted = serviceJobs.filter(job => job.isConverted)
        return [serviceJobsConverted.length, serviceJobs.length]
      }, [serviceJobs])

      const [
        maintenanceJobsConversionRateNumerator,
        maintenanceJobsConversionRateDenominator,
      ] = useMemo(() => {
        const maintenanceJobsConverted = maintenanceJobs.filter(
          job => job.isConverted,
        )
        return [maintenanceJobsConverted.length, maintenanceJobs.length]
      }, [maintenanceJobs])

      return (
        <>
          {showSalesAndInstallTiles && (
            <Tile title="Sales">
              <Row justify="space-between">
                <Col className="h-5.5 text-[16px] font-normal">
                  {`Estimate Close Rate (${salesJobsEstimateCloseRateNumerator} of ${salesJobsEstimateCloseRateDenominator})`}
                </Col>
                <Col className="h-6 text-base">
                  {formatPercentage(
                    salesJobsEstimateCloseRateDenominator === 0
                      ? 0
                      : salesJobsEstimateCloseRateNumerator /
                          salesJobsEstimateCloseRateDenominator,
                  )}
                </Col>
              </Row>
              <TeamPerformanceDivider />
              <Row justify="space-between">
                <Col className="h-5.5 text-[16px] font-normal">
                  {`Total Sales`}
                </Col>
                <Col className="h-6 text-base">
                  {formatUscWholeDollars(salesJobsTotalEstimatesSoldUsc)}
                </Col>
              </Row>
            </Tile>
          )}
          {showSalesAndInstallTiles && (
            <Tile title="Installs">
              <Row justify="space-between">
                <Col className="h-5.5 text-[16px] font-normal">
                  {`Estimate Close Rate (${installJobsEstimateCloseRateNumerator} of ${installJobsEstimateCloseRateDenominator})`}
                </Col>
                <Col className="h-6 text-base">
                  {formatPercentage(
                    installJobsEstimateCloseRateDenominator === 0
                      ? 0
                      : installJobsEstimateCloseRateNumerator /
                          installJobsEstimateCloseRateDenominator,
                  )}
                </Col>
              </Row>
              <TeamPerformanceDivider />
              <Row justify="space-between">
                <Col className="h-5.5 text-[16px] font-normal">
                  Total Revenue
                </Col>
                <Col className="h-6 text-base">
                  {formatUscWholeDollars(
                    usdToUsCents(installJobsTotalRevenueUsd),
                  )}
                </Col>
              </Row>
              <TeamPerformanceDivider />
              <Row justify="space-between">
                <Col className="h-5.5 text-[16px] font-normal">
                  Avg. Ticket Size
                </Col>
                <Col className="h-6 text-base">
                  {formatUscWholeDollars(
                    usdToUsCents(installJobsAvgTicketSizeUsd),
                  )}
                </Col>
              </Row>
            </Tile>
          )}

          <Tile title="Service">
            <Row justify="space-between">
              <Col className="h-5.5 text-[16px] font-normal">
                {`Jobs Converted (${serviceJobsConversionRateNumerator} of ${serviceJobsConversionRateDenominator})`}
              </Col>
              <Col className="h-6 text-base">
                {formatPercentage(
                  serviceJobsConversionRateDenominator === 0
                    ? 0
                    : serviceJobsConversionRateNumerator /
                        serviceJobsConversionRateDenominator,
                )}
              </Col>
            </Row>
            <TeamPerformanceDivider />
            <Row justify="space-between">
              <Col className="h-5.5 text-[16px] font-normal">Total Revenue</Col>
              <Col className="h-6 text-base">
                {formatUscWholeDollars(
                  usdToUsCents(serviceJobsTotalRevenueUsd),
                )}
              </Col>
            </Row>
            <TeamPerformanceDivider />
            <Row justify="space-between">
              <Col className="h-5.5 text-[16px] font-normal">
                Avg. Ticket Size
              </Col>
              <Col className="h-6 text-base">
                {formatUscWholeDollars(
                  usdToUsCents(serviceJobsAvgTicketSizeUsd),
                )}
              </Col>
            </Row>
          </Tile>
          <Tile title="Maintenance">
            <Row justify="space-between">
              <Col className="h-5.5 text-[16px] font-normal">
                {`Jobs Converted (${maintenanceJobsConversionRateNumerator} of ${maintenanceJobsConversionRateDenominator})`}
              </Col>
              <Col className="h-6 text-base">
                {formatPercentage(
                  maintenanceJobsConversionRateDenominator === 0
                    ? 0
                    : maintenanceJobsConversionRateNumerator /
                        maintenanceJobsConversionRateDenominator,
                )}
              </Col>
            </Row>
            <TeamPerformanceDivider />
            <Row justify="space-between">
              <Col className="h-5.5 text-[16px] font-normal">Total Revenue</Col>
              <Col className="h-6 text-base">
                {formatUscWholeDollars(
                  usdToUsCents(maintenanceJobsTotalRevenueUsd),
                )}
              </Col>
            </Row>
            <TeamPerformanceDivider />
            <Row justify="space-between">
              <Col className="h-5.5 text-[16px] font-normal">
                Avg. Ticket Size
              </Col>
              <Col className="h-6 text-base">
                {formatUscWholeDollars(
                  usdToUsCents(maintenanceJobsAvgTicketSizeUsd),
                )}
              </Col>
            </Row>
          </Tile>
        </>
      )
    },
  )
