import {
  BzDateFns,
  FUTURE_TIME_HORIZON_OPTIONS,
  FutureReportingDateRange,
  FutureTimeHorizon,
  TimeZoneId,
} from '@breezy/shared'
import React, { useState } from 'react'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { BaseReportingDateRangePicker } from './BaseReportingDateRangePicker'
import './ReportingDateRangePicker.less'
import { CommonReportingDateRangePickerProps } from './utils'

const getDateRangeFromFutureTimeHorizon = (
  timeHorizon: FutureTimeHorizon,
  tzId: TimeZoneId,
): FutureReportingDateRange => {
  const startOfToday = BzDateFns.startOfDay(BzDateFns.now(tzId))

  const end = (() => {
    switch (timeHorizon) {
      case 'Next 7 days':
        return BzDateFns.addDays(startOfToday, 7)

      case 'Next 15 days':
        return BzDateFns.addDays(startOfToday, 15)

      case 'Next 30 days':
        return BzDateFns.addDays(startOfToday, 30)
    }
    throw new Error(`Unrecognized time horizon: ${timeHorizon}`)
  })()

  return {
    start: BzDateFns.formatISO(startOfToday, tzId),
    end: BzDateFns.formatISO(BzDateFns.endOfDay(end), tzId),
    timeHorizon,
  }
}

export const FutureReportingDateRangePicker = React.memo<
  CommonReportingDateRangePickerProps<FutureTimeHorizon>
>(props => (
  <BaseReportingDateRangePicker
    {...props}
    presets={FUTURE_TIME_HORIZON_OPTIONS}
    getRangeFromPreset={getDateRangeFromFutureTimeHorizon}
  />
))

export const useFutureReportingDateRangePickerState = (
  defaultTimeHorizon: FutureTimeHorizon = FUTURE_TIME_HORIZON_OPTIONS[0],
) => {
  const tzId = useExpectedCompanyTimeZoneId()
  return useState(() =>
    getDateRangeFromFutureTimeHorizon(defaultTimeHorizon, tzId),
  )
}
