import React, { useEffect } from 'react'
import { hasPendingChanges } from '../PendingChanges/pendingChanges'
import { useSchedulePendingChanges } from '../PendingChanges/SchedulePendingChangesContext'
import { useSchedulePlaygroundMode } from '../SchedulePlaygroundModeContext'
import { useCommitScheduleChanges } from './useCommitScheduleChanges'

export const CommitScheduleChangesListener = React.memo(() => {
  const { playgroundMode } = useSchedulePlaygroundMode()
  const { pendingChanges } = useSchedulePendingChanges()
  const commitScheduleChanges = useCommitScheduleChanges()
  useEffect(() => {
    if (!playgroundMode && hasPendingChanges(pendingChanges)) {
      commitScheduleChanges()
    }
    // I only want this to happen when pendingChanges changes, not commitChanges
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingChanges, playgroundMode])

  return null
})
