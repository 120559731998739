import { PageHeader } from '@ant-design/pro-components'
import { BzDateFns, CompanyTimesheetConfig } from '@breezy/shared'
import { useMemo } from 'react'
import { useMutation, useQuery } from 'urql'
import DatePicker from '../../components/DatePicker/DatePicker'
import { Page } from '../../components/Page/Page'
import { RecurrenceForm } from '../../components/RecurrenceForm/RecurrenceForm'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'
import {
  UPSERT_COMPANY_CONFIG_TIMESHEETS_PAY_PERIOD_END_ANCHOR,
  UPSERT_COMPANY_CONFIG_TIMESHEETS_PAY_PERIOD_END_RRULE,
} from '../../gql/CompanyConfig.gql'
import { FETCH_TIMESHEETS_CONFIG_GQL } from '../../hooks/fetch/useFetchTimesheetsConfig'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { TimesheetsIcon } from '../../utils/feature-icons'
import { TimesheetsEnableView } from './TimesheetsEnableView'

const payPeriodDescription =
  'The pay period is the length of time between paychecks. The pay period is typically a week, two weeks, or a month.'

export const TimesheetsSettingsPage = () => {
  const companyGuid = useExpectedCompanyGuid()
  const companyTimezoneId = useExpectedCompanyTimeZoneId()

  const [{ data }, refetch] = useQuery({
    query: FETCH_TIMESHEETS_CONFIG_GQL,
    pause: !companyGuid,
    variables: { companyGuid: companyGuid ?? '' },
    requestPolicy: 'network-only',
  })
  const timesheetsConfig = useMemo(() => {
    return data?.companyConfig[0] as CompanyTimesheetConfig
  }, [data])

  const [{ fetching: uploadingAnchorDate }, upsertAnchorDate] = useMutation(
    UPSERT_COMPANY_CONFIG_TIMESHEETS_PAY_PERIOD_END_ANCHOR,
  )
  const [, upsertRecurrenceRule] = useMutation(
    UPSERT_COMPANY_CONFIG_TIMESHEETS_PAY_PERIOD_END_RRULE,
  )

  return (
    <Page requiresCompanyUser className="flex flex-col p-0">
      <PageHeader
        title={<PageTitle title="Timesheets" icon={TimesheetsIcon} />}
      />
      <ScrollCard
        className="flex h-full w-full flex-col space-y-4"
        hasPageHeading
      >
        <TimesheetsEnableView refetch={refetch} />
        {timesheetsConfig?.timesheetsEnabled && (
          <>
            <h3 className="grey9">Pay Period</h3>
            <p className="regular_14_22 grey8 max-w-[700px] font-light">
              {payPeriodDescription}
            </p>
            <div className="flex flex-col gap-3">
              <RecurrenceForm
                exclusiveDayOfWeek
                rrule={timesheetsConfig.timesheetPayPeriodBeginRrule}
                startingDate={timesheetsConfig.timesheetPayPeriodBeginAnchor}
                onChange={rule => {
                  upsertRecurrenceRule({
                    config: { companyGuid, timesheetPayPeriodBeginRrule: rule },
                  })
                  refetch()
                }}
              />

              <div className="flex w-full flex-row items-center gap-3">
                <span>Starting on</span>

                <DatePicker
                  disabled={uploadingAnchorDate}
                  defaultValue={
                    new Date(timesheetsConfig.timesheetPayPeriodBeginAnchor)
                  }
                  format="MMMM D, YYYY"
                  className="min-w-[300px] max-w-[300px]"
                  allowClear={false}
                  onChange={async date => {
                    if (date) {
                      const midnightAtCompanyTimezoneOfDate =
                        BzDateFns.parseWithTz(
                          BzDateFns.formatLocalDate(date),
                          'yyyy-MM-dd',
                          companyTimezoneId,
                        )
                      upsertAnchorDate({
                        config: {
                          companyGuid,
                          timesheetPayPeriodBeginAnchor:
                            midnightAtCompanyTimezoneOfDate.toISOString(),
                        },
                      })
                    }
                    refetch()
                  }}
                />
              </div>
            </div>
          </>
        )}
      </ScrollCard>
    </Page>
  )
}
