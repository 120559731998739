import { prettyJson } from '@breezy/shared'
import { Button, Col, Form, Row } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import TextArea from 'antd/lib/input/TextArea'
import React, { useCallback } from 'react'
import { trpc } from '../../hooks/trpc'

type FormSchema = {
  importDataJson: string
}

export const DataMigrationView = React.memo(() => {
  const migrateData = trpc.devTools['devtools:migrate-data'].useMutation()
  const [form] = useForm<FormSchema>()

  const migrateDataViaApi = useCallback(
    (values: FormSchema) => {
      migrateData.mutate(JSON.parse(values.importDataJson))
    },
    [migrateData],
  )

  return (
    <div style={{ marginTop: 32 }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={migrateDataViaApi}
        validateTrigger="onBlur"
        initialValues={{
          importDataJson: prettyJson({
            company: {},
            contacts: [],
            locations: [],
            accounts: [],
            jobs: [],
            attachments: [],
            notes: [],
            executingUserGuid: '',
          }),
        }}
      >
        <Row>
          <Col xs={24}>
            <Form.Item name="importDataJson" label="Import Data JSON">
              <TextArea rows={20} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={migrateData.isLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
})
