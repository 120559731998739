import {
  BzDateFns,
  BzDateTime,
  Guid,
  IsoDateString,
  TIMESHEET_ENTRY_ACTIVITY_NAMES,
  TimeZoneId,
  TimesheetEntryActivityName,
  ZoneId,
  ZonedDateTime,
  bzExpect,
  isNullish,
  isPayableTimesheetEntryActivity,
  nextGuid,
  noOp,
  timesheetEntryActivityDisplayName,
} from '@breezy/shared'
import { Form, Input, Select, message } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'urql'
import {
  OnsiteModal,
  OnsiteModalContent,
  OnsiteModalFooter,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import DatePicker from '../../components/DatePicker/DatePicker'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { UserPersonResourceView } from '../../components/PersonResourceView/UserPersonResourceView'
import BzTimePicker from '../../elements/BzTimePicker/BzTimePicker'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
  useExpectedPrincipal,
} from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'
import { validatorNotFalsy } from '../../utils/validators'
import { TIMESHEET_DETAILS_SUMMARY_QUERY } from '../TimesheetsSummaryPage/TimesheetsSummaryPage.gql'
import {
  UPDATE_TIMESHEET_ENTRY_GQL,
  UPSERT_TIMESHEET_ENTRY_GQL,
} from './Timesheets.gql'

const toIsoDateString = (
  year: number,
  month: number,
  day: number,
  hour: number,
  minute: number,
  timeZoneId: TimeZoneId,
) => {
  const isoString = BzDateTime.fromJsJoda(
    ZonedDateTime.now(ZoneId.of(timeZoneId))
      .withYear(year)
      .withMonth(month)
      .withDayOfMonth(day)
      .withHour(hour)
      .withMinute(minute)
      .withSecond(0)
      .withNano(0),
  ).toIsoString()

  return isoString
}

type TimesheetActivityTypeOrCustom = TimesheetEntryActivityName | 'CUSTOM'

type TimesheetEntryFormSchema = {
  localDate: Date
  startTimeOfDay: Date
  endTimeOfDay?: Date
  activityType: TimesheetActivityTypeOrCustom
  customActivityName?: string
  isPayable?: boolean
  jobAppointmentAssignmentGuid?: Guid
  jobAppointmentGuid?: Guid
  jobGuid?: Guid
}

export type TimesheetAddEditFormProps =
  | TimesheetAddFormProps
  | TimesheetEditFormProps

type TimesheetAddFormProps = {
  mode: 'add'
  userGuid: Guid
  timesheetEntryGuid: Guid
  timesheetEntryActivityGuid?: never
  item?: never
  payPeriod: { start: IsoDateString; end: IsoDateString }
  onSubmit?: () => void
  onCancel: () => void
}

type TimesheetEditFormProps = {
  mode: 'edit'
  userGuid: Guid
  timesheetEntryGuid: Guid
  timesheetEntryActivityGuid: Guid
  item: TimesheetEntryFormSchema
  payPeriod: { start: IsoDateString; end: IsoDateString }
  onSubmit?: () => void
  onCancel: () => void
}

// BZ-1788 - POLISH Issues:
// - Layout shift when loading spinner is shown
// - Can cancel form using X button while uploading
// - Can cancel form using Cancel button while uploading
// - The Time Pickers are awful... hard to use, cannot enter text like 7:15

// BZ-1787 - Add Job/Assignment Linking

export const TimesheetAddEditForm = ({
  userGuid,
  timesheetEntryGuid,
  onSubmit,
  onCancel,
  mode,
  timesheetEntryActivityGuid: timesheetEntryActivityGuidProp,
  item,
  payPeriod,
}: TimesheetAddEditFormProps) => {
  const companyGuid = useExpectedCompanyGuid()
  const principleUserGuid = useExpectedPrincipal().userGuid
  const tzId = useExpectedCompanyTimeZoneId()
  const [form] = Form.useForm<TimesheetEntryFormSchema>()

  useEffect(() => {
    if (item) form.setFieldsValue(item)
  }, [form, item])

  const timesheetEntryActivityGuid = useMemo(
    () => timesheetEntryActivityGuidProp || nextGuid(),
    // NOTE: Need to regenerate this whenever the parent guid changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timesheetEntryActivityGuidProp, timesheetEntryGuid],
  )

  const [useCustomActivityName, setUseCustomActivityName] = useState(false)
  const [r1, upsert] = useMutation(UPSERT_TIMESHEET_ENTRY_GQL)
  const [r2, update] = useMutation(UPDATE_TIMESHEET_ENTRY_GQL)
  const [entriesRes] = useQuery({
    query: TIMESHEET_DETAILS_SUMMARY_QUERY,
    variables: {
      companyGuid,
      startDate: payPeriod.start,
      endDate: payPeriod.end,
    },
  })

  const isLoading = entriesRes.fetching || r1.fetching || r2.fetching

  const startOfPayPeriod: Date = useMemo(
    () => BzDateFns.parseISO(payPeriod.start, tzId),
    [payPeriod.start, tzId],
  )
  const endOfPayPeriod: Date = useMemo(
    () => BzDateFns.parseISO(payPeriod.end, tzId),
    [payPeriod.end, tzId],
  )
  const entriesForPayPeriod = useMemo(() => {
    if (isNullish(entriesRes.data)) {
      return null
    }

    return (
      entriesRes.data.users.find(user => user.userGuid === userGuid)
        ?.timesheetEntries ?? null
    )
  }, [entriesRes.data, userGuid])

  const onClose = useCallback(() => {
    form.resetFields()
    onCancel?.()
  }, [form, onCancel])

  const onActivityNameSelected = useCallback(
    (val: TimesheetActivityTypeOrCustom) => {
      setUseCustomActivityName(val === 'CUSTOM')
    },
    [setUseCustomActivityName],
  )

  const onFinish = useCallback(
    async (values: TimesheetEntryFormSchema) => {
      const isCustomActivity = values.activityType === 'CUSTOM'
      const activityName = !isCustomActivity
        ? values.activityType
        : values.customActivityName ?? 'UNKNOWN'
      const isPayable =
        !isCustomActivity && !!values.activityType
          ? isPayableTimesheetEntryActivity(
              values.activityType as TimesheetEntryActivityName,
            )
          : values.isPayable ?? false

      const startTime = toIsoDateString(
        values.localDate.getFullYear(),
        values.localDate.getMonth() + 1,
        values.localDate.getDate(),
        values.startTimeOfDay.getHours(),
        values.startTimeOfDay.getMinutes(),
        tzId,
      )
      const endTime = toIsoDateString(
        values.localDate.getFullYear(),
        values.localDate.getMonth() + 1,
        values.localDate.getDate(),
        values.endTimeOfDay?.getHours() ?? 0,
        values.endTimeOfDay?.getMinutes() ?? 0,
        tzId,
      )

      const now = BzDateFns.now(tzId)

      // Checks if start is before the end time
      const start = BzDateFns.parseISO(startTime, tzId)
      const end = BzDateFns.parseISO(endTime, tzId)
      if (BzDateFns.isBefore(end, start)) {
        message.error('End Time must be after Start Time')
        return
      }

      if (BzDateFns.isBefore(now, start) || BzDateFns.isBefore(now, end)) {
        message.error('Start and End Times cannot be in the set in the future')
        return
      }

      // Check if times are within given pay period
      if (
        BzDateFns.isBefore(start, startOfPayPeriod) ||
        BzDateFns.isAfter(end, endOfPayPeriod)
      ) {
        message.error(
          'Selected date and times must be within the selected pay period',
        )
        return
      }

      // Check if given times overlap with any current existing entries
      if (!isNullish(entriesForPayPeriod)) {
        const overlappingEntry = entriesForPayPeriod
          .filter(entry => entry.timesheetEntryGuid !== timesheetEntryGuid)
          .find(entry => {
            const entryStart = BzDateFns.parseISO(
              bzExpect(entry.finalStartTime),
              tzId,
            )

            // If there's an ongoing timesheet entry, don't allow user to add one after it
            // since it might end up conflicting at a later point
            if (isNullish(entry.finalEndTime)) {
              return true
            }

            const entryEnd = BzDateFns.parseISO(entry.finalEndTime, tzId)

            const isStartOveralapping =
              BzDateFns.isAfter(start, entryStart) &&
              BzDateFns.isBefore(start, entryEnd)
            const isEndOverlapping =
              BzDateFns.isAfter(end, entryStart) &&
              BzDateFns.isBefore(end, entryEnd)

            return isStartOveralapping || isEndOverlapping
          })

        if (!isNullish(overlappingEntry)) {
          message.error(
            'The entry your trying to save overlaps with other entries. Please change the start and end time so they do not overlap.',
          )
          return
        }
      }

      try {
        if (mode === 'add') {
          await upsert({
            entry: {
              timesheetEntryGuid,
              companyGuid,
              userGuid,
              startTime,
              endTime,
              createdByUserGuid: principleUserGuid,
              timesheetEntryActivities: {
                data: [
                  {
                    timesheetEntryActivityGuid,
                    companyGuid,
                    activityName,
                    isPayable,
                  },
                ],
              },
              timesheetEntryLinkData: {
                data: [
                  {
                    companyGuid,
                    jobAppointmentAssignmentGuid:
                      values.jobAppointmentAssignmentGuid,
                    jobAppointmentGuid: values.jobAppointmentGuid,
                    jobGuid: values.jobGuid,
                  },
                ],
              },
            },
          })
        } else {
          await update({
            timesheetEntryGuid,
            updatedByUserGuid: principleUserGuid,
            startTimeOverride: startTime,
            endTimeOverride: endTime,
            timesheetEntryActivityGuid,
            activityName,
            isPayable,
          })
        }

        onSubmit?.()
        onClose()
      } catch (e) {
        console.error(e)
        message.error(`Failed to ${mode} timesheet entry`)
      }
    },
    [
      tzId,
      startOfPayPeriod,
      endOfPayPeriod,
      entriesForPayPeriod,
      mode,
      onSubmit,
      onClose,
      upsert,
      timesheetEntryGuid,
      companyGuid,
      userGuid,
      principleUserGuid,
      timesheetEntryActivityGuid,
      update,
    ],
  )

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="column gap-y-4"
    >
      {isLoading && <LoadingSpinner spinnerClassName="h-[258px] my-0 py-0" />}
      {!isLoading && (
        <div>
          <div className="row no-flex-wrap two-sides flex">
            <div className="center-children-v flex w-[46%]">
              <UserPersonResourceView userGuid={userGuid} />
            </div>
            <div className="column w-[46%]">
              <Form.Item
                name="localDate"
                required
                label="Entry Date"
                rules={[{ validator: validatorNotFalsy('Entry Date') }]}
              >
                <DatePicker
                  format="MMMM D, YYYY"
                  allowClear={false}
                  className="w-full"
                  disabledDate={date => {
                    const selectedDate = BzDateFns.parseISO(
                      BzDateFns.formatISO(date, tzId),
                      tzId,
                    )
                    return (
                      BzDateFns.isBefore(selectedDate, startOfPayPeriod) ||
                      BzDateFns.isAfter(selectedDate, endOfPayPeriod)
                    )
                  }}
                />
              </Form.Item>
            </div>
          </div>
          <div className="row no-flex-wrap two-sides flex">
            <div className="column w-[46%]">
              <Form.Item
                name="startTimeOfDay"
                required
                label="Start Time"
                rules={[{ validator: validatorNotFalsy('Start Time') }]}
              >
                <BzTimePicker
                  format="h:mm a"
                  allowClear={false}
                  className="w-full"
                />
              </Form.Item>
            </div>
            <div className="column w-[46%]">
              <Form.Item
                name="endTimeOfDay"
                required
                label="End Time"
                rules={[{ validator: validatorNotFalsy('End Time') }]}
              >
                <BzTimePicker
                  format="h:mm a"
                  allowClear={false}
                  className="w-full"
                />
              </Form.Item>
            </div>
          </div>
          <div className="row no-flex-wrap two-sides flex">
            <div className="w-[46%]">
              <Form.Item
                name="activityType"
                required
                label="Activity Type"
                rules={[{ validator: validatorNotFalsy('Activity Type') }]}
              >
                <Select
                  placeholder="Select Activity"
                  onChange={onActivityNameSelected}
                  className="w-full"
                >
                  {TIMESHEET_ENTRY_ACTIVITY_NAMES.map(a => (
                    <Select.Option key={a} value={a}>
                      {timesheetEntryActivityDisplayName(a)}
                    </Select.Option>
                  ))}
                  <Select.Option key="CUSTOM" value="CUSTOM">
                    Custom
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="row no-flex-wrap two-sides flex">
            <div className="w-[46%]">
              <Form.Item
                name="customActivityName"
                label="Activity Name"
                hidden={!useCustomActivityName}
                className="w-full"
                required={useCustomActivityName}
                rules={[
                  {
                    validator: useCustomActivityName
                      ? validatorNotFalsy('Activity Name')
                      : undefined,
                  },
                ]}
              >
                <Input type="text" />
              </Form.Item>
            </div>
            <div className="w-[46%]">
              <Form.Item
                name="isPayable"
                label="Payable"
                hidden={!useCustomActivityName}
                className="w-full"
                required={useCustomActivityName}
              >
                <Select placeholder="Select Payable" className="w-full">
                  <Select.Option key="true" value={true}>
                    Payable
                  </Select.Option>
                  <Select.Option key="false" value={false}>
                    Non-Payable
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
          </div>
        </div>
      )}

      <OnsiteModalFooter
        className="mt-6"
        onCancel={onClose}
        submitIsLoading={isLoading}
      />
    </Form>
  )
}

type TimesheetAddEditModalProps = {
  item?: TimesheetAddEditFormProps
}

export const TimesheetAddEditModal = m<TimesheetAddEditModalProps>(
  ({ item }) => {
    const onClose = useCallback(() => item?.onCancel() ?? noOp(), [item])

    return (
      <OnsiteModal open={!!item} onClose={onClose}>
        {item && (
          <OnsiteModalContent header="Add Timesheet Entry" onClose={onClose}>
            <div className="column">
              <TimesheetAddEditForm {...item} />
            </div>
          </OnsiteModalContent>
        )}
      </OnsiteModal>
    )
  },
)
