import { BasicBrandedEmailTemplate } from '@breezy/backend/src/email'
import { BasicBrandedEmailData, Email } from '@breezy/shared'
import { memo, useMemo } from 'react'
import { useQuery } from 'urql'
import xss, { getDefaultWhiteList } from 'xss'
import { OnsiteBasicModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { BILLING_PROFILE_FOR_BASIC_BRANDED_EMAIL_PREVIEW_QUERY } from './DripMarketingSettingsPage.gql'

interface Props {
  open: boolean
  onClose: () => void
  bodyContentHtml: string
}

export const BasicBrandedEmailPreviewModal = memo<Props>(
  ({ open, onClose, bodyContentHtml }) => {
    const companyGuid = useExpectedCompanyGuid()

    const billingProfileQuery = useQuery({
      query: BILLING_PROFILE_FOR_BASIC_BRANDED_EMAIL_PREVIEW_QUERY,
      variables: {
        companyGuid,
      },
    })

    const [{ fetching, data }] = billingProfileQuery

    const emailContentHtml = useMemo(() => {
      if (fetching) {
        return ''
      }

      if (data && data.companiesByPk && data.billingProfilesByPk) {
        const basicBrandedEmailMetadata: BasicBrandedEmailData = {
          companyLogoUrl: data.billingProfilesByPk.logoUrl,
          companyName: data.companiesByPk.name,
          contactPhoneNumber: data.billingProfilesByPk.phoneNumber.phoneNumber,
          contactEmailAddress:
            data.billingProfilesByPk.emailAddress.emailAddress,
          websiteUrl: data.billingProfilesByPk.websiteUrl,
          websiteDisplayName: data.billingProfilesByPk.websiteDisplayName,
        }

        const defaultXssWhiteList = getDefaultWhiteList()

        const email: Email = {
          to: '',
          subject: '',
          message: xss(bodyContentHtml, {
            // TODO Is allowing inline styles here safe?
            whiteList: {
              ...defaultXssWhiteList,
              p: defaultXssWhiteList.p?.concat(['style']),
              a: defaultXssWhiteList.a?.concat(['style']),
            },
          }),
          messageMetadata: basicBrandedEmailMetadata,
        }

        return BasicBrandedEmailTemplate(email)
      }

      return ''
    }, [bodyContentHtml, data, fetching])

    return (
      <OnsiteBasicModal open={open} onClose={onClose} size="large">
        <div className="pointer-events-none min-h-[230px] cursor-not-allowed outline-none">
          <GqlQueryLoader
            query={billingProfileQuery}
            render={_ => {
              return (
                <div dangerouslySetInnerHTML={{ __html: emailContentHtml }} />
              )
            }}
          />
        </div>
      </OnsiteBasicModal>
    )
  },
)
