import { isNullish } from '@breezy/shared'
import { Button } from 'antd'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useChangeJobTypeModalContext } from './ChangeJobTypeModalContext'
import { ChangeJobTypeFormSchema } from './change-job-type-form-schema'

type ModalFooterButtonConfig = { label: string; onClick: () => void }

export const ChangeJobTypeModalFooter = () => {
  const {
    formSteps,
    currFormStep: step,
    setCurrFormStep: setStep,
    onSubmit,
    onClose,
  } = useChangeJobTypeModalContext()

  const { trigger } = useFormContext<ChangeJobTypeFormSchema>()

  const backBtnConfig: ModalFooterButtonConfig = useMemo(() => {
    switch (step.name) {
      case 'newJobType':
        return { label: 'Cancel', onClick: onClose }
      case 'reasonForChange':
        return {
          label: 'Back',
          onClick: () => {
            if (isNullish(step.prev)) {
              console.error(
                'ChangeJobTypeModal: No previous step set for reasonForChange',
              )
              return
            }

            setStep(formSteps[step.prev])
          },
        }
      default:
        return { label: 'Cancel', onClick: onClose }
    }
  }, [formSteps, onClose, setStep, step.name, step.prev])

  const nextBtnConfig: ModalFooterButtonConfig = useMemo(() => {
    switch (step.name) {
      case 'newJobType':
        return {
          label: 'Next',
          onClick: async () => {
            if (isNullish(step.next)) {
              console.error(
                'ChangeJobTypeModal: No next step set for newJobType',
              )
              return
            }

            const isValidated = await trigger(
              [
                'jobTypeGuid',
                'preexistingEquipment',
                'newEquipment',
                'summary',
                'jobLifecycle',
              ],
              { shouldFocus: true },
            )

            if (isValidated) {
              setStep(formSteps[step.next])
            }
          },
        }
      case 'reasonForChange':
        return {
          label: 'Finish',
          onClick: () => {
            onSubmit()
          },
        }
      default:
        return { label: 'Cancel', onClick: onClose }
    }
  }, [formSteps, onClose, onSubmit, setStep, step.name, step.next, trigger])

  return (
    <div className="flex flex-row gap-3">
      <Button className="grow" onClick={backBtnConfig.onClick}>
        {backBtnConfig.label}
      </Button>

      <Button className="grow" type="primary" onClick={nextBtnConfig.onClick}>
        {nextBtnConfig.label}
      </Button>
    </div>
  )
}
