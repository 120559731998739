import {
  AssignmentDTO,
  debounce,
  Guid,
  nextGuid,
  R,
  TimeWindowDto,
} from '@breezy/shared'
import { useCallback, useMemo } from 'react'
import { useMutation } from 'urql'
import { TechnicianCapacityBlocksInsertInput } from '../../../generated/user/graphql'
import { trpc } from '../../../hooks/trpc'
import { useExpectedCompanyGuid } from '../../../providers/PrincipalUser'
import { useMessage } from '../../../utils/antd-utils'
import { useSchedulePendingChanges } from '../PendingChanges/SchedulePendingChangesContext'
import {
  hasPendingChanges,
  isBlockChange,
  PendingScheduleChanges,
} from '../PendingChanges/pendingChanges'
import {
  APPLY_SCHEDULE_CHANGES_MUTATION,
  ScheduleAssignment,
} from '../Schedule.gql'
import { isNewBlockEvent } from '../scheduleUtils'

const DEBOUNCED_SAVED_MESSAGE_MS = 500
type AppointmentGuid = string

export const useCommitScheduleChanges = () => {
  const message = useMessage()
  const companyGuid = useExpectedCompanyGuid()

  const {
    pendingChanges,
    originalAssignmentMap,
    originalInternalEventMap,
    appointmentMap: originalAppointmentMap,
    setSubscriptionsPaused,
  } = useSchedulePendingChanges()

  const appointmentMap = useMemo(() => {
    const map: Record<
      AppointmentGuid,
      {
        jobGuid: Guid
        assignments: ScheduleAssignment[]
        timeWindow: TimeWindowDto
      } & ScheduleAssignment['appointment']
    > = {}
    for (const assignment of R.values(originalAssignmentMap)) {
      const val = map[assignment.appointmentGuid] ?? {
        jobGuid: assignment.appointment.job.jobGuid,
        assignments: [],
        timeWindow: {
          start: assignment.appointment.appointmentWindowStart,
          end: assignment.appointment.appointmentWindowEnd,
        },
        ...assignment.appointment,
      }
      val.assignments.push(assignment)
      map[assignment.appointmentGuid] = val
    }
    for (const appointment of R.values(originalAppointmentMap)) {
      if (!appointment) {
        continue
      }
      map[appointment.appointmentGuid] = map[appointment.appointmentGuid] ?? {
        jobGuid: appointment.job.jobGuid,
        assignments: [],
        timeWindow: {
          start: appointment.appointmentWindowStart,
          end: appointment.appointmentWindowEnd,
        },
        ...appointment,
      }
    }
    return map
  }, [originalAppointmentMap, originalAssignmentMap])

  const [, applyScheduleChanges] = useMutation(APPLY_SCHEDULE_CHANGES_MUTATION)

  const upsertAppointmentMutation =
    trpc.appointments['appointment-and-assignments:upsert'].useMutation()

  const debouncedSaveMessage = useMemo(
    () =>
      debounce(
        () => message.success('Schedule saved.'),
        DEBOUNCED_SAVED_MESSAGE_MS,
      ),
    [message],
  )

  const hasChanges = useMemo(
    () => hasPendingChanges(pendingChanges),
    [pendingChanges],
  )

  // TODO: This is a beast of a function. We should break this up so it's easier to maintain.
  return useCallback(async () => {
    if (!hasChanges) {
      return
    }
    // Since this thing is going to Promise.all a bunch of calls to `appointment-and-assignments:upsert`, we want to
    // pause the subscriptions so we don't DDoS ourselves.
    setSubscriptionsPaused(true)

    const appointmentUpsertPromises: Promise<unknown>[] = []

    const blocksToUpsert: TechnicianCapacityBlocksInsertInput[] = []
    const deletedBlockGuids: string[] = []
    const canceledAppointmentGuids: string[] = []

    let changesForAppointmentMap: Record<
      AppointmentGuid,
      PendingScheduleChanges
    > = {}

    for (const newEvent of R.values(pendingChanges.newEventMap)) {
      if (isNewBlockEvent(newEvent)) {
        blocksToUpsert.push({
          companyGuid,
          technicianCapacityBlockGuid: newEvent.blockGuid,
          start: newEvent.start,
          end: newEvent.end,
          reasonDescription: newEvent.reasonDescription,
          reasonType: newEvent.reasonType,
          recurrenceRule: newEvent.recurrenceRule,
          userGuids: newEvent.userGuids,
        })
      } else {
        changesForAppointmentMap = R.assocPath(
          [newEvent.appointmentGuid, 'newEventMap', newEvent.assignmentGuid],
          newEvent,
          changesForAppointmentMap,
        )
      }
    }

    for (const change of R.values(pendingChanges.eventChangeMap)) {
      if (!change) {
        continue
      }
      if (isBlockChange(change)) {
        const block = originalInternalEventMap[change.blockGuid]
        if (block) {
          // If we just yeet the values into Hasura, it will complain that we're including values that don't belong to the
          // insert type. So we have to filter out keys that aren't these.
          blocksToUpsert.push(
            R.pick(
              [
                'technicianCapacityBlockGuid',
                'companyGuid',
                'userGuids',
                'start',
                'end',
                'reasonType',
                'reasonDescription',
                'recurrenceRule',
                'recurrenceRuleExceptions',
              ],
              {
                ...block,
                ...change,
                companyGuid,
                userGuids: change.userGuids,
                start: change.start,
                end: change.end,
                reasonType: change.reasonType,
                reasonDescription: change.reasonDescription,
                recurrenceRule: change.recurrenceRule,
                recurrenceRuleExceptions: change.recurrenceRuleExceptions,
              },
            ),
          )
        }
      } else {
        const assignment = originalAssignmentMap[change.assignmentGuid]
        if (change && assignment) {
          changesForAppointmentMap = R.assocPath(
            [change.appointmentGuid, 'eventChangeMap', change.assignmentGuid],
            change,
            changesForAppointmentMap,
          )
        } else {
          console.error("Couldn't find assignment for change", change)
          // Shouldn't be possible
          message.error('Something went wrong.')
        }
      }
    }

    for (const deletedGuid of R.keys(pendingChanges.deletedEventMap)) {
      const metadata = pendingChanges.deletedEventMap[deletedGuid]
      if (metadata.blockGuid) {
        deletedBlockGuids.push(metadata.blockGuid)
      } else if (metadata.appointmentGuid && metadata.isCancellingAppointment) {
        canceledAppointmentGuids.push(metadata.appointmentGuid)
      } else {
        changesForAppointmentMap = R.assocPath(
          [metadata.appointmentGuid ?? '', 'deletedEventMap', deletedGuid],
          metadata,
          changesForAppointmentMap,
        )
      }
    }

    for (const appointmentGuid of R.keys(
      pendingChanges.arrivalWindowChangeMap,
    )) {
      changesForAppointmentMap = R.assocPath(
        [appointmentGuid, 'arrivalWindowChangeMap', appointmentGuid],
        pendingChanges.arrivalWindowChangeMap[appointmentGuid],
        changesForAppointmentMap,
      )
    }

    for (const appointmentGuid of R.keys(changesForAppointmentMap)) {
      const appointment = appointmentMap[appointmentGuid]

      if (appointment) {
        const changes = changesForAppointmentMap[appointmentGuid]
        const newAssignments: AssignmentDTO[] = []
        for (const assignment of appointment.assignments) {
          if (changes.deletedEventMap?.[assignment.assignmentGuid]) {
            continue
          }
          const change = changes.eventChangeMap?.[assignment.assignmentGuid]
          if (change) {
            for (const user of change.userGuids) {
              newAssignments.push({
                assignmentGuid: assignment.assignmentGuid,
                assignmentStatus:
                  assignment.assignmentStatus?.status ?? 'TO_DO',
                // NOTE: We assume that an appointment only has one user. This may change in the future.
                technicianUserGuid: user,
                timeWindow: {
                  start: change.start,
                  end: change.end,
                },
              })
            }
          } else {
            newAssignments.push({
              assignmentGuid: assignment.assignmentGuid,
              assignmentStatus: assignment.assignmentStatus?.status ?? 'TO_DO',
              technicianUserGuid: assignment.technicianUserGuid,
              timeWindow: {
                start: assignment.start,
                end: assignment.end,
              },
            })
          }
        }
        for (const newEvent of R.values(
          changesForAppointmentMap[appointmentGuid].newEventMap,
        )) {
          if (!isNewBlockEvent(newEvent)) {
            for (const user of newEvent.userGuids) {
              newAssignments.push({
                assignmentGuid: nextGuid(),
                assignmentStatus: newEvent.assignmentStatus,
                technicianUserGuid: user,
                timeWindow: {
                  start: newEvent.start,
                  end: newEvent.end,
                },
              })
            }
          }
        }

        appointmentUpsertPromises.push(
          upsertAppointmentMutation.mutateAsync({
            appointmentGuid,
            jobGuid: appointment.jobGuid,
            arrivalWindow:
              changesForAppointmentMap[appointmentGuid]
                ?.arrivalWindowChangeMap?.[appointmentGuid] ??
              appointment.timeWindow,
            appointmentType: appointment.appointmentType,
            description: appointment.description,
            assignments: newAssignments,
            // TODO: this might be the way to have it send notifications when this changes.
            suppressAccountNotifications: true,
          }),
        )
      } else {
        console.error(
          "Couldn't find appointment from appointment change map",
          changesForAppointmentMap[appointmentGuid],
        )
        // Shouldn't be possible
        message.error('Something went wrong.')
      }
    }

    // TODO: we could have an endpoint that took an array of changes
    await Promise.all([
      ...appointmentUpsertPromises,
      applyScheduleChanges({
        blocks: blocksToUpsert,
        deletedBlockGuids,
        canceledAppointmentGuids,
      }),
    ])

    setSubscriptionsPaused(false)

    debouncedSaveMessage()
  }, [
    hasChanges,
    setSubscriptionsPaused,
    applyScheduleChanges,
    debouncedSaveMessage,
    pendingChanges.newEventMap,
    pendingChanges.eventChangeMap,
    pendingChanges.deletedEventMap,
    pendingChanges.arrivalWindowChangeMap,
    companyGuid,
    originalInternalEventMap,
    originalAssignmentMap,
    message,
    appointmentMap,
    upsertAppointmentMutation,
  ])
}
