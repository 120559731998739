import { isNullish } from '@breezy/shared'
import { Button } from 'antd'
import BzDrawer from 'src/elements/BzDrawer/BzDrawer'

export type JobTypeUnarchiveDrawerProps = {
  open: boolean
  jobType: {
    name: string
    jobTypeGuid: string
  } | null
  isLoading: boolean
  onJobTypeUnarchiveClicked: (jobTypeGuid: string) => void
  onCancel: () => void
}

export const JobTypeUnarchiveDrawer = ({
  open,
  jobType,
  isLoading,
  onJobTypeUnarchiveClicked,
  onCancel,
}: JobTypeUnarchiveDrawerProps) => {
  return (
    <BzDrawer
      preferredWidth={720}
      title={'Unarchive Job Type'}
      item={open ? { onCancel } : undefined}
      footer={
        <div className="flex flex-row gap-3">
          <Button className="ml-auto" onClick={onCancel} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={
              isNullish(jobType)
                ? undefined
                : () => onJobTypeUnarchiveClicked(jobType.jobTypeGuid)
            }
            disabled={isNullish(jobType) || isLoading}
          >
            {isLoading ? 'Unarchiving...' : 'Unarchive Job Type'}
          </Button>
        </div>
      }
    >
      <div className="flex flex-col">
        {isNullish(jobType) ? (
          <span>No job type selected to unarchive!</span>
        ) : (
          <>
            <h2 className="m-0 mb-3 font-semibold text-bz-gray-900">
              Are you sure you want to unarchive the job type "{jobType.name}"?
            </h2>
            <p className="mb-6 text-bz-gray-900">
              By unarchiving this job type, it will be available again to select
              when creating new jobs. You can always archive the job type in the
              future if needed.
            </p>
          </>
        )}
      </div>
    </BzDrawer>
  )
}
