import { formatJobClassShort, JobClass } from '@breezy/shared'
import {
  faCircleQuestion,
  faCircleStop,
  faScrewdriverWrench,
  faShield,
  faWrench,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons'
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons'
import {
  dayBreakBlue1,
  dayBreakBlue6,
  dayBreakBlue8,
  dustRed1,
  dustRed6,
  dustRed8,
  goldenPurple1,
  goldenPurple6,
  goldenPurple8,
  polarGreen1,
  polarGreen6,
  polarGreen8,
  sunriseYellow1,
  sunriseYellow7,
  sunriseYellow8,
  sunsetOrange1,
  sunsetOrange6,
  sunsetOrange8,
} from '../../../themes/theme'

export const getStyles = (styleKey: JobClass | 'Blocked'): JobClassStyling => {
  return styles[styleKey]
}

type JobClassStyling = {
  readonly title: string
  readonly icon: IconDefinition
  readonly showAvatars: boolean

  readonly color: string
  readonly borderColor: string
  readonly backgroundColor: string
}

const estimateJobStyle = {
  color: polarGreen8,
  borderColor: polarGreen6,
  backgroundColor: polarGreen1,
  icon: faCircleQuestion,
  showAvatars: false,
}

const styles = {
  [JobClass.INSTALL]: {
    title: formatJobClassShort(JobClass.INSTALL),
    color: dayBreakBlue8,
    borderColor: dayBreakBlue6,
    backgroundColor: dayBreakBlue1,
    icon: faScrewdriverWrench,
    showAvatars: true,
  },
  [JobClass.ESTIMATE_REPAIR]: {
    ...estimateJobStyle,
    title: formatJobClassShort(JobClass.ESTIMATE_REPAIR),
  },
  [JobClass.ESTIMATE_REPLACE]: {
    ...estimateJobStyle,
    title: formatJobClassShort(JobClass.ESTIMATE_REPLACE),
  },
  [JobClass.MAINTENANCE]: {
    title: formatJobClassShort(JobClass.MAINTENANCE),
    color: sunriseYellow8,
    borderColor: sunriseYellow7,
    backgroundColor: sunriseYellow1,
    icon: faWrench,
    showAvatars: false,
  },
  [JobClass.SERVICE]: {
    title: formatJobClassShort(JobClass.SERVICE),
    color: sunsetOrange8,
    borderColor: sunsetOrange6,
    backgroundColor: sunsetOrange1,
    icon: faWrench,
    showAvatars: false,
  },
  [JobClass.WARRANTY]: {
    title: formatJobClassShort(JobClass.WARRANTY),
    color: goldenPurple8,
    borderColor: goldenPurple6,
    backgroundColor: goldenPurple1,
    icon: faShield,
    showAvatars: false,
  },
  [JobClass.CALLBACK]: {
    title: formatJobClassShort(JobClass.CALLBACK),
    color: goldenPurple8,
    borderColor: goldenPurple6,
    backgroundColor: goldenPurple1,
    icon: faShield,
    showAvatars: false,
  },
  [JobClass.UNKNOWN]: {
    title: formatJobClassShort(JobClass.UNKNOWN),
    color: goldenPurple8,
    borderColor: goldenPurple6,
    backgroundColor: goldenPurple1,
    icon: faShield,
    showAvatars: false,
  },
  [JobClass.SALES]: {
    title: formatJobClassShort(JobClass.SALES),
    color: goldenPurple8,
    borderColor: goldenPurple6,
    backgroundColor: goldenPurple1,
    icon: faDollarSign,
    showAvatars: false,
  },
  Blocked: {
    title: 'Blocked',
    color: dustRed8,
    borderColor: dustRed6,
    backgroundColor: dustRed1,
    icon: faCircleStop,
    showAvatars: false,
  },
} satisfies Record<JobClass | 'Blocked', unknown>
