import { faFilter } from '@fortawesome/pro-solid-svg-icons'
import { Button, Col, Popover, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'
import FaIconButton from '../../elements/FaIconButton/FaIconButton'
import ThinDivider from '../../elements/ThinDivider'
import { blue6, gray6 } from '../../themes/theme'

type LocalStorageMultiSelectFilterButtonProps<T extends string> = {
  title: string
  options: readonly T[]
  displayNameSelector: (value: T) => string
  localStorageKey: string
  onChanged: (values: Set<T> | undefined) => void
  rightAlign?: boolean
}

function LocalStorageMultiSelectFilterButton<T extends string>({
  onChanged,
  localStorageKey,
  options,
  title,
  displayNameSelector,
  rightAlign,
}: LocalStorageMultiSelectFilterButtonProps<T>) {
  const [value, setValue] = useLocalStorage<T[] | undefined>(
    localStorageKey,
    undefined,
  )
  const [open, setIsOpen] = useState<boolean>(false)

  // Bug: BZ-1267 - Cannot remove last item using the "X" button on it in the Select
  const handleChange = (values: T[]) => {
    if (values.length === 0) {
      setValue(undefined)
    } else {
      setValue(values)
    }
  }

  useEffect(
    () => onChanged(value && value.length > 0 ? new Set(value) : undefined),
    [value, onChanged],
  )

  return (
    <Popover
      content={
        <Col>
          <Select
            className="ml-2 w-[200px]"
            mode="multiple"
            value={value ? Array.from(value) : []}
            onChange={handleChange}
          >
            {options.map((v, i) => (
              <Select.Option value={v} key={`${v}_${i}`}>
                {displayNameSelector(v)}
              </Select.Option>
            ))}
          </Select>
          <ThinDivider />
          <div className="row two-sides flex">
            <Button
              size="small"
              onClick={() => {
                setValue([])
                setIsOpen(false)
              }}
            >
              Clear
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={() => setIsOpen(false)}
            >
              Ok
            </Button>
          </div>
        </Col>
      }
      title={title}
      trigger="click"
      placement="bottomRight"
      open={open}
    >
      <FaIconButton
        icon={faFilter}
        onClick={() => setIsOpen(!open)}
        style={{
          marginLeft: rightAlign ? 'auto' : undefined,
          color: value && value.length > 0 ? blue6 : gray6,
        }}
      />
    </Popover>
  )
}

export default LocalStorageMultiSelectFilterButton
