import { BzTimeWindow, tryParseEndOfAppointmentNextSteps } from '@breezy/shared'
import React, { useCallback, useMemo } from 'react'
import {
  EditComprehensiveAppointmentDetails,
  UpsertAppointmentForm,
} from '../../components/NewAppointmentModal/UpsertAppointmentForm/UpsertAppointmentForm'
import BzDrawer from '../../elements/BzDrawer/BzDrawer'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { useHidableData, useStrictContext } from '../../utils/react-utils'
import {
  FullScheduleAppointment,
  getInferredAppointmentStatusFromAssignments,
  SchedulePageContext,
} from './scheduleUtils'

type EditAppointmentDrawerProps = {
  appointment?: FullScheduleAppointment
}

export const EditAppointmentDrawer = React.memo<EditAppointmentDrawerProps>(
  ({ appointment }) => {
    const [data, isOpen, key] = useHidableData(appointment)

    const { setEditingAppointmentGuid } = useStrictContext(SchedulePageContext)
    const clearEditingAppointmentGuid = useCallback(
      () => setEditingAppointmentGuid(''),
      [setEditingAppointmentGuid],
    )

    const drawerItem = useMemo(
      () =>
        isOpen
          ? {
              onCancel: clearEditingAppointmentGuid,
            }
          : undefined,
      [clearEditingAppointmentGuid, isOpen],
    )
    return (
      <BzDrawer
        title="Edit Visit"
        item={drawerItem}
        preferredWidth={666}
        bodyStyles={{ paddingBottom: 0 }}
      >
        {data ? (
          <EditAppointmentDrawerInner
            key={key}
            appointment={data}
            onClose={clearEditingAppointmentGuid}
          />
        ) : null}
      </BzDrawer>
    )
  },
)

type EditAppointmentDrawerInnerProps = {
  appointment: FullScheduleAppointment
  onClose: () => void
}

const EditAppointmentDrawerInner = React.memo<EditAppointmentDrawerInnerProps>(
  ({ appointment, onClose }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const { setSelectedAppointmentGuid } = useStrictContext(SchedulePageContext)

    const comprehensiveAppointment =
      useMemo<EditComprehensiveAppointmentDetails>(() => {
        const assignments = appointment.assignments ?? []
        return {
          timeWindow: BzTimeWindow.fromDto(
            {
              start: appointment.appointmentWindowStart,
              end: appointment.appointmentWindowEnd,
            },
            tzId,
          ),
          appointmentType: appointment.appointmentType,
          description: appointment.description,
          endOfAppointmentNextSteps: tryParseEndOfAppointmentNextSteps(
            appointment.endOfAppointmentNextSteps,
          ),
          appointmentStatus: getInferredAppointmentStatusFromAssignments(
            assignments,
            appointment.cancellationStatus?.canceled,
          ),
          assignments: assignments.map(
            ({ assignmentGuid, technicianUserGuid, start, end }) => ({
              assignmentGuid,
              technicianUserGuid,
              timeWindow: BzTimeWindow.fromDto({ start, end }, tzId),
            }),
          ),
        }
      }, [
        appointment.appointmentType,
        appointment.appointmentWindowEnd,
        appointment.appointmentWindowStart,
        appointment.assignments,
        appointment.cancellationStatus?.canceled,
        appointment.description,
        appointment.endOfAppointmentNextSteps,
        tzId,
      ])

    const onSuccess = useCallback(() => {
      setSelectedAppointmentGuid('')
      onClose()
    }, [onClose, setSelectedAppointmentGuid])
    return (
      <UpsertAppointmentForm
        mode="edit"
        onCancel={onClose}
        jobGuid={appointment.job.jobGuid}
        jobClass={appointment.job.jobType.jobClass}
        appointmentGuid={appointment.appointmentGuid}
        comprehensiveAppointment={comprehensiveAppointment}
        onAppointmentEdited={onSuccess}
      />
    )
  },
)
