import { ReactNode, memo } from 'react'

type TileGroupProps = React.PropsWithChildren<{
  title?: string
  extra?: ReactNode
  marginBottomClass?: string
}>

export const TileGroup = memo<TileGroupProps>(
  ({ title, extra, marginBottomClass = 'mb-6', children }) => (
    <div className="flex h-full flex-col">
      {(title || extra) && (
        <div
          className={`${marginBottomClass} flex items-center justify-between`}
        >
          <div className="font-semibold text-bz-gray-1000">{title}</div>
          {extra}
        </div>
      )}

      <div className="flex flex-1 flex-row space-x-6">{children}</div>
    </div>
  ),
)
