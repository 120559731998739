import { isNullish } from '@breezy/shared'

export const resizeImage = (input: {
  fileUrl: string
  maxWidth: number
  maxHeight: number
}): Promise<string> => {
  return new Promise((resolve, reject) => {
    const { fileUrl, maxWidth, maxHeight } = input

    const image = document.createElement('img')

    image.onload = () => {
      const ratio = Math.min(maxWidth / image.width, maxHeight / image.height)

      const newWidth = image.width * ratio
      const newHeight = image.height * ratio

      const canvas = document.createElement('canvas')

      canvas.width = newWidth
      canvas.height = newHeight

      const ctx = canvas.getContext('2d')

      if (isNullish(ctx)) {
        reject('Failed to resize image')
        return
      }

      ctx.drawImage(image, 0, 0, newWidth, newHeight)

      resolve(canvas.toDataURL('image/png'))
    }

    image.onerror = err => reject(err)

    image.src = fileUrl
  })
}
