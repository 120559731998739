import { Col } from 'antd'
import React from 'react'

export const FullWidthColumn = React.memo<React.PropsWithChildren>(
  ({ children }) => <Col xs={24}>{children}</Col>,
)
export const HalfWidthColumn = React.memo<React.PropsWithChildren>(
  ({ children }) => (
    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
      {children}
    </Col>
  ),
)
