import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Skeleton, Tooltip } from 'antd'
import classNames from 'classnames'
import React from 'react'

type SummaryStatBoxProps = React.PropsWithChildren<{
  label: string
  tooltipContent?: string
  bottomBarColorClasses?: string[]
  doubleWide?: boolean
  loading?: boolean
  secondaryContent?: React.ReactNode
}>

export const SummaryStatBox = React.memo<SummaryStatBoxProps>(
  ({
    doubleWide,
    label,
    bottomBarColorClasses = ['bg-bz-gray-400'],
    tooltipContent,
    children,
    loading,
    secondaryContent,
  }) => {
    return (
      <div
        className={classNames(
          'flex flex-col overflow-hidden rounded-lg border border-solid border-bz-gray-400 shadow-sm',
          doubleWide ? 'flex-[2]' : 'flex-1',
        )}
      >
        <div className="flex-1 px-4 py-3">
          <div className="mb-3 flex flex-row items-center text-base">
            <div className="font-semibold">{label}</div>
            {tooltipContent ? (
              <Tooltip title={tooltipContent}>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="ml-2 text-bz-gray-700"
                />
              </Tooltip>
            ) : null}
          </div>
          <div className="flex flex-row items-center justify-between space-x-4 text-2xl font-semibold">
            {loading ? <Skeleton.Button active block /> : <div>{children}</div>}
            {secondaryContent && loading ? (
              <Skeleton.Button active />
            ) : (
              <div className="text-bz-gray-700">{secondaryContent}</div>
            )}
          </div>
        </div>
        <div className="flex flex-row space-x-[1px]">
          {bottomBarColorClasses.map((colorClassName, i) => (
            <div
              key={`${colorClassName}_${i}`}
              className={classNames('h-1 flex-1 ', colorClassName)}
            />
          ))}
        </div>
      </div>
    )
  },
)

type SummaryStatsContainerProps = React.PropsWithChildren<{
  className?: string
}>

export const SummaryStatsContainer = React.memo<SummaryStatsContainerProps>(
  ({ children, className }) => {
    return (
      <div
        className={classNames(
          'z-20 mb-4 flex flex-row space-x-3 px-6',
          className,
        )}
      >
        {children}
      </div>
    )
  },
)
