import { R } from '@breezy/shared'
import classNames from 'classnames'
import React, {
  Children,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { DetailChip } from '../../adam-components/ListPage/DetailChip'
import { useIsLargeScreen } from '../../hooks/useIsMobile'

type TagRowProps = React.PropsWithChildren & {
  chipClassName?: string
}

const MAX_TAG_ROW_WIDTH_LARGE_SCREEN = 400
const MAX_TAG_ROW_WIDTH_SMALL_SCREEN = 200

// TODO: can we use this on the estimates listing page too?
// TODO: test some edge cases, like when you have one element that's too big so we only have the + box
export const TagRow = React.memo<TagRowProps>(({ children, chipClassName }) => {
  const isLargeScreen = useIsLargeScreen()

  const maxWidth = isLargeScreen
    ? MAX_TAG_ROW_WIDTH_LARGE_SCREEN
    : MAX_TAG_ROW_WIDTH_SMALL_SCREEN

  const containerRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLDivElement>(null)

  const [numHiddenElements, setNumHiddenElements] = useState(0)
  const [computingNumHidden, setComputingNumHidden] = useState(true)

  const childArray = Children.toArray(children)

  const numShownElements = childArray.length - numHiddenElements

  useEffect(() => {
    setNumHiddenElements(0)
    setComputingNumHidden(true)
  }, [children])

  useLayoutEffect(() => {
    if (numShownElements <= 0) {
      return
    }
    const containerWidth =
      containerRef.current?.getBoundingClientRect().width ?? 0
    const listWidth = listRef.current?.getBoundingClientRect().width ?? 0

    if (listWidth > containerWidth) {
      setNumHiddenElements(R.inc)
    } else {
      setComputingNumHidden(false)
    }
  }, [childArray, numShownElements])

  return (
    <div
      ref={containerRef}
      style={{ maxWidth: `${maxWidth}px` }}
      className={classNames('overflow-hidden', {
        'min-h-8': childArray.length > 0,
      })}
    >
      <div
        ref={listRef}
        className={classNames('flex w-max flex-row space-x-2', {
          hidden: computingNumHidden,
        })}
      >
        {childArray.slice(0, numShownElements)}
        {numHiddenElements > 0 && (
          <DetailChip
            className={chipClassName}
            square
            popoverContent={
              <div>
                <div className="flex flex-row space-x-2">
                  {childArray.slice(numShownElements)}
                </div>
              </div>
            }
          >
            +{numHiddenElements}
          </DetailChip>
        )}
      </div>
    </div>
  )
})
