import { TimeZoneId, isNullish } from '@breezy/shared'
import { Button, Checkbox, Select } from 'antd'
import classNames from 'classnames'
import { useCallback, useContext, useMemo } from 'react'
import { useQuery } from 'urql'
import GqlQueryLoader from '../../../components/GqlQueryLoader/GqlQueryLoader'
import { KanbanJob } from '../../../components/Kanban/kanbanUtils'
import BzDrawer from '../../../elements/BzDrawer/BzDrawer'
import { Job } from '../../../gql/queries/Jobs.gql'
import {
  FilterConfigGroupItem,
  FilterConfigOptionsItem,
  UseFilterOutput,
} from '../../../hooks/useFilter'
import { useExpectedCompany } from '../../../providers/PrincipalUser'
import { JobKanbanContext } from '../JobKanbanContext'
import { COMPANY_USERS_QUERY, JOBS_PAGE_ALL_TAGS_QUERY } from '../JobsPage.gql'
import { getAssignedTechniciansFromJobs } from '../util'

export const createTechnicianOptions = (
  kanbanJobs: KanbanJob[],
): { option: string; filter: (item: KanbanJob) => boolean }[] => {
  const allTechnicians = getAssignedTechniciansFromJobs(
    kanbanJobs.map(({ job }) => job),
  )

  const uniqueTechnicians: Map<
    string,
    Job['appointments'][number]['assignments'][number]['technician']
  > = new Map()
  for (let i = 0; i < allTechnicians.length; i++) {
    const technician = allTechnicians[i]
    if (uniqueTechnicians.has(technician.userGuid)) {
      continue
    }

    uniqueTechnicians.set(technician.userGuid, technician)
  }

  return [...uniqueTechnicians.values()]
    .sort((a, b) => a.firstName.localeCompare(b.firstName))
    .map(technician => ({
      option: `${technician.firstName} ${technician.lastName}`,
      filter: item =>
        item.job.appointments.some(appt =>
          appt.assignments.some(
            assignment =>
              assignment.technician.userGuid === technician.userGuid,
          ),
        ),
    }))
}

type JobLifecycleTagFiltersProps = {
  selectedTags: { label: string; value: string }[]
  onTagSelected: (tag: string) => void
  onTagDeselected: (tag: string) => void
}

const JobLifecycleTagFilters = ({
  selectedTags,
  onTagSelected,
  onTagDeselected,
}: JobLifecycleTagFiltersProps) => {
  const { companyGuid } = useExpectedCompany()
  const allTagsQuery = useQuery({
    query: JOBS_PAGE_ALL_TAGS_QUERY,
    variables: { companyGuid },
  })

  return (
    <GqlQueryLoader
      query={allTagsQuery}
      render={data => (
        <Select
          mode="multiple"
          placeholder="Select tags to filter by..."
          value={selectedTags.map(selectedTag => selectedTag.value)}
          options={data.tags.map(tag => ({
            label: tag.name,
            value: tag.name,
          }))}
          onSelect={onTagSelected}
          onDeselect={onTagDeselected}
        />
      )}
    />
  )
}

type JobLifecycleAccountManagerFiltersProps = {
  selectedAccountManagers: { label: string; value: string }[]
  onAccountManagerSelected: (accountManagerGuid: string) => void
  onAccountManagerDeselected: (accountManagerGuid: string) => void
}

const JobLifecycleAccountManagerFilters = ({
  selectedAccountManagers,
  onAccountManagerSelected,
  onAccountManagerDeselected,
}: JobLifecycleAccountManagerFiltersProps) => {
  const { companyGuid } = useExpectedCompany()
  const allTagsQuery = useQuery({
    query: COMPANY_USERS_QUERY,
    variables: { companyGuid },
  })

  return (
    <GqlQueryLoader
      query={allTagsQuery}
      render={data => (
        <Select
          mode="multiple"
          placeholder="Select tags to filter by..."
          value={selectedAccountManagers.map(
            selectedAccountManager => selectedAccountManager.value,
          )}
          options={data.companyUsers.map(companyUser => ({
            label: companyUser.userByUserGuid.fullName ?? 'Company User',
            value: companyUser.userByUserGuid.userGuid,
          }))}
          onSelect={onAccountManagerSelected}
          onDeselect={onAccountManagerDeselected}
          filterOption={(input, option) =>
            (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
          }
        />
      )}
    />
  )
}

type JobLifecycleFilterOptionsProps = {
  filter: FilterConfigOptionsItem<KanbanJob>
  hiddenFilterKeys?: string[]
  isFilterOptionChecked: (
    filters: UseFilterOutput<KanbanJob>['filters'],
    currFilterKey: string,
    currOption: string,
  ) => boolean
}

const JobLifecycleFilterOptions = ({
  filter,
  hiddenFilterKeys,
  isFilterOptionChecked,
}: JobLifecycleFilterOptionsProps) => {
  const { filtersV2, addFilter, removeFilter } = useContext(JobKanbanContext)

  if (filter.key === 'tags') {
    return (
      <div className="mb-6 flex flex-col space-y-1">
        <span className="mb-3 text-sm font-semibold text-bz-gray-900">
          {filter.label}
        </span>

        <JobLifecycleTagFilters
          selectedTags={filter.options
            .filter(option =>
              isFilterOptionChecked(filtersV2, filter.key, option.option),
            )
            .map(option => ({
              label: option.option,
              value: option.option,
            }))}
          onTagSelected={tag => addFilter(filter.key, tag)}
          onTagDeselected={tag => removeFilter(filter.key, tag)}
        />
      </div>
    )
  }

  if (filter.key === 'accountManagers') {
    if (
      !isNullish(hiddenFilterKeys) &&
      hiddenFilterKeys.some(filterKey => filterKey === filter.key)
    ) {
      return null
    }

    return (
      <div className="mb-6 flex flex-col space-y-1">
        <span className="mb-3 text-sm font-semibold text-bz-gray-900">
          {filter.label}
        </span>

        <JobLifecycleAccountManagerFilters
          selectedAccountManagers={filter.options
            .filter(option =>
              isFilterOptionChecked(filtersV2, filter.key, option.option),
            )
            .map(option => ({
              label: option.option,
              value: option.option,
            }))}
          onAccountManagerSelected={tag => addFilter(filter.key, tag)}
          onAccountManagerDeselected={tag => removeFilter(filter.key, tag)}
        />
      </div>
    )
  }

  return (
    <div className="mb-6 flex flex-col space-y-1">
      <span className="mb-3 text-sm font-semibold text-bz-gray-900">
        {filter.label}
      </span>

      <div className="grid grid-cols-2 items-center gap-x-3 gap-y-2 text-sm">
        {filter.options.map(option => {
          const isChecked = isFilterOptionChecked(
            filtersV2,
            filter.key,
            option.option,
          )

          return (
            <Checkbox
              key={`${filter.key}-${option.option}`}
              className="pl-0"
              checked={isChecked}
              onClick={() => {
                if (isChecked) {
                  removeFilter(filter.key, option.option)
                } else {
                  addFilter(filter.key, option.option)
                }
              }}
            >
              <span className="text-bz-gray-900">{option.option}</span>
            </Checkbox>
          )
        })}
      </div>
    </div>
  )
}

type JobLifecycleFilterDrawerV2InnerProps = {
  hiddenFilterKeys?: string[]
}

const JobLifecycleFilterDrawerV2Inner = ({
  hiddenFilterKeys,
}: JobLifecycleFilterDrawerV2InnerProps) => {
  const { filtersV2Config: filterConfig } = useContext(JobKanbanContext)

  const filterGroups = useMemo(() => {
    const groups = filterConfig.filter(
      curr => curr.type === 'group',
    ) as FilterConfigGroupItem[]
    const filters = filterConfig.filter(
      curr => curr.type === 'options',
    ) as FilterConfigOptionsItem<KanbanJob>[]

    const groupToFiltersMap: Map<
      string,
      { groupLabel: string; filters: FilterConfigOptionsItem<KanbanJob>[] }
    > = new Map()

    for (let i = 0; i < groups.length; i++) {
      const group = groups[i]
      for (let j = 0; j < group.children.length; j++) {
        const child = group.children[j]
        const filterIdx = filters.findIndex(curr => curr.key === child)
        if (filterIdx < 0) {
          continue
        }

        const currGroupToFilters = groupToFiltersMap.get(group.key)
        if (!currGroupToFilters) {
          groupToFiltersMap.set(group.key, {
            groupLabel: group.label,
            filters: [filters[filterIdx]],
          })
        } else {
          currGroupToFilters.filters.push(filters[filterIdx])
        }
      }
    }

    return Array.from(groupToFiltersMap.entries())
  }, [filterConfig])

  const isFilterOptionChecked = useCallback(
    (
      filters: UseFilterOutput<KanbanJob>['filters'],
      currFilterKey: string,
      currOption: string,
    ) => {
      const filterIdx = filters.findIndex(
        filter => filter.key === currFilterKey,
      )
      if (filterIdx < 0) {
        return false
      }

      return filters[filterIdx].optionsSelected.includes(currOption)
    },
    [],
  )

  return (
    <>
      {filterGroups.map(([group, { groupLabel, filters }], i) => (
        <div
          key={group}
          className={classNames(
            'm-0 flex flex-col border-0 border-b border-solid border-[#e2e2e2]',
            {
              // Space out the dividing border
              'pt-4': i !== 0,
            },
          )}
        >
          <span className="mb-4 text-base font-semibold text-bz-gray-900">
            {groupLabel}
          </span>

          {filters.map(filter => (
            <JobLifecycleFilterOptions
              key={filter.key}
              filter={filter}
              isFilterOptionChecked={isFilterOptionChecked}
              hiddenFilterKeys={hiddenFilterKeys}
            />
          ))}
        </div>
      ))}
    </>
  )
}

type JobLifecycleFilterDrawerV2Props = {
  open: boolean
  data: KanbanJob[]
  tzId: TimeZoneId
  hiddenFilterKeys?: string[]
  onClose: () => void
}

export const JobLifecycleFilterDrawerV2 = ({
  open,
  hiddenFilterKeys,
  onClose,
}: JobLifecycleFilterDrawerV2Props) => {
  const { clearFilters } = useContext(JobKanbanContext)
  return (
    <BzDrawer
      item={open ? { onCancel: onClose } : undefined}
      title={
        <div className="text-xl text-bz-gray-1000">Advanced Job Filters</div>
      }
      footer={
        <Button block type="link" size="large" onClick={() => clearFilters()}>
          Clear All Filters
        </Button>
      }
      preferredWidth={624}
    >
      <JobLifecycleFilterDrawerV2Inner hiddenFilterKeys={hiddenFilterKeys} />
    </BzDrawer>
  )
}
