import { PageHeader } from '@ant-design/pro-components'
import { faTags } from '@fortawesome/pro-light-svg-icons'
import GqlQueryLoader from 'src/components/GqlQueryLoader/GqlQueryLoader'
import { useQuery } from 'urql'
import { Page } from '../../components/Page/Page'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'
import { usePrincipalUser } from '../../providers/PrincipalUser'
import { TAGS_ALL_QUERY } from './Tags.gql'
import { TagsSettings } from './components'

export const TagsSettingsPage = () => {
  const companyUser = usePrincipalUser().expectCompanyUserPrincipal()

  const tagsQuery = useQuery({
    query: TAGS_ALL_QUERY,
    variables: {
      companyGuid: companyUser.company.companyGuid,
    },
  })

  return (
    <Page requiresCompanyUser className="flex flex-col p-0">
      <PageHeader title={<PageTitle title="Tags" icon={faTags} />} />
      <ScrollCard
        className="flex h-full w-full flex-col space-y-4"
        hasPageHeading
      >
        <GqlQueryLoader
          query={tagsQuery}
          render={tags => (
            <TagsSettings tags={tags.tags} refetchTags={tagsQuery[1]} />
          )}
        />
      </ScrollCard>
    </Page>
  )
}
