import { isNullish } from '@breezy/shared'
import { Alert, Button } from 'antd'
import BzDrawer from 'src/elements/BzDrawer/BzDrawer'

export type JobTypeArchiveDrawerProps = {
  open: boolean
  jobType: {
    name: string
    jobTypeGuid: string
  } | null
  isLoading: boolean
  onJobTypeArchiveClicked: (jobTypeGuid: string) => void
  onCancel: () => void
}

export const JobTypeArchiveDrawer = ({
  open,
  jobType,
  isLoading,
  onJobTypeArchiveClicked,
  onCancel,
}: JobTypeArchiveDrawerProps) => {
  return (
    <BzDrawer
      preferredWidth={720}
      title={'Archive Job Type'}
      item={open ? { onCancel } : undefined}
      footer={
        <div className="flex flex-row gap-3">
          <Button className="ml-auto" onClick={onCancel} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            type="primary"
            danger
            onClick={
              isNullish(jobType)
                ? undefined
                : () => onJobTypeArchiveClicked(jobType.jobTypeGuid)
            }
            disabled={isNullish(jobType) || isLoading}
          >
            {isLoading ? 'Archiving...' : 'Archive Job Type'}
          </Button>
        </div>
      }
    >
      <div className="flex flex-col">
        {isNullish(jobType) ? (
          <span>No job type selected to archive!</span>
        ) : (
          <>
            <h2 className="m-0 mb-3 font-semibold text-bz-gray-900">
              Are you sure you want to archive the job type "{jobType.name}"?
            </h2>
            <p className="mb-6 text-bz-gray-900">
              By archiving this job type, it will no longer be available when
              creating new jobs. You can always unarchive the job type in the
              future if needed.
            </p>
            <Alert
              type="warning"
              message={
                <p className="m-0 text-sm text-bz-gray-900">
                  <span className="font-semibold">
                    IMPORTANT: Any current jobs with this type will be
                    unchanged.
                  </span>{' '}
                  You can change the job type for any job in the job details
                  page.
                </p>
              }
            />
          </>
        )}
      </div>
    </BzDrawer>
  )
}
