import { PermissionV2 } from '@breezy/shared'
import { memo, useCallback } from 'react'
import { useMutation } from 'urql'
import { FeatureEnableView } from '../../components/FeatureEnableView/FeatureEnableView'
import { UPSERT_COMPANY_CONFIG_TECHNICIAN_PERFORMANCE_ENABLED_MUTATION } from '../../gql/CompanyConfig.gql'
import { useFetchTechnicianPerformanceConfig } from '../../hooks/fetch/useFetchTechnicianPerformanceConfig'
import { useIsAuthorizedTo } from '../../hooks/permission/useIsAuthorized'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'

export const TechnicianPerformanceEnableView = memo(() => {
  const companyGuid = useExpectedCompanyGuid()
  const isAuthorizedToManageTechnicianPerformance = useIsAuthorizedTo(
    PermissionV2.OFFICE_TECHNICIAN_PERFORMANCE_MANAGE,
  )

  const [technicianPerformanceConfig, refetchConfig] =
    useFetchTechnicianPerformanceConfig()

  const [{ fetching: mutating }, upsert] = useMutation(
    UPSERT_COMPANY_CONFIG_TECHNICIAN_PERFORMANCE_ENABLED_MUTATION,
  )

  const upsertTechnicianPerformanceEnabled = useCallback(
    async (enabled: boolean) => {
      await upsert({
        config: { companyGuid, technicianPerformanceEnabled: enabled },
      })
    },
    [companyGuid, upsert],
  )

  return (
    <FeatureEnableView
      featureTitle="Technician Performance"
      featureEnabled={
        technicianPerformanceConfig?.technicianPerformanceEnabled ?? false
      }
      switchDisabled={!isAuthorizedToManageTechnicianPerformance}
      isLoading={mutating || !technicianPerformanceConfig}
      setValue={upsertTechnicianPerformanceEnabled}
      refetch={refetchConfig}
    />
  )
})
