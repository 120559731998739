import { IsoDateString } from '@breezy/shared'
import { CommAvatar } from './CommAvatar'

export type CommContactCardData = {
  contactMethodId?: string
  avatarText?: string
  displayName: string
  lastContactAt: IsoDateString
  isUnread: boolean
  lastMessagePreview: string
  lastMessagePreviewIcon?: React.ReactNode
}

export type CommContactCardProps = CommContactCardData & {
  isSelected: boolean
  onClick: () => void
}

export const CommContactCard: React.FC<CommContactCardProps> = ({
  contactMethodId,
  avatarText,
  displayName,
  lastContactAt,
  isSelected,
  isUnread,
  lastMessagePreview,
  lastMessagePreviewIcon,
  onClick,
}) => {
  return (
    <div
      className={`space-between flex min-h-[54px] w-full cursor-pointer justify-between overflow-hidden rounded-lg p-2 hover:bg-gray-100 ${
        isSelected ? 'bg-gray-100' : ''
      }`}
      onClick={onClick}
    >
      <div className="space-between flex w-full flex-none gap-x-2">
        <CommAvatar avatarText={avatarText} isSelected={isSelected} />
        <div className="flex w-full flex-col">
          <div className="space-between mb-1 flex w-full items-center">
            <div className="text-sm font-semibold">{displayName}</div>
            <div className="flex-none text-xs text-gray-400">
              {new Date(lastContactAt).toDateString() ===
              new Date().toDateString()
                ? new Date(lastContactAt).toLocaleTimeString([], {
                    hour: 'numeric',
                    minute: 'numeric',
                  })
                : new Date(lastContactAt).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                  })}
            </div>
          </div>
          <div className="space-between flex w-full items-center">
            <div className="max-w-[272px] truncate text-xs text-gray-500">
              {lastMessagePreviewIcon}
              {lastMessagePreview}
            </div>
            {isUnread && (
              <span className="h-2 w-2 rounded-full bg-orange-500"></span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
