import {
  BzDateFns,
  ReportingDateRangeWithTimeHorizon,
  TimeZoneId,
} from '@breezy/shared'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Datepicker,
  Input,
  MbscDatepickerChangeEvent,
  MbscDateType,
  Popup,
} from '@mobiscroll/react'
import { Button, Select } from 'antd'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { typedMemo, useBooleanState } from '../../../utils/react-utils'
import { fixMbscDate } from '../../ScheduleV2Page/scheduleUtils'
import './ReportingDateRangePicker.less'
import { CommonReportingDateRangePickerProps } from './utils'

interface BaseReportingDateRangePickerProps<T extends string>
  extends CommonReportingDateRangePickerProps<T> {
  presets: readonly T[]
  getRangeFromPreset: (
    preset: T,
    tzId: TimeZoneId,
  ) => ReportingDateRangeWithTimeHorizon<T>
}

export const BaseReportingDateRangePicker = typedMemo(
  <T extends string>({
    setRange: setExternalRange,
    range: externalRange,
    disabled,
    presets,
    getRangeFromPreset,
  }: BaseReportingDateRangePickerProps<T>) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const [start, startRef] = useState<Input | null>(null)
    const [end, endRef] = useState<Input | null>(null)

    const [rangeRaw, setRangeRaw] = useState(externalRange)

    const range = useMemo(() => {
      const { timeHorizon, ...range } = rangeRaw
      if (rangeRaw.timeHorizon) {
        return getRangeFromPreset(rangeRaw.timeHorizon, tzId)
      }
      return range
    }, [getRangeFromPreset, rangeRaw, tzId])

    const [selectedCalendarRange, setSelectedCalendarRange] = useState<
      MbscDateType[]
    >(() => [range.start, range.end])

    const setRange = useCallback(
      (newRange: ReportingDateRangeWithTimeHorizon<T>) => {
        setRangeRaw(newRange)
        setSelectedCalendarRange([
          newRange.start,
          BzDateFns.withTimeZone(newRange.end, tzId, date =>
            BzDateFns.startOfDay(date),
          ),
        ])
      },
      [tzId],
    )

    const onReset = useCallback(
      () => setRange(externalRange),
      [externalRange, setRange],
    )

    useEffect(() => {
      onReset()
      // This only runs when the external range changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalRange])

    const inputValue = useMemo(
      () =>
        externalRange.timeHorizon ??
        `${BzDateFns.formatFromISO(
          externalRange.start,
          'MMM d, yyyy',
          tzId,
        )} — ${BzDateFns.formatFromISO(
          externalRange.end,
          'MMM d, yyyy',
          tzId,
        )}`,
      [externalRange.end, externalRange.start, externalRange.timeHorizon, tzId],
    )

    const onPresetSelect = useCallback(
      (preset: T) => {
        setRange(getRangeFromPreset(preset, tzId))
      },
      [getRangeFromPreset, setRange, tzId],
    )

    const onDateChange = useCallback(
      (ev: MbscDatepickerChangeEvent) => {
        const date = ev.value as MbscDateType[]

        setSelectedCalendarRange(date)

        if (!date[0] || !date[1]) {
          return
        }

        const [start, end] = date.map(date =>
          // The datepicker returns a "local date" string
          BzDateFns.parseISO(fixMbscDate(date), BzDateFns.UTC),
        )

        setRangeRaw({
          start: BzDateFns.formatISO(start, tzId),
          end: BzDateFns.formatISO(BzDateFns.endOfDay(end), tzId),
        })
      },
      [tzId],
    )

    const [isOpen, show, hide] = useBooleanState()

    const onApply = useCallback(() => {
      setExternalRange(range)
      hide()
    }, [hide, range, setExternalRange])

    const inputRef = useRef<HTMLDivElement>(null)

    return (
      <>
        <div
          ref={inputRef}
          className="reporting-date-range-picker inline text-sm"
        >
          <Select
            open={false}
            value={inputValue}
            className={classNames({ 'select-with-hover-state': !disabled })}
            bordered={false}
            onClick={show}
            disabled={disabled}
          />
        </div>
        <Popup
          isOpen={isOpen}
          theme="material"
          themeVariant="light"
          anchor={inputRef.current as HTMLDivElement | undefined}
          contentPadding={false}
          display="anchored"
          scrollLock={false}
          showOverlay={false}
          touchUi={false}
          maxWidth={900}
          onClose={hide}
          className="reporting-date-range-picker-popup"
        >
          <div className="flex flex-row">
            <div className="flex flex-col items-start space-y-2 border-0 border-r border-solid border-bz-border p-4">
              {presets.map(preset => (
                <Button
                  key={preset}
                  type="text"
                  onClick={() => onPresetSelect(preset)}
                  className={classNames({
                    'text-bz-primary': preset === rangeRaw.timeHorizon,
                  })}
                >
                  {preset}
                </Button>
              ))}
            </div>
            <div className="flex flex-1 flex-col">
              <div className="flex-0 flex flex-row items-center border-0 border-b border-solid border-bz-border">
                <div className="flex flex-1 flex-row items-center">
                  <Input
                    ref={startRef}
                    inputStyle="outline"
                    placeholder="Please Select..."
                    theme="ios"
                    themeVariant="light"
                    className="m-4 max-w-[135px]"
                  />
                  <FontAwesomeIcon icon={faArrowRight} />
                  <Input
                    ref={endRef}
                    inputStyle="outline"
                    placeholder="Please Select..."
                    theme="ios"
                    themeVariant="light"
                    className="m-4 max-w-[135px]"
                  />
                </div>
                <div className="mr-4 space-x-2 text-right">
                  <Button onClick={onReset}>Reset</Button>
                  <Button type="primary" onClick={onApply}>
                    Apply
                  </Button>
                </div>
              </div>
              <div className="min-w-[610px] flex-1 p-2">
                <Datepicker
                  showOuterDays
                  className="border-0"
                  theme="ios"
                  themeVariant="light"
                  select="range"
                  display="inline"
                  showRangeLabels={false}
                  pages="auto"
                  startInput={start}
                  endInput={end}
                  returnFormat="iso8601"
                  showOnClick={false}
                  showOnFocus={false}
                  value={selectedCalendarRange}
                  onChange={onDateChange}
                />
              </div>
            </div>
          </div>
        </Popup>
      </>
    )
  },
)
