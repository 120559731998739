import cn from 'classnames'
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import './AppMenu.less'

export type AppMenuContextProps = {
  isAppMenuOpen: boolean
  setIsAppMenuOpen: (isOpen: boolean) => void
  toggleIsAppMenuOpen: () => void
}

export const AppMenuContext = createContext({} as AppMenuContextProps)

export const useAppMenu = () => useContext(AppMenuContext)

type AppMenuProps = {
  children: React.ReactNode
}

export const AppMenu = ({ children }: AppMenuProps) => {
  const [isOpenState, setIsOpenState] = useState(false)
  const toggleIsAppMenuOpen = useCallback(() => {
    setIsOpenState(prev => !prev)
  }, [])

  const navigationContextProps = useMemo(
    (): AppMenuContextProps => ({
      isAppMenuOpen: isOpenState,
      setIsAppMenuOpen: setIsOpenState,
      toggleIsAppMenuOpen,
    }),
    [isOpenState, toggleIsAppMenuOpen],
  )

  return (
    <AppMenuContext.Provider value={navigationContextProps}>
      <nav
        className={cn('appMenu', {
          'is-open': isOpenState,
          'is-closed': !isOpenState,
        })}
        onMouseEnter={() => setIsOpenState(true)}
        onMouseLeave={() => setIsOpenState(false)}
      >
        <div className="appMenu__content">{children}</div>
      </nav>
    </AppMenuContext.Provider>
  )
}
