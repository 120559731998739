import {
  AsyncFn,
  PricebookCategory,
  QboIncomeAccount,
  isNullish,
} from '@breezy/shared'
import { Flatfile } from '@flatfile/api'
import {
  GetRecordsRequest,
  GetRecordsResponse,
  ListSheetsResponse,
  Sheet,
} from '@flatfile/api/api'
import { Records } from '@flatfile/api/api/resources/records/client/Client'

export type FlatfilePricebookCategoriesWriter = AsyncFn<{
  workbookId: string
  categories: PricebookCategory[] | undefined
}>

export type FlatfileQboIncomeAccountsWriter = AsyncFn<{
  workbookId: string
  qboIncomeAccounts: QboIncomeAccount[] | undefined
}>

export type FlatfileSheetsReader = AsyncFn<
  { workbookId: string },
  ListSheetsResponse
>

export type FlatfileSheetReader = AsyncFn<
  { workbookId: string; slug: string },
  Sheet | undefined
>

export type FlatfileSheetRecordsReader = AsyncFn<
  {
    workbookId: string
    slug: string
    request?: GetRecordsRequest | undefined
    requestOptions?: Records.RequestOptions | undefined
  },
  GetRecordsResponse
>

export type PricebookCategoryGuidReader = AsyncFn<
  { workbookId: string; name: string },
  string | undefined
>

export type FlatfilePricebookCategoryRecord = {
  name: Flatfile.CellValue // This is the concatenated category name
  parentCategoryName: Flatfile.CellValue // This is the concatenated category name of the parent
  pricebookCategoryGuid: Flatfile.CellValue
  parentCategoryGuid: Flatfile.CellValue
  sourcePhotoUrl: Flatfile.CellValue
}

export type FlatfilePricebookItemRecord = {
  name: Flatfile.CellValue
  pricebookItemGuid: Flatfile.CellValue
  category: Flatfile.CellValue // This is the reference to the concatenated category name
  description: Flatfile.CellValue
  itemType: Flatfile.CellValue
  costUsd: Flatfile.CellValue
  priceUsd: Flatfile.CellValue
  isTaxable: Flatfile.CellValue
  isActive: Flatfile.CellValue
  isDiscountable: Flatfile.CellValue

  pricebookCategoryGuid: Flatfile.CellValue
  sourcePhotoUrl: Flatfile.CellValue
}

export type FlatfilePricebookItemRecordWithQboIncomeAccount =
  FlatfilePricebookItemRecord & {
    qboIncomeAccountName: Flatfile.CellValue // This is the concatenated income account name
    qboIncomeAccountId: Flatfile.CellValue
  }

export type FlatfileQboIncomeAccountRecord = {
  qboIncomeAccountId: Flatfile.CellValue
  name: Flatfile.CellValue // This is the concatenated income account name
}

export const isFlatfilePricebookItemRecordWithQboIncomeAccount = (
  record:
    | FlatfilePricebookItemRecord
    | FlatfilePricebookItemRecordWithQboIncomeAccount,
): record is FlatfilePricebookItemRecordWithQboIncomeAccount => {
  return !isNullish(
    (record as FlatfilePricebookItemRecordWithQboIncomeAccount)
      ?.qboIncomeAccountId,
  )
}
