import { User } from '@auth0/auth0-react'
import { AvatarData } from '@breezy/shared'
import { userColor } from './color-utils'

export const userToAvatarData = (user: User): AvatarData => {
  return {
    avatarAltShortString: user.firstName[0] + user.lastName[0],
    avatarAltColor: userColor(user.userGuid),
    userGuid: user.userGuid,
    deactivatedAt: user.deactivatedAt,
  }
}
