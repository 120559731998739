import {
  BzDateFns,
  dates,
  formatTechnicianCapacityBlockReasonType,
  getPrettyRRuleDescription,
  IsoDateString,
} from '@breezy/shared'
import { faEdit } from '@fortawesome/pro-regular-svg-icons'
import { faRepeat } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React, { useMemo } from 'react'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import {
  BlockCalendarEvent,
  DEFAULT_EVENT_COLOR_CONFIG,
} from '../scheduleUtils'
import { BaseCard, CardSection } from './cardUtils'

type InternalEventCardContentProps = {
  start: IsoDateString
  end: IsoDateString
  event: BlockCalendarEvent
  onEdit: () => void
  onDelete: () => void
}

export const InternalEventCard = React.memo<InternalEventCardContentProps>(
  ({ start, end, event, onEdit, onDelete }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const title = formatTechnicianCapacityBlockReasonType(event.reasonType)
    const dateAndTime = useMemo(() => {
      const prefix = BzDateFns.formatFromISO(start, 'E, MMM d', tzId)
      const range = dates.calculateDateTimeWindow(start, end, tzId, {
        alwaysShowMinutes: true,
      })
      return `${prefix} • ${range}`
    }, [end, start, tzId])

    const recurrenceRule = useMemo(() => {
      if (event.recurrenceRule) {
        return getPrettyRRuleDescription(event.recurrenceRule)
      }
    }, [event.recurrenceRule])

    return (
      <BaseCard ribbonColorClassName={DEFAULT_EVENT_COLOR_CONFIG.ribbon}>
        <CardSection title={title}>
          {event.reasonDescription || (
            <span className="text-bz-text-tertiary">No description</span>
          )}
        </CardSection>
        <CardSection title="Date & Time">
          {dateAndTime}
          {recurrenceRule && (
            <div>
              <FontAwesomeIcon className="text-bz-gray-1000" icon={faRepeat} />
              <span className="ml-1">{recurrenceRule}</span>
            </div>
          )}
        </CardSection>
        <div className="flex flex-row space-x-2">
          <Button
            block
            type="primary"
            icon={<FontAwesomeIcon icon={faEdit} />}
            onClick={onEdit}
          >
            Edit Event
          </Button>
          <Button danger block onClick={onDelete}>
            Delete
          </Button>
        </div>
      </BaseCard>
    )
  },
)
