import { useMutation, useQuery } from 'urql'
import BzCheckBox from '../../elements/BzCheckBox/BzCheckBox'
import {
  READ_COMPANY_CONFIG_QUERY,
  UPSERT_COMPANY_CONFIG_QBO_AUTO_SYNC_INVOICE_ON_FULLY_PAID_MUTATION,
} from '../../gql/CompanyConfig.gql'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'
import GqlQueryLoader from '../GqlQueryLoader/GqlQueryLoader'

const label = 'Auto Sync Invoices when Fully Paid'

const ConfigLoader = m(() => {
  const companyGuid = useExpectedCompanyGuid()
  const query = useQuery({
    query: READ_COMPANY_CONFIG_QUERY,
    variables: { companyGuid },
  })

  return (
    <GqlQueryLoader
      query={query}
      render={data => {
        if (!data.companyConfigByPk) return <></>

        return (
          <QuickbooksEnableAutoSyncInvoiceOnFullyPaidCheckbox
            isEnabled={
              data.companyConfigByPk?.qboAutoSyncInvoiceOnFullyPaid ?? false
            }
          />
        )
      }}
    />
  )
})

type IsEnabledProps = {
  isEnabled: boolean
}

const QuickbooksEnableAutoSyncInvoiceOnFullyPaidCheckbox = m<IsEnabledProps>(
  ({ isEnabled }) => {
    const companyGuid = useExpectedCompanyGuid()
    const [, executeMutation] = useMutation(
      UPSERT_COMPANY_CONFIG_QBO_AUTO_SYNC_INVOICE_ON_FULLY_PAID_MUTATION,
    )

    return (
      <BzCheckBox
        label={label}
        value={isEnabled}
        onChange={async e => {
          await executeMutation({
            config: {
              companyGuid,
              qboAutoSyncInvoiceOnFullyPaid: e,
            },
          })
        }}
      />
    )
  },
)

export default ConfigLoader
