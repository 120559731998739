import {
  InvoiceTerm,
  bzOptional,
  emailAddressValueSchema,
  guidSchema,
  stateAbbreviationSchema,
  telephoneNumberSchema,
} from '@breezy/shared'
import { z } from 'zod'

export const COMPANY_LOGO_MAX_SIZE = {
  width: 300,
  height: 300,
} as const

export const billingProfileFormSchema = z.object({
  business: z.object({
    fullLegalName: z.string().min(1),
    contractorLicenseNumber: z.string(),
    emailAddress: emailAddressValueSchema,
    phoneNumber: telephoneNumberSchema,
    address: z.object({
      line1: z.string().min(1),
      line2: bzOptional(z.string()),
      zipCode: z.string().min(1),
      city: z.string().min(1),
      stateAbbreviation: stateAbbreviationSchema,
    }),
  }),
  company: z.object({
    logoUrl: z.string().url(),
  }),
  invoicesAndEstimates: z.object({
    defaultInvoiceTerm: bzOptional(z.nativeEnum(InvoiceTerm)),
    defaultInvoiceMemo: bzOptional(z.string()),
    defaultEstimateDisclaimer: z.string(),
    defaultInvoiceDisclaimer: z.string(),
    defaultTaxRateGuid: bzOptional(guidSchema),
  }),
})

export type BillingProfileFormSchema = z.infer<typeof billingProfileFormSchema>
