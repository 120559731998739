import {
  DEFAULT_JOB_COMPLETED_CUSTOM_EMAIL_CONTENT,
  DEFAULT_MAINTENANCE_REMINDER_CUSTOM_EMAIL_CONTENT,
  bzOptional,
} from '@breezy/shared'
import { z } from 'zod'

export const dripMarketingSettingsFormSchema = z.object({
  yelpReviewUrl: bzOptional(z.string().url()),
  googleReviewUrl: bzOptional(z.string().url()),
  facebookReviewUrl: bzOptional(z.string().url()),

  // Sent after a job is completed, with relevant follow-up information about the job
  jobCompletedEnabled: z.boolean().default(false),
  jobCompletedDelayMinutes: z
    .string()
    .refine(val => {
      const parsedNumber = Number(val)
      return !isNaN(parsedNumber) && parsedNumber >= 0
    })
    .default('0'),
  jobCompletedCustomEmailContent: z
    .string()
    .default(DEFAULT_JOB_COMPLETED_CUSTOM_EMAIL_CONTENT),

  // Sent several months after a job is completed, to remind customers to book maintenance visits
  maintenanceRemindersEnabled: z.boolean().default(false),
  maintenanceRemindersDelayMonths: z
    .string()
    .refine(val => {
      const parsedNumber = Number(val)
      return !isNaN(parsedNumber) && parsedNumber >= 0
    })
    .default('6'),
  maintenanceRemindersCustomEmailContent: z
    .string()
    .default(DEFAULT_MAINTENANCE_REMINDER_CUSTOM_EMAIL_CONTENT),
})

export type DripMarketingSettingsFormData = z.infer<
  typeof dripMarketingSettingsFormSchema
>
