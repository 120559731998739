import { gql, useQuery } from 'urql'
import ActionBanner from '../../elements/ActionBanner/ActionBanner'
import { Query_Root } from '../../generated/user/graphql'
import useAppNav from '../../hooks/useAppNav'
import {
  useCompanyGuid,
  useExpectedCompanyGuid,
} from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'
import GqlQueryLoader from '../GqlQueryLoader/GqlQueryLoader'
import { QuickbooksButtonClassName } from './QuickbooksColor'
import { QuickbooksLogoIconNonResizing } from './QuickbooksLogoIcon'

export const FETCH_QBO_REAUTH_NEEDED_QUERY = gql(/* GraphQL */ `
  query FetchQboAuthTokensInvalid($companyGuid: uuid) {
    qboAuthData(where: { companyGuid: { _eq: $companyGuid } }) {
      isInvalid
    }
  }
`)

const qboTokensInvalid = (data: Query_Root) =>
  data?.qboAuthData?.[0]?.isInvalid ?? false

const reauthNeededMessage =
  'There’s an issue with your Quickbooks Online integration, which has resulted in syncing being paused. Please re-authenticate now to resume.'

export const QuickbooksReauthNeededBanner = m(() => {
  const appNav = useAppNav()
  return (
    <ActionBanner
      icon={<QuickbooksLogoIconNonResizing />}
      dismissKey="qbo-reauth-needed"
      text={reauthNeededMessage}
      actionButton={{
        text: 'View Settings',
        onClick: () => appNav.navigateToQuickbooksConfig(),
        className: QuickbooksButtonClassName,
      }}
    />
  )
})

export const QuickbooksReauthNeededNonActionBanner = m(() => {
  const companyGuid = useExpectedCompanyGuid()
  const query = useQuery({
    query: FETCH_QBO_REAUTH_NEEDED_QUERY,
    variables: { companyGuid },
  })

  return (
    <GqlQueryLoader
      query={query}
      render={data =>
        qboTokensInvalid(data) ? (
          <ActionBanner text={reauthNeededMessage} />
        ) : (
          <></>
        )
      }
    />
  )
})

export const QuickbooksReauthNeededBannerLoader = m(() => {
  const companyGuid = useCompanyGuid()

  const query = useQuery({
    query: FETCH_QBO_REAUTH_NEEDED_QUERY,
    variables: { companyGuid },
    pause: !companyGuid,
  })
  const seemsToBeOnQuickbooksSettingsPageAlready =
    window.location.pathname.includes('settings/quickbooks')

  if (!companyGuid || seemsToBeOnQuickbooksSettingsPageAlready) return <></>

  return (
    <GqlQueryLoader
      query={query}
      render={data =>
        qboTokensInvalid(data) ? (
          <div className="sticky top-0 z-10">
            <QuickbooksReauthNeededBanner />
          </div>
        ) : (
          <></>
        )
      }
    />
  )
})
