import cn from 'classnames'
import React from 'react'

export type TilledLogoSize = 'sm' | 'md' | 'lg'
type LogoProps = {
  size?: TilledLogoSize
}

export const TilledLogo = React.memo<LogoProps>(({ size = 'md' }) => (
  <div
    className={cn(
      'flex items-center justify-center',
      size === 'sm' ? 'h-6 w-[72px]' : '',
      size === 'md' ? 'h-[36px] w-[100px]' : '',
      size === 'lg' ? 'h-11 w-[132px]' : '',
    )}
  >
    <img src="/img/tilled-logo.webp" alt="Tilled Logo" />
  </div>
))
