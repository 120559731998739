import { R } from '@breezy/shared'
import { Radio } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { typedForwardRef, typedMemo } from '../../utils/react-utils'

type CheckboxButtonFieldProps<T extends string> = {
  value: T[]
  onChange: (value: T[]) => void
  options: {
    label: string
    value: T
  }[]
  className?: string
}

export const CheckboxButtonField = typedMemo(
  typedForwardRef(
    <T extends string>(
      { className, value, onChange, options }: CheckboxButtonFieldProps<T>,
      ref: React.ForwardedRef<HTMLDivElement>,
    ) => {
      const selectedValueMap = useMemo(() => {
        const map: Partial<Record<T, boolean>> = {}
        for (const val of value) {
          map[val] = true
        }
        return map
      }, [value])
      const onClick = useCallback(
        (val: T) => {
          const newValues: T[] = []
          let seen = false
          for (const existingValue of R.keys(selectedValueMap)) {
            if (val === existingValue) {
              seen = true
            } else {
              newValues.push(existingValue)
            }
          }
          if (!seen) {
            newValues.push(val)
          }
          onChange(newValues)
        },
        [onChange, selectedValueMap],
      )
      return (
        <div ref={ref} className={className}>
          {/* I'm aware that radio buttons are meant to be mutually exclusive and what we really want is
            "checkboxes" or a "button group". But antd doesn't provide a component that looks exactly like their
            radio buttons but where multiple are selectable, so here we are. */}
          <Radio.Group size="large" value="">
            {options.map(({ label, value }) => (
              <Radio.Button
                key={label}
                onClick={() => onClick(value)}
                className={classNames('select-none', {
                  'ant-radio-button-wrapper-checked': selectedValueMap[value],
                })}
              >
                {label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
      )
    },
  ),
)
