import {
  AttributionLinkingStrategy,
  AttributionLinkingStrategyValues,
  CompanyCreatorV2Input,
} from '@breezy/shared'
import { Input, Select } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'
import { DocumentType } from 'src/generated'
import { CANONICAL_LEAD_SOURCES_QUERY } from './CreateCompanyDrawerLeadSourcesSection.gql'

export type LeadSourcesSectionLeadSourceCollapsibleProps = {
  index: number
  canonicalLeadSources: DocumentType<
    typeof CANONICAL_LEAD_SOURCES_QUERY
  >['canonicalLeadSources']
}

export const LeadSourcesSectionLeadSourceCollapsible = ({
  index,
  canonicalLeadSources,
}: LeadSourcesSectionLeadSourceCollapsibleProps) => {
  const { control, setValue } = useFormContext<CompanyCreatorV2Input>()

  return (
    <div className="flex flex-col gap-3">
      <div className="flex w-full flex-row gap-2">
        <Controller
          control={control}
          name={`leadSources.${index}.canonicalLeadSourceName`}
          render={({ field }) => (
            <label className="flex flex-1 flex-col gap-1">
              <span>Canonical Category</span>
              <Select
                {...field}
                options={canonicalLeadSources.map(leadSource => ({
                  label: leadSource.canonicalLeadSourceName,
                  value: leadSource.canonicalLeadSourceName,
                }))}
                onChange={value => {
                  const leadSource = canonicalLeadSources.find(
                    curr => curr.canonicalLeadSourceName === value,
                  )
                  if (!leadSource) {
                    return
                  }

                  setValue(
                    `leadSources.${index}.canonicalLeadSourceName`,
                    leadSource.canonicalLeadSourceName,
                  )
                  setValue(
                    `leadSources.${index}.attributionLinkingStrategy`,
                    leadSource.attributionLinkingStrategy as AttributionLinkingStrategy,
                  )
                  setValue(
                    `leadSources.${index}.attributionPrompt`,
                    leadSource.attributionPrompt ?? '',
                  )
                }}
              />
            </label>
          )}
        />

        <Controller
          control={control}
          name={`leadSources.${index}.leadSourceNameOverride`}
          render={({ field }) => (
            <label className="flex flex-1 flex-col gap-1">
              <span>Lead Source Name Override</span>
              <Input {...field} />
            </label>
          )}
        />
      </div>

      <div className="flex w-full flex-row gap-2">
        <Controller
          control={control}
          name={`leadSources.${index}.attributionLinkingStrategy`}
          render={({ field }) => (
            <label className="flex flex-1 flex-col gap-1">
              <span>Default Attribution Linking Strategy</span>
              <Input {...field} disabled />
            </label>
          )}
        />

        <Controller
          control={control}
          name={`leadSources.${index}.attributionLinkingStrategyOverride`}
          render={({ field }) => (
            <label className="flex flex-1 flex-col gap-1">
              <span>Attribution Linking Strategy Override</span>
              <Select
                {...field}
                options={AttributionLinkingStrategyValues.map(
                  linkingStrategy => ({
                    label: linkingStrategy,
                    value: linkingStrategy,
                  }),
                )}
              />
            </label>
          )}
        />
      </div>

      <div className="flex w-full flex-row gap-2">
        <Controller
          control={control}
          name={`leadSources.${index}.attributionPrompt`}
          render={({ field }) => (
            <label className="flex flex-1 flex-col gap-1">
              <span>Default Attribution Prompt</span>
              <Input {...field} disabled />
            </label>
          )}
        />

        <Controller
          control={control}
          name={`leadSources.${index}.attributionPromptOverride`}
          render={({ field }) => (
            <label className="flex flex-1 flex-col gap-1">
              <span>Attribution Prompt Override</span>
              <Input {...field} />
            </label>
          )}
        />
      </div>
    </div>
  )
}
