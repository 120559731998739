import { EstimateStatus, formatUsc, isNullish } from '@breezy/shared'
import { Popover, Tag } from 'antd'
import { memo, useMemo } from 'react'
import { Job } from 'src/gql/queries/Jobs.gql'

const getAcceptedEstimateValue = (estimate: Job['estimates'][number]) => {
  if (estimate.status !== EstimateStatus.ACCEPTED) {
    return null
  }

  const estimateOption = estimate.estimateOptions.find(
    option => option.isSelected,
  )

  if (isNullish(estimateOption)) {
    return null
  }

  return estimateOption?.totalUsc
}

type EstimateOptionsPopoverContentProps = {
  estimateOptions: Job['estimates'][number]['estimateOptions']
}

const EstimateOptionsPopoverContent = ({
  estimateOptions,
}: EstimateOptionsPopoverContentProps) => {
  const options = useMemo(() => {
    return [...estimateOptions].sort((a, b) => a.seq - b.seq)
  }, [estimateOptions])

  return (
    <div className="flex w-[273px] flex-row flex-wrap items-center gap-2">
      {options.map(estimateOption => (
        <Tag
          key={`estimateOptionTag-${estimateOption.estimateOptionGuid}`}
          color={estimateOption.isSelected ? 'green' : undefined}
        >
          <span
            className={
              estimateOption.isSelected ? undefined : 'text-xs text-bz-gray-700'
            }
          >
            {estimateOption.seq + 1}
          </span>{' '}
          {formatUsc(estimateOption.totalUsc)}
        </Tag>
      ))}
    </div>
  )
}

type AdditionalEstimatesPopoverContentProps = {
  estimates: Job['estimates']
}

const AdditionalEstimatesPopoverContent = ({
  estimates,
}: AdditionalEstimatesPopoverContentProps) => {
  return (
    <div className="flex w-[273px] flex-row flex-wrap items-center gap-2">
      {estimates.map(estimate => {
        const estimateValue = getAcceptedEstimateValue(estimate)

        return (
          <Tag
            key={estimate.estimateGuid}
            color={!isNullish(estimateValue) ? 'green' : undefined}
          >
            # {estimate.displayId}
            {!isNullish(estimateValue) && ` (${formatUsc(estimateValue)})`}
          </Tag>
        )
      })}
    </div>
  )
}

export type EstimatesTagListProps = {
  estimates: Job['estimates']
  limit: number
}

export const EstimatesTagList = memo(
  ({ estimates, limit }: EstimatesTagListProps) => {
    const shownEstimates = estimates.slice(0, limit)
    const hiddenEstimates = estimates.slice(limit)

    return (
      <div className="flex-row-gap-2 flex flex-wrap">
        {shownEstimates.map(estimate => {
          const estimateValue = getAcceptedEstimateValue(estimate)

          return (
            <Popover
              key={estimate.estimateGuid}
              trigger="hover"
              title="Estimate Options"
              content={
                <EstimateOptionsPopoverContent
                  estimateOptions={estimate.estimateOptions}
                />
              }
            >
              <Tag color={!isNullish(estimateValue) ? 'green' : undefined}>
                # {estimate.displayId}
                {!isNullish(estimateValue) && ` (${formatUsc(estimateValue)})`}
              </Tag>
            </Popover>
          )
        })}

        {hiddenEstimates.length > 0 && (
          <Popover
            trigger="hover"
            title="Additional Estimates"
            content={
              <AdditionalEstimatesPopoverContent estimates={hiddenEstimates} />
            }
          >
            <Tag>+ {hiddenEstimates.length}</Tag>
          </Popover>
        )}
      </div>
    )
  },
)
