import { ReminderStatus } from '@breezy/shared'
import { Img } from '@react-email/components'
import React from 'react'

type ReminderStatusIconProps = {
  status: ReminderStatus
}

const ReminderStatusIcon = React.memo<ReminderStatusIconProps>(({ status }) => {
  return (
    <Img
      width={20}
      height={20}
      alt="Reminder status icon"
      src={
        status === 'INCOMPLETE'
          ? 'https://d3j5nzmmhjc8v6.cloudfront.net/check-icon-incomplete.png'
          : 'https://d3j5nzmmhjc8v6.cloudfront.net/check-icon-complete.png'
      }
    />
  )
})

export default ReminderStatusIcon
