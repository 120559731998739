import {
  BzDateFns,
  Dfns,
  IsoDateString,
  JOBS_V2_CLASSES,
  JobLifecycleView,
  TimeZoneId,
  jobClassDisplayNames,
} from '@breezy/shared'
import { useMemo } from 'react'
import { useQuery } from 'urql'
import { KanbanJob } from '../../components/Kanban/kanbanUtils'
import { TAGS_MINIMAL_FOR_COMPANY_QUERY } from '../../gql/queries/Tags.gql'
import {
  FilterConfig,
  queryStringToFilters,
  useFilter,
} from '../../hooks/useFilter'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { COMPANY_USERS_QUERY } from './JobsPage.gql'
import { createTechnicianOptions } from './components/JobLifecycleFilterDrawerV2'

const appointmentFilter = (
  incoming: IsoDateString,
  optionValue: IsoDateString,
  tzId: TimeZoneId,
) => {
  const incomingDate = BzDateFns.parseISO(incoming, tzId)

  // Is it in the past? Don't show
  if (Dfns.isBefore(BzDateFns.now(tzId), incomingDate)) {
    return false
  }

  const optionDate = BzDateFns.parseISO(optionValue, tzId)

  return Dfns.isBefore(optionDate, incomingDate)
}

type UseJobFiltersInput = {
  jobs: KanbanJob[]
  tzId: TimeZoneId
  initialView?: JobLifecycleView
  initialFilters?: { key: string; optionsSelected: string[] }[]
}

export const useJobFilters = ({
  jobs,
  tzId,
  initialView,
  initialFilters,
}: UseJobFiltersInput) => {
  const companyGuid = useExpectedCompany().companyGuid

  const [filtersQuery] = useQuery({
    query: TAGS_MINIMAL_FOR_COMPANY_QUERY,
    variables: { companyGuid },
  })

  const [companyUsersQuery] = useQuery({
    query: COMPANY_USERS_QUERY,
    variables: { companyGuid },
  })

  const today = BzDateFns.getToday(tzId)

  const initialJobFilters: { key: string; optionsSelected: string[] }[] =
    useMemo(() => {
      if (!initialView && !initialFilters) {
        return []
      }

      const viewFilters = queryStringToFilters(
        initialView?.view.filtersV2 ?? '',
      )
      if (!initialFilters) {
        return viewFilters
      }

      const filterMap = new Map<string, Set<string>>(
        viewFilters.map(({ key, optionsSelected }) => [
          key,
          new Set(optionsSelected),
        ]),
      )
      for (let i = 0; i < initialFilters.length; i++) {
        const filter = initialFilters[i]
        const uniqueOptionsForFilter = filterMap.get(filter.key)
        if (!uniqueOptionsForFilter) {
          filterMap.set(filter.key, new Set(filter.optionsSelected))
        } else {
          for (let j = 0; j < filter.optionsSelected.length; j++) {
            uniqueOptionsForFilter.add(filter.optionsSelected[j])
          }
        }
      }

      return Array.from(filterMap.entries()).map(([key, options]) => ({
        key,
        optionsSelected: Array.from(options.values()),
      }))
    }, [initialView, initialFilters])

  const config: FilterConfig<KanbanJob> = useMemo(() => {
    const jobFilterConfig: FilterConfig<KanbanJob> = [
      {
        type: 'group',
        key: 'groupJobProperties',
        label: 'Job Properties',
        children: ['jobClass', 'jobType'],
      },
      {
        type: 'options',
        key: 'jobClass',
        label: 'Job Class',
        options: JOBS_V2_CLASSES.map(jobClass => ({
          option: jobClassDisplayNames[jobClass],
          filter: item => item.job.jobClass === jobClass,
        })),
      },
      {
        type: 'options',
        key: 'jobType',
        label: 'Job Type',
        options: Array.from(
          new Set(jobs.map(({ job }) => job.jobType.name)),
        ).map(jobType => ({
          option: jobType,
          filter: item => item.job.jobType.name === jobType,
        })),
      },
      {
        type: 'group',
        key: 'groupAppointmentProperties',
        label: 'Appointment Properties',
        children: ['nextAppointment', 'assignedTechnicians', 'accountManagers'],
      },
      {
        type: 'options',
        key: 'nextAppointment',
        label: 'Next Appointment',
        options: [
          {
            option: 'No Appointments',
            filter: item => item.job.appointments.length === 0,
          },
          {
            option: 'Today',
            filter: item =>
              item.job.appointments.some(appt =>
                appointmentFilter(
                  appt.appointmentWindowStart,
                  BzDateFns.formatISO(BzDateFns.addDays(today, 1), tzId),

                  tzId,
                ),
              ),
          },
          {
            option: 'Tomorrow',
            filter: item =>
              item.job.appointments.some(appt =>
                appointmentFilter(
                  appt.appointmentWindowStart,
                  BzDateFns.formatISO(BzDateFns.addDays(today, 2), tzId),
                  tzId,
                ),
              ),
          },
          {
            option: 'Within 3 days',
            filter: item =>
              item.job.appointments.some(appt =>
                appointmentFilter(
                  appt.appointmentWindowStart,
                  BzDateFns.formatISO(BzDateFns.addDays(today, 4), tzId),
                  tzId,
                ),
              ),
          },
          {
            option: 'Within 5 days',
            filter: item =>
              item.job.appointments.some(appt =>
                appointmentFilter(
                  appt.appointmentWindowStart,
                  BzDateFns.formatISO(BzDateFns.addDays(today, 6), tzId),
                  tzId,
                ),
              ),
          },
          {
            option: 'Within 7 days',
            filter: item =>
              item.job.appointments.some(appt =>
                appointmentFilter(
                  appt.appointmentWindowStart,
                  BzDateFns.formatISO(BzDateFns.addDays(today, 8), tzId),
                  tzId,
                ),
              ),
          },
        ],
      },
      {
        type: 'options',
        key: 'assignedTechnicians',
        label: 'Assigned Technicians',
        options: createTechnicianOptions(jobs),
      },
    ]

    if (companyUsersQuery.data) {
      jobFilterConfig.push({
        type: 'options',
        key: 'accountManagers',
        label: 'Account Managers',
        options: companyUsersQuery.data.companyUsers.map(companyUser => ({
          option: companyUser.userByUserGuid.userGuid,
          filter: item =>
            item.job.account.accountManager?.userGuid ===
            companyUser.userByUserGuid.userGuid,
        })),
      })
    }

    if (filtersQuery.data) {
      jobFilterConfig.push({
        type: 'group',
        key: 'groupExtra',
        label: '',
        children: ['tags'],
      })

      jobFilterConfig.push({
        type: 'options',
        key: 'tags',
        label: 'Tags',
        options: filtersQuery.data.tags.map(tag => ({
          option: tag.name,
          filter: item => {
            return (
              item.job.tags.some(curr => curr.tag.tagGuid === tag.tagGuid) ||
              item.job.account.tags.some(
                curr => curr.tag.tagGuid === tag.tagGuid,
              )
            )
          },
        })),
      })
    }

    return jobFilterConfig
  }, [companyUsersQuery.data, filtersQuery.data, jobs, today, tzId])

  return {
    ...useFilter({
      data: jobs,
      config,
      initialFilters: initialJobFilters,
    }),
    isLoading: filtersQuery.fetching,
  }
}
