import { isNullish } from '@breezy/shared'
import { InputNumber } from 'antd'
import React, { forwardRef, useCallback } from 'react'
import { Styled } from '../../utils/Stylable'

type ItemPriceInputNumberProps = Readonly<{
  min?: number
  style?: React.CSSProperties
  disabled?: boolean
  className?: string
}>

const priceSimpleRegex = /[^\d.-]/g
const cleanedFloatValue = (value: string | undefined, min?: number): number => {
  const intermediate = (value ?? '').replace(priceSimpleRegex, '')
  const num = Number.parseFloat(intermediate)
  if (isNaN(num)) {
    return min ?? 0
  }

  if (!isNullish(min) && num < min) {
    return min
  }

  return num
}
const PARSER = cleanedFloatValue

export const ItemPriceInputNumber = forwardRef<
  HTMLInputElement,
  Styled<ItemPriceInputNumberProps>
>(
  (
    { min, disabled, className, ...rest }: Styled<ItemPriceInputNumberProps>,
    ref,
  ) => {
    const parser = useCallback(
      (value: string | undefined): number => {
        return PARSER(value, min)
      },
      [min],
    )

    const formatter = useCallback(
      (
        formValue: unknown,
        { userTyping, input }: { userTyping: boolean; input: string },
      ): string => {
        // I want to format based on the text in the input box, not what antd thinks the value is. However, on load, even if
        // the value is pre-populated, antd won't put anything in the box and will tell us it's empty. So if the input is
        // empty, we fall back to the form value. And I tested "what happens if you backspace all of it". That doesn't matter
        // because on each change it's updating the form value. So it's not going to suddenly be the original value. And just
        // to be absolutely certain we're safe, stringify that form value in case antd pulls a fast one on us and gives us a
        // number.
        const value = `${input || formValue}`

        if (userTyping) {
          return value
        }
        const num = cleanedFloatValue(value, min)
        if (isNaN(num)) {
          return ''
        }
        return num.toLocaleString('en-US', {
          currency: 'USD',
          style: 'currency',
        })
      },
      [min],
    )

    return (
      <InputNumber
        ref={ref}
        className={className}
        min={min}
        formatter={formatter}
        parser={parser}
        disabled={isNullish(disabled) ? undefined : disabled}
        {...rest}
      />
    )
  },
)
