import { Modal, Switch, message } from 'antd'
import { memo, useCallback, useMemo } from 'react'
import ThinDivider from '../../elements/ThinDivider'

type FeatureEnableViewProps = {
  readonly featureTitle: string
  readonly featureEnabled: boolean
  readonly isLoading: boolean
  readonly switchDisabled?: boolean
  readonly disableConfirmPrompt?: string
  readonly setValue: (enabled: boolean) => Promise<void>
  readonly refetch: () => void
  readonly toggleContainerClassName?: string
}

export const FeatureEnableView = memo<FeatureEnableViewProps>(
  ({
    featureTitle,
    featureEnabled,
    refetch,
    setValue,
    switchDisabled,
    isLoading,
    disableConfirmPrompt,
    toggleContainerClassName,
  }) => {
    const toggleFeature = useCallback(
      async (enabled: boolean) => {
        try {
          await setValue(enabled)

          refetch()

          const msg = enabled
            ? `${featureTitle} feature enabled!`
            : `${featureTitle} feature disabled!`
          message.success(msg)
        } catch (e) {
          message.error(`Failed to update ${featureTitle} settings`)
        }
      },
      [setValue, refetch, featureTitle],
    )

    const onToggleChange = useCallback(
      async (checked: boolean) => {
        const isDisablingFeature = !checked

        if (isDisablingFeature && disableConfirmPrompt) {
          Modal.confirm({
            title: `Confirm disabling ${featureTitle}`,
            content: disableConfirmPrompt,
            async onOk() {
              await toggleFeature(checked)
            },
            onCancel() {
              return
            },
          })
        } else {
          await toggleFeature(checked)
        }
      },
      [disableConfirmPrompt, featureTitle, toggleFeature],
    )

    const toggleDescriptionText = useMemo(
      () =>
        featureEnabled
          ? `Disable this if you do not want to use Breezy's ${featureTitle} offerings and want to hide all of the functionality`
          : `Enable this if you wish to use Breezy's ${featureTitle} offerings`,
      [featureEnabled, featureTitle],
    )

    return (
      <>
        <div className="col w-full pt-2">
          <div className="row flex-between ">
            <div className="col">
              <h3 className="grey9">{`Enable ${featureTitle} Feature`}</h3>
              <p className="regular_14_22 grey8 max-w-[700px] font-light">
                {toggleDescriptionText}
              </p>
            </div>
          </div>
          <div
            className={
              toggleContainerClassName ??
              'mx-auto flex w-20 items-center justify-center'
            }
          >
            <Switch
              disabled={switchDisabled || isLoading}
              checked={featureEnabled}
              onChange={onToggleChange}
              checkedChildren={'Enabled'}
              unCheckedChildren={'Disabled'}
            />
          </div>
        </div>
        <ThinDivider />
      </>
    )
  },
)
