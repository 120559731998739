import { formatUscWholeDollars, usdToUsCents } from '@breezy/shared'
import { Col, Row } from 'antd'
import { memo, useMemo } from 'react'
import { Tile } from '../Tile'
import { TeamPerformanceDivider } from './TeamPerformanceDivider'
import { TeamPerformanceJob } from './team-performance-queries.gql'

type TotalJobRevenueTileProps = {
  jobs: TeamPerformanceJob[]
  loading: boolean
}

export const TotalJobRevenueTile = memo<TotalJobRevenueTileProps>(
  ({ jobs, loading }) => {
    const allJobsTotalRevenueUsd = useMemo(() => {
      return jobs.reduce((acc, job) => {
        return (
          acc + (job.jobFullyPaidInvoicesV2Summary?.totalPaidAmountUsd ?? 0)
        )
      }, 0)
    }, [jobs])

    const opportunitiesTotalRevenueUsd = useMemo(() => {
      const opportunities = jobs.filter(job => job.isOpportunity)
      return opportunities.reduce((acc, job) => {
        return (
          acc + (job.jobFullyPaidInvoicesV2Summary?.totalPaidAmountUsd ?? 0)
        )
      }, 0)
    }, [jobs])

    const hotLeadsTotalRevenueUsd = useMemo(() => {
      const hotLeads = jobs.filter(job => job.isHotLead)
      return hotLeads.reduce((acc, job) => {
        return (
          acc + (job.jobFullyPaidInvoicesV2Summary?.totalPaidAmountUsd ?? 0)
        )
      }, 0)
    }, [jobs])

    return (
      <Tile title="Total Job Revenue" loading={loading}>
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">All Jobs</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {formatUscWholeDollars(usdToUsCents(allJobsTotalRevenueUsd))}
          </Col>
        </Row>
        <TeamPerformanceDivider />
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">Opportunities</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {formatUscWholeDollars(usdToUsCents(opportunitiesTotalRevenueUsd))}
          </Col>
        </Row>
        <TeamPerformanceDivider />
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">Hot Leads</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {formatUscWholeDollars(usdToUsCents(hotLeadsTotalRevenueUsd))}
          </Col>
        </Row>
      </Tile>
    )
  },
)
