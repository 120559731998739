import { BulkPricebookCategory, BulkPricebookItemRow } from '@breezy/shared'
import { FlatfileListener } from '@flatfile/listener'
import { useCallback, useEffect, useRef } from 'react'
import { useModal } from '../../utils/antd-utils'
import { FlatfilePricebookImportHookInput } from './useFlatfilePricebookImport'
import { FlatfilePricebookImportListener } from './utils/FlatfilePricebookImportListener'

export const useFlatfilePricebookImportListener = (
  input: FlatfilePricebookImportHookInput,
) => {
  const listenerRef = useRef<FlatfileListener | undefined>(undefined)
  const Modal = useModal()

  const triggerErrorModal = useCallback(
    (errorMsg: string) => {
      Modal.error({
        title: 'Error occurred during Flatfile processing',
        content: errorMsg,
      })
    },
    [Modal],
  )

  const onFlatfileSubmit = useCallback(
    (
      pricebookCategoryCells: BulkPricebookCategory[],
      pricebookItemCells: BulkPricebookItemRow[],
    ) => {
      input.stageImportRows(pricebookCategoryCells, pricebookItemCells)
      input.onContinue()
    },
    [input],
  )
  const listener = useCallback(
    () =>
      FlatfilePricebookImportListener({
        categories: input.categories,
        onSubmit: onFlatfileSubmit,
        triggerErrorModal,
        qboEnabled: input.qboEnabled,
        qboIncomeAccounts: input.qboIncomeAccounts,
      }),
    [
      input.categories,
      input.qboEnabled,
      input.qboIncomeAccounts,
      onFlatfileSubmit,
      triggerErrorModal,
    ],
  )
  useEffect(() => {
    listenerRef.current = listener()
  }, [listener])

  return listenerRef.current
}
