import { useMutation } from 'urql'
import { gql } from '../generated'

const UPSERT_COMPANY_BILLING_PROFILE_MUTATION = gql(/* GraphQL */ `
  mutation UpsertCompanyBillingProfile($object: BillingProfilesInsertInput!) {
    insertBillingProfilesOne(
      object: $object
      onConflict: {
        constraint: billing_profiles_pkey
        updateColumns: [
          businessAddressGuid
          businessFullLegalName
          contractorLicenseNumber
          defaultPricebookTaxRateGuid
          emailAddressGuid
          estimateDisclaimer
          invoiceMemo
          invoiceTerm
          logoUrl
          maintenancePlanTaxRateGuid
          maintenancePlanServiceItemGuid
          maintenancePlanAutoRenewalAllowed
          maintenancePlanManualRenewalAllowed
          phoneNumberGuid
          tilledMerchantAccountId
          updatedAt
          websiteDisplayName
          websiteUrl
        ]
      }
    ) {
      __typename
    }
  }
`)

export const useUpsertCompanyBillingProfile = () => {
  const [{ fetching }, upsert] = useMutation(
    UPSERT_COMPANY_BILLING_PROFILE_MUTATION,
  )

  return {
    fetching,
    upsert,
  }
}
