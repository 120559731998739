import { faPhone } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import { CallablePhoneLink } from '../../../elements/CallablePhoneLink/CallablePhoneLink'
import { gray7 } from '../../../themes/theme'
import './PhoneNumberView.less'

export type PhoneNumberViewProps = {
  phoneNumber: string
  iconOnly?: boolean
  displayType?: 'compact' | 'circle-button'
}

export const CompactPhoneNumberView = ({
  phoneNumber,
  iconOnly,
}: PhoneNumberViewProps) => {
  return (
    <Tooltip className="phone__number" title={phoneNumber} placement="bottom">
      <CallablePhoneLink phoneNumber={phoneNumber}>
        <>
          <FontAwesomeIcon
            icon={faPhone}
            color={gray7}
            style={{ fontSize: '16px', marginRight: iconOnly ? '0px' : '12px' }}
          />
          {!iconOnly && <p>{phoneNumber}</p>}
        </>
      </CallablePhoneLink>
    </Tooltip>
  )
}

export const CircleButtonPhoneNumberView = ({
  phoneNumber,
}: PhoneNumberViewProps) => (
  <Tooltip className="phone__number" title={phoneNumber} placement="bottom">
    <CallablePhoneLink phoneNumber={phoneNumber}>
      <div
        style={{
          borderRadius: '50%',
          border: '1px solid',
          borderColor: gray7,
          width: 36,
          height: 36,
          marginRight: 12,
        }}
      >
        <FontAwesomeIcon
          icon={faPhone}
          color={gray7}
          style={{ fontSize: '16px', margin: 'auto' }}
        />
      </div>
    </CallablePhoneLink>
  </Tooltip>
)

export const PhoneNumberView = ({
  phoneNumber,
  iconOnly,
  displayType = 'compact',
}: PhoneNumberViewProps) => {
  if (!phoneNumber) return null
  if (displayType === 'compact')
    return (
      <CompactPhoneNumberView phoneNumber={phoneNumber} iconOnly={iconOnly} />
    )
  return <CircleButtonPhoneNumberView phoneNumber={phoneNumber} />
}
