import { useCallback, useEffect, useState } from 'react'

export const useKeysDown = (onChange: (keys: string[]) => void) => {
  const [keysDown, setKeysDown] = useState<Set<string>>(new Set())

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      setKeysDown(prevKeys => {
        prevKeys.add(event.key.toLowerCase())
        return prevKeys
      })

      onChange(Array.from(keysDown))
    },
    [onChange, keysDown],
  )

  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    setKeysDown(prevKeys => {
      prevKeys.delete(event.key.toLowerCase())
      return prevKeys
    })
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [keysDown, handleKeyDown, handleKeyUp])
}
