import { formatPercentage, formatUscWholeDollars } from '@breezy/shared'
import { Col, Row } from 'antd'
import { memo, useMemo } from 'react'
import { Tile } from '../Tile'
import { TeamPerformanceDivider } from './TeamPerformanceDivider'
import { TeamPerformanceJob } from './team-performance-queries.gql'

type MaintenancePlansTileProps = {
  jobs: TeamPerformanceJob[]
  loading: boolean
}

export const MaintenancePlansTile = memo<MaintenancePlansTileProps>(
  ({ jobs, loading }) => {
    const maintenancePlansSold = useMemo(
      () => jobs.filter(job => job.isMembershipSold).length,
      [jobs],
    )

    const totalMembershipOpportunities = useMemo(
      () =>
        jobs.filter(
          job =>
            job.isMembershipOpportunity || job.isMembershipRenewalOpportunity,
        ).length,
      [jobs],
    )

    const totalAnnualRevenueFromSoldMaintenancePlansUsc = useMemo(() => {
      return jobs.reduce((acc, job) => {
        if (job.isMembershipSold) {
          return acc + (job.maintenancePlan?.yearlyPriceUsc ?? 0)
        }
        return acc
      }, 0)
    }, [jobs])

    const conversionRate = useMemo(() => {
      if (totalMembershipOpportunities === 0) {
        return 0
      }
      return maintenancePlansSold / totalMembershipOpportunities
    }, [maintenancePlansSold, totalMembershipOpportunities])

    return (
      <Tile title="Maintenance Plans" loading={loading}>
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">Memberships</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {maintenancePlansSold}
          </Col>
        </Row>
        <TeamPerformanceDivider />
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">Conversion Rate</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {formatPercentage(conversionRate)}
          </Col>
        </Row>
        <TeamPerformanceDivider />
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">Annual Revenue</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {formatUscWholeDollars(
              totalAnnualRevenueFromSoldMaintenancePlansUsc,
            )}
          </Col>
        </Row>
      </Tile>
    )
  },
)
