import { NavLink as BaseNavLink } from 'react-router-dom'
import { AppMenuItem } from '../../components/AppMenu/AppMenuItem'
import { AppMenuSplash } from '../../components/AppMenu/AppMenuSplash'
import { Authorized } from '../../components/Permissions/Authorized/Authorized'
import { usePrincipalUser } from '../../providers/PrincipalUser'
import { ReactRoute } from '../../utils/ReactRoute'

type NavigationLinkProps = {
  route: ReactRoute
  splash?: boolean
  icon?: React.ComponentProps<typeof AppMenuItem>['icon']
  iconClassName?: string
}

export const NavigationLink: React.FC<NavigationLinkProps> = ({
  route,
  splash,
  icon,
  iconClassName,
}: NavigationLinkProps) => {
  const principalUser = usePrincipalUser().principal

  const { path, label, permission, principalUserRequiresCompany } = route

  if (splash) {
    return (
      <BaseNavLink to={{ pathname: path }}>
        <AppMenuSplash />
      </BaseNavLink>
    )
  }

  const item = (
    <BaseNavLink
      tabIndex={0}
      to={{ pathname: path }}
      className={({ isActive }) => (isActive ? 'is-active' : '')}
    >
      {props => {
        const { isActive } = props
        return (
          <AppMenuItem
            icon={icon}
            isActive={isActive}
            iconClassName={iconClassName}
          >
            {label}
          </AppMenuItem>
        )
      }}
    </BaseNavLink>
  )

  if (
    principalUserRequiresCompany &&
    (!principalUser || !principalUser.company)
  ) {
    return null
  }

  return permission ? (
    <Authorized
      to={permission}
      key={path}
      allowsNonCompanyUser={!principalUserRequiresCompany}
    >
      {item}
    </Authorized>
  ) : (
    item
  )
}
