import { gql } from '../../generated'

export const TAGS_ALL_QUERY = gql(/* GraphQL */ `
  query TagsAllQuery($companyGuid: uuid!) {
    tags(
      where: { companyGuid: { _eq: $companyGuid } }
      orderBy: { updatedAt: DESC }
    ) {
      ...Tag
    }
  }
`)

export const TAG_UPSERT_ONE = gql(/* GraphQL */ `
  mutation TagUpsertOne($tag: TagsInsertInput!) {
    insertTagsOne(
      object: $tag
      onConflict: { constraint: tags_pkey, updateColumns: [name, color] }
    ) {
      tagGuid
    }
  }
`)

export const TAG_DELETE_ONE = gql(/* GraphQL */ `
  mutation DeleteTag($companyGuid: uuid!, $tagGuid: uuid!) {
    deleteTags(
      where: { companyGuid: { _eq: $companyGuid }, tagGuid: { _eq: $tagGuid } }
    ) {
      __typename
    }
  }
`)
