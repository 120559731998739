import {
  JobClass,
  MaintenancePlanDefinition,
  formatMoney,
  formatPercentage,
  isNullish,
  nextGuid,
  usCentsToUsd,
} from '@breezy/shared'
import { faBoxArchive, faCopy, faEdit } from '@fortawesome/pro-light-svg-icons'
import { Row, Tooltip } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import FaIconButton from '../../elements/FaIconButton/FaIconButton'
import { trpc } from '../../hooks/trpc'
import { useIntercom } from '../../hooks/useIntercom'
import { useMessage } from '../../utils/antd-utils'
import AddOrEditPlanDefinitionDrawer, {
  MaintenancePlanDefinitionWithIntent,
} from './AddOrEditPlanDefinitionDrawer'
import {
  MaintenanceConfigSection,
  MaintenanceItemContainer,
  MaintenanceNumberLabelPair,
  maintenancePlanItemStandardWidth,
} from './MaintenancePlanElements'

type MaintenancePlanDefinitionsViewProps = {
  readonly planDefinitions: ReadonlyArray<MaintenancePlanDefinition>
  readonly refetch: () => void
}

const MaintenancePlanDefinitionsView = ({
  planDefinitions,
  refetch,
}: MaintenancePlanDefinitionsViewProps) => {
  const [drawerContent, setDrawerContent] = useState<
    MaintenancePlanDefinitionWithIntent | 'create new'
  >()

  useIntercom({ isLauncherVisible: isNullish(drawerContent) })

  return (
    <>
      <AddOrEditPlanDefinitionDrawer
        refetch={refetch}
        open={!!drawerContent}
        onClose={() => setDrawerContent(undefined)}
        planDefinition={
          drawerContent === 'create new' ? undefined : drawerContent
        }
      />
      <MaintenanceConfigSection
        title="Plan Types"
        description="Create the plan types your customers can choose from when signing up for their membership. Creating multiple options allows your customers to choose the right plan and cadence for their equipment needs."
        buttonText="+ Create New Plan"
        buttonOnClick={() => setDrawerContent('create new')}
        empty={
          <MaintenanceItemContainer>
            <p className="regular_14_22 grey7 center-vh text-center font-light">
              No plan types have been created yet.
              <br />
              <br />
              Click the button above to create a new plan type.
            </p>
          </MaintenanceItemContainer>
        }
        // NOTE: Length Check is necessary to correctly cause the Empty Element to be Rendered
        children={
          planDefinitions.length > 0 &&
          [...planDefinitions]
            .sort((a, b) => {
              // Sort de-activated Plan Types last
              if (!a.isAvailableForSale || isNullish(a.yearlyStaticPriceUsc)) {
                return 1
              } else if (!b.isAvailableForSale) {
                return -1
              } else {
                return 0
              }
            })
            .map(planType => (
              <MaintenancePlanItem
                key={planType.maintenancePlanDefinitionGuid}
                {...planType}
                isAvailableForSale={
                  planType.isAvailableForSale &&
                  !isNullish(planType.yearlyStaticPriceUsc)
                }
                beginEdit={() =>
                  setDrawerContent({ intent: 'edit', ...planType })
                }
                beginDuplicate={() =>
                  setDrawerContent({
                    intent: 'create',
                    ...planType,
                    discounts: planType.discounts.map(d => ({
                      maintenancePlanDefinitionDiscountGuid: nextGuid(),
                      discountJobClass: d.discountJobClass,
                      discountRate: d.discountRate,
                    })),
                    benefits: planType.benefits.map(b => ({
                      maintenancePlanDefinitionBenefitGuid: nextGuid(),
                      name: b.name,
                      description: b.description,
                    })),
                  })
                }
                refetch={refetch}
              />
            ))
        }
      />
    </>
  )
}

type MaintenancePlanItemProps = MaintenancePlanDefinition & {
  readonly refetch: () => void
  readonly beginEdit: () => void
  readonly beginDuplicate: () => void
}

const MaintenancePlanItem = (props: MaintenancePlanItemProps) => {
  const message = useMessage()
  const {
    name,
    numVisitCreditsPerYear,
    flare,
    discounts,
    isAvailableForSale: originalIsAvailableForSale,
    beginEdit,
    beginDuplicate,
    refetch,
    yearlyStaticPriceUsc,
  } = props
  const [isAvailableForSale, setIsAvailableForSale] = useState(
    originalIsAvailableForSale,
  )
  const installDiscount = useMemo(
    () => discounts.find(d => d.discountJobClass === JobClass.INSTALL),
    [discounts],
  )
  const serviceDiscount = useMemo(
    () => discounts.find(d => d.discountJobClass === JobClass.SERVICE),
    [discounts],
  )

  const mutateActive =
    trpc.maintenancePlans[
      'maintenance-plans-config:upsert-plan-definition'
    ].useMutation()
  const toggleActive = useCallback(() => {
    setIsAvailableForSale(!isAvailableForSale)
    mutateActive.mutateAsync(
      {
        ...props,
        isAvailableForSale: !isAvailableForSale,
      },
      {
        onSuccess: () => {
          message.success('Updated plan active status')
          refetch()
        },
        onError: () => {
          setIsAvailableForSale(isAvailableForSale)
          message.error('Failed to update plan active status')
        },
      },
    )
  }, [isAvailableForSale, mutateActive, props, refetch, message])

  const displayName = useMemo(() => {
    return isNullish(yearlyStaticPriceUsc) ? `Legacy Plan: ${name}` : name
  }, [name, yearlyStaticPriceUsc])

  const accentColor = isAvailableForSale
    ? flare?.primaryColorHex ?? '#D9D9D9'
    : '#D9D9D9'

  return (
    <Tooltip
      title={
        isNullish(yearlyStaticPriceUsc)
          ? 'Not Available for Sale - Legacy Breezy Plan Type'
          : 'Not Available For Sale'
      }
      // NOTE: Do not visibly show when Active. No ability to disable the Tooltip and
      // also did not desire to have a different structure of DOM Tree for this branch
      mouseEnterDelay={isAvailableForSale ? 99999999 : 0.1}
    >
      {/* This div is necessary for the tooltip to work */}
      <div className="m-0 mb-3 h-[86px] w-full p-0">
        <MaintenanceItemContainer
          className={`row center-children-v h-full w-full p-0 ${
            isAvailableForSale ? '' : 'bg-gray-300'
          } shadow-[0px_1px_2px_0px_rgba(0,0,0,0.03),0px_1px_6px_-1px_rgba(0,0,0,0.02),0px_2px_4px_0px_rgba(0,0,0,0.02)]`}
        >
          <Row className="row center-children-v flex-between flex w-full">
            {accentColor && (
              <div
                style={{
                  backgroundColor: isAvailableForSale
                    ? accentColor
                    : 'transparent',
                }}
                className="relative m-0 ml-[-12px] h-[86px] w-2 rounded-l-md"
              />
            )}
            <div className="column center-children-vh flex">
              <div
                className={`semibold_16_24 ${maintenancePlanItemStandardWidth.trim()} truncate`}
                title={displayName}
              >
                {displayName}
              </div>
              {!isNullish(props.yearlyStaticPriceUsc) && (
                <div className={`${maintenancePlanItemStandardWidth.trim()}`}>
                  {`Flat ${formatMoney(
                    usCentsToUsd(props.yearlyStaticPriceUsc),
                  )}/yr`}
                </div>
              )}
            </div>
            <MaintenanceNumberLabelPair
              kpi={`${numVisitCreditsPerYear} annual visits`}
              description="Visit frequency"
            />
            {installDiscount && (
              <MaintenanceNumberLabelPair
                kpi={formatPercentage(installDiscount.discountRate)}
                description="Install discount"
              />
            )}
            {serviceDiscount && (
              <MaintenanceNumberLabelPair
                kpi={formatPercentage(serviceDiscount.discountRate)}
                description="Service discount"
              />
            )}
            <div className="min-w-[32px]" />
            <Row className="flex gap-2">
              {isAvailableForSale ? (
                <>
                  <FaIconButton
                    icon={faEdit}
                    onClick={beginEdit}
                    tooltip="Edit"
                  />
                </>
              ) : (
                <div className="w-[32px]"></div>
              )}
              {!isNullish(yearlyStaticPriceUsc) && (
                <FaIconButton
                  icon={faCopy}
                  onClick={beginDuplicate}
                  tooltip="Duplicate"
                />
              )}
              {!isNullish(yearlyStaticPriceUsc) && (
                <FaIconButton
                  icon={faBoxArchive}
                  onClick={toggleActive}
                  tooltip={isAvailableForSale ? 'Deactivate' : 'Activate'}
                />
              )}
            </Row>
          </Row>
        </MaintenanceItemContainer>
      </div>
    </Tooltip>
  )
}

export default MaintenancePlanDefinitionsView
