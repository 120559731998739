import { JobLifecycleType, kebabCase } from '@breezy/shared'
import { IconDefinition, faFilter } from '@fortawesome/pro-regular-svg-icons'
import {
  faGear,
  faGrid2,
  faList,
  faMapLocationDot,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Input, Radio, Select } from 'antd'
import { useMemo, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { LoadingSpinner } from '../../../../components/LoadingSpinner'
import {
  JobLifecycleColumnDisplayPropertySettings,
  JobLifecycleDisplayPropertySettings,
  JobLifecycleKanbanView,
  JobLifecycleTimePeriod,
} from '../../../../hooks/useJobLifecyclePersistedDisplay'
import { DisplayDropdown } from '../DisplayDropdown'

export type JobsToolbarProps = {
  selectedLifecycleName: string
  lifecycleOptions: { lifecycleGuid: string; lifecycleName: string }[]
  showClearFilters: boolean
  selectedBoardViewType: JobLifecycleKanbanView
  disableListView: boolean
  selectedLifecycleType?: JobLifecycleType
  selectedClosedJobTimePeriod: JobLifecycleTimePeriod
  showEmptyStatuses: boolean
  hiddenDisplayPropertySettings: (keyof JobLifecycleDisplayPropertySettings)[]
  isLoading: boolean
  onSearch: (search: string) => void
  onLifecycleChange: (lifecycleName: string) => void
  onFilterDrawerOpenToggle: () => void
  onFiltersClear: () => void
  onBoardViewTypeChange: (viewType: JobLifecycleKanbanView) => void
  onClosedJobTimePeriodChange: (timePeriod: JobLifecycleTimePeriod) => void
  onShowEmptyStatusesChange: (visible: boolean) => void
  onColumnPropertiesChange: (
    data: JobLifecycleColumnDisplayPropertySettings,
  ) => void
  onDisplayPropertiesChange: (data: JobLifecycleDisplayPropertySettings) => void
}

export const JobsToolbar = ({
  selectedLifecycleName,
  lifecycleOptions,
  showClearFilters,
  selectedBoardViewType,
  disableListView,
  selectedLifecycleType,
  selectedClosedJobTimePeriod,
  showEmptyStatuses,
  hiddenDisplayPropertySettings,
  isLoading,
  onSearch,
  onLifecycleChange,
  onFilterDrawerOpenToggle,
  onFiltersClear,
  onBoardViewTypeChange,
  onClosedJobTimePeriodChange,
  onShowEmptyStatusesChange,
  onColumnPropertiesChange,
  onDisplayPropertiesChange,
}: JobsToolbarProps) => {
  const [searchTerm, setSearchTerm] = useState('')

  const viewOptions = useMemo(() => {
    const viewOptions: [IconDefinition, JobLifecycleKanbanView, string][] = [
      [faGrid2, 'kanban', 'Board'],
      [faMapLocationDot, 'map', 'Map'],
    ]

    if (!disableListView) {
      viewOptions.push([faList, 'list', 'List'])
    }

    return viewOptions
  }, [disableListView])

  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row items-center gap-x-2">
        <Input
          size="large"
          placeholder="Search..."
          prefix={<AiOutlineSearch />}
          value={searchTerm}
          onChange={e => {
            setSearchTerm(e.target.value)
            onSearch(e.target.value)
          }}
          allowClear
          data-dd-action-name="bz-jobs-search"
          className="w-64"
        />

        <Select
          data-job-lifecycle-dropdown
          size="large"
          popupMatchSelectWidth={false}
          disabled={isLoading}
          value={selectedLifecycleName}
          onChange={lifecycleName => onLifecycleChange(lifecycleName)}
          options={lifecycleOptions.map(option => ({
            value: option.lifecycleName,
            label: (
              <span
                className="inline-block w-full"
                data-dd-action-name={`bz-jobs-lifecycle-dropdown-${kebabCase(
                  option.lifecycleName,
                )}`}
              >
                {option.lifecycleName}
              </span>
            ),
          }))}
          data-dd-action-name="bz-jobs-lifecycle-dropdown"
          dropdownRender={menu => (
            <>
              {menu}
              {isLoading && <LoadingSpinner spinnerClassName="h-6 w-6 mt-3" />}
            </>
          )}
        />

        <Button
          icon={
            <FontAwesomeIcon icon={faFilter} className="text-bz-gray-800" />
          }
          className="text-bz-gray-800"
          type="text"
          size="large"
          onClick={() => onFilterDrawerOpenToggle()}
          data-dd-action-name="bz-jobs-advanced-filter-drawer-open"
          disabled={isLoading}
        >
          Advanced Filters
        </Button>

        {showClearFilters && (
          <Button
            type="text"
            size="large"
            className="text-bz-primary"
            onClick={onFiltersClear}
          >
            Clear All
          </Button>
        )}
      </div>

      <div className="flex flex-row gap-x-2">
        <Radio.Group
          className="mt-2 xl:mt-0"
          onChange={e => {
            onBoardViewTypeChange(e.target.value)
          }}
          value={selectedBoardViewType}
          optionType="button"
          buttonStyle="solid"
          size="large"
        >
          {viewOptions.map(([icon, viewType, label]) => (
            <Radio.Button key={viewType} value={viewType}>
              <div
                key={viewType}
                data-dd-action-name={`bz-jobs-view-${viewType}`}
              >
                <FontAwesomeIcon
                  className="mr-2"
                  icon={icon}
                  data-testid={`job-view-option-${viewType}`}
                />
                {label}
              </div>
            </Radio.Button>
          ))}
        </Radio.Group>

        <DisplayDropdown
          selectedJobLifecycleType={selectedLifecycleType}
          closedJobTimePeriod={selectedClosedJobTimePeriod}
          setClosedJobTimePeriod={onClosedJobTimePeriodChange}
          showEmptyStatuses={showEmptyStatuses}
          setShowEmptyStatuses={onShowEmptyStatusesChange}
          setColumnPropertiesSettings={onColumnPropertiesChange}
          setDisplayPropertiesSettings={onDisplayPropertiesChange}
          hiddenDisplayPropertySettings={hiddenDisplayPropertySettings}
        />

        <Link to="/settings/lifecycles">
          <Button
            size="large"
            data-dd-action-name="bz-jobs-lifecycle-settings-link"
            icon={<FontAwesomeIcon icon={faGear} />}
          />
        </Link>
      </div>
    </div>
  )
}
