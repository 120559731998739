import {
  dateRangeToUnitAndN,
  getDateGroupingTypeForRange,
  R,
} from '@breezy/shared'
import { faArrowTrendUp } from '@fortawesome/pro-light-svg-icons'
import React, { useCallback, useMemo } from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Card } from '../../elements/Card/Card'
import { LineChart, LineData } from '../../elements/Charts/LineChart'
import { Link } from '../../elements/Link/Link'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import {
  MaintenancePlanWizard,
  useMaintenancePlanWizardFlags,
} from '../CreateOrEditMaintenancePlanPage/MaintenancePlanWizard'
import { NoDataOverlay } from './NoDataOverlay'
import {
  StandardReportingDateRangePicker,
  useStandardReportingDateRangePickerState,
} from './ReportingDateRangePicker/StandardReportingDateRangePicker'
import { DUMMY_MAINTENANCE_PLAN_DATA } from './dummyBackgroundData'
import { getStandardXAxisValue, useStandardXAxisFormatter } from './utils'

const Y_AXIS_FORMATTER = (value: number) => value.toLocaleString('en-us')

type MaintenancePlansUnusedCreditsWidgetProps = {
  // Not optional. I'm expecting at least a fixed height class
  className: string
}
export const MaintenancePlansUnusedCreditsWidget =
  React.memo<MaintenancePlansUnusedCreditsWidgetProps>(({ className }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const [dateRange, setDateRange] = useStandardReportingDateRangePickerState()

    const queryParams = useMemo(
      () => dateRangeToUnitAndN(tzId, dateRange),
      [dateRange, tzId],
    )

    const maintenancePlanReportQuery = trpc.maintenancePlans[
      'maintenance-plans:report:periodic-summary'
    ].useQuery({ ...queryParams, timePastN: queryParams.timePastN })

    const hasData = useMemo(() => {
      for (const bucket of R.values(maintenancePlanReportQuery.data ?? {})) {
        if (bucket.numActivePlans > 0) {
          return true
        }
      }
      return false
    }, [maintenancePlanReportQuery.data])

    const [xAxisValues, data, totalUnusedVisits] = useMemo(() => {
      const data =
        hasData && maintenancePlanReportQuery.data
          ? maintenancePlanReportQuery.data
          : DUMMY_MAINTENANCE_PLAN_DATA
      // Normally I don't condone the use of .sort(), but it's too convenient here. We have a list of strings we just
      // made and want them sorted in alphabetical order.
      const orderedBucketKeys = R.keys(data).sort()

      const xAxisValues: string[] = []
      const values: number[] = []

      const groupingType = getDateGroupingTypeForRange(dateRange, tzId)

      for (let i = 0; i < orderedBucketKeys.length; ++i) {
        const bucketTimestamp = orderedBucketKeys[i]
        xAxisValues.push(
          getStandardXAxisValue(tzId, bucketTimestamp, groupingType),
        )
        values.push(data[bucketTimestamp].totalUnusedCredits)
      }
      // The last bucket is the "current" bucket. This doesn't go in the chart, but it has the current total.
      const currentBucket =
        data[orderedBucketKeys[orderedBucketKeys.length - 1]]

      return [xAxisValues, values, currentBucket?.totalUnusedCredits ?? 0]
    }, [dateRange, hasData, maintenancePlanReportQuery.data, tzId])

    const xAxisFormatter = useStandardXAxisFormatter(dateRange, tzId)

    const lineChartData = useMemo<LineData[]>(
      () => [
        {
          label: 'Unused Visits',
          data,
        },
      ],
      [data],
    )

    const hasNoDataOverlay = !hasData

    const [
      maintenancePlanWizardOpen,
      openMaintenancePlanWizard,
      closeMaintenancePlanWizard,
    ] = useMaintenancePlanWizardFlags(
      'mpw',
      'maintenance-plans-unused-credits-empty',
    )

    const onCloseMpWizard = useCallback(() => {
      closeMaintenancePlanWizard()
      maintenancePlanReportQuery.refetch()
    }, [closeMaintenancePlanWizard, maintenancePlanReportQuery])

    return (
      <Card
        title="Maintenance Plans Unused Visits"
        className={className}
        titleAction={
          !hasNoDataOverlay && (
            <StandardReportingDateRangePicker
              range={dateRange}
              setRange={setDateRange}
            />
          )
        }
      >
        {maintenancePlanReportQuery.isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="flex h-full flex-col">
              <div className="pb-4 text-base font-semibold text-bz-gray-900">
                Current Total: {totalUnusedVisits}
              </div>
              <div className="flex-1">
                <LineChart
                  data={lineChartData}
                  yAxisFormatter={Y_AXIS_FORMATTER}
                  xAxisValues={xAxisValues}
                  xAxisFormatter={xAxisFormatter}
                />
              </div>
            </div>
            {hasNoDataOverlay && (
              <NoDataOverlay
                className="inset-[-16px]"
                icon={faArrowTrendUp}
                title="No maintenance plan data"
                link={
                  <Link onClick={openMaintenancePlanWizard}>
                    Create Maintenance Plan
                  </Link>
                }
              >
                <div className="text-center">
                  Customer unused maintenance plan visits will be displayed here
                  once a customer has purchased a maintenance plan.
                </div>
              </NoDataOverlay>
            )}
          </>
        )}
        {maintenancePlanWizardOpen && (
          <MaintenancePlanWizard
            onRamp="maintenance-plans-unused-credits-empty"
            onClose={onCloseMpWizard}
          />
        )}
      </Card>
    )
  })
