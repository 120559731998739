import { BzDateFns, BzDuration, IsoDateString } from '@breezy/shared'
import {
  faPhoneArrowDownLeft,
  faPhoneArrowUpRight,
  faPhoneMissed,
  faVoicemail,
} from '@fortawesome/pro-solid-svg-icons'
import React, { useState } from 'react'
import FaIconWithCircularBackground from '../../elements/FaIconWithCircularBackground/FaIconWithCircularBackground'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { CommAvatar } from './CommAvatar'
import { RecentPhoneCall } from './CommsPage.gql'
import {
  getAvatarText,
  getBreezyFormatExternalPhoneNumber,
  getDisplayNameShortFormat,
  getFormattedDate,
  getUserAvatarText,
  getUserDisplayName,
} from './CommUtils'

import { Typography } from 'antd'
import { useMemo } from 'react'

type ConvoElementProps = {
  call: RecentPhoneCall
}

export const ConvoElement: React.FC<ConvoElementProps> = ({ call }) => {
  const avatarText = getAvatarText(call.contact)
  const phoneNumber = getBreezyFormatExternalPhoneNumber(call)
  const displayName = getDisplayNameShortFormat(call.contact, phoneNumber)
  const userDisplayName = getUserDisplayName(call.user)
  const userAvatarText = getUserAvatarText(call.user)

  if (call.direction === 'inbound' && !call.integratedPhoneMissedCalls.length) {
    return (
      <ConvoInboundAnsweredCallElement
        startedAt={call.startedAt}
        endedAt={call.endedAt}
        avatarText={avatarText}
        displayName={displayName}
        recordingUrl={call.integratedPhoneCallRecording?.recordingUrl}
        transcript={call.integratedPhoneCallRecording?.recordingTranscript}
      />
    )
  } else if (
    call.direction === 'inbound' &&
    call.integratedPhoneMissedCalls.length &&
    call.integratedPhoneMissedCalls[0].integratedPhoneVoicemails.length
  ) {
    const voicemail =
      call.integratedPhoneMissedCalls[0].integratedPhoneVoicemails[0]
    return (
      <ConvoInboundVoicemailElement
        startedAt={call.startedAt}
        avatarText={avatarText}
        displayName={displayName}
        recordingUrl={voicemail.recordingUrl}
        transcript={voicemail.recordingTranscript}
      />
    )
  } else if (
    call.direction === 'inbound' &&
    call.integratedPhoneMissedCalls.length
  ) {
    return (
      <ConvoInboundMissedCallElement
        startedAt={call.startedAt}
        avatarText={avatarText}
        displayName={displayName}
      />
    )
  } else if (call.direction === 'outbound') {
    return (
      <ConvoOutboundAnsweredCallElement
        startedAt={call.startedAt}
        endedAt={call.endedAt}
        avatarText={userAvatarText}
        displayName={userDisplayName}
      />
    )
  }

  return (
    <div className="h-[38px] text-gray-500">
      ... Element Type - To be implemented...
    </div>
  )
}

type ConvoInboundAnsweredCallElementProps = {
  avatarText: string
  displayName: string
  startedAt: IsoDateString
  endedAt?: IsoDateString
  recordingUrl?: string
  transcript?: string
}

export const ConvoInboundAnsweredCallElement: React.FC<
  ConvoInboundAnsweredCallElementProps
> = ({
  startedAt,
  endedAt,
  avatarText,
  displayName,
  recordingUrl,
  transcript,
}) => {
  const now = new Date()
  const endTime = endedAt ? new Date(endedAt) : now
  const startTime = new Date(startedAt)
  const durationInMilliseconds = endTime.getTime() - startTime.getTime()
  const duration = new BzDuration(durationInMilliseconds).formatMSS()

  const formattedTranscript = useMemo(() => {
    if (!transcript) return null

    return transcript
      .split('\n')
      .filter(line => line.trim() !== '')
      .map((line, index) => {
        if (line.startsWith('Agent:') || line.startsWith('Customer:')) {
          return `\n${line}`
        }
        return line
      })
      .join('\n')
      .replace(/\n{2,}/g, '\n')
  }, [transcript])

  return (
    <ConvoShellInbound
      startedAt={startedAt}
      avatarText={avatarText}
      displayName={displayName}
    >
      <div className="flex max-w-[400px] flex-col gap-y-3">
        <div className="flex items-center gap-x-2 text-sm text-gray-600">
          <FaIconWithCircularBackground
            iconDefinition={faPhoneArrowDownLeft}
            backgroundColor="#8d8d8f"
            diameterPx={36}
            color="white"
            noRightMargin
          />
          <div>
            <div className="text-sm font-semibold leading-5 text-[#1F1F1F]">
              Call ended
            </div>
            <div className="pr-1 text-[13px] font-normal leading-[16px] text-[#595959]">
              You answered · {duration}
            </div>
          </div>
        </div>
        {transcript && (
          <div className="w-[400px]">
            <div className="text-[13px] font-normal leading-[18px] text-[#595959]">
              <Typography.Paragraph
                ellipsis={{
                  rows: 12,
                  expandable: true,
                  symbol: 'View More',
                }}
                className="mb-0 text-[13px] font-normal leading-[18px] text-[#595959]"
              >
                <div className="text-xs font-semibold leading-5 text-[#1F1F1F]">
                  Call Transcript
                </div>
                {formattedTranscript &&
                  formattedTranscript.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {index < formattedTranscript.split('\n').length - 1 && (
                        <div className="h-[4px]"></div>
                      )}
                    </React.Fragment>
                  ))}
              </Typography.Paragraph>
            </div>
          </div>
        )}
        {recordingUrl && (
          <audio controls className="h-10 w-full min-w-[320px] rounded-md">
            <source src={recordingUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        )}
      </div>
    </ConvoShellInbound>
  )
}

type ConvoOutboundAnsweredCallElementProps = {
  avatarText: string
  displayName: string
  startedAt: IsoDateString
  endedAt?: IsoDateString
}

export const ConvoOutboundAnsweredCallElement: React.FC<
  ConvoOutboundAnsweredCallElementProps
> = ({ startedAt, endedAt, avatarText, displayName }) => {
  const now = new Date()
  const endTime = endedAt ? new Date(endedAt) : now
  const startTime = new Date(startedAt)
  const durationInMilliseconds = endTime.getTime() - startTime.getTime()
  const duration = new BzDuration(durationInMilliseconds).formatMSS()

  return (
    <ConvoShellOutbound
      startedAt={startedAt}
      avatarText={avatarText}
      displayName={displayName}
    >
      <div className="flex items-center gap-x-2 text-sm text-gray-600">
        <FaIconWithCircularBackground
          iconDefinition={faPhoneArrowUpRight}
          backgroundColor="#1677ff"
          diameterPx={36}
          color="white"
          noRightMargin
        />
        <div>
          <div className="text-sm font-semibold leading-5 text-[#1F1F1F]">
            Call ended
          </div>
          <div className="mr-1 pr-1 text-[13px] font-normal leading-[16px] text-[#595959]">
            You called · {duration}
          </div>
        </div>
      </div>
    </ConvoShellOutbound>
  )
}

type ConvoInboundMissedCallElementProps = {
  avatarText: string
  displayName: string
  startedAt: IsoDateString
}

export const ConvoInboundMissedCallElement: React.FC<
  ConvoInboundMissedCallElementProps
> = ({ startedAt, avatarText, displayName }) => {
  return (
    <ConvoShellInbound
      startedAt={startedAt}
      avatarText={avatarText}
      displayName={displayName}
    >
      <div className="flex items-center gap-x-2 text-sm text-gray-600">
        <FaIconWithCircularBackground
          iconDefinition={faPhoneMissed}
          backgroundColor="#F5222D"
          diameterPx={36}
          color="white"
          noRightMargin
        />
        <div>
          <div className="text-sm font-semibold leading-5 text-[#1F1F1F]">
            Missed call
          </div>
          <div className="pr-1 text-[13px] font-normal leading-[16px] text-[#595959]">
            You didn't answer
          </div>
        </div>
      </div>
    </ConvoShellInbound>
  )
}

type ConvoInboundVoicemailElementProps = {
  avatarText: string
  displayName: string
  startedAt: IsoDateString
  recordingUrl: string
  transcript?: string
}

export const ConvoInboundVoicemailElement: React.FC<
  ConvoInboundVoicemailElementProps
> = ({ startedAt, avatarText, displayName, recordingUrl, transcript }) => {
  return (
    <ConvoShellInbound
      startedAt={startedAt}
      avatarText={avatarText}
      displayName={displayName}
    >
      <div className="flex max-w-[400px] flex-col gap-y-3">
        <div className="flex items-center gap-x-2 text-sm text-gray-600">
          <FaIconWithCircularBackground
            iconDefinition={faVoicemail}
            backgroundColor="#F5222D"
            diameterPx={36}
            color="white"
            noRightMargin
          />
          <div>
            <div className="text-sm font-semibold leading-5 text-[#1F1F1F]">
              Missed call
            </div>
            <div className="pr-1 text-[13px] font-normal leading-[16px] text-[#595959]">
              You didn't answer
            </div>
          </div>
        </div>
        {transcript && (
          <div className="w-[400px]">
            <div className="text-xs font-semibold leading-5 text-[#1F1F1F]">
              Voicemail Transcript
            </div>
            <div className="mt-1 text-[13px] font-normal leading-[18px] text-[#595959]">
              {transcript}
            </div>
          </div>
        )}
        {recordingUrl && (
          <audio controls className="h-10 w-full min-w-[320px] rounded-md">
            <source src={recordingUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        )}
      </div>
    </ConvoShellInbound>
  )
}

type ConvoShellInboundProps = {
  startedAt: IsoDateString
  avatarText: string
  displayName: string
  children: React.ReactNode
}

const useFormattedDate = (startedAt: IsoDateString): string => {
  const tz = useExpectedCompanyTimeZoneId()

  return useMemo(() => {
    const today = BzDateFns.now(tz)
    const startDate = BzDateFns.parseISO(startedAt, tz)
    return getFormattedDate(startDate, today)
  }, [startedAt, tz])
}

export const ConvoShellInbound: React.FC<ConvoShellInboundProps> = ({
  startedAt,
  children,
  avatarText,
  displayName,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const formattedDate = useFormattedDate(startedAt)

  return (
    <div
      className="flex flex-col gap-y-6"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="w-full text-center text-xs font-normal leading-5 text-[#595959]">
        {formattedDate}
      </div>
      <div className="flex h-full">
        <div className="group relative flex items-end gap-x-2">
          <CommAvatar avatarText={avatarText} />
          <div className="flex-1">
            <div className="mb-[2px] pl-[10px] text-xs font-normal leading-5 text-[#595959]">
              {displayName}
            </div>
            <div
              className={`rounded-2xl p-[10px] ${
                isHovered ? 'bg-[#F0F0F0]' : 'bg-[#F5F5F5]'
              }`}
            >
              {children}
            </div>
          </div>
        </div>
        {isHovered && <HoveredTime startedAt={startedAt} />}
      </div>
    </div>
  )
}

type ConvoShellOutboundProps = {
  startedAt: IsoDateString
  avatarText: string
  displayName: string
  children: React.ReactNode
}

export const ConvoShellOutbound: React.FC<ConvoShellOutboundProps> = ({
  startedAt,
  children,
  avatarText,
  displayName,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const formattedDate = useFormattedDate(startedAt)

  return (
    <div
      className="flex flex-col space-y-2"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="w-full text-center text-xs font-normal leading-5 text-[#595959]">
        {formattedDate}
      </div>
      <div className="flex h-full items-end justify-end gap-x-2">
        {isHovered && <HoveredTime startedAt={startedAt} />}
        <div className="group relative flex items-end gap-x-2">
          <div className="flex-1">
            <div className="mb-[2px] pl-[10px] text-xs font-normal leading-5 text-[#595959]">
              {displayName}
            </div>
            <div className="rounded-2xl bg-[#e6f4ff] p-[10px]">{children}</div>
          </div>
        </div>
        <CommAvatar avatarText={avatarText} />
      </div>
    </div>
  )
}

type HoveredTimeProps = {
  startedAt: IsoDateString
}

const HoveredTime: React.FC<HoveredTimeProps> = ({ startedAt }) => {
  const tz = useExpectedCompanyTimeZoneId()
  const localDate = BzDateFns.parseISO(startedAt, tz)
  return (
    <div
      className={`mx-2 mb-3 mt-auto flex h-full items-end justify-end text-xs font-normal leading-5 text-[#595959]`}
    >
      {localDate.toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      })}
    </div>
  )
}
