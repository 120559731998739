import {
  EquipmentType,
  JobClass,
  JobToEquipmentRelationship,
  formatMaybeEquipmentType,
} from '@breezy/shared'
import { Form, Select } from 'antd'
import { useCallback, useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { ChangeJobTypeFormSchema } from '../../change-job-type-form-schema'

const mapRelationshipTypeFromJobClassForPreexistingEquipment = (
  jobClass: JobClass,
): JobToEquipmentRelationship => {
  return jobClass
    ? jobClass === JobClass.SERVICE
      ? JobToEquipmentRelationship.SERVICING
      : jobClass === JobClass.INSTALL
      ? JobToEquipmentRelationship.DEACTIVATING
      : jobClass === JobClass.MAINTENANCE
      ? JobToEquipmentRelationship.MAINTAINING
      : JobToEquipmentRelationship.LINKED
    : JobToEquipmentRelationship.LINKED
}

export type PreexistingEquipmentSelectProps = {
  selectedJobType: { jobTypeGuid: string; name: string; jobClass: JobClass }
}

export const PreexistingEquipmentSelect = ({
  selectedJobType,
}: PreexistingEquipmentSelectProps) => {
  const { control } = useFormContext<ChangeJobTypeFormSchema>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'preexistingEquipment',
  })

  const preexistingEquipmentTypeOptions: {
    label: string
    value: EquipmentType
  }[] = useMemo(
    () =>
      Object.values(EquipmentType).map(equipmentType => ({
        label: formatMaybeEquipmentType(equipmentType),
        value: equipmentType,
      })),
    [],
  )

  const preexistingEquipmentFormLabel: string = useMemo(() => {
    switch (selectedJobType.jobClass) {
      case JobClass.SERVICE:
        return 'Equipment to service'
      case JobClass.MAINTENANCE:
        return 'Equipment to maintain'
      case JobClass.ESTIMATE_REPAIR:
        return 'Equipment to repair'
      case JobClass.ESTIMATE_REPLACE:
      case JobClass.INSTALL:
      case JobClass.SALES:
        return 'Equipment to replace'
      case JobClass.WARRANTY:
      case JobClass.CALLBACK:
      case JobClass.UNKNOWN:
        return 'What type of equipment?'
    }
  }, [selectedJobType])

  const onEquipmentTypeSelected = useCallback(
    (equipmentType: EquipmentType) => {
      append({
        equipmentType,
        equipmentAge: '',
        relationshipType:
          mapRelationshipTypeFromJobClassForPreexistingEquipment(
            selectedJobType.jobClass,
          ),
      })
    },
    [append, selectedJobType.jobClass],
  )

  const onEquipmentTypeDeselected = useCallback(
    (equipmentType: EquipmentType) => {
      const equipmentTypeIdx = fields.findIndex(
        field => field.equipmentType === equipmentType,
      )
      if (equipmentTypeIdx < 0) {
        return
      }

      remove(equipmentTypeIdx)
    },
    [fields, remove],
  )

  return (
    <Form.Item label={preexistingEquipmentFormLabel}>
      <Select<EquipmentType[]>
        mode="multiple"
        value={fields.map(field => field.equipmentType)}
        options={preexistingEquipmentTypeOptions}
        onSelect={onEquipmentTypeSelected}
        onDeselect={onEquipmentTypeDeselected}
      />
    </Form.Item>
  )
}
