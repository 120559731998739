import {
  EquipmentCoverageDefinition,
  EquipmentType,
  formatEquipmentType,
  fuzzyMatch,
  nextGuid,
} from '@breezy/shared'
import { faBox } from '@fortawesome/pro-light-svg-icons'
import { Drawer, Form, Input, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useCallback, useState } from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { FormCancelSubmitButtons } from '../../components/form-fields/FormCancelSubmitButtons/FormCancelSubmitButtons'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { trpc } from '../../hooks/trpc'
import { useIntercom } from '../../hooks/useIntercom'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { m } from '../../utils/react-utils'
import { validatorPositiveNonZeroFloatNumber } from '../../utils/validators'

type AddOrEditEquipmentCoverageDrawerProps = {
  readonly refetch: () => void
  readonly onClose: () => void
  readonly open: boolean
  readonly equipmentCoverage?: EquipmentCoverageDefinition
  readonly allowedEquipmentCoverageTypes: EquipmentType[]
}

const AddOrEditEquipmentCoverageForm = m(
  ({
    equipmentCoverage,
    allowedEquipmentCoverageTypes,
    onClose,
    refetch,
  }: AddOrEditEquipmentCoverageDrawerProps) => {
    const message = useMessage()
    const companyGuid = useExpectedCompany().companyGuid
    const mutation =
      trpc.maintenancePlans[
        'maintenance-plans-config:upsert-equipment'
      ].useMutation()
    const [form] = useForm<EquipmentCoverageDefinition>()
    const [isUploading, setIsUploading] = useState(false)

    const submit = useCallback(
      (values: EquipmentCoverageDefinition) => {
        setIsUploading(true)
        mutation.mutate(
          {
            maintenancePlanEquipmentCoverageDefinitionGuid:
              equipmentCoverage?.maintenancePlanEquipmentCoverageDefinitionGuid ??
              nextGuid(),
            companyGuid,
            equipmentType: values.equipmentType,
            planPricePerServiceUsd: Number(values.planPricePerServiceUsd),
            regularPricePerServiceUsd: Number(values.regularPricePerServiceUsd),
            isAvailableForSale: equipmentCoverage?.isAvailableForSale ?? true,
          },
          {
            onSuccess: () => {
              refetch()
              onClose()
              setIsUploading(false)
            },
            onError: () => {
              message.error('Failed to save equipment coverage')
              setIsUploading(false)
            },
          },
        )
      },
      [mutation, refetch, onClose, companyGuid, equipmentCoverage, message],
    )

    return (
      <div className="h-full">
        {isUploading && <LoadingSpinner />}
        {!isUploading && (
          <Form
            form={form}
            layout="vertical"
            onFinish={submit}
            validateTrigger="onBlur"
            initialValues={equipmentCoverage}
            className="h-full"
          >
            <div className="flex-between column h-full">
              <div>
                <div className="grey7 regular_14_22 mb-4">
                  Adding an equipment category will allow any equipment items
                  from this category be available for a maintenance plan.
                </div>
                <Form.Item
                  name="equipmentType"
                  label="Equipment Type"
                  rules={[
                    {
                      required: true,
                      message: 'Please select an equipment type',
                    },
                  ]}
                >
                  <Select
                    defaultValue={
                      equipmentCoverage
                        ? formatEquipmentType(equipmentCoverage?.equipmentType)
                        : undefined
                    }
                    disabled={!!equipmentCoverage}
                    showSearch
                    filterOption={(inputValue, option) => {
                      return option?.children
                        ? fuzzyMatch(`${option?.children}`, inputValue)
                        : false
                    }}
                  >
                    {allowedEquipmentCoverageTypes.map(type => (
                      <Select.Option key={type} value={type}>
                        {formatEquipmentType(type)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="planPricePerServiceUsd"
                  label="Plan Price Per Service"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a plan price per service',
                      validator: validatorPositiveNonZeroFloatNumber('Price'),
                    },
                  ]}
                  tooltip="The membership plan price for a single service of this equipment"
                >
                  <Input
                    type="number"
                    min={0}
                    max={9999}
                    placeholder="The membership plan price for a single service of this equipment"
                  />
                </Form.Item>

                <Form.Item
                  name="regularPricePerServiceUsd"
                  label="Regular Price Per Service"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a regular price per service',
                      validator: validatorPositiveNonZeroFloatNumber('Price'),
                    },
                  ]}
                  tooltip="The regular price for a single service of this equipment"
                >
                  <Input
                    type="number"
                    min={0}
                    max={9999}
                    placeholder="The regular price for a single service of this equipment"
                  />
                </Form.Item>
              </div>
              <FormCancelSubmitButtons
                onCancel={onClose}
                primaryButtonText="Save"
              />
            </div>
          </Form>
        )}
      </div>
    )
  },
)

const AddOrEditEquipmentCoverageDrawer = m(
  ({
    open,
    refetch,
    onClose,
    equipmentCoverage,
    allowedEquipmentCoverageTypes,
  }: AddOrEditEquipmentCoverageDrawerProps) => {
    useIntercom({ isLauncherVisible: !open })

    return (
      <Drawer
        title={
          <PageTitle
            icon={faBox}
            title={
              equipmentCoverage
                ? 'Edit Equipment Coverage'
                : 'New Equipment Coverage'
            }
          />
        }
        onClose={onClose}
        open={open}
        width={624}
        destroyOnClose
      >
        <AddOrEditEquipmentCoverageForm
          open={open}
          refetch={refetch}
          onClose={onClose}
          equipmentCoverage={equipmentCoverage}
          allowedEquipmentCoverageTypes={allowedEquipmentCoverageTypes}
        />
      </Drawer>
    )
  },
)

export default AddOrEditEquipmentCoverageDrawer
