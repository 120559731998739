import './common/wdyr' // Developer tool to help understand why a component is rendering in non-production environments

import { OfficeRoutes } from '@breezy/shared'
import { BackButtonOverridesWrapper } from '../components/BackButtonOverridesWrapper/BackButtonOverridesWrapper'
import { GlobalSearchWrapper } from '../components/GlobalSearch'
import { Scene } from '../layout/Scene/Scene'
import { initializeDatadogRum } from '../providers/AnalyticsWrapper'
import { BlockerWrapper } from '../providers/BlockerWrapper'
import Router from '../providers/Router'
import { OfficeReactRoutes } from '../utils/OfficeReactRoutes'
import { Applications } from '../utils/application-type'
import { AuthenticatedAppWrapper, withApp } from './common/CommonAppWrapper'

initializeDatadogRum()

withApp(
  <AuthenticatedAppWrapper>
    <Router
      applicationContext={Applications.OFFICE}
      routes={OfficeReactRoutes}
      routeWrapper={(children, header) => (
        <BlockerWrapper>
          <BackButtonOverridesWrapper>
            <GlobalSearchWrapper>
              <Scene header={header} routes={OfficeRoutes}>
                {children}
              </Scene>
            </GlobalSearchWrapper>
          </BackButtonOverridesWrapper>
        </BlockerWrapper>
      )}
    />
  </AuthenticatedAppWrapper>,
)
