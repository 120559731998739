import classNames from 'classnames'
import React from 'react'

export type BaseCardProps = React.HTMLAttributes<HTMLDivElement> & {
  ribbonColorClassName: string
}

export const BaseCard = React.memo<BaseCardProps>(
  ({ ribbonColorClassName, children, className, ...rest }) => {
    return (
      <div
        className={classNames(
          'flex max-h-[740px] min-h-0 w-[307px] max-w-[307px] flex-col overflow-hidden rounded-xl',
          className,
        )}
        {...rest}
      >
        <div className="schedule-base-card flex max-h-full min-h-0 flex-1 flex-col text-sm leading-[22px]">
          <div className={classNames('min-h-2 w-full', ribbonColorClassName)} />
          <div className="min-h-0 flex-1 space-y-4 overflow-auto p-3">
            {children}
          </div>
        </div>
      </div>
    )
  },
)

type CardSectionProps = React.PropsWithChildren<{
  title: React.ReactNode
  className?: string
}>

export const CardSection = React.memo<CardSectionProps>(
  ({ title, children, className }) => {
    return (
      <div className={className}>
        <div className="mb-1 font-semibold">{title}</div>
        {children}
      </div>
    )
  },
)
