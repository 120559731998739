import { R } from '@breezy/shared'
import {
  Button,
  Checkbox,
  Collapse,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Select,
  Table,
  Tooltip,
} from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { trpc } from '../../../hooks/trpc'

import {
  ChanceWeightsSchema,
  DBSeederPerformanceReportItem,
  DBSeederRequestBase,
  DB_SEEDER_SETTINGS_PRESETS,
  FourChanceWeightsSchema,
  TimeZoneId,
  dbSeederRequestGroups,
  dbSeederRequestSchema,
  salesDemoDBSeederRequest,
  timeZoneIdSchema,
} from '@breezy/shared'
import { faker } from '@faker-js/faker'
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  createTsForm,
  useDescription,
  useNumberFieldInfo,
  useStringFieldInfo,
  useTsController,
} from '@ts-react/form'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { useMessage } from '../../../utils/antd-utils'

const TextField = React.memo(() => {
  const {
    field: { onChange, value },
    error,
  } = useTsController<string>()

  const { label, placeholder } = useStringFieldInfo()

  return (
    <Form.Item
      label={
        <div className="font-normal">
          {label}
          {placeholder && (
            <Tooltip title={placeholder}>
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="ml-2 text-bz-gray-700"
              />
            </Tooltip>
          )}
        </div>
      }
      validateStatus={error ? 'error' : 'success'}
      help={error?.errorMessage}
    >
      <Input
        className="min-w-full"
        onChange={e => onChange(e.target.value)}
        value={value}
      />
    </Form.Item>
  )
})

type BaseNumberInputProps = {
  className?: string
  value?: number
  onChange: (value: number) => void
  isInt?: boolean
  min?: number
  max?: number
}

const BaseNumberInput = React.memo<BaseNumberInputProps>(
  ({ className, value, onChange, isInt = false, min, max }) => {
    const [inputValue, setInputValue] = useState(`${value ?? ''}`)

    useEffect(() => {
      setInputValue(`${value ?? ''}`)
    }, [value])

    const onBlur = useCallback(() => {
      const val = isInt ? parseInt(inputValue) : parseFloat(inputValue)
      if (isNaN(val)) {
        setInputValue(`${value ?? ''}`)
      } else {
        onChange(val)
        setInputValue(`${val}`)
      }
    }, [inputValue, isInt, onChange, value])

    return (
      <InputNumber
        className={className}
        min={`${min ?? ''}`}
        max={`${max ?? ''}`}
        value={inputValue}
        onChange={value => setInputValue(value ?? '')}
        onBlur={onBlur}
      />
    )
  },
)

const NumberField = React.memo(() => {
  const {
    field: { onChange, value },
    error,
  } = useTsController<number>()

  const { label, placeholder, minValue, maxValue, isInt } = useNumberFieldInfo()

  return (
    <Form.Item
      label={
        <div className="font-normal">
          {label}
          {placeholder && (
            <Tooltip title={placeholder}>
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="ml-2 text-bz-gray-700"
              />
            </Tooltip>
          )}
        </div>
      }
      validateStatus={error ? 'error' : 'success'}
      help={error?.errorMessage}
    >
      <BaseNumberInput
        className="min-w-full"
        value={value}
        onChange={onChange}
        min={minValue}
        max={maxValue}
        isInt={isInt}
      />
    </Form.Item>
  )
})

const BooleanField = React.memo(() => {
  const {
    field: { onChange, value },
    error,
  } = useTsController<boolean>()
  const { label, placeholder } = useDescription()

  return (
    <Form.Item
      label={
        <div className="font-normal">
          {label}
          {placeholder && (
            <Tooltip title={placeholder}>
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="ml-2 text-bz-gray-700"
              />
            </Tooltip>
          )}
        </div>
      }
      validateStatus={error ? 'error' : 'success'}
      help={error?.errorMessage}
    >
      <Checkbox checked={value} onChange={e => onChange(e.target.checked)} />
    </Form.Item>
  )
})

const TimeZoneSelectField = () => {
  const {
    field: { onChange, value },
    error,
  } = useTsController<TimeZoneId[]>()

  const { label, placeholder } = useDescription()

  return (
    <Form.Item
      label={
        <div className="font-normal">
          {label}
          {placeholder && (
            <Tooltip title={placeholder}>
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="ml-2 text-bz-gray-700"
              />
            </Tooltip>
          )}
        </div>
      }
      validateStatus={error ? 'error' : 'success'}
      help={error?.errorMessage}
    >
      <Select
        options={[
          { value: 'America/Los_Angeles' },
          { value: 'America/Denver' },
          { value: 'America/Chicago' },
          { value: 'America/New_York' },
          { value: 'Europe/Madrid' },
          { value: 'Asia/Manila' },
          { value: 'Africa/Nairobi' },
          { value: 'UTC' },
        ]}
        value={value}
        onChange={value => onChange(value)}
      />
    </Form.Item>
  )
}

const ChanceWeightsField = () => {
  const {
    field: { onChange, value: valueOrUndefined },
    error,
  } = useTsController<z.infer<typeof ChanceWeightsSchema>>()

  const values = useMemo(
    () => valueOrUndefined ?? [0, 0, 0],
    [valueOrUndefined],
  )

  const { label, placeholder } = useDescription()

  const [hasError, errorMessage] = useMemo(() => {
    if (error) {
      return [true, error?.errorMessage]
    }
    const total = R.sum(values)
    // Account for 0.9999999999
    if (total > 1 || 1 - total > 0.0000001) {
      return [true, `Total must be 1, currently ${total}`]
    }
    return [false, '']
  }, [error, values])

  return (
    <Form.Item
      label={
        <div className="font-normal">
          {label}
          {placeholder && (
            <Tooltip title={placeholder}>
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="ml-2 text-bz-gray-700"
              />
            </Tooltip>
          )}
        </div>
      }
      validateStatus={hasError ? 'error' : 'success'}
      help={errorMessage}
    >
      <div className="space-x-2">
        {[0, 1, 2].map(key => (
          <BaseNumberInput
            key={key}
            min={0}
            max={100}
            value={values[key]}
            onChange={value =>
              // Type assertion because `R.update` doesn't know it's a tuple and thinks it's just number[]
              onChange(R.update(key, value, values) as [number, number, number])
            }
          />
        ))}
      </div>
    </Form.Item>
  )
}

const FourChanceWeightsField = () => {
  const {
    field: { onChange, value: valueOrUndefined },
    error,
  } = useTsController<z.infer<typeof FourChanceWeightsSchema>>()

  const values = useMemo(
    () => valueOrUndefined ?? [0, 0, 0, 0],
    [valueOrUndefined],
  )

  const { label, placeholder } = useDescription()

  const [hasError, errorMessage] = useMemo(() => {
    if (error) {
      return [true, error?.errorMessage]
    }
    const total = R.sum(values)
    // Account for 0.9999999999
    if (total > 1 || 1 - total > 0.0000001) {
      return [true, `Total must be 1, currently ${total}`]
    }
    return [false, '']
  }, [error, values])

  return (
    <Form.Item
      label={
        <div className="font-normal">
          {label}
          {placeholder && (
            <Tooltip title={placeholder}>
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="ml-2 text-bz-gray-700"
              />
            </Tooltip>
          )}
        </div>
      }
      validateStatus={hasError ? 'error' : 'success'}
      help={errorMessage}
    >
      <div className="space-x-2">
        {[0, 1, 2, 3].map(key => (
          <BaseNumberInput
            key={key}
            min={0}
            max={100}
            value={values[key]}
            onChange={value =>
              // Type assertion because `R.update` doesn't know it's a tuple and thinks it's just number[]
              onChange(
                R.update(key, value, values) as [
                  number,
                  number,
                  number,
                  number,
                ],
              )
            }
          />
        ))}
      </div>
    </Form.Item>
  )
}

const mapping = [
  [timeZoneIdSchema, TimeZoneSelectField] as const,
  [z.number(), NumberField],
  [z.string(), TextField],
  [z.boolean(), BooleanField],
  [ChanceWeightsSchema, ChanceWeightsField],
  [FourChanceWeightsSchema, FourChanceWeightsField],
] as const

type CustomFormProps = React.PropsWithChildren<{
  onSubmit: () => void
  disabled?: boolean
  loading?: boolean
}>

const CustomForm = ({
  children,
  onSubmit,
  disabled,
  loading,
}: CustomFormProps) => {
  return (
    <Form
      onSubmitCapture={onSubmit}
      disabled={disabled || loading}
      layout="vertical"
    >
      {children}
      <Button htmlType="submit" loading={loading}>
        Generate
      </Button>
    </Form>
  )
}

const MyForm = createTsForm(mapping, { FormComponent: CustomForm })

type FormBodyProps = {
  elems: Partial<Record<keyof DBSeederRequestBase, JSX.Element | undefined>>
  loadPreset: (presetKey: keyof typeof DB_SEEDER_SETTINGS_PRESETS) => void
}

const FormBody = React.memo<FormBodyProps>(({ elems, loadPreset }) => {
  return (
    <Collapse
      className="mb-4"
      items={[
        {
          key: '1',
          label: 'Seeder Settings',
          children: (
            <div>
              <Dropdown
                className="mb-4"
                menu={{
                  items: R.keys(DB_SEEDER_SETTINGS_PRESETS)
                    .sort(
                      (a, b) =>
                        DB_SEEDER_SETTINGS_PRESETS[a].rank -
                        DB_SEEDER_SETTINGS_PRESETS[b].rank,
                    )
                    .map(key => ({
                      key,
                      label: (
                        <div onClick={() => loadPreset(key)}>
                          {DB_SEEDER_SETTINGS_PRESETS[key].name}
                        </div>
                      ),
                    })),
                }}
              >
                <Button>Load Preset</Button>
              </Dropdown>

              {dbSeederRequestGroups.map(([name, schema]) => (
                <div key={name}>
                  <div className="mb-2 border-0 border-b border-solid border-bz-gray-400 text-base font-semibold">
                    {name}
                  </div>
                  <div className="mx-[-8px] flex flex-wrap">
                    {R.keys(schema.shape).map((key: keyof typeof elems) => (
                      <div key={key} className="px-2">
                        {elems[key]}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ),
        },
      ]}
    />
  )
})

interface AdminDevToolsProps {
  refetchCompanies: () => unknown
}

export const AdminDevTools = React.memo<AdminDevToolsProps>(
  ({ refetchCompanies }) => {
    const message = useMessage()
    const seedDatabaseV2 = trpc.devTools['devtools:seed-v2'].useMutation({
      onSuccess: () => {
        refetchCompanies()
      },
    })

    const form = useForm<z.infer<typeof dbSeederRequestSchema>>({
      resolver: zodResolver(dbSeederRequestSchema),
    })

    const [performanceReport, setPerformanceReport] =
      useState<DBSeederPerformanceReportItem[]>()

    const triggerSeederV2 = useCallback(
      (args: z.infer<typeof dbSeederRequestSchema>) => {
        setPerformanceReport(undefined)
        seedDatabaseV2.mutate(args, {
          onSuccess: res => {
            setPerformanceReport(res.performanceReport)

            message.success(
              `Successfully generated company with name = ${res.companyName} `,
            )
          },
          onSettled: () => {
            // If we don't do this and they hit the button again, it will generate with the same name (and they'll get a
            // collision on the company name).
            form.setValue('companyOwnerFirstName', faker.person.firstName())
            form.setValue('companyOwnerLastName', faker.person.lastName())
          },
        })
      },
      [form, message, seedDatabaseV2],
    )

    const loadPreset = useCallback(
      (presetKey: keyof typeof DB_SEEDER_SETTINGS_PRESETS) => {
        const preset = DB_SEEDER_SETTINGS_PRESETS[presetKey]

        for (const key of R.keys(preset.request)) {
          form.setValue(key, preset.request[key])
        }
      },
      [form],
    )

    return (
      <div style={{ marginTop: 32 }}>
        <MyForm
          formProps={{ loading: seedDatabaseV2.isLoading }}
          form={form}
          schema={dbSeederRequestSchema}
          onSubmit={triggerSeederV2}
          defaultValues={{
            ...salesDemoDBSeederRequest,
            companyOwnerFirstName: faker.person.firstName(),
            companyOwnerLastName: faker.person.lastName(),
          }}
        >
          {elems => <FormBody elems={elems} loadPreset={loadPreset} />}
        </MyForm>
        {performanceReport && (
          <Collapse
            className="mt-4"
            items={[
              {
                key: '1',
                label: 'Seeder Performance Report',
                children: (
                  <Table
                    pagination={false}
                    size="small"
                    dataSource={performanceReport.map(item => ({
                      key: item.name,
                      ...item,
                    }))}
                    columns={[
                      {
                        title: 'Module Name',
                        dataIndex: 'name',
                        key: 'name',
                        sorter: R.ascend(R.prop('name')),
                      },
                      {
                        title: 'Duration (ms)',
                        dataIndex: 'duration',
                        key: 'duration',
                        sorter: R.ascend(R.prop('duration')),
                        render: (duration: number) => duration.toLocaleString(),
                      },
                      {
                        title: 'Percent of Total',
                        dataIndex: 'percentage',
                        key: 'percentage',
                        sorter: R.ascend(R.prop('percentage')),
                      },
                    ]}
                  />
                ),
              },
            ]}
          />
        )}
      </div>
    )
  },
)
