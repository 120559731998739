import React from 'react'
import { Outlet } from 'react-router-dom'

export const SettingsTeamLayout = React.memo(() => {
  return (
    <div className="flex w-full flex-col px-4">
      <Outlet />
    </div>
  )
})
