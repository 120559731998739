import { PricebookItemTypeEnum } from '@breezy/shared'
import { useMemo } from 'react'
import { useQuery } from 'urql'
import { gql } from '../../../generated'
import { useExpectedCompanyGuid } from '../../../providers/PrincipalUser'

const PRICEBOOK_ITEM_DEFAULT_PHOTO_QUERY = gql(/* GraphQL */ `
  query PricebookItemDefaultPhotoQueryAdmin($companyGuid: uuid!) {
    pricebookItemTypeFallbackPhotos(
      where: { companyGuid: { _eq: $companyGuid } }
    ) {
      itemType
      photoGuid
      photo {
        cdnUrl
      }
    }
  }
`)
export type PricebookItemDefaultPhotoMap = Record<
  PricebookItemTypeEnum,
  { photoGuid: string; cdnUrl: string } | undefined
>
export const usePricebookItemDefaultPhotos = () => {
  const companyGuid = useExpectedCompanyGuid()

  const [{ data, fetching, error }] = useQuery({
    query: PRICEBOOK_ITEM_DEFAULT_PHOTO_QUERY,
    variables: { companyGuid },
  })

  const defaultPhotos = useMemo(() => {
    const photos: PricebookItemDefaultPhotoMap = {
      [PricebookItemTypeEnum.SERVICE]: undefined,
      [PricebookItemTypeEnum.MATERIAL]: undefined,
      [PricebookItemTypeEnum.EQUIPMENT]: undefined,
      [PricebookItemTypeEnum.LABOR]: undefined,
    }

    if (data) {
      data.pricebookItemTypeFallbackPhotos.forEach(item => {
        photos[item.itemType] = {
          photoGuid: item.photoGuid,
          cdnUrl: item.photo.cdnUrl,
        }
      })
    }

    return photos
  }, [data])

  return { defaultPhotos, fetching, error }
}
