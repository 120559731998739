import {
  AppointmentGuid,
  MaintenanceOpportunity,
  Tag,
  milesToMeters,
} from '@breezy/shared'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'urql'
import { Filter, SetFilters } from '../../../hooks/useFilter'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { StateSetter, useStrictContext } from '../../../utils/react-utils'
import { useSchedulePendingChanges } from '../PendingChanges/SchedulePendingChangesContext'
import { FullScheduleAppointment, SchedulePageContext } from '../scheduleUtils'
import {
  LOCATIONS_WITHIN_RADIUS_QUERY,
  toMaintenanceOpportunity,
} from './NearbyLocations.gql'
import { useMaintenanceOpportunityFilters } from './useMaintenanceOpportunityFilters'

export const DEFAULT_RADIUS_MILES = 3

export type ActivePopup =
  | {
      type: 'appointment'
      appointmentGuid: AppointmentGuid
    }
  | {
      type: 'maintenanceOpportunity'
      locationGuid: string
    }

type ScheduleMapContextType = {
  activeAppointment?: FullScheduleAppointment
  setActiveAppointmentGuid: StateSetter<AppointmentGuid | undefined>
  clearActiveAppointment: () => void
  radiusMeters: number
  setRadiusMeters: StateSetter<number>
  maintOpportunities: MaintenanceOpportunity[]
  filteredMaintOpportunities: MaintenanceOpportunity[]
  setFilters: SetFilters
  filters: Filter[]
  clearFilters: () => void
  accountTags: Tag[]
  activePopup: ActivePopup | undefined
  setActivePopup: (popup: ActivePopup | undefined) => void
  clearActivePopup: () => void
  loading: boolean
  resetRadius: () => void
}

const ScheduleMapContext = React.createContext<
  ScheduleMapContextType | undefined
>(undefined)

export const ScheduleMapWrapper = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const { scheduleView, scheduleMode } = useStrictContext(SchedulePageContext)

    const { appointmentMap, allAppointments: appointments } =
      useSchedulePendingChanges()

    const lastScheduleViewRef = useRef(scheduleView)

    const [activePopup, setActivePopupRaw] = useState<ActivePopup | undefined>(
      undefined,
    )

    const [activeAppointmentGuid, setActiveAppointmentGuid] =
      useState<AppointmentGuid>()

    const [radiusMeters, setRadiusMeters] = useState(
      milesToMeters(DEFAULT_RADIUS_MILES),
    )

    const setActivePopup = useCallback((popup: ActivePopup | undefined) => {
      setActivePopupRaw(popup)
    }, [])

    const appointmentAddressGuids = useMemo(
      () =>
        appointments.map(
          appointment => appointment!.job.location.address.addressGuid,
        ),
      [appointments],
    )

    const activeAppointment = activeAppointmentGuid
      ? appointmentMap[activeAppointmentGuid]
      : undefined

    const [{ data, fetching }] = useQuery({
      query: LOCATIONS_WITHIN_RADIUS_QUERY,
      variables: {
        radius: radiusMeters,
        point: activeAppointment?.job.location.address.geoLocation,
        appointmentAddressGuids,
      },
      pause: !activeAppointment,
    })

    const maintOpportunities = useMemo(
      () =>
        data?.locations.map(location =>
          toMaintenanceOpportunity(location, tzId),
        ) || [],
      [data, tzId],
    )

    const {
      filtered: filteredMaintOpportunities,
      filters,
      setFilters,
      accountTags,
      clearFilters,
    } = useMaintenanceOpportunityFilters(maintOpportunities)

    const resetRadius = useCallback(
      () => setRadiusMeters(milesToMeters(DEFAULT_RADIUS_MILES)),
      [setRadiusMeters],
    )

    const reset = useCallback(() => {
      setActiveAppointmentGuid(undefined)
      setActivePopup(undefined)
      resetRadius()
    }, [setActivePopup, resetRadius])

    const clearActivePopup = useCallback(
      () => setActivePopup(undefined),
      [setActivePopup],
    )

    const clearActiveAppointment = useCallback(() => {
      reset()
    }, [reset])

    // Reset the state if the map view is navigated away from
    useEffect(() => {
      if (
        scheduleMode === 'CALENDAR' ||
        scheduleView !== lastScheduleViewRef.current
      ) {
        reset()
        lastScheduleViewRef.current = scheduleView
      }
    }, [scheduleMode, reset, scheduleView])

    return (
      <ScheduleMapContext.Provider
        value={{
          activeAppointment,
          setActiveAppointmentGuid,
          clearActiveAppointment,
          radiusMeters,
          setRadiusMeters,
          maintOpportunities,
          filteredMaintOpportunities,
          setFilters,
          clearFilters,
          filters,
          activePopup,
          setActivePopup,
          clearActivePopup,
          loading: fetching,
          accountTags,
          resetRadius,
        }}
      >
        {children}
      </ScheduleMapContext.Provider>
    )
  },
)

export const useScheduleMap = () => useStrictContext(ScheduleMapContext)
