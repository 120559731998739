import {
  BzDateFns,
  CalculatePaths,
  CompanyLeadSource,
  CompanyLeadSourceGuid,
  Guid,
  INVOICE_V2_FRIENDLY_STATUS_NAMES,
  InvoiceV2Status,
  IsoDateString,
  JOBS_V2_CLASSES,
  JobsV2Class,
  LocalDateString,
  PhoneNumberType,
  R,
  UserGuid,
  formatAbbreviatedUsc,
  formatUsc,
  invoiceV2ToInvoiceV1Status,
  jobClassDisplayNames,
  streetAddressLine1And2Condensed,
  toPlural,
  usCentsToUsd,
  usdToUsCents,
} from '@breezy/shared'
import {
  faCheck,
  faClose,
  faEllipsis,
  faFileInvoiceDollar,
  faSliders,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Form, Select, Switch } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'urql'
import { BaseContactCell } from '../../adam-components/ListPage/ContactCell'
import { DetailChip } from '../../adam-components/ListPage/DetailChip'
import { DetailsCell } from '../../adam-components/ListPage/DetailsCell'
import { ListPageContainer } from '../../adam-components/ListPage/ListPageContainer'
import { ListPageFilter } from '../../adam-components/ListPage/ListPageFilter'
import { ListPageFilterBar } from '../../adam-components/ListPage/ListPageFilterBar'
import { ListPageFilterButton } from '../../adam-components/ListPage/ListPageFilterButton'
import {
  ListPageTable,
  ListPageTableColsConfig,
  UsePaginationReturnType,
  usePagination,
} from '../../adam-components/ListPage/ListPageTable'
import { SummaryStatBox } from '../../adam-components/ListPage/SummaryStats'
import { TeamMemberCell } from '../../adam-components/ListPage/TeamMemberCell'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import InvoiceStatusTag from '../../components/Invoicing/InvoiceStatusTag/InvoiceStatusTag'
import { BaseLoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'
import { Page } from '../../components/Page/Page'
import { usePaymentWorkflowWizard } from '../../components/PaymentWorkflow/hooks/usePaymentWorkflowWizard'
import {
  QuickbooksSyncInvoiceButton,
  useIsQboEnabled,
} from '../../components/Quickbooks/QuickbooksSyncButton'
import { EmDash } from '../../elements/EmDash/EmDash'
import { Link } from '../../elements/Link/Link'
import {
  AggregatableInvoicesBoolExp,
  AggregatableInvoicesOrderBy,
  GetInvoiceListUsersQuery,
} from '../../generated/user/graphql'
import { trpc } from '../../hooks/trpc'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import {
  useDownloadInvoice,
  useFetchPdfBlob,
  useOpenInvoiceInNewTab,
} from '../../utils/invoice-utils'
import {
  useBooleanState,
  useQueryParamState,
  useQueryParamStateArray,
  useQueryParamStateEnumArray,
  useQueryParamStateWithOptions,
  useStrictContext,
} from '../../utils/react-utils'
import {
  COMPANY_CONFIG_QUERY,
  INVOICES_V2_QUERY,
  INVOICE_LISTING_USERS_QUERY,
  INVOICE_V2_AVG_UNPAID_DAYS_QUERY,
  INVOICE_V2_SUMMARY_STATISTICS_AVG_VALUE_QUERY,
  INVOICE_V2_SUMMARY_STATISTICS_QUERY,
} from './InvoiceListPage.gql'

type UserMap = Record<string, InvoiceListUser | undefined>

type LeadSourceMap = Record<
  CompanyLeadSourceGuid,
  CompanyLeadSource | undefined
>

const SORT_TYPES = [
  'NEWEST',
  'OLDEST',
  'OVERDUE',
  'TOTAL',
  'CREATED_BY',
] as const

type SortType = (typeof SORT_TYPES)[number]

type InvoiceListingContextType = {
  pagination: UsePaginationReturnType
  userMap: UserMap
  hasFilters: boolean
  searchTerm: string
  sortType: SortType
  startDate?: IsoDateString
  selectedJobClasses: JobsV2Class[]
  selectedV2Statuses: InvoiceV2Status[]
  selectedUserGuids: UserGuid[]
  selectedCompanyLeadSourceGuids: CompanyLeadSourceGuid[]
  selectedAccountManagerUserGuids: UserGuid[]
  showMPInvoices: boolean
}

const InvoiceListingContext = React.createContext<
  InvoiceListingContextType | undefined
>(undefined)

type InvoiceListingLeadSource = {
  companyLeadSourceGuid: CompanyLeadSourceGuid
  name: string
}

type InvoiceListingInvoice = {
  invoiceGuid: Guid
  accountGuid: Guid
  locationGuid?: Guid
  job?: {
    jobGuid: Guid
    displayId: number
    jobType: string
  }
  maintenancePlan?: {
    maintenancePlanGuid: Guid
    name: string
  }
  displayId: number
  address?: string
  fullyPaid: boolean
  totalUsc: number
  paidUsc: number
  processingPaymentsUsc: number
  status: InvoiceV2Status
  contact: {
    contactName: string
    contactPhoneNumber?: string
    contactPhoneType?: PhoneNumberType
    contactEmail?: string
  }
  serviceCompletionDate?: LocalDateString
  issuedAt?: IsoDateString
  dueAt?: string
  numDaysOverdue?: number
  isTerminalStatus: boolean
  createdByUserGuid?: Guid
  leadSource?: InvoiceListingLeadSource
  accountManagerUserGuid?: Guid
}

type InvoiceListingSummaryStats = {
  outstanding: {
    amountUsc: number
    count: number
  }
  overdue: {
    amountUsc: number
    count: number
  }
  fullyPaid: {
    amountUsc: number
    count: number
  }
  avgValueUsc: number
  avgDaysToPay: number
}

type InvoiceListingDataContextType = {
  listDataFetching: boolean
  summaryStatsLoading: boolean
  totalItems: number
  data: InvoiceListingInvoice[]
  summaryStats: InvoiceListingSummaryStats
  refetch?: () => void
}

const InvoiceListingDataContext = React.createContext<
  InvoiceListingDataContextType | undefined
>(undefined)

type InvoicesV2DataWrapperProps = React.PropsWithChildren

const InvoicesV2DataWrapper = React.memo<InvoicesV2DataWrapperProps>(
  ({ children }) => {
    const {
      pagination: { limit, offset },
      sortType,
      searchTerm,
      startDate,
      selectedJobClasses,
      selectedV2Statuses,
      selectedUserGuids,
      selectedCompanyLeadSourceGuids,
      selectedAccountManagerUserGuids,
      showMPInvoices,
    } = useStrictContext(InvoiceListingContext)

    const orderByClause = useMemo<
      AggregatableInvoicesOrderBy | AggregatableInvoicesOrderBy[]
    >(() => {
      switch (sortType) {
        case 'NEWEST':
          return { issuedOrCreatedAt: 'DESC' }
        case 'OLDEST':
          return { issuedOrCreatedAt: 'ASC' }
        case 'OVERDUE':
          return [
            { isTerminalStatus: 'ASC' },
            { numDaysOverdue: 'DESC_NULLS_LAST' },
          ]
        case 'TOTAL':
          return { totalUsc: 'DESC' }
        case 'CREATED_BY':
          return { createdByUser: { firstName: 'ASC' } }
      }
    }, [sortType])

    const whereClause = useMemo<AggregatableInvoicesBoolExp>(() => {
      if (searchTerm) {
        const _ilike = `%${searchTerm}%`

        return {
          _or: [
            {
              displayIdString: { _ilike },
            },
            {
              totalUscString: { _ilike },
            },
            {
              jobLink: { job: { displayIdString: { _ilike } } },
            },
            {
              jobLink: {
                job: {
                  pointOfContact: {
                    fullName: { _ilike },
                  },
                },
              },
            },
            {
              jobLink: {
                job: {
                  jobType: {
                    name: { _ilike },
                  },
                },
              },
            },
            {
              jobLink: {
                job: {
                  location: {
                    address: {
                      canonicalFullAddress: { _ilike },
                    },
                  },
                },
              },
            },
            {
              createdByUser: {
                fullName: { _ilike },
              },
            },
            {
              createdByUser: {
                emailAddress: { _ilike },
              },
            },
            {
              account: {
                accountContacts: {
                  contact: {
                    fullName: { _ilike },
                  },
                },
              },
            },
          ],
        }
      }
      const whereClause: AggregatableInvoicesBoolExp = {}
      if (startDate) {
        whereClause.issuedOrCreatedAt = { _gte: startDate }
      }
      if (selectedJobClasses.length) {
        whereClause.jobLink = {
          job: {
            jobType: {
              jobClass: { _in: selectedJobClasses },
            },
          },
        }
      }
      if (selectedV2Statuses.length) {
        whereClause.status = { _in: selectedV2Statuses }
      }
      if (selectedUserGuids.length) {
        whereClause.createdBy = { _in: selectedUserGuids }
      }
      if (!showMPInvoices) {
        whereClause._not = {
          maintenancePlanLink: {
            maintenancePlanGuid: { _isNull: false },
          },
        }
      }
      if (selectedCompanyLeadSourceGuids.length) {
        whereClause.jobLink = whereClause.jobLink || {}
        whereClause.jobLink.job = whereClause.jobLink.job || {}
        whereClause.jobLink.job.jobLeadSource =
          whereClause.jobLink.job.jobLeadSource || {}
        whereClause.jobLink.job.jobLeadSource.companyLeadSourceGuid = {
          _in: selectedCompanyLeadSourceGuids,
        }
      }
      if (selectedAccountManagerUserGuids.length) {
        whereClause.account = whereClause.account || {}
        whereClause.account.accountManagerUserGuid = {
          _in: selectedAccountManagerUserGuids,
        }
      }
      return whereClause
    }, [
      searchTerm,
      startDate,
      selectedJobClasses,
      selectedV2Statuses,
      selectedUserGuids,
      showMPInvoices,
      selectedCompanyLeadSourceGuids,
      selectedAccountManagerUserGuids,
    ])

    const [{ data, fetching }, refetchInvoiceList] = useQuery({
      query: INVOICES_V2_QUERY,
      variables: {
        limit,
        offset,
        orderBy: orderByClause,
        where: whereClause,
      },
    })

    const totalItems = data?.aggregatableInvoicesAggregate.aggregate?.count ?? 0

    const [
      {
        data: outstandingSummaryStats,
        fetching: fetchingOutstandingSummaryStats,
      },
    ] = useQuery({
      query: INVOICE_V2_SUMMARY_STATISTICS_QUERY,
      variables: {
        where: {
          _and: [
            whereClause,
            {
              status: {
                _eq: 'OPEN',
              },
            },
          ],
        },
      },
    })

    const [
      { data: overdueSummaryStats, fetching: fetchingOverdueSummaryStats },
    ] = useQuery({
      query: INVOICE_V2_SUMMARY_STATISTICS_QUERY,
      variables: {
        where: {
          _and: [
            whereClause,
            {
              numDaysOverdue: { _gt: 0 },
              isTerminalStatus: { _eq: false },
            },
          ],
        },
      },
    })

    const [
      { data: fullyPaidSummaryStats, fetching: fetchingFullyPaidSummaryStats },
    ] = useQuery({
      query: INVOICE_V2_SUMMARY_STATISTICS_QUERY,
      variables: {
        where: {
          _and: [
            whereClause,
            {
              status: {
                _eq: 'PAID',
              },
            },
          ],
        },
      },
    })

    const [
      { data: avgValueSummaryStats, fetching: fetchingAvgValueSummaryStats },
    ] = useQuery({
      query: INVOICE_V2_SUMMARY_STATISTICS_AVG_VALUE_QUERY,
      variables: {
        where: {
          _and: [
            whereClause,
            {
              status: {
                _neq: 'DRAFT',
              },
            },
          ],
        },
      },
    })

    const [{ data: avgUnpaidDays, fetching: fetchingAvgUnpaidDays }] = useQuery(
      {
        query: INVOICE_V2_AVG_UNPAID_DAYS_QUERY,
        variables: {
          where: {
            _and: [
              whereClause,
              {
                status: {
                  _in: ['OPEN', 'PAID'],
                },
              },
            ],
          },
        },
      },
    )

    const summaryStatsLoading =
      fetchingOutstandingSummaryStats ||
      fetchingOverdueSummaryStats ||
      fetchingFullyPaidSummaryStats ||
      fetchingAvgValueSummaryStats ||
      fetchingAvgUnpaidDays

    const tzId = useExpectedCompanyTimeZoneId()

    const value = useMemo<InvoiceListingDataContextType>(
      () => ({
        listDataFetching: fetching,
        refetch: refetchInvoiceList,
        data:
          data?.aggregatableInvoices.map<InvoiceListingInvoice>(
            ({
              invoiceGuid,
              accountGuid,
              locationLink,
              jobLink,
              displayId,
              status,
              totalUsc,
              paidUsd,
              processingUsd,
              account,
              issuedAt,
              dueAt,
              numDaysOverdue,
              isTerminalStatus,
              createdBy,
              maintenancePlanLink,
            }) => {
              const contactName =
                jobLink?.job.pointOfContact.fullName ??
                account?.accountContacts[0].contact.fullName ??
                'Unknown'

              const contactPhone =
                jobLink?.job.pointOfContact.primaryPhoneNumber ??
                account?.accountContacts[0]?.contact.primaryPhoneNumber

              const contactEmail =
                jobLink?.job.pointOfContact.primaryEmailAddress?.emailAddress ??
                account?.accountContacts[0]?.contact.primaryEmailAddress
                  ?.emailAddress

              // Because of business logic I know that this will always be defined btw
              const address = locationLink?.location.address.line1
                ? streetAddressLine1And2Condensed(
                    locationLink?.location.address.line1,
                    locationLink?.location.address.line2,
                  )
                : ''

              const fullyPaid = status === 'PAID'

              const paidUsc = usdToUsCents(paidUsd)
              const processingPaymentsUsc = usdToUsCents(processingUsd)

              return {
                invoiceGuid: invoiceGuid,
                accountGuid: accountGuid,
                locationGuid: locationLink?.locationGuid,
                job: jobLink && {
                  jobGuid: jobLink.jobGuid,
                  displayId: jobLink.job.displayId,
                  jobType: jobLink.job.jobType.name,
                },
                maintenancePlan: maintenancePlanLink && {
                  maintenancePlanGuid: maintenancePlanLink.maintenancePlanGuid,
                  name:
                    maintenancePlanLink.maintenancePlan
                      ?.maintenancePlanDefinition?.marketingInfo?.name ??
                    'Maintenance Plan',
                },
                displayId,
                address,
                fullyPaid,
                totalUsc,
                paidUsc,
                processingPaymentsUsc,
                status,
                contact: {
                  contactName,
                  contactPhoneNumber: contactPhone?.phoneNumber,
                  contactPhoneType: contactPhone?.type,
                  contactEmail,
                },
                issuedAt,
                dueAt: dueAt
                  ? BzDateFns.formatFromISO(dueAt, 'MMM d, yyyy', tzId)
                  : undefined,
                numDaysOverdue,
                isTerminalStatus,
                createdByUserGuid:
                  maintenancePlanLink?.maintenancePlan?.createdByUserGuid ??
                  createdBy,
                leadSource: jobLink?.job.jobLeadSource?.length
                  ? {
                      companyLeadSourceGuid:
                        jobLink?.job.jobLeadSource[0].companyLeadSource
                          .companyLeadSourceGuid,
                      name:
                        jobLink.job.jobLeadSource[0].companyLeadSource
                          .canonicalLeadSourceNameOverride ??
                        jobLink.job.jobLeadSource[0].companyLeadSource
                          .canonicalLeadSourceName,
                    }
                  : undefined,
                accountManagerUserGuid:
                  account?.accountManagerUserGuid ?? undefined,
              }
            },
          ) ?? [],
        totalItems,
        summaryStatsLoading,
        summaryStats: {
          outstanding: {
            count:
              outstandingSummaryStats?.aggregatableInvoicesAggregate.aggregate
                ?.count ?? 0,
            amountUsc:
              outstandingSummaryStats?.aggregatableInvoicesAggregate.aggregate
                ?.sum?.totalUsc ?? 0,
          },
          overdue: {
            count:
              overdueSummaryStats?.aggregatableInvoicesAggregate.aggregate
                ?.count ?? 0,
            amountUsc:
              overdueSummaryStats?.aggregatableInvoicesAggregate.aggregate?.sum
                ?.totalUsc ?? 0,
          },
          fullyPaid: {
            count:
              fullyPaidSummaryStats?.aggregatableInvoicesAggregate.aggregate
                ?.count ?? 0,
            amountUsc:
              fullyPaidSummaryStats?.aggregatableInvoicesAggregate.aggregate
                ?.sum?.totalUsc ?? 0,
          },
          avgValueUsc:
            avgValueSummaryStats?.aggregatableInvoicesAggregate.aggregate?.avg
              ?.totalUsc ?? 0,

          avgDaysToPay: Math.round(
            avgUnpaidDays?.aggregatableInvoicesAggregate.aggregate?.avg
              ?.daysUnpaid ?? 0,
          ),
        },
      }),
      [
        avgUnpaidDays?.aggregatableInvoicesAggregate.aggregate?.avg?.daysUnpaid,
        avgValueSummaryStats?.aggregatableInvoicesAggregate.aggregate?.avg
          ?.totalUsc,
        data?.aggregatableInvoices,
        fetching,
        fullyPaidSummaryStats?.aggregatableInvoicesAggregate.aggregate?.count,
        fullyPaidSummaryStats?.aggregatableInvoicesAggregate.aggregate?.sum
          ?.totalUsc,
        outstandingSummaryStats?.aggregatableInvoicesAggregate.aggregate?.count,
        outstandingSummaryStats?.aggregatableInvoicesAggregate.aggregate?.sum
          ?.totalUsc,
        overdueSummaryStats?.aggregatableInvoicesAggregate.aggregate?.count,
        overdueSummaryStats?.aggregatableInvoicesAggregate.aggregate?.sum
          ?.totalUsc,
        refetchInvoiceList,
        summaryStatsLoading,
        totalItems,
        tzId,
      ],
    )
    return (
      <InvoiceListingDataContext.Provider value={value}>
        {children}
      </InvoiceListingDataContext.Provider>
    )
  },
)

type InvoiceActionsDropdownProps = InvoiceListingInvoice

const canCollectPaymentOnInvoice = (
  status: InvoiceV2Status,
  amountDueUsc: number,
) => (status === 'OPEN' || status === 'UNCOLLECTABLE') && amountDueUsc > 0

const InvoiceActionsDropdown = React.memo<InvoiceActionsDropdownProps>(
  ({
    invoiceGuid,
    accountGuid,
    displayId,
    status,
    totalUsc,
    paidUsc,
    processingPaymentsUsc,
    serviceCompletionDate,
  }) => {
    const companyGuid = useExpectedCompanyGuid()
    const navigate = useNavigate()
    const message = useMessage()
    const { refetch } = useStrictContext(InvoiceListingDataContext)
    const amountDueUsc = totalUsc - paidUsc - processingPaymentsUsc
    const { showPaymentWorkflowWizard, paymentWorkflowWizard } =
      usePaymentWorkflowWizard({
        invoice: {
          invoiceGuid,
          accountGuid,
          displayId,
          referenceNumber: invoiceGuid,
          status: invoiceV2ToInvoiceV1Status(status),
          serviceCompletionDate,
        },
        invoiceAmountDueUsd: usCentsToUsd(amountDueUsc),
        onPaymentSuccess: refetch,
      })
    const { downloadInvoicePDF, invoiceDownloading } = useDownloadInvoice(
      companyGuid,
      invoiceGuid,
      displayId,
    )

    const emailInvoiceMut =
      trpc.invoice['invoicing:email-invoice'].useMutation()

    const emailInvoicePDF = useCallback(() => {
      emailInvoiceMut.mutate(
        {
          invoiceGuid,
        },
        {
          onSuccess: () => {
            message.success('Successfully Emailed Invoice PDF to customer')
          },
          onError: e => {
            console.error(
              `There was an unexpected error sending the invoice pdf`,
              e,
            )
            message.error(
              `There was an unexpected error sending the invoice pdf`,
            )
          },
        },
      )
    }, [emailInvoiceMut, invoiceGuid, message])

    const fetchPdfBlob = useFetchPdfBlob(companyGuid)

    const { beginOpenInvoice, invoiceGenerating } = useOpenInvoiceInNewTab(
      invoiceGuid,
      fetchPdfBlob,
    )

    const dropdownItems = useMemo(() => {
      const items: ItemType[] = [
        {
          key: 'Send Copy',
          label: <div onClick={emailInvoicePDF}>Send Copy</div>,
        },
        {
          key: 'Download PDF',
          label: <div onClick={downloadInvoicePDF}>Download PDF</div>,
        },
        {
          key: 'Print',
          label: <div onClick={beginOpenInvoice}>Print</div>,
        },
      ]
      if (status !== 'VOIDED') {
        items.unshift({
          key: 'Edit',
          label: (
            <div
              onClick={() =>
                navigate(CalculatePaths.invoiceEdit({ invoiceGuid }))
              }
            >
              Edit
            </div>
          ),
        })
      }

      if (canCollectPaymentOnInvoice(status, amountDueUsc)) {
        items.push({
          key: 'Collect Payment',
          label: <div onClick={showPaymentWorkflowWizard}>Collect Payment</div>,
        })
      }

      return items
    }, [
      amountDueUsc,
      beginOpenInvoice,
      downloadInvoicePDF,
      emailInvoicePDF,
      invoiceGuid,
      navigate,
      showPaymentWorkflowWizard,
      status,
    ])
    const loading =
      invoiceDownloading || emailInvoiceMut.isLoading || invoiceGenerating

    return (
      <>
        <Dropdown
          disabled={loading}
          className="mb-4"
          menu={{
            items: dropdownItems,
          }}
        >
          <Button
            shape="circle"
            disabled={loading}
            icon={
              loading ? (
                <BaseLoadingSpinner size={5} />
              ) : (
                <FontAwesomeIcon icon={faEllipsis} />
              )
            }
          />
        </Dropdown>
        {paymentWorkflowWizard}
      </>
    )
  },
)

type InvoiceListUser =
  GetInvoiceListUsersQuery['companyUsers'][number]['userByUserGuid']

type TotalsRectangleProps = React.PropsWithChildren<{
  fullyPaid: boolean
  labelClassName?: string
  amountUsc: number
}>

const TotalsRectangleLabel = React.memo<TotalsRectangleProps>(
  ({ children, fullyPaid, labelClassName, amountUsc }) => (
    <>
      <span
        className={classNames(
          'baseline baseline mr-1 text-xs font-semibold',
          {
            'text-bz-gray-700': !fullyPaid,
          },
          labelClassName,
        )}
      >
        {children}
      </span>
      <span className="baseline">{formatUsc(amountUsc)}</span>
    </>
  ),
)

const JOBS_V2_CLASS_OPTIONS = JOBS_V2_CLASSES.map(value => ({
  value,
  label: jobClassDisplayNames[value],
}))

const INVOICE_V2_STATUSES = [
  'DRAFT',
  'OPEN',
  'PAID',
  'VOIDED',
  'UNCOLLECTABLE',
] as const

const INVOICE_V2_STATUS_OPTIONS = INVOICE_V2_STATUSES.map(key => ({
  key,
  label: INVOICE_V2_FRIENDLY_STATUS_NAMES[key],
}))

const PRETTY_SORT_TYPE_MAP: Record<SortType, string> = {
  NEWEST: 'Issue Date (Newest)',
  OLDEST: 'Issue Date (Oldest)',
  OVERDUE: 'Overdue (Oldest)',
  TOTAL: 'Invoice Amount (Highest)',
  CREATED_BY: 'Created By (A-Z)',
}

const START_DATE_TYPES = [
  'LAST_7_DAYS',
  'LAST_30_DAYS',
  'LAST_90_DAYS',
  'LAST_6_MONTHS',
  'ALL_TIME',
] as const

type StartDateType = (typeof START_DATE_TYPES)[number]

const PRETTY_START_DATE_MAP: Record<StartDateType, string> = {
  LAST_7_DAYS: 'Last 7 days',
  LAST_30_DAYS: 'Last 30 days',
  LAST_90_DAYS: 'Last 90 days',
  LAST_6_MONTHS: 'Last 6 months',
  ALL_TIME: 'All time',
}

const SORT_TYPE_OPTIONS = SORT_TYPES.map(type => ({
  key: type,
  label: PRETTY_SORT_TYPE_MAP[type],
}))

const START_DATE_OPTIONS = START_DATE_TYPES.map(type => ({
  key: type,
  label: PRETTY_START_DATE_MAP[type],
}))

const FILTER_LABELS = {
  showMPInvoices: 'Show Maintenance Plan Invoices',
  jobClasses: 'Job Class',
  status: 'Invoice Status',
  users: 'Created by',
  leadSources: 'Lead Source',
  acctManagers: 'Account Manager',
}

const computeHasFilters = (
  showMPInvoices: boolean,
  selectedJobClasses: JobsV2Class[],
  selectedUserGuids: UserGuid[],
  selectedCompanyLeadSourceGuids: CompanyLeadSourceGuid[],
  selectedAccountManagerUserGuids: UserGuid[],
) =>
  showMPInvoices ||
  selectedJobClasses.length +
    selectedUserGuids.length +
    selectedCompanyLeadSourceGuids.length +
    selectedAccountManagerUserGuids.length +
    0 >
    0

type MoreFiltersProps = {
  showMPInvoices: boolean
  setShowMPInvoices: (show: boolean) => void
  selectedJobClasses: JobsV2Class[]
  setSelectedJobClasses: (jobClasses: JobsV2Class[]) => void
  selectedUserGuids: UserGuid[]
  setSelectedUserGuids: (userGuids: UserGuid[]) => void
  selectedCompanyLeadSourceGuids: CompanyLeadSourceGuid[]
  setSelectedCompanyLeadSourceGuids: (
    companyLeadSourceGuids: CompanyLeadSourceGuid[],
  ) => void
  selectedAccountManagerUserGuids: UserGuid[]
  setSelectedAccountManagerUserGuids: (userGuids: UserGuid[]) => void
  userData?: GetInvoiceListUsersQuery
  fetchingUsers: boolean
  fetchingCompanyLeadSources: boolean
  leadSourceData?: CompanyLeadSource[]
  accountManagerEnabled: boolean
}

const MoreFilters = React.memo<MoreFiltersProps>(
  ({
    showMPInvoices: externalShowMPInvoices = false,
    setShowMPInvoices: externalSetShowMPInvoices,
    selectedJobClasses: externalSelectedJobClasses = [],
    setSelectedJobClasses: externalSetSelectedJobClasses,
    selectedUserGuids: externalSelectedUserGuids = [],
    setSelectedUserGuids: externalSetSelectedUserGuids,
    selectedCompanyLeadSourceGuids: externalSelectedCompanyLeadSourceGuids = [],
    setSelectedCompanyLeadSourceGuids:
      externalSetSelectedCompanyLeadSourceGuids,
    selectedAccountManagerUserGuids:
      externalSelectedAccountManagerUserGuids = [],
    setSelectedAccountManagerUserGuids:
      externalSetSelectedAccountManagerUserGuids,
    userData,
    fetchingUsers,
    fetchingCompanyLeadSources,
    leadSourceData,
    accountManagerEnabled,
  }) => {
    const [moreFiltersOpen, openMoreFilters, closeMoreFilters] =
      useBooleanState()

    const [internalShowMPInvoices, setShowMPInvoices] = useState<boolean>()
    const showMPInvoices = internalShowMPInvoices ?? externalShowMPInvoices
    const [internalSelectedJobClasses, setSelectedJobClasses] =
      useState<JobsV2Class[]>()
    const selectedJobClasses =
      internalSelectedJobClasses ?? externalSelectedJobClasses
    const [internalSelectedUserGuids, setSelectedUserGuids] =
      useState<string[]>()
    const selectedUserGuids =
      internalSelectedUserGuids ?? externalSelectedUserGuids

    const [
      internalSelectedCompanyLeadSourceGuids,
      setSelectedCompanyLeadSourceGuids,
    ] = useState<string[]>()
    const selectedCompanyLeadSourceGuids =
      internalSelectedCompanyLeadSourceGuids ??
      externalSelectedCompanyLeadSourceGuids

    const [
      internalSelectedAccountManagerUserGuids,
      setSelectedAccountManagerUserGuids,
    ] = useState<string[]>()
    const selectedAccountManagerUserGuids =
      internalSelectedAccountManagerUserGuids ??
      externalSelectedAccountManagerUserGuids

    const clearInternalFilters = useCallback(() => {
      setShowMPInvoices(undefined)
      setSelectedJobClasses(undefined)
      setSelectedUserGuids(undefined)
      setSelectedCompanyLeadSourceGuids(undefined)
      setSelectedAccountManagerUserGuids(undefined)
    }, [])

    const closeModal = useCallback(() => {
      clearInternalFilters()
      closeMoreFilters()
    }, [clearInternalFilters, closeMoreFilters])

    const onConfirm = useCallback(() => {
      externalSetShowMPInvoices(showMPInvoices)
      externalSetSelectedJobClasses(selectedJobClasses)
      externalSetSelectedUserGuids(selectedUserGuids)
      externalSetSelectedCompanyLeadSourceGuids(selectedCompanyLeadSourceGuids)
      externalSetSelectedAccountManagerUserGuids(
        selectedAccountManagerUserGuids,
      )

      closeModal()
    }, [
      closeModal,
      externalSetSelectedAccountManagerUserGuids,
      externalSetSelectedCompanyLeadSourceGuids,
      externalSetSelectedJobClasses,
      externalSetSelectedUserGuids,
      externalSetShowMPInvoices,
      selectedAccountManagerUserGuids,
      selectedCompanyLeadSourceGuids,
      selectedJobClasses,
      selectedUserGuids,
      showMPInvoices,
    ])
    const hasFilters = useMemo(
      () =>
        computeHasFilters(
          externalShowMPInvoices,
          externalSelectedJobClasses,
          externalSelectedUserGuids,
          externalSelectedCompanyLeadSourceGuids,
          externalSelectedAccountManagerUserGuids,
        ),
      [
        externalSelectedAccountManagerUserGuids,
        externalSelectedCompanyLeadSourceGuids,
        externalSelectedJobClasses,
        externalSelectedUserGuids,
        externalShowMPInvoices,
      ],
    )

    const userOptions = useMemo(
      () =>
        userData?.companyUsers.map(({ userGuid, userByUserGuid }) => ({
          value: userGuid,
          label: userByUserGuid?.fullName,
        })) ?? [],
      [userData?.companyUsers],
    )

    const leadSourceOptions = useMemo(() => {
      return (
        leadSourceData?.map(leadSource => ({
          value: leadSource.companyLeadSourceGuid,
          label: leadSource.name,
        })) ?? []
      )
    }, [leadSourceData])

    const [areYouSureOpen, openAreYouSure, closeAreYouSure] = useBooleanState()

    const onClose = useCallback(() => {
      if (
        showMPInvoices !== externalShowMPInvoices ||
        !R.equals(
          selectedJobClasses.sort(),
          externalSelectedJobClasses.sort(),
        ) ||
        !R.equals(selectedUserGuids.sort(), externalSelectedUserGuids.sort()) ||
        !R.equals(
          selectedCompanyLeadSourceGuids.sort(),
          externalSelectedCompanyLeadSourceGuids.sort(),
        ) ||
        !R.equals(
          selectedAccountManagerUserGuids.sort(),
          externalSelectedAccountManagerUserGuids.sort(),
        )
      ) {
        closeMoreFilters()
        openAreYouSure()
      } else {
        closeModal()
      }
    }, [
      closeModal,
      closeMoreFilters,
      externalSelectedAccountManagerUserGuids,
      externalSelectedCompanyLeadSourceGuids,
      externalSelectedJobClasses,
      externalSelectedUserGuids,
      externalShowMPInvoices,
      openAreYouSure,
      selectedAccountManagerUserGuids,
      selectedCompanyLeadSourceGuids,
      selectedJobClasses,
      selectedUserGuids,
      showMPInvoices,
    ])

    const onAreYouSureCancel = useCallback(() => {
      closeAreYouSure()
      openMoreFilters()
    }, [closeAreYouSure, openMoreFilters])

    const onAreYouSureConfirm = useCallback(() => {
      closeAreYouSure()
      closeModal()
    }, [closeAreYouSure, closeModal])

    return (
      <>
        <ListPageFilterButton
          hasFilters={hasFilters}
          size="large"
          icon={<FontAwesomeIcon icon={faSliders} />}
          onClick={openMoreFilters}
        >
          More Filters
        </ListPageFilterButton>
        <OnsiteConfirmModal
          open={areYouSureOpen}
          danger
          header="Discard changes?"
          onCancel={onAreYouSureCancel}
          onConfirm={onAreYouSureConfirm}
          confirmText="Discard Changes"
        >
          The filters that you've set will not be applied unless you select
          "Show Results". Are you sure you want to close without applying the
          selected filters?
        </OnsiteConfirmModal>
        <OnsiteConfirmModal
          size="large-width"
          open={moreFiltersOpen}
          header="Filters"
          onCancel={onClose}
          onConfirm={onConfirm}
          confirmText="Apply"
        >
          <Form className="space-y-6" layout="vertical">
            <div className="flex flex-row items-center rounded-md border border-solid border-bz-gray-500 px-3 py-2">
              <div className="mr-4 flex min-w-0 flex-1">
                Show Maintenance Plan Invoices
              </div>
              <Switch
                checked={showMPInvoices}
                onChange={setShowMPInvoices}
                checkedChildren={<FontAwesomeIcon icon={faCheck} />}
                unCheckedChildren={<FontAwesomeIcon icon={faClose} />}
              />
            </div>
            <Form.Item label="Job Class">
              <Select
                size="large"
                mode="multiple"
                optionFilterProp="label"
                value={selectedJobClasses}
                options={JOBS_V2_CLASS_OPTIONS}
                onChange={setSelectedJobClasses}
                placeholder="Select job classes"
              />
            </Form.Item>
            <Form.Item label="Created by">
              <Select
                disabled={fetchingUsers}
                size="large"
                mode="multiple"
                optionFilterProp="label"
                value={selectedUserGuids}
                options={userOptions}
                onChange={setSelectedUserGuids}
                placeholder="Select users"
              />
            </Form.Item>
            <Form.Item label="Lead Sources">
              <Select
                disabled={fetchingCompanyLeadSources}
                size="large"
                mode="multiple"
                optionFilterProp="label"
                value={selectedCompanyLeadSourceGuids}
                options={leadSourceOptions}
                onChange={setSelectedCompanyLeadSourceGuids}
                placeholder="Select Lead Sources"
              />
            </Form.Item>
            {accountManagerEnabled && (
              <Form.Item label="Account Manager">
                <Select
                  disabled={fetchingUsers}
                  size="large"
                  mode="multiple"
                  optionFilterProp="label"
                  value={selectedAccountManagerUserGuids}
                  options={userOptions}
                  onChange={setSelectedAccountManagerUserGuids}
                  placeholder="Select Account Managers"
                />
              </Form.Item>
            )}
          </Form>
        </OnsiteConfirmModal>
      </>
    )
  },
)

const InvoiceListTable = React.memo<{ accountManagerEnabled: boolean }>(
  ({ accountManagerEnabled }) => {
    const {
      pagination: { page, pageSize, setPage, setPageSize },
      userMap,
      hasFilters,
      searchTerm,
    } = useStrictContext(InvoiceListingContext)
    const { data, listDataFetching, totalItems } = useStrictContext(
      InvoiceListingDataContext,
    )

    const tzId = useExpectedCompanyTimeZoneId()

    const isQboEnabled = useIsQboEnabled()

    const qboStaleInvoicesQuery = trpc.qbo[
      'finance-app:get-stale-invoices'
    ].useQuery(
      {},
      {
        enabled: isQboEnabled,
      },
    )

    const cols = useMemo(() => {
      const cols: ListPageTableColsConfig<InvoiceListingInvoice> = [
        {
          header: 'Details',
          flex: 1,
          minWidth: 300,
          render: invoice => {
            const {
              invoiceGuid,
              accountGuid,
              locationGuid,
              job,
              maintenancePlan,
              displayId,
              contact,
              address,
              fullyPaid,
              totalUsc,
              paidUsc,
              processingPaymentsUsc,
            } = invoice

            const amountDueUsc = totalUsc - paidUsc - processingPaymentsUsc

            const detailItems: React.ReactNode[] = [
              <Link
                blue={false}
                bold={false}
                to={CalculatePaths.accountDetails({ accountGuid })}
              >
                {contact.contactName}
              </Link>,
            ]

            if (job) {
              detailItems.push(
                <Link
                  blue={false}
                  bold={false}
                  to={CalculatePaths.jobDetails({ jobGuid: job.jobGuid })}
                >
                  {job.jobType} (#{job.displayId})
                </Link>,
              )
            }
            if (maintenancePlan) {
              detailItems.push(
                <Link
                  blue={false}
                  bold={false}
                  to={CalculatePaths.maintenancePlanDetails({
                    maintenancePlanGuid: maintenancePlan.maintenancePlanGuid,
                  })}
                >
                  {maintenancePlan.name}
                </Link>,
              )
            }

            if (address) {
              detailItems.push(
                <Link
                  blue={false}
                  bold={false}
                  to={CalculatePaths.locationDetails({ locationGuid })}
                >
                  {address}
                </Link>,
              )
            }

            return (
              <DetailsCell
                dropdown={<InvoiceActionsDropdown {...invoice} />}
                link={{
                  to: CalculatePaths.invoiceOverview({ invoiceGuid }),
                  label: `#${displayId}`,
                }}
                detailItems={detailItems}
                footer={
                  <div className="flex flex-row">
                    <DetailChip green={fullyPaid}>
                      <TotalsRectangleLabel
                        fullyPaid={fullyPaid}
                        amountUsc={totalUsc}
                      >
                        Total
                      </TotalsRectangleLabel>

                      {amountDueUsc > 0 && invoice.status !== 'VOIDED' && (
                        <>
                          <TotalsRectangleLabel
                            fullyPaid={fullyPaid}
                            labelClassName="ml-3"
                            amountUsc={amountDueUsc}
                          >
                            Due
                          </TotalsRectangleLabel>
                        </>
                      )}
                      {/* TODO: holding off for design input */}
                      {/* {processingPaymentsUsc > 0 &&
                        invoice.status !== 'VOIDED' && (
                          <>
                            <TotalsRectangleLabel
                              fullyPaid={fullyPaid}
                              labelClassName="ml-3"
                              amountUsc={processingPaymentsUsc}
                            >
                              Due
                            </TotalsRectangleLabel>
                          </>
                        )} */}
                    </DetailChip>
                  </div>
                }
              />
            )
          },
        },
        {
          header: 'Status',
          minWidth: 150,
          cellClassName: 'align-middle',
          render: ({ status }) => <InvoiceStatusTag status={status} />,
        },
        {
          header: 'Contact',
          minWidth: 250,
          render: ({ contact }) => <BaseContactCell {...contact} />,
        },
        {
          header: 'Issue Date',
          minWidth: 150,
          render: ({ issuedAt }) => (
            <>
              {issuedAt ? (
                BzDateFns.format(
                  BzDateFns.parseISO(issuedAt, tzId),
                  'MMM d, yyyy',
                )
              ) : (
                <EmDash />
              )}
            </>
          ),
        },
        {
          header: 'Due Date',
          minWidth: 150,
          render: ({ dueAt, numDaysOverdue, isTerminalStatus, status }) =>
            status === 'VOIDED' ? (
              <EmDash />
            ) : (
              <div>
                <div>{dueAt ?? <EmDash />}</div>
                {!isTerminalStatus && (numDaysOverdue ?? 0) > 0 && (
                  <div className="mt-1 text-bz-gray-700">
                    ({numDaysOverdue} {toPlural(numDaysOverdue ?? 0, 'day')}{' '}
                    past)
                  </div>
                )}
              </div>
            ),
        },
        {
          header: 'Created By',
          minWidth: 120,
          render: ({ createdByUserGuid }) => (
            <TeamMemberCell user={userMap[createdByUserGuid ?? '']} />
          ),
        },
      ]

      if (accountManagerEnabled) {
        cols.push({
          header: 'Acct. Manager',
          minWidth: 140,
          render: ({ accountManagerUserGuid }) => {
            return (
              <>
                {accountManagerUserGuid ? (
                  <TeamMemberCell
                    user={userMap[accountManagerUserGuid ?? '']}
                  />
                ) : (
                  <EmDash />
                )}
              </>
            )
          },
        })
      }

      cols.push({
        header: 'Lead Source',
        minWidth: 150,
        render: ({ leadSource }) => (
          <>{leadSource ? <span>{leadSource.name}</span> : <EmDash />}</>
        ),
      })

      if (isQboEnabled) {
        cols.push({
          header: 'QBO',
          minWidth: 80,
          render: ({ invoiceGuid }) => (
            <QuickbooksSyncInvoiceButton
              placement="left"
              params={{ invoiceGuid }}
              loading={qboStaleInvoicesQuery.isLoading}
              staleInfo={qboStaleInvoicesQuery.data?.[invoiceGuid]}
              onSuccess={qboStaleInvoicesQuery.refetch}
            />
          ),
        })
      }
      return cols
    }, [
      accountManagerEnabled,
      isQboEnabled,
      qboStaleInvoicesQuery.data,
      qboStaleInvoicesQuery.isLoading,
      qboStaleInvoicesQuery.refetch,
      tzId,
      userMap,
    ])

    return (
      <ListPageTable
        data={data}
        fetching={listDataFetching}
        cols={cols}
        rowKey="invoiceGuid"
        page={page}
        pageSize={pageSize}
        totalItems={totalItems}
        noResultsIcon={faFileInvoiceDollar}
        setPage={setPage}
        setPageSize={setPageSize}
        hasFilters={hasFilters || !!searchTerm}
        itemsDescriptor="invoices"
      />
    )
  },
)

const InvoiceSummaryStats = React.memo(() => {
  const {
    summaryStatsLoading,
    summaryStats: {
      outstanding,
      overdue,
      fullyPaid,
      avgValueUsc,
      avgDaysToPay,
    },
  } = useStrictContext(InvoiceListingDataContext)

  return (
    <>
      <SummaryStatBox
        doubleWide
        bottomBarColorClasses={[
          'bg-bz-cyan-300',
          'bg-bz-magenta-300',
          'bg-bz-purple-300',
        ]}
        label="Outstanding"
        loading={summaryStatsLoading}
        secondaryContent={outstanding.count}
        tooltipContent="Displays outstanding invoices, include those that have a status of “Created”, “Presented”, and “Reviewed”."
      >
        {formatAbbreviatedUsc(outstanding.amountUsc)}
      </SummaryStatBox>
      <SummaryStatBox
        label="Overdue"
        loading={summaryStatsLoading}
        secondaryContent={overdue.count}
        tooltipContent="Displays the total amount of invoices that are past due."
      >
        {formatAbbreviatedUsc(overdue.amountUsc)}
      </SummaryStatBox>
      <SummaryStatBox
        bottomBarColorClasses={['bg-bz-green-300']}
        label="Fully Paid"
        loading={summaryStatsLoading}
        tooltipContent="Displays the monetary value of all fully paid invoices."
        secondaryContent={fullyPaid.count}
      >
        {formatAbbreviatedUsc(fullyPaid.amountUsc)}
      </SummaryStatBox>
      <SummaryStatBox
        label="Avg. Invoice Value"
        loading={summaryStatsLoading}
        tooltipContent="Displays the average monetary amount of issued invoices."
      >
        {formatAbbreviatedUsc(avgValueUsc)}
      </SummaryStatBox>
      <SummaryStatBox
        label="Avg. Days to Pay"
        loading={summaryStatsLoading}
        tooltipContent="Displays the average number of calendar days from invoice issuance to payment."
      >
        {avgDaysToPay}
      </SummaryStatBox>
    </>
  )
})

type WithTotalItemsProps = {
  renderChildren: (totalItems: number) => JSX.Element
}

const WithTotalItems = React.memo<WithTotalItemsProps>(({ renderChildren }) => {
  const { totalItems } = useStrictContext(InvoiceListingDataContext)
  return renderChildren(totalItems)
})

export const InvoiceListPageV2 = React.memo(() => {
  const companyGuid = useExpectedCompanyGuid()

  const pagination = usePagination('50')

  const { resetPage } = pagination

  const [startDateOption, setStartDateOption] = useQueryParamStateWithOptions(
    'created',
    'LAST_90_DAYS',
    START_DATE_TYPES,
  )

  const startDate = useMemo(
    () =>
      startDateOption === 'ALL_TIME'
        ? undefined
        : BzDateFns.nowUtcTransform(date => {
            switch (startDateOption) {
              case 'LAST_7_DAYS':
                return BzDateFns.startOfDay(BzDateFns.subDays(date, 7))
              case 'LAST_30_DAYS':
                return BzDateFns.startOfDay(BzDateFns.subDays(date, 30))
              case 'LAST_90_DAYS':
                return BzDateFns.startOfDay(BzDateFns.subDays(date, 90))
              case 'LAST_6_MONTHS':
                return BzDateFns.startOfDay(BzDateFns.subMonths(date, 6))
            }
          }),
    [startDateOption],
  )

  const [searchTerm, setSearchTerm] = useQueryParamState('search', '')

  const [showMPInvoices, setShowMPInvoices] = useQueryParamState('mps', false, {
    encode: (val: boolean) => `${val}`,
    decode: (str: string) => str === 'true',
  })

  const [selectedJobClasses, setSelectedJobClasses] =
    useQueryParamStateEnumArray('jobClass', [], JOBS_V2_CLASSES)

  const [selectedV2Statuses, setSelectedV2Statuses] =
    useQueryParamStateEnumArray('statusv2', [], INVOICE_V2_STATUSES)

  const [selectedUserGuids, setSelectedUserGuids] =
    useQueryParamStateArray<UserGuid>('users', [])

  const [selectedCompanyLeadSourceGuids, setSelectedCompanyLeadSourceGuids] =
    useQueryParamStateArray<CompanyLeadSourceGuid>('leadSources', [])

  const [selectedAccountManagerUserGuids, setSelectedAccountManagerUserGuids] =
    useQueryParamStateArray<UserGuid>('acctManagers', [])

  const [sortType, setSortTypeRaw] = useQueryParamStateWithOptions(
    'sort',
    SORT_TYPES[0],
    SORT_TYPES,
  )

  const setSortType = useCallback(
    (type: SortType) => {
      setSortTypeRaw(type)
      resetPage()
    },
    [resetPage, setSortTypeRaw],
  )

  const [{ data: userData, fetching: fetchingUsers }] = useQuery({
    query: INVOICE_LISTING_USERS_QUERY,
  })

  const userMap = useMemo<UserMap>(
    () =>
      userData?.companyUsers.reduce(
        (map, { userGuid, userByUserGuid }) => ({
          ...map,
          [userGuid]: userByUserGuid,
        }),
        {} as UserMap,
      ) ?? {},
    [userData?.companyUsers],
  )

  const fetchCompanyLeadSourcesQuery = trpc.leadAttribution[
    'company-lead-sources:fetch'
  ].useQuery({ companyGuid })

  const [companyConfigQuery] = useQuery({
    query: COMPANY_CONFIG_QUERY,
    variables: { companyGuid },
  })

  const accountManagerEnabled =
    companyConfigQuery.data?.companyConfigByPk?.accountManagerEnabled ?? false

  const leadSourceMap = useMemo<LeadSourceMap>(
    () =>
      fetchCompanyLeadSourcesQuery.data?.reduce(
        (map, companyLeadSource) => ({
          ...map,
          [companyLeadSource.companyLeadSourceGuid]: companyLeadSource,
        }),
        {} as LeadSourceMap,
      ) ?? {},
    [fetchCompanyLeadSourcesQuery.data],
  )

  const filters = useMemo(() => {
    const filters: { key: string; optionsSelected: string[] }[] = []
    if (showMPInvoices) {
      filters.push({
        key: 'showMPInvoices',
        optionsSelected: ['On'],
      })
    }
    if (selectedJobClasses.length) {
      filters.push({
        key: 'jobClasses',
        optionsSelected: selectedJobClasses.map(
          jobClass => jobClassDisplayNames[jobClass],
        ),
      })
    }

    if (selectedV2Statuses.length) {
      filters.push({
        key: 'status',
        optionsSelected: selectedV2Statuses,
      })
    }

    if (selectedUserGuids.length) {
      filters.push({
        key: 'users',
        optionsSelected: selectedUserGuids.map(
          guid => userMap[guid]?.fullName ?? guid,
        ),
      })
    }

    if (selectedCompanyLeadSourceGuids.length) {
      filters.push({
        key: 'leadSources',
        optionsSelected: selectedCompanyLeadSourceGuids.map(
          guid => leadSourceMap[guid]?.name ?? guid,
        ),
      })
    }

    if (selectedAccountManagerUserGuids.length) {
      filters.push({
        key: 'acctManagers',
        optionsSelected: selectedAccountManagerUserGuids.map(
          guid => userMap[guid]?.fullName ?? guid,
        ),
      })
    }

    return filters
  }, [
    leadSourceMap,
    selectedCompanyLeadSourceGuids,
    selectedJobClasses,
    selectedUserGuids,
    selectedV2Statuses,
    selectedAccountManagerUserGuids,
    showMPInvoices,
    userMap,
  ])

  const hasFilters = useMemo(
    () =>
      computeHasFilters(
        showMPInvoices,
        selectedJobClasses,
        selectedUserGuids,
        selectedCompanyLeadSourceGuids,
        selectedAccountManagerUserGuids,
      ),
    [
      showMPInvoices,
      selectedJobClasses,
      selectedUserGuids,
      selectedCompanyLeadSourceGuids,
      selectedAccountManagerUserGuids,
    ],
  )

  const clearAllFilters = useCallback(() => {
    setShowMPInvoices(false)
    setSelectedJobClasses([])
    setSelectedV2Statuses([])
    setSelectedUserGuids([])
    setSelectedCompanyLeadSourceGuids([])
    setSelectedAccountManagerUserGuids([])
  }, [
    setSelectedAccountManagerUserGuids,
    setSelectedCompanyLeadSourceGuids,
    setSelectedJobClasses,
    setSelectedUserGuids,
    setSelectedV2Statuses,
    setShowMPInvoices,
  ])

  const clearFilter = useCallback(
    (filterKey: string, option: string) => {
      switch (filterKey) {
        case 'showMPInvoices':
          setShowMPInvoices(false)
          break
        case 'jobClasses':
          setSelectedJobClasses(
            selectedJobClasses.filter(
              jc => jobClassDisplayNames[jc] !== option,
            ),
          )
          break
        case 'status':
          setSelectedV2Statuses(
            selectedV2Statuses.filter(status => status !== option),
          )
          break
        case 'users':
          setSelectedUserGuids(
            selectedUserGuids.filter(
              guid => userMap[guid]?.fullName !== option,
            ),
          )
          break
        case 'leadSources':
          setSelectedCompanyLeadSourceGuids(
            selectedCompanyLeadSourceGuids.filter(
              guid => leadSourceMap[guid]?.name !== option,
            ),
          )
          break
        case 'acctManagers':
          setSelectedAccountManagerUserGuids(
            selectedAccountManagerUserGuids.filter(
              guid => userMap[guid]?.fullName !== option,
            ),
          )
          break
      }
    },
    [
      leadSourceMap,
      selectedAccountManagerUserGuids,
      selectedCompanyLeadSourceGuids,
      selectedJobClasses,
      selectedUserGuids,
      selectedV2Statuses,
      setSelectedAccountManagerUserGuids,
      setSelectedCompanyLeadSourceGuids,
      setSelectedJobClasses,
      setSelectedUserGuids,
      setSelectedV2Statuses,
      setShowMPInvoices,
      userMap,
    ],
  )

  const renderFilter = useCallback(
    (totalItems: number) => (
      <ListPageFilterBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        secondaryFilters={
          <ListPageFilter
            options={START_DATE_OPTIONS}
            value={startDateOption}
            valueLabel={PRETTY_START_DATE_MAP[startDateOption]}
            onChange={setStartDateOption}
          />
        }
        totalItems={totalItems}
        sortByControl={
          <ListPageFilter
            label="Sort by"
            options={SORT_TYPE_OPTIONS}
            value={sortType}
            onChange={setSortType}
          />
        }
        hasFilters={hasFilters}
        filters={filters}
        filterLabels={FILTER_LABELS}
        clearAllFilters={clearAllFilters}
        clearFilter={clearFilter}
      >
        <ListPageFilter
          multi
          label="Status"
          options={INVOICE_V2_STATUS_OPTIONS}
          value={selectedV2Statuses}
          onChange={setSelectedV2Statuses}
        />

        <MoreFilters
          showMPInvoices={showMPInvoices}
          setShowMPInvoices={setShowMPInvoices}
          selectedJobClasses={selectedJobClasses}
          setSelectedJobClasses={setSelectedJobClasses}
          selectedUserGuids={selectedUserGuids}
          setSelectedUserGuids={setSelectedUserGuids}
          userData={userData}
          fetchingUsers={fetchingUsers}
          selectedCompanyLeadSourceGuids={selectedCompanyLeadSourceGuids}
          setSelectedCompanyLeadSourceGuids={setSelectedCompanyLeadSourceGuids}
          fetchingCompanyLeadSources={fetchCompanyLeadSourcesQuery.isFetching}
          leadSourceData={fetchCompanyLeadSourcesQuery.data}
          selectedAccountManagerUserGuids={selectedAccountManagerUserGuids}
          accountManagerEnabled={accountManagerEnabled}
          setSelectedAccountManagerUserGuids={
            setSelectedAccountManagerUserGuids
          }
        />
      </ListPageFilterBar>
    ),
    [
      accountManagerEnabled,
      clearAllFilters,
      clearFilter,
      fetchCompanyLeadSourcesQuery.data,
      fetchCompanyLeadSourcesQuery.isFetching,
      fetchingUsers,
      filters,
      hasFilters,
      searchTerm,
      selectedAccountManagerUserGuids,
      selectedCompanyLeadSourceGuids,
      selectedJobClasses,
      selectedUserGuids,
      selectedV2Statuses,
      setSearchTerm,
      setSelectedAccountManagerUserGuids,
      setSelectedCompanyLeadSourceGuids,
      setSelectedJobClasses,
      setSelectedUserGuids,
      setSelectedV2Statuses,
      setShowMPInvoices,
      setSortType,
      setStartDateOption,
      showMPInvoices,
      sortType,
      startDateOption,
      userData,
    ],
  )

  const content = useMemo(
    () => (
      <ListPageContainer
        summaryStats={<InvoiceSummaryStats />}
        filterBar={<WithTotalItems renderChildren={renderFilter} />}
        table={
          <InvoiceListTable accountManagerEnabled={accountManagerEnabled} />
        }
      />
    ),
    [accountManagerEnabled, renderFilter],
  )

  return (
    <Page requiresCompanyUser className="flex min-w-[1460px] overflow-auto">
      <InvoiceListingContext.Provider
        value={{
          pagination,
          userMap,
          hasFilters,
          searchTerm,
          sortType,
          startDate,
          selectedJobClasses,
          selectedV2Statuses,
          selectedUserGuids,
          selectedCompanyLeadSourceGuids,
          selectedAccountManagerUserGuids,
          showMPInvoices,
        }}
      >
        <InvoicesV2DataWrapper>{content}</InvoicesV2DataWrapper>
      </InvoiceListingContext.Provider>
    </Page>
  )
})
