import {
  DataFilterConfig,
  dataFilterConfigSchema,
  groupBy,
  isArray,
} from '@breezy/shared'
import { useMemo } from 'react'

type DataFilterItem = {
  [key: string]: unknown | Array<DataFilterItem>
}

export type DataFilterMenuConfig<Item = DataFilterItem> = {
  [Key in keyof Item]?: Item[Key] extends DataFilterItem
    ? {
        type: 'item'
        label: string
        items: DataFilterMenuConfig<Item[Key]>
        parentGroup?: string
        groups?: string[]
        displayTitle?: string
      }
    : Item[Key] extends DataFilterItem[]
    ? {
        type: 'item'
        label: string
        items: DataFilterMenuConfig<Item[Key][number]>
        parentGroup?: string
        groups?: string[]
        displayTitle?: string
      }
    : {
        type: 'options'
        label: string
        values: {
          label: string
          data: Item[Key]
        }[]
        comparator: (input: {
          label: string
          incoming: Item[Key]
          optionValue: Item[Key]
        }) => boolean
        parentGroup?: string
        displayTitle?: string
      }
}

type DataFilterMenuItemConfig<Item = DataFilterItem> =
  | {
      type: 'item'
      key: string
      label: string
      children: string[]
      path: string[]
      parentKey?: string
      parentGroup?: string
      groups?: string[]
      displayTitle?: string
    }
  | {
      type: 'options'
      key: string
      label: string
      options: { label: string; data: Item[keyof Item] }[]
      path: string[]
      parentKey?: string
      parentGroup?: string
      displayTitle?: string
    }

/**
 * Recursively checks if some Item (object) has a property equal to a specified value
 */
const configHasPropertyEqualTo = <Item = DataFilterItem>(
  config: DataFilterMenuConfig<Item>,
  label: string,
  propertyPath: string[],
  data: Item,
  // TODO: this whole thing is very hard to strongly type. We should refactor in the future so this eslint ignore is not
  // necessary.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueToCompare: any,
): boolean => {
  if (propertyPath.length === 0) {
    return false
  }

  const curr = config[propertyPath[0] as keyof Item]
  if (!curr) {
    return false
  } else if (curr.type === 'item') {
    const currData = data[propertyPath[0] as keyof Item]
    if (Array.isArray(currData)) {
      if (currData.length === 0) {
        return false
      }

      for (let i = 0; i < currData.length; i++) {
        const hasProperty = configHasPropertyEqualTo(
          curr.items as DataFilterMenuConfig<Item[keyof Item]>,
          label,
          propertyPath.slice(1),
          currData[i],
          valueToCompare,
        )

        if (hasProperty) {
          return true
        }
      }

      return false
    } else {
      return configHasPropertyEqualTo(
        curr.items as DataFilterMenuConfig<Item[keyof Item]>,
        label,
        propertyPath.slice(1),
        currData,
        valueToCompare,
      )
    }
  } else {
    for (let i = 0; i < curr.values.length; i++) {
      if (!isArray(data)) {
        const currData = data[propertyPath[0] as keyof Item]
        const isEqual = curr.comparator({
          label,
          incoming: currData,
          optionValue: valueToCompare,
        })

        if (isEqual) {
          return true
        }
      } else {
        for (let j = 0; j < data.length; j++) {
          const currData = data[j][propertyPath[0] as keyof Item]
          const isEqual = curr.comparator({
            label,
            incoming: currData,
            optionValue: valueToCompare,
          })

          if (isEqual) {
            return true
          }
        }
      }
    }
    return false
  }
}

const encodeQueryFilters = (filters: DataFilterConfig[]) =>
  JSON.stringify(filters ?? [])

const decodeQueryFilters = (queryFilters: string) => {
  try {
    const asJSON = JSON.parse(queryFilters)
    const parsed = dataFilterConfigSchema.array().parse(asJSON)
    return parsed as DataFilterConfig[]
  } catch (err) {
    return []
  }
}

interface UseDataFilterBaseInput<Item extends DataFilterItem> {
  config: DataFilterMenuConfig<Item>
  data: Item[]
}

interface UseDataFilterLogicInput<Item extends DataFilterItem>
  extends UseDataFilterBaseInput<Item> {
  filters: DataFilterConfig[]
}

export const DATA_FILTER_DEFAULT_FILTER = [] as DataFilterConfig[]

export const DATA_FILTER_QUERY_PARAM_OPTIONS = {
  base64Encode: true,
  encode: encodeQueryFilters,
  decode: decodeQueryFilters,
}

export const useDataFilterLogic = <Item extends DataFilterItem>({
  config,
  data,
  filters,
}: UseDataFilterLogicInput<Item>): Item[] => {
  const filtered = useMemo(() => {
    const filtersGrouped = Object.entries(groupBy(filters, 'key'))
    return data.filter(item => {
      const acceptedAcrossFilters: boolean[] = []
      for (let i = 0; i < filtersGrouped.length; i++) {
        let isAccepted = false
        const filterGroup = filtersGrouped[i][1]
        for (let j = 0; j < filterGroup.length; j++) {
          const { label, path, compareAgainst } = filterGroup[j]
          if (
            configHasPropertyEqualTo(config, label, path, item, compareAgainst)
          ) {
            isAccepted = true
            break
          }
        }
        acceptedAcrossFilters.push(isAccepted)
      }

      return !acceptedAcrossFilters.includes(false)
    })
  }, [config, data, filters])

  return filtered
}

type DataFilterMenuConfigEntries<Item = DataFilterItem> = [
  keyof DataFilterMenuConfig<Item>,
  DataFilterMenuConfig<Item>[keyof DataFilterMenuConfig<Item>],
][]

/**
 * Recursively creates an array of DataFilterMenuItemConfig items.
 */
export const createDataFilterMenuItems = <Item = DataFilterItem>(
  config: DataFilterMenuConfig<Item>,
  path: string[],
  parentKey?: string,
): DataFilterMenuItemConfig[] => {
  const entries = Object.entries(config) as DataFilterMenuConfigEntries<Item>

  const items: DataFilterMenuItemConfig[] = []
  for (const [key, value] of entries) {
    if (!value) {
      continue
    }

    const itemKey = parentKey
      ? `${parentKey}-${key.toString()}`
      : key.toString()

    const currPath = [...path, key.toString()]
    if (value.type === 'item') {
      const children = createDataFilterMenuItems(
        value.items as DataFilterMenuConfig<Item[keyof Item]>,
        currPath,
        itemKey,
      )
      const item: DataFilterMenuItemConfig = {
        type: 'item',
        key: itemKey,
        label: value.label,
        path: currPath,
        children: children.map(child => child.key),
        parentKey,
        parentGroup: value.parentGroup,
        groups: value.groups,
        displayTitle: value.displayTitle,
      }
      items.push(item, ...children)
    } else if (value.type === 'options') {
      const item: DataFilterMenuItemConfig = {
        type: 'options',
        key: itemKey,
        label: value.label,
        options: value.values,
        parentKey,
        path: currPath,
        parentGroup: value.parentGroup,
        displayTitle: value.displayTitle,
      }
      items.push(item)
    }
  }

  return items
}
