import { JobLifecycleView, nextGuid } from '@breezy/shared'
import { Form, Input, Modal } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useJobsViewsContextValue } from '../../../components/JobLifecycleView'
import { LoadingSpinner } from '../../../components/LoadingSpinner'

const CHARACTER_LIMIT = 40

type CreateJobLifecycleViewModalProps = {
  onClose: () => void
  viewToEdit?: Partial<JobLifecycleView>
}
export const CreateJobLifecycleViewModal = ({
  onClose,
  viewToEdit,
}: CreateJobLifecycleViewModalProps) => {
  const { upsertJobsView, jobsViews } = useJobsViewsContextValue()

  const [validationError, setValidationError] = useState('')

  const usedNameMap = useMemo(() => {
    const usedNameMap: Record<string, true> = {}
    for (const view of jobsViews) {
      usedNameMap[view.title.toLowerCase()] = true
    }
    return usedNameMap
  }, [jobsViews])

  const [jobLifecycleTitle, setJobLifecycleTitleRaw] = useState(
    viewToEdit?.title ?? '',
  )

  const validateTitle = useCallback(
    (title: string) => {
      let message = ''
      if (title === '') {
        message = 'Please give your lifecycle a name.'
      } else if (usedNameMap[title.toLowerCase()]) {
        message = 'That name is already taken.'
      } else if (title.length > CHARACTER_LIMIT) {
        message = `You cannot have a name longer than ${CHARACTER_LIMIT} characters.`
      }
      setValidationError(message)
      return message
    },
    [usedNameMap],
  )

  const setJobLifecycleTitle = useCallback(
    (title: string) => {
      setJobLifecycleTitleRaw(title)
      validateTitle(title)
    },
    [validateTitle],
  )

  const [loading, setLoading] = useState(false)

  const onSubmitPressed = useCallback(async () => {
    const title = jobLifecycleTitle.trim()
    const validationError = validateTitle(title)
    if (validationError) {
      return
    }

    const view: JobLifecycleView = {
      jobLifecycleViewGuid: viewToEdit?.jobLifecycleViewGuid ?? nextGuid(),
      title,
      view: viewToEdit?.view ?? {},
    }
    setLoading(true)
    try {
      await upsertJobsView(view)
    } finally {
      setLoading(false)
    }

    onClose()
  }, [jobLifecycleTitle, validateTitle, viewToEdit, upsertJobsView, onClose])

  const remainingCharacters = CHARACTER_LIMIT - jobLifecycleTitle.length

  return (
    <Modal
      open
      title={viewToEdit?.jobLifecycleViewGuid ? 'Edit View' : 'Create New View'}
      okText={
        loading ? (
          <LoadingSpinner noMinHeight spinnerClassName="h-4 w-4" />
        ) : (
          'Save'
        )
      }
      onOk={onSubmitPressed}
      onCancel={onClose}
      okButtonProps={{
        disabled: !!validationError || loading,
      }}
    >
      <Form.Item
        validateStatus={validationError ? 'error' : ''}
        help={validationError}
        className={validationError ? 'pb-4' : ''}
      >
        <Input
          type="text"
          value={jobLifecycleTitle}
          onChange={event => setJobLifecycleTitle(event.currentTarget.value)}
          required
        />
        <div className="mt-1 text-xs text-bz-gray-800">
          {remainingCharacters}/{CHARACTER_LIMIT} characters remaining.
        </div>
      </Form.Item>
    </Modal>
  )
}
