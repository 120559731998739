import {
  BzDateFns,
  IsoDateString,
  RoleId,
  toRoleDisplayName,
} from '@breezy/shared'
import { Popover } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { getAvatarShortStringForPerson } from '../../utils/TechnicianResource'

type TeamMemberCellProps = {
  user?: {
    firstName: string
    lastName: string
    userRoles: {
      role: RoleId
    }[]
    deactivatedAt?: IsoDateString
  }
}

export const TeamMemberCell = React.memo<TeamMemberCellProps>(({ user }) => {
  const tzId = useExpectedCompanyTimeZoneId()

  return (
    <>
      {user && (
        <Popover
          content={
            <div>
              <div className="font-semibold">
                {user.firstName} {user.lastName}
              </div>
              {user.userRoles.length > 0 && (
                <div className="text-bz-gray-900">
                  {toRoleDisplayName(user.userRoles[0].role)}
                </div>
              )}
              {user.deactivatedAt && (
                <div className="text-bz-gray-700">
                  User deactivated on{' '}
                  {BzDateFns.formatFromISO(
                    user.deactivatedAt,
                    'MMMM d, yyyy',
                    tzId,
                  )}
                </div>
              )}
            </div>
          }
        >
          <div
            className={classNames(
              'flex h-10 w-10 items-center justify-center rounded-full border-2 border-solid border-bz-gray-100 bg-bz-gray-300 font-semibold text-bz-gray-800 transition-colors ease-in-out hover:bg-bz-gray-500',
              {
                'opacity-50': user.deactivatedAt,
              },
            )}
          >
            {getAvatarShortStringForPerson(user)}
          </div>
        </Popover>
      )}
    </>
  )
})
