import React, { useCallback, useRef, useState } from 'react'

import { Card } from '../../elements/Card/Card'
import { OnResize, useResizeObserver } from '../../hooks/useResizeObserver'
import { SummaryStatsContainer } from './SummaryStats'

export const FilterHeightContext = React.createContext<number>(0)

export type ListPageContainerProps = {
  summaryStats: React.ReactNode
  filterBar: React.ReactNode
  table: React.ReactNode
}

export const ListPageContainer = React.memo<ListPageContainerProps>(
  ({ summaryStats, filterBar, table }) => {
    const filterBarContainerRef = useRef<HTMLDivElement>(null)

    const [filterBarHeight, setFilterBarHeight] = useState(0)

    const onResize = useCallback<OnResize>(({ height }) => {
      setFilterBarHeight(height)
    }, [])

    useResizeObserver(filterBarContainerRef, onResize)

    return (
      <Card className="flex flex-1 flex-col overflow-hidden" noPadding>
        <div className="flex h-full min-h-0 flex-col overflow-auto py-0">
          <div className="sticky top-0 z-10 min-h-6 w-full bg-white" />
          <SummaryStatsContainer>{summaryStats}</SummaryStatsContainer>
          <div
            ref={filterBarContainerRef}
            className="sticky top-6 z-10 bg-white px-6"
          >
            {filterBar}
          </div>
          <FilterHeightContext.Provider value={filterBarHeight}>
            {table}
          </FilterHeightContext.Provider>
        </div>
      </Card>
    )
  },
)
