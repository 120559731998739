import { Guid, nextGuid } from '@breezy/shared'
import { message } from 'antd'
import { useCallback, useMemo } from 'react'
import { useMutation } from 'urql'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { RecommendationCard } from '../../components/Recommendations/RecommendationCard'
import { Card } from '../../elements/Card/Card'
import { gql } from '../../generated'
import { useFetchMyActiveRecommendations } from '../../hooks/fetch/useFetchMyActiveRecommendations'
import {
  useExpectedCompanyGuid,
  useExpectedUserGuid,
} from '../../providers/PrincipalUser'

const DISMISS_RECOMMENDATION_MUTATION = gql(`
  mutation DismissRecommendation($object: RecommendationDismissalsInsertInput!) {
    insertRecommendationDismissalsOne(object: $object) {
      __typename
    }
  }
`)

const MARK_RECOMMENDATION_INTERACTION_MUTATION = gql(`
  mutation InteractWithRecommendation($object: RecommendationInteractionsInsertInput!) {
    insertRecommendationInteractionsOne(object: $object) {
      __typename
    }
  }
`)

const COMPLETE_RECOMMENDATION_MUTATION = gql(`
  mutation CompleteRecommendation($recommendationGuid: uuid!, $completedAt: timestamptz!, $completedByUserGuid: uuid!) {
    updateRecommendations(
      where: {recommendationGuid: {_eq: $recommendationGuid}},
      _set: {
        completedAt: $completedAt,
        completedByUserGuid: $completedByUserGuid
      }
    ) {
      affectedRows
    }
  }
`)

const TodayRecommendationsWidget = () => {
  const userGuid = useExpectedUserGuid()
  const companyGuid = useExpectedCompanyGuid()
  const { data: items, fetching, refetch } = useFetchMyActiveRecommendations()
  const longestTitleText = useMemo(
    () =>
      items?.reduce(
        (acc, item) => (item.ctaText.length > acc.length ? item.ctaText : acc),
        '',
      ),
    [items],
  )

  const [, dismiss] = useMutation(DISMISS_RECOMMENDATION_MUTATION)
  const onDismiss = useCallback(
    (recommendationGuid: Guid) => {
      dismiss({
        object: {
          recommendationDismissalGuid: nextGuid(),
          companyGuid,
          userGuid,
          recommendationGuid,
          dismissedAt: new Date().toISOString(),
        },
      })
        .then(() => {
          refetch()
        })
        .catch(_ => {
          message.error('Failed to dismiss recommendation. Try again later.')
        })
    },
    [dismiss, refetch, companyGuid, userGuid],
  )

  const [, complete] = useMutation(COMPLETE_RECOMMENDATION_MUTATION)
  const onComplete = useCallback(
    (recommendationGuid: Guid) => {
      complete({
        recommendationGuid,
        completedAt: new Date().toISOString(),
        completedByUserGuid: userGuid,
      })
        .then(() => {
          refetch()
        })
        .catch(_ => {
          message.error('Failed to complete recommendation. Try again later.')
        })
    },
    [complete, refetch, userGuid],
  )

  const [, markInteraction] = useMutation(
    MARK_RECOMMENDATION_INTERACTION_MUTATION,
  )
  const onInteract = useCallback(
    (recommendationGuid: Guid) => {
      markInteraction({
        object: {
          recommendationInteractionGuid: nextGuid(),
          recommendationGuid,
          companyGuid,
          userGuid,
          interactedAt: new Date().toISOString(),
        },
      })
        .then(() => {
          refetch()
        })
        .catch(e => {
          console.error('Failed to mark interaction.', e)
        })
    },
    [markInteraction, refetch, userGuid, companyGuid],
  )

  return (
    <Card
      title="Today's AI-Powered Recommendations"
      infoNextToTitle
      info="These are the top AI-recommended actions for you today. Click on each action to see more details and take action."
      className="h-full"
    >
      <div className="flex flex-row space-x-2 overflow-x-auto px-2 pb-4">
        {fetching && (
          <div className="flex h-full min-h-[120px] w-full flex-col items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
        {!fetching && !!items && (
          <>
            {items.map((data, index) => (
              <div className="max-w-[600px]">
                <RecommendationCard
                  key={index}
                  longestTitleText={longestTitleText ?? data.ctaText}
                  viewModel={data}
                  onDismiss={onDismiss}
                  onComplete={onComplete}
                  onInteract={onInteract}
                />
              </div>
            ))}
            {(items ?? []).length === 0 && (
              <div className="flex h-full min-h-[120px] w-full flex-col items-center justify-center">
                <p className="text-md text-gray-500">
                  No more recommendations available today.
                </p>
                <p className="text-md text-gray-500">
                  Nice work! Check again tomorrow.
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </Card>
  )
}

export default TodayRecommendationsWidget
