import { gql } from 'src/generated'

export const TIMESHEET_DETAILS_SUMMARY_QUERY = gql(/* GraphQL */ `
  query TimesheetsSummaryPageTimesheetsDetailSummaryQuery(
    $companyGuid: uuid!
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    users(
      where: { companyUser: { companyGuid: { _eq: $companyGuid } } }
      orderBy: { firstName: ASC }
    ) {
      userGuid
      firstName
      lastName
      userRoles {
        role
      }
      timesheetEntries(
        where: {
          finalStartTime: { _gte: $startDate }
          finalEndTime: { _lte: $endDate }
        }
        orderBy: { finalStartTime: ASC }
      ) {
        timesheetEntryGuid
        finalStartTime
        finalEndTime
        entryLengthInMinutes
        timesheetEntryActivity {
          isPayable
        }
        deletedAt
      }
    }
  }
`)

export const TIMESHEET_TECHNICIANS_QUERY = gql(/* GraphQL */ `
  query TimesheetsSummaryPageTechniciansQuery(
    $companyGuid: uuid!
    $search: String!
    $roleSearch: String!
  ) {
    users(
      where: {
        _and: {
          companyUser: { companyGuid: { _eq: $companyGuid } }
          _or: [
            { fullName: { _ilike: $search } }
            { userRoles: { role: { _ilike: $roleSearch } } }
          ]
        }
      }
      orderBy: { firstName: ASC }
    ) {
      userGuid
      fullName
      firstName
      lastName
      userRoles {
        role
      }
    }
  }
`)
