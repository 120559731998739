import {
  BulkPricebookCategory,
  BulkPricebookItemRow,
  PricebookCategory,
  QboIncomeAccount,
} from '@breezy/shared'
import { ISpace, initializeFlatfile } from '@flatfile/react'
import cn from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { getConfig } from '../../config'
import { LoadingSpinner } from '../LoadingSpinner'
import './FlatfilePricebookImport.less'
import { GettingStartedMd } from './docs/GettingStartedMd'
import { BreezyFlatfileTheme } from './theme/BreezyFlatfileTheme'
import { useFlatfilePricebookImportListener } from './useFlatfilePricebookImportListener'
import {
  pricebookImportWorkbook,
  pricebookImportWorkbookWithQbo,
} from './workbooks/pricebookImportWorkbook'
const config = getConfig()

export type FlatfilePricebookImportHookInput = {
  categories: PricebookCategory[] | undefined
  stageImportRows: (
    pricebookCategoryCells: BulkPricebookCategory[],
    pricebookItemCells: BulkPricebookItemRow[],
  ) => void
  onContinue: () => void
  qboEnabled?: boolean
  qboIncomeAccounts?: QboIncomeAccount[]
}

export const useFlatfilePricebookImport = (
  input: FlatfilePricebookImportHookInput,
) => {
  const [showSpace, setShowSpace] = useState(false)
  const listener = useFlatfilePricebookImportListener({
    ...input,
    onContinue: () => {
      setShowSpace(false)
      input.onContinue()
    },
  })
  const FlatfileSpaceProps: ISpace = useMemo(
    () => ({
      name: 'Pricebook Import',
      workbook: input.qboEnabled
        ? pricebookImportWorkbookWithQbo
        : pricebookImportWorkbook,
      publishableKey: config.flatfilePublishableKey,
      environmentId: config.flatfileEnvironmentId,
      metadata: {
        theme: BreezyFlatfileTheme,
      },
      listener,
      sidebarConfig: {
        showSidebar: true,
      },
      document: {
        title: 'Getting Started',
        body: GettingStartedMd,
      },
      displayAsModal: false,
      loading: <LoadingSpinner />,
    }),
    [input.qboEnabled, listener],
  )

  const { Space: FlatfileSpace, OpenEmbed } =
    initializeFlatfile(FlatfileSpaceProps)

  const show = useCallback(() => {
    setShowSpace(true)
  }, [])

  const initialize = async () => {
    show()
    await OpenEmbed()
  }

  const FlatfileImporter = useMemo(
    () => (
      <div
        className={cn({
          hidden: !showSpace,
          block: showSpace,
        })}
      >
        {FlatfileSpace()}
      </div>
    ),
    [FlatfileSpace, showSpace],
  )

  return {
    initialize,
    show,
    FlatfileImporter,
  }
}
