import { useCallback, useState } from 'react'
import DangerConfirmModal from '../../components/DangerConfirmModal/DangerConfirmModal'
import { m } from '../../utils/react-utils'
import FaIconButton, { FaIconButtonsProps } from '../FaIconButton/FaIconButton'

export type FaIconButtonWithConfirmProps = Omit<
  FaIconButtonsProps,
  'onClick'
> & {
  onClick: () => void
  confirmModalTitle: string
  confirmModalOkButtonText: string
  confirmModalPromptText: React.ReactNode
}

export const FaIconButtonWithConfirm = m<FaIconButtonWithConfirmProps>(
  ({
    confirmModalTitle,
    confirmModalOkButtonText,
    confirmModalPromptText,
    ...rest
  }) => {
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)

    const openConfirmModal = useCallback(() => {
      setConfirmModalOpen(true)
    }, [setConfirmModalOpen])

    const closeConfirmModal = useCallback(() => {
      setConfirmModalOpen(false)
    }, [setConfirmModalOpen])

    return (
      <>
        <FaIconButton {...rest} onClick={openConfirmModal} />
        <DangerConfirmModal
          title={confirmModalTitle}
          open={confirmModalOpen}
          okButtonText={confirmModalOkButtonText}
          onOk={() => rest.onClick()}
          onCancel={closeConfirmModal}
          promptText={confirmModalPromptText}
        />
      </>
    )
  },
)
