import React from 'react'
import { OnsiteEmbeddedContext } from '../../../adam-components/OnsitePage/onsitePageUtils'
import { Page } from '../../../components/Page/Page'
import { Card } from '../../../elements/Card/Card'
import { InvoiceCreationPage } from '../InvoiceCreationPage'

export const WebAppInvoiceCreationPage = React.memo(() => {
  return (
    <Page requiresCompanyUser className="flex px-6 py-5">
      <Card className="max-h-full w-full">
        <div className="m-[-16px] min-h-0 flex-1">
          <OnsiteEmbeddedContext.Provider
            value={{ embedded: true, defaultOnDeleteTo: '/invoices' }}
          >
            <InvoiceCreationPage />
          </OnsiteEmbeddedContext.Provider>
        </div>
      </Card>
    </Page>
  )
})
