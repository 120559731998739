import {
  AttributionLinkingStrategyValues,
  bzExpect,
  bzOptional,
  isNullish,
} from '@breezy/shared'
import { faBillboard } from '@fortawesome/pro-light-svg-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Input, Select } from 'antd'
import { forwardRef, useEffect, useRef } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useQuery } from 'urql'
import { z } from 'zod'
import GqlQueryLoader from '../../../components/GqlQueryLoader/GqlQueryLoader'
import BzDrawer from '../../../elements/BzDrawer/BzDrawer'
import {
  CANONICAL_LEAD_SOURCES_QUERY,
  CanonicalLeadSource,
} from '../LeadSourcesSettingsPage.gql'

const addLeadSourceFormSchema = z.object({
  leadSourceType: z.string(),
  leadSourceName: z.string(),
  attributionLinkingStrategy: z.string(),
  attributionPrompt: bzOptional(z.string()),
})

type AddLeadSourceFormSchema = z.infer<typeof addLeadSourceFormSchema>

type OnAddLeadSourceClickedHandlerInput = {
  canonicalLeadSource: {
    canonicalLeadSourceName: string
    attributionLinkingStrategy: string
    attributionPrompt?: string
  }
  overrides: {
    name?: string
    attributionLinkingStrategy?: string
    attributionPrompt?: string
  }
}

type AddLeadSourceFormDrawerContentProps = {
  canonicalLeadSources: CanonicalLeadSource[]
  onAddLeadSourceClicked: (input: OnAddLeadSourceClickedHandlerInput) => void
}

const AddLeadSourceFormDrawerContent = forwardRef<
  HTMLInputElement,
  AddLeadSourceFormDrawerContentProps
>(({ canonicalLeadSources, onAddLeadSourceClicked }, ref) => {
  const {
    formState: { errors },
    control,
    handleSubmit,
    setValue,
  } = useForm<AddLeadSourceFormSchema>({
    resolver: zodResolver(addLeadSourceFormSchema),
    defaultValues: {
      leadSourceType: canonicalLeadSources[0]?.canonicalLeadSourceName,
      attributionLinkingStrategy:
        canonicalLeadSources[0]?.attributionLinkingStrategy,
    },
  })

  const currLeadSourceType = useWatch({ control, name: 'leadSourceType' })

  const onSubmit = handleSubmit(
    ({
      leadSourceType,
      leadSourceName,
      attributionLinkingStrategy,
      attributionPrompt,
    }) => {
      const canonicalLeadSource = bzExpect(
        canonicalLeadSources.find(
          leadSource => leadSource.canonicalLeadSourceName === leadSourceType,
        ),
      )

      onAddLeadSourceClicked({
        canonicalLeadSource,
        overrides: {
          name:
            leadSourceName !== canonicalLeadSource.canonicalLeadSourceName
              ? leadSourceName
              : undefined,
          attributionLinkingStrategy:
            attributionLinkingStrategy !==
            canonicalLeadSource.attributionLinkingStrategy
              ? attributionLinkingStrategy
              : undefined,
          attributionPrompt:
            attributionPrompt !== canonicalLeadSource.attributionPrompt
              ? attributionPrompt
              : undefined,
        },
      })
    },
  )

  useEffect(() => {
    if (isNullish(currLeadSourceType)) {
      return
    }

    const canonicalLeadSource = canonicalLeadSources.find(
      curr => curr.canonicalLeadSourceName === currLeadSourceType,
    )
    if (isNullish(canonicalLeadSource)) {
      return
    }

    setValue(
      'attributionLinkingStrategy',
      canonicalLeadSource.attributionLinkingStrategy,
    )
    setValue('attributionPrompt', canonicalLeadSource.attributionPrompt)
    setValue('leadSourceName', canonicalLeadSource.canonicalLeadSourceName)
  }, [canonicalLeadSources, currLeadSourceType, setValue])

  return (
    <form className="flex flex-col gap-6" onSubmit={onSubmit}>
      <div className="grid grid-cols-2 items-center gap-3">
        <Controller
          control={control}
          name="leadSourceType"
          render={({ field }) => (
            <label className="flex flex-col gap-1">
              <span>Type</span>

              <Select
                {...field}
                options={canonicalLeadSources.map(leadSource => ({
                  label: leadSource.canonicalLeadSourceName,
                  value: leadSource.canonicalLeadSourceName,
                }))}
                status={
                  !isNullish(errors.leadSourceType?.message)
                    ? 'error'
                    : undefined
                }
              />
            </label>
          )}
        />

        <Controller
          control={control}
          name="leadSourceName"
          render={({ field }) => (
            <label className="flex flex-col gap-1">
              <span>Name</span>

              <Input
                {...field}
                placeholder="Enter Lead Source name here..."
                status={
                  !isNullish(errors.leadSourceName?.message)
                    ? 'error'
                    : undefined
                }
              />
            </label>
          )}
        />
      </div>

      <Controller
        control={control}
        name="attributionPrompt"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Prompt</span>

            <Input
              {...field}
              placeholder="Enter prompt here..."
              status={
                !isNullish(errors.leadSourceName?.message) ? 'error' : undefined
              }
            />
          </label>
        )}
      />

      <Controller
        control={control}
        name="attributionLinkingStrategy"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Link Lead Source To</span>

            <Select
              {...field}
              options={AttributionLinkingStrategyValues.map(
                linkingStrategy => ({
                  label: linkingStrategy,
                  value: linkingStrategy,
                }),
              )}
              status={
                !isNullish(errors.attributionLinkingStrategy?.message)
                  ? 'error'
                  : undefined
              }
            />
          </label>
        )}
      />

      <input ref={ref} type="submit" className="hidden" />
    </form>
  )
})

export type AddLeadSourceDrawerSchemaProps = {
  open: boolean
  onAddLeadSourceClicked: (input: OnAddLeadSourceClickedHandlerInput) => void
  onCancel: () => void
}

export const AddLeadSourceFormDrawer = ({
  open,
  onAddLeadSourceClicked,
  onCancel,
}: AddLeadSourceDrawerSchemaProps) => {
  const btnFormSubmitRef = useRef<HTMLInputElement>(null)

  const canonicalLeadSourcesQuery = useQuery({
    query: CANONICAL_LEAD_SOURCES_QUERY,
  })

  return (
    <BzDrawer
      title="Add Lead Source"
      icon={faBillboard}
      preferredWidth={720}
      item={open ? { onCancel } : undefined}
      footer={
        <div className="flex w-full flex-row gap-2">
          <Button className="ml-auto" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => btnFormSubmitRef.current?.click()}
          >
            Add Lead Source
          </Button>
        </div>
      }
    >
      <GqlQueryLoader
        query={canonicalLeadSourcesQuery}
        render={data => (
          <AddLeadSourceFormDrawerContent
            ref={btnFormSubmitRef}
            canonicalLeadSources={data.canonicalLeadSources}
            onAddLeadSourceClicked={onAddLeadSourceClicked}
          />
        )}
      />
    </BzDrawer>
  )
}
