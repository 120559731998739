import {
  AttributionLinkingStrategyValues,
  BzDateFns,
  bzOptional,
  isNullish,
  isValidGuid,
} from '@breezy/shared'
import { faBillboard } from '@fortawesome/pro-light-svg-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Input, Select, Switch } from 'antd'
import { forwardRef, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'urql'
import { z } from 'zod'
import { GqlMultiQueryLoader } from '../../../components/GqlQueryLoader/GqlQueryLoader'
import BzDrawer from '../../../elements/BzDrawer/BzDrawer'
import { useExpectedCompany } from '../../../providers/PrincipalUser'
import {
  CANONICAL_LEAD_SOURCES_QUERY,
  CanonicalLeadSource,
  LEAD_SOURCES_SINGLE_QUERY,
} from '../LeadSourcesSettingsPage.gql'

const editLeadSourceFormSchema = z.object({
  leadSourceType: z.string(),
  leadSourceName: z.string(),
  attributionLinkingStrategy: z.string(),
  attributionPrompt: bzOptional(z.string()),
  archived: z.boolean(),
})

type EditLeadSourceFormSchema = z.infer<typeof editLeadSourceFormSchema>

type OnEditLeadSourceClickedInput = {
  canonicalLeadSourceName: string
  canonicalLeadSourceNameOverride?: string
  attributionLinkingStrategy: string
  attributionLinkingStrategyOverride?: string
  attributionPrompt?: string
  attributionPromptOverride?: string
  archivedAt?: string
}

export type EditLeadSourceDrawerContentProps = {
  canonicalLeadSources: CanonicalLeadSource[]
  leadSource: {
    canonicalLeadSourceName: string
    canonicalLeadSourceNameOverride?: string
    attributionLinkingStrategy: string
    attributionLinkingStrategyOverride?: string
    attributionPrompt?: string
    attributionPromptOverride?: string
    archivedAt?: string
  }
  onEditLeadSourceClicked: (input: OnEditLeadSourceClickedInput) => void
}

const EditLeadSourceDrawerContent = forwardRef<
  HTMLInputElement,
  EditLeadSourceDrawerContentProps
>(({ canonicalLeadSources, leadSource, onEditLeadSourceClicked }, ref) => {
  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<EditLeadSourceFormSchema>({
    resolver: zodResolver(editLeadSourceFormSchema),
    defaultValues: {
      leadSourceType: leadSource.canonicalLeadSourceName,
      leadSourceName:
        leadSource.canonicalLeadSourceNameOverride ??
        leadSource.canonicalLeadSourceName,
      attributionLinkingStrategy:
        leadSource.attributionLinkingStrategyOverride ??
        leadSource.attributionLinkingStrategy,
      attributionPrompt:
        leadSource.attributionPromptOverride ?? leadSource.attributionPrompt,
      archived: !isNullish(leadSource.archivedAt),
    },
  })

  const onSubmit = handleSubmit(
    ({
      leadSourceType,
      leadSourceName,
      attributionLinkingStrategy,
      attributionPrompt,
      archived,
    }) => {
      onEditLeadSourceClicked({
        canonicalLeadSourceName: leadSourceType,
        canonicalLeadSourceNameOverride:
          leadSourceName !== leadSourceType ? leadSourceName : undefined,
        attributionLinkingStrategy: leadSource.attributionLinkingStrategy,
        attributionLinkingStrategyOverride:
          attributionLinkingStrategy !== leadSource.attributionLinkingStrategy
            ? attributionLinkingStrategy
            : undefined,
        attributionPrompt: leadSource.attributionPrompt,
        attributionPromptOverride:
          attributionPrompt !== leadSource.attributionPrompt
            ? attributionPrompt
            : undefined,
        archivedAt: archived
          ? isNullish(leadSource.archivedAt)
            ? BzDateFns.nowISOString()
            : leadSource.archivedAt
          : undefined,
      })
    },
  )

  return (
    <form className="flex flex-col gap-3" onSubmit={onSubmit}>
      <div className="grid grid-cols-2 items-center gap-3">
        <Controller
          control={control}
          name="leadSourceType"
          render={({ field }) => (
            <label className="flex flex-col gap-1">
              <span>Type</span>

              <Select
                {...field}
                options={canonicalLeadSources.map(leadSource => ({
                  label: leadSource.canonicalLeadSourceName,
                  value: leadSource.canonicalLeadSourceName,
                }))}
                status={
                  !isNullish(errors.leadSourceType?.message)
                    ? 'error'
                    : undefined
                }
              />
            </label>
          )}
        />

        <Controller
          control={control}
          name="leadSourceName"
          render={({ field }) => (
            <label className="flex flex-col gap-1">
              <span>Name</span>

              <Input
                {...field}
                placeholder="Enter Lead Source name here..."
                status={
                  !isNullish(errors.leadSourceName?.message)
                    ? 'error'
                    : undefined
                }
              />
            </label>
          )}
        />
      </div>

      <Controller
        control={control}
        name="attributionPrompt"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Prompt</span>

            <Input
              {...field}
              placeholder="Enter prompt here..."
              status={
                !isNullish(errors.leadSourceName?.message) ? 'error' : undefined
              }
            />
          </label>
        )}
      />

      <Controller
        control={control}
        name="attributionLinkingStrategy"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Link Lead Source To</span>

            <Select
              {...field}
              options={AttributionLinkingStrategyValues.map(
                linkingStrategy => ({
                  label: linkingStrategy,
                  value: linkingStrategy,
                }),
              )}
              status={
                !isNullish(errors.attributionLinkingStrategy?.message)
                  ? 'error'
                  : undefined
              }
            />
          </label>
        )}
      />

      <Controller
        control={control}
        name="archived"
        render={({ field }) => (
          <label className="flex flex-row gap-3">
            <span>Archived:</span>
            <Switch {...field} />
          </label>
        )}
      />

      <input ref={ref} type="submit" className="hidden" />
    </form>
  )
})

export type EditLeadSourceDrawerProps = {
  open: boolean
  companyLeadSourceGuid: string
  onEditLeadSourceClicked: (input: OnEditLeadSourceClickedInput) => void
  onCancel: () => void
}

export const EditLeadSourceDrawer = ({
  open,
  companyLeadSourceGuid,
  onEditLeadSourceClicked,
  onCancel,
}: EditLeadSourceDrawerProps) => {
  const { companyGuid } = useExpectedCompany()
  const isValidCompanyLeadSourceGuid = isValidGuid(companyLeadSourceGuid)

  const canonicalLeadSourcesQuery = useQuery({
    query: CANONICAL_LEAD_SOURCES_QUERY,
    pause: !isValidCompanyLeadSourceGuid,
  })

  const leadSourceQuery = useQuery({
    query: LEAD_SOURCES_SINGLE_QUERY,
    variables: { companyGuid, companyLeadSourceGuid },
    pause: !isValidCompanyLeadSourceGuid,
    requestPolicy: 'network-only',
  })

  const btnFormSubmitRef = useRef<HTMLInputElement>(null)

  if (!isValidGuid(companyLeadSourceGuid)) {
    return (
      <BzDrawer
        title="Edit Lead Source"
        icon={faBillboard}
        preferredWidth={720}
        item={open ? { onCancel } : undefined}
      >
        <span>Invalid Lead Source</span>
      </BzDrawer>
    )
  }

  return (
    <BzDrawer
      title="Edit Lead Source"
      icon={faBillboard}
      preferredWidth={720}
      destroyOnClose
      item={open ? { onCancel } : undefined}
      footer={
        <div className="flex flex-row gap-2">
          <Button className="ml-auto" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => btnFormSubmitRef.current?.click()}
          >
            Save
          </Button>
        </div>
      }
    >
      <GqlMultiQueryLoader
        queries={[canonicalLeadSourcesQuery, leadSourceQuery]}
        render={([canonicalLeadSources, leadSource]: [
          NonNullable<(typeof canonicalLeadSourcesQuery)[0]['data']>,
          NonNullable<(typeof leadSourceQuery)[0]['data']>,
        ]) => (
          <EditLeadSourceDrawerContent
            ref={btnFormSubmitRef}
            canonicalLeadSources={canonicalLeadSources.canonicalLeadSources}
            leadSource={{
              canonicalLeadSourceName:
                leadSource.companyLeadSources[0].canonicalLeadSource
                  .canonicalLeadSourceName,
              canonicalLeadSourceNameOverride:
                leadSource.companyLeadSources[0]
                  .canonicalLeadSourceNameOverride,
              attributionLinkingStrategy:
                leadSource.companyLeadSources[0].canonicalLeadSource
                  .attributionLinkingStrategy,
              attributionLinkingStrategyOverride:
                leadSource.companyLeadSources[0]
                  .attributionLinkingStrategyOverride,
              attributionPrompt:
                leadSource.companyLeadSources[0].canonicalLeadSource
                  .attributionPrompt,
              attributionPromptOverride:
                leadSource.companyLeadSources[0].attributionPromptOverride,
              archivedAt: leadSource.companyLeadSources[0].archivedAt,
            }}
            onEditLeadSourceClicked={onEditLeadSourceClicked}
          />
        )}
      />
    </BzDrawer>
  )
}
