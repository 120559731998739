import {
  type FetchAllCompaniesDocument,
  type ResultOf,
} from '@breezy/backend/src/query'
import {
  EstimateEntityTypeName,
  InvoiceEntityTypeName,
  MaintenancePlanEntityTypeName,
  TimeZoneId,
  bzExpect,
} from '@breezy/shared'
import {
  faCircleInfo,
  faMoneyCheckDollarPen,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popover, Space, Table, Tooltip } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { DateView } from '../../elements/DateView/DateView'
import RenderIf from '../../elements/RenderIf/RenderIf'
import { trpc } from '../../hooks/trpc'
import { useMessage, useModal } from '../../utils/antd-utils'
import {
  SyncPaymentsOrPayoutsDrawer,
  SyncPaymentsOrPayoutsProps,
} from '../Admin/AdminSyncs/SyncPaymentsOrPayoutsDrawer'
import { DateFormat } from '../Dates'
import { WisetackLogo } from '../Financing/WisetackLogo'
import { ResyncSearchRecordsDrawer } from '../ResyncSearchRecordsDrawer'
import { TilledLogo } from '../TilledLogo/TilledLogo'
import { UpdateFinancialConfigDrawer } from '../UpdateFinancialConfigDrawer/UpdateFinancialConfigDrawer'

type FetchAllCompaniesResult = ResultOf<
  typeof FetchAllCompaniesDocument
>['companies']
type FetchAllCompaniesResultItem = ResultOf<
  typeof FetchAllCompaniesDocument
>['companies'][0]

type CompanyUserItem = FetchAllCompaniesResultItem['companyUsers'][0]

type CompaniesGridProps = {
  companies: FetchAllCompaniesResult
  onCompanyAction: () => void
}

type ResyncEntityType =
  | typeof MaintenancePlanEntityTypeName
  | typeof EstimateEntityTypeName
  | typeof InvoiceEntityTypeName

export const CompaniesGrid: React.FC<CompaniesGridProps> = React.memo(
  ({ companies, onCompanyAction }) => {
    const message = useMessage()
    const [resyncPaymentOrPayoutsCtx, setResyncPaymentOrPaymentsCtx] =
      useState<SyncPaymentsOrPayoutsProps>()

    const companiesToDisplay = useMemo(() => {
      return companies
    }, [companies])
    const resyncMutation = trpc.resync['resync:entity-type'].useMutation()

    const resyncEntities = useCallback(
      (companyGuid: string, entityType: ResyncEntityType) => {
        resyncMutation.mutate(
          {
            companyGuid,
            entityType,
          },
          { onSuccess: onCompanyAction },
        )
      },
      [resyncMutation, onCompanyAction],
    )

    const Modal = useModal()

    const addressGeocodeMigratorMutation =
      trpc.devTools['devtools:migrate:address-geocode'].useMutation()

    const migrateAddressesWithGeocodes = useCallback(
      (companyGuid: string) => {
        addressGeocodeMigratorMutation.mutateAsync(
          { companyGuid },
          {
            onSuccess: res => {
              const hasNonMigrated = res.addressesNotMigrated.length > 0
              const payload = {
                title: 'Addresses Geocoded',
                width: 500,
                content: (
                  <div>
                    <div>
                      <strong>Company Guid:</strong> {companyGuid}
                    </div>
                    <div>
                      <strong>Addressses Migrated:</strong>{' '}
                      {res.addressesMigrated}
                    </div>
                    <div
                      className={classNames({
                        'text-red-500': hasNonMigrated,
                      })}
                    >
                      <div>
                        <strong>Addresses Still To Geocode:</strong>
                        {hasNonMigrated ? '' : ' 0'}
                      </div>
                      {hasNonMigrated && (
                        <ol>
                          {res.addressesNotMigrated.map(
                            ({ addressGuid, reason }) => (
                              <li key={addressGuid}>
                                {addressGuid}
                                <ul>
                                  <li>{reason}</li>
                                </ul>
                              </li>
                            ),
                          )}
                        </ol>
                      )}
                    </div>
                  </div>
                ),
              }
              if (hasNonMigrated) {
                Modal.error(payload)
              } else {
                Modal.success(payload)
              }
            },
          },
        )
      },
      [Modal, addressGeocodeMigratorMutation],
    )

    const generateRecommendationsMutation =
      trpc.devTools['devtools:generate-recommendations:company'].useMutation()

    const generateRecommendations = useCallback(
      (companyGuid: string, companyTimeZoneId: TimeZoneId) => {
        generateRecommendationsMutation.mutate(
          { companyGuid, timezone: companyTimeZoneId },
          {
            onSuccess: () => {
              message.success('Recommendations generated')
              onCompanyAction()
            },
          },
        )
      },
      [generateRecommendationsMutation, message, onCompanyAction],
    )

    const migrateCreditsMutation =
      trpc.devTools['devtools:maintenance-plans:migration-all'].useMutation()

    const migrateCreditsToVisits = useCallback(
      (companyGuid: string) => {
        migrateCreditsMutation.mutateAsync(
          { companyGuid },
          {
            onSuccess: res => {
              const hasNonMigrated = res.plansNotMigrated.length > 0
              const payload = {
                title: 'Credits Migrated to Visits',
                width: 500,
                content: (
                  <div>
                    <div>
                      <strong>Company Guid:</strong> {companyGuid}
                    </div>
                    <div>
                      <strong>Maintenance Plans Migrated:</strong>{' '}
                      {res.migratedPlansCount}
                    </div>
                    <div>
                      <strong>Total Credits Migrated:</strong>{' '}
                      {res.migratedCreditsCount}
                    </div>
                    <div
                      className={classNames({
                        'text-red-500': hasNonMigrated,
                      })}
                    >
                      <div>
                        <strong>Plans Not Migrated:</strong>
                        {hasNonMigrated ? '' : ' 0'}
                      </div>
                      {hasNonMigrated && (
                        <ol>
                          {res.plansNotMigrated.map(
                            ({ maintenancePlanGuid, reason }) => (
                              <li key={maintenancePlanGuid}>
                                {maintenancePlanGuid}
                                <ul>
                                  <li>{reason}</li>
                                </ul>
                              </li>
                            ),
                          )}
                        </ol>
                      )}
                    </div>
                  </div>
                ),
              }
              if (hasNonMigrated) {
                Modal.error(payload)
              } else {
                Modal.success(payload)
              }
              onCompanyAction()
            },
            onError: error => {
              console.error(error)
              message.error(`Failed to migrate credits: ${error.message}`)
            },
          },
        )
      },
      [migrateCreditsMutation, Modal, message, onCompanyAction],
    )

    const migrateToStaticPricingMutation =
      trpc.devTools[
        'devtools:maintenance-plans:migrate-to-static-pricing'
      ].useMutation()

    const migrateToStaticPricing = useCallback(
      (companyGuid: string) => {
        migrateToStaticPricingMutation.mutateAsync(
          { companyGuid },
          {
            onSuccess: res => {
              const hasNonMigrated = res.plansNotMigrated.length > 0
              const payload = {
                title: 'Maintenance Plans Migrated to Static Pricing',
                width: 500,
                content: (
                  <div>
                    <div>
                      <strong>Company Guid:</strong> {companyGuid}
                    </div>
                    <div>
                      <strong>Maintenance Plans Migrated:</strong>{' '}
                      {res.migratedPlansCount}
                    </div>
                    <div
                      className={classNames({
                        'text-red-500': hasNonMigrated,
                      })}
                    >
                      <div>
                        <strong>Plans Not Migrated:</strong>
                        {hasNonMigrated ? '' : ' 0'}
                      </div>
                      {hasNonMigrated && (
                        <ol>
                          {res.plansNotMigrated.map(
                            ({ maintenancePlanGuid, reason }) => (
                              <li key={maintenancePlanGuid}>
                                {maintenancePlanGuid}
                                <ul>
                                  <li>{reason}</li>
                                </ul>
                              </li>
                            ),
                          )}
                        </ol>
                      )}
                    </div>
                  </div>
                ),
              }
              if (hasNonMigrated) {
                Modal.error(payload)
              } else {
                Modal.success(payload)
              }
              onCompanyAction()
            },
            onError: error => {
              console.error(error)
              message.error(
                `Failed to migrate to static pricing: ${error.message}`,
              )
            },
          },
        )
      },
      [migrateToStaticPricingMutation, Modal, message, onCompanyAction],
    )

    const migrateDupeAffinityDatesMutation =
      trpc.devTools[
        'devtools:maintenance-plans:migrate-dupe-affinity-dates'
      ].useMutation()

    const migrateDupeAffinityDates = useCallback(
      (companyGuid: string) => {
        migrateDupeAffinityDatesMutation.mutateAsync(
          { companyGuid },
          {
            onSuccess: res => {
              const hasNonMigrated = res.plansNotMigrated.length > 0
              const payload = {
                title: 'Maintenance Plans Migrated Dupe Affinity Dates',
                width: 500,
                content: (
                  <div>
                    <div>
                      <strong>Company Guid:</strong> {companyGuid}
                    </div>
                    <div>
                      <strong>Maintenance Plans Migrated:</strong>{' '}
                      {res.migratedPlansCount}
                    </div>
                    <div
                      className={classNames({
                        'text-red-500': hasNonMigrated,
                      })}
                    >
                      <div>
                        <strong>Plans Not Migrated:</strong>
                        {hasNonMigrated ? '' : ' 0'}
                      </div>
                      {hasNonMigrated && (
                        <ol>
                          {res.plansNotMigrated.map(
                            ({ maintenancePlanGuid, reason }) => (
                              <li key={maintenancePlanGuid}>
                                {maintenancePlanGuid}
                                <ul>
                                  <li>{reason}</li>
                                </ul>
                              </li>
                            ),
                          )}
                        </ol>
                      )}
                    </div>
                  </div>
                ),
              }
              if (hasNonMigrated) {
                Modal.error(payload)
              } else {
                Modal.success(payload)
              }
              onCompanyAction()
            },
            onError: error => {
              console.error(error)
              message.error(
                `Failed to migrate dupe affinity dates: ${error.message}`,
              )
            },
          },
        )
      },
      [migrateDupeAffinityDatesMutation, Modal, message, onCompanyAction],
    )

    const writeDefaultPricebookPhotosMutation =
      trpc.devTools['devtools:write-default-pricebook-photos'].useMutation()

    const writeDefaultPricebookPhotos = useCallback(
      (companyGuid: string) => {
        writeDefaultPricebookPhotosMutation.mutateAsync(
          { companyGuid },
          {
            onSuccess: res => {
              if (res.success) {
                message.success('Default pricebook photos written successfully')
              } else {
                Modal.error({
                  title: 'Error Writing Default Pricebook Photos',
                  content: res.error || 'Unknown error occurred',
                })
              }
              onCompanyAction()
            },
            onError: error => {
              console.error(error)
              message.error(
                `Failed to write default pricebook photos: ${error.message}`,
              )
            },
          },
        )
      },
      [writeDefaultPricebookPhotosMutation, Modal, message, onCompanyAction],
    )

    const seedPricebookItemDefaultPhotosMutation =
      trpc.devTools['devtools:seed-pricebook-item-default-photos'].useMutation()

    const seedPricebookItemDefaultPhotos = useCallback(
      (companyGuid: string) => {
        seedPricebookItemDefaultPhotosMutation.mutateAsync(
          { companyGuid },
          {
            onSuccess: res => {
              const payload = {
                title: 'Pricebook Items Seeded with Default Photos',
                width: 500,
                content: (
                  <div>
                    <div>
                      <strong>Company Guid:</strong> {companyGuid}
                    </div>
                    <div>
                      <strong>Pricebook Items Updated:</strong>{' '}
                      {res.updatedItemsCount}
                    </div>
                  </div>
                ),
              }

              Modal.success(payload)
              onCompanyAction()
            },
            onError: error => {
              console.error(error)
              message.error(
                `Failed to seed pricebook item default photos: ${error.message}`,
              )
            },
          },
        )
      },
      [seedPricebookItemDefaultPhotosMutation, Modal, message, onCompanyAction],
    )

    const [companyGuid, setCompanyGuid] = useState<string | undefined>()
    const [searchRecordsDrawerOpen, setSearchRecordsDrawerOpen] =
      useState(false)
    const [
      updateFinancialConfigDrawerOpen,
      setUpdateFinancialConfigDrawerOpen,
    ] = useState(false)

    const [openActionMenuId, setOpenActionMenuId] = useState('')

    const handleOpenChange = (newOpen: boolean, companyGuid: string) => {
      if (newOpen) {
        setOpenActionMenuId(companyGuid)
      } else {
        setOpenActionMenuId('')
      }
    }

    const columns = [
      {
        title: 'Company ID',
        dataIndex: 'companyGuid',
        key: 'companyGuid',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Live Since',
        dataIndex: 'breezySystemOfRecordDate',
        render: (_: unknown, record: FetchAllCompaniesResultItem) => {
          return (
            <DateView
              isoWithOffsetTimestamp={record.breezySystemOfRecordDate}
              format={DateFormat['MMM d, yyyy']}
              zone={{ type: 'explicit', timezone: record.timezone }}
            />
          )
        },
      },
      {
        title: 'Financial Config',
        dataIndex: 'financialConfig',
        key: 'financialConfig',
        render: (_: unknown, record: FetchAllCompaniesResultItem) => (
          <div className="flex flex-row flex-nowrap gap-2">
            <RenderIf if={!!record.billingProfile?.wisetackMerchantId}>
              <WisetackLogo size="sm" />
            </RenderIf>
            <RenderIf if={!!record.billingProfile?.merchantId}>
              <TilledLogo size="sm" />
            </RenderIf>
            <RenderIf
              if={
                !record.billingProfile?.merchantId &&
                !record.billingProfile?.wisetackMerchantId
              }
            >
              <Tooltip
                title={`No financial integration configured. Wisetack Loan Promo will be visible but will be unable to create loan and prequal applications. To update the company finance config to enable financial integrations, click the "Update Financial Configuration" action.`}
              >
                <FontAwesomeIcon icon={faCircleInfo} />
              </Tooltip>
            </RenderIf>
          </div>
        ),
      },
      {
        title: 'Job Count',
        dataIndex: 'jobsAggregate',
        key: 'jobsAggregate',
        render: (
          jobsAggregate: FetchAllCompaniesResultItem['jobsAggregate'],
        ) => {
          return jobsAggregate?.aggregate?.jobCount || 0
        },
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (_: unknown, record: FetchAllCompaniesResultItem) => (
          <Space>
            <Popover
              trigger="click"
              content={
                <div className="p0 flex flex-col flex-nowrap content-start">
                  <Button
                    type="link"
                    htmlType="button"
                    loading={resyncMutation.isLoading}
                    onClick={() =>
                      resyncEntities(
                        record.companyGuid,
                        MaintenancePlanEntityTypeName,
                      )
                    }
                  >
                    🔄 Resync Maintenance Plans
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    loading={resyncMutation.isLoading}
                    onClick={() =>
                      resyncEntities(record.companyGuid, InvoiceEntityTypeName)
                    }
                  >
                    🔄 Resync Invoices
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    loading={resyncMutation.isLoading}
                    onClick={() =>
                      resyncEntities(record.companyGuid, EstimateEntityTypeName)
                    }
                  >
                    🔄 Resync Estimates
                  </Button>
                  {record.billingProfile?.merchantId && (
                    <Button
                      type="link"
                      htmlType="button"
                      onClick={() =>
                        setResyncPaymentOrPaymentsCtx({
                          type: 'payment',
                          companyGuid: record.companyGuid,
                          merchantId: bzExpect(
                            record.billingProfile?.merchantId,
                            'merchantId',
                          ),
                          onCancel: () =>
                            setResyncPaymentOrPaymentsCtx(undefined),
                        })
                      }
                    >
                      🔄 Resync Payments
                    </Button>
                  )}
                  {record.billingProfile?.merchantId && (
                    <Button
                      type="link"
                      htmlType="button"
                      onClick={() =>
                        setResyncPaymentOrPaymentsCtx({
                          type: 'payouts',
                          companyGuid: record.companyGuid,
                          merchantId: bzExpect(
                            record.billingProfile?.merchantId,
                            'merchantId',
                          ),
                          onCancel: () =>
                            setResyncPaymentOrPaymentsCtx(undefined),
                        })
                      }
                    >
                      🔄 Resync Payouts
                    </Button>
                  )}
                  <Button
                    type="link"
                    htmlType="button"
                    onClick={() => {
                      setSearchRecordsDrawerOpen(true)
                      setCompanyGuid(record.companyGuid)
                    }}
                  >
                    🔄 Resync Search Records
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    loading={addressGeocodeMigratorMutation.isLoading}
                    onClick={() =>
                      migrateAddressesWithGeocodes(record.companyGuid)
                    }
                  >
                    🔄 Geocode Addresses
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    loading={generateRecommendationsMutation.isLoading}
                    onClick={() =>
                      generateRecommendations(
                        record.companyGuid,
                        record.timezone,
                      )
                    }
                  >
                    🔄 Regenerate Today's Recommendations
                  </Button>

                  <Button
                    type="link"
                    htmlType="button"
                    icon={<FontAwesomeIcon icon={faMoneyCheckDollarPen} />}
                    onClick={() => {
                      setUpdateFinancialConfigDrawerOpen(true)
                      setCompanyGuid(record.companyGuid)
                    }}
                  >
                    Update Financial Configuration
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    loading={migrateCreditsMutation.isLoading}
                    onClick={() => migrateCreditsToVisits(record.companyGuid)}
                  >
                    🔄 Migrate Credits to Visits
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    loading={migrateToStaticPricingMutation.isLoading}
                    onClick={() => migrateToStaticPricing(record.companyGuid)}
                  >
                    🔄 Migrate to Static Pricing
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    loading={migrateDupeAffinityDatesMutation.isLoading}
                    onClick={() => migrateDupeAffinityDates(record.companyGuid)}
                  >
                    🔄 Migrate Dupe Affinity Dates
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    loading={writeDefaultPricebookPhotosMutation.isLoading}
                    onClick={() =>
                      writeDefaultPricebookPhotos(record.companyGuid)
                    }
                  >
                    🖼️ Write Default Pricebook Photos
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    loading={seedPricebookItemDefaultPhotosMutation.isLoading}
                    onClick={() =>
                      seedPricebookItemDefaultPhotos(record.companyGuid)
                    }
                  >
                    🌱 Seed Pricebook Item Default Photos
                  </Button>
                </div>
              }
              placement="topRight"
              open={openActionMenuId === record.companyGuid}
              onOpenChange={(newOpen: boolean) =>
                handleOpenChange(newOpen, record.companyGuid)
              }
            />

            <Button
              type="primary"
              onClick={() => setOpenActionMenuId(record.companyGuid)}
            >
              Actions
            </Button>
          </Space>
        ),
      },
    ]

    const innerTableColumns = [
      {
        title: 'Email',
        key: 'emailAddress',
        render: (companyUser: CompanyUserItem) => {
          return companyUser?.user?.emailAddress
        },
      },
      {
        title: 'First Name',
        key: 'firstName',
        render: (companyUser: CompanyUserItem) => {
          return companyUser?.user?.firstName
        },
      },
      {
        title: 'Last Name',
        key: 'lastName',
        render: (companyUser: CompanyUserItem) => {
          return companyUser?.user?.lastName
        },
      },
      {
        title: 'Roles',
        key: 'roles',
        render: (companyUser: CompanyUserItem) => {
          return companyUser?.user?.roles.map(r => r.name).join(', ') || ''
        },
      },
      {
        title: 'Permissions',
        key: 'permissions',
        render: (companyUser: CompanyUserItem) => {
          return (
            companyUser?.user?.permissions.map(p => p.name).join(', ') || ''
          )
        },
      },
    ]

    return (
      <>
        <Table
          rowKey="companyGuid"
          expandable={{
            expandedRowRender: item => {
              return (
                <Table
                  rowKey={item => item.user.userGuid}
                  pagination={false}
                  dataSource={item.companyUsers}
                  columns={innerTableColumns}
                />
              )
            },
            rowExpandable: () => true,
          }}
          dataSource={companiesToDisplay}
          columns={columns}
        />

        <SyncPaymentsOrPayoutsDrawer item={resyncPaymentOrPayoutsCtx} />

        <ResyncSearchRecordsDrawer
          companyGuid={companyGuid}
          open={searchRecordsDrawerOpen}
          onClose={() => {
            setSearchRecordsDrawerOpen(false)
          }}
        />

        <UpdateFinancialConfigDrawer
          open={updateFinancialConfigDrawerOpen}
          companyGuid={companyGuid ?? ''}
          onFinancialConfigUpdated={() => {
            setUpdateFinancialConfigDrawerOpen(false)
            setCompanyGuid(undefined)
          }}
          onCancel={() => {
            setUpdateFinancialConfigDrawerOpen(false)
            setCompanyGuid(undefined)
          }}
        />
      </>
    )
  },
)
