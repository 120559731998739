import { gql } from 'src/generated'

export const JOB_TYPE_WITH_JOB_LIFECYCLE_QUERY = gql(/* GraphQL */ `
  query ChangeJobPipelineCardSelectJobTypeWithJobLifecycleQuery(
    $jobTypeGuid: uuid!
  ) {
    jobTypesByPk(jobTypeGuid: $jobTypeGuid) {
      jobLifecycle {
        name
        jobLifecycleStatuses {
          jobLifecycleStatusGuid
          name
        }
        jobLifecycleGuid
        sequence
      }
    }
  }
`)
