import {
  type OfficeRouteInfo,
  type Route,
  type TechnicianRouteInfo,
} from '@breezy/shared'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const getLongestRouteMatch = (
  pathname: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routes: Record<string, any>,
): Route<string> | undefined => {
  const locationPathArray = pathname.split('/').slice(1)
  let matchingRouteLabel: Route<string> | undefined = undefined
  for (const [, route] of Object.entries(routes)) {
    const routePathArray = route.path.split('/').slice(1)

    let matchesRoute = routePathArray.length === locationPathArray.length

    if (matchesRoute) {
      for (let i = 0; i < routePathArray.length; i++) {
        const matchesWildcard =
          (locationPathArray[i] && routePathArray[i].startsWith(':')) ||
          routePathArray[i] === '*'

        if (routePathArray[i] !== locationPathArray[i] && !matchesWildcard) {
          matchesRoute = false
          continue
        }
      }
    }

    if (matchesRoute) {
      matchingRouteLabel = route
    }
  }
  return matchingRouteLabel
}

export const useRouteTitleAsDocumentTitle = (
  routes: OfficeRouteInfo | TechnicianRouteInfo,
) => {
  const location = useLocation()

  useEffect(() => {
    const route = getLongestRouteMatch(location.pathname, routes)
    document.title = route ? route?.label + ' - Breezy' : 'Breezy'
  }, [routes, location])
}
