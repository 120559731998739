import { Named, TaxRateSetEventData } from '@breezy/shared'
import { useGetPricebookTaxRatesAdmin } from '../../PricebookAdmin/hooks/useGetPricebookTaxRates'
import PricebookSelectionDrawer, {
  PricebookSelectionDrawerInputProps,
} from '../PricebookItemSelectionDrawer/PricebookSelectionDrawer'

const PricebookTaxRateSelectionDrawer = (
  input: PricebookSelectionDrawerInputProps<Named<TaxRateSetEventData>>,
) => {
  const { getPricebookTaxRatesAdminQuery: query } =
    useGetPricebookTaxRatesAdmin()

  return (
    <PricebookSelectionDrawer
      {...input}
      query={query}
      title="Set Tax Rate"
      itemTypeName="Tax Rate"
      getItems={data => data.pricebookTaxRates}
      getRenderableItem={(item: {
        name: string
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rate: any
        version: number
        pricebookTaxRateGuid: string
        companyGuid: string
        isActive: boolean
      }) => ({
        selectionItem: {
          rate: item.rate,
          taxRateGuid: item.pricebookTaxRateGuid,
          name: item.name,
        },
        searchableTerm: item.name,
        displayName: item.name,
        displayAmount: `${item.rate * 100}%`,
      })}
    />
  )
}

export default PricebookTaxRateSelectionDrawer
