import { JobLifecycleStatus, isNullish } from '@breezy/shared'
import { Button } from 'antd'
import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import { KanbanJob } from '../../../components/Kanban/kanbanUtils'

export type CreateLinkedJobConfirmationModalProps = {
  open: boolean
  config: {
    job: KanbanJob
    newStatus: JobLifecycleStatus
  } | null
  onProceedWithCreateLinkedJobWorkflow: (
    job: KanbanJob,
    newStatus: JobLifecycleStatus,
  ) => void
  onProceedWithoutCreateLinkedJobWorkflow: (
    job: KanbanJob,
    newStatus: JobLifecycleStatus,
  ) => void
  onCancel: () => void
}

export const CreateLinkedJobConfirmationModal = ({
  open,
  config,
  onProceedWithCreateLinkedJobWorkflow,
  onProceedWithoutCreateLinkedJobWorkflow,
  onCancel,
}: CreateLinkedJobConfirmationModalProps) => {
  if (isNullish(config)) {
    return (
      <OnsiteBasicModal
        open={open}
        onClose={onCancel}
        header="Create Linked Job"
        footer={
          <div className="flex w-full flex-col">
            <Button className="grow" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        }
      >
        <span>Error: No job and/or lifecycle supplied. Please try again</span>
      </OnsiteBasicModal>
    )
  }

  return (
    <OnsiteBasicModal
      open={open}
      onClose={onCancel}
      header="Create Linked Job"
      footer={
        <div className="flex w-full flex-row items-center gap-3">
          <Button
            className="grow"
            onClick={() =>
              onProceedWithoutCreateLinkedJobWorkflow(
                config.job,
                config.newStatus,
              )
            }
          >
            Don't Create Linked Job
          </Button>

          <Button
            className="grow"
            type="primary"
            onClick={() =>
              onProceedWithCreateLinkedJobWorkflow(config.job, config.newStatus)
            }
          >
            Create Linked Job
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-3">
        <span>
          This job has moved to the “{config.newStatus.name}” status, which
          triggers a workflow for creating a linked job.
        </span>

        <span>
          Would you like to create a new job that links to “#
          {config.job.job.displayId} {config.job.job.jobType.name}”?
        </span>
      </div>
    </OnsiteBasicModal>
  )
}
