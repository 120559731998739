import { Guid } from '@breezy/shared'
import { Select } from 'antd'
import { useFetchPersonResources } from '../../hooks/fetch/useFetchUserDetails'
import { m } from '../../utils/react-utils'
import { LoadingSpinner } from '../LoadingSpinner'
import PersonResourceView from './PersonResourceView'

type UserPersonResourceSelectProps = {
  userGuid: Guid
  setUserGuid: (userGuid: Guid) => void
}

const containerClassName = 'min-w-[240px] min-h-[46px]'
export const UserPersonResourceSelect = m<UserPersonResourceSelectProps>(
  ({ userGuid, setUserGuid }) => {
    const users = useFetchPersonResources()

    if (!users)
      return (
        <div className={containerClassName}>
          <LoadingSpinner />
        </div>
      )

    if (users.length === 0)
      return <div className={containerClassName}>No users found</div>

    return (
      <Select
        value={userGuid}
        onChange={setUserGuid}
        className={containerClassName}
      >
        {users.map(u => (
          <Select.Option key={u.userGuid} value={u.userGuid}>
            <PersonResourceView {...u} />
          </Select.Option>
        ))}
      </Select>
    )
  },
)
