import { LocalDateString, TimeZoneId, usCentsToUsd } from '../../../common'
import { BzDateTime } from '../../DateTime/BzDateTime'
import { ESTIMATED_PAYOUT_DEPOSIT_TRANSIT_DAYS, PayoutEnriched } from './PayoutTypes'

export type PayoutSummaryCsvRow = {
  originationDate: LocalDateString
  estimatedDepositDate: LocalDateString
  netAmountUsd: number
  grossAmountUsd: number
  chargesAmountUsd: number
  feesAmountUsd: number
  refundsUsd: number
  balanceAdjustmentUsd: number
}

export type PayoutItemCsvRow = PayoutSummaryCsvRow & {
  transactionDate: LocalDateString
  transactionType: 'Charge' | 'Refund' | 'Fee'
  transactionAmountUsd: number
  transactionAccountName?: string
  qboTransactionLink?: string
}

export const convertPayoutDetailsToCsvRow = (tzId: TimeZoneId, payout: PayoutEnriched): PayoutItemCsvRow[] =>
  payout.items.map(i => ({
    originationDate: BzDateTime.fromIsoString(payout.paidAt, tzId).toLocalDateString(),
    estimatedDepositDate: BzDateTime.fromIsoString(payout.paidAt, tzId)
      .plusDays(ESTIMATED_PAYOUT_DEPOSIT_TRANSIT_DAYS)
      .toLocalDateString(),
    netAmountUsd: usCentsToUsd(payout.netAmountUsc),
    grossAmountUsd: usCentsToUsd(payout.grossAmountUsc),
    chargesAmountUsd: usCentsToUsd(payout.chargesAmountUsc),
    feesAmountUsd: usCentsToUsd(payout.feesAmountUsc),
    refundsUsd: usCentsToUsd(payout.refundsAmountUsc),
    balanceAdjustmentUsd: usCentsToUsd(payout.balanceAdjustmentUsc),
    transactionDate: BzDateTime.fromIsoString(i.occurredAt, tzId).toLocalDateString(),
    transactionType: i.itemType === 'CHARGE' ? 'Charge' : i.itemType === 'REFUND' ? 'Refund' : 'Fee',
    transactionAmountUsd: usCentsToUsd(i.itemAmountUsc),
    transactionAccountName: i.accountDisplayName,
    qboTransactionLink: i.qboLink,
  }))

export const convertPayoutsDetailsToCsvRows = (tzId: TimeZoneId, payouts: PayoutEnriched[]): PayoutItemCsvRow[] =>
  payouts.map(p => convertPayoutDetailsToCsvRow(tzId, p)).reduce((acc, cur) => acc.concat(cur), [])
