import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { blue6 } from '../../themes/theme'
import { StateSetter, typedMemo } from '../../utils/react-utils'
import { ChartTooltip } from './ChartTooltip'

const DEFAULT_COLOR = blue6

type BarData = {
  label: string
  value: number
  color?: string
}

type FormatValue<T extends BarData> = (
  value: number,
  data: T,
) => React.ReactNode

type TooltipInfo<T extends BarData> = {
  x: number
  y: number
  data: T
  caretOnRight: boolean
  visible: boolean
}

type BarSectionProps<T extends BarData> = {
  data: T
  formatValue?: FormatValue<T>
  setTooltipInfo: StateSetter<TooltipInfo<T>>
  dataChanging: boolean
  maxVal: number
}

const BarSection = typedMemo(
  <T extends BarData>({
    data,
    setTooltipInfo,
    dataChanging,
    maxVal,
    formatValue,
  }: BarSectionProps<T>) => {
    const { label, value, color } = data
    const barRef = useRef<HTMLDivElement | null>(null)

    return (
      <div>
        <div className="text-xs font-semibold text-bz-gray-900">{label}</div>
        <div className="flex flex-row items-center">
          <div
            className="flex-1"
            onMouseEnter={e => {
              const containerRect = e.currentTarget?.getBoundingClientRect()
              if (!barRef.current) {
                return
              }
              const barRect = barRef.current?.getBoundingClientRect()

              // Position starts at the tip of the bar
              const y = barRect.y + barRect.height / 2
              // Nudge it a little to the left so it isn't directly on the tip
              const x = barRect.x + barRect.width - 5
              let caretOnRight = true

              // If the distance between the right side of the bar and the right side of the container is greater
              // than half the width of the container, then we're in the left half of the container. In that case,
              // show the tooltip pointing to the right so we're less likely to overflow.
              if (
                containerRect &&
                containerRect.right - barRect.right > containerRect.width / 2
              ) {
                caretOnRight = false
              }

              // Put the tooltip right on the end of the bar, aligned middle, but push it a few pixels to the left
              // so it's not right up against the end.
              setTooltipInfo({
                data,
                x,
                y,
                caretOnRight,
                visible: true,
              })
            }}
            onMouseLeave={() =>
              setTooltipInfo(info => ({ ...info, visible: false }))
            }
          >
            <div
              ref={barRef}
              className="py-4 transition-all duration-500 ease-in-out"
              style={{
                width: dataChanging ? '0%' : `${(value * 100) / maxVal}%`,
              }}
            >
              <div
                className="h-[6px] rounded"
                style={{
                  backgroundColor: color ?? DEFAULT_COLOR,
                }}
              />
            </div>
          </div>
          <div className="w-36 text-right text-sm text-bz-gray-900">
            {formatValue ? formatValue(value, data) : value}
          </div>
        </div>
      </div>
    )
  },
)

type BarChartProps<T extends BarData> = {
  data: T[]
  disableTooltip?: boolean
  formatValue?: FormatValue<T>
  formatValueTooltip?: FormatValue<T>
}

export const BarChart = typedMemo(
  <T extends BarData>({
    data,
    formatValue,
    formatValueTooltip,
    disableTooltip,
  }: BarChartProps<T>) => {
    const maxVal = useMemo(() => {
      let max = 0
      for (const { value } of data) {
        if (value > max) {
          max = value
        }
      }
      return max
    }, [data])

    const [tooltipInfo, setTooltipInfo] = useState<TooltipInfo<T>>({
      x: 0,
      y: 0,
      data: data[0],
      caretOnRight: false,
      visible: false,
    })

    const chartContainerRef = useRef<HTMLDivElement | null>(null)

    // The tooltip is fixed so if it starts at 0,0, when it appears for the first time it will fly from the upper-left of
    // the screen. This sets the initial value to the upper-left of the chart so it's less weird
    useLayoutEffect(() => {
      if (chartContainerRef.current) {
        const rect = chartContainerRef.current.getBoundingClientRect()
        setTooltipInfo(info => ({
          ...info,
          x: rect.x,
          y: rect.y,
        }))
      }
    }, [])

    const [dataChanging, setDataChanging] = useState(true)

    useEffect(() => {
      setDataChanging(true)
      setTimeout(() => setDataChanging(false), 0)
    }, [data])

    return (
      <div ref={chartContainerRef} className="relative">
        {data.map((datum, i) => (
          <BarSection
            key={datum.label}
            data={datum}
            dataChanging={dataChanging}
            maxVal={maxVal}
            setTooltipInfo={setTooltipInfo}
            formatValue={formatValue}
          />
        ))}
        {!disableTooltip && (
          <ChartTooltip
            visible={tooltipInfo.visible}
            caretOnRight={tooltipInfo.caretOnRight}
            left={tooltipInfo.x}
            top={tooltipInfo.y}
            data={[
              {
                color: DEFAULT_COLOR,
                ...tooltipInfo.data,
                value: formatValueTooltip
                  ? formatValueTooltip(tooltipInfo.data.value, tooltipInfo.data)
                  : formatValue
                  ? formatValue(tooltipInfo.data.value, tooltipInfo.data)
                  : tooltipInfo.data.value,
              },
            ]}
          />
        )}
      </div>
    )
  },
)
