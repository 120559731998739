import {
  EstimateStatus,
  InvoiceStatuses,
  formatUscWholeDollars,
  invoiceV2ToInvoiceV1Status,
  usCentsToUsd,
  usdRoundUpToNextDollar,
} from '@breezy/shared'
import { useMemo } from 'react'
import {
  SummaryStatBox,
  SummaryStatsContainer,
} from '../../../adam-components/ListPage/SummaryStats'
import { KanbanJob } from '../../../components/Kanban/kanbanUtils'

const VALID_INVOICE_STATUSES: InvoiceStatuses[] = [
  InvoiceStatuses.CREATED,
  InvoiceStatuses.PRESENTED,
  InvoiceStatuses.FULLY_PAID,
] as const

const VALID_ESTIMATE_STATUSES: EstimateStatus[] = [
  EstimateStatus.PRESENTED,
  EstimateStatus.ACCEPTED,
] as const

type JobsScoreCardsProps = {
  jobs: KanbanJob[]
}

export const JobsScoreCards = ({ jobs }: JobsScoreCardsProps) => {
  const numTotalJobs: number = useMemo(() => jobs.length, [jobs.length])

  const numUncheduledJobs: number = useMemo(() => {
    return jobs.filter(({ job }) => job.appointments.length > 0).length
  }, [jobs])

  const avgInvoiceValue: number = useMemo(() => {
    const invoices = jobs
      .flatMap(({ job }) => job.jobInvoices)
      .filter(ji =>
        VALID_INVOICE_STATUSES.includes(
          invoiceV2ToInvoiceV1Status(ji.invoice.status),
        ),
      )

    if (invoices.length === 0) {
      return 0
    }

    const totalInvoicedUsc = invoices.reduce(
      (acc, invoice) => acc + invoice.invoice.totalUsc,
      0,
    )
    return usCentsToUsd(totalInvoicedUsc) / invoices.length
  }, [jobs])

  const avgEstimateValue: number = useMemo(() => {
    const acceptedEstimates = jobs
      .flatMap(({ job }) => job.estimates)
      .filter(estimate =>
        VALID_ESTIMATE_STATUSES.includes(estimate.status as EstimateStatus),
      )

    const selectedEstimateOptions = acceptedEstimates.flatMap(
      estimate => estimate.estimateOptions,
    )

    if (selectedEstimateOptions.length === 0) {
      return 0
    }

    return (
      selectedEstimateOptions.reduce(
        (acc, estimateOption) => acc + estimateOption.totalUsc,
        0,
      ) / selectedEstimateOptions.length
    )
  }, [jobs])

  return (
    <SummaryStatsContainer>
      <SummaryStatBox label="Number of Jobs" bottomBarColorClasses={[]}>
        {numTotalJobs}
      </SummaryStatBox>

      <SummaryStatBox label="Unscheduled Jobs" bottomBarColorClasses={[]}>
        {numUncheduledJobs}
      </SummaryStatBox>

      <SummaryStatBox label="Avg. Invoice Value" bottomBarColorClasses={[]}>
        ${usdRoundUpToNextDollar(avgInvoiceValue)}
      </SummaryStatBox>

      <SummaryStatBox label="Avg. Estimate Value" bottomBarColorClasses={[]}>
        {formatUscWholeDollars(avgEstimateValue)}
      </SummaryStatBox>
    </SummaryStatsContainer>
  )
}
