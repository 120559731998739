import { AssignmentWithTechnicianNameDTO, Guid } from '@breezy/shared'
import {
  faClose,
  faLocationCrosshairs,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useQuery } from 'urql'
import BzAdvancedMarker from '../../../components/MapView/BzAdvancedMarker/BzAdvancedMarker'
import { AppointmentCard } from '../../../components/TechnicianApp/MyAppointmentsPage/AppointmentsList/AppointmentCard'
import RenderIf from '../../../elements/RenderIf/RenderIf'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import {
  EQUIPMENT_TYPE_LINKS_FOR_APPOINTMENT_QUERY,
  ScheduleAssignment,
} from '../Schedule.gql'
import {
  FullScheduleAppointment,
  getColorForJobClass,
  TechnicianResource,
} from '../scheduleUtils'
import { Circle } from './Circle'
import { useScheduleMap } from './ScheduleMapContext'

type TechMap = Record<string, TechnicianResource>

export const useAppointmentPinText = (
  assignments: ScheduleAssignment[],
  techMap: TechMap,
) => {
  const getTechInitials = useCallback(
    (technicianGuid: Guid) => {
      const tech = techMap[technicianGuid]
      if (!tech) {
        return '?'
      }
      return `${tech.firstName[0]}${tech.lastName[0]}`.toUpperCase()
    },
    [techMap],
  )
  return useMemo(() => {
    if (assignments.length === 0) {
      return '?'
    }

    if (assignments.length === 1) {
      return getTechInitials(assignments[0].technicianUserGuid)
    }

    if (assignments.length === 2) {
      return (
        <div className="flex flex-col leading-4">
          {assignments.map(assignment => (
            <div key={assignment.technicianUserGuid}>
              {getTechInitials(assignment.technicianUserGuid)}
            </div>
          ))}
        </div>
      )
    }

    return (
      <div className="flex flex-col">
        <div>{getTechInitials(assignments[0].technicianUserGuid)}</div>

        <div>+{assignments.length - 1}</div>
      </div>
    )
  }, [assignments, getTechInitials])
}

type AppointmentPinProps = {
  appointment: FullScheduleAppointment
  setRadius: (radius: number) => void
  techMap: TechMap
}

export const AppointmentPin = React.memo<AppointmentPinProps>(
  ({ appointment, setRadius, techMap }) => {
    const {
      activeAppointment,
      radiusMeters: radius,
      setActivePopup,
      activePopup,
    } = useScheduleMap()

    const { appointmentGuid, job } = appointment
    const { jobType, location } = job
    const { jobClass } = jobType

    const geo = location.address.geoLocation

    const isActive =
      activeAppointment?.job.location.locationGuid ===
      appointment.job.location.locationGuid

    const markerState = useMemo(() => {
      if (!activePopup && !activeAppointment) return 'active'

      if (
        activePopup?.type === 'appointment' &&
        activePopup.appointmentGuid === appointmentGuid
      ) {
        return 'active'
      }
      if (!activePopup && isActive) {
        return 'active'
      }

      return 'background'
    }, [activePopup, activeAppointment, appointmentGuid, isActive])

    const pinText = useAppointmentPinText(
      appointment.assignments ?? [],
      techMap,
    )

    const pinColor = useMemo(
      () => getColorForJobClass(jobClass).mapPinColor,
      [jobClass],
    )

    const onClick = useCallback(() => {
      setActivePopup({
        type: 'appointment',
        appointmentGuid,
      })
    }, [setActivePopup, appointmentGuid])

    return (
      <BzAdvancedMarker
        markerState={markerState}
        onClick={onClick}
        position={{
          lng: geo?.coordinates[0] || 0,
          lat: geo?.coordinates[1] || 0,
        }}
        title={'Appointment pin'}
        zIndex={markerState === 'active' ? 10 : 1}
        pinContent={pinText}
        pinColorConfig={{
          active: pinColor,
          default: pinColor,
        }}
        renderPopupIf={
          activePopup?.type === 'appointment' &&
          activePopup.appointmentGuid === appointmentGuid
        }
        ddPinActionName={`BZ Schedule Map - Appointment Pin`}
        popupContent={
          <AppointmentDetailsPopupContents
            appointment={appointment}
            isActive={isActive}
            techMap={techMap}
          />
        }
      >
        <RenderIf if={isActive}>
          <Circle
            radius={radius}
            center={{
              lat: geo?.coordinates[1] || 0,
              lng: geo?.coordinates[0] || 0,
            }}
            onRadiusChanged={setRadius}
            strokeColor={'#1677FF'}
            strokeOpacity={1}
            strokeWeight={2}
            fillColor={'#1677FF'}
            fillOpacity={0.1}
            editable
            onClick={() => {
              setActivePopup(undefined)
            }}
            zIndex={10}
          />
        </RenderIf>
      </BzAdvancedMarker>
    )
  },
)

type AppointmentDetailsPopupContentsProps = {
  appointment: FullScheduleAppointment
  isActive: boolean
  techMap: TechMap
}

const AppointmentDetailsPopupContents =
  React.memo<AppointmentDetailsPopupContentsProps>(
    ({ appointment, isActive, techMap }) => {
      const tzId = useExpectedCompanyTimeZoneId()

      const { setActiveAppointmentGuid, clearActiveAppointment } =
        useScheduleMap()

      const onClick = useCallback(() => {
        if (isActive) {
          clearActiveAppointment()
        } else {
          setActiveAppointmentGuid(appointment.appointmentGuid)
        }
      }, [
        isActive,
        clearActiveAppointment,
        setActiveAppointmentGuid,
        appointment.appointmentGuid,
      ])

      const assignments = useMemo<AssignmentWithTechnicianNameDTO[]>(
        () =>
          (appointment.assignments ?? []).map(
            ({
              assignmentGuid,
              assignmentStatus,
              technicianUserGuid,
              start,
              end,
            }) => {
              const tech = techMap[technicianUserGuid]
              return {
                assignmentGuid,
                assignmentStatus: assignmentStatus?.status ?? 'TO_DO',
                technicianUserGuid,
                timeWindow: {
                  start,
                  end,
                },
                technicianFirstName: tech?.firstName ?? 'Unknown First Name',
                technicianLastName: tech?.lastName ?? 'Unknown Last Name',
              }
            },
          ),
        [appointment.assignments, techMap],
      )

      const [equipmentTypeJobLinksRes] = useQuery({
        query: EQUIPMENT_TYPE_LINKS_FOR_APPOINTMENT_QUERY,
        variables: { appointmentGuid: appointment.appointmentGuid },
      })

      return (
        <div className="flex flex-col p-3 pb-0">
          <AppointmentCard
            key={appointment.appointmentGuid}
            jobGuid={appointment.job.jobGuid}
            jobType={appointment.job.jobType}
            appointmentType={appointment.appointmentType}
            appointmentCanceled={appointment.cancellationStatus?.canceled}
            assignments={assignments}
            customerArrivalWindow={{
              start: appointment.appointmentWindowStart,
              end: appointment.appointmentWindowEnd,
            }}
            location={appointment.job.location}
            // readonly equip job type links
            equipmentTypeJobLinks={
              equipmentTypeJobLinksRes.data?.jobAppointmentsByPk?.job
                .equipmentTypeJobLinks
            }
            tzId={tzId}
            showTechnician
          />
          <Divider className="my-2" />
          <Button
            type={isActive ? 'default' : 'primary'}
            onClick={onClick}
            data-dd-action-name={`BZ Schedule Map - Appointment Pin Popup - ${
              isActive ? 'Hide' : 'Show'
            }`}
            icon={
              <FontAwesomeIcon
                icon={isActive ? faClose : faLocationCrosshairs}
              />
            }
          >
            {isActive
              ? 'Hide Nearby Maint. Opportunities'
              : 'Show Nearby Maint. Opportunities'}
          </Button>
        </div>
      )
    },
  )
