import { R } from '@breezy/shared'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { StateSetter, useStrictContext } from '../../../utils/react-utils'
import {
  InternalEvent,
  ScheduleAssignment,
  UnassignedAppointment,
} from '../Schedule.gql'
import { AppointmentMap, FullScheduleAppointment } from '../scheduleUtils'
import {
  applyChanges,
  DEFAULT_PENDING_CHANGES,
  PendingScheduleChanges,
  SetPendingChanges,
  SetPendingChangesArg,
} from './pendingChanges'

type SchedulePendingChangesContextType = {
  setSubscriptionsPaused: (paused: boolean) => void
  pendingChanges: PendingScheduleChanges
  setPendingChanges: (
    arg: SetPendingChangesArg | SetPendingChangesArg[],
  ) => void
  setPendingChangesRaw: StateSetter<PendingScheduleChanges>
  originalAssignmentMap: Record<string, ScheduleAssignment>
  originalInternalEventMap: Record<string, InternalEvent>
  scheduleAssignments: ScheduleAssignment[]
  unassignedAppointments: UnassignedAppointment[]
  internalEvents: InternalEvent[]
  appointmentMap: AppointmentMap
  allAppointments: FullScheduleAppointment[]
}

const SchedulePendingChangesContext = React.createContext<
  SchedulePendingChangesContextType | undefined
>(undefined)

type SchedulePendingChangesWrapperProps = React.PropsWithChildren<{
  scheduleAssignments: ScheduleAssignment[]
  unassignedAppointments: UnassignedAppointment[]
  internalEvents: InternalEvent[]
  appointmentMap: AppointmentMap
  allAppointments: FullScheduleAppointment[]
  setSubscriptionsPaused: (paused: boolean) => void
  noSubscriptionsFetching: boolean
}>

export const SchedulePendingChangesWrapper =
  React.memo<SchedulePendingChangesWrapperProps>(
    ({
      children,
      scheduleAssignments,
      unassignedAppointments,
      internalEvents,
      appointmentMap,
      setSubscriptionsPaused,
      noSubscriptionsFetching,
      allAppointments,
    }) => {
      const [pendingChanges, setPendingChangesRaw] =
        useState<PendingScheduleChanges>(DEFAULT_PENDING_CHANGES)

      const originalAssignmentMap = useMemo(
        () => R.indexBy(R.prop('assignmentGuid'), scheduleAssignments),
        [scheduleAssignments],
      )
      const originalInternalEventMap = useMemo(
        () => R.indexBy(R.prop('technicianCapacityBlockGuid'), internalEvents),
        [internalEvents],
      )

      const setPendingChanges = useCallback<SetPendingChanges>(
        arg => {
          setPendingChangesRaw(pendingChanges => {
            const args = Array.isArray(arg) ? arg : [arg]
            let changes = pendingChanges
            for (const arg of args) {
              changes = applyChanges(
                arg,
                changes,
                originalAssignmentMap,
                originalInternalEventMap,
                appointmentMap,
              )
            }
            return changes
          })
        },
        [appointmentMap, originalAssignmentMap, originalInternalEventMap],
      )

      const [frozenData, setFrozenData] = useState<{
        scheduleAssignments: ScheduleAssignment[]
        unassignedAppointments: UnassignedAppointment[]
        internalEvents: InternalEvent[]
        pendingChanges: PendingScheduleChanges
        appointmentMap: AppointmentMap
        allAppointments: FullScheduleAppointment[]
      }>()

      useEffect(() => {
        if (noSubscriptionsFetching) {
          setFrozenData(undefined)
          setPendingChangesRaw(DEFAULT_PENDING_CHANGES)
        } else {
          setFrozenData({
            scheduleAssignments,
            unassignedAppointments,
            internalEvents,
            pendingChanges,
            appointmentMap,
            allAppointments,
          })
        }
        // When we commit changes, we pause the subscriptions. When we're done, we unpause, so they all refetch. We need
        // to wait until they all return to return all the latest things and clear the pending map otherwise we'll either
        // get boxes that disappear and reappear or boxes that are doubled (we either clear the pending map too early or
        // too late).
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [noSubscriptionsFetching])

      return (
        <SchedulePendingChangesContext.Provider
          value={{
            setSubscriptionsPaused,
            ...(frozenData ?? {
              pendingChanges,
              scheduleAssignments,
              unassignedAppointments,
              internalEvents,
              appointmentMap,
              allAppointments,
            }),
            setPendingChanges,
            setPendingChangesRaw,
            originalAssignmentMap,
            originalInternalEventMap,
          }}
        >
          {children}
        </SchedulePendingChangesContext.Provider>
      )
    },
  )

export const useSchedulePendingChanges = () =>
  useStrictContext(SchedulePendingChangesContext)
