import { toPlural } from '@breezy/shared'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Input } from 'antd'
import classNames from 'classnames'
import React from 'react'
import {
  FilterTagGroupListV2,
  FilterTagGroupListV2Filter,
} from '../../pages/JobPages/components/FilterTags'

const ELEMENT_DIVIDER = <div className="mx-2 h-6 w-[1px] bg-bz-gray-500" />

type ListPageFilterBarProps = React.PropsWithChildren<{
  searchTerm: string
  setSearchTerm: (value: string) => void
  secondaryFilters?: React.ReactNode
  totalItems: number
  sortByControl: React.ReactNode
  hasFilters: boolean
  filters: FilterTagGroupListV2Filter[]
  filterLabels: Record<string, string>
  clearFilter: (filterKey: string, option: string) => void
  clearAllFilters: () => void
}>

export const ListPageFilterBar = React.memo<ListPageFilterBarProps>(
  ({
    searchTerm,
    setSearchTerm,
    children,
    secondaryFilters,
    totalItems,
    sortByControl,
    hasFilters,
    filters,
    filterLabels,
    clearAllFilters,
    clearFilter,
  }) => {
    return (
      <>
        <div
          className={classNames(
            'flex items-center',
            hasFilters ? 'mb-3' : 'mb-6',
          )}
        >
          <div className="flex flex-1 flex-row items-center space-x-2">
            <Input
              className="min-w-[100px] max-w-[260px]"
              prefix={
                <FontAwesomeIcon icon={faSearch} className="text-bz-gray-700" />
              }
              size="large"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              placeholder="Search..."
            />
            {children}
            {secondaryFilters ? (
              <>
                {ELEMENT_DIVIDER}
                {secondaryFilters}
              </>
            ) : null}
          </div>
          <div className="ml-3 flex flex-row items-center">
            <div className="whitespace-nowrap text-base font-semibold">
              {totalItems} {toPlural(totalItems, 'item')}
            </div>
            {ELEMENT_DIVIDER}
            {sortByControl}
          </div>
        </div>
        {hasFilters && (
          <div
            className={classNames(
              'mb-6 flex w-full flex-row items-center border-x-0 border-y border-solid border-bz-gray-400 py-2',
              {
                'opacity-40': !!searchTerm,
              },
            )}
          >
            <div className="flex-1">
              <FilterTagGroupListV2
                filters={filters}
                labels={filterLabels}
                onClick={clearFilter}
              />
            </div>
            <Button type="link" onClick={clearAllFilters}>
              Clear all
            </Button>
          </div>
        )}
      </>
    )
  },
)
