import { mapToVisitJob } from '@breezy/backend/src/application-types'
import {
  bzExpect,
  getVisitStatus,
  InstalledEquipment,
  LocalDate,
  LocalDateString,
  NextVisitViewModel,
} from '@breezy/shared'
import { MaintenancePlanNextVisitFragment } from '../../generated/user/graphql'

export type NextVisitQuery = NonNullable<MaintenancePlanNextVisitFragment>

export const mapQueryNextVisitToNextVisitViewModel = (
  nextVisit: NextVisitQuery | undefined,
): NextVisitViewModel | undefined => {
  if (!nextVisit) {
    return undefined
  }
  const visitEquipment = (
    nextVisit.maintenancePlanVisitInstalledEquipments ?? []
  )
    .filter(e => !!e.installedEquipment)
    .map(e => ({
      installedEquipmentGuid: bzExpect(
        e.installedEquipment?.installedEquipmentGuid,
        'Installed Equipment Guid',
      ),
      equipmentType: bzExpect(
        e.installedEquipment?.equipmentType,
        'Equipment Type',
      ),
      manufacturer: e.installedEquipment?.manufacturer,
      installationDate: e.installedEquipment?.installationDate
        ? LocalDate.parse(e.installedEquipment.installationDate)
        : undefined,
      estimatedEndOfLifeDate: e.installedEquipment?.estimatedEndOfLifeDate
        ? LocalDate.parse(e.installedEquipment.estimatedEndOfLifeDate)
        : undefined,
      averageLifeExpectancyYears:
        e.installedEquipment?.averageLifeExpectancyYears,
      modelNumber: e.installedEquipment?.modelNumber,
      serialNumber: e.installedEquipment?.serialNumber,
    }))
  const oldestEquipmentAgeYears =
    InstalledEquipment.getOldestInstalledEquipmentAgeYears(
      new Date(),
      visitEquipment,
    )
  return {
    maintenancePlanGuid: nextVisit.maintenancePlanGuid,
    companyGuid: nextVisit.companyGuid,
    visitGuid: nextVisit.maintenancePlanVisitGuid,
    name: nextVisit.name,
    affinityDate: nextVisit.affinityDate as LocalDateString,
    status: getVisitStatus(nextVisit),
    visitJob: nextVisit.job ? mapToVisitJob(nextVisit.job) : undefined,
    visitEquipment,
    oldestEquipmentAgeYears,
    isExpiredOverride: nextVisit.isExpiredOverride,
    isCompletedOverride: nextVisit.isCompletedOverride,
    completedAtOverride: nextVisit.completedAtOverride,
    issuedAt: nextVisit.issuedAt,
    expiresAt: nextVisit.expiresAt,
    dueAt: nextVisit.dueAt,
    visitNumber: nextVisit.visitNumber ?? 1,
  }
}
