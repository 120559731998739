import { PermissionV2 } from '@breezy/shared'
import { Switch } from 'antd'
import React, { useContext } from 'react'
import { useIsImpersonating } from '../../../providers/PrincipalUser'
import { Authorized } from '../../Permissions/Authorized/Authorized'
import { CSModeContext } from './CSModeWrapper'

export const CSModeSwitch = React.memo(() => {
  const { isCSMode, setIsCSMode } = useContext(CSModeContext)
  const isImpersonating = useIsImpersonating()
  return (
    <Authorized
      to={PermissionV2.IMPERSONATE_USER}
      overrideAuthorized={!!isImpersonating}
      allowsNonCompanyUser
    >
      <div className="mt-2 flex flex-row items-center">
        <div className="mr-2">CS Mode</div>
        <Switch checked={isCSMode} onChange={setIsCSMode} />
      </div>
    </Authorized>
  )
})
