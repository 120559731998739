import { BzDateTime, PayoutEnriched, formatUsc } from '@breezy/shared'
import TagColumn from '../../components/Page/Columns/TagColumn/TagColumn'
import VerticalKeyValue from '../../components/Page/Columns/TagColumn/VerticalKeyValue'
import BzColumn from '../../elements/BzColumn/BzColumn'
import ThinDivider from '../../elements/ThinDivider'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { PayoutIcon } from '../../utils/feature-icons'
import { m } from '../../utils/react-utils'

type PayoutSummaryCardProps = {
  payout: PayoutEnriched
}

export const PayoutSummaryCard = m<PayoutSummaryCardProps>(({ payout }) => {
  const tzId = useExpectedCompanyTimeZoneId()

  return (
    <TagColumn
      title="Payout"
      icon={PayoutIcon}
      headerCssClass={'bg-gradient-to-r to-[#B084CC] from-[#791E94]'}
    >
      <BzColumn noPadding>
        <div className="flex justify-between">
          <div className="account-display-name card-title-md w-full">
            Payout
          </div>
          <div className="account-display-name card-title-md w-full">
            {formatUsc(payout.netAmountUsc)}
          </div>
        </div>
        <ThinDivider />
        <div className="grid grid-cols-2 items-start gap-y-4">
          <VerticalKeyValue
            pair={{
              key: 'Origination Date',
              value: BzDateTime.fromIsoString(payout.paidAt, tzId).toDateFormat(
                'MMM dd, yyyy',
              ),
            }}
          />
          <VerticalKeyValue
            pair={{
              key: 'Expected Deposit Date',
              value: BzDateTime.fromIsoString(payout.paidAt, tzId)
                .plusDays(3)
                .toDateFormat('MMM dd, yyyy'),
            }}
          />
          <VerticalKeyValue
            pair={{
              key: 'Total Charges',
              value: formatUsc(payout.chargesAmountUsc),
            }}
          />
          <VerticalKeyValue
            pair={{
              key: 'Total Refunds',
              value: formatUsc(payout.refundsAmountUsc),
            }}
          />
          <VerticalKeyValue
            pair={{
              key: 'Total Fees',
              value: formatUsc(payout.feesAmountUsc),
            }}
          />
          <VerticalKeyValue
            pair={{
              key: 'Balance Adjustment',
              value: formatUsc(payout.balanceAdjustmentUsc),
            }}
          />
          <VerticalKeyValue
            pair={{
              key: 'Number of Charges',
              value: payout.chargesCount.toString(),
            }}
          />
          <VerticalKeyValue
            pair={{
              key: 'Number of Refunds',
              value: payout.refundsCount.toString(),
            }}
          />
        </div>
        <ThinDivider />
      </BzColumn>
    </TagColumn>
  )
})
