import {
  JobLifecycleStatus,
  JobLifecycleType,
  R,
  isNullish,
  jobOutcomesDataShouldBeCollectedOnTransition,
  usdToUsCents,
} from '@breezy/shared'
import {
  faFileInvoiceDollar,
  faReceipt,
} from '@fortawesome/pro-regular-svg-icons'
import { Button, Divider } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery } from 'urql'
import { BehindFeatureFlag } from '../../components/BehindFeatureFlag'
import { EditJobModal } from '../../components/EditJobModal/EditJobModal'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import {
  DEFAULT_JOBS_VIEWS_GUID,
  useJobsViewsContext,
} from '../../components/JobLifecycleView'
import { JobOutcomesModal } from '../../components/JobOutcomesModal/JobOutcomesModal'
import { Board, KanbanJob, Status } from '../../components/Kanban/kanbanUtils'
import { LifecycleStatusCircle } from '../../components/LifecycleStatusCircle/LifecycleStatusCircle'
import { Page } from '../../components/Page/Page'
import { Card } from '../../elements/Card/Card'
import { useCanManageOfficeJobs } from '../../hooks/permission/useCanManageOfficeJobs'
import { useCanManageTechnicianPerformance } from '../../hooks/permission/useCanManageTechnicianPerformance'
import { trpc } from '../../hooks/trpc'
import useAppNavigation from '../../hooks/useAppNav'
import {
  DATA_FILTER_DEFAULT_FILTER,
  DATA_FILTER_QUERY_PARAM_OPTIONS,
} from '../../hooks/useDataFilter'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import {
  filtersToQueryString,
  queryStringToFilters,
} from '../../hooks/useFilter'
import {
  JobLifecycleColumnDisplayPropertySettings,
  JobLifecycleDisplayPropertySettings,
  getDefaultJobLifecycleColumnDisplayPropertiesForLifecycleType,
  useJobLifecyclePersistedDisplay,
} from '../../hooks/useJobLifecyclePersistedDisplay'
import {
  useExpectedCompanyTimeZoneId,
  usePrincipalUser,
} from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import {
  useLoadPreviousQueryStringOnMount,
  useQueryParamState,
} from '../../utils/react-utils'
import { JobKanbanContext } from './JobKanbanContext'
import { COMPANY_CONFIG_QUERY, JOB_LIFECYCLES_QUERY } from './JobsPage.gql'
import { ChangeJobTypeModal } from './components/ChangeJobTypeModal/ChangeJobTypeModal'
import { CreateJobLifecycleViewModal } from './components/CreateJobLifecycleViewModal'
import { CreateLinkedJobConfirmationModal } from './components/CreateLinkedJobConfirmationModal'
import { FilterTagGroupListV2 } from './components/FilterTags'
import { JobLifecycleFilterDrawerV2 } from './components/JobLifecycleFilterDrawerV2'
import {
  JobMenuDropdownOnCreateLinkedJobHandler,
  JobMenuDropdownOnEditJobHandler,
} from './components/JobMenuDropdown'
import { JobsScoreCards } from './components/JobsScoreCards'
import { JobsToolbar } from './components/JobsToolbar/JobsToolbar'
import { JobsView } from './components/JobsView'
import LifecycleFinanceSummaryStub from './components/LifecycleFinanceSummaryStub'
import { useRecentJobs } from './hooks/useRecentJobs'
import { useJobFilters } from './useJobFilters'
import {
  getAccountManagersFromJobs,
  getAssignedTechniciansFromJobs,
  getJobLifecycleStatusFinancialRollupData,
  mapKanbanJobToJobOutcomesModalJob,
  mergeFilters,
} from './util'

const JobsPageV2 = React.memo(() => {
  useLoadPreviousQueryStringOnMount()
  const message = useMessage()

  const tzId = useExpectedCompanyTimeZoneId()
  const jobsScoreCardsEnabled = useFeatureFlag('jobsScoreCards')
  const disableJobsListView = useFeatureFlag('disableJobsListView')
  const linkedJobsEnabled = useFeatureFlag('linkedJobs')
  const canManageTechnicianPerformance = useCanManageTechnicianPerformance()
  const [kanbanJobForJobOutcomesModal, setKanbanJobForJobOutcomesModal] =
    useState<KanbanJob | null>(null)
  const [
    jobLifecycleStatusGuidForJobOutcomesModal,
    setJobLifecycleStatusGuidForJobOutcomesModal,
  ] = useState('')

  const openJobOutcomesModal = useCallback(
    (job: KanbanJob, jobLifecycleStatusGuidOnSubmission: string) => {
      setKanbanJobForJobOutcomesModal(job)
      setJobLifecycleStatusGuidForJobOutcomesModal(
        jobLifecycleStatusGuidOnSubmission,
      )
    },
    [],
  )

  const closeJobOutcomesModal = useCallback(() => {
    setKanbanJobForJobOutcomesModal(null)
    setJobLifecycleStatusGuidForJobOutcomesModal('')
  }, [])

  // Record of jobGuid to new lifecycle status id
  const [optimisticChanges, setOptimisticChanges] = useState<
    Record<string, string>
  >({})

  const companyUser = usePrincipalUser().expectCompanyUserPrincipal()

  const appNav = useAppNavigation()

  const canManageOfficeJobs = useCanManageOfficeJobs()

  const lifecycleQuery = useQuery({
    query: JOB_LIFECYCLES_QUERY,
    variables: {
      companyGuid: companyUser.company.companyGuid,
    },
    requestPolicy: 'network-only',
  })

  const lifecycles = useMemo(() => {
    return (
      lifecycleQuery[0].data?.jobLifecycles?.filter(x => !x.isArchived) ?? []
    )
  }, [lifecycleQuery])

  const getKnownLifecycleName = useCallback(
    (name: string | null | undefined): string | undefined => {
      if (isNullish(name)) {
        return undefined
      }

      return lifecycles.find(x => x.name === name.trim())?.name
    },
    [lifecycles],
  )

  const {
    selectedJobsView,
    upsertJobsView,
    isLoading: jobsViewsIsLoading,
  } = useJobsViewsContext()

  const [searchParams, setSearchParams] = useSearchParams()

  const {
    kanbanView,
    closedJobTimePeriod,
    showEmptyStatuses,
    columnPropertySettings,
    setColumnPropertySettings,
    displayPropertySettings,
    setDisplayPropertySettings,
    scrollPos,
    setScrollPos,
  } = useJobLifecyclePersistedDisplay(
    lifecycles.map(lifecycle => ({
      lifecycleGuid: lifecycle.jobLifecycleGuid,
      lifecycleType: lifecycle.jobLifecycleType as JobLifecycleType,
    })),
  )

  const [jobsQuery, jobsQueryRefetch] = useRecentJobs({
    companyGuid: companyUser.company.companyGuid,
    tzId: tzId,
    closedJobTimePeriod: closedJobTimePeriod.value,
  })

  const selectedLifecycleName: string = useMemo(() => {
    let lifecycle: string = lifecycles.length > 0 ? lifecycles[0].name : ''

    const dbSavedLifecycleName = getKnownLifecycleName(
      selectedJobsView.view.lifecycleName,
    )
    if (dbSavedLifecycleName) {
      lifecycle = dbSavedLifecycleName
    }

    const searchParamLifecycleName = getKnownLifecycleName(
      searchParams.get('lifecycle'),
    )
    if (searchParamLifecycleName) {
      lifecycle = searchParamLifecycleName
    }

    return lifecycle
  }, [
    searchParams,
    selectedJobsView.view.lifecycleName,
    lifecycles,
    getKnownLifecycleName,
  ])

  const setSelectedLifecycleName = useCallback(
    (lifecycleName: string) => {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        lifecycle: lifecycleName,
      })
    },
    [searchParams, setSearchParams],
  )

  const initialFilters = useMemo(() => {
    const searchParamsCopy = new URLSearchParams(searchParams.toString())
    searchParamsCopy.delete('view')
    searchParamsCopy.delete('lifecycle')

    const parsedFilters = queryStringToFilters(searchParamsCopy.toString())
    if (parsedFilters.length > 0) {
      return mergeFilters(...parsedFilters)
    }

    if (!isNullish(selectedJobsView.view.filtersV2)) {
      const filtersString = selectedJobsView.view.filtersV2.trim()
      if (filtersString !== '') {
        const viewFilters = queryStringToFilters(filtersString)
        parsedFilters.push(...viewFilters)
      }
    }

    return mergeFilters(...parsedFilters)
  }, [searchParams, selectedJobsView.view.filtersV2])

  const [columnPropertiesSettings, setColumnPropertiesSettings] =
    useState<JobLifecycleColumnDisplayPropertySettings>(columnPropertySettings)

  const [displayPropertiesSettings, setDisplayPropertiesSettings] =
    useState<JobLifecycleDisplayPropertySettings>(displayPropertySettings)

  const statusGuidToStatusMap = useMemo(() => {
    const statusGuidToStatusMap: Record<string, JobLifecycleStatus> = {}

    for (const lifecycle of lifecycles) {
      for (const status of lifecycle.statuses) {
        statusGuidToStatusMap[status.jobLifecycleStatusGuid] = status
      }
    }
    return statusGuidToStatusMap
  }, [lifecycles])

  const selectedLifecycleGuid = useMemo(() => {
    const name = decodeURIComponent(selectedLifecycleName)
    return lifecycles.find(lc => lc.name === name)?.jobLifecycleGuid ?? ''
  }, [lifecycles, selectedLifecycleName])

  const lifecycleDropdownOptions: {
    lifecycleGuid: string
    lifecycleName: string
  }[] = useMemo(() => {
    return lifecycles
      .filter(lifecycle => !lifecycle.isArchived)
      .map(lifecycle => ({
        lifecycleGuid: lifecycle.jobLifecycleGuid,
        lifecycleName: lifecycle.name,
      }))
  }, [lifecycles])

  const [searchTerm, setSearchTerm] = useState('')

  const data: KanbanJob[] = useMemo(() => {
    const data: KanbanJob[] = []

    const lcSearchTerm = searchTerm.toLowerCase()

    for (const job of jobsQuery.data?.jobs ?? []) {
      const jobLifecycleStatusGuid =
        optimisticChanges[job.jobGuid] ?? job.jobLifecycleStatusGuid

      const assignedTechs = getAssignedTechniciansFromJobs([job])
      const accountManagers = getAccountManagersFromJobs([job])
      if (
        searchTerm &&
        ![
          job.account.accountDisplayName,
          job.jobType.name,
          job.jobType.jobClass,
          `#${job.displayId}`,
          `Job #${job.displayId}`,
          job.location.address.line1,
          job.location.address.zipCode.slice(0, 5),
          ...assignedTechs.map(
            technician => `${technician.firstName} ${technician.lastName}`,
          ),
          ...accountManagers.map(
            accountManager =>
              `${accountManager.firstName} ${accountManager.lastName}`,
          ),
          ...job.tags.map(({ tag }) => tag.name),
          ...job.account.tags.map(({ tag }) => tag.name),
        ].some(val => val.toLowerCase().includes(lcSearchTerm))
      ) {
        continue
      }

      const status = statusGuidToStatusMap[jobLifecycleStatusGuid]
      if (!status) {
        continue
      }

      if (status.jobLifecycleGuid !== selectedLifecycleGuid) {
        continue
      }

      data.push({
        id: job.jobGuid,
        job: {
          ...job,
          jobClass: job.jobType.jobClass,
          isCreatedLinkedJob: job.linkedToJobs.length > 0,
        },
        statusId: jobLifecycleStatusGuid,
        boardId: status.jobLifecycleGuid,
      })
    }
    return data
  }, [
    jobsQuery.data?.jobs,
    optimisticChanges,
    searchTerm,
    selectedLifecycleGuid,
    statusGuidToStatusMap,
  ])

  const {
    filters: filtersV2,
    filtered: filteredV2,
    config: filtersV2Config,
    clearFilters,
    setFilters: setFiltersV2,
    isLoading: isJobFiltersLoading,
  } = useJobFilters({
    jobs: data,
    tzId,
    initialFilters: initialFilters,
  })
  const jobLifecycleStatusRollupDataMap = useMemo(
    () => getJobLifecycleStatusFinancialRollupData(filteredV2),
    [filteredV2],
  )

  const board = useMemo(() => {
    const board: Board = {
      boardId: selectedLifecycleGuid,
      statuses: [],
    }

    for (const lifecycle of lifecycles) {
      if (lifecycle.jobLifecycleGuid !== selectedLifecycleGuid) {
        continue
      }

      // Order statuses by the sequence defined on the job lifecycle
      const statuses: Status[] = []
      for (const statusGuid of lifecycle.sequence) {
        const status = lifecycle.statuses.find(
          status => status.jobLifecycleStatusGuid === statusGuid,
        )

        if (!status) continue

        const columnSettings =
          columnPropertiesSettings[lifecycle.jobLifecycleGuid] ??
          getDefaultJobLifecycleColumnDisplayPropertiesForLifecycleType(
            lifecycle.jobLifecycleType as JobLifecycleType,
          )

        const showSummary = Object.values(columnSettings).some(Boolean)

        statuses.push({
          id: status.jobLifecycleStatusGuid,
          name: status.name,
          icon: (
            // TODO: the designs have a border around a circle when it's light. We should add that.
            <LifecycleStatusCircle
              color={status.color}
              specialStatus={status.specialStatus}
            />
          ),
          summary: showSummary ? (
            <>
              {columnSettings['Invoice Totals'] && (
                <LifecycleFinanceSummaryStub
                  icon={faFileInvoiceDollar}
                  title="Invoices"
                  valueUsc={usdToUsCents(
                    jobLifecycleStatusRollupDataMap[
                      status.jobLifecycleStatusGuid
                    ]?.totalAmountInvoicedUsd ?? 0,
                  )}
                  amountClassName={classNames({
                    'min-w-[50px]': kanbanView.value === 'list',
                  })}
                />
              )}
              {columnSettings['Estimate Totals'] && (
                <LifecycleFinanceSummaryStub
                  icon={faReceipt}
                  title="Estimates"
                  valueUsc={
                    jobLifecycleStatusRollupDataMap[
                      status.jobLifecycleStatusGuid
                    ]?.totalAmountEstimatedUsc ?? 0
                  }
                  className={classNames({
                    'min-w-[156px]': kanbanView.value === 'list',
                  })}
                />
              )}
            </>
          ) : null,
        })
      }

      board.statuses = statuses
    }
    return board
  }, [
    columnPropertiesSettings,
    jobLifecycleStatusRollupDataMap,
    kanbanView.value,
    lifecycles,
    selectedLifecycleGuid,
  ])

  const changeStatusMutation = trpc.jobLifecycles[
    'job-lifecycles:change-status'
  ].useMutation({
    onSuccess: () => {
      jobsQueryRefetch()
    },
  })

  const updateJobStatus = useCallback(
    async (jobGuid: string, statusId: string) => {
      setOptimisticChanges(map => ({
        ...map,
        [jobGuid]: statusId,
      }))

      changeStatusMutation.mutateAsync({
        jobGuid,
        jobLifecycleStatusGuid: statusId,
      })
    },
    [changeStatusMutation],
  )

  const [createLinkedJobModalConfig, setCreateLinkedJobModalConfig] = useState<{
    job: KanbanJob
    newStatus: JobLifecycleStatus
  } | null>(null)

  const onKanbanChange = useCallback(
    (datum: KanbanJob, newStatusId: string) => {
      if (datum.statusId === newStatusId) {
        return
      }

      const currentStatusId =
        optimisticChanges[datum.job.jobGuid] ?? datum.statusId

      const currentStatus = statusGuidToStatusMap[currentStatusId]
      const nextStatus = statusGuidToStatusMap[newStatusId]

      if (
        jobOutcomesDataShouldBeCollectedOnTransition(
          currentStatus,
          nextStatus,
          canManageTechnicianPerformance,
          datum.job.jobType.jobClass,
        )
      ) {
        openJobOutcomesModal(datum, newStatusId)
      } else if (
        linkedJobsEnabled &&
        statusGuidToStatusMap[newStatusId].isCreateLinkedJobAutomationEnabled
      ) {
        setCreateLinkedJobModalConfig({
          job: datum,
          newStatus: statusGuidToStatusMap[newStatusId],
        })
      } else {
        updateJobStatus(datum.job.jobGuid, newStatusId)
      }
    },
    [
      optimisticChanges,
      canManageTechnicianPerformance,
      linkedJobsEnabled,
      statusGuidToStatusMap,
      openJobOutcomesModal,
      updateJobStatus,
    ],
  )

  const navigate = useNavigate()

  const onCardClick = useCallback(
    (data: KanbanJob) => {
      navigate(`/jobs/${data.id}`)
    },
    [navigate],
  )

  const [createJobLifecycleViewModalOpen, setCreateJobLifecycleViewModalOpen] =
    useState(false)

  const [jobLifecycleFilterDrawerOpen, setJobLifecycleFilterDrawerOpen] =
    useState(false)

  const [qsFilters, setFilters] = useQueryParamState(
    'filter',
    DATA_FILTER_DEFAULT_FILTER,
    {
      ...DATA_FILTER_QUERY_PARAM_OPTIONS,
      // This allows us to easily fall back to the view and the default if this isn't set.
      returnUndefinedAsDefault: true,
    },
  )

  const filters =
    qsFilters ?? selectedJobsView.view.filters ?? DATA_FILTER_DEFAULT_FILTER

  const clearAll = useCallback(() => {
    setSearchParams({ view: 'All Jobs' })
    clearFilters()
  }, [clearFilters, setSearchParams])

  const isViewDirty = useMemo(() => {
    if (selectedLifecycleName !== selectedJobsView.view.lifecycleName) {
      return true
    }

    const viewsFilters = queryStringToFilters(
      selectedJobsView.view.filtersV2 ?? '',
    )
    if (viewsFilters.length !== filtersV2.length) {
      return true
    }

    const viewsFilterSet = new Map<string, Set<string>>(
      viewsFilters.map(curr => [curr.key, new Set(curr.optionsSelected)]),
    )
    for (let i = 0; i < filtersV2.length; i++) {
      const currFilter = filtersV2[i]
      const optionsSet = new Set(currFilter.optionsSelected)
      const viewFilter = viewsFilterSet.get(currFilter.key)
      if (!viewFilter) {
        return true
      }

      if (!R.equals(optionsSet, viewFilter)) {
        return true
      }
    }

    return false
  }, [filtersV2, selectedJobsView.view, selectedLifecycleName])

  const clearChanges = useCallback(() => {
    setSearchParams({ view: selectedJobsView.title })
    setFiltersV2(queryStringToFilters(selectedJobsView.view.filtersV2 ?? ''))
  }, [
    selectedJobsView.title,
    selectedJobsView.view.filtersV2,
    setFiltersV2,
    setSearchParams,
  ])

  const updateView = useCallback(() => {
    const qsV2Copy = new URLSearchParams(searchParams.toString())
    qsV2Copy.delete('view')
    qsV2Copy.delete('lifecycle')
    upsertJobsView({
      ...selectedJobsView,
      view: {
        ...selectedJobsView,
        filtersV2: qsV2Copy.toString(),
        lifecycleName: selectedLifecycleName,
      },
    })
  }, [searchParams, selectedJobsView, selectedLifecycleName, upsertJobsView])

  const addFilter = (filterKey: string, filterOption: string) => {
    const filters = [...filtersV2]
    const filterIdx = filters.findIndex(filter => filter.key === filterKey)
    if (filterIdx >= 0) {
      filters[filterIdx].optionsSelected = Array.from(
        new Set([...filters[filterIdx].optionsSelected, filterOption]),
      )
    } else {
      filters.push({ key: filterKey, optionsSelected: [filterOption] })
    }

    setSearchParams({
      ...Object.fromEntries(searchParams),
      ...mergeFilters(...filters).reduce<Record<string, string>>(
        (acc, curr) => {
          acc[curr.key] = curr.optionsSelected.join(',')
          return acc
        },
        {},
      ),
    })
  }

  const removeFilter = (filterKey: string, filterOption: string) => {
    const filters = [...filtersV2]
    const filterIdx = filters.findIndex(filter => filter.key === filterKey)
    if (filterIdx >= 0) {
      const optionIdx = filters[filterIdx].optionsSelected.findIndex(
        option => option === filterOption,
      )
      if (optionIdx >= 0) {
        filters[filterIdx].optionsSelected.splice(optionIdx, 1)

        if (filters[filterIdx].optionsSelected.length === 0) {
          filters.splice(filterIdx, 1)
        }
      }
    }

    setSearchParams({
      view: selectedJobsView.title,
      lifecycle: selectedLifecycleName,
      ...mergeFilters(...filters).reduce<Record<string, string>>(
        (acc, curr) => {
          acc[curr.key] = curr.optionsSelected.join(',')
          return acc
        },
        {},
      ),
    })
  }

  const clearAllFilters = () => {
    setSearchParams({
      view: selectedJobsView.title,
      lifecycle: selectedLifecycleName,
    })
  }

  const onJobOutcomesModalOk = useCallback(() => {
    try {
      if (isNullish(kanbanJobForJobOutcomesModal)) {
        return
      }

      updateJobStatus(
        kanbanJobForJobOutcomesModal.job.jobGuid,
        jobLifecycleStatusGuidForJobOutcomesModal,
      )
      closeJobOutcomesModal()
    } catch (e) {
      message.error(
        'Failed to complete job. Please reload the application and try again. If the problem persists, please contact support.',
      )
    }
  }, [
    closeJobOutcomesModal,
    jobLifecycleStatusGuidForJobOutcomesModal,
    kanbanJobForJobOutcomesModal,
    message,
    updateJobStatus,
  ])

  const onJobOutcomesModalClose = useCallback(() => {
    closeJobOutcomesModal()
  }, [closeJobOutcomesModal])

  const [companyConfigQuery] = useQuery({
    query: COMPANY_CONFIG_QUERY,
    variables: { companyGuid: companyUser.company.companyGuid },
  })

  const hiddenDisplayPropertySettings: (keyof JobLifecycleDisplayPropertySettings)[] =
    useMemo(() => {
      const hiddenDisplayProperties: (keyof JobLifecycleDisplayPropertySettings)[] =
        []

      if (!companyConfigQuery.data?.companyConfigByPk?.accountManagerEnabled) {
        hiddenDisplayProperties.push('Account Manager')
      }

      if (!jobsScoreCardsEnabled) {
        hiddenDisplayProperties.push('Estimates', 'Invoices')
      }

      return hiddenDisplayProperties
    }, [
      companyConfigQuery.data?.companyConfigByPk?.accountManagerEnabled,
      jobsScoreCardsEnabled,
    ])

  const hiddenFilters: string[] = useMemo(() => {
    const hidden: string[] = []

    if (!companyConfigQuery.data?.companyConfigByPk?.accountManagerEnabled) {
      hidden.push('accountManagers')
    }

    return hidden
  }, [companyConfigQuery.data?.companyConfigByPk?.accountManagerEnabled])

  const [jobToEdit, setJobToEdit] = useState<string | null>(null)

  const onEditJob: JobMenuDropdownOnEditJobHandler = useCallback(
    jobGuid => setJobToEdit(jobGuid),
    [],
  )

  const onCreateLinkedJob: JobMenuDropdownOnCreateLinkedJobHandler =
    useCallback(
      ({ accountGuid, locationGuid, linkedJobGuid }) =>
        appNav.navigateToCreateNewJob({
          accountGuid,
          locationGuid,
          linkedJobGuid,
        }),
      [appNav],
    )

  const [changeJobTypeModalOpen, setChangeJobTypeModalOpen] = useState(false)

  const onChangeJobType = useCallback(() => {
    setChangeJobTypeModalOpen(true)
  }, [])

  return (
    <Page requiresCompanyUser className="flex">
      <JobKanbanContext.Provider
        value={{
          selectedLifecycleGuid,
          columnPropertiesSettings,
          displayPropertiesSettings,
          filters,
          setFilters,
          scrollPos,
          setScrollPos,
          filtersV2Config,
          filtersV2,
          addFilter,
          removeFilter,
          clearFilters: clearAllFilters,
        }}
      >
        <GqlQueryLoader
          query={lifecycleQuery}
          render={() => {
            return (
              <>
                <Card className="flex min-w-[1000px] flex-1 flex-col p-2">
                  <BehindFeatureFlag
                    enabledFeatureFlag="jobsScoreCards"
                    render={<JobsScoreCards jobs={filteredV2} />}
                  />

                  <JobsToolbar
                    selectedLifecycleName={selectedLifecycleName}
                    lifecycleOptions={lifecycleDropdownOptions}
                    showClearFilters={filtersV2.length > 0}
                    selectedBoardViewType={kanbanView.value}
                    disableListView={disableJobsListView}
                    selectedLifecycleType={
                      (lifecycles.find(
                        lifecycle =>
                          lifecycle.jobLifecycleGuid === selectedLifecycleGuid,
                      )?.jobLifecycleType as JobLifecycleType) ?? undefined
                    }
                    selectedClosedJobTimePeriod={closedJobTimePeriod.value}
                    showEmptyStatuses={showEmptyStatuses.value}
                    hiddenDisplayPropertySettings={
                      hiddenDisplayPropertySettings
                    }
                    isLoading={
                      lifecycleQuery[0].fetching || isJobFiltersLoading
                    }
                    onSearch={setSearchTerm}
                    onLifecycleChange={setSelectedLifecycleName}
                    onFilterDrawerOpenToggle={() =>
                      setJobLifecycleFilterDrawerOpen(true)
                    }
                    onFiltersClear={clearAll}
                    onBoardViewTypeChange={viewType => {
                      kanbanView.set(viewType)

                      setScrollPos({ offsetTop: 0, offsetLeft: 0 })
                    }}
                    onClosedJobTimePeriodChange={timePeriod =>
                      closedJobTimePeriod.set(timePeriod)
                    }
                    onShowEmptyStatusesChange={newStatus =>
                      showEmptyStatuses.set(newStatus)
                    }
                    onColumnPropertiesChange={newSettings => {
                      setColumnPropertiesSettings(newSettings)
                      setColumnPropertySettings(newSettings)
                    }}
                    onDisplayPropertiesChange={newSettings => {
                      setDisplayPropertiesSettings(newSettings)
                      setDisplayPropertySettings(newSettings)
                    }}
                  />

                  <Divider />

                  <div className="flex flex-col">
                    <div className="flex w-full flex-row items-center">
                      <div className="flex-1">
                        <FilterTagGroupListV2
                          filters={filtersV2}
                          labels={filtersV2Config
                            .filter(curr => curr.type === 'options')
                            .reduce<{ [key: string]: string }>((acc, curr) => {
                              acc[curr.key] = curr.label
                              return acc
                            }, {})}
                          onClick={removeFilter}
                        />
                      </div>
                      {filtersV2.length > 0 && (
                        <div className="flex flex-row flex-wrap items-center space-x-2 self-start">
                          {selectedJobsView.jobLifecycleViewGuid ===
                          DEFAULT_JOBS_VIEWS_GUID ? (
                            <Button
                              disabled={jobsViewsIsLoading}
                              className="font-semibold text-bz-primary"
                              onClick={() =>
                                setCreateJobLifecycleViewModalOpen(true)
                              }
                            >
                              Save View
                            </Button>
                          ) : (
                            <>
                              {isViewDirty && (
                                <Button
                                  disabled={jobsViewsIsLoading}
                                  type="link"
                                  className="px-0"
                                  onClick={clearChanges}
                                >
                                  Clear changes
                                </Button>
                              )}
                              <Button
                                disabled={jobsViewsIsLoading}
                                onClick={() =>
                                  setCreateJobLifecycleViewModalOpen(true)
                                }
                              >
                                Save View As
                              </Button>
                              {isViewDirty && (
                                <Button
                                  disabled={jobsViewsIsLoading}
                                  type="primary"
                                  onClick={updateView}
                                >
                                  Save View
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {filters.length > 0 && <Divider />}

                  <GqlQueryLoader
                    query={[jobsQuery, jobsQueryRefetch]}
                    render={() => (
                      <JobsView
                        kanbanView={kanbanView.value}
                        board={board}
                        jobs={filteredV2}
                        lifecycles={lifecycles}
                        hideEmptyStatuses={!showEmptyStatuses.value}
                        disabled={
                          changeStatusMutation.isLoading ||
                          jobsQuery.fetching ||
                          !canManageOfficeJobs
                        }
                        isLoading={
                          changeStatusMutation.isLoading || jobsQuery.fetching
                        }
                        onChange={onKanbanChange}
                        onCardClick={onCardClick}
                        onEditClicked={onEditJob}
                        onCreateLinkedJobClicked={onCreateLinkedJob}
                        onChangeJobType={onChangeJobType}
                      />
                    )}
                  />
                </Card>

                {createJobLifecycleViewModalOpen && (
                  <CreateJobLifecycleViewModal
                    viewToEdit={{
                      view: {
                        filtersV2: filtersToQueryString(filtersV2),
                        lifecycleName: selectedLifecycleName,
                      },
                    }}
                    onClose={() => setCreateJobLifecycleViewModalOpen(false)}
                  />
                )}

                <JobLifecycleFilterDrawerV2
                  data={data}
                  tzId={tzId}
                  open={jobLifecycleFilterDrawerOpen}
                  hiddenFilterKeys={hiddenFilters}
                  onClose={() => setJobLifecycleFilterDrawerOpen(false)}
                />

                {!isNullish(kanbanJobForJobOutcomesModal) && ( // This conditional lets us assert the TS typing as non-null
                  <JobOutcomesModal
                    open={!isNullish(kanbanJobForJobOutcomesModal)}
                    job={mapKanbanJobToJobOutcomesModalJob(
                      kanbanJobForJobOutcomesModal,
                    )}
                    onOk={onJobOutcomesModalOk}
                    onCancel={onJobOutcomesModalClose}
                  />
                )}

                <EditJobModal
                  open={!isNullish(jobToEdit)}
                  jobGuid={jobToEdit ?? ''}
                  onClose={() => setJobToEdit(null)}
                  onJobUpdateSuccess={() => {
                    try {
                      jobsQueryRefetch()
                      message.success('Updated job!')
                    } catch (err) {
                      console.error(err)
                    }

                    setJobToEdit(null)
                  }}
                />

                <ChangeJobTypeModal
                  open={changeJobTypeModalOpen}
                  onClose={() => setChangeJobTypeModalOpen(false)}
                />

                <CreateLinkedJobConfirmationModal
                  open={!isNullish(createLinkedJobModalConfig)}
                  config={createLinkedJobModalConfig}
                  onProceedWithCreateLinkedJobWorkflow={async (
                    job,
                    newStatus,
                  ) => {
                    setCreateLinkedJobModalConfig(null)

                    try {
                      await updateJobStatus(
                        job.job.jobGuid,
                        newStatus.jobLifecycleStatusGuid,
                      )

                      onCreateLinkedJob({
                        accountGuid: job.job.account.accountGuid,
                        locationGuid: job.job.location.locationGuid,
                        linkedJobGuid: job.job.jobGuid,
                      })
                    } catch (err) {
                      console.error(err)
                      message.error("Failed to update job's lifecycle status")
                    }
                  }}
                  onProceedWithoutCreateLinkedJobWorkflow={(job, newStatus) => {
                    setCreateLinkedJobModalConfig(null)
                    updateJobStatus(
                      job.job.jobGuid,
                      newStatus.jobLifecycleStatusGuid,
                    )
                  }}
                  onCancel={() => setCreateLinkedJobModalConfig(null)}
                />
              </>
            )
          }}
        />
      </JobKanbanContext.Provider>
    </Page>
  )
})

export default JobsPageV2
