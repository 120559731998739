import { DocumentType, gql } from '../../generated'

export const JOBS_PAGE_ALL_TAGS_QUERY = gql(/* GraphQL */ `
  query JobsPageAllTagsQuery($companyGuid: uuid!) {
    tags(
      where: { companyGuid: { _eq: $companyGuid } }
      orderBy: { name: ASC }
    ) {
      ...TagMinimal
    }
  }
`)

export const JOB_LIFECYCLES_QUERY = gql(/* GraphQL */ `
  query JobsPageJobLifecyclesQuery($companyGuid: uuid!) {
    jobLifecycles(where: { companyGuid: { _eq: $companyGuid } }) {
      sequence
      lastModifiedBy
      name
      updatedAt
      companyGuid
      createdAt
      description
      jobLifecycleGuid
      isArchived
      sequence
      jobLifecycleType
      statuses: jobLifecycleStatuses {
        color
        companyGuid
        description
        isDefault
        isLeadWon
        isWorkComplete
        isFirstAppointmentBookedAutomationEnabled
        isAllOutstandingAppointmentsAssignedAutomationEnabled
        isTechnicianDispatchedAutomationEnabled
        isTechnicianArrivesAtAppointmentAutomationEnabled
        isAllAppointmentsCompletedAutomationEnabled
        isCreateLinkedJobAutomationEnabled
        isNoAdditionalWorkRequiredAutomationEnabled
        jobLifecycleGuid
        jobLifecycleStatusGuid
        name
        stage
        specialStatus
        jobsAggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`)

export type JobLifecycle = DocumentType<
  typeof JOB_LIFECYCLES_QUERY
>['jobLifecycles'][number]

export const COMPANY_USERS_QUERY = gql(/* GraphQL */ `
  query JobsPageV2CompanyUsersQuery($companyGuid: uuid!) {
    companyUsers(where: { companyGuid: { _eq: $companyGuid } }) {
      userByUserGuid {
        userGuid
        fullName
      }
    }
  }
`)

export const COMPANY_USERS_SINGLE_QUERY = gql(/* GraphQL */ `
  query JobsPageV2CompanyUsersSingleQuery(
    $companyGuid: uuid!
    $userGuid: uuid!
  ) {
    companyUsers(
      where: {
        companyGuid: { _eq: $companyGuid }
        userGuid: { _eq: $userGuid }
      }
    ) {
      userByUserGuid {
        userGuid
        fullName
      }
    }
  }
`)

export const COMPANY_CONFIG_QUERY = gql(/* GraphQL */ `
  query JobPageV2CompanyConfigQuery($companyGuid: uuid!) {
    companyConfigByPk(companyGuid: $companyGuid) {
      companyGuid
      accountManagerEnabled
    }
  }
`)
