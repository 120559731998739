import {
  R,
  dateRangeToUnitAndN,
  getDateGroupingTypeForRange,
} from '@breezy/shared'
import { faArrowTrendUp } from '@fortawesome/pro-light-svg-icons'
import React, { useCallback, useMemo } from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Card } from '../../elements/Card/Card'
import { LineChart, LineData } from '../../elements/Charts/LineChart'
import { Link } from '../../elements/Link/Link'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import {
  MaintenancePlanWizard,
  useMaintenancePlanWizardFlags,
} from '../CreateOrEditMaintenancePlanPage/MaintenancePlanWizard'
import { NoDataOverlay } from './NoDataOverlay'
import {
  StandardReportingDateRangePicker,
  useStandardReportingDateRangePickerState,
} from './ReportingDateRangePicker/StandardReportingDateRangePicker'
import { DUMMY_MAINTENANCE_PLAN_DATA } from './dummyBackgroundData'
import { getStandardXAxisValue, useStandardXAxisFormatter } from './utils'

const Y_AXIS_FORMATTER = (value: number) => value.toLocaleString('en-us')
const RIGHT_Y_AXIS_FORMATTER = (value: number) =>
  value.toLocaleString('en-us', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: Number.isInteger(value) ? 0 : 2,
  })

type MaintenancePlansTrendsWidgetProps = {
  // Not optional. I'm expecting at least a fixed height class
  className: string
}
export const MaintenancePlansTrendsWidget =
  React.memo<MaintenancePlansTrendsWidgetProps>(({ className }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const [dateRange, setDateRange] =
      useStandardReportingDateRangePickerState('Last 6 months')

    const queryParams = useMemo(
      () => dateRangeToUnitAndN(tzId, dateRange),
      [dateRange, tzId],
    )

    const maintenancePlanReportQuery = trpc.maintenancePlans[
      'maintenance-plans:report:periodic-summary'
    ].useQuery({ ...queryParams, timePastN: queryParams.timePastN })

    const hasData = useMemo(() => {
      for (const bucket of R.values(maintenancePlanReportQuery.data ?? {})) {
        if (bucket.numActivePlans > 0) {
          return true
        }
      }
      return false
    }, [maintenancePlanReportQuery.data])

    const [xAxisValues, revenueData, customersData] = useMemo(() => {
      const data =
        hasData && maintenancePlanReportQuery.data
          ? maintenancePlanReportQuery.data
          : DUMMY_MAINTENANCE_PLAN_DATA

      // Normally I don't condone the use of .sort(), but it's too convenient here. We have a list of strings we just
      // made and want them sorted in alphabetical order.
      const orderedBucketKeys = R.keys(data).sort()

      const xAxisValues: string[] = []
      const revenueData: number[] = []
      const customersData: number[] = []

      const groupingType = getDateGroupingTypeForRange(dateRange, tzId)

      for (let i = 0; i < orderedBucketKeys.length; ++i) {
        const bucketTimestamp = orderedBucketKeys[i]
        xAxisValues.push(
          getStandardXAxisValue(tzId, bucketTimestamp, groupingType),
        )
        revenueData.push(data[bucketTimestamp].totalRecurringMonthlyRevenue)
        customersData.push(data[bucketTimestamp].numActivePlans)
      }

      return [xAxisValues, revenueData, customersData]
    }, [dateRange, hasData, maintenancePlanReportQuery.data, tzId])

    const lineChartData = useMemo<LineData[]>(
      () => [
        {
          label: 'Active Plans',
          data: customersData,
          renderTooltipValue: (value: number) => value.toLocaleString(),
        },
        {
          label: 'Recurring Monthly Revenue',
          data: revenueData,
          rightAxis: true,
          renderTooltipValue: RIGHT_Y_AXIS_FORMATTER,
        },
      ],
      [customersData, revenueData],
    )

    const xAxisFormatter = useStandardXAxisFormatter(dateRange, tzId)

    const hasNoDataOverlay = !hasData
    const [
      maintenancePlanWizardOpen,
      openMaintenancePlanWizard,
      closeMaintenancePlanWizard,
    ] = useMaintenancePlanWizardFlags('mpw', 'maintenance-plans-trends-empty')

    const onCloseMpWizard = useCallback(() => {
      closeMaintenancePlanWizard()
      maintenancePlanReportQuery.refetch()
    }, [closeMaintenancePlanWizard, maintenancePlanReportQuery])
    return (
      <Card
        title="Maintenance Plan Trends"
        className={className}
        titleAction={
          !hasNoDataOverlay && (
            <StandardReportingDateRangePicker
              range={dateRange}
              setRange={setDateRange}
            />
          )
        }
      >
        {maintenancePlanReportQuery.isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <LineChart
              startAtZero={false}
              data={lineChartData}
              yAxisFormatter={Y_AXIS_FORMATTER}
              xAxisValues={xAxisValues}
              xAxisFormatter={xAxisFormatter}
              yAxisRightFormatter={RIGHT_Y_AXIS_FORMATTER}
            />

            {hasNoDataOverlay && (
              <NoDataOverlay
                className="inset-[-16px]"
                icon={faArrowTrendUp}
                title="No maintenance plan data"
                link={
                  <Link onClick={openMaintenancePlanWizard}>
                    Create Maintenance Plan
                  </Link>
                }
              >
                <div className="text-center">
                  Revenue and number of active plans will be displayed here once
                  a customer has purchased a maintenance plan.
                </div>
              </NoDataOverlay>
            )}
          </>
        )}
        {maintenancePlanWizardOpen && (
          <MaintenancePlanWizard
            onRamp="maintenance-plans-trends-empty"
            onClose={onCloseMpWizard}
          />
        )}
      </Card>
    )
  })
