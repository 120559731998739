import { getDateGroupingTypeForRange } from '@breezy/shared'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Card } from '../../elements/Card/Card'
import { LineChart, LineData } from '../../elements/Charts/LineChart'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import {
  AngiSmallLogo,
  BBBSmallLogo,
  FacebookLogo,
  GoogleSmallLogo,
  YelpSmallLogo,
} from './CustomerReviewsWidget'
import {
  StandardReportingDateRangePicker,
  useStandardReportingDateRangePickerState,
} from './ReportingDateRangePicker/StandardReportingDateRangePicker'
import { getStandardXAxisValue, useStandardXAxisFormatter } from './utils'

const Y_AXIS_DOMAIN = [0, 5]
const Y_AXIS_TICKS = [0, 1, 2, 3, 4, 5]

const tooltipValueRenderer = (value: number) => (
  <div className="flex flex-row items-center">
    <FontAwesomeIcon
      icon={faStar}
      className="mr-1 h-4 w-4 text-bz-yellow-600"
    />
    <div className="text-base font-semibold">{value.toFixed(1)}</div>
  </div>
)

type CustomerReviewTrendsWidgetProps = {
  className?: string
}
export const CustomerReviewTrendsWidget =
  React.memo<CustomerReviewTrendsWidgetProps>(({ className }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const [dateRange, setDateRange] = useStandardReportingDateRangePickerState()

    const { data, isLoading } = trpc.reporting['customer-reviews:get'].useQuery(
      {
        dateRange,
      },
      {
        refetchOnWindowFocus: false,
      },
    )

    const groupingType = getDateGroupingTypeForRange(dateRange, tzId)

    const xAxisValues = useMemo(() => {
      return (data?.trendData ?? []).map(datum =>
        getStandardXAxisValue(tzId, datum.date, groupingType),
      )
    }, [data, groupingType, tzId])

    const lineChartData = useMemo<LineData[]>(() => {
      const lineData: LineData[] = [
        {
          label: 'Average',
          color: '#722ED1',
          dashed: true,
          data: data?.trendData.map(data => data.value) ?? [],
          renderTooltipValue: tooltipValueRenderer,
        },
      ]

      if (data?.google.totalReviews) {
        lineData.push({
          label: 'Google',
          color: '#52C41A',
          formattedLabel: (
            <div>
              <GoogleSmallLogo className="mt-[-2px]" /> Google
            </div>
          ),
          data: data?.google.trendData.map(data => data.value) ?? [],
          renderTooltipValue: tooltipValueRenderer,
        })
      }

      if (data?.yelp.totalReviews) {
        lineData.push({
          label: 'Yelp',
          color: '#F5222D',
          formattedLabel: (
            <div>
              <YelpSmallLogo className="mt-[-2px]" /> Yelp
            </div>
          ),
          data: data?.yelp.trendData.map(data => data.value) ?? [],
          renderTooltipValue: tooltipValueRenderer,
        })
      }
      if (data?.facebook.totalReviews) {
        lineData.push({
          label: 'Facebook',
          color: '#1677FF',
          formattedLabel: (
            <div>
              <FacebookLogo className="mt-[-2px]" /> Facebook
            </div>
          ),
          data: data?.facebook.trendData.map(data => data.value) ?? [],
          renderTooltipValue: tooltipValueRenderer,
        })
      }
      if (data?.angi.totalReviews) {
        lineData.push({
          label: 'Angi',
          color: '#FA8C16',
          formattedLabel: (
            <div>
              <AngiSmallLogo className="mt-[-2px]" /> Angi
            </div>
          ),
          data: data?.angi.trendData.map(data => data.value) ?? [],
          renderTooltipValue: tooltipValueRenderer,
        })
      }
      if (data?.bbb.totalReviews) {
        lineData.push({
          label: 'BBB',
          color: '#13C2C2',
          formattedLabel: (
            <div>
              <BBBSmallLogo className="mt-[-2px]" /> BBB
            </div>
          ),
          data: data?.bbb.trendData.map(data => data.value) ?? [],
          renderTooltipValue: tooltipValueRenderer,
        })
      }
      return lineData
    }, [data])

    const xAxisFormatter = useStandardXAxisFormatter(dateRange, tzId)

    return (
      <Card
        title="Customer Review Trends"
        className={classNames('h-[366px] min-h-[366px]', className)}
        titleAction={
          <StandardReportingDateRangePicker
            range={dateRange}
            setRange={setDateRange}
          />
        }
      >
        {isLoading ? (
          <LoadingSpinner />
        ) : data && data.reviews.length ? (
          <div className="flex h-full flex-col">
            <LineChart
              showLegend
              truncateLeadingZeros
              overflowHidden={false}
              data={lineChartData}
              xAxisValues={xAxisValues}
              xAxisFormatter={xAxisFormatter}
              yAxisDomain={Y_AXIS_DOMAIN}
              yAxisTicks={Y_AXIS_TICKS}
            />
          </div>
        ) : (
          <div className="flex flex-1 items-center justify-center text-base font-semibold">
            <div>No reviews in your chosen time period.</div>
          </div>
        )}
      </Card>
    )
  })
