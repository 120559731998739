import { faCheck, faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown } from 'antd'
import { useCallback, useMemo } from 'react'
import { typedMemo } from '../../utils/react-utils'
import './ListPageFilter.less'

export type ListPageFilterOption<T extends string = string> = {
  key: T
  label?: string
}

type SelectedMap<T extends string> = Partial<Record<T, boolean>>

type ListPageFilterProps<T extends string> = (
  | {
      multi: true
      value: T[]
      valueLabel?: never
      onChange: (selectedValues: T[]) => void
      label: string
    }
  | {
      multi?: false
      value: T
      valueLabel?: string
      onChange: (selectedValue: T) => void
      label?: string
    }
) & {
  options: ListPageFilterOption<T>[]
  disabled?: boolean
}

export const ListPageFilter = typedMemo(
  <T extends string>({
    multi,
    label,
    options,
    onChange,
    value,
    disabled,
    valueLabel,
  }: ListPageFilterProps<T>) => {
    const selectedMap = useMemo(
      () =>
        multi
          ? value.reduce(
              (map, key) => ({ ...map, [key]: true }),
              {} as SelectedMap<T>,
            )
          : // Unfortunate we kind of have to cheat here. TypeScript thinks this is `{ [x: string]: boolean; }`, even
            // though `value` is type `T`, and it says `{ [x: string]: boolean; }` and `SelectedMap<T>` are
            // incompatible.
            ({ [value]: true } as SelectedMap<T>),
      [multi, value],
    )

    const toggle = useCallback(
      (key: T) => {
        if (multi) {
          const newMap = {
            ...selectedMap,
            [key]: !selectedMap[key],
          }
          onChange(
            options.reduce(
              (list, { key }) => (newMap[key] ? [...list, key] : list),
              [] as T[],
            ),
          )
        } else {
          onChange(key)
        }
      },
      [multi, onChange, options, selectedMap],
    )

    const selectedKeys = useMemo(
      () => (multi ? value : [value]),
      [multi, value],
    )

    return (
      <Dropdown
        disabled={disabled}
        autoAdjustOverflow
        overlayClassName="list-page-filter-dropdown"
        menu={{
          selectable: true,
          multiple: multi,
          onClick: ({ key }) => toggle(key as T),
          selectedKeys,
          items: options.map(({ key, label }) => ({
            key,
            label: (
              <div className="flex flex-row items-center">
                <div className="flex-1">{label ?? key}</div>
                {selectedMap[key] && multi && (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="ml-4 text-[#1677FF]"
                  />
                )}
              </div>
            ),
          })),
        }}
      >
        <Button size="large">
          {multi ? label : label ?? valueLabel ?? value}{' '}
          <FontAwesomeIcon
            icon={faChevronDown}
            className="ml-2 align-baseline text-[10px] text-bz-gray-900"
          />
        </Button>
      </Dropdown>
    )
  },
)
