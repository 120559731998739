import { Tailwind as ReactEmailTailwind } from '@react-email/components'
import React, { PropsWithChildren } from 'react'

// TODO: consolidate this with the tailwind config in the web app
const Tailwind = React.memo<PropsWithChildren>(({ children }) => {
  return (
    <ReactEmailTailwind
      config={{
        theme: {
          extend: {
            colors: {
              'daybreak-blue': {
                100: '#E6F7FF',
                200: '#BAE7FF',
                300: '#91D5FF',
                400: '#69c0ff',
                500: '#40a9ff',
                600: '#1890FF',
                700: '#096DD9',
                800: '#0050B3',
                900: '#003A8C',
                1000: '#002766',
              },
              'bz-cyan': {
                100: '#e6fffb',
                200: '#b5f5ec',
                300: '#87e8de',
                400: '#5cdbd3',
                500: '#36cfc9',
                600: '#13c2c2',
                700: '#08979c',
                800: '#006d75',
                900: '#00474f',
                1000: '#002329',
              },
              'bz-orange': {
                100: '#fff7e6',
                200: '#ffe7ba',
                300: '#ffd591',
                400: '#ffc069',
                500: '#ffa940',
                600: '#fa8c16',
                700: '#d46b08',
                800: '#ad4e00',
                900: '#873800',
                1000: '#612500',
              },
              'bz-green': {
                100: '#F6FFED',
                200: '#D9F7BE',
                300: '#B7EB8F',
                400: '#95DE64',
                500: '#73D13D',
                600: '#52C41A',
                700: '#389E0D',
                800: '#237804',
                900: '#135200',
                1000: '#092B00',
              },
              'bz-yellow': {
                100: '#FFFFE6',
                200: '#FFFFB8',
                300: '#FFFB8F',
                400: '#FFF566',
                500: '#FFEC3D',
                600: '#FADB14',
                700: '#D4B106',
                800: '#AD8B00',
                900: '#876800',
                1000: '#614700',
              },
              'bz-lime': {
                100: '#FCFFE6',
                200: '#F4FFB8',
                300: '#EAFF8F',
                400: '#D3F261',
                500: '#BAE637',
                600: '#A0D911',
                700: '#7CB305',
                800: '#5B8C00',
                900: '#3F6600',
                1000: '#254000',
              },
              'bz-gray': {
                100: '#FFFFFF',
                200: '#FAFAFA',
                300: '#F5F5F5',
                400: '#F0F0F0',
                500: '#D9D9D9',
                600: '#BFBFBF',
                700: '#8C8C8C',
                800: '#595959',
                900: '#434343',
                1000: '#262626',
              },
              'bz-purple': {
                100: '#FAF5FF',
                200: '#F3E8FF',
                300: '#E9D5FF',
                400: '#C084FC',
                500: '#A855F7',
                600: '#9333EA',
                700: '#7E22CE',
                800: '#6B21A8',
                900: '#581C87',
              },
              'bz-magenta': {
                100: '#FFF0F6',
                200: '#FFD6E7',
                300: '#FFADD2',
                400: '#FF85C0',
                500: '#F759AB',
                600: '#EB2F96',
                700: '#C41D7F',
                800: '#9E1068',
                900: '#780650',
                1000: '#520339',
              },
              'bz-red': {
                100: '#FFF1F0',
                200: '#FFCCC7',
                300: '#FFA39E',
                400: '#FF7875',
                500: '#FF4D4F',
                600: '#F5222D',
                700: '#CF1322',
                800: '#A8071A',
                900: '#820014',
                1000: '#5C0011',
              },
            },
          },
          fontFamily: {
            body: [
              'SF Pro Text',
              '-apple-system',
              'BlinkMacSystemFont',
              'Roboto',
              'Segoe UI',
              'Helvetica',
              'Arial',
              'sans-serif',
              'Apple Color Emoji',
              'Segoe UI Emoji',
              'Segoe UI Symbol',
            ],
          },
        },
      }}
    >
      {children}
    </ReactEmailTailwind>
  )
})

export default Tailwind
