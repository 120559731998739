import { Tooltip } from 'antd'
import { Styled } from '../../utils/Stylable'

const WithMaybeTooltip = ({
  children,
  tooltip,
}: {
  children: React.ReactNode
  tooltip?: string
}) => {
  return tooltip ? (
    <Tooltip title={tooltip}>{children}</Tooltip>
  ) : (
    <>{children}</>
  )
}

type CircleButtonProps = {
  children: React.ReactNode
  href?: string
  tooltip?: string
  onClick?: () => void | Promise<void>
}

export const CircleButton = ({
  children,
  href,
  tooltip,
  className,
  style,
  onClick,
}: Styled<CircleButtonProps>) => {
  return (
    <div
      className={`my-auto mr-[12px] flex h-[36px] max-h-[36px] w-[36px] items-center justify-center rounded-full pt-[2px] hover:bg-[#f0f0f0] active:bg-[#f0f0f0] ${className} ${
        onClick ? 'cursor-pointer' : ''
      }`}
      // Style Necessary since Tailwind coloring all comes through as !important and prevents overrides
      style={{ backgroundColor: '#fff', border: '1px solid #D9D9D9', ...style }}
      onClick={onClick}
    >
      {href ? (
        <a href={href} target="_blank" rel="noreferrer">
          <WithMaybeTooltip tooltip={tooltip}>{children}</WithMaybeTooltip>
        </a>
      ) : (
        <div>
          <WithMaybeTooltip tooltip={tooltip}>{children}</WithMaybeTooltip>
        </div>
      )}
    </div>
  )
}
