import { DocumentType, gql } from '../../generated'

export const GET_RECENT_PHONE_CALLS_SUB = gql(/* GraphQL */ `
  subscription GetRecentPhoneCalls($companyGuid: uuid!) {
    integratedPhoneCalls(
      limit: 500
      orderBy: { startedAt: DESC }
      where: { companyGuid: { _eq: $companyGuid } }
    ) {
      integratedPhoneCallGuid
      startedAt
      endedAt
      completedAt
      contact {
        firstNameInitial
        contactGuid
        fullName
        firstName
        lastName
        accountContacts {
          account {
            accountDisplayName
            accountGuid
          }
        }
      }
      accountGuid
      integratedPhone {
        phoneNumber
        companyLeadSource {
          companyLeadSourceGuid
          canonicalLeadSourceName
          canonicalLeadSourceNameOverride
        }
      }
      integratedPhoneCallStatuses(orderBy: { occurredAt: DESC }, limit: 1) {
        status
      }
      integratedPhoneMissedCalls {
        integratedPhoneMissedCallGuid
        fallbackStrategyUsed
        integratedPhoneMissedCallDismissals {
          dismissedAt
        }
        integratedPhoneVoicemails {
          recordingTranscript
          recordingUrl
          recordingDurationSeconds
        }
      }
      integratedPhoneCallRecording {
        recordingUrl
        recordingTranscript
        recordingDurationSeconds
      }
      fromPhoneNumber
      toPhoneNumber
      direction
      user {
        userGuid
        firstName
        lastName
      }
    }
  }
`)

export type RecentPhoneCall = NonNullable<
  DocumentType<typeof GET_RECENT_PHONE_CALLS_SUB>['integratedPhoneCalls']
>[0]

export type RecentPhoneCallContact = NonNullable<RecentPhoneCall['contact']>
export type RecentPhoneCallStatus = NonNullable<
  RecentPhoneCall['integratedPhoneCallStatuses']
>[0]

export type RecentPhoneCallMissedCall = NonNullable<
  RecentPhoneCall['integratedPhoneMissedCalls']
>[0]

export type RecentPhoneCallVoicemail = NonNullable<
  RecentPhoneCallMissedCall['integratedPhoneVoicemails']
>[0]

export type RecentPhoneCallUser = NonNullable<RecentPhoneCall['user']>

export const DISMISS_MISSED_CALL = gql(`
  mutation DismissMissedCall($obj: IntegratedPhoneMissedCallDismissalsInsertInput!) {
    insertIntegratedPhoneMissedCallDismissalsOne(object: $obj) {
      __typename
    }
  }
`)

export type DismissMissedCallMutation = DocumentType<typeof DISMISS_MISSED_CALL>
export type DismissMissedCallMutationVariables = {
  missedCallGuid: string
}

export const DELETE_MISSED_CALL_DISMISSALS = gql(`
  mutation DeleteMissedCallDismissals($integratedPhoneMissedCallGuid: uuid!) {
    deleteIntegratedPhoneMissedCallDismissals(
      where: { integratedPhoneMissedCallGuid: { _eq: $integratedPhoneMissedCallGuid } }
    ) {
      affectedRows
    }
  }
`)

export type DeleteMissedCallDismissalsMutation = DocumentType<
  typeof DELETE_MISSED_CALL_DISMISSALS
>
export type DeleteMissedCallDismissalsMutationVariables = {
  integratedPhoneMissedCallGuid: string
}

export const COMPLETE_CALLS = gql(`
  mutation CompleteCalls($completedAt: timestamptz!, $completedByUserGuid: uuid!, $integratedPhoneCallGuids: [uuid!]!) {
    updateIntegratedPhoneCalls(
      _set: { 
        completedAt: $completedAt, 
        completedByUserGuid: $completedByUserGuid 
      }
      where: { integratedPhoneCallGuid: { _in: $integratedPhoneCallGuids } }
    ) {
      affectedRows
    }
  }
`)

export type CompleteCallsMutation = DocumentType<typeof COMPLETE_CALLS>
export type CompleteCallsMutationVariables = {
  integratedPhoneCallGuids: string[]
  completedAt: string
  completedByUserGuid: string
}

export const UNCOMPLETE_CALL = gql(`
  mutation UncompleteCall($integratedPhoneCallGuid: uuid!) {
    updateIntegratedPhoneCalls(
      _set: { 
        completedAt: null, 
        completedByUserGuid: null 
      }
      where: { integratedPhoneCallGuid: { _eq: $integratedPhoneCallGuid } }
    ) {
      affectedRows
    }
  }
`)

export type UncompleteCallMutation = DocumentType<typeof UNCOMPLETE_CALL>
export type UncompleteCallMutationVariables = {
  integratedPhoneCallGuid: string
}
