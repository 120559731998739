import {
  formatPercentage,
  formatUscWholeDollars,
  usdToUsCents,
} from '@breezy/shared'
import { Col, Row } from 'antd'
import { memo, useMemo } from 'react'
import { Tile } from '../Tile'
import { TeamPerformanceDivider } from './TeamPerformanceDivider'
import { TeamPerformanceJob } from './team-performance-queries.gql'

type TechGeneratedLeadsTileProps = {
  jobs: TeamPerformanceJob[]
  loading: boolean
}

export const TechGeneratedLeadsTile = memo<TechGeneratedLeadsTileProps>(
  ({ jobs, loading }) => {
    const jobsTurnedOverByTechnicians = useMemo(() => {
      return jobs.filter(job => {
        if (job.jobTeamMembers.some(teamMember => teamMember.turnedOverJob)) {
          return true
        } else {
          return false
        }
      })
    }, [jobs])

    const revenueFromTechGeneratedLeadsUsd = useMemo(() => {
      return jobsTurnedOverByTechnicians.reduce((acc, job) => {
        return (
          acc + (job.jobFullyPaidInvoicesV2Summary?.totalPaidAmountUsd ?? 0)
        )
      }, 0)
    }, [jobsTurnedOverByTechnicians])

    const closeRate = useMemo(() => {
      const convertedTechGeneratedLeads = jobsTurnedOverByTechnicians.filter(
        job => job.isConverted,
      )

      if (jobsTurnedOverByTechnicians.length === 0) {
        return 0
      } else {
        return (
          convertedTechGeneratedLeads.length /
          jobsTurnedOverByTechnicians.length
        )
      }
    }, [jobsTurnedOverByTechnicians])

    return (
      <Tile title="Tech Generated Leads" loading={loading}>
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">Number of Leads</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {jobsTurnedOverByTechnicians.length}
          </Col>
        </Row>
        <TeamPerformanceDivider />
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">Close Rate</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {formatPercentage(closeRate)}
          </Col>
        </Row>
        <TeamPerformanceDivider />
        <Row justify="space-between">
          <Col className="h-5.5 text-[16px] font-normal">Revenue Generated</Col>
          <Col className="flex h-8 flex-col justify-center text-base">
            {formatUscWholeDollars(
              usdToUsCents(revenueFromTechGeneratedLeadsUsd),
            )}
          </Col>
        </Row>
      </Tile>
    )
  },
)
