import { Form, Switch } from 'antd'

export const PricebookFormItemTaxable = () => (
  <Form.Item>
    <Form.Item name="isTaxable" valuePropName="checked" noStyle>
      <Switch id="isTaxable" />
    </Form.Item>
    <label htmlFor="isTaxable" className="ml-2 font-semibold">
      Item is taxable
    </label>
  </Form.Item>
)
