import { StarOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-components'
import { CompanyDripMarketingConfigInsertInput } from '@breezy/backend/src/query'
import {
  DEFAULT_JOB_COMPLETED_CUSTOM_EMAIL_CONTENT,
  DEFAULT_MAINTENANCE_REMINDER_CUSTOM_EMAIL_CONTENT,
  generateJobCompletionEmailContent,
  generateMaintenanceReminderEmailContent,
  isNullish,
} from '@breezy/shared'
import { faEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Card, Form, Tag } from 'antd'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'urql'
import {
  CategorySection,
  CheckField,
  SubSection,
} from '../../components/Settings/SettingsBuilderComponents'
import { WysiwygEditor } from '../../components/WysiwygEditor/WysiwygEditor'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../elements/Forms/SelectField'
import { TextField } from '../../elements/Forms/TextField'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { UPSERT_COMPANY_CONFIG_DRIP_MARKETING_SETTINGS_MUTATION } from '../../gql/CompanyConfig.gql'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { BasicBrandedEmailPreviewModal } from './BasicBrandedEmailPreviewModal'
import { COMPANY_DRIP_MARKETING_CONFIG_QUERY } from './DripMarketingSettingsPage.gql'
import {
  DripMarketingSettingsFormData,
  dripMarketingSettingsFormSchema,
} from './dripMarketingSettingsFormSchema'

/**
 * ! Currently, this page and related functionality is presented as "Job Follow-up Emails" in the app rather than "Drip Marketing".
 * ! "Job Follow-Up" was a more appropriate name for the first version of this feature, but we didn't want to take
 * ! the time to refactor all the naming in the code, so we just made a text-change. In the future, this functionality may
 * ! expand to make the "Drip Marketing" name more appropriate.
 */
export const DripMarketingSettingsPage = memo(() => {
  const companyGuid = useExpectedCompanyGuid()
  const message = useMessage()
  const dripMarketingFeatureFLag = useFeatureFlag('dripMarketing')
  const [previewEmailContentHtml, setPreviewEmailContentHtml] = useState<
    string | null
  >(null)

  // Represents whether the Drip marketing config values have been loaded into react-hook-form for the first time
  const [
    loadedCompanyDripMarketingConfig,
    setLoadedCompanyDripMarketingConfig,
  ] = useState(false)

  const [{ fetching: fetchingDripMarketingConfig, data: dripMarketingConfig }] =
    useQuery({
      query: COMPANY_DRIP_MARKETING_CONFIG_QUERY,
      variables: {
        companyGuid,
      },
    })

  useEffect(() => {
    if (!loadedCompanyDripMarketingConfig && !fetchingDripMarketingConfig) {
      setLoadedCompanyDripMarketingConfig(true)
    }
  }, [fetchingDripMarketingConfig, loadedCompanyDripMarketingConfig])

  const {
    formState: { errors, isDirty },
    control,
    handleSubmit,
    reset,
    // setValue,
    watch,
  } = useForm<DripMarketingSettingsFormData>({
    resolver: zodResolver(dripMarketingSettingsFormSchema),
    // Doing `.parse({})` on our schema will give it to us with all the default values filled in.
    defaultValues: {
      ...dripMarketingSettingsFormSchema.parse({}),
    },
  })

  const jobCompletedEnabledValue = watch('jobCompletedEnabled')
  const maintenanceRemindersEnabledValue = watch('maintenanceRemindersEnabled')
  const yelpReviewUrlValue = watch('yelpReviewUrl')
  const googleReviewUrlValue = watch('googleReviewUrl')
  const facebookReviewUrlValue = watch('facebookReviewUrl')
  const jobCompletedCustomEmailContentValue = watch(
    'jobCompletedCustomEmailContent',
  )
  const maintenanceRemindersCustomEmailContent = watch(
    'maintenanceRemindersCustomEmailContent',
  )

  const jobCompletedFullEmailContent = useMemo(() => {
    return generateJobCompletionEmailContent({
      bodyContent: jobCompletedCustomEmailContentValue,
      customerName: '[Customer Name]',
      signOff: '[Your Technicians]',
      serviceLocation: '[Job Location]',
      jobDescription: '[Job Description]',
      jobDateAndTime: '[Job Date & Time]',
      yelpReviewUrl: yelpReviewUrlValue,
      googleReviewUrl: googleReviewUrlValue,
      facebookReviewUrl: facebookReviewUrlValue,
    })
  }, [
    facebookReviewUrlValue,
    googleReviewUrlValue,
    jobCompletedCustomEmailContentValue,
    yelpReviewUrlValue,
  ])

  const maintenanceRemindersFullEmailContent = useMemo(() => {
    return generateMaintenanceReminderEmailContent({
      bodyContent: maintenanceRemindersCustomEmailContent,
      customerName: '[Customer Name]',
      signOff: '[Your Technicians]',
    })
  }, [maintenanceRemindersCustomEmailContent])

  const [{ fetching: submitting }, executeMutation] = useMutation(
    UPSERT_COMPANY_CONFIG_DRIP_MARKETING_SETTINGS_MUTATION,
  )

  const onSubmit = handleSubmit(async data => {
    const convertedFormData: CompanyDripMarketingConfigInsertInput = {
      yelpReviewUrl: data.yelpReviewUrl,
      googleReviewUrl: data.googleReviewUrl,
      facebookReviewUrl: data.facebookReviewUrl,
      jobCompletedEnabled: data.jobCompletedEnabled,
      jobCompletedDelayMs: isNaN(Number(data.jobCompletedDelayMinutes))
        ? 0
        : Number(data.jobCompletedDelayMinutes) * 60 * 1000,
      jobCompletedCustomEmailContent: data.jobCompletedCustomEmailContent,
      maintenanceRemindersEnabled: data.maintenanceRemindersEnabled,
      maintenanceRemindersDelayMonths: isNaN(
        Number(data.maintenanceRemindersDelayMonths),
      )
        ? 6
        : Number(data.maintenanceRemindersDelayMonths),
      maintenanceRemindersCustomEmailContent:
        data.maintenanceRemindersCustomEmailContent,
    }

    try {
      await executeMutation({
        settings: {
          companyGuid,
          ...convertedFormData,
        },
      })

      message.success('Job Follow-up Email settings updated')
    } catch (e) {
      message.error('Failed to update Job Follow-up Email settings')
    }
  })

  useEffect(() => {
    if (dripMarketingConfig?.companyDripMarketingConfigByPk) {
      const convertedFormData: DripMarketingSettingsFormData = {
        yelpReviewUrl:
          dripMarketingConfig.companyDripMarketingConfigByPk.yelpReviewUrl,
        googleReviewUrl:
          dripMarketingConfig.companyDripMarketingConfigByPk.googleReviewUrl,
        facebookReviewUrl:
          dripMarketingConfig.companyDripMarketingConfigByPk.facebookReviewUrl,
        jobCompletedEnabled:
          dripMarketingConfig.companyDripMarketingConfigByPk
            .jobCompletedEnabled,
        jobCompletedDelayMinutes: String(
          dripMarketingConfig.companyDripMarketingConfigByPk
            .jobCompletedDelayMs /
            (1000 * 60),
        ),
        jobCompletedCustomEmailContent:
          dripMarketingConfig.companyDripMarketingConfigByPk
            .jobCompletedCustomEmailContent ??
          DEFAULT_JOB_COMPLETED_CUSTOM_EMAIL_CONTENT,
        maintenanceRemindersEnabled:
          dripMarketingConfig.companyDripMarketingConfigByPk
            .maintenanceRemindersEnabled,
        maintenanceRemindersDelayMonths: String(
          dripMarketingConfig.companyDripMarketingConfigByPk
            .maintenanceRemindersDelayMonths,
        ),
        maintenanceRemindersCustomEmailContent:
          dripMarketingConfig.companyDripMarketingConfigByPk
            .maintenanceRemindersCustomEmailContent ??
          DEFAULT_MAINTENANCE_REMINDER_CUSTOM_EMAIL_CONTENT,
      }

      reset(convertedFormData)
    }
  }, [dripMarketingConfig, reset])

  // const onClickJobCompletedCustomEmailContentResetToDefault =
  //   useCallback(() => {
  //     setValue(
  //       'jobCompletedCustomEmailContent',
  //       DEFAULT_JOB_COMPLETED_CUSTOM_EMAIL_CONTENT,
  //       {
  //         shouldDirty: true,
  //       },
  //     )
  //   }, [setValue])

  const onClickJobCompletedPreviewEmail = useCallback(() => {
    setPreviewEmailContentHtml(jobCompletedFullEmailContent)
  }, [jobCompletedFullEmailContent])

  const onClickMaintenanceReminderPreviewEmail = useCallback(() => {
    setPreviewEmailContentHtml(maintenanceRemindersFullEmailContent)
  }, [maintenanceRemindersFullEmailContent])

  if (!dripMarketingFeatureFLag) {
    return (
      <div className="flex w-full min-w-[700px] flex-col">
        <h1>Coming Soon </h1>
      </div>
    )
  }

  return (
    <>
      <div className="flex w-full min-w-[700px] flex-col">
        <PageHeader
          title={
            <PageTitle
              title={
                <div className="flex items-center space-x-2">
                  <span>Job Follow-Up Emails</span>
                  <Tag
                    icon={<StarOutlined />}
                    color="gold"
                    className="rounded-[12px] text-[0.9em]"
                  >
                    Beta
                  </Tag>
                </div>
              }
              icon={faEnvelopeOpen}
            />
          }
        />

        <Card className="min-h-0 w-full overflow-auto p-2">
          <Form onSubmitCapture={onSubmit} layout="vertical">
            <div className="flex flex-row items-start justify-between border-0 border-b border-solid border-b-bz-gray-400 pb-4">
              <div className="max-w-[768px] text-bz-gray-900">
                Configure and customize automated email campaigns that trigger
                when a job is completed to engage with your customers over time.
                Set up custom schedules, personalized messages, and targeted
                workflows that nurture leads and strengthen relationships.
                Harness the power of automation to stay top-of-mind and drive
                customer engagement seamlessly.
              </div>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                disabled={!isDirty}
              >
                Save Changes
              </Button>
            </div>
            <div className="divide-y divide-solid divide-bz-gray-400">
              <CategorySection
                title="Jobs"
                subtitle="Set up automated email campaigns for job-related activities"
                layout={{ leftContainerFlex: 1, rightContainerFlex: 2 }}
              >
                <SubSection
                  title="Job Feedback"
                  subtitle="This email campaign is triggered shortly after job completion, excluding Sales jobs. It helps nurture customer relationships and gather valuable feedback and reviews."
                >
                  <CheckField
                    defaultValue={false}
                    errors={errors}
                    name="jobCompletedEnabled"
                    control={control}
                    disabled={submitting}
                    label="Enable Job-Completion Email Campaign"
                  />

                  {jobCompletedEnabledValue && (
                    <>
                      <ReactHookFormItem
                        required
                        control={control}
                        name="jobCompletedDelayMinutes"
                        // label="Delay (in minutes) after job completion before sending the campaign:"
                        errors={errors}
                        render={({ field }) => (
                          <SelectField
                            showSearch={false}
                            options={[
                              { value: '0', label: '0 minutes' },
                              { value: '5', label: '5 minutes' },
                              { value: '15', label: '15 minutes' },
                              { value: '30', label: '30 minutes' },
                              { value: '60', label: '60 minutes' },
                            ]}
                            title="Delay (in minutes) after job completion before sending the campaign:"
                            {...field}
                          />
                        )}
                      />
                    </>
                  )}
                </SubSection>

                {jobCompletedEnabledValue && (
                  <>
                    <SubSection
                      title="Customer Review"
                      subtitle="Provide your company's review URLs to automatically include feedback links in customer emails."
                    >
                      <ReactHookFormItem
                        control={control}
                        name="yelpReviewUrl"
                        label="Yelp"
                        errors={errors}
                        render={({ field }) => (
                          <TextField
                            size="middle"
                            placeholder="Yelp URL"
                            className="min-w-[250px]"
                            {...field}
                          />
                        )}
                      />
                      <ReactHookFormItem
                        control={control}
                        name="googleReviewUrl"
                        label="Google"
                        errors={errors}
                        render={({ field }) => (
                          <TextField
                            size="middle"
                            placeholder="Google URL"
                            className="min-w-[250px]"
                            {...field}
                          />
                        )}
                      />
                      <ReactHookFormItem
                        control={control}
                        name="facebookReviewUrl"
                        label="Facebook"
                        errors={errors}
                        render={({ field }) => (
                          <TextField
                            size="middle"
                            placeholder="Facebook URL"
                            className="min-w-[250px]"
                            {...field}
                          />
                        )}
                      />
                    </SubSection>

                    <SubSection
                      title="Custom Email Content"
                      subtitle="Add extra content to the end of the email. This is not the entire email, but just a part of it. Click preview to see how your email will look."
                    >
                      {loadedCompanyDripMarketingConfig && (
                        <ReactHookFormItem
                          control={control}
                          name="jobCompletedCustomEmailContent"
                          label="Extra Email Content (HTML)"
                          errors={errors}
                          render={({ field }) => (
                            <WysiwygEditor
                              value={field.value}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      )}

                      <div className="mt-4 flex justify-end space-x-2">
                        {/* <Button
                          onClick={
                            onClickJobCompletedCustomEmailContentResetToDefault
                          }
                        >
                          Reset to Default Content
                        </Button> */}
                        <Button
                          type="primary"
                          onClick={onClickJobCompletedPreviewEmail}
                        >
                          Preview
                        </Button>
                      </div>
                    </SubSection>
                  </>
                )}
              </CategorySection>

              <CategorySection
                title="Maintenance Plans"
                subtitle="Set up automated email campaigns to remind customers about booking tune-up visits and promote your maintenance plan memberships."
                layout={{ leftContainerFlex: 1, rightContainerFlex: 2 }}
              >
                <SubSection
                  title="Post-Job Maintenance Reminder"
                  subtitle="This campaign is sent a few months after completing a Service or Install job. It's an excellent way to foster long-term relationships with customers."
                >
                  <CheckField
                    defaultValue={false}
                    errors={errors}
                    name="maintenanceRemindersEnabled"
                    control={control}
                    disabled={submitting}
                    label="Enable Maintenance Visit Reminders"
                  />
                  {maintenanceRemindersEnabledValue && (
                    <>
                      <ReactHookFormItem
                        required
                        control={control}
                        name="maintenanceRemindersDelayMonths"
                        label="Delay (in months) after job completion before sending the campaign:"
                        errors={errors}
                        render={({ field }) => (
                          <SelectField
                            showSearch={false}
                            options={[
                              // { value: '0', label: '0 months' }, // For testing only
                              { value: '3', label: '3 months' },
                              { value: '6', label: '6 months' },
                              { value: '12', label: '12 months' },
                            ]}
                            title="Delay (in months) after job completion before sending the campaign:"
                            {...field}
                          />
                        )}
                      />

                      <SubSection
                        title="Custom Email Content"
                        subtitle="Add extra content to the end of the email. This is not the entire email, but just a part of it. Click preview to see how your email will look."
                      >
                        {loadedCompanyDripMarketingConfig && (
                          <ReactHookFormItem
                            control={control}
                            name="maintenanceRemindersCustomEmailContent"
                            label="Extra Email Content (HTML)"
                            errors={errors}
                            render={({ field }) => (
                              <WysiwygEditor
                                value={field.value}
                                onChange={field.onChange}
                              />
                            )}
                          />
                        )}

                        <div className="mt-4 flex justify-end space-x-2">
                          {/* <Button
                            onClick={
                              onClickJobCompletedCustomEmailContentResetToDefault
                            }
                          >
                            Reset to Default Content
                          </Button> */}
                          <Button
                            type="primary"
                            onClick={onClickMaintenanceReminderPreviewEmail}
                          >
                            Preview
                          </Button>
                        </div>
                      </SubSection>
                    </>
                  )}
                </SubSection>
              </CategorySection>
            </div>
          </Form>
        </Card>
      </div>
      {!isNullish(previewEmailContentHtml) && (
        <BasicBrandedEmailPreviewModal
          open={!isNullish(previewEmailContentHtml)}
          onClose={() => setPreviewEmailContentHtml(null)}
          bodyContentHtml={previewEmailContentHtml}
        />
      )}
    </>
  )
})
