import { BzDateFns } from '@breezy/shared'
import { Tooltip } from 'antd'
import React, { useMemo } from 'react'
import { useWeatherForecast } from '../../apis/weather'
import {
  useExpectedCompany,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { useStrictContext } from '../../utils/react-utils'
import { getStartDateForView, SchedulePageContext } from './scheduleUtils'

export const WeatherWidget = React.memo(() => {
  const { zipCode } = useExpectedCompany()
  const tzId = useExpectedCompanyTimeZoneId()
  const { selectedDate, scheduleView } = useStrictContext(SchedulePageContext)

  const selectedLocalDate = useMemo(() => {
    const selected = BzDateFns.parseISO(selectedDate, tzId)

    // The one week view is a special case. If it's the current week, we want to show today's weather. The weather API
    // gives you blanks for dates in the past. If it's a wednesday and you're in the week view, your selected date will
    // be monday, which will give you nothing. For the weather we want to show SOMETHING so we show today's weather. In
    // the future, we just choose the "selected date" which is that monday (just as good as any of the other dates in
    // that future week). We can't simply say "if the selected date is before today, use today" because if we're showing
    // PAST weeks, we don't want to show today's weather, we want it to be blank. So we take the selected date and find
    // what the default selected date is for the one week view (via getStartDateForView, which gives it based on today
    // if the third arg is blank). If they are the same, we know we're looking at the current week and we should show
    // today's weather. Otherwise, use the selected date, which will produce blank weather if it's a past week and
    // future monday weather if it's in the future.
    if (scheduleView === 'ONE_WEEK') {
      const startOfWeekString = getStartDateForView(scheduleView, tzId)
      const startOfWeekDate = BzDateFns.parseISO(startOfWeekString, tzId)

      if (BzDateFns.isSameDay(selected, startOfWeekDate)) {
        return BzDateFns.getTodayLocalDate(tzId)
      }
    }
    return BzDateFns.formatLocalDate(selected)
  }, [scheduleView, selectedDate, tzId])

  const forecastDays = useWeatherForecast(selectedLocalDate, zipCode, 1)

  const forecastLink = `https://www.weatherapi.com/weather/q/${zipCode}`
  const day = forecastDays[selectedLocalDate]

  return day && zipCode ? (
    <div className="center-children-vh flex-1">
      <div className="my-auto">
        <a href={forecastLink} target="_blank" rel="noreferrer">
          <div className="row center-children-vh flex w-full space-x-2">
            <div className="h-8 max-h-8 w-8 max-w-8">
              <Tooltip title={day.weatherCondition}>
                <img src={day.weatherIconSrc} alt="weather" />
              </Tooltip>
            </div>
            <div className="flex h-full flex-row justify-between">
              <div className="text-md text-black">
                {day.highTempF.toFixed(0)}°
              </div>
              <div className="text-md ml-2 text-gray-400">
                {day.lowTempF.toFixed(0)}°
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  ) : null
})
