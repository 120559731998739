import { Guid, User, toRoleDisplayName } from '@breezy/shared'
import { PersonResourceViewProps } from '../../components/PersonResourceView/PersonResourceView'
import { userToAvatarData } from '../../utils/users'
import { trpc } from '../trpc'

export const useFetchUser = (userGuid: Guid): User | undefined => {
  const query = trpc.user['users:get-by-guid'].useQuery({ userGuid })
  return query.data?.user
}

export const useFetchPersonResource = (
  userGuid: Guid,
): PersonResourceViewProps | undefined => {
  const user = useFetchUser(userGuid)
  if (!user) return undefined

  const primaryRole = user.roles[0]?.role
  return {
    userGuid,
    displayName: `${user.firstName} ${user.lastName}`,
    displayCategory: primaryRole
      ? toRoleDisplayName(primaryRole)
      : 'Unknown Role',
    avatarData: userToAvatarData(user),
  }
}

export const useFetchPersonResources = ():
  | PersonResourceViewProps[]
  | undefined => {
  const query = trpc.user['users:get'].useQuery()

  if (!query.data) return undefined

  return query.data.users.map(user => {
    const primaryRole = user.roles[0]?.role
    return {
      userGuid: user.userGuid,
      displayName: `${user.firstName} ${user.lastName}`,
      displayCategory: primaryRole
        ? toRoleDisplayName(primaryRole)
        : 'Unknown Role',
      avatarData: userToAvatarData(user),
    }
  })
}
