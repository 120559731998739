import { Guid, IsoDateString, JobClass } from '@breezy/shared'
import { faWrench } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import { StateSetter, useStrictContext } from '../../utils/react-utils'
import { BaseEventBox } from './BaseEventBox'
import { AssignmentCard } from './Cards/AssignmentCard'
import { ScheduleAssignment } from './Schedule.gql'
import {
  FullScheduleAppointment,
  getColorForJobClass,
  SchedulePageContext,
  TechnicianResource,
  usePopoverState,
} from './scheduleUtils'

type AppointmentEventBoxProps = {
  appointment: FullScheduleAppointment
  jobClass: JobClass
  accountDisplayName: string
  start: IsoDateString
  end: IsoDateString
  tech?: TechnicianResource
  setChecklistAppointment: StateSetter<FullScheduleAppointment | undefined>
  assignmentGuid: Guid
}

export const AppointmentEventBox = React.memo<AppointmentEventBoxProps>(
  ({
    appointment,
    jobClass,
    accountDisplayName,
    tech,
    start,
    end,
    setChecklistAppointment,
    assignmentGuid,
  }) => {
    const { scheduleView } = useStrictContext(SchedulePageContext)
    const colorConfig = getColorForJobClass(jobClass)

    const [popoverOpen, setPopoverOpen] = usePopoverState()

    const fakedAssignment = useMemo<ScheduleAssignment | undefined>(
      () =>
        tech
          ? {
              assignmentGuid,
              appointmentGuid: appointment.appointmentGuid,
              start,
              end,
              technicianUserGuid: tech?.id,
              appointment,
            }
          : undefined,
      [appointment, assignmentGuid, end, start, tech],
    )

    return (
      <BaseEventBox
        title={accountDisplayName}
        icon={<FontAwesomeIcon icon={faWrench} />}
        colorConfig={colorConfig}
        start={start}
        end={end}
        popoverOpen={popoverOpen}
        setPopoverOpen={setPopoverOpen}
        popoverContent={
          fakedAssignment ? (
            <AssignmentCard
              closePopover={() => setPopoverOpen(false)}
              start={start}
              end={end}
              assignment={fakedAssignment}
              tech={tech}
              openChecklists={() => setChecklistAppointment(appointment)}
            />
          ) : undefined
        }
      >
        {appointment.job.location.address.city}
        {scheduleView === 'DISPATCH' || !tech
          ? ''
          : ` • ${tech.firstName} ${tech.lastName}`}
      </BaseEventBox>
    )
  },
)
