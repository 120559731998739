import { PricebookCategoryGuid, times } from '@breezy/shared'
import { DefaultOptionType } from 'antd/lib/select'
import { DataNode } from 'antd/lib/tree'
import { InvoiceItemSelectionNode } from '../../Invoicing/InvoiceItemSelectionDrawer/InvoiceItemSelectionNode'
import {
  PricebookCategoriesItem,
  PricebookItemType,
} from '../PricebookAdminTypes'
import { PricebookCategoryNode } from '../PricebookCategoryNode'

export const sortPricebookCategories = <T extends PricebookCategoriesItem>(
  categories: T[],
): T[] => {
  // Probably a smarter way of doing this, but this ensures that categories closer too root are pushed
  // toward the front of list. So if you walk the list you'll end up the dependency categories first
  const categoriesByDepth = categories.reduce((memo, next) => {
    const depth = next.name.split(':').length
    return {
      ...memo,
      [depth]: [...(memo[depth] || []), next],
    }
  }, {} as Record<string, T[]>)

  const sortedLevels = Object.keys(categoriesByDepth).sort()
  return sortedLevels.flatMap(level => {
    return categoriesByDepth[level]
  })
}

export type PricebookCategoryTreeNode<T = PricebookCategoriesItem> = T & {
  children: PricebookCategoryTreeNode<T>[]
}

export const buildPricebookCategoryTreeStructure = <
  T extends PricebookCategoriesItem,
>(
  categories: T[],
  allowIncomplete?: boolean,
): PricebookCategoryTreeNode<T>[] => {
  const categoryGuidToCategoryIndexMap: { [x: string]: number } = {}
  const pricebookCategoryTreeNodes: PricebookCategoryTreeNode<T>[] = []

  times(categories.length).forEach(i => {
    categoryGuidToCategoryIndexMap[categories[i].pricebookCategoryGuid] = i
    pricebookCategoryTreeNodes.push({
      ...categories[i],
      children: [],
    })
  })

  const roots: PricebookCategoryTreeNode<T>[] = []
  let node: PricebookCategoryTreeNode<T>
  let parentNode: PricebookCategoryTreeNode<T>

  for (let i = 0; i < pricebookCategoryTreeNodes.length; i++) {
    node = pricebookCategoryTreeNodes[i]
    if (node.parentCategoryGuid) {
      parentNode =
        pricebookCategoryTreeNodes[
          categoryGuidToCategoryIndexMap[node.parentCategoryGuid]
        ]
      if (!parentNode && allowIncomplete) {
        // this is an incomplete set of categories, missing a parent is fine
        continue
      } else if (!parentNode.children) {
        throw new Error('This should never happen')
      }

      parentNode.children.push(node)
    } else {
      roots.push(node)
    }
  }

  return roots
}

export const buildSortedPricebookCategoryTreeStructure = <
  T extends PricebookCategoriesItem,
>(
  categories: T[],
  allowIncomplete?: boolean,
): PricebookCategoryTreeNode<T>[] => {
  return buildPricebookCategoryTreeStructure<T>(
    sortPricebookCategories(categories),
    allowIncomplete,
  )
}

export const toDefaultOptionTypeTreeSelectDataNode = (
  category: PricebookCategoryTreeNode,
): DefaultOptionType => ({
  key: category.pricebookCategoryGuid,
  title: category.name,
  value: category.pricebookCategoryGuid,
  label: category.name,
  children: category.children.map(toDefaultOptionTypeTreeSelectDataNode),
  isLeaf: (category.children?.length ?? 0) === 0,
})

export const getDataNodeParentKey = (
  key: React.Key,
  tree: DataNode[],
): React.Key | null => {
  let parentKey: React.Key | null = null
  let tempParentKey: React.Key | null = null
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key
      } else if ((tempParentKey = getDataNodeParentKey(key, node.children))) {
        parentKey = tempParentKey
      }
    }
  }
  return parentKey
}

export const toTreeSelectDataNode = (
  searchValue: string,
  onNewChildCategoryAdded: (parentCategoryGuid: PricebookCategoryGuid) => void,
  refetch: () => void,
  category: PricebookCategoryTreeNode,
): DataNode => ({
  key: category.pricebookCategoryGuid,
  title: (
    <PricebookCategoryNode
      category={category}
      searchValue={searchValue}
      refetch={refetch}
      onNewChildCategoryAdded={onNewChildCategoryAdded}
    />
  ),
  children: category.children.map(c =>
    toTreeSelectDataNode(searchValue, onNewChildCategoryAdded, refetch, c),
  ),
  isLeaf: (category.children?.length ?? 0) === 0,
})

export const treeSearchedTitle = (
  strTitle: string,
  searchValue: string,
  onClick?: () => void,
) => {
  const index = strTitle.toLowerCase().indexOf(searchValue.toLowerCase())
  const beforeStr = strTitle.substring(0, index)
  const afterIndex = index + searchValue.length
  const afterStr = strTitle.slice(afterIndex)
  const match = strTitle.substring(index, afterIndex)

  return index > -1 ? (
    <span onClick={onClick}>
      {beforeStr}
      {match && <span className="category-tree-search-value">{match}</span>}
      {afterStr}
    </span>
  ) : (
    <span>{strTitle}</span>
  )
}

export type PricebookCategoryWithItems = PricebookCategoriesItem & {
  items: PricebookItemType[]
}

export const toItemCategoryTreeSelectDataNode = (
  searchValue: string,
  category: PricebookCategoryTreeNode<PricebookCategoryWithItems>,
  items: PricebookItemType[],
  onClick: (item: PricebookItemType) => void,
): DataNode => ({
  key: `category_${category.pricebookCategoryGuid}`,
  title: <div>{treeSearchedTitle(category.name, searchValue)}</div>,
  children: category.children
    .map(c =>
      toItemCategoryTreeSelectDataNode(searchValue, c, c.items, onClick),
    )
    .concat(toItemTreeSelectionList(searchValue, items, onClick)),
  isLeaf: !(
    category.items.length !== 0 || (category.children?.length ?? 0) !== 0
  ),
})

export const toItemTreeSelectionList = (
  searchValue: string,
  items: PricebookItemType[],
  onClick: (item: PricebookItemType) => void,
) => {
  return items
    .map(i => toItemTreeSelectDataNode(searchValue, i, onClick))
    .sort((a, b) =>
      a.key.toLocaleString().localeCompare(b.key.toLocaleString()),
    )
}

export const toItemTreeSelectDataNode = (
  searchValue: string,
  item: PricebookItemType,
  onClick: (item: PricebookItemType) => void,
): DataNode => ({
  key: `item_${item.pricebookItemGuid}`,
  title: (
    <InvoiceItemSelectionNode
      item={item}
      searchValue={searchValue}
      onClick={onClick}
    />
  ),
  isLeaf: true,
})
