import { gql } from '../../generated'

export const FETCH_TIMESHEET_ENTRIES_GQL = gql(/* GraphQL */ `
  query FetchTimesheetEntries($where: TimesheetEntriesBoolExp) {
    timesheetEntries(
      where: $where
      orderBy: { finalStartTime: ASC, finalEndTime: ASC }
    ) {
      timesheetEntryGuid
      userGuid
      finalStartTime
      finalEndTime
      entryLengthInMinutes
      deletedAt
      user {
        firstName
        lastName
        userGuid
        userRoles {
          role
        }
      }
      timesheetEntryActivity {
        timesheetEntryActivityGuid
        activityName
        isPayable
      }
      timesheetEntryLinks {
        jobGuid
        jobAppointmentGuid
        jobAppointmentAssignmentGuid
        job {
          displayId
        }
      }
    }
  }
`)

export const UPSERT_TIMESHEET_ENTRY_GQL = gql(/* GraphQL */ `
  mutation UpsertTimesheetEntry($entry: TimesheetEntriesInsertInput!) {
    insertTimesheetEntriesOne(
      object: $entry
      onConflict: { constraint: timesheet_entries_pkey, updateColumns: [] }
    ) {
      __typename
    }
  }
`)

export const UPDATE_TIMESHEET_ENTRY_GQL = gql(/* GraphQL */ `
  mutation UpdateTimesheetEntry(
    $updatedByUserGuid: uuid!
    $timesheetEntryGuid: uuid!
    $startTimeOverride: timestamptz
    $endTimeOverride: timestamptz
    $timesheetEntryActivityGuid: uuid!
    $activityName: String!
    $isPayable: Boolean!
  ) {
    updateTimesheetEntriesByPk(
      pkColumns: { timesheetEntryGuid: $timesheetEntryGuid }
      _set: {
        startTimeOverride: $startTimeOverride
        endTimeOverride: $endTimeOverride
        updatedByUserGuid: $updatedByUserGuid
      }
    ) {
      __typename
    }
    updateTimesheetEntryActivitiesByPk(
      pkColumns: { timesheetEntryActivityGuid: $timesheetEntryActivityGuid }
      _set: { activityName: $activityName, isPayable: $isPayable }
    ) {
      __typename
    }
  }
`)

export const DELETE_TIMESHEET_ENTRY_GQL = gql(/* GraphQL */ `
  mutation DeleteTimesheetEntry(
    $timesheetEntryGuid: uuid!
    $deletedAt: timestamptz!
    $deletedByUserGuid: uuid!
  ) {
    updateTimesheetEntriesByPk(
      pkColumns: { timesheetEntryGuid: $timesheetEntryGuid }
      _set: { deletedAt: $deletedAt, deletedByUserGuid: $deletedByUserGuid }
    ) {
      __typename
    }
  }
`)
