import {
  CompanyTechnicianPerformanceSettingCommissionsFormSchema,
  companyTechnicianPerformanceSettingCommissionsFormSchema,
  isNullish,
  usCentsToUsd,
  usdToUsCents,
} from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Col, Form, Radio, Row } from 'antd'
import React, { useCallback, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'urql'
import PercentInputNumber from '../../components/JobOutcomesModal/PercentInputNumber'
import { ItemPriceInputNumber } from '../../components/form-fields/ItemPriceInputNumber'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { UPSERT_COMPANY_COMMISSION_CONFIG } from './TechnicianPerformanceSettings.gql'

interface CompanyCommissionConfigResult {
  defaultCommissionOverheadDeductionRate?: number
  defaultCommissionOverheadFlatDeductionUsc?: number
}

type TechnicianPerformanceCommissionsFormProps = {
  companyCommissionConfig?: CompanyCommissionConfigResult
}
const TechnicianPerformanceCommissionsForm: React.FC<
  TechnicianPerformanceCommissionsFormProps
> = ({ companyCommissionConfig }) => {
  const message = useMessage()
  const companyGuid = useExpectedCompanyGuid()
  const btnFormSubmit = useRef<HTMLInputElement>(null)

  const [{ fetching: upsertingCompanyConfig }, upsertCompanyConfig] =
    useMutation(UPSERT_COMPANY_COMMISSION_CONFIG)

  const form =
    useForm<CompanyTechnicianPerformanceSettingCommissionsFormSchema>({
      resolver: zodResolver(
        companyTechnicianPerformanceSettingCommissionsFormSchema,
      ),

      defaultValues: {
        defaultCommissionOverheadDeductionType: !isNullish(
          companyCommissionConfig?.defaultCommissionOverheadFlatDeductionUsc,
        )
          ? 'FIXED'
          : 'PERCENT',
        defaultCommissionOverheadDeductionPercent:
          (companyCommissionConfig?.defaultCommissionOverheadDeductionRate ??
            0) * 100,
        defaultCommissionOverheadFlatDeductionUsd: usCentsToUsd(
          companyCommissionConfig?.defaultCommissionOverheadFlatDeductionUsc ??
            0,
        ),
      },
    })

  const overheadAllocationUnit = form.watch(
    'defaultCommissionOverheadDeductionType',
  )

  const onSubmit = form.handleSubmit(async values => {
    try {
      await upsertCompanyConfig({
        config: {
          companyGuid,
          defaultCommissionOverheadFlatDeductionUsc:
            values.defaultCommissionOverheadDeductionType === 'FIXED'
              ? usdToUsCents(values.defaultCommissionOverheadFlatDeductionUsd)
              : null,
          defaultCommissionOverheadDeductionRate:
            values.defaultCommissionOverheadDeductionType === 'PERCENT'
              ? values.defaultCommissionOverheadDeductionPercent / 100
              : null,
        },
      })

      // Reset the form to non-dirty state without resetting values
      form.reset(form.getValues())

      message.success('Successfully updated Technician Performance settings')
    } catch (e) {
      message.error(
        'Failed to update Technician Performance settings. Please reload the application and try again, or contact support if the problem persists.',
      )
      console.error(e)
    }
  })

  const onCancel = useCallback(() => {
    form.reset()
  }, [form])

  return (
    <form onSubmit={onSubmit}>
      <Row justify="space-between">
        <Col span={8}>
          <h3 className="grey9">Commissions</h3>
          <p className="regular_14_22 grey8 max-w-[700px] font-light">
            Settings and system defaults for calculating employee sales
            commissions.
          </p>
        </Col>
        <Col span={8}>
          <Row>
            <h4>Default Overhead Allocation</h4>
          </Row>
          <Row>
            {overheadAllocationUnit === 'FIXED' ? (
              <Controller
                key="fixed"
                name={'defaultCommissionOverheadFlatDeductionUsd'}
                control={form.control}
                render={({ field }) => (
                  <ItemPriceInputNumber
                    style={{ width: '140px ' }}
                    {...field}
                  />
                )}
              />
            ) : (
              <Controller
                key="percent"
                name={'defaultCommissionOverheadDeductionPercent'}
                control={form.control}
                render={({ field }) => (
                  <PercentInputNumber
                    value={field.value}
                    onChange={field.onChange}
                    ref={field.ref}
                    className="w-[140px]"
                  />
                )}
              />
            )}

            <Controller
              control={form.control}
              name="defaultCommissionOverheadDeductionType"
              render={({ field }) => (
                <Radio.Group
                  {...field}
                  optionType="button"
                  style={{ marginLeft: '8px' }}
                >
                  <Radio
                    value={'PERCENT'}
                    style={{ width: '124px', textAlign: 'center' }}
                  >
                    % Percent
                  </Radio>
                  <Radio
                    value={'FIXED'}
                    style={{ width: '124px', textAlign: 'center' }}
                  >
                    $ Fixed
                  </Radio>
                </Radio.Group>
              )}
            />
          </Row>

          <Row>
            <Form.Item>
              <p style={{ color: 'rgba(0, 0, 0, 0.45' }}>
                This amount will be automatically deducted from a job's sold
                revenue total when calculating the commissionable base for
                sales-based commissions.
              </p>
            </Form.Item>
          </Row>
          <Row>
            {form.formState.isDirty && (
              <>
                <Button
                  type="default"
                  onClick={onCancel}
                  disabled={upsertingCompanyConfig}
                  style={{ marginRight: '8px' }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={upsertingCompanyConfig}
                  htmlType="button"
                  type="primary"
                  onClick={() => btnFormSubmit.current?.click()}
                >
                  Save Changes
                </Button>
              </>
            )}
          </Row>
          {/* Submitting our react-hook-form directly through antd component doesn't seem
                to work, so we create this hidden button with an attached ref so we can use it
                to trigger a form submission */}
          <input ref={btnFormSubmit} type="submit" className="hidden" />
        </Col>
      </Row>
    </form>
  )
}

export default TechnicianPerformanceCommissionsForm
