import {
  BzDateFns,
  IsoDateString,
  ReportingDateGroupingType,
  ReportingDateRange,
  STANDARD_REPORTING_TIME_HORIZON_OPTIONS,
  StandardReportingTimeHorizon,
  TimeZoneId,
  getDateGroupingTypeForRange,
} from '@breezy/shared'
import React, { useImperativeHandle, useMemo, useState } from 'react'

const WEEK_LABEL_PREFIX = 'Week of '

export const getStandardXAxisValue = (
  tzId: TimeZoneId,
  dateStr: IsoDateString,
  groupingType: ReportingDateGroupingType,
) => {
  if (groupingType === 'months') {
    return BzDateFns.formatFromISO(dateStr, 'MMMM', tzId)
  } else if (groupingType === 'weeks') {
    return `${WEEK_LABEL_PREFIX}${BzDateFns.formatFromISO(
      dateStr,
      'MMM d',
      tzId,
    )}`
  } else if (groupingType === 'years') {
    return BzDateFns.formatFromISO(dateStr, 'yyyy', tzId)
  } else {
    return BzDateFns.formatFromISO(dateStr, 'M/d', tzId)
  }
}

export const useStandardXAxisFormatter = (
  dateRange: ReportingDateRange,
  tzId: TimeZoneId,
) =>
  useMemo(() => {
    const groupingType = getDateGroupingTypeForRange(dateRange, tzId)
    if (groupingType === 'months') {
      // Abbreviate month names, leave week strings
      return (value: string) => value.substring(0, 3)
    } else if (groupingType === 'weeks') {
      return (value: string) => value.replace(WEEK_LABEL_PREFIX, '')
    } else {
      return undefined
    }
  }, [dateRange, tzId])

export const useStandardTimeHorizon = (
  defaultTimeHorizon: StandardReportingTimeHorizon = STANDARD_REPORTING_TIME_HORIZON_OPTIONS[0],
) => {
  const [timeHorizon, setTimeHorizon] =
    useState<StandardReportingTimeHorizon>(defaultTimeHorizon)

  return [timeHorizon, setTimeHorizon] as const
}

export type RefetchableAppointmentsRef = {
  refetchAppointments: () => void
}

export const useRefetchableAppointments = (
  ref: React.Ref<RefetchableAppointmentsRef>,
  refetchAppointments: () => unknown,
) => {
  useImperativeHandle(
    ref,
    () => ({
      refetchAppointments,
    }),
    [refetchAppointments],
  )
}
