import { nextGuid } from '../../common'
import { JobLifecycleStatus, JobLifecycleStatusWithoutLifecycleGuid, UpsertJobLifecycleRequest } from './JobLifecycles'

type DefaultJobLifecycle = Omit<UpsertJobLifecycleRequest, 'jobLifecycleGuid' | 'statuses'> & {
  statuses: Omit<JobLifecycleStatus, 'jobLifecycleStatusGuid' | 'jobLifecycleGuid'>[]
}
export const DEFAULT_SERVICE_JOB_LIFECYCLE = {
  name: 'Service & Repairs',
  description: 'Pipeline for Service & Repair jobs',
  type: 'JOB',
  statuses: [
    {
      name: 'Requested',
      description: 'Service has been requested but not yet scheduled.',
      stage: 'Upcoming',
      color: '#FF8787',
      isDefault: true,
      isWorkComplete: false,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'Scheduled',
      description: 'Service has been scheduled and awaiting execution.',
      stage: 'Upcoming',
      color: '#CC005C',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: true,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'In Progress',
      description: 'Maintenance service is currently being performed.',
      stage: 'Ongoing',
      color: '#0A72CC',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: true,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'On Hold',
      description: 'Waiting on Customer, Parts Order, Stalled',
      stage: 'Ongoing',
      color: '#FFA500',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'Onsite Work Complete',
      description: 'Work is done, job  is ready to close out pending finalization',
      stage: 'Ongoing',
      color: '#008432',
      isDefault: false,
      isWorkComplete: true,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: true,
    },
    {
      name: 'Completed',
      description: 'Service has been successfully completed.',
      stage: 'Closed',
      color: '#73D13D',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      specialStatus: 'Completed',
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'Canceled',
      description: 'Service was canceled before completion.',
      stage: 'Closed',
      color: '#FF7875',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      specialStatus: 'Canceled',
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
  ],
} satisfies DefaultJobLifecycle

export const DEFAULT_MAINTENANCE_JOB_LIFECYCLE = {
  name: 'Preventative Maintenance',
  description: 'Pipeline for Preventative Maintenance Work',
  type: 'JOB',
  statuses: [
    {
      name: 'Requested',
      description: 'Maintenance service has been requested but not yet scheduled.',
      stage: 'Upcoming',
      color: '#FF8787',
      isDefault: true,
      isWorkComplete: false,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'Scheduled',
      description: 'Maintenance service has been scheduled and awaiting execution.',
      stage: 'Upcoming',
      color: '#CC005C',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: true,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'In Progress',
      description: 'Maintenance service is currently being performed.',
      stage: 'Ongoing',
      color: '#0A72CC',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: true,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'On Hold',
      description: 'Waiting on Customer, Parts Order, Stalled',
      stage: 'Ongoing',
      color: '#FFA500',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'Onsite Work Complete',
      description: 'Work is done, job  is ready to close out pending finalization',
      stage: 'Ongoing',
      color: '#008432',
      isDefault: false,
      isWorkComplete: true,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: true,
    },
    {
      name: 'Completed',
      description: 'Maintenance service has been successfully completed.',
      stage: 'Closed',
      color: '#73D13D',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      specialStatus: 'Completed',
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'Canceled',
      description: 'Maintenance service was canceled before completion.',
      stage: 'Closed',
      color: '#FF7875',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      specialStatus: 'Canceled',
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
  ],
} satisfies DefaultJobLifecycle

export const DEFAULT_INSTALL_JOB_LIFECYCLE = {
  name: 'Installs / Replacements',
  description: 'Pipeline for Install & Replacement jobs',
  type: 'JOB',
  statuses: [
    {
      name: 'New',
      description: 'New installation job',
      stage: 'Opportunity',
      color: '#FF8787',
      isDefault: true,
      isWorkComplete: false,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'Scheduled',
      description: 'Installation job has been scheduled and is awaiting execution.',
      stage: 'Upcoming',
      color: '#CC005C',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: true,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'In Progress',
      description: 'Installation has started and is ongoing',
      stage: 'Ongoing',
      color: '#0A72CC',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: true,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'On Hold',
      description: 'Waiting on Customer, Parts Order, Stalled',
      stage: 'Ongoing',
      color: '#FFA500',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'Onsite Work Complete',
      description: 'Work is done, job  is ready to close out pending finalization',
      stage: 'Ongoing',
      color: '#008432',
      isDefault: false,
      isWorkComplete: true,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: true,
    },
    {
      name: 'Completed',
      description: 'Installation has been successfully completed.',
      stage: 'Closed',
      color: '#73D13D',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      specialStatus: 'Completed',
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'Canceled',
      description: 'Installation job was canceled before execution or completion.',
      stage: 'Closed',
      color: '#FF7875',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      specialStatus: 'Canceled',
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
  ],
} satisfies DefaultJobLifecycle

export const DEFAULT_SALES_LIFECYCLE = {
  name: 'Sales / Leads',
  description: 'Pipeline for Sales & Estimates',
  type: 'SALES',
  statuses: [
    {
      name: 'New Lead',
      description: 'New opportunity for a potential sale',
      stage: 'Opportunity',
      color: '#FF8787',
      isDefault: true,
      isWorkComplete: false,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'Scheduled',
      description: 'All appointments for this job have been scheduled',
      stage: 'Opportunity',
      color: '#CC005C',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'In Progress',
      description: 'Sales visit is currently being carried out.',
      stage: 'Ongoing',
      color: '#0A72CC',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: true,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'Follow-up',
      description: 'The estimate has been sent to the customer for approval',
      stage: 'Ongoing',
      color: '#FF8533',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'Estimate Accepted',
      description: 'The customer has accepted an estimate',
      stage: 'Won',
      color: '#008432',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: true,
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'Sold/Complete',
      description: 'The job is fully complete and ready to be scheduled for the install team',
      stage: 'Closed',
      color: '#73D13D',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      specialStatus: 'Completed',
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: true,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
    {
      name: 'Closed - Lost',
      description: 'The lead did not agree to the proposed job or was unresponsive',
      stage: 'Closed',
      color: '#FF4D4F',
      isDefault: false,
      isWorkComplete: false,
      isLeadWon: false,
      specialStatus: 'Closed - Lost',
      isAllAppointmentsCompletedAutomationEnabled: false,
      isAllOutstandingAppointmentsAssignedAutomationEnabled: false,
      isFirstAppointmentBookedAutomationEnabled: false,
      isTechnicianArrivesAtAppointmentAutomationEnabled: false,
      isTechnicianDispatchedAutomationEnabled: false,
      isCreateLinkedJobAutomationEnabled: false,
      isNoAdditionalWorkRequiredAutomationEnabled: false,
    },
  ],
} satisfies DefaultJobLifecycle

/**
 * Generates the default statuses for a lifecycle.
 *
 * See [BZ-940](https://getbreezyapp.atlassian.net/browse/BZ-940) for the requirements and list
 * for the default statuses
 */
export const generateDefaultJobLifecycleStatuses = (): JobLifecycleStatusWithoutLifecycleGuid[] => {
  return DEFAULT_SERVICE_JOB_LIFECYCLE.statuses.map(status => {
    return {
      jobLifecycleStatusGuid: nextGuid(),
      name: status.name,
      description: status.description,
      stage: status.stage,
      color: status.color,
      isDefault: status.isDefault,
      isWorkComplete: status.isWorkComplete,
      isLeadWon: status.isLeadWon,
      isAllAppointmentsCompletedAutomationEnabled: status.isAllAppointmentsCompletedAutomationEnabled,
      isAllOutstandingAppointmentsAssignedAutomationEnabled:
        status.isAllOutstandingAppointmentsAssignedAutomationEnabled,
      isFirstAppointmentBookedAutomationEnabled: status.isFirstAppointmentBookedAutomationEnabled,
      isTechnicianArrivesAtAppointmentAutomationEnabled: status.isTechnicianArrivesAtAppointmentAutomationEnabled,
      isTechnicianDispatchedAutomationEnabled: status.isTechnicianDispatchedAutomationEnabled,
      isCreateLinkedJobAutomationEnabled: status.isCreateLinkedJobAutomationEnabled,
      isNoAdditionalWorkRequiredAutomationEnabled: status.isNoAdditionalWorkRequiredAutomationEnabled,
    }
  })
}

export const generateDefaultSalesLifecycleStatuses = (): JobLifecycleStatusWithoutLifecycleGuid[] => {
  return DEFAULT_SALES_LIFECYCLE.statuses.map(status => {
    return {
      jobLifecycleStatusGuid: nextGuid(),
      name: status.name,
      description: status.description,
      stage: status.stage,
      color: status.color,
      isDefault: status.isDefault,
      isWorkComplete: status.isWorkComplete,
      isLeadWon: status.isLeadWon,
      isAllAppointmentsCompletedAutomationEnabled: status.isAllAppointmentsCompletedAutomationEnabled,
      isAllOutstandingAppointmentsAssignedAutomationEnabled:
        status.isAllOutstandingAppointmentsAssignedAutomationEnabled,
      isFirstAppointmentBookedAutomationEnabled: status.isFirstAppointmentBookedAutomationEnabled,
      isTechnicianArrivesAtAppointmentAutomationEnabled: status.isTechnicianArrivesAtAppointmentAutomationEnabled,
      isTechnicianDispatchedAutomationEnabled: status.isTechnicianDispatchedAutomationEnabled,
      isCreateLinkedJobAutomationEnabled: status.isCreateLinkedJobAutomationEnabled,
      isNoAdditionalWorkRequiredAutomationEnabled: status.isNoAdditionalWorkRequiredAutomationEnabled,
    }
  })
}
