import { m } from '../../utils/react-utils'

export const QuickbooksLogoIcon = m(() => (
  <img
    alt="Quickbooks Logo"
    src="https://d3j5nzmmhjc8v6.cloudfront.net/quickbooks_logo.png"
    className="mr-2 h-4 w-4"
  />
))

export const QuickbooksLogoIconNonResizing = m(() => (
  <div className="mr-1 min-h-[36px] min-w-[36px] max-w-[36px]">
    <img
      alt="Quickbooks Logo"
      src="https://d3j5nzmmhjc8v6.cloudfront.net/quickbooks_logo.png"
    />
  </div>
))
