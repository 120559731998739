import { PageHeader } from '@ant-design/pro-components'
import { Divider } from 'antd'
import { useQuery } from 'urql'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import { Page } from '../../components/Page/Page'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'
import { useCanManageTechnicianPerformance } from '../../hooks/permission/useCanManageTechnicianPerformance'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { TechnicianPerformanceIcon } from '../../utils/feature-icons'
import TechnicianPerformanceCommissionsForm from './TechnicianPerformanceCommissionsForm'
import { TechnicianPerformanceEnableView } from './TechnicianPerformanceEnableView'
import TechnicianPerformanceGenerateReportForm from './TechnicianPerformanceGenerateReportForm'
import { FETCH_COMPANY_COMMISSION_CONFIG } from './TechnicianPerformanceSettings.gql'

export const TechnicianPerformanceSettingsPage = () => {
  const canManageTechnicianPerformance = useCanManageTechnicianPerformance()
  const companyGuid = useExpectedCompanyGuid()
  const fetchCompanyCommissionConfigQuery = useQuery({
    query: FETCH_COMPANY_COMMISSION_CONFIG,
    pause: !companyGuid,
    variables: { companyGuid: companyGuid ?? '' },
    requestPolicy: 'network-only',
  })

  return (
    <Page requiresCompanyUser className="flex flex-col p-0">
      <PageHeader
        title={
          <PageTitle
            title="Technician Performance"
            icon={TechnicianPerformanceIcon}
          />
        }
      />
      <ScrollCard
        className="flex h-full w-full flex-col space-y-6"
        hasPageHeading
      >
        <TechnicianPerformanceEnableView />
        <TechnicianPerformanceGenerateReportForm />
        <Divider className="my-6" />
        {canManageTechnicianPerformance && (
          <GqlQueryLoader
            query={fetchCompanyCommissionConfigQuery}
            render={data => (
              <TechnicianPerformanceCommissionsForm
                companyCommissionConfig={data.companyCommissionConfig[0]}
              />
            )}
          />
        )}
      </ScrollCard>
    </Page>
  )
}
