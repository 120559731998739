import { useMemo } from 'react'
import { getPermissionCategoryEnabled } from './getters'
import { PermissionCategory } from './types'
import { usePermissionControls } from './usePermissionControls'

export const useIsCategoryEnabled = (category: PermissionCategory) => {
  const { state } = usePermissionControls()
  return useMemo(
    () => getPermissionCategoryEnabled(state, category),
    [state, category],
  )
}
