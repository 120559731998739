import {
  EquipmentType,
  JobClass,
  JobToEquipmentRelationship,
  formatMaybeEquipmentType,
} from '@breezy/shared'
import { Form, Select } from 'antd'
import { useCallback, useMemo } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { ChangeJobTypeFormSchema } from '../../change-job-type-form-schema'

const mapRelationshipTypeFromJobClassForPreexistingEquipment = (
  jobClass: JobClass,
): JobToEquipmentRelationship => {
  return jobClass
    ? jobClass === JobClass.SERVICE
      ? JobToEquipmentRelationship.SERVICING
      : jobClass === JobClass.INSTALL
      ? JobToEquipmentRelationship.DEACTIVATING
      : jobClass === JobClass.MAINTENANCE
      ? JobToEquipmentRelationship.MAINTAINING
      : JobToEquipmentRelationship.LINKED
    : JobToEquipmentRelationship.LINKED
}

export type NewEquipmentSelectProps = {
  selectedJobType: { jobTypeGuid: string; name: string; jobClass: JobClass }
}

export const NewEquipmentSelect = ({
  selectedJobType,
}: NewEquipmentSelectProps) => {
  const { control } = useFormContext<ChangeJobTypeFormSchema>()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'newEquipment',
  })

  const newEquipmentOptions: {
    label: string
    value: EquipmentType
  }[] = useMemo(
    () =>
      Object.values(EquipmentType).map(equipmentType => ({
        label: formatMaybeEquipmentType(equipmentType),
        value: equipmentType,
      })),
    [],
  )

  const onEquipmentSelected = useCallback(
    (equipmentType: EquipmentType) => {
      append({
        equipmentType,
        relationshipType:
          mapRelationshipTypeFromJobClassForPreexistingEquipment(
            selectedJobType.jobClass,
          ),
      })
    },
    [append, selectedJobType.jobClass],
  )

  const onEquipmentDeselected = useCallback(
    (equipmentType: EquipmentType) => {
      const equipmentIdx = fields.findIndex(
        equipment => equipment.equipmentType === equipmentType,
      )

      if (equipmentIdx < 0) {
        return
      }

      remove(equipmentIdx)
    },
    [fields, remove],
  )

  return (
    <Controller
      control={control}
      name="preexistingEquipment"
      render={() => (
        <Form.Item label="Equipment to Install">
          <Select<EquipmentType[]>
            mode="multiple"
            value={fields.map(equipment => equipment.equipmentType)}
            options={newEquipmentOptions}
            onSelect={onEquipmentSelected}
            onDeselect={onEquipmentDeselected}
          />
        </Form.Item>
      )}
    />
  )
}
