import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

type CommAvatarProps = {
  avatarText?: string
  isSelected?: boolean
}

export const CommAvatar = React.memo<CommAvatarProps>(
  ({ avatarText, isSelected }) => {
    const bgColor = isSelected ? '#d9d9d9' : '#f0f0f0'
    return (
      <div
        className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full"
        style={{ backgroundColor: bgColor }}
      >
        {avatarText && avatarText !== '?' ? (
          <span className="text-sm font-semibold text-[#8c8c8c]">
            {avatarText}
          </span>
        ) : (
          <FontAwesomeIcon icon={faUser} className="text-sm text-[#8c8c8c]" />
        )}
      </div>
    )
  },
)
