import {
  JobLifecycle,
  JobLifecycleDeleterInput,
  JobLifecycleStatus,
  isNullish,
  jobLifecycleDeleterInputSchema,
} from '@breezy/shared'
import { faRight, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { Alert, Button, Modal, Select } from 'antd'
import { useEffect, useMemo, useRef } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'

export type JobLifecycleDeleteModalProps = {
  open: boolean
  lifecycle: JobLifecycle | null
  allLifecycles: JobLifecycle[]
  isLoading: boolean
  onDeleteClicked: (data: JobLifecycleDeleterInput) => void
  onCancel: () => void
}

export const JobLifecycleDeleteModal = ({
  open,
  lifecycle,
  allLifecycles,
  isLoading,
  onDeleteClicked,
  onCancel,
}: JobLifecycleDeleteModalProps) => {
  // This is a hacky way to trigger the hidden submit button in the form when clicking on the delete lifecycle button
  // that's contained within the Modal's footer.
  //
  // For some reason, when Antd's button is set to htmlType 'submit', it does not trigger the form's onSubmit handler
  // when the button is in a regular form and not in Antd's Form component.
  const btnSubmitRef = useRef<HTMLInputElement>(null)

  const {
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    reset,
  } = useForm<JobLifecycleDeleterInput>({
    resolver: zodResolver(jobLifecycleDeleterInputSchema),
  })

  const selectedTransitionLifecycleGuid = useWatch({
    control,
    name: 'jobLifecycle.toJobLifecycleGuid',
  })
  const selectedTransitionLifecycle = allLifecycles.find(
    curr => curr.jobLifecycleGuid === selectedTransitionLifecycleGuid,
  )

  const onDeleteSubmit = handleSubmit(data => onDeleteClicked(data))

  const transitionableLifecycles: JobLifecycle[] = useMemo(() => {
    return allLifecycles.filter(
      curr => curr.jobLifecycleGuid !== lifecycle?.jobLifecycleGuid,
    )
  }, [allLifecycles, lifecycle?.jobLifecycleGuid])

  const transitionableLifecycleStatuses: JobLifecycleStatus[] = useMemo(() => {
    return allLifecycles
      .filter(
        curr =>
          curr.jobLifecycleGuid !== lifecycle?.jobLifecycleGuid &&
          curr.jobLifecycleGuid === selectedTransitionLifecycleGuid,
      )
      .flatMap(curr => curr.statuses)
  }, [
    allLifecycles,
    lifecycle?.jobLifecycleGuid,
    selectedTransitionLifecycleGuid,
  ])

  useEffect(() => {
    if (!lifecycle) {
      reset()
      return
    }

    setValue('jobLifecycle', {
      fromJobLifecycleGuid: lifecycle.jobLifecycleGuid,
      toJobLifecycleGuid: '',
    })
    setValue(
      'jobLifecycleStatuses',
      lifecycle.statuses.map(status => ({
        fromJobLifecycleStatusGuid: status.jobLifecycleStatusGuid,
        toJobLifecycleStatusGuid: '',
      })),
    )
  }, [lifecycle, reset, setValue])

  useEffect(() => {
    if (!lifecycle || !selectedTransitionLifecycle) {
      return
    }

    setValue(
      'jobLifecycleStatuses',
      lifecycle.statuses.map(status => ({
        fromJobLifecycleStatusGuid: status.jobLifecycleStatusGuid,
        toJobLifecycleStatusGuid:
          selectedTransitionLifecycle.statuses.find(
            transitionStatus =>
              transitionStatus.name.toLowerCase() === status.name.toLowerCase(),
          )?.jobLifecycleStatusGuid ?? '',
      })),
    )
  }, [lifecycle, selectedTransitionLifecycle, setValue])

  if (isNullish(lifecycle)) {
    return (
      <Modal
        open={open}
        title={`Delete Job Lifecycle`}
        onCancel={onCancel}
        footer={
          <div className="flex w-full flex-row">
            <Button className="ml-auto" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        }
      >
        <span>No Job Lifecycle selected to delete</span>
      </Modal>
    )
  }

  return (
    <Modal
      open={open}
      title="Delete Job Lifecycle"
      width={720}
      styles={{
        body: { overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' },
      }}
      centered
      onCancel={onCancel}
      footer={
        <div className="flex w-full flex-row">
          <Button
            htmlType="button"
            className="ml-auto"
            onClick={onCancel}
            disabled={isLoading}
          >
            Cancel
          </Button>

          <Button
            type="primary"
            danger
            icon={<FontAwesomeIcon icon={faTrash} />}
            disabled={isLoading}
            onClick={() => btnSubmitRef.current?.click()}
          >
            Delete Lifecycle
          </Button>
        </div>
      }
    >
      <form
        className="div flex h-full flex-col gap-4 overflow-y-scroll"
        onSubmit={onDeleteSubmit}
      >
        <Alert
          type="warning"
          showIcon
          message="Deleting a Job Pipeline is irreversible! Ensure that you select the correct Job Pipeline to transfer all jobs, job types, and views to."
        />
        <Controller
          name="jobLifecycle.toJobLifecycleGuid"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <label className="flex flex-col gap-2">
              <span>
                Select the pipeline to migrate "{lifecycle.name}"'s jobs to
              </span>
              <Select
                {...field}
                disabled={isLoading}
                title="Select transition"
                status={
                  !isNullish(errors.jobLifecycle?.toJobLifecycleGuid)
                    ? 'error'
                    : undefined
                }
                options={transitionableLifecycles.map(curr => ({
                  label: curr.name,
                  value: curr.jobLifecycleGuid,
                }))}
              />
            </label>
          )}
        />

        {!isNullish(selectedTransitionLifecycle) && (
          <>
            <div className="col-span-full">
              <p>
                Map each status for{' '}
                <span className="font-bold">{lifecycle.name}</span> to one in{' '}
                <span className="font-bold">
                  {selectedTransitionLifecycle.name}
                </span>
              </p>
            </div>

            <div className="flex flex-row items-center">
              <span className="text-center font-bold">{lifecycle.name}</span>

              <span className="ml-auto font-bold">
                {selectedTransitionLifecycle.name}
              </span>
            </div>

            <div className="flex w-full flex-col gap-4">
              {lifecycle.statuses.map((status, idx) => (
                <div
                  key={status.jobLifecycleStatusGuid}
                  className="grid w-full grid-cols-10"
                >
                  <span className="col-span-4 w-full text-center">
                    {status.name}
                  </span>

                  <div className="col-span-2 flex w-full flex-col items-center justify-center">
                    <FontAwesomeIcon icon={faRight} />
                  </div>

                  <Controller
                    control={control}
                    name={`jobLifecycleStatuses.${idx}.toJobLifecycleStatusGuid`}
                    render={({ field }) => (
                      <Select
                        {...field}
                        disabled={isLoading}
                        className="col-span-4 w-full"
                        status={
                          !isNullish(
                            errors.jobLifecycleStatuses?.[idx]
                              ?.toJobLifecycleStatusGuid,
                          )
                            ? 'error'
                            : undefined
                        }
                        options={transitionableLifecycleStatuses.map(
                          status => ({
                            label: status.name,
                            value: status.jobLifecycleStatusGuid,
                          }),
                        )}
                      />
                    )}
                  />
                </div>
              ))}
            </div>
          </>
        )}

        <input ref={btnSubmitRef} type="submit" className="hidden" />
      </form>
    </Modal>
  )
}
