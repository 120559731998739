import { DiscountType, bzExpect, normalizeString } from '@breezy/shared'
import { faSearch } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import ColoredCircle from '../../elements/ColoredCircle/ColoredCircle'
import { useInMemorySearch } from '../../hooks/useInMemorySearch'
import { PricebookDiscount } from './PricebookAdmin.gql'
import { usePricebookAdmin } from './hooks/usePricebookAdmin'

const computePricebookDiscountItemIndex = (item: PricebookDiscount) => {
  return [item.name, item.description]
    .filter(Boolean)
    .map(s => normalizeString(bzExpect(s, 'Filtered nullish values above')))
    .join('')
}

export const PricebookDiscountTable = () => {
  const { discountItemClicked, discountItems } = usePricebookAdmin()

  const { searchFilter, searchText, onSearch } =
    useInMemorySearch<PricebookDiscount>({
      wait: 1000,
      keyMapper: p => p.pricebookDiscountGuid,
      computeIndex: computePricebookDiscountItemIndex,
    })

  const columns: ColumnsType<PricebookDiscount> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: 'Discount',
      key: 'discount',
      render: (item: PricebookDiscount) => {
        if (item.type === DiscountType.FLAT) {
          return `$${item.discountAmountUsd}`
        }
        if (item.type === DiscountType.RATE) {
          return `${(item.discountRate ?? 0) * 100}%`
        }
      },
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: 'Status',
      key: 'status',
      render: (item: PricebookDiscount) => {
        return (
          <div style={{ display: 'flex' }}>
            <ColoredCircle color={item.isActive ? 'limegreen' : 'lightgrey'} />
            <div
              style={{
                display: 'inline-block',
                color: item.isActive ? 'black' : 'lightgrey',
              }}
            >
              {item.isActive ? 'Active' : 'Disabled'}
            </div>
          </div>
        )
      },
      ellipsis: {
        showTitle: true,
      },
      filters: [
        { text: 'Active', value: true },
        { text: 'Disabled', value: false },
      ],
      onFilter: (value, record) => record.isActive === value,
    },
  ]

  if (!discountItems) return null

  return (
    <div>
      <div className="m-2 flex justify-between">
        <div>
          <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
            Discounts
          </span>{' '}
          <span style={{ fontSize: '12px', color: 'gray' }}>
            {discountItems.length} items
          </span>
        </div>
        <div>
          <Input
            placeholder="Search Discounts"
            prefix={
              <FontAwesomeIcon icon={faSearch} size="sm" className="mr-1" />
            }
            value={searchText}
            onChange={onSearch}
            style={{ minWidth: 300 }}
            allowClear
          />
        </div>
      </div>
      <Table
        rowKey="pricebookDiscountGuid"
        scroll={{ x: true }}
        pagination={{
          defaultPageSize: 15,
          pageSizeOptions: [15, 30, 100, 250],
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              discountItemClicked(record)
            },
          }
        }}
        columns={columns}
        dataSource={searchFilter(discountItems)}
      />
    </div>
  )
}
