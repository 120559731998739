import { PageHeader } from '@ant-design/pro-components'
import { isNullish } from '@breezy/shared/src'
import { faCalendarCheck } from '@fortawesome/pro-regular-svg-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Form } from 'antd'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'urql'
import { z } from 'zod'
import { Page } from '../../components/Page/Page'
import {
  CategorySection,
  CheckField,
  SubSection,
} from '../../components/Settings/SettingsBuilderComponents'
import { Card } from '../../elements/Card/Card'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { useIsEndOfAppointmentWorkflowEnabledForCompany } from '../../hooks/fetch/useIsEndOfAppointmentWorkflowEnabledForCompany'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { UPDATE_END_OF_APPOINTMENT_WORKFLOW_ENABLED } from './AppointmentConfigurationSettingsPage.gql'

const appointmentConfigurationSettingsFormSchema = z.object({
  endOfAppointmentWorkflowEnabled: z.boolean().default(false),
})

type AppointmentConfigurationSettingsForm = z.infer<
  typeof appointmentConfigurationSettingsFormSchema
>

export const AppointmentConfigurationSettingsPage = React.memo(() => {
  const companyGuid = useExpectedCompanyGuid()

  const isEndOfAppointmentWorkflowEnabledForCompany =
    useIsEndOfAppointmentWorkflowEnabledForCompany()

  const {
    formState: { errors, isDirty },
    control,
    handleSubmit,
    reset,
  } = useForm<AppointmentConfigurationSettingsForm>({
    resolver: zodResolver(appointmentConfigurationSettingsFormSchema),
    // Doing `.parse({})` on our schema will give it to us with all the default values filled in.
    defaultValues: appointmentConfigurationSettingsFormSchema.parse({}),
  })

  useEffect(() => {
    if (!isNullish(isEndOfAppointmentWorkflowEnabledForCompany)) {
      reset({
        endOfAppointmentWorkflowEnabled:
          isEndOfAppointmentWorkflowEnabledForCompany,
      })
    }
  }, [isEndOfAppointmentWorkflowEnabledForCompany, reset])

  const [{ fetching: submitting }, executeMutation] = useMutation(
    UPDATE_END_OF_APPOINTMENT_WORKFLOW_ENABLED,
  )

  const onSubmit = handleSubmit(data => {
    executeMutation({
      companyGuid,
      enabled: data.endOfAppointmentWorkflowEnabled,
    })
  })

  return (
    <Page requiresCompanyUser className="flex flex-col p-0">
      <PageHeader
        title={
          <PageTitle title="Appointment Settings" icon={faCalendarCheck} />
        }
      />
      <Card className="min-h-0 w-full overflow-auto p-2">
        <Form onSubmitCapture={onSubmit} layout="vertical">
          <div className="flex flex-row items-start justify-between border-0 border-b border-solid border-b-bz-gray-400 pb-4">
            <div className="max-w-[512px] text-bz-gray-900">
              Enabled or Disable certain features related to appointments
            </div>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              disabled={!isDirty}
            >
              Save Changes
            </Button>
          </div>
          <div className="divide-y divide-solid divide-bz-gray-400">
            <CategorySection
              title="Technician Assignments"
              subtitle="Configure settings related to technician assignments"
            >
              <SubSection
                title="End of Appointment Next Steps Form"
                subtitle="Technicians are required to submit a next steps form and a note for the dispatcher before closing the appointment."
              >
                <CheckField
                  defaultValue={false}
                  errors={errors}
                  name="endOfAppointmentWorkflowEnabled"
                  control={control}
                  disabled={submitting}
                  label={`Enable End of Appointment Next Steps Form`}
                />
              </SubSection>
            </CategorySection>
          </div>
        </Form>
      </Card>
    </Page>
  )
})
