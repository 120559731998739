import {
  IconDefinition,
  faPrintMagnifyingGlass,
  faReceipt,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Pagination } from 'antd'
import classNames from 'classnames'
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useScrollbarWidth } from 'react-use'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { OnResize, useResizeObserver } from '../../hooks/useResizeObserver'
import { FlexItem, calculateFlex } from '../../utils/layoutUtils'
import {
  typedMemo,
  useQueryParamNumberState,
  useQueryParamStateWithOptions,
} from '../../utils/react-utils'
import { FilterHeightContext } from './ListPageContainer'
import './ListPageTable.less'

const PAGINATION_SIZES = ['10', '20', '50', '100'] as const
type ValidPaginationSize = (typeof PAGINATION_SIZES)[number]

export type UsePaginationReturnType = {
  page: number
  pageSize: number
  setPage: (page: number) => void
  setPageSize: (size: ValidPaginationSize) => void
  resetPage: () => void
  limit: number
  offset: number
}

export const usePagination = (
  defaultPageSize: ValidPaginationSize,
): UsePaginationReturnType => {
  const [page, setPage] = useQueryParamNumberState('page', 0)
  const [pageSize, setPageSizeRaw] = useQueryParamStateWithOptions(
    'pageSize',
    defaultPageSize,
    PAGINATION_SIZES,
  )

  const resetPage = useCallback(() => {
    setPage(0)
  }, [setPage])

  const setPageSize = useCallback(
    (size: ValidPaginationSize) => {
      resetPage()
      setPageSizeRaw(size)
    },
    [resetPage, setPageSizeRaw],
  )

  return useMemo(() => {
    const pageSizeInt = parseInt(pageSize)
    return {
      page,
      pageSize: pageSizeInt,
      setPage,
      setPageSize,
      resetPage,
      // I know that this is the same as "pageSize" above. But it's convenient to alias.
      limit: pageSizeInt,
      offset: page * pageSizeInt,
    }
  }, [page, pageSize, setPage, setPageSize, resetPage])
}

export type ListPageTableColsConfig<T> = (Partial<FlexItem> & {
  header: string
  cellClassName?: string
  render: (row: T) => React.ReactNode
})[]

type ListPageTableProps<T> = {
  data?: T[]
  fetching: boolean
  cols: ListPageTableColsConfig<T>
  rowKey: keyof T
  page: number
  setPage: (page: number) => void
  pageSize: number
  setPageSize: (pageSize: ValidPaginationSize) => void
  totalItems: number
  hasFilters?: boolean
  noResultsIcon?: IconDefinition
  itemsDescriptor: string
  renderRowWrapper?: (data: T, children: React.ReactNode) => React.ReactNode
  autoSize?: boolean
}

export const ListPageTable = typedMemo(
  <T,>({
    data,
    cols,
    fetching,
    rowKey,
    page,
    pageSize,
    totalItems,
    setPage,
    setPageSize,
    hasFilters,
    noResultsIcon = faReceipt,
    itemsDescriptor,
    renderRowWrapper,
    autoSize,
  }: ListPageTableProps<T>) => {
    const scrollBarWidth = useScrollbarWidth()

    const containerRef = useRef<HTMLDivElement>(null)

    const [tableWidth, setTableWidth] = useState(0)

    const onResize = useCallback<OnResize>(({ width }) => {
      setTableWidth(width)
    }, [])

    useResizeObserver(containerRef, onResize)

    const [widths, totalMinWidth] = useMemo(
      () =>
        calculateFlex(
          cols.map(({ flex = 0, minWidth = 100 }) => ({ flex, minWidth })),
          tableWidth,
        ),
      [cols, tableWidth],
    )

    const filterHeight = useContext(FilterHeightContext)

    return (
      <div
        ref={containerRef}
        className="relative mr-[-24px] flex-1 pb-6"
        style={{
          scrollbarGutter: 'stable',
          paddingRight: `${24 - (scrollBarWidth ?? 0)}px`,
        }}
      >
        {data &&
          (data.length ? (
            <>
              <table
                className={classNames(
                  'w-full max-w-full',
                  autoSize ? 'table-auto' : 'table-fixed',
                )}
                style={{ minWidth: `${totalMinWidth}px` }}
              >
                <thead
                  className="sticky z-10"
                  style={{ top: `${(filterHeight ?? 0) + 24}px` }}
                >
                  <tr className="bg-white">
                    {cols.map(({ header }, i) => (
                      <th
                        key={header}
                        className="overflow-hidden border-0 border-b border-solid border-transparent"
                        style={{
                          width: autoSize ? undefined : `${widths[i]}px`,
                        }}
                      >
                        <div className="whitespace-nowrap border-0 border-b border-solid border-b-bz-gray-400 px-4 pb-4 text-left text-sm font-semibold uppercase text-bz-gray-800">
                          {header}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map(datum => {
                    const row = (
                      <tr
                        className="border-0 border-b border-t border-solid border-b-bz-gray-400 *:px-4"
                        key={`${datum[rowKey]}`}
                      >
                        {cols.map(({ header, render, cellClassName }) => (
                          <td
                            key={header}
                            className={classNames(
                              'overflow-hidden',
                              cellClassName,
                            )}
                          >
                            {render(datum)}
                          </td>
                        ))}
                      </tr>
                    )
                    if (renderRowWrapper) {
                      return (
                        <React.Fragment key={`${datum[rowKey]}`}>
                          {renderRowWrapper(datum, row)}
                        </React.Fragment>
                      )
                    }
                    return row
                  })}
                </tbody>
              </table>
              <div className="mt-4 flex flex-row justify-end overflow-hidden">
                <Pagination
                  showSizeChanger={false}
                  current={page + 1}
                  pageSize={pageSize}
                  total={totalItems}
                  onChange={page => setPage(page - 1)}
                />
                {/* Note: <Pagination> has its own size dropdown. However, it isn't working with the overflow hidden or
                    something and it's not really showing. This works. */}
                <Dropdown
                  autoAdjustOverflow
                  overlayClassName="list-page-filter-dropdown"
                  menu={{
                    selectable: true,
                    onClick: ({ key }) => {
                      setPageSize(key as ValidPaginationSize)
                      setPage(0)
                    },
                    selectedKeys: [`${pageSize}`],
                    items: PAGINATION_SIZES.map(size => ({
                      key: size,
                      label: size,
                    })),
                  }}
                >
                  <Button>{pageSize} / Page</Button>
                </Dropdown>
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center rounded-md bg-bz-gray-200 pb-24 pt-16">
              <div className="list-page-table-empty-state-circle-icon-drop-shadow flex h-14 w-14 items-center justify-center rounded-full bg-bz-gray-100">
                <FontAwesomeIcon
                  icon={hasFilters ? faPrintMagnifyingGlass : noResultsIcon}
                  className="text-2xl text-daybreak-blue-400"
                />
              </div>
              <div className="mb-2 mt-4 text-xl font-semibold text-bz-gray-900">
                No {hasFilters ? 'results' : itemsDescriptor}
              </div>
              <div className="text-bz-gray-900">
                {hasFilters
                  ? `No ${itemsDescriptor} match that criteria. Please try again.`
                  : `${itemsDescriptor
                      .charAt(0)
                      .toUpperCase()}${itemsDescriptor.substring(
                      1,
                    )} will be displayed here.`}
              </div>
            </div>
          ))}

        {fetching && (
          <div className="absolute inset-0 z-10 flex items-center justify-center backdrop-blur-sm">
            <LoadingSpinner />
          </div>
        )}
      </div>
    )
  },
)
