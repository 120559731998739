import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import React from 'react'
import StatusTag, { StatusTagColor } from '../../elements/StatusTag/StatusTag'

type ScheduleStatusTagProps = {
  status: 'Live Edit' | 'Draft'
}

const toStatusTagDetails = (
  status: ScheduleStatusTagProps['status'],
): { text: string; tooltip: string; color: StatusTagColor } => {
  switch (status) {
    case 'Live Edit':
      return {
        text: 'Live Edit',
        tooltip: 'Edits take effect immediately',
        color: 'green',
      }
    default:
      return {
        text: status === 'Draft' ? 'Draft' : 'Unknown',
        tooltip: 'Edits must be saved to take effect',
        color: 'gray',
      }
  }
}

export const ScheduleStatusTag = React.memo<ScheduleStatusTagProps>(
  ({ status }) => {
    return (
      <StatusTag
        className="flex items-center gap-1"
        text={toStatusTagDetails(status).text}
        color={toStatusTagDetails(status).color}
      >
        <Tooltip title={toStatusTagDetails(status).tooltip}>
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={14}
            style={{ borderRadius: '50%' }}
          />
        </Tooltip>
      </StatusTag>
    )
  },
)
