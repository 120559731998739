import { BulkPricebookCategory, isNullish } from '@breezy/shared'
import { RecordsWithLinks } from '@flatfile/api/api'
import {
  fromRecordToBulkPricebookCategory,
  toFlatfilePricebookCategoryRecord,
} from './FlatfileConversions'

export const fromFlatfileRecordToBulkPricebookCategory = (
  records: RecordsWithLinks,
): BulkPricebookCategory[] => {
  return records
    .filter(r => r.valid)
    .map(toFlatfilePricebookCategoryRecord)
    .map(fromRecordToBulkPricebookCategory)
    .filter((record): record is BulkPricebookCategory => !isNullish(record))
}
