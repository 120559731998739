import { DataFilterConfig } from '@breezy/shared'
import { createContext } from 'react'
import { KanbanJob } from '../../components/Kanban/kanbanUtils'
import { FilterConfig } from '../../hooks/useFilter'
import {
  JobLifecycleColumnDisplayPropertySettings,
  JobLifecycleDisplayPropertySettings,
  JobLifecycleScrollPosition,
} from '../../hooks/useJobLifecyclePersistedDisplay'

type JobKanbanContextType = {
  selectedLifecycleGuid: string
  displayPropertiesSettings: JobLifecycleDisplayPropertySettings
  columnPropertiesSettings: JobLifecycleColumnDisplayPropertySettings
  filters: DataFilterConfig[]
  setFilters: (newFilters: DataFilterConfig[]) => void
  scrollPos: JobLifecycleScrollPosition
  setScrollPos: (newScrollPos: JobLifecycleScrollPosition) => void
  filtersV2Config: FilterConfig<KanbanJob>
  filtersV2: { key: string; optionsSelected: string[] }[]
  addFilter: (filterKey: string, option: string) => void
  removeFilter: (filterKey: string, option: string) => void
  clearFilters: () => void
}

export const JobKanbanContext = createContext<JobKanbanContextType>({
  selectedLifecycleGuid: '',
  displayPropertiesSettings: {},
  columnPropertiesSettings: {},
  filters: [],
  setFilters: () => {},
  scrollPos: { offsetTop: 0, offsetLeft: 0 },
  setScrollPos: () => {},
  filtersV2Config: [],
  filtersV2: [],
  addFilter: () => {},
  removeFilter: () => {},
  clearFilters: () => {},
})
