import { faPhone, faPhoneHangup } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React from 'react'
import {
  CallCard,
  CallHeader,
  CallInfoBody,
  CallInfoBodyProps,
} from './CallElements'

type IncomingCallViewProps = CallInfoBodyProps & {
  onAccept: () => void
  onReject: () => void
}

export const IncomingCallView: React.FC<IncomingCallViewProps> = ({
  avatarText,
  contactName,
  phoneNumber,
  leadSourceName,
  onAccept,
  onReject,
}) => (
  <CallCard
    header={<CallHeader content={<>Incoming Call</>} onClose={onReject} />}
    body={
      <CallInfoBody
        avatarText={avatarText}
        contactName={contactName}
        leadSourceName={leadSourceName}
        phoneNumber={phoneNumber}
        contactIsKnown={avatarText !== '?'}
      />
    }
    controlBar={
      <div className="flex justify-between">
        <Button
          type="primary"
          danger
          size="large"
          onClick={onReject}
          className="w-[48%]"
        >
          <FontAwesomeIcon icon={faPhoneHangup} className="mr-2" />
          Ignore
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={onAccept}
          className="w-[48%] bg-green-500 hover:bg-green-600"
        >
          <FontAwesomeIcon icon={faPhone} className="mr-2" />
          Accept
        </Button>
      </div>
    }
  />
)
