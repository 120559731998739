import { zodResolver } from '@hookform/resolvers/zod'
import {
  createTsForm,
  useStringFieldInfo,
  useTsController,
} from '@ts-react/form'
import { Button, Form, Input, message } from 'antd'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { trpc } from '../../hooks/trpc'
import { LoadingSpinner } from '../LoadingSpinner'

const TextArea = React.memo(() => {
  const {
    field: { onChange, value, name },
    error,
  } = useTsController<string>()

  const { label, placeholder } = useStringFieldInfo()

  return (
    <Form.Item
      label={label}
      validateStatus={error ? 'error' : 'success'}
      help={error?.errorMessage}
    >
      {' '}
      <Input.TextArea
        id={name}
        rows={7}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        value={value}
      />
    </Form.Item>
  )
})
const PromptSandboxFormSchema = z.object({
  prompt: z.string().describe('Prompt'),
  // eslint-disable-next-line breezy/no-zod-optional
  queryContext: z.string().optional().describe('Query Context'),
})

type PromptSandboxFormData = z.infer<typeof PromptSandboxFormSchema>

const mapping = [[z.string(), TextArea]] as const

type CustomFormProps = React.PropsWithChildren<{
  onSubmit: () => void
  disabled?: boolean
  loading?: boolean
}>

const CustomForm = ({
  children,
  onSubmit,
  disabled,
  loading,
}: CustomFormProps) => {
  return (
    <Form
      onSubmitCapture={onSubmit}
      disabled={disabled || loading}
      layout="vertical"
    >
      {children}
      <Button htmlType="submit" type="primary" loading={loading}>
        Generate
      </Button>
    </Form>
  )
}

const PromptSandboxForm = createTsForm(mapping, { FormComponent: CustomForm })

const PromptSandbox = React.memo(() => {
  const [resp, setResp] = useState<string>()
  const [prompt, setPrompt] = useState<string>('')

  const form = useForm<z.infer<typeof PromptSandboxFormSchema>>({
    resolver: zodResolver(PromptSandboxFormSchema),
  })

  const query = trpc.devTools['devtools:get-llm-completion'].useQuery(
    { value: prompt },
    {
      enabled: !!prompt && prompt.length > 0,
      onSuccess: v => setResp(v.value),
      onError: e => message.error(e.message),
    },
  )

  const onSubmit = useCallback((data: PromptSandboxFormData) => {
    const prompt = `${data.prompt}. ${
      (data.queryContext ?? '').length > 0
        ? `Here is the context data: ${data.queryContext}`
        : ''
    }`
    setPrompt(prompt)
  }, [])

  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <PromptSandboxForm
        form={form}
        schema={PromptSandboxFormSchema}
        onSubmit={onSubmit}
      />
      {query.isLoading && <LoadingSpinner />}
      {!query.isLoading && (
        <>
          <h3 className="mb-1 mt-6 text-sm">Response</h3>
          <Input.TextArea
            rows={7}
            placeholder={'Happy Hacking!'}
            value={resp ?? 'Happy Hacking!'}
            readOnly
          />
        </>
      )}
    </div>
  )
})

export default PromptSandbox
