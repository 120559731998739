import {
  BzDateTime,
  CalculatePaths,
  INVOICE_STATUSES,
  INVOICE_V2_FRIENDLY_STATUS_NAMES,
  InvoiceV2Status,
  IsoDateString,
  QboStaleInfo,
  TimeZoneId,
  bzExpect,
  createInvoiceQboLink,
  createPaymentQboLink,
  createPayoutQboLink,
  formatMoney,
  isNullish,
  toTitleCase,
  usCentsToUsd,
} from '@breezy/shared'
import { faEllipsis } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Pagination, Table } from 'antd'
import { ColumnsType, FilterValue } from 'antd/es/table/interface'
import { TablePaginationConfig } from 'antd/lib'
import { SorterResult } from 'antd/lib/table/interface'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import InvoiceStatusTag from '../../components/Invoicing/InvoiceStatusTag/InvoiceStatusTag'
import {
  QuickbooksSyncInvoiceButton,
  useIsQboEnabled,
} from '../../components/Quickbooks/QuickbooksSyncButton'
import {
  dateColumn,
  renderDataTableAccountLink,
} from '../../components/datatables/CommonColumnDefinitions'
import { EmDash } from '../../elements/EmDash/EmDash'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'

type EllipsisDropdownItems = Record<string, () => void>
type EllipsisDropdownProps = { actions: EllipsisDropdownItems }

const EllipsisDropdown = React.memo(({ actions }: EllipsisDropdownProps) => {
  return (
    <Dropdown
      className="m-0"
      placement="bottomRight"
      menu={{
        items: Object.entries(actions).map(([title, action]) => ({
          key: title,
          label: (
            <div className="text-blue-500" onClick={action}>
              {title}
            </div>
          ),
        })),
      }}
    >
      <Button
        shape="circle"
        className="h-[28px] min-h-[24px] w-[28px] min-w-[24px]"
        icon={<FontAwesomeIcon icon={faEllipsis} />}
      />
    </Dropdown>
  )
})

const openLinkInNewTab = (url: string) => {
  window.open(url, '_blank')
}

type PaymentRecordPayoutItem = {
  payout?: PayoutItem
}

export type PayoutItem = {
  paidAt: IsoDateString
  payoutGuid: string
  qboSync?: {
    qboId?: string
  }
}

type PaymentStatus = {
  paymentStatus: string
}

type PaymentRecord = {
  paymentRecordGuid: string
  paymentMethod: string
  occurredAt: IsoDateString
  paymentStatuses: PaymentStatus[]
  amountUsd: number
  payoutItem?: PaymentRecordPayoutItem
  qboSync?: {
    qboId?: string
  }
}

type QboStatus = {
  qboSyncedAt: IsoDateString
  isQboStale: boolean
  createdAt: IsoDateString
}

export type TableBookkeeper = {
  status: InvoiceV2Status
  invoiceGuid: string
  displayId: number
  accountDisplayName: string
  accountGuid: string
  issuedAt: IsoDateString
  dueUsc: number
  totalUsc: number
  qboStatus?: QboStatus
  successfulPayments?: PaymentRecord[]
  payouts?: PayoutItem[]
  qboSync?: {
    qboId?: string
  }
  qboStale?: QboStaleInfo
}

type TableItemType = TableBookkeeper

const itemIfOnlyOne = <T,>(
  arr: T[] | undefined,
  mapper: (item: T) => React.ReactNode,
): React.ReactNode => {
  if (!arr) return <EmDash />
  if (arr.length === 0) return <EmDash />
  if (arr.length > 1) return <div>Multiple</div>
  return mapper(arr[0])
}

export const useColumns = (tzId: TimeZoneId, refetch: () => void) => {
  const isQboEnabled = useIsQboEnabled()

  const qboStaleInvoicesQuery = trpc.qbo[
    'finance-app:get-stale-invoices'
  ].useQuery(
    {},
    {
      enabled: isQboEnabled,
    },
  )
  const cols: ColumnsType<TableItemType> = [
    {
      ...dateColumn(tzId, 'Issued', 'issuedAt', 'M/dd/yy', true, true),
    },
    {
      title: 'Invoice',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      sorter: true,
      render: (_: string, item: TableItemType) => (
        <Link
          to={CalculatePaths.invoiceOverview(item)}
        >{`#${item.displayId}`}</Link>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: INVOICE_STATUSES.filter(x => x !== 'DRAFT').map(
        invoiceStatus => ({
          text: INVOICE_V2_FRIENDLY_STATUS_NAMES[invoiceStatus],
          value: invoiceStatus,
        }),
      ),
      render: (_: string, record: TableItemType) => (
        <InvoiceStatusTag status={record.status} />
      ),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: 'Account',
      dataIndex: 'accountDisplayName',
      key: 'accountDisplayName',
      render: (_: string, record: TableItemType) =>
        renderDataTableAccountLink({
          accountGuid: record.accountGuid,
          displayName: record.accountDisplayName,
        }),
      ellipsis: {
        showTitle: true,
      },
      width: 240,
    },
    {
      title: 'Amount',
      dataIndex: 'totalUsc',
      key: 'totalUsc',
      sorter: true,
      render: (_: string, record: TableItemType) => (
        <div>{formatMoney(usCentsToUsd(record.totalUsc))}</div>
      ),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: 'Due',
      dataIndex: '  dueUsc',
      key: 'dueUsc',
      render: (_: string, record: TableItemType) =>
        record.dueUsc > 0 ? (
          <div>{formatMoney(usCentsToUsd(record.dueUsc))}</div>
        ) : (
          <EmDash />
        ),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: 'Paid At',
      dataIndex: 'successfulPayments',
      key: 'successfulPayments2',
      render: (_: string, record: TableItemType) =>
        itemIfOnlyOne(record.successfulPayments, p => (
          <Link to={CalculatePaths.paymentDetails(p)}>
            {BzDateTime.fromIsoString(p.occurredAt, tzId).toDateFormat(
              'M/dd/yy',
            )}
          </Link>
        )),
    },
    {
      title: 'Paid',
      dataIndex: 'successfulPayments',
      key: 'successfulPayments',
      render: (_: string, record: TableItemType) =>
        itemIfOnlyOne(record.successfulPayments, p => (
          <div>{formatMoney(p.amountUsd)}</div>
        )),
    },
    {
      title: 'Method',
      dataIndex: 'successfulPayments',
      key: 'successfulPayments3',
      render: (_: string, record: TableItemType) =>
        itemIfOnlyOne(record.successfulPayments, p => (
          <div>{toTitleCase(p.paymentMethod)}</div>
        )),
    },
    {
      title: 'Payout',
      dataIndex: 'payouts',
      key: 'payouts',
      render: (_: string, record: TableItemType) =>
        itemIfOnlyOne(record.payouts, p => (
          <Link to={CalculatePaths.payoutDetails(p)}>
            {BzDateTime.fromIsoString(p.paidAt, tzId).toDateFormat('M/dd/yy')}
          </Link>
        )),
    },
  ]

  if (isQboEnabled) {
    cols.push({
      title: 'QBO',
      key: 'Quickbooks Sync',
      filters: [
        { text: 'Synced', value: 'Synced' },
        { text: 'Out of sync', value: 'Out of sync' },
      ],
      onCell: () => ({
        className: 'p-1',
      }),
      render: (_: string, invoice: TableItemType) => (
        <QuickbooksSyncInvoiceButton
          className="float-right mr-6"
          placement="left"
          size="sm"
          params={{ invoiceGuid: invoice.invoiceGuid }}
          loading={qboStaleInvoicesQuery.isLoading}
          staleInfo={invoice.qboStale}
          onSuccess={() => {
            qboStaleInvoicesQuery.refetch()
            refetch()
          }}
        />
      ),
    })
  }

  cols.push({
    title: 'Links',
    key: 'links',
    onCell: () => ({
      className: 'p-1',
    }),
    render: (_: string, record: TableItemType) => {
      const externalLinks: Record<string, () => void> = {}
      if (isQboEnabled) {
        if (record.qboSync?.qboId) {
          externalLinks['QBO - View Invoice'] = () => {
            openLinkInNewTab(
              createInvoiceQboLink(
                bzExpect(record.qboSync?.qboId, 'Invoice Qbo ID'),
              ),
            )
          }
        }
        record.successfulPayments?.forEach((payment, index) => {
          if (payment.qboSync?.qboId) {
            externalLinks[`QBO - View Payment ${index > 0 ? index + 1 : ''}`] =
              () => {
                openLinkInNewTab(
                  createPaymentQboLink(
                    bzExpect(payment.qboSync?.qboId, 'Payment Qbo ID'),
                  ),
                )
              }
          }
        })
        record.payouts?.forEach((payout, index) => {
          if (payout.qboSync?.qboId) {
            externalLinks[`QBO - View Payout ${index > 0 ? index + 1 : ''}`] =
              () => {
                openLinkInNewTab(
                  createPayoutQboLink(
                    bzExpect(payout.qboSync?.qboId, 'Payout Qbo ID'),
                  ),
                )
              }
          }
        })
      }
      return Object.entries(externalLinks).length > 0 ? (
        <EllipsisDropdown actions={externalLinks} />
      ) : (
        <EmDash />
      )
    },
  })

  return cols
}

export type BookkeeperTableProps = {
  items: TableItemType[]
  page: number
  perPage: number
  total: number
  refetch: () => void
  loading?: boolean
  onPaginationChanged: (page: number, pageSize: number) => void
  onPaginationShowSizeChanged: (currentSize: number, size: number) => void
  onTableChanged?: (values: {
    filters: { field: string; filterValues: string[] }[]
    sorters: { field: string; ascending: boolean }[]
  }) => void
}

export const BookkeeperTable = ({
  items,
  page,
  perPage,
  total,
  refetch,
  loading,
  onPaginationChanged,
  onPaginationShowSizeChanged,
  onTableChanged,
}: BookkeeperTableProps) => {
  const companyTimeZoneId = useExpectedCompanyTimeZoneId()

  const cols = useColumns(companyTimeZoneId, refetch)

  const onTableChangedInternal = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<TableItemType> | SorterResult<TableItemType>[],
    ) => {
      if (!onTableChanged) {
        return
      }

      const newFilters: Parameters<typeof onTableChanged>[0]['filters'] = []
      Object.entries(filters).forEach(([field, values]) => {
        if (isNullish(values) || values.length === 0) {
          return
        }

        newFilters.push({
          field: field,
          filterValues: values.map(value => value.toString()),
        })
      })

      const newSorters: Parameters<typeof onTableChanged>[0]['sorters'] = []
      if (Array.isArray(sorter)) {
        sorter.forEach(currSorter => {
          if (isNullish(currSorter.column) || isNullish(currSorter.order)) {
            return
          }

          newSorters.push({
            field: currSorter.columnKey as string,
            ascending: (currSorter.order as string) === 'ascend',
          })
        })
        return
      } else if (!isNullish(sorter.column) && !isNullish(sorter.order)) {
        newSorters.push({
          field: sorter.columnKey as string,
          ascending: (sorter.order as string) === 'ascend',
        })
      }

      onTableChanged({ filters: newFilters, sorters: newSorters })
    },
    [onTableChanged],
  )

  return (
    <>
      <Table
        rowKey="invoiceGuid"
        dataSource={items}
        scroll={{ x: true }}
        columns={cols}
        pagination={false}
        onChange={onTableChangedInternal}
        size="small"
        loading={loading}
      />

      {!loading && (
        <div className="mt-3 flex w-full flex-col">
          <Pagination
            className="self-end"
            pageSizeOptions={[15, 30, 50, 100, 250]}
            defaultPageSize={30}
            current={page}
            pageSize={perPage}
            total={total}
            onChange={onPaginationChanged}
            onShowSizeChange={onPaginationShowSizeChanged}
          />
        </div>
      )}
    </>
  )
}
